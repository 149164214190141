import React, { FunctionComponent, Suspense, useState } from 'react';
import { APP_URLS, xTenantId } from '../../consts';
import useDestroyUserSession from '../../hooks/auth/useDestroyUserSession';
import { useSnackbar } from '../../hooks/snackbar';
import { CreatorName } from '../../libs/graphql/baseAppAPI/__generated_types__/types.generated';
import { useSuspensionLimitsCreateMutation } from '../../libs/graphql/baseAppAPI/mutations/__generated__/suspension-limits-create.mutation.generated';
import { useUserContext } from '../../providers/UserProvider';
import { SuspensionLimitsType } from '../../types.__generated__';
import { AccountPageWrapper, AccountPageWrapperProps } from '../Account/MyAccount/AccountPageWrapper';
import { AccountTile } from '../Account/MyAccount/AccountTile';
import { AccountTileListItem } from '../Account/MyAccount/AccountTileListItem';
import { openNewWindow } from '../Navigation/NavigationLink/helper';
import { Heading } from '../shared/Heading';
import { Paragraph } from '../shared/Paragraph';
import { Spinner } from '../shared/Spinner';
import AccountLimits from './AccountLimits';
import RealityChecks from './RealityChecks';
import { SelfExclusion } from './SelfExclusion';
import { TakeABreak, TakeABreakPayload } from './TakeABreak';
import './styles';
import { useTranslations } from '../../hooks/useTranslationsHelper';
import { Trans } from '../shared/Trans';

export type GamblingControlProps = {
  /**
   * Function to navigate to homepage
   */
  onGoToHome: (withRefresh: boolean) => void;
  /**
   * Props to open AccountClosure
   */
  forceDisplayAccountClosure?: boolean;
} & Pick<AccountPageWrapperProps, 'onClose' | 'elementToFocusOnOpen' | 'hasBackButton' | 'hasCloseButton'>;

export const GamblingControls: FunctionComponent<GamblingControlProps> = ({
  onClose,
  elementToFocusOnOpen,
  hasBackButton = true,
  hasCloseButton = true,
  onGoToHome,
}) => {
  const { t } = useTranslations();
  const { userProfileId } = useUserContext();
  const { logoutUser } = useDestroyUserSession();
  const { addSnack } = useSnackbar();
  const [suspensionLimitsCreateMutation, { loading, error }] = useSuspensionLimitsCreateMutation();

  const [selfExclusionModalOpen, setSelfExclusionModalOpen] = useState(false);
  const [takeABreakModalOpen, setTakeABreakModalOpen] = useState(false);

  async function handleSelfExclude(duration: string, reason: string, durationLabel: string): Promise<void> {
    try {
      const params = {
        reason,
        intent: SuspensionLimitsType.Selfexclusion,
        createdBy: userProfileId,
        duration: `P${+duration}D`, // ISO 8601 Durations: https://www.digi.com/resources/documentation/digidocs/90001488-13/reference/r_iso_8601_duration_format.htm
      };

      await suspensionLimitsCreateMutation({
        variables: { userProfileId, xTenantId, creatorName: CreatorName.User, suspensionLimitsCreate: params },
      });
      await logoutUser();

      addSnack({
        type: 'success',
        message:
          duration === 'indefinitely'
            ? t('my-account.home.gambling.exlude-message')
            : `${t('my-account.home.gambling.exlude-certain-message')} ${durationLabel}`,
        theme: 'dark',
      });
      onGoToHome(false);
    } catch {
      console.log('handleSelfExclude error: ', error);
      addSnack({
        type: 'error',
        message: t('errors.general'),
        theme: 'dark',
      });
    }
  }

  async function handleTakeBreak({ duration, reason }: TakeABreakPayload): Promise<void> {
    try {
      const params = {
        reason,
        intent: SuspensionLimitsType.Timeout,
        createdBy: userProfileId,
        duration: `P${+duration}D`, // ISO 8601 Durations: https://www.digi.com/resources/documentation/digidocs/90001488-13/reference/r_iso_8601_duration_format.htm
      };

      await suspensionLimitsCreateMutation({
        variables: { userProfileId, xTenantId, creatorName: CreatorName.User, suspensionLimitsCreate: params },
      });
      await logoutUser();

      addSnack({
        type: 'success',
        message: t('messages.success'),
      });
      onGoToHome(false);
    } catch {
      console.log('handleTakeBreak error: ', error);
      addSnack({
        type: 'error',
        message: t('errors.general'),
      });
    }
  }

  const openTakeABreak = (): void => {
    setTakeABreakModalOpen(true);
    setSelfExclusionModalOpen(false);
  };

  const openExclusion = (): void => {
    setTakeABreakModalOpen(false);
    setSelfExclusionModalOpen(true);
  };

  const closeGamblingControls = (): void => {
    onClose();
  };

  const onOpenHelp = (): void => {
    openNewWindow(APP_URLS.help, '_blank');
  };

  return (
    <AccountPageWrapper
      hasBackButton={hasBackButton}
      hasCloseButton={hasCloseButton}
      onClose={closeGamblingControls}
      className="gambling-controls"
      elementToFocusOnOpen={elementToFocusOnOpen}
      stickyFooter={
        <div>
          <Paragraph noMargin size="xs">
            <Trans keyProp="gambling-controls.caution.message">
              If at any time you feel overwhelmed, you can speak to our{' '}
              <button onClick={onOpenHelp} className="gambling-controls__link">
                customer service team
              </button>{' '}
              or{' '}
              <button onClick={openExclusion} className="gambling-controls__link">
                self exclude
              </button>{' '}
              service.
            </Trans>
          </Paragraph>
        </div>
      }
    >
      <div className="gambling-controls__header">
        <Heading level={3} noMargin>
          {t('my-account.home.gambling.title')}
        </Heading>
      </div>

      <Suspense fallback={<Spinner theme="dark" />}>
        <div className="gambling-controls__content">
          <AccountLimits />
          <RealityChecks isModal={false} />
          <Heading className="gambling-controls__section-heading" noMargin level={6}>
            {t('my-account.home.gambling.account-closure')}
          </Heading>
          <Paragraph size="xs" className="gambling-controls__section-description">
            {t('my-account.home.gambling.self-exclude-text')}
          </Paragraph>
          <div className="account-closure">
            <AccountTile noPadding>
              <AccountTileListItem
                text={t('my-account.home.gambling.pause-account')}
                icon="ChevronRight"
                iconAlign="right"
                onClick={openTakeABreak}
              />
              <AccountTileListItem
                text={t('my-account.home.gambling.self-exclude')}
                icon="ChevronRight"
                iconAlign="right"
                onClick={openExclusion}
              />
            </AccountTile>
          </div>
        </div>

        <SelfExclusion
          isOpen={selfExclusionModalOpen}
          onClose={(): void => setSelfExclusionModalOpen(false)}
          onSelfExclude={handleSelfExclude}
          ctaUrl={APP_URLS.help}
        />

        <TakeABreak
          isOpen={takeABreakModalOpen}
          disabled={loading}
          onExclusion={openExclusion}
          onClose={(): void => {
            setTakeABreakModalOpen(false);
          }}
          onSubmit={handleTakeBreak}
        />
      </Suspense>
    </AccountPageWrapper>
  );
};
