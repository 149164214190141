import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import { APP_URLS, locale } from '../../../consts';
import { useSnackbar } from '../../../hooks/snackbar';
import { MediaBreakpoint, useBreakpointInfo } from '../../../hooks/useDeviceInfo';
import { ButtonLinkType, HeroBannerCarousel, HeroBannerSlide } from '../../HeroBannerCarousel';
import { Accordion, AccordionContent, AccordionHeader } from '../../shared/Accordion';
import { Button } from '../../shared/Button';
import { ContentPreview } from '../../shared/ContentPreview';
import { Heading } from '../../shared/Heading';
import { InnerHTML } from '../../shared/InnerHTML';
import { RouterLink } from '../../shared/Link';
import { GameCard } from '../GameCard';
import { GameSpecs } from '../GameSpecs';
import { GamesCarouselList } from '../GamesCarousel';
import { GameImage, Game } from '../games.model';
import { gameInfoConfig } from '../mocks';
import { GameInfoCard } from './GameInfoCard';
import { GameInfoRegBonus } from './GameInfoRegBonus';
import { GameTags } from './GameTags';
import { GameFeatures } from '../GameFeatures';
import defaultImage from '../../../assets/Games/slot_8.png';
import { getGameCarouselConfig } from './GamesCarouselConfig';
import { useFetchGames } from '../../../hooks/useFetchGames';
import { transformArrayGameData } from '../../../utils/games/games.utils';
import { storageRoute } from '../../../utils/storage';
import { getGameUrl } from '../helper';
import { useSelector } from 'react-redux';
import { selectDisabledGamesData } from '../../../state/game';
import { getRedirectUri } from '../../Authentification/helper';
import './styles';
import { useTranslations } from '../../../hooks/useTranslationsHelper';

export type GameInfoProps = {
  /**
   * Game data to display info about
   */
  game: Game;
  /**
   * Whether user is currently logged in
   */
  isUserAuthenticated: boolean;
  /**
   * Button type used to trigger LowBalancePrompt Journey in HeroBannerCarousel
   */
  buttonLinkType?: ButtonLinkType;
  gameEnabled: boolean;
  /**
   * Whether game is initialized or not
   */
  isGameInitialised: boolean;
};

/**
 * Component to render game info
 * TODO: Had to use regular Link instead of RouterLink.  Not ideal.  Look into what can be done.
 */
export const GameInfo: FunctionComponent<GameInfoProps> = ({
  game,
  gameEnabled,
  isGameInitialised,
  isUserAuthenticated,
}) => {
  const { t } = useTranslations();
  const auth = useAuth();
  const navigate = useNavigate();
  const { addSnack } = useSnackbar();
  const { areGamesDisabled } = useSelector(selectDisabledGamesData);
  const [similarGames, setSimilarGames] = useState<Game[]>([]);
  const isBelowMobileWidth = useBreakpointInfo([MediaBreakpoint.BELOW_MOBILE]);
  const isBelowDesktopWidth = useBreakpointInfo([MediaBreakpoint.BELOW_DESKTOP]);
  const {
    tags,
    rules,
    gameId,
    gameImage,
    displayName,
    bannerImage,
    categoryName,
    friendlyUrlName,
    longDescription,
    shortDescription,
  } = game;
  const { fetchGamesByTags, isFetchedGamesByTagsDataLoading } = useFetchGames({});
  const fallbackImage: GameImage = {
    alt: displayName,
    // TODO: Set proper image URL
    url: defaultImage,
    label: displayName,
  };

  // Data from custom games carousel config
  const {
    gameCarouselTitle,
    slidesCount,
    bigDesktopSlideCount,
    desktopSlideCount,
    tabletSlideCount,
    mobileSlideCount,
    slideContainerType,
    rowsNumber,
  } = getGameCarouselConfig(t);

  const filteredGames = useMemo(
    () => similarGames.filter((item) => item.friendlyUrlName !== game.friendlyUrlName),
    [game.friendlyUrlName, similarGames.length]
  );

  const slideLinkProps = {
    buttonEnabled: !isUserAuthenticated || gameEnabled,
    buttonLabel: isUserAuthenticated ? t('buttons.play-now.ippercase') : t('buttons.join-now.ippercase'),
    buttonLink: isUserAuthenticated ? `${getGameUrl(game.friendlyUrlName)}/real` : APP_URLS.registration,
    routerLink: isUserAuthenticated,
  };
  const bannerBackground = bannerImage.imgUrl || gameImage.imgUrl;

  const slides: HeroBannerSlide[] = [
    {
      ...slideLinkProps,
      heading: displayName,
      subHeading: shortDescription || '',
      footer: '',
      imgSrc: bannerBackground,
      mobileImgSrc: bannerBackground,
      imgAlt: displayName,
      buttonLinkType: ButtonLinkType.GAME,
    },
  ];

  const featuresSection = <GameFeatures gameFeatures={game.features} heading={t('game.features.label')} />;
  const specsSection = <GameSpecs game={game} />;

  const showDescription = !gameInfoConfig.hideLongDescription && longDescription && longDescription.length;
  const showRules = !gameInfoConfig.hideRules && rules && rules.length;

  const fetchGames = async (): Promise<void> => {
    if (!game.tags?.length) {
      return;
    }

    for (const tag of tags || []) {
      const similarGamesResponse = await fetchGamesByTags({
        variables: {
          gameId,
          language: locale,
          count: slidesCount,
          tag: tag?.name || '',
          category: categoryName,
        },
        onCompleted: (data) => {
          const transformedData = transformArrayGameData(data.game);

          if (transformedData.length) {
            setSimilarGames(transformedData);
          }
        },
      }).then((resp) => resp.data?.game);

      if (similarGamesResponse?.length) break;
    }
  };

  useEffect(() => {
    gameId && fetchGames();
  }, [isGameInitialised, gameId]);

  const gameLaunch = async (event: React.MouseEvent | React.KeyboardEvent): Promise<void> => {
    event.preventDefault();
    const gameUrl = `/game-details/${friendlyUrlName}/real`;

    if (isUserAuthenticated) {
      try {
        if (auth.user?.expired) {
          await auth.signinSilent();
        }

        navigate(gameUrl);

        storageRoute.set({
          value: `/game-details/${friendlyUrlName}`,
        });
      } catch (error) {
        console.error('retrieveGameLaunchUrl error: ', error);
        addSnack({
          type: 'error',
          message: "Oops, something's gone wrong",
        });
      }

      return;
    }

    auth.signinRedirect({ redirect_uri: getRedirectUri(gameUrl) });
  };

  return (
    <div className="game-info">
      <div className="game-info__col-1">
        {/* Carousel */}
        {Boolean(bannerBackground) && (
          <HeroBannerCarousel
            isDisabledSlideContent={areGamesDisabled}
            className="game-info__banner game-info__col-1"
            slides={slides}
            autoplayInterval={6000}
            friendlyUrlName={friendlyUrlName}
            game={game}
            callEligiblePromos={false}
            isGameInfoPage
          />
        )}

        {/* Game tags */}
        <GameTags game={game} />

        {/* Unauthed users - shows register bonus banner, Authed users - shows Game play button on screens below 600px */}
        {isUserAuthenticated ? (
          isBelowMobileWidth &&
          (!gameEnabled ? (
            <Button type="button" size="large" className="button--full-width" disabled onClick={gameLaunch}>
              PLAY NOW
            </Button>
          ) : (
            <RouterLink
              buttonStyle={{ variant: 'primary', size: 'large' }}
              to="/real"
              className="button--full-width"
              onClick={gameLaunch}
            >
              PLAY NOW
            </RouterLink>
          ))
        ) : (
          <GameInfoRegBonus />
        )}

        {/* Game features. To be filled in EFE-931 */}
        {isBelowDesktopWidth && featuresSection}

        {/* Game description & rules */}
        {(showRules || showDescription) && (
          <GameInfoCard className="game-info__description">
            {showDescription && (
              <ContentPreview>
                <Heading level={5} className="game-info__card-heading">
                  {t('game.about.label')}
                </Heading>
                <InnerHTML text={longDescription as string} />
              </ContentPreview>
            )}

            {showRules && (
              <Accordion defaultExpanded={!isBelowDesktopWidth}>
                <AccordionHeader>{t('game.rules.label')}</AccordionHeader>
                <AccordionContent>
                  <InnerHTML text={rules} />
                </AccordionContent>
              </Accordion>
            )}
          </GameInfoCard>
        )}

        {/* Game specs */}
        {isBelowDesktopWidth && specsSection}
        <div className="game-info__similar-games">
          {filteredGames.length ? (
            <GamesCarouselList
              title={gameCarouselTitle}
              fallbackImageUrl={fallbackImage.url}
              fallbackImageAltText={fallbackImage.alt}
              bigDesktopSlideCount={bigDesktopSlideCount}
              desktopSlideCount={desktopSlideCount}
              tabletSlideCount={tabletSlideCount}
              mobileSlideCount={mobileSlideCount}
              showArrows
              showTitle
              showDots={false}
              autoplayInterval={5000}
              gameCardContainerType={slideContainerType}
              disabled={areGamesDisabled}
              GameComponent={GameCard}
              loadMoreButtonTitle=""
              rowsNumber={rowsNumber}
              catalogue={filteredGames}
              isLoading={isFetchedGamesByTagsDataLoading}
            />
          ) : null}
        </div>
      </div>

      {/* Second column containing features and specs, only shown > desktop width */}
      {!isBelowDesktopWidth && (
        <div className="game-info__col-2">
          {featuresSection}
          {specsSection}
        </div>
      )}
    </div>
  );
};
