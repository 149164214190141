import React, { ComponentPropsWithRef, FunctionComponent } from 'react';
import { HeadingLevel } from '../Heading';
import { joinStrings } from '../../../utils/string';
import './FieldSet.scss';

export type FieldSetProps = {
  /**
   * FieldSet legend text. Optional but ideally should be used for screen readers.
   */
  legend?: string | React.ReactNode;
  /**
   * Whether to show legend for screen reader only.  Defaults to false
   */
  legendSrOnly?: boolean;
  /**
   * Apply heading level style to legend.  Optional, doesn't apply heading style by default.
   */
  legendHeadingStyleLevel?: HeadingLevel;
  /**
   * Optional class name for legend.
   */
  legendClassName?: string;
  children?: React.ReactNode;
} & ComponentPropsWithRef<'fieldset'>;

/**
 * A FieldSet component so that fieldset tags and their associated legend can be standardized across components.
 */
export const FieldSet: FunctionComponent<FieldSetProps> = ({
  children,
  legend,
  legendHeadingStyleLevel,
  legendSrOnly,
  legendClassName,
  className,
  ...fieldsetProps
}) => {
  return (
    <fieldset {...fieldsetProps} className={joinStrings(['field-set', className])}>
      {legend && (
        <legend
          data-testid="field-set-legend"
          className={joinStrings([
            legendSrOnly && 'u-sr-only',
            legendHeadingStyleLevel && `field-set__legend--${legendHeadingStyleLevel}`,
            legendClassName,
          ])}
        >
          {legend}
        </legend>
      )}
      {children}
    </fieldset>
  );
};
