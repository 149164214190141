import axios from 'axios';
import { getToken } from '../../../../utils/auth';
import { xTenantId, currencyCode, requestUrl } from '../../../../consts';
import { CashierType } from '../../../../state/cashier';

export const fetchSessionId = async (
  userProfileId: string,
  method: string | CashierType | undefined
): Promise<string> => {
  const token = getToken();
  const response = await axios.post(
    `${requestUrl}/endeavour-devcode/v1/user-profiles/${userProfileId}/launch/${method}`,
    { currencyCode },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Tenant-ID': xTenantId,
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data.sessionContextId;
};
