import React, { FunctionComponent, useState } from 'react';
import { Spinner } from '../../../../shared/Spinner';
import { Icon } from '../../../../shared/Icon';
import { PromotionProgressBar } from '../../../../Layout/Promotions/PromotionProgressBar';
import './styles';
import { ParsedPromo } from '../../../../Layout/Promotions/types';

export type BonusItemProps = {
  index: number;
  promotion: ParsedPromo;
  isFetching: boolean;
};

export const BonusItem: FunctionComponent<BonusItemProps> = ({ promotion, isFetching }) => {
  const [showDetails, showDetailsSet] = useState<boolean>(false);

  const onToggleDetails = (e: React.MouseEvent | React.KeyboardEvent): void => {
    e.stopPropagation();
    showDetailsSet(!showDetails);
  };

  const searchGamesByTag = (): void => {
    console.log('searchGamesByTag', searchGamesByTag);
  };

  return (
    <>
      {isFetching ? (
        <Spinner />
      ) : (
        <div
          className="balance-details__item"
          onClick={(): void => searchGamesByTag()}
          onKeyPress={(): void => searchGamesByTag()}
          role="button"
          tabIndex={0}
        >
          <PromotionProgressBar promotion={promotion} />
          <span
            tabIndex={0}
            className="balance-details__item-expand-btn"
            onClick={onToggleDetails || undefined}
            onKeyPress={onToggleDetails || undefined}
            role="switch"
            aria-checked={showDetails}
          >
            <Icon variant="ChevronRight" />
          </span>
        </div>
      )}
    </>
  );
};
