import React, { FC, useState } from 'react';
import { WelcomeBonusTermsAndConditions } from '../../../Account/WelcomeBonusJourney/WelcomeBonusTermsAndConditions';
import { PromotionPreDepositConfirmation } from './PromotionPreDepositConfirmation';
import './PromotionPreDepositContent.scss';
import { ParsedPromo } from '../types';

interface PromotionPreDepositContentProps {
  activatedPromo: ParsedPromo;
  onClose: () => void;
}

export const PromotionPreDepositContent: FC<PromotionPreDepositContentProps> = ({ activatedPromo, onClose }) => {
  const [isTnCShown, setTnCShown] = useState(false);
  const [depositAmount, setDepositAmount] = useState<string>('');
  const { description } = activatedPromo;

  return isTnCShown ? (
    <WelcomeBonusTermsAndConditions
      title="Terms and conditions"
      description={description}
      back={(): void => setTnCShown(false)}
    />
  ) : (
    <PromotionPreDepositConfirmation
      showTnC={(): void => setTnCShown(true)}
      onClose={onClose}
      depositAmount={depositAmount}
      setDepositAmount={setDepositAmount}
      activatedPromo={activatedPromo}
    />
  );
};
