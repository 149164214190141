import React, { FC } from 'react';
import { BonusBar } from '../../../../shared/SVG/BonusBar/BonusBar';
import { Link } from '../../../../shared/Link';
import { formatCurrency } from '../../../../../utils/wallets';
import { PromotionCancelButton } from '../../PromotionCancelButton';
import { ParsedPromo } from '../../types';

export interface CardActiveCTASectionProps {
  depositedValue?: number;
  remainingValue?: number;
  pageUrl?: string;
  bonusType?: string;
  showButton?: boolean;
  activatedPromo: ParsedPromo;
  isActivePromotion: boolean;
}

export const CardActiveCTASection: FC<CardActiveCTASectionProps> = ({
  depositedValue = 0,
  remainingValue = 0,
  pageUrl,
  bonusType,
  activatedPromo,
  isActivePromotion,
  showButton = true,
}): JSX.Element => {
  // TODO: Markup should be reworked in appropriate ticket for promotion active card
  const progressBarPercent = (Number(depositedValue) / Number(remainingValue)) * 100;

  return (
    <div className="promotion-card-active padding-16">
      {/* Assume that if remainingValue present bonus is progressive */}
      {remainingValue && bonusType !== 'CashbackOnNetLosses' ? (
        <div className="promotion-card-active__progress-info">
          <BonusBar value={progressBarPercent} />
          <div className="promotion-card-active__progress-values">
            <div>
              <b>{formatCurrency(depositedValue, 'auto', 'auto')}</b>
              <span>staked</span>
            </div>
            <div>
              <b>{formatCurrency(remainingValue, 'auto', 'auto')}</b>
              <span>target</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="promotion-card-active__message-block">
          <span className="promotion-card-active__opted-in-message">
            Opted in
            {/* Consider using InputFeedbackIcon */}
          </span>
        </div>
      )}
      {showButton && (
        <div className="promotion-card-active__cta-section">
          {isActivePromotion && <PromotionCancelButton activatedPromo={activatedPromo} />}
          <Link href={pageUrl} buttonStyle={{ variant: 'primary ', size: 'medium' }}>
            MORE INFO
          </Link>
        </div>
      )}
    </div>
  );
};
