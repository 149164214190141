import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type QuestionnaireTemplatesByIdQueryVariables = Types.Exact<{
  xTenantId: Types.Scalars['ID']['input'];
  questionnaireTemplatesByIdId: Types.Scalars['ID']['input'];
}>;


export type QuestionnaireTemplatesByIdQuery = { __typename?: 'Query', questionnaireTemplatesById: { __typename?: 'QuestionnaireTemplatesValues', name?: string | null, questions?: Array<{ __typename?: 'Questions', isOptional?: boolean | null, text?: string | null, type?: string | null, answerChoices?: Array<{ __typename?: 'AnswerChoices', id?: string | null, text?: string | null } | null> | null } | null> | null } };


export const QuestionnaireTemplatesByIdDocument = gql`
    query QuestionnaireTemplatesById($xTenantId: ID!, $questionnaireTemplatesByIdId: ID!) {
  questionnaireTemplatesById(
    xTenantID: $xTenantId
    id: $questionnaireTemplatesByIdId
  ) {
    questions {
      answerChoices {
        id
        text
      }
      isOptional
      text
      type
    }
    name
  }
}
    `;

/**
 * __useQuestionnaireTemplatesByIdQuery__
 *
 * To run a query within a React component, call `useQuestionnaireTemplatesByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnaireTemplatesByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnaireTemplatesByIdQuery({
 *   variables: {
 *      xTenantId: // value for 'xTenantId'
 *      questionnaireTemplatesByIdId: // value for 'questionnaireTemplatesByIdId'
 *   },
 * });
 */
export function useQuestionnaireTemplatesByIdQuery(baseOptions: Apollo.QueryHookOptions<QuestionnaireTemplatesByIdQuery, QuestionnaireTemplatesByIdQueryVariables> & ({ variables: QuestionnaireTemplatesByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionnaireTemplatesByIdQuery, QuestionnaireTemplatesByIdQueryVariables>(QuestionnaireTemplatesByIdDocument, options);
      }
export function useQuestionnaireTemplatesByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionnaireTemplatesByIdQuery, QuestionnaireTemplatesByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionnaireTemplatesByIdQuery, QuestionnaireTemplatesByIdQueryVariables>(QuestionnaireTemplatesByIdDocument, options);
        }
export function useQuestionnaireTemplatesByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<QuestionnaireTemplatesByIdQuery, QuestionnaireTemplatesByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QuestionnaireTemplatesByIdQuery, QuestionnaireTemplatesByIdQueryVariables>(QuestionnaireTemplatesByIdDocument, options);
        }
export type QuestionnaireTemplatesByIdQueryHookResult = ReturnType<typeof useQuestionnaireTemplatesByIdQuery>;
export type QuestionnaireTemplatesByIdLazyQueryHookResult = ReturnType<typeof useQuestionnaireTemplatesByIdLazyQuery>;
export type QuestionnaireTemplatesByIdSuspenseQueryHookResult = ReturnType<typeof useQuestionnaireTemplatesByIdSuspenseQuery>;
export type QuestionnaireTemplatesByIdQueryResult = Apollo.QueryResult<QuestionnaireTemplatesByIdQuery, QuestionnaireTemplatesByIdQueryVariables>;