import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GamesGetByCategoryQueryVariables = Types.Exact<{
  category?: Types.InputMaybe<Types.Scalars['String']['input']>;
  language: Types.Scalars['String']['input'];
  count: Types.Scalars['Int']['input'];
}>;


export type GamesGetByCategoryQuery = { __typename?: 'Query', categories: Array<{ __typename?: 'categories', games?: Array<{ __typename?: 'categories_game', game_id?: { __typename?: 'game', game_id?: string | null, provider?: { __typename?: 'game_provider', mediator_id?: string | null } | null, translations?: Array<{ __typename?: 'game_translations', display_name?: string | null, slug?: string | null } | null> | null, game_image?: { __typename?: 'directus_files', filename_disk?: string | null, width?: number | null, height?: number | null } | null, tags?: Array<{ __typename?: 'game_game_tags', game_tags_id?: { __typename?: 'game_tags', translations?: Array<{ __typename?: 'game_tags_translations', name?: string | null } | null> | null } | null } | null> | null } | null } | null> | null }> };


export const GamesGetByCategoryDocument = gql`
    query GamesGetByCategory($category: String, $language: String!, $count: Int!) {
  categories(filter: {internal_name: {_eq: $category}}) {
    games(
      filter: {game_id: {status: {_eq: "published"}, provider: {status: {_eq: "published"}}}}
      limit: $count
    ) {
      game_id(filter: null) {
        game_id
        provider(filter: null) {
          mediator_id
        }
        translations(
          filter: {languages_language_code: {language_code: {_eq: $language}}}
        ) {
          display_name
          slug
        }
        game_image(filter: null) {
          filename_disk
          width
          height
        }
        tags {
          game_tags_id(filter: null) {
            translations(
              filter: {languages_language_code: {language_code: {_eq: $language}}}
            ) {
              name
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGamesGetByCategoryQuery__
 *
 * To run a query within a React component, call `useGamesGetByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGamesGetByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGamesGetByCategoryQuery({
 *   variables: {
 *      category: // value for 'category'
 *      language: // value for 'language'
 *      count: // value for 'count'
 *   },
 * });
 */
export function useGamesGetByCategoryQuery(baseOptions: Apollo.QueryHookOptions<GamesGetByCategoryQuery, GamesGetByCategoryQueryVariables> & ({ variables: GamesGetByCategoryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GamesGetByCategoryQuery, GamesGetByCategoryQueryVariables>(GamesGetByCategoryDocument, options);
      }
export function useGamesGetByCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GamesGetByCategoryQuery, GamesGetByCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GamesGetByCategoryQuery, GamesGetByCategoryQueryVariables>(GamesGetByCategoryDocument, options);
        }
export function useGamesGetByCategorySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GamesGetByCategoryQuery, GamesGetByCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GamesGetByCategoryQuery, GamesGetByCategoryQueryVariables>(GamesGetByCategoryDocument, options);
        }
export type GamesGetByCategoryQueryHookResult = ReturnType<typeof useGamesGetByCategoryQuery>;
export type GamesGetByCategoryLazyQueryHookResult = ReturnType<typeof useGamesGetByCategoryLazyQuery>;
export type GamesGetByCategorySuspenseQueryHookResult = ReturnType<typeof useGamesGetByCategorySuspenseQuery>;
export type GamesGetByCategoryQueryResult = Apollo.QueryResult<GamesGetByCategoryQuery, GamesGetByCategoryQueryVariables>;