import React, { FC } from 'react';
import { Button } from '../../shared/Button';
import { Icon } from '../../shared/Icon';

interface AccountLimitsItemControlsProps {
  isRemoveAllowed: boolean;
  isInitialLimit: boolean;
  onEdit: () => void;
  onRemove: () => void;
}

export const AccountLimitsItemControls: FC<AccountLimitsItemControlsProps> = ({
  isInitialLimit,
  isRemoveAllowed,
  onEdit,
  onRemove,
}) => {
  return (
    <div className="account-limits-item-controls">
      {isRemoveAllowed && (
        <Button type="button" variant="text" onClick={onRemove} aria-label="Delete">
          <Icon variant="Delete" />
        </Button>
      )}
      <Button type="button" variant="text" onClick={onEdit} aria-label="Edit">
        <Icon variant={isInitialLimit ? 'Plus' : 'Edit'} />
      </Button>
    </div>
  );
};
