import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ContentPageGetBySlugQueryVariables = Types.Exact<{
  language: Types.Scalars['String']['input'];
  slug: Types.Scalars['String']['input'];
}>;


export type ContentPageGetBySlugQuery = { __typename?: 'Query', documents: Array<{ __typename?: 'documents', internal_name?: string | null, revisions?: Array<{ __typename?: 'documents_document_revisions', document_revisions_id?: { __typename?: 'document_revisions', document_content?: Array<{ __typename?: 'document_revisions_translations', title?: string | null, content?: string | null } | null> | null } | null } | null> | null }> };


export const ContentPageGetBySlugDocument = gql`
    query ContentPageGetBySlug($language: String!, $slug: String!) {
  documents(
    filter: {status: {_eq: "published"}, translations: {slug: {_eq: $slug}, languages_language_code: {language_code: {_eq: $language}}}}
  ) {
    internal_name
    revisions(
      limit: 1
      filter: {document_revisions_id: {status: {_eq: "published"}}}
    ) {
      document_revisions_id(filter: null) {
        document_content(
          filter: {languages_language_code: {language_code: {_eq: $language}}}
        ) {
          title
          content
        }
      }
    }
  }
}
    `;

/**
 * __useContentPageGetBySlugQuery__
 *
 * To run a query within a React component, call `useContentPageGetBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentPageGetBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentPageGetBySlugQuery({
 *   variables: {
 *      language: // value for 'language'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useContentPageGetBySlugQuery(baseOptions: Apollo.QueryHookOptions<ContentPageGetBySlugQuery, ContentPageGetBySlugQueryVariables> & ({ variables: ContentPageGetBySlugQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContentPageGetBySlugQuery, ContentPageGetBySlugQueryVariables>(ContentPageGetBySlugDocument, options);
      }
export function useContentPageGetBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContentPageGetBySlugQuery, ContentPageGetBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContentPageGetBySlugQuery, ContentPageGetBySlugQueryVariables>(ContentPageGetBySlugDocument, options);
        }
export function useContentPageGetBySlugSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ContentPageGetBySlugQuery, ContentPageGetBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ContentPageGetBySlugQuery, ContentPageGetBySlugQueryVariables>(ContentPageGetBySlugDocument, options);
        }
export type ContentPageGetBySlugQueryHookResult = ReturnType<typeof useContentPageGetBySlugQuery>;
export type ContentPageGetBySlugLazyQueryHookResult = ReturnType<typeof useContentPageGetBySlugLazyQuery>;
export type ContentPageGetBySlugSuspenseQueryHookResult = ReturnType<typeof useContentPageGetBySlugSuspenseQuery>;
export type ContentPageGetBySlugQueryResult = Apollo.QueryResult<ContentPageGetBySlugQuery, ContentPageGetBySlugQueryVariables>;