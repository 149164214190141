import React, { FunctionComponent, useRef } from 'react';
import { joinStrings } from '../../utils/string';
import './HeroBanner.scss';

export type HeroBannerProps = {
  /**
   * Path to background image
   */
  imgPath: string;
  /**
   * Alt tag to be used for background image
   */
  imgAlt: string;
  /**
   * Optional.
   * Text to overlay background image.
   * Background gradient will also be rendered when titleText is not undefined
   */
  titleText?: string;
  /**
   * Optional custom className.
   */
  className?: string;
  children?: React.ReactNode;
};

/**
 * Simple Hero Banner component.
 *
 * ### Example Usage
 *
 * ```tsx
 * <HeroBanner
 *  imgPath='https://www.primeapi.com/cdn/gameRes/rect/500/Starburst.jpg'
 *  imgAlt='Starburst Slot Game'
 *  titleText='Starburst'
 * />
 * ```
 */
export const HeroBanner: FunctionComponent<HeroBannerProps> = ({ titleText, imgPath, imgAlt, className, children }) => {
  const ref = useRef(null);
  // const isVisible = useElementOnScreen(ref); Left for future optimization

  return (
    <div
      className={joinStrings(['hero-banner', className])}
      role="banner"
      aria-label={imgAlt}
      style={{ backgroundImage: `url("${imgPath}")` }}
      ref={ref}
    >
      {titleText && (
        <div className="hero-banner__content-container">
          <div className="hero-banner__title">{titleText}</div>
        </div>
      )}
      {children}
    </div>
  );
};

HeroBanner.displayName = 'HeroBanner';
