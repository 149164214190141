/* eslint-disable @typescript-eslint/ban-ts-comment */
import packageCountries from './countries.json';

/**
 * An export of the countries package with updated values
 *
 * This removes some telephone codes which are not Kosovo specific.
 * DO, PR and KZ still have multiple codes but these seem correct.
 */

const countryChanges = {
  XK: {
    phone: '383', // removes 377(Monaco), 381(Serbia), 386(Slovenia)
  },
};

export const countries = Object.keys(countryChanges).reduce(
  (newCountries, key) => {
    return {
      ...newCountries,
      ...{
        [key]: {
          // @ts-ignore
          ...newCountries[key],
          // @ts-ignore
          ...countryChanges[key],
        },
      },
    };
  },
  { ...packageCountries }
);
