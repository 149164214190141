import React, { FunctionComponent } from 'react';
import { joinStrings } from '../../../utils/string';
import './Chip.scss';

export type ChipVariant = 'tag' | 'rounded';

export type ChipProps = {
  /**
   * Variant determines the Chip style.
   */
  variant?: ChipVariant;
  /**
   * Content to display inside the chip
   */
  children: React.ReactNode;
  /**
   * Optional onClick callback to make Chip behave like button.  Button role will be applied.
   */
  onClick?: (e: React.MouseEvent) => void;
} & React.ComponentPropsWithoutRef<'div'>;

/**
 * Chip Component
 */
export const Chip: FunctionComponent<ChipProps> = ({ variant = 'tag', children, onClick, ...props }) => {
  return (
    <div
      {...props}
      className={joinStrings([
        'chip',
        `chip--${variant}`,
        onClick && 'chip--clickable',
        onClick && `chip--clickable-${variant}`,
        props.className,
      ])}
      {...(onClick ? { role: 'button', onClick } : {})}
    >
      {children}
    </div>
  );
};
