import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetRealityCheckConfigsQueryVariables = Types.Exact<{
  xTenantId: Types.Scalars['ID']['input'];
  userProfileId: Types.Scalars['ID']['input'];
}>;


export type GetRealityCheckConfigsQuery = { __typename?: 'Query', getRealityCheckConfigs: { __typename?: 'RealityCheckConfigsResponse', data?: Array<{ __typename?: 'RealityCheckConfigSuccess', interval?: string | null, mediatorType?: string | null, type?: string | null }> | null } };


export const GetRealityCheckConfigsDocument = gql`
    query GetRealityCheckConfigs($xTenantId: ID!, $userProfileId: ID!) {
  getRealityCheckConfigs(xTenantID: $xTenantId, userProfileId: $userProfileId) {
    data {
      interval
      mediatorType
      type
    }
  }
}
    `;

/**
 * __useGetRealityCheckConfigsQuery__
 *
 * To run a query within a React component, call `useGetRealityCheckConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRealityCheckConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRealityCheckConfigsQuery({
 *   variables: {
 *      xTenantId: // value for 'xTenantId'
 *      userProfileId: // value for 'userProfileId'
 *   },
 * });
 */
export function useGetRealityCheckConfigsQuery(baseOptions: Apollo.QueryHookOptions<GetRealityCheckConfigsQuery, GetRealityCheckConfigsQueryVariables> & ({ variables: GetRealityCheckConfigsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRealityCheckConfigsQuery, GetRealityCheckConfigsQueryVariables>(GetRealityCheckConfigsDocument, options);
      }
export function useGetRealityCheckConfigsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRealityCheckConfigsQuery, GetRealityCheckConfigsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRealityCheckConfigsQuery, GetRealityCheckConfigsQueryVariables>(GetRealityCheckConfigsDocument, options);
        }
export function useGetRealityCheckConfigsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetRealityCheckConfigsQuery, GetRealityCheckConfigsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetRealityCheckConfigsQuery, GetRealityCheckConfigsQueryVariables>(GetRealityCheckConfigsDocument, options);
        }
export type GetRealityCheckConfigsQueryHookResult = ReturnType<typeof useGetRealityCheckConfigsQuery>;
export type GetRealityCheckConfigsLazyQueryHookResult = ReturnType<typeof useGetRealityCheckConfigsLazyQuery>;
export type GetRealityCheckConfigsSuspenseQueryHookResult = ReturnType<typeof useGetRealityCheckConfigsSuspenseQuery>;
export type GetRealityCheckConfigsQueryResult = Apollo.QueryResult<GetRealityCheckConfigsQuery, GetRealityCheckConfigsQueryVariables>;