import React, { FunctionComponent, useLayoutEffect, useRef, useState } from 'react';
import { Button, ButtonProps } from '../Button';
import { joinStrings } from '../../../utils/string';
import './ContentPreview.scss';

export type ContentPreviewProps = {
  /**
   * Set the max height in px of the preview.  Defaults to 200.
   */
  previewMaxHeight?: number;
  /**
   * Optional button props to override default setup
   */
  buttonProps?: Partial<Omit<ButtonProps, 'onClick'>>;
  children?: React.ReactNode;
  /**
   * Optional prop when component uses on promo page
   */
  withinPromotion?: boolean;
  /**
   * Optional prop to get expanded state index
   */
  expandedIndex?: number | null;
  defaultShowAll?: boolean;
  showLessButton?: boolean;
};

/**
 * Content preview component will show a limited amount of the child content.
 *
 * Implemented as a controlled component so that extra data can be fetched asynchronously if needed.
 */
export const ContentPreview: FunctionComponent<ContentPreviewProps> = ({
  children,
  previewMaxHeight = 200,
  buttonProps,
  withinPromotion = false,
  expandedIndex,
  defaultShowAll = false,
  showLessButton = true,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [buttonsVisible, setButtonsVisible] = useState(false);
  const [showContent, setShowContent] = useState(defaultShowAll);

  const onHide = showLessButton ? (): void => setShowContent(false) : undefined;
  const onShow = (): void => setShowContent(true);

  useLayoutEffect(() => {
    /* istanbul ignore if */
    if (ref.current === null) return;

    setButtonsVisible(ref.current.clientHeight > previewMaxHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, showContent, expandedIndex]);

  return (
    <div className="content-preview">
      <div
        className={joinStrings([
          'content-preview__content',
          buttonsVisible && !showContent && 'content-preview__content--preview',
        ])}
        style={{ maxHeight: showContent ? 'none' : `${previewMaxHeight}px` }}
      >
        <div data-testid="content-container" ref={ref}>
          {children}
        </div>
      </div>
      {buttonsVisible && (
        <div className="content-preview__buttons">
          {!showContent && (
            <Button className="content-preview__button" type="button" variant="text" onClick={onShow} {...buttonProps}>
              {withinPromotion ? 'Show More' : 'View More'}
            </Button>
          )}

          {onHide && showContent && (
            <Button className="content-preview__button" type="button" variant="text" onClick={onHide} {...buttonProps}>
              {withinPromotion ? 'Show Less' : 'View Less'}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
