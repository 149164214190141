import React, { Children, FunctionComponent, useEffect, useState } from 'react';
import noop from 'lodash/noop';
import { useSpring, animated } from 'react-spring';
import { CookiesDetailsItem } from './CookieDetailsItem';
import {
  ConsentEntry,
  ConsentEntryWithVendors,
  ConsentSettings,
  ConsentType,
  ConsentVendor,
  ConsentVendorCookie,
} from '../types';
import { getApexDomain } from '../../../../utils/extract-apex-domain';
import './CookieDetails.scss';

const dataByConsentType = {
  [ConsentType.STRICTLY_NECESSARY]: {
    title: 'Strictly Necessary Cookies',
  },
  [ConsentType.FUNCTIONAL]: {
    title: 'Functionality Cookies',
  },
  [ConsentType.PERFORMANCE]: {
    title: 'Performance Cookies',
  },
  [ConsentType.TARGETING]: {
    title: 'Third Party Marketing / Targeting Cookies',
  },
};

export type CookiesDetailsProps = {
  /**
   * List of cookies
   */
  data?: ConsentSettings;
  /**
   * Active category name
   */
  activeCategory: string;
  /**
   * Account page wrapper title setter
   */
  setCookieDetailedPageTitle?: (title: string | undefined) => void;
};

/**
 * Cookie Details component to render list of cookies
 */
export const CookiesDetails: FunctionComponent<CookiesDetailsProps> = ({
  data,
  activeCategory,
  setCookieDetailedPageTitle = noop,
}) => {
  const [cookies, setCookies] = useState<ConsentEntryWithVendors>();

  const animationProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
  });

  const findCategory = (data: ConsentEntryWithVendors[]): ConsentEntryWithVendors | undefined =>
    data.find((item: ConsentEntryWithVendors) => activeCategory === item.type);

  const getTitle = (data: ConsentEntry | undefined): string =>
    data?.type && dataByConsentType[data.type] ? dataByConsentType[data.type].title : 'Default title';

  useEffect(() => {
    if (data) {
      const currentCategory = findCategory(data.settings);

      setCookieDetailedPageTitle(getTitle(currentCategory));

      setCookies(currentCategory);
    }

    return () => {
      setCookieDetailedPageTitle(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <animated.div style={animationProps}>
      <div className="cookie-details">
        {Children.toArray(
          cookies?.vendors.map((item: ConsentVendor) =>
            item.cookies.map((elem: ConsentVendorCookie) => (
              <CookiesDetailsItem
                details={{
                  name: elem.name,
                  domain: elem.isFirstPartyApex
                    ? getApexDomain(window.location.host)
                    : elem.isFirstParty
                      ? window.location.host
                      : elem.domain,
                  duration: elem.duration,
                  description: item.description,
                }}
              />
            ))
          )
        )}
      </div>
    </animated.div>
  );
};
