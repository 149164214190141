import React, { ComponentPropsWithoutRef, FunctionComponent } from 'react';
import { Heading, HeadingLevel } from '../../Heading';
import { joinStrings } from '../../../../utils/string';
import './styles';

export type ModalHeaderProps = {
  /**
   * Required. Modal header content
   */
  heading: string;
  /**
   * Optional, defaults to 2. Which heading tag the heading should be rendered within.
   */
  headingLevel?: HeadingLevel;
  /**
   * Optional. Which heading level style should be applied to heading.
   */
  styleHeadingAs?: HeadingLevel;
  /**
   * Optional content that can be rendered below header
   */
  subTitle?: React.ReactNode;
  /**
   * Optional Icon that can be rendered inside header
   */
  feedbackIcon?: React.ReactNode;
} & ComponentPropsWithoutRef<'div'>;

/**
 * A component used to render modal header content within a parent <Modal> component.
 */
export const ModalHeader: FunctionComponent<ModalHeaderProps> = ({
  heading,
  headingLevel = 2,
  styleHeadingAs,
  subTitle,
  feedbackIcon,
  className,
  ...props
}) => {
  return (
    <header {...props} className={joinStrings(['modal-header', className])}>
      <Heading noMargin level={headingLevel} styleAs={styleHeadingAs || headingLevel} className="modal-header__heading">
        {heading}
        {feedbackIcon && feedbackIcon}
      </Heading>
      {subTitle && subTitle}
    </header>
  );
};
