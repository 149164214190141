import Downshift from 'downshift';
import noop from 'lodash/noop';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { InputFeedbackIcon } from '../../InputFeedbackIcon';
import { findSelectImage, SelectOption, SelectProps } from '../Select.shared';
import { joinValidationMessageIDs } from '../../../../utils/form';
import { joinStrings } from '../../../../utils/string';
import { Icon } from '../../Icon';
import './SelectNonNative.scss';

export const SelectNonNative: FunctionComponent<SelectProps> = ({
  validationMessages,
  validationState,
  options,
  value,
  defaultValue,
  placeholder,
  hasSearchInput,
  showFeedbackIcon,
  theme,
  placement,
  onChange = noop,
  onFocus = noop,
  disabled,
  reducedHeight,
  showSelectIcon,
  inputValue = '',
  phoneInputRef,
  setInputValue = noop,
  ...props
}) => {
  const divRef = useRef<HTMLDivElement>(null);
  const ulRef = useRef<HTMLUListElement>(null);
  const liRef = useRef<HTMLLIElement>(null);

  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
  const [height, setHeight] = useState<number>(0);
  const [activeItemIndex, setActiveItemIndex] = useState<number>(0);
  const [selectedItem, setSelectedItem] = useState<string | undefined>(value || defaultValue);
  const [selectItemHeight, setSelectItemHeight] = useState<number>(0);
  const [upThreshold, setUpThreshold] = useState<number>(0);
  const [downThreshold, setDownThreshold] = useState<number>(0);
  const [filteredOptions, setFilteredOptions] = useState<SelectOption[]>(options);

  const ariaDescribedBy = joinValidationMessageIDs(validationMessages, props['aria-describedby']);

  const [selectImage, setSelectImage] = useState<string | undefined>(findSelectImage(filteredOptions, value as string));

  useEffect(() => {
    setInputValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (divRef.current) {
      setHeight(divRef.current.getBoundingClientRect().height);
    }

    // Show all options on select bar open
    setFilteredOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectOpen]);

  useEffect(() => {
    const phoneInputCurrent = phoneInputRef?.current;

    // Set focus to the phone input element after country code is selected
    return () => phoneInputCurrent?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  const backgroundImage = (): { background?: string } => {
    return selectImage
      ? {
          background: `url(${selectImage}) no-repeat left 0.7em center`,
        }
      : {};
  };

  const findInOptions = (value: string, isStrictComparison = false): SelectOption[] =>
    options.filter((option) => {
      const searchOptionValue = (option.search || option.value).toLocaleLowerCase();
      const valueLowerCase = value.toLowerCase();

      return isStrictComparison
        ? option.value === valueLowerCase
        : searchOptionValue.match(new RegExp(`\\b${valueLowerCase.replace('+', '')}`));
    });

  const toggleOpen = (): void => setIsSelectOpen(!isSelectOpen);

  const onItemSelect = ({ value }: SelectOption): void => {
    setSelectImage(findSelectImage(filteredOptions, value));
    setInputValue(value);
    setSelectedItem(value);
    onChange(value);
    toggleOpen();
  };

  const onInputChange = (e: React.ChangeEvent | React.KeyboardEvent | React.MouseEvent<HTMLInputElement>): void => {
    const { value } = e.target as HTMLTextAreaElement;
    const foundInOptions = findInOptions(value);

    setDownThreshold(0);
    setUpThreshold(0);

    setInputValue(value);
    setFilteredOptions(value ? foundInOptions : options);

    if (foundInOptions.length) {
      setFilteredOptions(foundInOptions);
      setIsSelectOpen(true);
    } else {
      setFilteredOptions(options);
      setIsSelectOpen(false);
    }

    // Revert initial scroll position in a case of scrolling
    ulRef?.current?.scrollTo(0, 0);
  };

  const onInputBlur = (): void => setInputValue(selectedItem);

  const onInputClick = (e: React.MouseEvent<HTMLInputElement>): void => {
    props.onClick && props.onClick(e);
    onInputChange(e);
    toggleOpen();
  };

  const onInputFocus = (e: React.FocusEvent<HTMLInputElement> | React.MouseEvent<HTMLInputElement>): void => {
    e.currentTarget.select();
    onFocus(e);
  };

  const getListHTMLProperties = (): { ulRefCurrent: HTMLUListElement | null; scrollTop: number } => {
    const ulRefCurrent = ulRef?.current;
    const liRefCurrent = liRef?.current;

    const scrollTop = ulRefCurrent?.scrollTop || 0;

    const itemMarginTop = parseInt(liRefCurrent ? getComputedStyle(liRefCurrent).getPropertyValue('margin-top') : '');

    const fullItemHeight = itemMarginTop * 2 + Number(liRefCurrent?.offsetHeight);

    !selectItemHeight && setSelectItemHeight(fullItemHeight);

    return {
      ulRefCurrent,
      scrollTop,
    };
  };

  const onInputKeyDown = (e: React.KeyboardEvent): void => {
    const { ulRefCurrent, scrollTop } = getListHTMLProperties();
    const thresholdRange = 2;

    switch (e.key.toLowerCase()) {
      case 'enter':
        e.preventDefault();

        if (isSelectOpen) {
          onItemSelect(filteredOptions[activeItemIndex]);
        } else {
          setActiveItemIndex(filteredOptions.findIndex((x) => x.value === selectedItem));
          toggleOpen();
        }

        break;
      case 'arrowdown':
        if (isSelectOpen) {
          // prevents screen from shift on button press
          e.preventDefault();
          const tmpIndex = filteredOptions[activeItemIndex + 1]?.disabled ? 2 : 1;

          const index =
            activeItemIndex + tmpIndex > filteredOptions.length - 1 ? activeItemIndex : activeItemIndex + tmpIndex;

          setActiveItemIndex(index);

          // Scroll by arrowdown
          downThreshold >= thresholdRange
            ? ulRefCurrent?.scrollTo(0, scrollTop + selectItemHeight)
            : setDownThreshold(downThreshold + 1);
          setUpThreshold(0);
        }

        break;
      case 'arrowup':
        if (isSelectOpen) {
          // prevents screen from shift on button press
          e.preventDefault();
          const tmpIndex = filteredOptions[activeItemIndex - 1]?.disabled ? 2 : 1;

          const index = activeItemIndex - tmpIndex < 0 ? activeItemIndex : activeItemIndex - tmpIndex;

          setActiveItemIndex(index);

          // Scroll by arrowup
          upThreshold >= thresholdRange
            ? ulRefCurrent?.scrollTo(0, scrollTop - selectItemHeight)
            : setUpThreshold(upThreshold + 1);
          setDownThreshold(0);
        }

        break;
      case 'tab':
        if (isSelectOpen) {
          // prevents tab focus from jumping if dropdown is open
          e.preventDefault();
          toggleOpen();
        }

        break;
      case 'backspace':
        if (isSelectOpen) {
          onInputChange(e);
        }

        break;
    }
  };

  const getValue = (value: string | undefined): string => {
    return filteredOptions.find((x) => x.value === value)?.label || '';
  };

  const dropdownImage = (src: string): { background: string; backgroundSize: string } => {
    return {
      background: `url(${src}) no-repeat center center`,
      backgroundSize: 'cover',
    };
  };

  const className = `${joinStrings([
    'select-non-native',
    validationState && `select-non-native--${validationState}`,
    disabled && 'select-non-native--disabled',
    theme === 'dark' && 'select-non-native--dark',
  ])}`;

  return (
    <div data-testid="select-non-native" body-scroll-lock-ignore="true" className={className}>
      <Downshift
        onOuterClick={(): void => toggleOpen()}
        selectedItem={getValue(selectedItem)}
        isOpen={isSelectOpen}
        inputValue={inputValue}
      >
        {({ isOpen, selectedItem, getInputProps, inputValue }): JSX.Element => {
          return (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <div
              {...getInputProps({ 'aria-labelledby': undefined })}
              aria-describedby={ariaDescribedBy}
              className="select-non-native__container"
              style={backgroundImage()}
            >
              <input
                aria-disabled={disabled}
                disabled={disabled}
                aria-label="select"
                data-testid="select-non-native-button"
                className={joinStrings([
                  'select-non-native__input',
                  selectedItem && 'select-non-native__input--has-value',
                  selectImage && 'select-non-native__input--has-image',
                  placeholder && !value && 'select-non-native__input--is-placeholder',
                  reducedHeight && 'select-non-native__input--reduced-height',
                ])}
                value={inputValue || defaultValue || placeholder || ''}
                onKeyDown={onInputKeyDown}
                onClick={onInputClick}
                onFocus={onInputFocus}
                onChange={onInputChange}
                onBlur={onInputBlur}
                readOnly={!hasSearchInput}
              />
              <div className={joinStrings(['select-non-native__chevron', isOpen && 'inverted'])}>
                <Icon variant="SelectArrowIcon" onClick={(): void => setIsSelectOpen(!isSelectOpen)} />
              </div>
              {showFeedbackIcon && !disabled && (
                <InputFeedbackIcon rightPosition="var(--size-32)" state={validationState} />
              )}

              {isOpen && filteredOptions && (
                <div
                  ref={divRef}
                  className={joinStrings([
                    'select-non-native__dropdown',
                    `select-non-native__dropdown--${placement}`,
                    reducedHeight && `select-non-native__dropdown--${placement}--reduced-height`,
                    theme === 'dark' && 'select-non-native__dropdown--dark',
                  ])}
                  data-testid="select-non-native-dropdown"
                  style={placement === 'top' ? { top: `-${height + 32}px` } : {}}
                >
                  <ul ref={ulRef} className="select-non-native__ul">
                    {placeholder && (
                      <li
                        className={joinStrings([
                          'select-non-native__li',
                          theme === 'dark' && 'select-non-native__ul--dark',
                        ])}
                      >
                        <span className="select-non-native__span">{placeholder}</span>
                      </li>
                    )}
                    {!filteredOptions.length && (
                      <li
                        className={joinStrings([
                          'select-non-native__li',
                          theme === 'dark' && 'select-non-native__li--dark',
                        ])}
                      >
                        <span className="select-non-native__span">{props.emptyListText}</span>
                      </li>
                    )}
                    {filteredOptions.map((option, index) => (
                      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                      <li
                        role="option"
                        className={joinStrings([
                          'select-non-native__li',
                          index === activeItemIndex && `select-non-native__li--active-${theme}`,
                          theme === 'dark' && 'select-non-native__li--dark',
                          option.disabled && 'select-non-native__li--disabled',
                          reducedHeight && 'select-non-native__li--reduced-height',
                        ])}
                        aria-selected={index === activeItemIndex}
                        aria-disabled={option.disabled}
                        key={option.id || option.label}
                        ref={liRef}
                        onMouseEnter={(): void => {
                          !option.disabled && setActiveItemIndex(index);
                        }}
                        onClick={(): void => {
                          !option.disabled && onItemSelect(option);
                        }}
                        onBlur={props.onBlur as (e: React.FocusEvent) => void}
                      >
                        {option.imageSrc && (
                          <div
                            className="select-non-native__img"
                            role="img"
                            aria-label={option.imageSrc}
                            style={dropdownImage(option.imageSrc)}
                          />
                        )}
                        {option.customDropDownItemRender ? (
                          option.customDropDownItemRender(option.label)
                        ) : showSelectIcon && selectedItem === option.label ? (
                          <div className="select-non-native__li--selected-item">
                            <span className="select-non-native__span">{option.label}</span>
                            <Icon variant="Check" />
                          </div>
                        ) : (
                          <span className="select-non-native__span">{option.label}</span>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          );
        }}
      </Downshift>
    </div>
  );
};

SelectNonNative.displayName = 'SelectNonNative';
