import { createContext } from 'react';

interface ModalContextConfig {
  /**
   * Can be used to set a custom overflow target element for Modal overlay if needed.
   */
  setOverflowTargetElement?: (el: HTMLElement) => void;
}

export const ModalContext = createContext<ModalContextConfig>({});
