import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SubmitUserAnswersToAssignedQuestionnaireMutationVariables = Types.Exact<{
  xTenantId: Types.Scalars['ID']['input'];
  questionnaireId: Types.Scalars['ID']['input'];
  userProfileId: Types.Scalars['ID']['input'];
  submitUserAnswersToAssignedQuestionnaireUserInfo?: Types.InputMaybe<Types.SubmitUserAnswersToAssignedQuestionnaireUserInput>;
}>;


export type SubmitUserAnswersToAssignedQuestionnaireMutation = { __typename?: 'Mutation', submitUserAnswersToAssignedQuestionnaire: { __typename?: 'SubmitUserAnswersToAssignedQuestionnaireUserResponse', error?: Array<{ __typename?: 'FieldError', code: string, description?: string | null, fieldName: string, kind: Array<string | null>, pattern?: string | null } | { __typename?: 'GenericError', code: string }> | null } };


export const SubmitUserAnswersToAssignedQuestionnaireDocument = gql`
    mutation SubmitUserAnswersToAssignedQuestionnaire($xTenantId: ID!, $questionnaireId: ID!, $userProfileId: ID!, $submitUserAnswersToAssignedQuestionnaireUserInfo: SubmitUserAnswersToAssignedQuestionnaireUserInput) {
  submitUserAnswersToAssignedQuestionnaire(
    xTenantID: $xTenantId
    questionnaireId: $questionnaireId
    userProfileId: $userProfileId
    submitUserAnswersToAssignedQuestionnaireUserInfo: $submitUserAnswersToAssignedQuestionnaireUserInfo
  ) {
    error {
      ... on FieldError {
        code
        description
        fieldName
        kind
        pattern
      }
      ... on GenericError {
        code
      }
    }
  }
}
    `;
export type SubmitUserAnswersToAssignedQuestionnaireMutationFn = Apollo.MutationFunction<SubmitUserAnswersToAssignedQuestionnaireMutation, SubmitUserAnswersToAssignedQuestionnaireMutationVariables>;

/**
 * __useSubmitUserAnswersToAssignedQuestionnaireMutation__
 *
 * To run a mutation, you first call `useSubmitUserAnswersToAssignedQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitUserAnswersToAssignedQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitUserAnswersToAssignedQuestionnaireMutation, { data, loading, error }] = useSubmitUserAnswersToAssignedQuestionnaireMutation({
 *   variables: {
 *      xTenantId: // value for 'xTenantId'
 *      questionnaireId: // value for 'questionnaireId'
 *      userProfileId: // value for 'userProfileId'
 *      submitUserAnswersToAssignedQuestionnaireUserInfo: // value for 'submitUserAnswersToAssignedQuestionnaireUserInfo'
 *   },
 * });
 */
export function useSubmitUserAnswersToAssignedQuestionnaireMutation(baseOptions?: Apollo.MutationHookOptions<SubmitUserAnswersToAssignedQuestionnaireMutation, SubmitUserAnswersToAssignedQuestionnaireMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitUserAnswersToAssignedQuestionnaireMutation, SubmitUserAnswersToAssignedQuestionnaireMutationVariables>(SubmitUserAnswersToAssignedQuestionnaireDocument, options);
      }
export type SubmitUserAnswersToAssignedQuestionnaireMutationHookResult = ReturnType<typeof useSubmitUserAnswersToAssignedQuestionnaireMutation>;
export type SubmitUserAnswersToAssignedQuestionnaireMutationResult = Apollo.MutationResult<SubmitUserAnswersToAssignedQuestionnaireMutation>;
export type SubmitUserAnswersToAssignedQuestionnaireMutationOptions = Apollo.BaseMutationOptions<SubmitUserAnswersToAssignedQuestionnaireMutation, SubmitUserAnswersToAssignedQuestionnaireMutationVariables>;