import { BudgetLimitsTransactionType } from '../../../types.__generated__';

export enum ModalAction {
  Edit = 'edit',
  Remove = 'remove',
}

/**
 * Enum of player periods
 */
export enum LimitPeriodsEnum {
  daily = 'P1D',
  weekly = 'P7D',
  monthly = 'P30D',
}

export type ActiveLimitType = BudgetLimitsTransactionType.Deposit | BudgetLimitsTransactionType.Loss;
