import React, { FC } from 'react';
import { Heading } from '../Heading';
import { Spinner } from '../Spinner';
import { formatCurrency } from '../../../utils/wallets';

interface IBalanceProps {
  balance: number;
  className: string;
  isLoading: boolean;
  isHeading?: boolean;
}

export const Balance: FC<IBalanceProps> = ({ isHeading = false, balance, className, isLoading }) => {
  const Component: FC = () =>
    isHeading ? (
      <Heading noMargin level={5} className={className}>
        {formatCurrency(balance, 'auto', 'auto')}
      </Heading>
    ) : (
      <span className={className}>{formatCurrency(balance, 'auto', 'auto')}</span>
    );

  return isLoading ? (
    <div className="spinner-wrapper">
      <Spinner />
    </div>
  ) : (
    <Component />
  );
};
