import React, { ComponentPropsWithoutRef, FunctionComponent } from 'react';
import { joinStrings } from '../../../utils/string';
import './Heading.scss';

export type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;

export type HeadingProps = {
  /**
   * Required. Which heading level the element should be, e.g. 3 will render <h3>My Heading</h3>
   */
  level: HeadingLevel;
  /**
   * Optionally remove the margin on top and bottom of heading. Defaults to false
   */
  noMargin?: boolean;
  /**
   * Optionally change which heading level style to apply to the heading.
   * This is useful in situations where you might want a heading to be <h1>
   * semantically, but need it to have a smaller font-size.
   */
  styleAs?: HeadingLevel;
} & ComponentPropsWithoutRef<'h1'>;

/**
 * A component used to render a heading element with consistent styling applied.
 *
 * ### Example
 *
 * ```tsx
 *   <Heading level={1}>Casino Games</Heading>
 * ```
 */
export const Heading: FunctionComponent<HeadingProps> = ({
  level,
  className,
  noMargin = false,
  styleAs,
  children,
  ...props
}) => {
  const HeaderTag = `h${level}` as 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

  return (
    <HeaderTag
      {...props}
      className={joinStrings(['heading', `heading--${styleAs || level}`, noMargin && `heading--no-margin`, className])}
    >
      {children}
    </HeaderTag>
  );
};
