import React, { FC } from 'react';
import { BudgetLimitsTransactionResponse } from '../../../../types.__generated__';
import { Paragraph } from '../../../shared/Paragraph';
import { formatCurrency } from '../../../../utils/wallets';
import './styles';

interface AccountLimitsItemStatusProps {
  label: string;
  activeItem?: BudgetLimitsTransactionResponse;
}

export const AccountLimitsItemStatus: FC<AccountLimitsItemStatusProps> = ({ label, activeItem }) => {
  return (
    <div className="account-limits-item-status">
      <div className="account-limits-item-status__container">
        <Paragraph className="account-limits-item-status__title" size="xs" noMargin highlight>
          {label}
        </Paragraph>
      </div>
      {activeItem ? (
        <>
          <hr />
          <Paragraph className="account-limits-item-status__amount" size="xs" noMargin highlight>
            {activeItem?.amount ? `Amount: ${formatCurrency(activeItem.amount, 'auto', 'auto')}` : 'No Limit'}
          </Paragraph>
        </>
      ) : null}
    </div>
  );
};
