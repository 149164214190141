/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useFetch } from '../../../../hooks/fetch';
import { useSnackbar } from '../../../../hooks/snackbar';
import { joinStrings } from '../../../../utils/string';
import { Button } from '../../../shared/Button';
import { Checkbox } from '../../../shared/Checkbox';
import { Icon } from '../../../shared/Icon';
import { Paragraph } from '../../../shared/Paragraph';
import { limitsMock } from '../../limitsMock';
import { PlayerLimitsFields } from '../../types';
import './AutoWithdrawalCard.scss';

export type AutoWithdrawalCardProps = {
  /**
   * Callback for when the limit value has been updated
   */
  onUpdate: (newValue: string | number | null) => void;
  /**
   * Callback when the limit is turned on, to show the edit view
   */
  onEdit: () => void;
  /**
   * The current limit value
   */
  limitValue?: null | number;
  /**
   * Disable interactions in the view
   */
  disabled?: boolean;
  /**
   * Optional iconPath to display icon set in AEM.
   */
  iconPath?: string | null;
};

export const AutoWithdrawalCard: FunctionComponent<AutoWithdrawalCardProps> = ({
  onUpdate,
  onEdit,
  limitValue = null,
  disabled,
  iconPath,
}) => {
  const limitName = 'autoWithdrawalThreshold';

  const { addSnack } = useSnackbar();

  const {
    fetchData: fetchLimits,
    data,
    isDataInitialised,
  } = useFetch({
    dataRequest: async () => {
      return limitsMock;
    },
  });

  const [limitData, setLimitData] = useState<PlayerLimitsFields | null>(null);
  const [currentValue, setCurrentValue] = useState<Nullable<number>>(limitValue);
  const [isProcessing, setProcessing] = useState<boolean>(false);
  const [isPending, setPending] = useState<boolean>(false);

  const isActive = currentValue !== null;
  const isDisabled = disabled || !isDataInitialised || isProcessing;

  async function resetAutoWithdrawalLimit(): Promise<void> {
    setProcessing(true);

    try {
      console.log('setPlayerLimits');
      setCurrentValue(null);
      addSnack({
        type: 'success',
        message: 'Auto-Withdrawal has been turned off.',
        theme: 'dark',
      });

      onUpdate(null);

      setProcessing(false);
    } catch {
      addSnack({
        type: 'error',
        message: 'An error occured turning off Auto-Withdrawal.',
        theme: 'dark',
      });
      setProcessing(false);
    }
  }

  // Fetch limits when the limitValue changes and on load
  useEffect(() => {
    fetchLimits();
    setProcessing(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitValue]);

  // Update state when then fetched data changes
  useEffect(() => {
    // @ts-ignore
    const limitData = data?.[limitName];

    if (limitData) {
      const isPending = !!(limitData?.pendingLimitCoolOffEnd || limitData?.pendingConfirmation);
      const limitActive = limitData?.activeLimit ? limitData?.activeLimit : null;
      const limitPending = limitData?.pendingLimit;
      // If limit is being turned off set limit card an inactive state
      const limitValue = isPending && limitPending === null ? limitPending : limitActive;

      setPending(isPending);
      setLimitData(limitData);
      setCurrentValue(limitValue);
      onUpdate(limitValue);
    }

    setProcessing(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className={joinStrings(['auto-withdrawal-card', isDisabled && 'auto-withdrawal-card--disabled'])}>
      <Checkbox
        data-testid="auto-withdrawal-card__toggle"
        variant="switch"
        name="toggle-auto-withdrawal"
        id="toggle-auto-withdrawal"
        className="auto-withdrawal-card__toggle"
        label="Auto-Withdrawal"
        iconPath={iconPath}
        checked={isActive}
        disabled={isDisabled}
        onChange={(): void => {
          // If a the current value is null, edit the value
          if (currentValue === null) {
            onEdit();
          } else {
            // Else reset the limit
            resetAutoWithdrawalLimit();
          }
        }}
      />

      {isActive ? (
        <Paragraph className="auto-withdrawal-card__info">
          {isPending ? `Current: ${currentValue} (pending ${limitData?.pendingLimit})` : `Current: ${currentValue}`}
          <Button
            data-testid="auto-withdrawal-card__edit-button"
            className="auto-withdrawal-card__edit-button"
            type="button"
            variant="text"
            onClick={onEdit}
            aria-label="Edit"
          >
            <Icon variant="Edit" />
          </Button>
        </Paragraph>
      ) : (
        <Paragraph className="auto-withdrawal-card__info">
          Every spin winning that exceeds this threshold will be automatically proccessed to withdraw.
        </Paragraph>
      )}
    </div>
  );
};
