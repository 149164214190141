export const limitsMock = {
  minDeposit: 10,
  maxDeposit: null,
  dailyDeposit: {
    playerChosenLimit: 4,
    activeLimit: null,
    upperLimit: 5,
    pendingLimit: 34,
    pendingLimitCoolOffEnd: '2021-06-07T10:24:04.45Z',
    remainingAmount: 10,
  },
  weeklyDeposit: {
    playerChosenLimit: null,
    activeLimit: null,
    upperLimit: 2,
    pendingLimit: 45,
    pendingLimitCoolOffEnd: '2021-06-07T10:24:04.45Z',
    remainingAmount: 10,
  },
  monthlyDeposit: {
    playerChosenLimit: 3,
    activeLimit: 11,
    upperLimit: 5,
    pendingLimit: null,
    pendingLimitCoolOffEnd: '2021-06-07T10:24:04.45Z',
    remainingAmount: 10,
  },
  dailyLosses: {
    activeLimit: null,
    pendingLimit: null,
    pendingLimitCoolOffEnd: null,
    remainingAmount: null,
    pendingConfirmation: false,
  },
  monthlyLosses: {
    activeLimit: null,
    pendingLimit: null,
    pendingLimitCoolOffEnd: null,
    remainingAmount: null,
    pendingConfirmation: false,
  },
  weeklyLosses: {
    activeLimit: null,
    pendingConfirmation: false,
    pendingLimit: null,
    pendingLimitCoolOffEnd: null,
    remainingAmount: null,
  },
  minWithdrawal: null,
  maxWithdrawal: null,
  totalDeposit: null,
  depositLimitsConfirmed: false,
  nextDepositLimitIncreaseAvailableOn: null,
  depositLimitsLastIncreasedAt: null,
  remainingDailyWithdrawals: 999,
};
