import React, { Fragment, FunctionComponent } from 'react';
import { IconBaseProps } from '@react-icons/all-files';
import { Icon } from '../Icon';
import { Theme, ValidationState } from '../../../types';
import { joinStrings } from '../../../utils/string';
import './InputFeedbackIcon.scss';

export type InputFeedbackIconProps = {
  /**
   *  Which feedback state to represent. Optional.  Will render nothing by default.
   */
  state?: ValidationState;
  /**
   * Display position. Absolute by default.
   * TODO: Adjust to allow right position to be adjusted.
   */
  position?: 'absolute' | 'relative';
  /**
   * Optional right position for absolute positioning. Defaults to var(--size-12).
   * Should ideally use a size variable from _variables.scss.
   */
  rightPosition?: string;
  /**
   * Optional, defaults to 'light'. If set to 'dark' the icon will be coloured to be placed on a dark background.
   */
  theme?: Theme;
  /**
   * Optional, adding a class to animate the icon
   */
  animationIconClass?: string;
} & Omit<IconBaseProps, 'position'>;

export const FeedbackIcon: FunctionComponent<InputFeedbackIconProps> = ({ state, ...props }) => {
  switch (state) {
    case 'success':
      return <Icon variant="Success" {...props} />;
    case 'error':
      return <Icon variant="Error" {...props} />;
    default:
      return <Icon variant="Warning" {...props} />;
  }
};

export const InputFeedbackIcon: FunctionComponent<InputFeedbackIconProps> = ({
  position = 'absolute',
  rightPosition = 'var(--size-12)',
  theme = 'light',
  animationIconClass,
  ...props
}) => {
  const rightPositionStyle = {
    right: rightPosition,
  };
  const baseClassName = 'input-feedback-icon';
  const className = joinStrings([
    baseClassName,
    animationIconClass,
    `${baseClassName}--${position}`,
    props.state && `${baseClassName}--${props.state}`,
    theme === 'dark' && `${baseClassName}--dark`,
  ]);

  return (
    <Fragment>
      {props.state && (
        <span
          data-testid="feedback-icon"
          className={className}
          style={position === 'absolute' ? rightPositionStyle : {}}
        >
          <FeedbackIcon {...props} />
        </span>
      )}
    </Fragment>
  );
};
