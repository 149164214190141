import { parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { APP_URLS } from '../../consts';
import { useSnackbar } from '../snackbar';
import { formattedTimeDifference } from '../../utils/time';
import { currentGameSession, selectDisabledGamesData, selectGameLaunchTime } from '../../state/game';

export type UseGameSessionOptions = {
  /**
   * Optional, defaults to ':'. Used to separated the hh,mm,ss values in time elapsed
   */
  delimiter?: string;

  /**
   * Optional, defaults to no refresh. How many seconds between data refreshes
   */
  refreshInterval?: number;
};

export type UseGameSessionReturnValue = {
  /**
   * If the session has been returned and is in the state
   */
  isSessionReady: boolean;

  /**
   * Formatted hours, minutes & seconds for time elapsed since the game has been launched.
   */
  gameTimeElapsed?: string;

  /**
   * Formatted hours, minutes & seconds for time elapsed in active compliance session.
   */
  sessionTimeElapsed?: string;

  /**
   * The net position of the current game session (wins - losses)
   */
  netPostion: number;
  /**
   * The function that handles a game start during the mandatory break
   */
  handleGameStartDuringBreak: () => void;
};

/**
 * Retrieve and refresh game session data
 */
export function useGameSession({
  delimiter = ':',
  refreshInterval,
}: UseGameSessionOptions = {}): UseGameSessionReturnValue {
  const navigate = useNavigate();
  const { addSnack } = useSnackbar();
  const gameSession = useSelector(currentGameSession());
  const gameLaunchTime = useSelector(selectGameLaunchTime);
  const { areGamesDisabled } = useSelector(selectDisabledGamesData);

  const [gameTimeElapsed, setGameTimeElapsed] = useState<string>();
  const [sessionTimeElapsed, setSessionTimeElapsed] = useState<string>();
  const [isSessionReady, setSessionReady] = useState<boolean>(false);

  const handleGameStartDuringBreak = (): void => {
    if (areGamesDisabled) {
      addSnack({
        type: 'info',
        message: 'You cannot play games during the mandatory break',
      });
      navigate(APP_URLS.home);
    }
  };

  // Format elapsed times
  useEffect(() => {
    if (!gameLaunchTime && !gameSession?.sessionStart) return;

    const formatTime = (time: string): string => formattedTimeDifference(new Date(), parseISO(time), true, delimiter);

    const updateElapsedTimes = (): void => {
      if (gameLaunchTime) {
        setGameTimeElapsed(formatTime(gameLaunchTime));
      }

      if (gameSession?.sessionStart) {
        setSessionTimeElapsed(formatTime(gameSession.sessionStart));
      }
    };

    const timeout = setInterval(updateElapsedTimes, 1000);

    updateElapsedTimes();

    return () => clearInterval(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameLaunchTime, gameSession?.sessionStart]);

  // Handle data refreshing if required
  useEffect(() => {
    if (!refreshInterval) return;

    const refreshTimer = setInterval(() => {
      console.log('requestGameSessions');
    }, refreshInterval * 1000);

    return () => {
      clearInterval(refreshTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set session ready value
  useEffect(() => {
    const isStateReady = !!gameSession;

    if (isStateReady !== isSessionReady) {
      setSessionReady(isStateReady);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameSession]);

  return {
    isSessionReady,
    gameTimeElapsed,
    sessionTimeElapsed,
    handleGameStartDuringBreak,
    netPostion: gameSession ? gameSession.wins - gameSession.losses : 0,
  };
}
