import React, { FunctionComponent, useState } from 'react';
import { CardCTASection } from './CardCTASection';
import { PromotionAvailableBlock } from '../PromotionAvailableBlock';
import { ParsedPromo } from '../types';
import dompurify from 'dompurify';
import './styles';
import { Heading } from '../../../shared/Heading';
import { Paragraph } from '../../../shared/Paragraph';
import { RouterLink } from '../../../shared/Link';
import { joinStrings, filterSpecialHTMLCharacters, replaceSpecialSymbols } from '../../../../utils/string';
import pandaMoneyImg from '../../../../assets/Games/PandaMoney.png';
import { formatDate } from 'date-fns';
import { PromotionMessagesPrompt } from '../PromotionMessagesPrompt';
import { CardActiveCTASection } from './CardActiveCTASection';

interface PromotionCardProps {
  isActivePromotions?: boolean;
  promo: ParsedPromo;
  withoutMargin?: boolean;
  showCTASection?: boolean;
  showMoreButon?: boolean;
}

export const PromotionCard: FunctionComponent<PromotionCardProps> = ({
  promo,
  withoutMargin,
  showCTASection = true,
  isActivePromotions,
  showMoreButon = true,
}): JSX.Element => {
  const sanitizer = dompurify.sanitize;

  const {
    title,
    description,
    terms,
    imgPath,
    imgAlt,
    promoButtonText,
    pageUrl,
    endDate,
    expireDateLabel,
    wageredAmount,
    wageringRequirementAmount,
  } = promo;

  const dateLabel = expireDateLabel || formatDate(endDate, 'dd/MM/yy');

  const [showPromoPrompt, setShowPromoPrompt] = useState<boolean>(false);

  return (
    <>
      <div
        className={joinStrings([
          'promotion-card',
          withoutMargin && 'promotion-card--specific promotion-card--without-margin',
        ])}
      >
        <div className="promotion-card__banner-block">
          <img src={`${imgPath || pandaMoneyImg}`} alt={imgAlt} />
          {Boolean(dateLabel) && <PromotionAvailableBlock expireDateTag={String(dateLabel)} />}
        </div>
        <div className="promotion-card__info-block">
          <div className="promotion-card__description-block padding-16">
            <Heading className="promotion-card__banner-title" level={6}>
              {title}
            </Heading>
            {Boolean(description) && (
              <>
                <Paragraph
                  className="promotion-card__description"
                  noMargin
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(description),
                  }}
                />
                <Paragraph noMargin className="promotion-card__short-terms">
                  {replaceSpecialSymbols(filterSpecialHTMLCharacters(sanitizer(terms)), true)}{' '}
                  <RouterLink
                    className=""
                    to={{
                      pathname: pageUrl,
                      hash: '#terms',
                    }}
                    aria-label="Terms"
                  >
                    T&C's
                  </RouterLink>
                </Paragraph>
              </>
            )}
          </div>
          {showCTASection &&
            (isActivePromotions ? (
              <CardActiveCTASection
                depositedValue={Number(wageredAmount)}
                remainingValue={Number(wageringRequirementAmount)}
                pageUrl={pageUrl}
                showButton={showMoreButon}
                activatedPromo={promo}
                isActivePromotion={isActivePromotions}
              />
            ) : (
              <CardCTASection
                onActivateBonus={(): void => setShowPromoPrompt(true)}
                buttonText={promoButtonText}
                pageUrl={pageUrl}
              />
            ))}
        </div>
      </div>
      <PromotionMessagesPrompt
        imgMobileAlt=""
        imgMobilePath=""
        show={showPromoPrompt}
        setShow={setShowPromoPrompt}
        paragraphText="Do you really want to claim this promotion?"
        ctaTextSecondary="CANCEL"
        onClose={(): void => setShowPromoPrompt(false)}
        activatedPromo={promo}
      />
    </>
  );
};
