export const welcomeOfferItem = {
  name: 'Welcome Bonus',
  title: 'Welcome Offer - Deposit £20, play with £50',
  promoUrl: 'test-url',
  termsUrl: 'test-url',
  promoCode: 'PROMO_CODE',
  registrationContent: {
    title: 'test-title',
    subtitle: 'test-sub-title',
    promotionImage: 'image-url',
  },
  depositBonus: {
    minimumDeposit: 10,
    maximumBonusAmount: 50,
    bonusPercentage: 100,
  },
  postDepositContent: {
    title: 'Welcome pre deposit offer',
    subtitle: 'Your amazing welcome bonus',
    image: 'test-url',
    description: 'test-description',
    primaryCtaText: 'Achieve more benefits',
    primaryCtaUrl: 'cta-test-url',
    secondaryCtaText: 'Get all promotion coast',
    secondaryCtaUrl: 'cta-test-url',
  },
  terms:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla mattis volutpat ante, eu facilisis enim placerat vel. Suspendisse in molestie felis. Maecenas faucibus nunc ac quam ornare, feugiat scelerisque lectus interdum. Pellentesque blandit eros sit amet ligula semper, sed sagittis velit auctor. Aenean tincidunt orci mollis felis luctus, vitae efficitur justo congue.',
};

export const depositLimitsMock = {
  deposit: {
    limits: {
      player: {
        dailyDeposit: {
          pendingLimitCoolOffEnd: null,
          remainingAmount: 960,
          pendingLimit: null,
          activeLimit: null,
        },
        weeklyDeposit: {
          pendingLimitCoolOffEnd: null,
          remainingAmount: 960,
          pendingLimit: null,
          activeLimit: null,
        },
        monthlyDeposit: {
          pendingLimitCoolOffEnd: null,
          remainingAmount: 960,
          pendingLimit: null,
          activeLimit: null,
        },
      },
    },
  },
  claims: {
    secureClaims: [{ type: 'callNOW' }],
  },
  account: {
    playerProfile: {
      player: {
        email: 'duu@mail.com',
        profile: {
          personal: {
            firstName: 'Dubi',
            lastName: 'Duu',
          },
        },
      },
    },
  },
};
