import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IframePageGetBySlugQueryVariables = Types.Exact<{
  slug: Types.Scalars['String']['input'];
}>;


export type IframePageGetBySlugQuery = { __typename?: 'Query', iframe_pages: Array<{ __typename?: 'iframe_pages', slug?: string | null, title?: string | null, src?: string | null }> };


export const IframePageGetBySlugDocument = gql`
    query IframePageGetBySlug($slug: String!) {
  iframe_pages(filter: {slug: {_eq: $slug}, status: {_eq: "published"}}) {
    slug
    title
    src
  }
}
    `;

/**
 * __useIframePageGetBySlugQuery__
 *
 * To run a query within a React component, call `useIframePageGetBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useIframePageGetBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIframePageGetBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useIframePageGetBySlugQuery(baseOptions: Apollo.QueryHookOptions<IframePageGetBySlugQuery, IframePageGetBySlugQueryVariables> & ({ variables: IframePageGetBySlugQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IframePageGetBySlugQuery, IframePageGetBySlugQueryVariables>(IframePageGetBySlugDocument, options);
      }
export function useIframePageGetBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IframePageGetBySlugQuery, IframePageGetBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IframePageGetBySlugQuery, IframePageGetBySlugQueryVariables>(IframePageGetBySlugDocument, options);
        }
export function useIframePageGetBySlugSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IframePageGetBySlugQuery, IframePageGetBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IframePageGetBySlugQuery, IframePageGetBySlugQueryVariables>(IframePageGetBySlugDocument, options);
        }
export type IframePageGetBySlugQueryHookResult = ReturnType<typeof useIframePageGetBySlugQuery>;
export type IframePageGetBySlugLazyQueryHookResult = ReturnType<typeof useIframePageGetBySlugLazyQuery>;
export type IframePageGetBySlugSuspenseQueryHookResult = ReturnType<typeof useIframePageGetBySlugSuspenseQuery>;
export type IframePageGetBySlugQueryResult = Apollo.QueryResult<IframePageGetBySlugQuery, IframePageGetBySlugQueryVariables>;