export const takeABreakMocks = {
  takeABreakReason: {
    options: [
      { label: 'I spend too much time playing', value: 'I spend too much time playing' },
      { label: 'I spend too much money playing', value: 'I spend too much money playing' },
      { label: 'I do not wish to provide an answer', value: 'I do not wish to provide an answer' },
    ],
    required: true,
  },
  takeABreakPeriod: {
    options: [
      { label: '24 hours', value: '1', unit: 'days' },
      { label: '1 week', value: '1', unit: 'weeks' },
      { label: '4 weeks', value: '4', unit: 'weeks' },
      { label: '6 weeks', value: '6', unit: 'weeks' },
    ],
  },
};
