import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDisabledGamesData } from '../../state/game';
import { storageDisabledGames } from '../../utils/storage';
import { useUserContext } from '../../providers/UserProvider';
import { setDisabledGamesData } from '../../state/game/reducers';
import { IDisabledGamesData } from '../../models/game-session.model';

export function useGlobalGameSession(): void {
  const dispatch = useDispatch();
  const { userProfileId } = useUserContext();
  const { enableGamesDate, areGamesDisabled } = useSelector(selectDisabledGamesData);
  const storageDisabledGamesData = storageDisabledGames.get() as IDisabledGamesData;

  const hasBreakEnded = (value: number): boolean => value < new Date().getTime();

  useEffect(() => {
    let interval: NodeJS.Timeout | number = 0;

    if (areGamesDisabled) {
      interval = setInterval(() => {
        if (enableGamesDate && hasBreakEnded(enableGamesDate)) {
          dispatch(setDisabledGamesData({ areGamesDisabled: false, enableGamesDate: null }));
          storageDisabledGames.remove();

          clearInterval(interval);
        }
      }, 5000);
    }

    return () => clearInterval(interval);
  }, [areGamesDisabled]);

  useEffect(() => {
    if (storageDisabledGamesData?.areGamesDisabled && storageDisabledGamesData.userProfileId === userProfileId) {
      const { areGamesDisabled, enableGamesDate } = storageDisabledGamesData || {};

      dispatch(setDisabledGamesData({ areGamesDisabled, enableGamesDate }));
    }
  }, []);
}
