import React, { FC } from 'react';
import dompurify from 'dompurify';
import { Heading } from '../../shared/Heading';
import { Paragraph } from '../../shared/Paragraph';
import { Button } from '../../shared/Button';
import { filterSpecialHTMLCharacters } from '../../../utils/string';

interface WelcomeBonusTermsAndConditionsProps {
  title: string;
  description?: string;
  back: () => void;
}

export const WelcomeBonusTermsAndConditions: FC<WelcomeBonusTermsAndConditionsProps> = ({
  title,
  description = '',
  back,
}): JSX.Element => {
  const sanitizer = dompurify.sanitize;

  return (
    <>
      <div className="welcome-bonus-TnC">
        <Heading level={6} noMargin>
          {title}
        </Heading>
        <Paragraph
          noMargin
          dangerouslySetInnerHTML={{
            __html: filterSpecialHTMLCharacters(sanitizer(description)),
          }}
          className="aem-text"
        />
      </div>
      <Button type="button" size="large" onClick={back} className="welcome-bonus-TnC-back">
        Back
      </Button>
    </>
  );
};
