/* eslint-disable @typescript-eslint/ban-ts-comment */
const requestFullscreenAttribute = [
  'requestFullscreen',
  'mozRequestFullScreen',
  'webkitRequestFullscreen',
  'msRequestFullscreen',
  // @ts-ignore
].find((fnName) => typeof document.body?.[fnName] === 'function');

const closeFullscreenAttribute = [
  'exitFullscreen',
  'mozCancelFullScreen',
  'webkitExitFullscreen',
  'msExitFullscreen',
  // @ts-ignore
].find((fnName) => typeof document[fnName] === 'function');

export const requestFullscreen = (el: HTMLElement): Promise<void> => {
  if (!requestFullscreenAttribute) {
    return Promise.reject(new Error('Request fullscreen not supported for device'));
  }

  try {
    // @ts-ignore
    const promise = el[requestFullscreenAttribute]();

    if (promise) {
      return promise.catch((err: { name: string; message: string }) => {
        console.log(`An error occurred while trying to switch into fullscreen mode: ${err.message} (${err.name})`);
      });
    }

    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getGameUrl = (gameUrl: string, playType?: 'demo' | 'real'): string => {
  return `/game-details/${gameUrl}${playType ? '#/' + playType : ''}`;
};

export const closeFullscreen = (): void => {
  // @ts-ignore
  if (closeFullscreenAttribute) document[closeFullscreenAttribute]();
};

export const isDocumentFullscreen = (): boolean =>
  ['fullscreenElement', 'mozFullScreenElement', 'webkitFullscreenElement', 'msFullscreenElement'].some(
    // @ts-ignore
    (att) => !!document[att]
  );

export const hasInPathName = (path: string): boolean => {
  return window.location.pathname.includes(path);
};
