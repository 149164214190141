import React, { FC } from 'react';
import { joinStrings } from '../../../utils/string';
import './Typography.scss';

export type TypographyTextSize = 'xl' | 'lg' | 'md' | 'sm' | 'xs' | 'xxs';

type AllowedTags = Pick<JSX.IntrinsicElements, 'span' | 'div' | 'strong' | 'li' | 'dt' | 'dd'>;

export type TypographyProps = {
  /**
   * Size of the text.
   * @default m
   */
  size?: TypographyTextSize;
  /**
   * Optional custom tag.
   * @default span
   */
  tag?: keyof AllowedTags;
  /**
   * Optionally apply highlight styles to the paragraph.
   * Highlight styles could be customised per site, but by default will make the text bolder.
   */
  highlight?: boolean;
} & React.HTMLAttributes<HTMLOrSVGElement>;

/**
 * Apply basic starterkit typography styling to text for a range of tags.
 */
export const Typography: FC<TypographyProps> = ({
  children,
  tag: Tag = 'span',
  highlight,
  size = 'md',
  className,
  ...rest
}: TypographyProps) => {
  return (
    <Tag
      {...rest}
      className={joinStrings([
        'typography',
        `typography typography--${size}`,
        highlight && 'typography--highlight',
        className,
      ])}
    >
      {children}
    </Tag>
  );
};
