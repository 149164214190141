import { FlattenedData, FlattenedFooter, FlattenedItem } from './types';
import { FooterGetQuery } from '../../libs/graphql/betzoneDirectusAPI/queries/__generated__/footer-get.query.generated';
import { getImageUrl } from '../navigation';

export const initialFooterDataState: FlattenedFooter = {
  translations: {
    footer_text: '',
    copyright_text: '',
  },
  collections: [],
};
export const flattenFooterData = (data: FooterGetQuery): FlattenedFooter => {
  const translations = {
    footer_text: data.site_footer?.translations?.[0]?.footer_text || '',
    copyright_text: data.site_footer?.translations?.[0]?.copyright_text || '',
  };

  const collections: FlattenedData[] =
    data.site_footer?.collections?.map((collection) => {
      const items: FlattenedItem[] =
        collection?.footer_collection_id?.items?.map((item) => {
          const imageURL = getImageUrl(item?.footer_item_id?.thumbnail?.filename_disk);

          return {
            imageURL,
            pageURL: item?.footer_item_id?.translations?.[0]?.url || '',
            title: item?.footer_item_id?.translations?.[0]?.title || '',
            isOpenInNewTab: item?.footer_item_id?.translations?.[0]?.open_in_new_tab || false,
          };
        }) || [];

      const sectionTitle = collection?.footer_collection_id?.translations?.[0]?.title || '';

      return {
        items,
        sectionTitle,
      };
    }) || [];

  return { translations, collections };
};
