import React, { FunctionComponent, useState, Suspense } from 'react';
import { AutocompleteInput } from '../../AutocompleteInput';
import { Icon } from '../../Icon';
import { Input } from '../../Input';
import { SelectOption } from '../../Select';
import './FilterableCountryCode.scss';
import { useTranslations } from '../../../../hooks/useTranslationsHelper';

export type FilterableCountryCodeProps = {
  /**
   * Label of the input for screen readers only (isSrOnly set to true)
   */
  label: string;
  /**
   * Name used on the input
   */
  name?: string;
  /**
   * Update the telephone value when the country code changes
   */
  onChange?: (countryCode: string) => void;
  /**
   * Options to display in the filterable dropdown
   */
  options: SelectOption[];
  /**
   * Current input value
   */
  value: string;
};

export const FilterableCountryCode: FunctionComponent<FilterableCountryCodeProps> = ({
  label,
  name,
  onChange,
  options,
  value,
}) => {
  const { t } = useTranslations();

  const [dropdownItems, setDropdownItems] = useState(options);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string | undefined>('');

  const toggleDropdownOpen = (): void => {
    setSearchQuery('');
    setDropdownItems(options);
    setDropdownOpen((prevState) => !prevState);
  };

  return (
    <>
      <div className="country-code__toggle" data-testid="country-code-toggle">
        <Input
          iconRight={<Icon variant="SelectArrowIcon" />}
          label={label}
          labelProps={{ isSrOnly: true }}
          name={name}
          onClick={toggleDropdownOpen}
          readOnly
          value={value}
        />
      </div>
      {dropdownOpen && (
        <div className="country-code__filter" data-testid="country-code-filter">
          <Suspense>
            <AutocompleteInput
              inputProps={{
                label: t('input.phone.search-code'),
                labelProps: {
                  isSrOnly: true,
                  labelVariant: 'default',
                },
                placeholder: t('input.phone.placeholder'),
                iconLeft: <Icon variant="Search" />,
                onKeyDown: (e): void => {
                  if (e.key === 'Enter' && dropdownItems.length === 1) {
                    onChange && onChange(dropdownItems[0].value);
                    toggleDropdownOpen();
                  }
                },
              }}
              isMenuOpen={dropdownOpen}
              onChange={(countryCode): void => setSearchQuery(countryCode)}
              onInputChange={(query): void => {
                const updatedItems = query
                  ? options.filter(({ label }) => label.toLowerCase().includes(query.toLowerCase()))
                  : options;

                setDropdownItems(updatedItems);
              }}
              onMenuOpenChange={toggleDropdownOpen}
              onSelect={(option): void => {
                onChange && onChange(option.value);
              }}
              options={dropdownItems}
              optionToString={(item): string => item?.label || ''}
              value={searchQuery}
            />
          </Suspense>
        </div>
      )}
    </>
  );
};
