/**
 * These are another form of event. We're going to try and normalize the api a bit so they can
 * all be subscribed to via our on() method. then we map them from within KambiBridge
 *
 * For all events supported via this alternative method see
 * https://www.kambipartner.com/techhub/api_docs/gen_doc/client_documentation/how_to_integrate_the_html5_client/catalog_of_notification_events
 */
export enum KambiApplicationEventType {
  APP_BET_VALIDATION_COMPLETED = 'betValidationCompleted',
  APP_CUSTOMIZED_FEEDBACK = 'customizedFeedback',
  APP_DATA_LAYER_PUSHED = 'dataLayerPushed',
  APP_DEPOSIT_REQUESTED = 'depositRequested',
  APP_KAMBI_MAINTENANCE = 'KambiMaintenance',
  APP_LOGIN_REQUEST_DONE = 'loginRequestDone',
  APP_LOGIN_REQUESTED = 'loginRequested',
  APP_MAINTENANCE = 'maintenance',
  APP_PAGE_RENDERED = 'pageRendered',
  APP_PERFORMANCE_MEASUREMENTS_CREATED = 'performanceMeasurementsCreated',
  APP_PLACED_BET = 'placedBet',
  APP_PLACING_BET_FAILED = 'placingBetFailed',
  APP_PLACING_BET_SUCCEEDED = 'placingBetSucceeded',
  APP_PLACING_BET_END = 'placingBetEnd',
  APP_PUNTER_LOGIN_SUCCEEDED = 'punterLoginSucceeded',
  APP_PUNTER_LOGIN_FAILED = 'punterLoginFailed',
  APP_SESSION_TIMED_OUT = 'sessionTimedOut',
  APP_TRACKED = 'tracked',
}

export enum KambiEventType {
  IFRAME_READY = 'iframeReady',
  REMOVE = 'remove',
  NAVIGATE = 'navigate',
  BET_HISTORY = 'BetHistory',
  BETSLIP_OUTCOMES_REMOVE = 'BetslipOutcomesRemove',
  BETSLIP_OUTCOMES = 'BetslipOutcomes',
  BETSLIP_MAXIMIZED = 'BetslipMaximize',
  BETSLIP_MAXIMIZED_CHANGE = 'BetslipMaximizedChange',
  BETSLIP_STAKE_UPDATED = 'BetslipStakeUpdated',
  BETSLIP_UPDATE_STAKE = 'BetslipUpdateStake',
  BETSLIP_VALID_REWARDS_UPDATED = 'BetslipValidRewardsUpdated',
  EVENT_INFO = 'EventInfo',
  EVENT_INFO_UNSUBSCRIBE = 'EventInfoUnSubscribe',
  PLACE_BET = 'BetslipPlaceBet',
  CLIENT_ODDS_FORMAT = 'ClientOddsFormat',
  PLACE_BET_STATE = 'PlaceBetState',
  PLACE_BET_FAILURE_REASON = 'PlaceBetFailureReason',
  PAGE_INFO = 'PageInfo',
  USER_LOGGED_IN = 'UserLoggedIn',
  USER_SESSION_CHANGE = 'UserSessionChange',
  USER_DATA = 'UserData',
  CLIENT_CONFIG = 'ClientConfig',
  VERSIONS = 'Versions',
  ODDS_FRACTIONAL = 'OddsAsFractional',
  ODDS_AMERICAN = 'OddsAsAmerican',
  LIBS = 'Libs',
  WIDGET_ARGS = 'Args',
  WIDGET_HEIGHT = 'Height',
  WIDGET_ENABLE_TRANSITION = 'EnableTransition',
  WIDGET_DISABLE_TRANSITION = 'DisableTransition',
  WIDGET_SETUP = 'Setup',
  WIDGET_MESSAGE = 'WidgetMessage',
  LOGIN = 'Login',
  LOGOUT = 'Logout',
  CLIENT_HIDE = 'ClientHide',
  CLIENT_SHOW = 'ClientShow',
  TRACK_EXTERNAL_INTERACTION = 'TrackExternalInteraction',
  BETSLIP_HIDE = 'BetslipHide',
  BETSLIP_SHOW = 'BetslipShow',
  BETSLIP_CLEAR = 'BetslipClear',
  REWARDS = 'Rewards',
  KAMBI_REQUEST_CLIENT_READY_RESPONSE = 'KambiBC_RequestClientReadyResponse',
  KAMBI_RESPOND_CLIENT_READY = 'KambiBC_RespondClientReady',
  START_TUTORIAL = 'StartTutorial',
  DISABLE_CASH_OUT = 'DisableCashout',
  ENABLE_CASH_OUT = 'EnableCashout',
  RETAIL_TOGGLE = 'RetailToggle',
  RETAIL_SST_AUX_CLIENT_NOTIFICATION = 'RetailSstAuxClientNotification',
  BETSLIP_RECEIPT_CLEAR = 'BetslipReceiptClear',
  EXTERNAL_VISUALISATION = 'external.visualisation',
  TOGGLE_LEFT_NAVIGATION_OVERLAY = 'ToggleLeftNavigationOverlay',
  PRINT_DATA = 'PrintData',
  STREAM_URL = 'StreamURL',
  DISABLE_PLACE_BET_BUTTON = 'DisablePlaceBetButton',
  PLACE_BET_STATE_EXTENDED = 'PlaceBetStateExtended',
}

export enum KambiCallbackType {
  BALANCE_REQUEST = 'getBalance',
}

export enum KambiBetslipOutcomesArgs {
  UPDATE_REPLACE = 'replace',
  UPDATE_APPEND = 'append',
  TYPE_SINGLE = 'single',
  TYPE_COMBINATION = 'combination',
  TYPE_SYSTEM = 'system',
  TYPE_PATENT = 'patent',
  TYPE_TRIXIE = 'trixie',
  TYPE_YANKEE = 'yankee',
  TYPE_CANADIAN = 'canadian',
  TYPE_HEINZ = 'heinz',
  TYPE_SUPERHEINZ = 'superheinz',
}

export enum KambiPlaceBetStateValue {
  PLACING = 'placing',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export enum KambiBetType {
  SINGLE = 'RCT_SINGLE',
  COMBINATION = 'RCT_COMBINATION',
  SYSTEM = 'RCT_SYSTEM',
}

export enum KambiBetslipStakeUpdatedTypes {
  STAKE_UPDATE_TYPE_SINGLE = 'Single',
  STAKE_UPDATE_TYPE_COMBINATION = 'Combination',
  STAKE_UPDATE_TYPE_SYSTEM = 'System',
}

export enum KambiEventInfoTypes {
  BASIC = 'BASIC',
  BET_OFFERS = 'BET_OFFERS',
  SCORE = 'SCORE',
  MATCH_CLOCK = 'MATCH_CLOCK',
  MATCH_OCCURENCES = 'MATCH_OCCURENCES',
}

export enum KambiEventInfoContext {
  LIVE = 'LIVE',
  PRE_MATCH = 'PRE-MATCH',
}

export enum KambiFetchCouponStatus {
  PENDING = 'PENDING',
  SETTLED = 'SETTLED',
  WON = 'WON',
  LOST = 'LOST',
  VOID = 'VOID',
  CASH_OUT = 'CASH_OUT',
  CASHED_OUT = 'CASHED_OUT',
  ALL = '',
  LIVE = 'LIVE',
}

export enum KambiFetchOutcomeStatus {
  LOST = 'LOST',
  NOT_SET = 'NOT_SET',
  OPEN = 'OPEN',
  VOID = 'VOID',
  WON = 'WON',
}

export enum KambiFetchCouponRowStatus {
  LOST = 'LOST',
  OPEN = 'OPEN',
  PARTIAL_VOID = 'PARTIAL_VOID',
  PLACED = 'PLACED',
  VOID = 'VOID',
  WON = 'WON',
}

export enum KambiFetchBetStatus {
  CANCELLED = 'CANCELLED',
  CASHED_OUT = 'CASHED_OUT',
  CLAIMED = 'CLAIMED',
  EXPIRED = 'EXPIRED',
  LOST = 'LOST',
  OPEN = 'OPEN',
  PLACED = 'PLACED',
  REJECTED = 'REJECTED',
  REJECTED_BY_AAMS = 'REJECTED_BY_AAMS',
  SETTLED = 'SETTLED',
  VOID = 'VOID',
  WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
  WAITING_FOR_APPROVAL_BY_AAMS = 'WAITING_FOR_APPROVAL_BY_AAMS',
  WON = 'WON',
}

export enum KambiTransactionType {
  DEBIT = 'Debit',
  CREDIT = 'Credit',
  ADJUSTMENT = 'Adjustment',
  SETTLE = 'Settle',
}

export enum KambiLogoutReasons {
  LOGIN_FAILED = 'LOGIN_FAILED',
  LOGOUT_REQUESTED = 'LOGOUT_REQUESTED',
  SESSION_TIMED_OUT = 'SESSION_TIMED_OUT',
}

export enum KambiOddsFormatTypes {
  DECIMAL = 'decimal',
  FRACTIONAL = 'fractional',
  AMERICAN = 'american',
}

export enum KambiFeedbackMessageTypes {
  VET_TOO_MANY_OUTCOMES = 'VET_TOO_MANY_OUTCOMES',
  VET_NOT_LOGGED_IN = 'VET_NOT_LOGGED_IN',
  VET_LIVE_PLACE_BET_FAIL_AFTER_DELAY = 'VET_LIVE_PLACE_BET_FAIL_AFTER_DELAY',
  VET_LIVE_PLACE_BET_COUPON_STATUS_UNKNOWN = 'VET_LIVE_PLACE_BET_COUPON_STATUS_UNKNOWN',
  VET_GENERIC = 'VET_GENERIC',
  VET_REQUEST_TIMEOUT = 'VET_REQUEST_TIMEOUT',
  VET_MULTIPLE_OUTCOME_ERRORS = 'VET_MULTIPLE_OUTCOME_ERRORS',
  VET_LIVE_BETTING_BY_PHONE_ONLY = 'VET_LIVE_BETTING_BY_PHONE_ONLY',
  VET_BET_CONFIRMATION_REQUIRED = 'VET_BET_CONFIRMATION_REQUIRED',
  VET_BET_SELECTION_GENERIC = 'VET_BET_SELECTION_GENERIC',
  VET_TEASER_NOT_AVAILABLE = 'VET_TEASER_NOT_AVAILABLE',
  VET_TEASER_OUTCOME_CLOSED = 'VET_TEASER_OUTCOME_CLOSED',
  VET_MIXED_BOG_REWARD = 'VET_MIXED_BOG_REWARD',
  VET_BET_OFFER_CLOSED = 'VET_BET_OFFER_CLOSED',
  VET_BET_OFFER_SUSPENDED = 'VET_BET_OFFER_SUSPENDED',
  VET_NOT_BETTABLE = 'VET_NOT_BETTABLE',
  VET_ODDS_CHANGED = 'VET_ODDS_CHANGED',
  VET_ODDS_TOO_LOW = 'VET_ODDS_TOO_LOW',
  VET_ODDS_TOO_HIGH = 'VET_ODDS_TOO_HIGH',
  VET_INVALID_BET = 'VET_INVALID_BET',
  VET_TOO_MANY_COUPON_ROWS = 'VET_TOO_MANY_COUPON_ROWS',
  VET_NOT_COMBINABLE = 'VET_NOT_COMBINABLE',
  VET_MIXED_LIVE_AND_PRE_MATCH = 'VET_MIXED_LIVE_AND_PRE_MATCH',
  VET_STAKE_TOO_HIGH = 'VET_STAKE_TOO_HIGH',
  VET_STAKE_TOO_LOW = 'VET_STAKE_TOO_LOW',
  VET_STAKE_NOT_INTEGER = 'VET_STAKE_NOT_INTEGER',
  VET_COMBINATION_STAKE_NOT_DIVISIBLE_BY = 'VET_COMBINATION_STAKE_NOT_DIVISIBLE_BY',
  VET_PAYOUT_TOO_HIGH = 'VET_PAYOUT_TOO_HIGH',
  VET_OVERSIZED_BET = 'VET_OVERSIZED_BET',
  VET_STAKE_OVER_REGULATORY_LIMIT = 'VET_STAKE_OVER_REGULATORY_LIMIT',
  VET_USER_NOT_AUTHENTICATED = 'VET_USER_NOT_AUTHENTICATED',
  VET_USER_LOGGED_OUT = 'VET_USER_LOGGED_OUT',
  VET_NOT_ENOUGH_FUNDS = 'VET_NOT_ENOUGH_FUNDS',
  VET_ACCOUNT_INACTIVE = 'VET_ACCOUNT_INACTIVE',
  VET_ACCOUNT_BLOCKED = 'VET_ACCOUNT_BLOCKED',
  VET_RESPONSIBLE_GAMING_SELF_EXCLUSION_IN_EFFECT = 'VET_RESPONSIBLE_GAMING_SELF_EXCLUSION_IN_EFFECT',
  VET_RESPONSIBLE_GAMING_LIMIT_EXCEEDED = 'VET_RESPONSIBLE_GAMING_LIMIT_EXCEEDED',
  VET_GAMING_LIMIT_EXCEEDED = 'VET_GAMING_LIMIT_EXCEEDED',
  VET_TECHNICAL_ERROR = 'VET_TECHNICAL_ERROR',
  VET_NEW_FUNDS_BLOCKED_FOR_EVENT = 'VET_NEW_FUNDS_BLOCKED_FOR_EVENT',
  VET_UNAVAILABLE_PROMOTION_FUNDS = 'VET_UNAVAILABLE_PROMOTION_FUNDS',
  VET_BONUS_MONEY_NOT_ALLOWED = 'VET_BONUS_MONEY_NOT_ALLOWED',
  VET_TERMS_CHANGED = 'VET_TERMS_CHANGED',
  VET_REJECTED_OVER_LIMIT = 'VET_REJECTED_OVER_LIMIT',
  VET_REJECTED_HIGH_ODDS = 'VET_REJECTED_HIGH_ODDS',
  VET_PBA_NOT_ALLOWED = 'VET_PBA_NOT_ALLOWED',
  VET_PARTICIPANTS_SCRATCHED = 'VET_PARTICIPANTS_SCRATCHED',
  VET_REWARD_NOT_AVAILABLE = 'VET_REWARD_NOT_AVAILABLE',
  VET_ODDS_BELOW_REWARD_REQUIREMENT = 'VET_ODDS_BELOW_REWARD_REQUIREMENT',
  VET_COMBINATION_ODDS_ABOVE_REWARD_REQUIREMENT = 'VET_COMBINATION_ODDS_ABOVE_REWARD_REQUIREMENT',
  VET_TOO_MANY_OUTCOMES_FOR_REWARD = 'VET_TOO_MANY_OUTCOMES_FOR_REWARD',
  VET_TOO_FEW_OUTCOMES_FOR_REWARD = 'VET_TOO_FEW_OUTCOMES_FOR_REWARD',
  VET_PBT_STAKE_TOO_HIGH = 'VET_PBT_STAKE_TOO_HIGH',
  VET_PBT_WINNINGS_TOO_HIGH = 'VET_PBT_WINNINGS_TOO_HIGH',
  VET_EW_REWARD_NON_EW_BET = 'VET_EW_REWARD_NON_EW_BET',
  VET_EW_BET_NON_EW_REWARD = 'VET_EW_BET_NON_EW_REWARD',
  VET_BAD_REQUEST = 'VET_BAD_REQUEST',
}

export enum KambiRewardType {
  FREE_BET = 'FREE_BET',
  PROFIT_BOOST = 'PROFIT_BOOST',
  BEST_ODDS = 'BEST_ODDS',
  ODDS_BOOST = 'ODDS_BOOST',
  SECOND_CHANCE = 'SECOND_CHANCE',
}

export type KambiCallback = (done?: (val: unknown) => void) => void;

export type KambiBridgeConfiguration = {
  currency: string;
  locale: string;
  market: string;
  routeRoot?: string;
};

export type KambiAuthenticationConfiguration = {
  punterId: string;
  ticket: string;
};

export type KambiNotification = {
  name: KambiApplicationEventType;
};

export type KambiUserData = {
  currency: string;
  hashedPunterId: string;
  locale: string;
  market: string;
  punterId: string;
  routingKey: string;
  sessionId: string;
};

export type KambiCouponsResponse = {
  isFreeBet?: boolean;
  couponRef: number;
  couponExternalRef: string;
  placedDate: string;
  currency: string;
  channel: string;
  rewardType?: KambiRewardType;
  bets: {
    betOdds: number;
    betOddsBoosted?: number;
    betRef: number;
    betStatus: KambiFetchBetStatus;
    couponRowIndexes: number[];
    payout: number;
    playedOdds: number;
    potentialPayout: number;
    potentialPayoutBoosted: number;
    stake: number;
  }[];
  betOffers: {
    betOfferId: number;
    boType: string;
    boTypeId: number;
    criterion: string;
  }[];
  outcomes: {
    betOfferId: number;
    eventId: number;
    label: string;
    outcomeId: number;
    settledInfo?: {
      result?: {
        score: string;
        correct: string[];
        scratched: string[];
      };
      voidReason?: string;
    };
    status: KambiFetchOutcomeStatus;
    outcomeTags: string[];
  }[];
  couponRows: {
    bestOddsGuaranteed: boolean;
    index: number;
    outcomeId: number;
    payoutOdds?: number;
    oddsBoosted?: number;
    playedOdds: number;
    selectionType: string;
    status: KambiFetchCouponRowStatus;
  }[];
  events: {
    awayName: string;
    eventGroups: { id: number; name: string }[];
    eventId: number;
    eventName: string;
    eventStartDate: string;
    homeName: string;
    sport: string;
  }[];
};

export type OutCome = {
  id: string;
  label: string;
  englishLabel: string;
  odds: number;
  participant?: string;
  type: string;
  betOfferId: number;
  changedDate: string;
  participantId: number;
  oddsFractional: string;
  oddsAmerican: string;
  status: string;
  cashOutStatus: string;
};

export type KambiOfferResponse = {
  betOffers: [
    {
      outcomes: OutCome[];
      suspended: boolean;
    },
  ];
  events: [{ start: string; homeName: string; awayName: string }];
};

export type KambiCouponFlatData = {
  couponRef: number;
  rewardType?: KambiRewardType;
  placedDate: string;
  outcomeStatus: KambiFetchOutcomeStatus;
  couponRowStatus?: KambiFetchCouponRowStatus;
  betsStakeSum: number;
  betsPayoutSum: number;
  betsPotentialPayoutSum: number;
  betsPotentialPayoutBoostedSum: number;
  betsBetStatuses: KambiFetchBetStatus[];
} & Pick<KambiCouponsResponse, 'bets'> &
  Omit<KambiCouponsResponse['outcomes'][number], 'status'> &
  Omit<KambiCouponsResponse['couponRows'][number], 'status'> &
  KambiCouponsResponse['events'][number] &
  KambiCouponsResponse['betOffers'][number];

export type KambiCouponDescription = {
  text: string;
  odds?: string;
  oldOdds?: string;
};
