import React, { FunctionComponent } from 'react';
import { NotificationMessage } from '../../../../shared/NotificationMessage';
import './AccountNotifications.scss';

export const AccountNotifications: FunctionComponent = () => {
  const isSOFRequired = false;

  const onClickSofNotificationMoreBtn = (): void => {
    console.log('onClickSofNotificationMoreBtn');
  };

  const onShowSofNotification = (): void => {
    console.log('onShowSofNotification');
  };

  return (
    <div className="account-notifications">
      {/* Source of funds */}
      {isSOFRequired && (
        <NotificationMessage
          iconVariant="Warning"
          iconClass="account-notifications__icon account-notifications__icon--warning"
          text="Proof of income documents needed"
          buttonText="More info"
          buttonClass="account-notifications__link"
          onClick={onClickSofNotificationMoreBtn}
          onShow={onShowSofNotification}
        />
      )}
    </div>
  );
};
