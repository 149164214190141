import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TransactionLimitsDeleteMutationVariables = Types.Exact<{
  limitId: Types.Scalars['ID']['input'];
  userProfileId: Types.Scalars['ID']['input'];
  xTenantId: Types.Scalars['ID']['input'];
  creatorName?: Types.InputMaybe<Types.CreatorName>;
}>;


export type TransactionLimitsDeleteMutation = { __typename?: 'Mutation', transactionLimitsDelete?: any | null };


export const TransactionLimitsDeleteDocument = gql`
    mutation TransactionLimitsDelete($limitId: ID!, $userProfileId: ID!, $xTenantId: ID!, $creatorName: CreatorName) {
  transactionLimitsDelete(
    limitId: $limitId
    userProfileId: $userProfileId
    xTenantID: $xTenantId
    creatorName: $creatorName
  )
}
    `;
export type TransactionLimitsDeleteMutationFn = Apollo.MutationFunction<TransactionLimitsDeleteMutation, TransactionLimitsDeleteMutationVariables>;

/**
 * __useTransactionLimitsDeleteMutation__
 *
 * To run a mutation, you first call `useTransactionLimitsDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransactionLimitsDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transactionLimitsDeleteMutation, { data, loading, error }] = useTransactionLimitsDeleteMutation({
 *   variables: {
 *      limitId: // value for 'limitId'
 *      userProfileId: // value for 'userProfileId'
 *      xTenantId: // value for 'xTenantId'
 *      creatorName: // value for 'creatorName'
 *   },
 * });
 */
export function useTransactionLimitsDeleteMutation(baseOptions?: Apollo.MutationHookOptions<TransactionLimitsDeleteMutation, TransactionLimitsDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TransactionLimitsDeleteMutation, TransactionLimitsDeleteMutationVariables>(TransactionLimitsDeleteDocument, options);
      }
export type TransactionLimitsDeleteMutationHookResult = ReturnType<typeof useTransactionLimitsDeleteMutation>;
export type TransactionLimitsDeleteMutationResult = Apollo.MutationResult<TransactionLimitsDeleteMutation>;
export type TransactionLimitsDeleteMutationOptions = Apollo.BaseMutationOptions<TransactionLimitsDeleteMutation, TransactionLimitsDeleteMutationVariables>;