import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PromotionsState } from './types';
import { ProductType, PromotionTabsType } from '../../components/Layout/Promotions/PromotionsList/types';

const initialState: PromotionsState = {
  productTabName: ProductType.ALL,
  promotionTab: PromotionTabsType.PROMOTIONS,
};

export const promotionsSlice = createSlice({
  name: 'promotions',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setProductTab: (state: PromotionsState, action: PayloadAction<ProductType | string>) => {
      return {
        ...state,
        productTabName: action.payload,
      };
    },
    setPromotionTabs: (state: PromotionsState, action: PayloadAction<PromotionTabsType>) => {
      return {
        ...state,
        promotionTab: action.payload,
      };
    },
  },
});

export const { setProductTab, setPromotionTabs } = promotionsSlice.actions;

export default promotionsSlice.reducer;
