import React, { forwardRef } from 'react';
import { Control, Controller, RegisterOptions as ValidationRules } from 'react-hook-form';
import { ToggleButton, ToggleButtonProps } from './ToggleButton';

type HookFormButtonProps = {
  control: Control;
  name: string;
  rules?: ValidationRules;
  buttonProps: ToggleButtonProps;
  children?: React.ReactNode;
};
/**
 * A wrapper to allow the controlled Toggle Button component to work with
 * react-hook-form using the provided Controller helper component
 */
export const HookFormToggleButton = forwardRef<HTMLButtonElement, HookFormButtonProps>(
  ({ control, name, rules, children, ...buttonProps }, ref) => (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, ...props }): JSX.Element => (
        <ToggleButton
          {...buttonProps}
          {...props}
          {...field}
          ref={ref}
          selected={field.value}
          onClick={(): void => field?.onChange(!field.value)}
        >
          {children}
        </ToggleButton>
      )}
    />
  )
);

HookFormToggleButton.displayName = 'HookToggleButtonForm';
