import isObject from 'lodash/isObject';
import {
  StoragePersistanceService,
  StoragePersistanceServiceEntry,
  StoragePersistanceServiceProps,
  STORAGE_PREFIX,
} from './types';

/**
 * Try to parse string from storage.
 * If can't parse, then returns the raw value
 *
 * @param value string that should be parsed
 */
const tryJsonParse = (value: string): StoragePersistanceServiceProps | string => {
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
};

/**
 * parse all localStorage entries with Betzone. prefix and prune results that have an expiration number before now
 */
export const checkExpirationAndClearStorage = (): void => {
  const items = { ...localStorage };

  for (const property in items) {
    if (property.includes(STORAGE_PREFIX)) {
      const currentDate = new Date();
      const currentTime = currentDate.getTime();
      const item = localStorage.getItem(property) || '';
      const data = tryJsonParse(item);

      if (isObject(data) && data.expiration && data.expiration < currentTime) {
        localStorage.removeItem(property);
      }
    }
  }
};

/**
 * This is a new reusable module that will essentially wrap localStorage. It will enhance the existing functionality by adding support for:
 * - TTL
 * - Associating content to a given customer
 *
 * The object should expose the following methods:
 * - set(key: string, value: any, props?: LocalPersistanceProps): void - set Betzone.${key} with LocalPersistanceProps
 * - get(key: string): any | null - execute has mathod and return value
 * - remove(key: string): void - remove Betzone.${key} from the storage
 * - has(key: string): boolean - check Betzone.${key} exists and retrieve it
 *
 * @param storageAPI define the API usage
 *
 */
const storagePersistanceService =
  (storageAPI: Storage) =>
  (name: string): StoragePersistanceService => {
    const storageItem = `${STORAGE_PREFIX}${name}`;
    const currentDate = new Date();
    const currentTime = currentDate.getTime();

    const remove = (): void => storageAPI.removeItem(storageItem);

    const has = (currentPlayerId?: string): boolean => {
      const storageValue = storageAPI.getItem(storageItem) || '';
      const parsedData = tryJsonParse(storageValue);

      // if value exists and it's an object, check expiration, playerId and return boolean
      if (isObject(parsedData)) {
        const { expiration, playerId } = parsedData;

        // if expiration has expired, remove entry from localStorage, return false
        if (expiration && expiration < currentTime) {
          remove();

          return false;
        }

        // if playerId is set, compare against logged in player session
        // if they don’t match, keep the entry (it may still have use once logged in) and return false
        if (playerId && playerId !== currentPlayerId) {
          return false;
        }
      }

      return !!parsedData;
    };

    const get = (currentPlayerId?: string): string | unknown => {
      const isValueExists = has(currentPlayerId);
      const storageValue = storageAPI.getItem(storageItem) || '';
      const data = isValueExists ? tryJsonParse(storageValue) : null;

      return isObject(data) ? data.value : data;
    };

    const set = (props: StoragePersistanceServiceEntry): void => {
      const { value, ttl, playerId } = props;

      const serviceProps: StoragePersistanceServiceProps = {
        value,
      };

      if (playerId) {
        serviceProps.playerId = playerId;
      }

      if (ttl) {
        serviceProps.expiration = currentTime + ttl;
      }

      storageAPI.setItem(storageItem, JSON.stringify(serviceProps));
    };

    return {
      get,
      set,
      has,
      remove,
    };
  };

export const localStorageApi = storagePersistanceService(localStorage);
export const sessionStorageApi = storagePersistanceService(sessionStorage);
