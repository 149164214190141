import { useLayoutEffect, useRef, useState } from 'react';

type UseExpansionOptions = {
  /**
   * Number of milliseconds for transition. Used to generate expansionContainerStyles etc.
   */
  transitionMs: number;
  /**
   * Whether to start in expanded state. Defaults to false.
   */
  defaultExpanded?: boolean;
};

type UseExpansionReturnValue<T> = {
  /**
   * Whether the state is currently expanded.
   */
  expanded: boolean;
  /**
   * CSS transition duration
   */
  transitionDuration: string;
  /**
   * Use this to control expansion state & transition effects
   */
  setExpanded: (expand: boolean) => void;
  /**
   * Should be set as a ref for the element which should be hidden from view.
   * The ref used to calculate details/styles for expansion transition.
   */
  expansionElementRef: React.MutableRefObject<T | null>;
  /**
   * Should be set to container surrounding the component with ref={expansionElementRef}.
   */
  expansionContainerStyles: React.CSSProperties;
};

/**
 * Hook which can be used to control display logic for expansion type components such as accordions, menus etc.
 *
 * TODO: Currently covered by Accordion tests but should add tests for hook since it's also used elsewhere
 */
export function useExpansion<El extends HTMLElement = HTMLDivElement>({
  defaultExpanded = false,
  transitionMs,
}: UseExpansionOptions): UseExpansionReturnValue<El> {
  const [firstRender, setFirstRender] = useState(true);
  const expansionElementRef = useRef<El>(null);
  const [expansionHeight, setExpansionHeight] = useState('0px');
  const [expanded, setExpanded] = useState(defaultExpanded);

  useLayoutEffect(() => {
    if (firstRender) {
      const wrapperHeight = expanded ? 'auto' : '0px';

      setFirstRender(false);

      return setExpansionHeight(wrapperHeight);
    }

    /* istanbul ignore else */
    if (expansionElementRef.current) {
      setExpansionHeight(expansionElementRef.current.clientHeight + 'px');

      const timeout: NodeJS.Timeout = expanded
        ? // Switch to auto after transition - in case content changes
          setTimeout(() => setExpansionHeight('auto'), transitionMs)
        : setTimeout(() => setExpansionHeight('0px'), 0);

      return () => {
        clearTimeout(timeout);
      };
    } else {
      return setExpansionHeight('0px');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  const transitionDuration = `${transitionMs}ms`;
  const expansionContainerStyles: React.CSSProperties = {
    transitionDuration,
    height: expansionHeight,
    visibility: expansionHeight === '0px' ? 'hidden' : 'initial',
    overflow: 'hidden',
  };

  return {
    expanded,
    transitionDuration,
    setExpanded,
    expansionElementRef,
    expansionContainerStyles,
  };
}
