import React, { Children, FC, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkIsUrlExternal } from '../../../utils/links-helper/links-helper';
import { joinStrings } from '../../../utils/string';
import { openNewWindow } from '../../Navigation/NavigationLink/helper';
import { Link } from '../../shared/Link';
import { Paragraph } from '../../shared/Paragraph';
import { GeneralListProps } from '../types';
import './styles';

interface LinkWrapperProps {
  disableImages?: boolean;
  linksList: GeneralListProps[];
}

export const FooterLinksList: FC<LinkWrapperProps> = ({ linksList, disableImages = false }) => {
  const navigate = useNavigate();

  const onClick = (link: GeneralListProps) => (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const { pageURL, isOpenInNewTab } = link;

    if (pageURL) {
      const isExternalUrl = checkIsUrlExternal(pageURL);
      const isNewWindow = isExternalUrl || isOpenInNewTab;

      isNewWindow ? openNewWindow(pageURL, '_blank') : navigate(pageURL);
    }
  };

  return (
    <div className="footer-links-list">
      {Children.toArray(
        linksList.map((link: GeneralListProps) => {
          const { imageURL, title } = link;

          return (
            <div
              className={joinStrings([
                'footer-links-list__item',
                link?.disabled && 'footer-links-list__item--disabled',
              ])}
            >
              <Link onClick={onClick(link)}>
                {imageURL && <img src={!disableImages ? imageURL : undefined} alt={title} />}
                {title && <Paragraph noMargin>{title}</Paragraph>}
              </Link>
            </div>
          );
        })
      )}
    </div>
  );
};
