import { GameCardContainerType } from '../GameCard/GameCard.types';

type GameCarouselConfigType = {
  gameCarouselTitle: string;
  slidesCount: number;
  categoryName: string;
  bigDesktopSlideCount: number;
  desktopSlideCount: number;
  tabletSlideCount: number;
  mobileSlideCount: number;
  slideContainerType: GameCardContainerType;
  rowsNumber: number;
};

export const getGameCarouselConfig = (t: (arg: string) => string): GameCarouselConfigType => {
  return {
    gameCarouselTitle: t('game.similar.label'),
    categoryName: 'popular-games',
    slidesCount: 14,
    bigDesktopSlideCount: 4,
    desktopSlideCount: 4,
    tabletSlideCount: 4,
    mobileSlideCount: 2,
    slideContainerType: GameCardContainerType.Wide,
    rowsNumber: 1,
  };
};
