import React, { FunctionComponent } from 'react';
import { Prompt } from '../../../shared/Prompt';
import { useNavigate } from 'react-router-dom';
import { APP_URLS } from '../../../../consts';
import promptImg from '../../../../assets/Prompt/registration-abandon-prompt.png';
import { Paragraph } from '../../../shared/Paragraph';

export type GameSessionParallelProps = {
  show: boolean;
  onClose?: () => void;
  onPrimary?: () => void;
};

export const GameSessionParallel: FunctionComponent<GameSessionParallelProps> = ({ show, onClose, onPrimary }) => {
  const navigate = useNavigate();
  const onBackToLobby = (): void => navigate(APP_URLS.home);

  return (
    <>
      <Prompt
        onClose={onClose}
        show={show}
        title="You are on more than one device"
        image={{
          src: promptImg,
          alt: 'More than one device',
        }}
        PrimaryButton={{
          onClick: onPrimary,
          text: 'Try Again',
        }}
        SecondaryButton={{
          onClick: onBackToLobby,
          text: 'Back to lobby',
        }}
      >
        <Paragraph noMargin size="sm">
          We have detected that you are playing in more than one area. If you wish to continue on this device please
          close any other game play and try again.
        </Paragraph>
      </Prompt>
    </>
  );
};
