import React, { ComponentPropsWithRef, forwardRef, FC, useEffect, useRef } from 'react';
import { IconBaseProps } from '@react-icons/all-files';
import { Icon } from '../Icon';
import { InputValidationAlerts } from '../InputValidationAlert';
import { Label } from '../Label';
import { Switch } from './Switch';
import './Checkbox.scss';
import { InputValidationProps } from '../Input';
import { joinStrings } from '../../../utils/string';

const CheckboxIcon: FC<
  {
    value?: boolean;
    indeterminate?: boolean;
  } & IconBaseProps
> = ({ value, indeterminate, ...props }) => {
  if (value === true) return <Icon variant="SquareCheck" {...props} data-testid="check-icon" />;

  if (value === false || !indeterminate) return <Icon variant="SquareOutline" {...props} data-testid="square-icon" />;

  return <Icon variant="SquareMinus" {...props} data-testid="indeterminate-icon" />;
};

export type CheckboxProps = {
  /**
   * Anything other than true or false will show as indeterminate
   */
  checked?: boolean;

  /**
   * Optionally disable the checkbox.
   *
   * This is provided by ComponentProps but it's added to note that is will
   * be aria disabled rather than using the HTMLInputElement.disable property
   * so therefore visible to screen readers.
   */
  disabled?: boolean;

  /**
   * Optional, defaults to false. Will show indeterminate when checked is not strict true or false.
   */
  indeterminate?: boolean;

  /**
   * Optional label next to the input
   */
  label?: React.ReactNode | string;

  /**
   * Optional. Disables toggle logic on label click
   */
  disableLabelClick?: boolean;

  /**
   * Emit the next checkbox change event
   */
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * Optional. Defaults to Checkbox.
   * variant allows for different markup to be rendered
   */
  variant?: 'checkbox' | 'switch';

  /**
   * Optional iconPath to display icon.
   */
  iconPath?: string | null;

  /**
   * Optional.
   * Should utilise the Icon component e.g. <Icon variant='Close' />
   */
  iconLabel?: React.ReactNode;
} & InputValidationProps &
  ComponentPropsWithRef<'input'>;

/**
 * A simple controlled checkbox input component.
 *
 * ### Example as used alone as a controlled component
 *
 * ```tsx
 * const StatefulControllerComponent = () => {
 *   const [checked, setChecked] = useState(false);
 *   return (
 *     <Fragment>
 *       <div>...Other things...</div>
 *       <Checkbox
 *         name="checkbox"
 *         id="some-checkbox"
 *         label="Click me"
 *         checked={checked}
 *         onChange={(e)=> setChecked(e.target.checked)}
 *       />
 *     </Fragment>
 *   );
 * };
 * ```
 *
 * ### Example as used within react-hook-form
 *
 * ```tsx
 * import { useForm } from 'react-hook-form';
 *
 * const ReactHookFormComponent = () => {
 *   const { handleSubmit, control } = useForm({
 *     defaultValues: { checkbox: true }
 *   });
 *   return (
 *     <form onSubmit={handleSubmit(onSubmit)}>
 *       <HookFormCheckbox
 *        name='checkbox'
 *        control={control}
 *        label='A label'
 *       />
 *       <button type='submit' />
 *     </form>
 *   );
 * }
 *
 * ```
 */
export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      onChange,
      indeterminate = false,
      checked,
      disabled,
      label,
      disableLabelClick,
      validationMessages,
      validationState,
      variant = 'checkbox',
      iconLabel,
      iconPath,
      ...props
    },
    externalRef
  ) => {
    const ref = useRef<HTMLInputElement>();

    useEffect(() => {
      /* istanbul ignore else */
      if (ref.current) {
        ref.current.indeterminate = indeterminate;
      }
    }, [indeterminate]);

    const className = joinStrings(['checkbox', props.className, disabled && 'checkbox--disabled']);

    const inputClassName = joinStrings(['checkbox__input', disableLabelClick && 'checkbox__input--disabled-label']);

    const id = props.id || props.name;

    return (
      <div className={className}>
        <div className="checkbox__container">
          <input
            {...props}
            ref={(el): void => {
              ref.current = el || undefined;

              if (!externalRef) return;

              if (typeof externalRef === 'object') {
                externalRef.current = el;
              }

              if (typeof externalRef !== 'function') return;

              externalRef(el);
            }}
            checked={checked}
            className={inputClassName}
            type="checkbox"
            aria-disabled={disabled}
            id={id}
            onChange={(e): void => {
              if (disabled) {
                e.preventDefault();
              } else {
                onChange && onChange(e);
              }
            }}
          />
          {variant === 'switch' ? (
            <Switch aria-hidden checked={checked} />
          ) : (
            <CheckboxIcon
              focusable={false}
              aria-hidden
              className={joinStrings([
                'checkbox__icon',
                checked && 'checkbox__icon--checked',
                indeterminate && 'checkbox__icon--indeterminate',
              ])}
              value={checked}
              indeterminate={indeterminate}
            />
          )}

          {label && (
            <Label className="checkbox__label" htmlFor={disableLabelClick ? '' : props.id || props.name}>
              {label}
            </Label>
          )}

          {iconLabel && (
            <span aria-hidden="true" className="checkbox__inline-icon" data-testid="checkbox-icon">
              {iconLabel}
            </span>
          )}
          {iconPath && (
            <span aria-hidden="true" className="checkbox__inline-icon" data-testid="checkbox-icon">
              <img src={iconPath} alt="" />
            </span>
          )}
        </div>

        <InputValidationAlerts className="checkbox__validation" messages={validationMessages} state={validationState} />
      </div>
    );
  }
);

Checkbox.displayName = 'Checkbox';
