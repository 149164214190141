import React, { FunctionComponent, useRef } from 'react';
import { Overlay } from '../../shared/Overlay';
import './styles';

export type NavigationDrawerProps = {
  /**
   * Required. Whether the drawer is open or closed
   */
  isOpen: boolean;
  /**
   * Callback that's executed when the backdrop/menu button is clicked
   */
  onClose: () => void;
  children?: React.ReactNode;
};

// TODO: Currently not animating in on Safari
export const NavigationDrawer: FunctionComponent<NavigationDrawerProps> = ({ isOpen, onClose, children }) => {
  const hamburgerRef = useRef<HTMLButtonElement>(null);

  return (
    <Overlay
      canDismiss
      isOpen={isOpen}
      closeTimeoutMS={300}
      className="navigation-drawer"
      role="dialog"
      backdropClassName="navigation-drawer-backdrop"
      onRequestClose={onClose}
      elementToFocusOnOpen={hamburgerRef}
      aria-label="Navigation"
      style={{ overlay: { zIndex: 999999 } }}
    >
      {/* Navigation Links */}
      {children}
    </Overlay>
  );
};
