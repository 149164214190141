import { PaginatedResponse } from '../../../../models/api.model';
import { BonusHistoryItem, GetBonusHistoryParams } from '../../../../models/bonuses.model';

export const getPlayerBonusHistoryActive = (params?: GetBonusHistoryParams): PaginatedResponse<BonusHistoryItem> => ({
  currentPage: 1,
  itemsPerPage: 5,
  totalItems: 20,
  totalPages: 4,
  items: [
    {
      id: 1,
      bonusId: 1,
      displayName: 'Lucky Dip',
      bonusAmount: 20.0,
      selfWageringRequirementsTarget: 20,
      wageringRequirementsTarget: 20,
      currentContributions: 12,
      activedDate: '2024-05-20T00:00:00Z',
      expiryDate: '2025-08-20T00:00:00Z',
      revokeDate: '2024-03-20T00:00:00Z',
      targetWalletType: 'string',
      targetWalletVertical: 'string',
      status: 'Active',
      promoCode: 'Promo',
      currentAccumulation: 10,
      targetAccumulation: 20.0,
      bonusType: 'Promotion',
      payoutAfterPeriod: true,
      enhancedPayoutQualifyingStakesSum: 200,
      endDateToQualify: '2024-03-20T00:00:00Z',
      termsAndConditions: null,
      reason: null,
      reasonDetails: null,
      configuration: {
        MaximumBonusAmount: null,
        BonusPercentage: null,
      },
      buyInAmount: null,
      description: null,
      detailedDescription: null,
      maximumStakeRestriction: null,
      images: {
        // eslint-disable-next-line quote-props
        default: {
          url: null,
          alt: null,
        },
      },
    },
    {
      id: 2,
      bonusId: 2,
      displayName: 'Spotlight on slots!',
      bonusAmount: 14.25,
      selfWageringRequirementsTarget: 20,
      wageringRequirementsTarget: 14.25,
      currentContributions: 0,
      activedDate: '2024-05-20T00:00:00Z',
      expiryDate: '2025-03-20T00:00:00Z',
      revokeDate: '2024-01-20T00:00:00Z',
      targetWalletType: 'string',
      targetWalletVertical: 'string',
      status: 'Active',
      promoCode: 'Promo',
      currentAccumulation: 10,
      targetAccumulation: 20,
      bonusType: 'Promotion',
      payoutAfterPeriod: true,
      enhancedPayoutQualifyingStakesSum: 200,
      endDateToQualify: '2024-01-20T00:00:00Z',
      termsAndConditions: null,
      reason: null,
      reasonDetails: null,
      configuration: {
        MaximumBonusAmount: null,
        BonusPercentage: null,
      },
      buyInAmount: null,
      description: null,
      detailedDescription: null,
      maximumStakeRestriction: null,
      images: {
        // eslint-disable-next-line quote-props
        default: {
          url: null,
          alt: null,
        },
      },
    },
    {
      id: 3,
      bonusId: 3,
      displayName: 'Spotlight on slots!',
      bonusAmount: 3.25,
      selfWageringRequirementsTarget: 3.25,
      wageringRequirementsTarget: 3.25,
      currentContributions: 0,
      activedDate: '2024-05-20T00:00:00Z',
      expiryDate: '2025-03-20T00:00:00Z',
      revokeDate: '2024-01-20T00:00:00Z',
      targetWalletType: 'string',
      targetWalletVertical: 'string',
      status: 'Active',
      promoCode: 'Promo',
      currentAccumulation: 10,
      targetAccumulation: 20,
      bonusType: 'Promotion',
      payoutAfterPeriod: true,
      enhancedPayoutQualifyingStakesSum: 200,
      endDateToQualify: '2024-01-20T00:00:00Z',
      termsAndConditions: null,
      reason: null,
      reasonDetails: null,
      configuration: {
        MaximumBonusAmount: null,
        BonusPercentage: null,
      },
      buyInAmount: null,
      description: null,
      detailedDescription: null,
      maximumStakeRestriction: null,
      images: {
        // eslint-disable-next-line quote-props
        default: {
          url: null,
          alt: null,
        },
      },
    },
    {
      id: 4,
      bonusId: 4,
      displayName: 'Spotlight on slots!',
      bonusAmount: 11.0,
      selfWageringRequirementsTarget: 20,
      wageringRequirementsTarget: 10,
      currentContributions: 5,
      activedDate: '2024-05-20T00:00:00Z',
      expiryDate: '2025-03-20T00:00:00Z',
      revokeDate: '2024-01-20T00:00:00Z',
      targetWalletType: 'string',
      targetWalletVertical: 'string',
      status: 'Active',
      promoCode: 'Promo',
      currentAccumulation: 10,
      targetAccumulation: 20,
      bonusType: 'Promotion',
      payoutAfterPeriod: true,
      enhancedPayoutQualifyingStakesSum: 200,
      endDateToQualify: '2024-01-20T00:00:00Z',
      termsAndConditions: null,
      reason: null,
      reasonDetails: null,
      configuration: {
        MaximumBonusAmount: null,
        BonusPercentage: null,
      },
      buyInAmount: null,
      description: null,
      detailedDescription: null,
      maximumStakeRestriction: null,
      images: {
        // eslint-disable-next-line quote-props
        default: {
          url: null,
          alt: null,
        },
      },
    },
  ],
});
