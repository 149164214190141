import React, { FunctionComponent, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { CasinoProps, Game } from '../games.model';
import { ButtonLinkType } from '../../HeroBannerCarousel';
import { RouterAuthGuard } from '../../RouteAuthGuard';
import { GameInfo } from '../GameInfo';
import { GameLauncher } from '../GameLauncher';
import { useFetchGames } from '../../../hooks/useFetchGames';
import { transformGameData } from '../../../utils/games/games.utils';
import { useSnackbar } from '../../../hooks/snackbar';
import { APP_URLS, locale } from '../../../consts';
import { Spinner } from '../../shared/Spinner';

type CasinoRoutesProps = {
  game?: Game;
  isGameInitialised: boolean;
} & CasinoProps;

/**
 * Route configuration and login handling for Casino SPA.
 */
const CasinoRoutes: FunctionComponent<CasinoRoutesProps> = ({ game, isGameInitialised, gameEnabled }) => {
  const auth = useAuth();
  const location = useLocation();
  const isUserAuthenticated = auth?.isAuthenticated;

  useEffect(() => {
    if (isUserAuthenticated || location.pathname !== '/real') return;

    auth.signinRedirect();
  }, [location.pathname, isUserAuthenticated, auth]);

  return (
    <Routes>
      <Route
        path="/real/*"
        element={<RouterAuthGuard redirectTo="/">{game && <GameLauncher game={game} mode="real" />}</RouterAuthGuard>}
      />
      <Route
        path="/"
        element={
          game && isGameInitialised ? (
            <GameInfo
              game={game}
              gameEnabled={gameEnabled}
              buttonLinkType={ButtonLinkType.GAME}
              isGameInitialised={isGameInitialised}
              isUserAuthenticated={isUserAuthenticated}
            />
          ) : (
            <div className="spinner-wrapper">
              <Spinner />
            </div>
          )
        }
      />
    </Routes>
  );
};

/**
 * Casino mini SPA to view & launch a game.
 */
export const Casino: FunctionComponent<CasinoProps> = (props) => {
  const navigate = useNavigate();
  const { addSnack } = useSnackbar();
  const { name } = useParams();
  const [game, setGame] = useState<Game | undefined>(undefined);
  const { fetchGameBySlug, fetchedGameBySlugLoading } = useFetchGames({
    // TODO: replace hardcoded language value with dynamic
    slug: name,
    language: locale,
    onFetchGameBySlugComplete: (data) => {
      const transformedData = transformGameData(data.game[0]);

      if (!transformedData) {
        addSnack({
          type: 'error',
          message: `Oops, something's gone wrong`,
          theme: 'dark',
        });
        navigate(APP_URLS.home);

        return;
      }

      setGame(transformedData);
    },
  });

  useEffect(() => {
    (async (): Promise<void> => {
      await fetchGameBySlug();
    })();
  }, [name]);

  return <CasinoRoutes {...props} game={game} isGameInitialised={!fetchedGameBySlugLoading} />;
};
