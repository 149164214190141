type UseClickableElement<E> = {
  role: string;
  tabIndex: number;
  onClick: () => void;
  onKeyDown: (e: React.KeyboardEvent<E>) => void;
  onKeyUp: (e: React.KeyboardEvent<E>) => void;
};

/**
 * A hook that returns attributes which enable an element that isn't a <button> (e.g. <div>) to be clickable in an accessible way.
 */
export function useClickableElement<T>(onClick: () => void): UseClickableElement<T> {
  function handleKeydown(e: React.KeyboardEvent<T>): void {
    /* istanbul ignore else */
    if (e.key === ' ') {
      e.preventDefault();
    } else if (e.key === 'Enter') {
      e.preventDefault();
      onClick && onClick();
    }
  }

  function handleKeyup(e: React.KeyboardEvent<T>): void {
    if (e.key === ' ') {
      e.preventDefault();
      onClick && onClick();
    }
  }

  return {
    role: 'button',
    tabIndex: 0,
    onClick: onClick,
    onKeyDown: handleKeydown,
    onKeyUp: handleKeyup,
  };
}
