export const preventScrollInIos = (
  isNavigationExpanded: boolean,
  scrollPosition: number,
  setPosition: (scrollPosition: number) => void
): void => {
  const docElement = document.documentElement;

  if (isNavigationExpanded) {
    const updatedScrollPosition = window.scrollY;

    setPosition(updatedScrollPosition);
    docElement.classList.add('fixed-body');
    docElement.style.setProperty('--scroll-top', `-${updatedScrollPosition}px`);
  } else {
    docElement.classList.remove('fixed-body');
    docElement.style.setProperty('--scroll-top', '0');
    window.scroll(0, scrollPosition);
  }
};
