import React, { FunctionComponent } from 'react';
import noop from 'lodash/noop';
import { Button } from '../../../shared/Button';
import { useAuth } from 'react-oidc-context';
import { useSignIn } from '../../../../hooks/auth';

type PromotionClaimButtonProps = {
  label?: string;
  style?: React.CSSProperties;
  className?: string;
  onActivateBonus?: () => void;
};

export const PromotionClaimButton: FunctionComponent<PromotionClaimButtonProps> = ({
  label,
  style,
  className,
  onActivateBonus = noop,
}): JSX.Element => {
  const auth = useAuth();
  const signIn = useSignIn();

  const isUserAuthenticated = auth?.isAuthenticated;

  const onClaimClick = (): void => {
    isUserAuthenticated ? onActivateBonus() : signIn();
  };

  return (
    <Button type="button" variant="primary" onClick={onClaimClick} style={style} className={className}>
      {label || ''}
    </Button>
  );
};
