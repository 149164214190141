import React from 'react';
import { Mode as FormValidationMode, RegisterOptions as ValidationRules } from 'react-hook-form';
import {
  BaseRegistrationStepConfig,
  FlattenedProfileData,
  UseRegistrationStepperReturnValue,
} from '../../hooks/registration';
import { FormField } from '../shared/Form';
import { ButtonSize } from '../shared/Button';
import { HeadingLevel } from '../shared/Heading';
import { FieldErrorSummary } from '../../utils/form';
import { GridColumnAmount } from '../shared/Grid';
import { PasswordRequirement, PasswordStrength } from '../shared/PasswordInput';
import { SelectOption } from '../shared/Select';

export type ValidationSettings = {
  rules: ValidationRules;
  requirements?: ValidationRequirements;
};

export type UserValidationConstraints = {
  minimumAge: number;
  postalCodePattern: RegExp;
  phoneNumberPattern: RegExp;
  usernamePattern: RegExp;
  emailPattern: RegExp;
  maxUsernameLength: number;
  minUsernameLength: number;
  addressLinePattern: RegExp;
  addressLinePatternFirstTwoInput: RegExp;
  placeOfBirthPattern: RegExp;
  countryCodeOptions: SelectOption[];
  name: {
    maxNameLength: number;
    minNameLength: number;
    namePattern: RegExp;
    customValidate?: (value: string) => Promise<{ isValid: boolean; translationKey?: string }>;
  };
  password: {
    /**
     * Can be used to do custom async validation if needed.
     */
    customValidatePassword?: (
      password: string,
      blacklistedPasswords?: string[]
    ) => Promise<{ isValid: boolean; translationKey?: string }>;
    scorePassword?: (password: string) => PasswordStrength;
    rules: ValidationRules;
    requirements: ValidationRequirements;
  };
  email: {
    customValidate?: (value: string, initialValue: unknown) => Promise<{ isValid: boolean; translationKey?: string }>;
  } & ValidationSettings;
  username: ValidationSettings;
};

export type AbandonPromptConfig = {
  abandonPromptTitle?: string;
  abandonPromptMessage?: string;
};

export enum ManualSearchKeys {
  Edit = 'edit',
  Show = 'show',
}

export enum RegistrationStepIds {
  AccountDetails = 'account-details',
  PersonalDetails = 'personal-details',
  AddressDetails = 'address-details',
  Success = 'success',
  Error = 'error',
  IpAddressError = 'ip-address-error',
  VerificationError = 'verification-error',
}

export type PasswordRequirementType =
  | 'MustIncludeCharacterLength'
  | 'MustIncludeLetter'
  | 'MustIncludeNumber'
  | 'MustIncludeSpecialCharacter'
  | 'MustIncludeLowerCaseLetter'
  | 'MustIncludeUpperCaseLetter'
  | 'MustNotIncludeUserName'
  | 'MustNotIncludeFirstName'
  | 'MustNotIncludeEmail'
  | 'MustNotIncludeTrailing'
  | 'MustNotIncludePreviousPassword';

export type ValidationRequirements = {
  mustInclude: Partial<Record<PasswordRequirementType, PasswordRequirement>>;
  mustNotInclude: Partial<Record<PasswordRequirementType, PasswordRequirement>>;
};

export enum MarketingItemValue {
  Accepted = 'Accepted',
  OptedOut = 'OptedOut',
}

export type MarketingData = {
  optInEmail: boolean;
  optInSms: boolean;
  optInTelephone: boolean;
};

export type AddressData = {
  addrStreetAddress: string;
  addrLocality: string;
  addrRegion: string;
  addrPostalCode: string;
  addrCountryCode: string;
};

export type RegistrationData = {
  honorificPrefix?: HonoricTitleOptions;
  gender: GenderOptions;
  givenName: string;
  familyName: string;
  password: string;
  birthDate: string;
  email: string;
  mobilePrefix: string;
  mobileNumber: string;
  address: AddressData;
  acceptTerms: boolean;
  acceptMarketingPreferences: boolean;
  promoCode: string;
  currencyCode: string;
};

export enum GenderOptions {
  Male = 'Male',
  Female = 'Female',
  Others = 'Others',
}

export enum HonoricTitleOptions {
  Mr = 'Mr',
  Ms = 'Ms',
  Mrs = 'Mrs',
  Others = 'Others',
}

export interface ErrorData {
  code: string;
  error?: string;
  title: string;
  detail: string;
  reason?: string;
}

export interface PlayerSession {
  access_token: string;
  expires_in: number;
  token_type: string;
  refresh_token: string;
}

export type RegistrationStepperProps = Record<string, unknown>;
/**
 * Stepper component is just a presentational container component for the moment but this type
 * has been added in case extra functionality is needed.
 *
 * Any custom stepper component must conform to this type.
 */
export type RegistrationStepperComponent = React.ComponentType<React.PropsWithChildren<RegistrationStepperProps>>;

/**
 * Props for a registration step component.  Whether it be a form, review etc.
 */
export type RegistrationStepComponentProps = {
  /**
   * General registration step configuration
   */
  stepConfig: RegistrationStepConfig;
  /**
   * Selected registration step configuration
   */
  selectedStep?: BaseRegistrationStepConfig;
  /**
   * Actual step index in overall list of registration steps.
   */
  stepIndex: number;
  /**
   * Index of previous step - if any.
   */
  previousStepIndex?: number;
  /**
   * All registration steps. Can be used to refer to other steps, an example being the review step.
   */
  stepConfigs: RegistrationStepConfig[];
  /**
   * Current registration state. (Default form state with potential updates applied by previous form steps steps)
   */
  formState: FlattenedProfileData;
  /**
   * A map of all registration field configurations.  To be used to render form controls for current stepConfig.
   */
  formFields: Record<string, FormField<FlattenedProfileData>>;
  /**
   * Whether currently selected. Component should be hidden if false.
   */
  selected: boolean;
  /**
   * Defaults to onBlur.
   */
  validationMode?: FormValidationMode;
  /**
   * Defaults to onBlur.
   */
  reValidateMode?: Exclude<FormValidationMode, 'onTouched' | 'all'>;
  /**
   * List of errors returned from registration submission.
   *
   * Currently only handled by error steps.
   * Extra handling could be put in place in case validation errors slip through the cracks.
   */
  errors?: ErrorData[];
  /**
   * Will be used to determine whether the component is represented as a tabpanel
   */
  stepperEnabled?: boolean;
  /**
   * Custom button size if needed.
   */
  buttonSize?: ButtonSize;
  /**
   * Custom heading size if needed.
   */
  headingLevel?: HeadingLevel;
  /**
   * Optional custom form children which can use the form context if needed.
   */
  formChildren?: React.ReactNode;
  /**
   * Will only be set in success step because it won't exist otherwise.
   *
   * Can be used to handle autoLogin if needed.
   */
  registrationSuccessResponse?: PlayerSession;
  /**
   * Optional children that can be pasted inside a step fieldset
   */
  fieldSetChildren?: { render: React.ReactNode; order: number };
  /**
   * Will clean error with using useForm hook
   */
  errrorToClear?: string;
  /**
   * Custom header content block
   */
  showSecureShield?: boolean;
  /**
   * Intro text between the title and fields
   */
  introText?: string;
  /**
   * Will be called when component is unmounted to save current form state.
   *
   * Currently used to save form state
   * when registration step routing is enabled and a user navigates to a previous step.
   */
  onUnmount?: (data: Partial<FlattenedProfileData>) => void;
  /**
   * Will be called when form state changes form valid to invalid and vice versa.
   *
   * Can be used to disable other steps etc.
   */
  onFormValidityChange?: (valid: boolean) => void;
  /**
   * Called when there is a form validation error. Can be used to trigger custom behavior.
   */
  onFormError?: (summary: Record<string, FieldErrorSummary>, formState: Partial<FlattenedProfileData>) => void;
  /**
   * Will be used to navigate to navigate to previous step when the used clicks back.
   */
  onSelectPreviousStep?: () => void;
  /**
   * Will be used in review step or to link to other steps in a custom implementation.
   */
  onSelectStep?: (stepId: string, firstStep?: boolean) => void;
  /**
   * Will be called when a user clicks continue and the form data, if a form step, is valid.
   */
  onCompleteStep?: (stepIndex: number, data: Partial<FlattenedProfileData>) => void | Promise<void>;
  /**
   * Click the link
   */
  onClick?: () => void;
  /**
   * Will be used in IntroductionStep to display AbandonPrompt and logged out a user
   */
  setAbandonPromptOpen?: (arg: boolean) => void;
} & Partial<UseRegistrationStepperReturnValue>;

/**
 * Any custom registration step components must conform to this component typing.
 */
export type RegistrationStepComponent = React.ComponentType<React.PropsWithChildren<RegistrationStepComponentProps>>;

/**
 * Available props for registration step components.
 */
export type RegistrationStepperStepComponentProps = {
  /**
   * General registration step configuration
   */
  stepConfig: RegistrationStepConfig;
  /**
   * True if the current step active.
   */
  selected?: boolean;
  /**
   * True if the current step is completed. ie form valid.
   */
  completed?: boolean;
  /**
   * True if step should be disabled.  ie previous step is not completed
   */
  disabled?: boolean;
  /**
   * Current step number. To be used for display purposes
   */
  stepNumber: number;
  /**
   * Actual step index in overall list of registration steps.
   */
  stepIndex: number;
  /**
   * Number of registration steps.  Can be used in stepper step component.
   */
  stepCount: number;
  /**
   * Custom Registration step button size. Optional.
   */
  buttonSize?: ButtonSize;
  /**
   * True if this is the end stepper step.  Even if there are further stages such as success etc.
   */
  lastStep?: boolean;
  /**
   * When a the step is selected by user in non router mode.
   */
  onSelectStep?: (stepId: string) => void;
};

export type RegistrationFieldConfig = Pick<
  FormField<Partial<FlattenedProfileData>>,
  'name' | 'optional' | 'disabled' | 'hidden'
> & {
  /**
   * Grid column spans for to be used for Form field layout.  The default RegistrationFormStep component
   * has 12 total column sections. So a value of [6,6] would display a first row split into
   * 2 columns with a span of 6 each.
   */
  columns?: GridColumnAmount;
  /**
   * Grid column spans for step if summary step is used.  The default review component
   * has 12 total column sections. So a value of [6,6] would display a first row split into
   * 2 columns with a span of 6 each.
   */
  overviewColumns?: GridColumnAmount;
};

export type RegistrationFieldSetConfig = {
  /**
   * A unique string id for the fieldset.
   */
  id: string;
  /**
   * A label for form field set legend.  Optional.
   */
  label?: string;
  /**
   * Whether the label should be visible for screen reader only.
   */
  labelSrOnly?: boolean;
  /**
   * List of field configurations to be used in form inputs for field set.
   */
  fields: RegistrationFieldConfig[];
};

/**
 * Type which any custom version of the stepper step display must conform to.
 */
export type RegistrationStepperStepComponent = React.ComponentType<RegistrationStepperStepComponentProps>;

/**
 * Configuration for a registration step.
 *
 * Note that this extends BaseRegistrationStepConfig which is used in the useRegistration hook.
 */
export type RegistrationStepConfig = {
  /**
   * Main title for registration step.
   */
  title: string;
  /**
   * Short title to be used in stepper.
   */
  shortTitle: string;
  /**
   * Intro text to be used in stepper.
   */
  introText?: string | React.ReactNode;
  /**
   * A component to handle step functionality.  Must conform to RegistrationStepComponent type.
   */
  StepComponent: RegistrationStepComponent;
  /**
   * A component to render the stepper step information.  Must confirm to RegistrationStepperStepComponent type.
   */
  StepperStepComponent?: RegistrationStepperStepComponent;
  /**
   * Optional text to be placed before form step action buttons.
   * Can either be a string, a key which will be translated or a react node.
   */
  preSubmitText?: string | React.ReactNode;
  /**
   * Optional submit button text.  Will default to key translation.
   */
  submitButtonText?: string;
  /**
   * Custom registration form button size.
   */
  buttonSize?: ButtonSize;
  /**
   * List of fields by name to display if StepComponent is a form step component.
   */
  fieldSets: RegistrationFieldSetConfig[];
  customTitle?: string;
  customSubTitle?: string;

  isUserHaveAccountText?: boolean;
} & BaseRegistrationStepConfig;
