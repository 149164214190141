import { User } from 'oidc-client-ts';
import { config } from '../components/Authentification/config';

export function getUser(): User | undefined {
  const key = `oidc.user:${config.authUrl}/realms/${config.realm}:${config.clientId}`;

  try {
    const oidcStorage = localStorage.getItem(key) ?? sessionStorage.getItem(key);

    if (!oidcStorage) {
      return;
    }

    return User.fromStorageString(oidcStorage);
  } catch {
    return;
  }
}

export function getToken(): string {
  const user = getUser();

  return user?.access_token ?? user?.access_token ?? '';
}
