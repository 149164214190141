import { useCallback } from 'react';
import { useAuth } from 'react-oidc-context';
import { useFullLocation } from '../routing';
import { User } from 'oidc-client-ts';

export const useSignIn = (): ((params?: {
  acrValues?: string;
  returnQueryParameters?: Record<string, string>;
}) => Promise<void> | Promise<User>) => {
  const auth = useAuth();
  const fullLocation = useFullLocation();

  return useCallback(
    (params?: { acrValues?: string; returnQueryParameters?: Record<string, string> }) => {
      const url = new URL(fullLocation);
      const newParams = new URLSearchParams(url.search);

      if (params?.returnQueryParameters) {
        Object.entries(params?.returnQueryParameters).forEach(([key, value]) => {
          newParams.set(key, value);
        });
      }

      url.search = newParams.toString();
      const isPopup = !!params?.acrValues;
      const signupFunction = isPopup ? auth.signinPopup : auth.signinRedirect;

      return signupFunction.bind(auth)({
        redirect_uri: url.toString(),
        ...(params?.acrValues
          ? {
              extraQueryParams: { acr_values: params.acrValues },
            }
          : {}),
      });
    },
    [auth, fullLocation]
  );
};
