import React, { FC } from 'react';
import { NavigationTemplate } from '../../NavigationTemplate';
import { SportsbookContainer } from '../../../components/SportsbookContainer';
import './Home.scss';

export const Home: FC = () => {
  return (
    <div className="content-wrapper">
      <NavigationTemplate isDarkBackground paddingDisabled isSportsBook>
        <SportsbookContainer />
      </NavigationTemplate>
    </div>
  );
};
