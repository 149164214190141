import { NavigateFunction } from 'react-router-dom';

export const redirectHelper = (navigateProp: NavigateFunction): void => {
  const searchParams = new URLSearchParams(window.location.search);

  searchParams.delete('state');
  searchParams.delete('session_state');
  searchParams.delete('code');
  searchParams.delete('kc_action_status');

  navigateProp({
    pathname: window.location.pathname,
    search: searchParams.toString(),
  });
};
