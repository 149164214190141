import debounce from 'lodash.debounce';
import { useEffect, useState } from 'react';
import { passiveOption } from '../../consts';

export enum MediaBreakpoint {
  MOBILE,
  BELOW_MOBILE,
  TINY_MOBILE,
  TABLET,
  BELOW_TABLET,
  BELOW_MID_DESKTOP,
  ABOVE_MID_DESKTOP,
  DESKTOP,
  BELOW_DESKTOP,
  ABOVE_DESKTOP,
  BIG_DESKTOP,
  BELOW_BIG_DESKTOP,
}

export enum Orientation {
  LANDSCAPE,
  PORTRAIT,
}

export type DeviceInformation = {
  isIos: boolean;
  isMobileDevice: boolean;
  isTablet: boolean;
  isVersion(val: number): boolean;
  isMacOs: boolean;
  isNewerIpad: boolean;
};

export type WindowDimensions = {
  width: number;
  height: number;
};

const knownQueries = {
  BIG_DESKTOP: `(max-width:2560px) and (min-width:1200px)`,
  BELOW_BIG_DESKTOP: '(max-width:1199px)',
  DESKTOP: '(max-width:1200px) and (min-width:993px)',
  ABOVE_DESKTOP: '(max-width:1919px) and (min-width:1201px)',
  BELOW_DESKTOP: '(max-width:1199px)',
  ABOVE_MID_DESKTOP: '(min-width:1025px)',
  BELOW_MID_DESKTOP: '(max-width:1024px)',
  TABLET: `(max-width:960px) and (min-width:601px)`,
  BELOW_TABLET: '(max-width:959px)',
  MOBILE: `(max-width:600px)`,
  BELOW_MOBILE: '(max-width:599px)',
  TINY_MOBILE: '(max-width: 389px)',
  LANDSCAPE: `(orientation:landscape)`,
  HOVER: '(hover: hover)',
};

export function getBreakpointMediaQueries(breakpoints: MediaBreakpoint[]): MediaQueryList[] {
  const mediaQueries = [];

  if (breakpoints.includes(MediaBreakpoint.BIG_DESKTOP)) {
    mediaQueries.push(window.matchMedia(knownQueries.BIG_DESKTOP));
  }

  if (breakpoints.includes(MediaBreakpoint.BELOW_BIG_DESKTOP)) {
    mediaQueries.push(window.matchMedia(knownQueries.BELOW_BIG_DESKTOP));
  }

  if (breakpoints.includes(MediaBreakpoint.ABOVE_DESKTOP)) {
    mediaQueries.push(window.matchMedia(knownQueries.ABOVE_DESKTOP));
  }

  if (breakpoints.includes(MediaBreakpoint.DESKTOP)) {
    mediaQueries.push(window.matchMedia(knownQueries.DESKTOP));
  }

  if (breakpoints.includes(MediaBreakpoint.BELOW_DESKTOP)) {
    mediaQueries.push(window.matchMedia(knownQueries.BELOW_DESKTOP));
  }

  if (breakpoints.includes(MediaBreakpoint.ABOVE_MID_DESKTOP)) {
    mediaQueries.push(window.matchMedia(knownQueries.ABOVE_MID_DESKTOP));
  }

  if (breakpoints.includes(MediaBreakpoint.BELOW_MID_DESKTOP)) {
    mediaQueries.push(window.matchMedia(knownQueries.BELOW_MID_DESKTOP));
  }

  if (breakpoints.includes(MediaBreakpoint.TABLET)) {
    mediaQueries.push(window.matchMedia(knownQueries.TABLET));
  }

  if (breakpoints.includes(MediaBreakpoint.BELOW_TABLET)) {
    mediaQueries.push(window.matchMedia(knownQueries.BELOW_TABLET));
  }

  if (breakpoints.includes(MediaBreakpoint.MOBILE)) {
    mediaQueries.push(window.matchMedia(knownQueries.MOBILE));
  }

  if (breakpoints.includes(MediaBreakpoint.BELOW_MOBILE)) {
    mediaQueries.push(window.matchMedia(knownQueries.BELOW_MOBILE));
  }

  if (breakpoints.includes(MediaBreakpoint.TINY_MOBILE)) {
    mediaQueries.push(window.matchMedia(knownQueries.TINY_MOBILE));
  }

  return mediaQueries;
}

export function doesBreakpointMatch(mediaQueries: MediaQueryList[]): boolean {
  return mediaQueries.filter((query) => query.matches).length > 0;
}

export function useBreakpointInfo(breakpoints: MediaBreakpoint[]): boolean {
  const mediaQueries = getBreakpointMediaQueries(breakpoints);

  const [breakpointMatch, setBreakpointMatch] = useState(doesBreakpointMatch(mediaQueries));

  useEffect(() => {
    const listener = debounce(() => setBreakpointMatch(doesBreakpointMatch(mediaQueries)), 20);

    mediaQueries.forEach((query) => {
      query.addEventListener('change', listener, passiveOption);
    });

    return () =>
      mediaQueries.forEach((query) => {
        query.removeEventListener('change', listener, passiveOption as EventListenerOptions);
      });
  }, [mediaQueries]);

  return breakpointMatch;
}

export function useOrientation(): Orientation {
  return window.matchMedia(knownQueries.LANDSCAPE).matches ? Orientation.LANDSCAPE : Orientation.PORTRAIT;
}

export function useDeviceInfo(): DeviceInformation {
  const isMacOs = /(macintosh)/i.test(navigator.userAgent.toLowerCase());
  const canHover = window.matchMedia('(hover: hover)')?.matches;
  const isNewerIpad = isMacOs && !canHover;
  const isIos = isNewerIpad || /iPad|iPhone|iPod/.test(navigator.userAgent);
  const isTablet =
    // eslint-disable-next-line max-len
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      navigator.userAgent.toLowerCase()
    ) || isNewerIpad;
  const isMobileDevice =
    isIos ||
    /Android|webOS|Mobile|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
      navigator.userAgent
    );

  return {
    isIos,
    isMobileDevice,
    isTablet,
    isVersion(number): boolean {
      const match = RegExp(`Version/[ ]*(${number})`, 'i');

      return match.test(navigator.userAgent);
    },
    isMacOs,
    isNewerIpad,
  };
}

export function useWindowDimensions(): WindowDimensions {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
}
