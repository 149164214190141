import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TransactionLimitsUpdateMutationVariables = Types.Exact<{
  userProfileId: Types.Scalars['ID']['input'];
  xTenantId: Types.Scalars['ID']['input'];
  limitId: Types.Scalars['ID']['input'];
  budgetLimitsReview?: Types.InputMaybe<Types.BudgetLimitsReview>;
  creatorName?: Types.InputMaybe<Types.CreatorName>;
}>;


export type TransactionLimitsUpdateMutation = { __typename?: 'Mutation', transactionLimitsUpdate?: { __typename?: 'TransactionLimitsCreateResponse', data?: { __typename?: 'TransactionLimitsCreateSuccess', id: string } | null } | null };


export const TransactionLimitsUpdateDocument = gql`
    mutation TransactionLimitsUpdate($userProfileId: ID!, $xTenantId: ID!, $limitId: ID!, $budgetLimitsReview: BudgetLimitsReview, $creatorName: CreatorName) {
  transactionLimitsUpdate(
    userProfileId: $userProfileId
    xTenantID: $xTenantId
    limitId: $limitId
    budgetLimitsReview: $budgetLimitsReview
    creatorName: $creatorName
  ) {
    data {
      id
    }
  }
}
    `;
export type TransactionLimitsUpdateMutationFn = Apollo.MutationFunction<TransactionLimitsUpdateMutation, TransactionLimitsUpdateMutationVariables>;

/**
 * __useTransactionLimitsUpdateMutation__
 *
 * To run a mutation, you first call `useTransactionLimitsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransactionLimitsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transactionLimitsUpdateMutation, { data, loading, error }] = useTransactionLimitsUpdateMutation({
 *   variables: {
 *      userProfileId: // value for 'userProfileId'
 *      xTenantId: // value for 'xTenantId'
 *      limitId: // value for 'limitId'
 *      budgetLimitsReview: // value for 'budgetLimitsReview'
 *      creatorName: // value for 'creatorName'
 *   },
 * });
 */
export function useTransactionLimitsUpdateMutation(baseOptions?: Apollo.MutationHookOptions<TransactionLimitsUpdateMutation, TransactionLimitsUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TransactionLimitsUpdateMutation, TransactionLimitsUpdateMutationVariables>(TransactionLimitsUpdateDocument, options);
      }
export type TransactionLimitsUpdateMutationHookResult = ReturnType<typeof useTransactionLimitsUpdateMutation>;
export type TransactionLimitsUpdateMutationResult = Apollo.MutationResult<TransactionLimitsUpdateMutation>;
export type TransactionLimitsUpdateMutationOptions = Apollo.BaseMutationOptions<TransactionLimitsUpdateMutation, TransactionLimitsUpdateMutationVariables>;