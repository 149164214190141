import React, { Fragment, FunctionComponent } from 'react';
import { InputValidationAlert } from './InputValidationAlert';
import { Theme, ValidationState } from '../../../types';
import { ValidationLinkDetails } from './types';
import { joinStrings } from '../../../utils/string';
import './InputValidationAlerts.scss';

export type InputValidationAlertsProps = {
  /**
   * Array of validation messages to display
   */
  messages?: {
    message: string;
    id: string;
  }[];
  /**
   * Validation state - used to style messages with appropriate colours etc.
   */
  state?: ValidationState;
  /**
   * Optional custom className.
   */
  className?: string;
  /**
   * Optional, defaults to 'light'. If set to 'dark' the alerts will be styled for placement on a dark background.
   */
  theme?: Theme;
  /**
   * Optinal, props to display the link in the validation error message
   */
  linkDetails?: ValidationLinkDetails;
};

export const InputValidationAlerts: FunctionComponent<InputValidationAlertsProps> = ({
  messages,
  className,
  state,
  theme = 'light',
  linkDetails,
}) => {
  return (
    <Fragment>
      {messages && messages.length > 0 && state && (
        <ul data-testid="input-validation-alerts" className={joinStrings(['input-validation-alerts', className])}>
          {messages.map((message) => (
            <li key={message.id}>
              <InputValidationAlert {...message} state={state} theme={theme} linkDetails={linkDetails} />
            </li>
          ))}
        </ul>
      )}
    </Fragment>
  );
};
