import React, { FC, PropsWithChildren, ReactElement, Suspense } from 'react';
import uuidv4 from '../../../utils/getUUID';

type SuspensifyProps = PropsWithChildren<{
  fallback?: React.ReactElement;
}>;

type NativeElementProps = {
  id?: string | number;
};

const suspensifyWeakMap = new WeakMap();
const getKey = <T,>(component: React.ReactElement<T, string | React.JSXElementConstructor<T>>): string | number => {
  if (component.key) {
    return component.key;
  }

  if (typeof component.type === 'string') {
    return (
      component.type +
      (((component.props as NativeElementProps).id && `#${(component.props as NativeElementProps).id}`) || '')
    );
  }

  const cachedId = suspensifyWeakMap.get(component.type);

  if (cachedId) {
    return cachedId;
  }

  const newId = uuidv4();

  suspensifyWeakMap.set(component.type, newId);

  return newId;
};

export const Suspensify: FC<SuspensifyProps> = ({ children, fallback }) => {
  return (
    <>
      {(children as Array<ReactElement>)
        ?.filter((val) => !!val)
        .map((el, i) => (
          <Suspense key={getKey(el)} fallback={i === 0 ? fallback : null}>
            {el}
          </Suspense>
        ))}
    </>
  );
};
