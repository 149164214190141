import React, { FunctionComponent } from 'react';
import { Overlay, OverlayProps } from '../Overlay';
import './SideDrawer.scss';

export type SideDrawerProps = {
  /**
   * Required. Whether the drawer is open or closed
   */
  isOpen: boolean;
} & OverlayProps;

/**
 * A component which displays content at the side of a window and covers background content with a backdrop, currently only supports being positioned at the left.
 * It uses the `Overlay` component internally which makes use of a 3rd party library called [React-modal](http://reactcommunity.org/react-modal/) which adds support for
 * layering, key bindings, focus trapping, focus restoring and hiding background content from screenreaders.
 *
 * Currently used by the `MyAccount` drawer.
 *
 *
 * ## Example Usage - Simple
 *
 * ```tsx
 * const SideDrawerExample = () => {
 *   const [drawerOpen, setDrawerOpen] = useState(false);
 *   return (
 *     <Fragment>
 *       <Button onClick={() => setDrawerOpen(true)}>Open Drawer</Button>
 *       <SideDrawer
 *         isOpen={drawerOpen}
 *         onRequestClose={() => setDrawerOpen(false)}
 *         aria-label='My Account'>
 *         My Account
 *       </SideDrawer>
 *     </Fragment>
 *   );
 * };
 * ```
 *
 */

export const SideDrawer: FunctionComponent<SideDrawerProps> = ({ isOpen, children, ...overlayProps }) => {
  return (
    <Overlay
      {...overlayProps}
      isOpen={isOpen}
      canDismiss
      closeTimeoutMS={300}
      className="side-drawer"
      role="dialog"
      backdropClassName="side-drawer-backdrop"
    >
      {children}
    </Overlay>
  );
};
