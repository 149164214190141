import mapValues from 'lodash/mapValues';
import { RegisterOptions as ValidationRules } from 'react-hook-form';
import { minDepositLimit, useUserValidationConstraints } from '../../Registration/const';

type GamblingControlValidationRuleMap = Record<
  string,
  | ValidationRules
  | ((
      watch: (names: string) => unknown,
      triggerValidation: (fieldName: string) => void,
      initialValue: unknown
    ) => ValidationRules)
>;

/**
 *  Get form validation rules for gambling control components
 */
export const useGamblingControlsValidationRules = (): GamblingControlValidationRuleMap => {
  const userValidationConstraints = useUserValidationConstraints();

  const getRule = (rule?: ValidationRules): ValidationRules => {
    if (!rule) return {};

    return mapValues(rule, (rule: { value: number; message: string }) => {
      return {
        message: rule.message,
        value: rule.value,
      };
    });
  };

  return {
    dailyDeposit: (watch): ValidationRules => {
      return {
        ...getRule(userValidationConstraints.limits?.dailyDeposit?.rules as ValidationRules),
        validate: {
          trigger(): string | undefined {
            const dailyLimit = watch('dailyLimit');

            if (minDepositLimit && dailyLimit !== null && Number(dailyLimit) < minDepositLimit) {
              return `Limit must be higher than the minimum ${minDepositLimit} amount`;
            }

            if (dailyLimit === null) {
              return undefined;
            }
          },
        },
      };
    },
    weeklyDeposit: (watch): ValidationRules => {
      return {
        ...getRule(userValidationConstraints.limits?.weeklyDeposit?.rules as ValidationRules),
        validate: {
          validateWeekly(weeklyLimit: string): string | undefined {
            const dailyLimit = Number(watch('dailyLimit'));

            if (weeklyLimit === null) {
              return undefined;
            }

            if (dailyLimit > Number(weeklyLimit)) {
              return 'Weekly limit must be higher than daily limit';
            }
          },
        },
      };
    },
    monthlyDeposit: (watch): ValidationRules => {
      return {
        ...getRule(userValidationConstraints.limits?.monthlyDeposit?.rules as ValidationRules),
        validate: {
          validateMonthly(monthlyLimit: string): string | undefined {
            const dailyLimit = Number(watch('dailyLimit'));
            const weeklyLimit = Number(watch('weeklyLimit'));

            if (monthlyLimit === null) {
              return undefined;
            }

            if (dailyLimit > Number(monthlyLimit)) {
              return 'Monthly limit must be higher than daily limit';
            } else if (weeklyLimit > Number(monthlyLimit)) {
              return 'Monthly limit must be higher than weekly limit';
            }
          },
        },
      };
    },
  };
};
