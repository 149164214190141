import React, { FC } from 'react';
import noop from 'lodash/fp/noop';
import { Button, ButtonProps } from '../Button';
import { joinStrings } from '../../../utils/string';
import { cashierModal } from '../../../state/cashier';
import { useSelector } from 'react-redux';
import { useTranslations } from '../../../hooks/useTranslationsHelper';

type DepositButtonProps = {
  onClick?: () => void;
} & Partial<ButtonProps>;

export const DepositButton: FC<DepositButtonProps> = ({ size, className, onClick = noop }) => {
  const { t } = useTranslations();
  const isDepositButtonLoading = false;
  const enablePartialAccountDisplayMode = useSelector(cashierModal);

  const onDepositBtnClick = (): void => {
    onClick();
  };

  return (
    <Button
      size={size}
      type="button"
      variant="primary"
      className={joinStrings([
        className,
        isDepositButtonLoading && 'button--loading',
        enablePartialAccountDisplayMode.isOpen && 'button--disabled',
      ])}
      onClick={onDepositBtnClick}
      isSubmitting={isDepositButtonLoading}
    >
      {t('my-account.deposit')}
    </Button>
  );
};
