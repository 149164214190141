/* eslint-disable max-len */
import React, { FC } from 'react';
import './styles';

export const Logo: FC = () => {
  return (
    <svg
      className="logo"
      fill="none"
      height="20px"
      viewBox="0 0 117 20"
      width="117px"
      xmlns="http://www.w3.org/2000/svg"
      data-component="BetZoneLogo"
    >
      <path
        fill="#09FB02"
        d="M12.31 5.671c0 .45-.077.895-.225 1.32-.145.42-.346.818-.598 1.183a5.792 5.792 0 01-1.823 1.708 5.96 5.96 0 012.186 1.602c.307.376.552.8.724 1.254.189.495.282 1.021.276 1.551.01.74-.152 1.473-.471 2.14a5.188 5.188 0 01-1.309 1.675 6.234 6.234 0 01-1.95 1.09c-.77.266-1.58.398-2.394.392H.64a.58.58 0 01-.46-.203.641.641 0 01-.181-.422V1.16A.64.64 0 01.181.737.615.615 0 01.64.534h5.737a6.732 6.732 0 012.337.414 5.975 5.975 0 011.88 1.09c.525.448.952 1 1.254 1.62.306.627.464 1.316.46 2.013zM3.678 4.004c.054 0 .083-.026.083-.08v.08h-.083zm.083 4.548c0-.036-.029-.072-.083-.109h.083v.11zm-.083 3.107c.054 0 .083-.026.083-.08v.08h-.083zm.083 4.603c0-.018-.029-.044-.083-.08h.083v.08zm0-12.258v4.44h2.283a2.115 2.115 0 001.616-.655c.41-.443.63-1.03.613-1.635.014-.29-.034-.58-.14-.851a2.067 2.067 0 00-.473-.722c-.44-.4-1.021-.61-1.616-.585l-2.283.008zm0 7.655v4.523h2.671a2.321 2.321 0 001.66-.654 2.212 2.212 0 00.681-1.664c0-.305-.077-.605-.225-.872a2.332 2.332 0 00-.598-.709 2.779 2.779 0 00-.862-.46 3.06 3.06 0 00-.99-.164H3.762zM9.56 9.842v.026a.15.15 0 00-.112-.055l.112.03zm-.029.135h.083a.055.055 0 00-.04 0c-.009.002-.018.003-.026.002a.05.05 0 01-.025-.01l.007.008zm9.961-5.944V8.17h6.127a.639.639 0 01.461.207.607.607 0 01.206.421v2.26a.57.57 0 01-.207.465.72.72 0 01-.46.16h-6.127v4.443h7.464a.564.564 0 01.471.204.683.683 0 01.167.45v2.206a.645.645 0 01-.167.421.57.57 0 01-.47.204h-10.61a.573.573 0 01-.476-.204.649.649 0 01-.166-.421V1.184a.662.662 0 01.166-.421.591.591 0 01.475-.204h10.61a.584.584 0 01.471.204.657.657 0 01.167.421v2.21a.676.676 0 01-.377.606.567.567 0 01-.26.047l-7.465-.014zM44.343.545a.58.58 0 01.456.203.626.626 0 01.182.422v2.209a.644.644 0 01-.182.447.579.579 0 01-.456.207H39.44v14.954a.634.634 0 01-.181.421.604.604 0 01-.486.204h-2.478a.637.637 0 01-.461-.204.595.595 0 01-.21-.421V4.033h-4.928a.648.648 0 01-.46-.207.61.61 0 01-.207-.447v-2.21a.608.608 0 01.207-.42c.12-.127.286-.2.46-.204h13.647z"
      ></path>
      <path
        fill="#fff"
        d="M100.677.545c.165.008.32.081.431.203a.61.61 0 01.21.422v18.09a.588.588 0 01-.21.435.639.639 0 01-.431.19h-.446a1.334 1.334 0 01-.946-.408L88.646 8.007l-.025 10.98a.634.634 0 01-.181.421.586.586 0 01-.461.204h-2.507a.621.621 0 01-.432-.204.607.607 0 01-.206-.421V.897a.577.577 0 01.206-.436.647.647 0 01.432-.189h.5a1.23 1.23 0 01.92.407h-.025l10.577 11.263.03-10.761a.64.64 0 01.18-.422.596.596 0 01.461-.203l2.562-.011zm-2.979 11.626h-.058.058zm10.563-8.138V8.17h6.124a.62.62 0 01.251.056c.079.036.15.087.209.15a.583.583 0 01.21.422v2.26a.559.559 0 01-.21.465.72.72 0 01-.46.16h-6.124v4.443h7.461a.57.57 0 01.475.204.706.706 0 01.166.45v2.206a.664.664 0 01-.38.579.584.584 0 01-.261.046h-10.61a.57.57 0 01-.471-.204.65.65 0 01-.167-.421V1.184a.657.657 0 01.379-.578.574.574 0 01.259-.047h10.61a.6.6 0 01.475.204.675.675 0 01.166.421v2.21a.697.697 0 01-.166.446.576.576 0 01-.475.207l-7.461-.014zM71.912 0c-5.537 0-10.027 4.389-10.027 9.81 0 5.42 4.49 9.809 10.027 9.809 5.537 0 10.023-4.389 10.023-9.81 0-5.42-4.5-9.809-10.023-9.809zm0 15.844a6.025 6.025 0 01-5.484-3.823 6.063 6.063 0 011.393-6.55 6.022 6.022 0 019.248.985 6.058 6.058 0 011.003 3.35 6.132 6.132 0 01-1.838 4.293 6.1 6.1 0 01-4.322 1.745z"
      ></path>
      <path
        fill="#fff"
        d="M72.358 19.408a.617.617 0 00.18-.421v-2.29a.618.618 0 00-.181-.461.601.601 0 00-.457-.192l-7.776-.022a10.084 10.084 0 007.063 3.59h.725a.564.564 0 00.445-.204zM60.4 16.011l-7.817-.022 4.262-7.08C57.38 8 57.91 7.114 58.432 6.252c.518-.865.993-1.639 1.42-2.33.484-.799.947-1.553 1.392-2.263.185-.363.181-.64 0-.828a.912.912 0 00-.652-.287H47.474a.657.657 0 00-.46.203.615.615 0 00-.206.422v2.289a.602.602 0 00.206.465.67.67 0 00.46.189h8.102a256.83 256.83 0 00-2.507 4.018l-1.96 3.186-1.504 2.453c-.428.702-.79 1.308-1.087 1.816l-.613 1.007c-.315.526-.583.973-.808 1.337-.098.18-.21.352-.333.515a.805.805 0 00-.153.545c.024.14.093.268.196.364.1.1.225.17.362.203.109.033.22.052.334.055h15.883a13.003 13.003 0 01-2.986-3.6z"
      ></path>
      <path
        fill="#EB6708"
        d="M71.901 13.417c2.037 0 3.689-1.617 3.689-3.611 0-1.995-1.652-3.612-3.689-3.612s-3.689 1.617-3.689 3.612c0 1.994 1.652 3.611 3.69 3.611z"
      ></path>
    </svg>
  );
};
