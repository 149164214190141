import flatMap from 'lodash/flatMap';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import { FieldError, FieldErrors, ValidateResult } from 'react-hook-form';
import { ValidationMessage } from './general';

const validateResultToMessages = (
  validationResult: ValidateResult | undefined,
  baseId: string
): ValidationMessage[] => {
  switch (typeof validationResult) {
    case 'string':
      return [
        {
          message: validationResult,
          id: baseId,
        },
      ];
    case 'object':
      return validationResult.map((message, i) => ({
        message,
        id: `${baseId}-${i}`,
      }));
    default:
      return [];
  }
};

/**
 * Extract a list of message strings from a FieldError object
 * @param fieldError React Hook Form FieldError object
 * @param baseMessageId A base id string to use for message id generation
 */
export const fieldErrorToMessages = (fieldError: FieldError, baseMessageId: string): ValidationMessage[] => {
  if (fieldError.types) {
    return flatMap(
      map(fieldError.types, (validationResult, type) =>
        validateResultToMessages(validationResult, `${baseMessageId}-${type}`)
      )
    );
  }

  return typeof fieldError.message === 'string'
    ? [
        {
          message: fieldError.message || fieldError.type,
          id: `${baseMessageId}-${fieldError.type}`,
        },
      ]
    : [];
};

export type FieldErrorSummary = {
  fieldName: string;
  messages: ValidationMessage[];
  label: string;
};

/**
 * Convert field errors into a more usable format with a list of messages.
 * @param fieldErrors React Hook Form FieldErrors object
 * @param labels Record of labels by form field name
 * @param baseMessageId An id string to use as a base value for message id generation
 */
export const errorsToErrorSummary = (
  fieldErrors: FieldErrors,
  labels: Record<string, string>,
  baseMessageId: string
): Record<string, FieldErrorSummary> =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  mapValues<FieldErrors, FieldErrorSummary>(fieldErrors, (error: FieldError, fieldName: string) => ({
    fieldName,
    label: labels[fieldName],
    messages: fieldErrorToMessages(error, `${baseMessageId}-${fieldName}`),
  }));
