import React, { FC } from 'react';
import { joinStrings } from '../../../utils/string';
import { Theme } from '../../../types';
import './Spinner.scss';

export type SpinnerProps = {
  /**
   * Dark or light mode. Defaults to light
   */
  theme?: Theme;
};

/**
 * Spinner SVG component with a light & dark mode.
 * Adapted from this CodePen https://codepen.io/nunoooo/pen/XWrGMym linked in the Figma login designs
 */
export const Spinner: FC<SpinnerProps> = ({ theme = 'light' }) => {
  return (
    <svg className="spinner" width="1em" height="1em" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      <circle
        className={joinStrings(['spinner__path', theme === 'dark' && 'spinner__path--dark'])}
        fill="none"
        strokeWidth="6"
        strokeLinecap="round"
        cx="33"
        cy="33"
        r="30"
      />
    </svg>
  );
};
