import React, { Fragment, FC, useState, Suspense } from 'react';
import { HeaderBalance } from '../HeaderBalance';
import { MediaBreakpoint, useBreakpointInfo } from '../../../hooks/useDeviceInfo';
import { HamburgerLogo, Header } from '../../shared/Header';
import { DepositButton } from '../../shared/DepositButton';
import { Spinner } from '../../shared/Spinner';
import './styles';
import { useDispatch } from 'react-redux';
import { setCashierModal } from '../../../state/cashier/reducers';
import { CashierType } from '../../../state/cashier';

type AuthenticatedHeaderProps = {
  onHamburgerClick: () => void;
  isNavHidden?: boolean;
};

export const AuthenticatedHeader: FC<AuthenticatedHeaderProps> = ({ onHamburgerClick, isNavHidden }) => {
  const isBelowMobileWidth = useBreakpointInfo([MediaBreakpoint.BELOW_MOBILE]);
  const dispatch = useDispatch();
  const [hideBalanceDrawer, setHideBalanceDrawer] = useState(false);
  const [balanceClicked, setBalanceClicked] = useState(true);

  const onHeaderClick = (): void => {
    if (!balanceClicked) {
      setHideBalanceDrawer(true);
      setBalanceClicked(true);
    }
  };

  const onDepositBtnClick = (): void => {
    dispatch(setCashierModal({ isOpen: true, type: CashierType.Deposit }));
  };

  return (
    <div role="presentation" className="authenticated-header-wrapper" onClick={onHeaderClick} onKeyDown={onHeaderClick}>
      <Header
        className="authenticated-header"
        sticky
        left={<HamburgerLogo onHamburgerClick={onHamburgerClick} isNavHidden={isNavHidden} />}
        right={
          <Fragment>
            <Suspense fallback={<Spinner />}>
              <HeaderBalance
                hideBalanceDrawer={hideBalanceDrawer}
                setHideBalanceDrawer={setHideBalanceDrawer}
                setBalanceClicked={setBalanceClicked}
              />
            </Suspense>
            <DepositButton
              size={isBelowMobileWidth ? 'small' : 'large'}
              className="authenticated-header__deposit-btn"
              onClick={onDepositBtnClick}
            />
          </Fragment>
        }
      />
    </div>
  );
};
