import React, { FC } from 'react';

type SearchIconTypeProps = {
  color: string;
};

export const SearchIcon: FC<SearchIconTypeProps> = ({ color }): JSX.Element => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.7321 10.8182H10.9907L10.7279 10.565C11.8541 9.25184 12.436 7.46032 12.1169 5.55624C11.6758 2.94869 9.4984 0.866395 6.87055 0.547485C2.90061 0.0597412 -0.440517 3.39891 0.0475131 7.36652C0.36661 9.99284 2.45012 12.1689 5.05921 12.6098C6.9644 12.9287 8.75697 12.3471 10.0709 11.2216L10.3243 11.4842V12.2252L14.313 16.2116C14.6978 16.5961 15.3266 16.5961 15.7114 16.2116C16.0962 15.827 16.0962 15.1986 15.7114 14.814L11.7321 10.8182ZM6.10096 10.8182C3.76405 10.8182 1.87763 8.93293 1.87763 6.59739C1.87763 4.26184 3.76405 2.37653 6.10096 2.37653C8.43788 2.37653 10.3243 4.26184 10.3243 6.59739C10.3243 8.93293 8.43788 10.8182 6.10096 10.8182Z"
      fill={color}
    />
  </svg>
);
