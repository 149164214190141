import React from 'react';
import { RegistrationStepComponent } from '../../types';
import { Heading } from '../../../shared/Heading';
import { Link } from '../../../shared/Link';
import { APP_URLS } from '../../../../consts';
import { Notification } from '../../../shared/Notification';
import '../PostRegistrationStep.scss';

const defaultErrorMessage = 'For more information, please contact the Customer Support team.';

/**
 * A step component to render registration error message if registration fails.
 */
export const RegistrationErrorStep: RegistrationStepComponent = ({ errors = [], selected, stepConfig, onClick }) => {
  if (!selected) return null;

  return (
    <div id={stepConfig.id} className="registration-error-step">
      <Heading level={4}>{stepConfig.title}</Heading>

      <Notification type="error">
        {errors.length > 0 && errors[0].error}
        {errors.length === 0 && defaultErrorMessage}
      </Notification>
      <div className="registration-error-step__actions">
        <Link buttonStyle={{ variant: 'primary', size: 'medium' }} href={APP_URLS.home} onClick={onClick}>
          Contact Support
        </Link>
      </div>
    </div>
  );
};
