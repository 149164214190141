import { ConsentType } from '../../components/Compliance/CookiesPreferences';

export const cookieSettings = [
  {
    type: ConsentType.STRICTLY_NECESSARY,
    userHasConsented: false,
    alwaysActive: true,
    vendorIds: [-1027182149, -1374328714, -1863652829, 711986351, -83857904],
    vendors: [
      {
        id: -1027182149,
        name: 'betzone.jadexconsulting.com',
        description:
          '<p>tracks confirmation of cookie acceptance<br>\n<br>\nThis tracks whether you have accepted our cookie policy.</p>\n',
        cookies: [
          {
            name: 'cookie-policy-accepted',
            isFirstParty: true,
            isFirstPartyApex: false,
            duration: 31536000000,
          },
        ],
      },
      {
        id: 362805465,
        name: 'www.trafficguard.ai',
        description: '<p>Used to distinguish user sessions</p>\n',
        cookies: [
          {
            name: '_tglksd',
            isFirstParty: false,
            isFirstPartyApex: false,
            domain: 'www.trafficguard.ai',
            duration: 31536000000,
          },
        ],
      },
      {
        id: -1386386262,
        name: 'www.trafficguard.ai',
        description: '<p>Used to distinguish users</p>\n',
        cookies: [
          {
            name: '_tgpc',
            isFirstParty: false,
            isFirstPartyApex: false,
            domain: 'www.trafficguard.ai',
            duration: 31536000000,
          },
        ],
      },
    ],
  },
  {
    type: ConsentType.FUNCTIONAL,
    userHasConsented: false,
    alwaysActive: false,
    vendorIds: [-379507716],
    vendors: [
      {
        id: -379507716,
        name: 'betzone.jadexconsulting.com',
        description:
          '<p>set by iovation<br>\n<br>\nThis is used in fraud and risk protection by helping us to identify your device.</p>\n',
        cookies: [
          {
            name: 'fp_token_*',
            isFirstParty: true,
            isFirstPartyApex: false,
            duration: 31536000000,
          },
        ],
      },
    ],
  },
  {
    type: ConsentType.PERFORMANCE,
    userHasConsented: false,
    alwaysActive: false,
    vendorIds: [-611871657, 1363479298, -183906910, -1077709664, -475047567],
    vendors: [
      {
        id: -611871657,
        name: 'betzone.jadexconsulting.com',
        description:
          '<p>google analytics<br>\n<br>\nThis collects information and reports site usage statistics without personally identifying individual visitors to Google</p>\n',
        cookies: [
          {
            name: '_ga*',
            isFirstParty: false,
            isFirstPartyApex: true,
            duration: 63072000000,
          },
        ],
      },
      {
        id: 1363479298,
        name: 'betzone.jadexconsulting.com',
        description:
          '<p>records landing url/parameters/referer.&nbsp; Used for FB Pixel, google id, MyAffiliate etc.</p>\n<p>This is used to track affiliates, welcome bonuses and campaign performance by checking how you reached our website.</p>\n',
        cookies: [
          {
            name: 'ey_landing-*',
            isFirstParty: true,
            isFirstPartyApex: false,
            duration: 2592000000,
          },
        ],
      },
      {
        id: -183906910,
        name: 'www.nr-data.com',
        description:
          '<p><b>New Relic</b></p>\n<p>used to link error and performance reporting to a single user session</p>\n<p>This allows us to link error and performance reporting to a single user session.</p>\n',
        cookies: [
          {
            name: 'JSESSIONID',
            isFirstParty: false,
            isFirstPartyApex: false,
            domain: 'www.nr-data.com',
            duration: 0,
          },
        ],
      },
      {
        id: -1077709664,
        name: 'betzone.jadexconsulting.com',
        description: '<p>the Fullstory version</p>\n',
        cookies: [
          {
            name: 'fs_cid',
            isFirstParty: true,
            isFirstPartyApex: false,
            duration: 31536000000,
          },
        ],
      },
      {
        id: -475047567,
        name: 'betzone.jadexconsulting.com',
        description: '<p>a unique and anonymous customer id used to distinguish each customer to Fullstory</p>\n',
        cookies: [
          {
            name: 'fs_uid',
            isFirstParty: true,
            isFirstPartyApex: false,
            duration: 31536000000,
          },
        ],
      },
    ],
  },
  {
    type: ConsentType.TARGETING,
    userHasConsented: false,
    alwaysActive: false,
    vendorIds: [914233567, 1488028416],
    vendors: [
      {
        id: 914233567,
        name: 'betzone.jadexconsulting.com',
        description:
          '<p>adsense</p>\n<p>This cookie is used to improve advertising, by sending you google adverts relevant to your interests and helps us improve reporting on our campaign performance.</p>\n',
        cookies: [
          {
            name: '_gcl_au',
            isFirstParty: false,
            isFirstPartyApex: true,
            duration: 5184000000,
          },
        ],
      },
      {
        id: 1488028416,
        name: 'www.doubleclick.net',
        description: '<p>Functional browser testing</p>\n',
        cookies: [
          {
            name: 'test_cookie',
            isFirstParty: false,
            isFirstPartyApex: false,
            domain: 'www.doubleclick.net',
            duration: 900000,
          },
        ],
      },
    ],
  },
];

export const vendors = [
  {
    requestedConsent: [
      {
        type: ConsentType.PERFORMANCE,
        cookies: [
          {
            duration: 63072000000,
            name: '_ga*',
            isFirstParty: false,
            isFirstPartyApex: true,
          },
        ],
      },
    ],
    name: 'betzone.jadexconsulting.com',
    description:
      '<p>google analytics<br>\n<br>\nThis collects information and reports site usage statistics without personally identifying individual visitors to Google</p>\n',
    id: -611871657,
  },
  {
    requestedConsent: [
      {
        type: ConsentType.TARGETING,
        cookies: [
          {
            duration: 5184000000,
            name: '_gcl_au',
            isFirstParty: false,
            isFirstPartyApex: true,
          },
        ],
      },
    ],
    name: 'betzone.jadexconsulting.com',
    description:
      '<p>adsense</p>\n<p>This cookie is used to improve advertising, by sending you google adverts relevant to your interests and helps us improve reporting on our campaign performance.</p>\n',
    id: 914233567,
  },
  {
    requestedConsent: [
      {
        type: ConsentType.PERFORMANCE,
        cookies: [
          {
            duration: 2592000000,
            name: 'ey_landing-*',
            isFirstParty: true,
            isFirstPartyApex: false,
          },
        ],
      },
    ],
    name: 'betzone.jadexconsulting.com',
    description:
      '<p>records landing url/parameters/referer.&nbsp; Used for FB Pixel, google id, MyAffiliate etc.</p>\n<p>This is used to track affiliates, welcome bonuses and campaign performance by checking how you reached our website.</p>\n',
    id: 1363479298,
  },
  {
    requestedConsent: [
      {
        type: ConsentType.FUNCTIONAL,
        cookies: [
          {
            duration: 31536000000,
            name: 'fp_token_*',
            isFirstParty: true,
            isFirstPartyApex: false,
          },
        ],
      },
    ],
    name: 'betzone.jadexconsulting.com',
    description:
      '<p>set by iovation<br>\n<br>\nThis is used in fraud and risk protection by helping us to identify your device.</p>\n',
    id: -379507716,
  },
  {
    requestedConsent: [
      {
        type: ConsentType.PERFORMANCE,
        cookies: [
          {
            duration: 0,
            domain: 'www.nr-data.com',
            name: 'JSESSIONID',
            isFirstParty: false,
            isFirstPartyApex: false,
          },
        ],
      },
    ],
    name: 'www.nr-data.com',
    description:
      '<p><b>New Relic</b></p>\n<p>used to link error and performance reporting to a single user session</p>\n<p>This allows us to link error and performance reporting to a single user session.</p>\n',
    id: -183906910,
  },
  {
    requestedConsent: [
      {
        type: ConsentType.TARGETING,
        cookies: [
          {
            duration: 900000,
            domain: 'www.doubleclick.net',
            name: 'test_cookie',
            isFirstParty: false,
            isFirstPartyApex: false,
          },
        ],
      },
    ],
    name: 'www.doubleclick.net',
    description: '<p>Functional browser testing</p>\n',
    id: 1488028416,
  },
  {
    requestedConsent: [
      {
        type: ConsentType.STRICTLY_NECESSARY,
        cookies: [
          {
            duration: 1800000,
            domain: 'www.trafficguard.ai',
            name: '_tgrsid',
            isFirstParty: false,
            isFirstPartyApex: false,
          },
          {
            duration: 1800000,
            domain: 'www.trafficguard.ai',
            name: '_tgmsclid',
            isFirstParty: false,
            isFirstPartyApex: false,
          },
          {
            duration: 1800000,
            domain: 'www.trafficguard.ai',
            name: '_tgirclid',
            isFirstParty: false,
            isFirstPartyApex: false,
          },
          {
            duration: 1800000,
            domain: 'www.trafficguard.ai',
            name: '_tgfbclid\t',
            isFirstParty: false,
            isFirstPartyApex: false,
          },
          {
            duration: 1800000,
            domain: 'www.trafficguard.ai',
            name: '_tgdcclid',
            isFirstParty: false,
            isFirstPartyApex: false,
          },
          {
            duration: 1800000,
            domain: 'www.trafficguard.ai',
            name: '_tgtgclid',
            isFirstParty: false,
            isFirstPartyApex: false,
          },
        ],
      },
    ],
    name: 'www.trafficguard.ai',
    description: '<p>Used to distinguish user sessions</p>\n',
    id: -491449608,
  },
  {
    requestedConsent: [
      {
        type: ConsentType.STRICTLY_NECESSARY,
        cookies: [
          {
            duration: 60000,
            domain: 'www.trafficguard.ai',
            name: '_tgt_',
            isFirstParty: false,
            isFirstPartyApex: false,
          },
        ],
      },
    ],
    name: 'www.trafficguard.ai',
    description: '<p>Used to test tld</p>\n',
    id: 1927714605,
  },
  {
    requestedConsent: [
      {
        type: ConsentType.PERFORMANCE,
        cookies: [
          {
            duration: 31536000000,
            name: 'fs_cid',
            isFirstParty: true,
            isFirstPartyApex: false,
          },
        ],
      },
    ],
    name: 'betzone.jadexconsulting.com',
    description: '<p>the Fullstory version</p>\n',
    id: -1077709664,
  },
  {
    requestedConsent: [
      {
        type: ConsentType.PERFORMANCE,
        cookies: [
          {
            duration: 31536000000,
            name: 'fs_uid',
            isFirstParty: true,
            isFirstPartyApex: false,
          },
        ],
      },
    ],
    name: 'betzone.jadexconsulting.com',
    description: '<p>a unique and anonymous customer id used to distinguish each customer to Fullstory</p>\n',
    id: -475047567,
  },
];
