import React, { ComponentPropsWithRef, forwardRef, useContext } from 'react';
import { GridColumnAmount } from '.';
import { GridContext } from './Grid';
import { joinStrings } from '../../../utils/string';
import './GridItem.scss';

type GridAreaParams = {
  /**
   * Optional row number.
   */
  row?: number;
  /**
   * Optional start column.  Used to determine col span in combination with `endCol`
   */
  startCol?: GridColumnAmount;
  /**
   * Optional end column.  Used to determine col span in combination with `startCol`
   */
  endCol?: GridColumnAmount;
  /**
   * Defaults to false. Overflow is not hidden by default so that things such as box shadows will display correctly.
   * This prop will override that and hide overflow if needed.
   */
  overflowHidden?: boolean;
};

const getGridSpanClassNames = ({ row, startCol, endCol }: GridAreaParams): (string | 0 | undefined)[] => {
  return [row && `grid-item--row-${row}`, startCol && endCol && `grid-item--column-${startCol}-${endCol}`];
};

export type GridItemProps = GridAreaParams & ComponentPropsWithRef<'div'>;

/**
 * GridItem component.  Note that currently grid area styles will only be applied when all
 * props are provided.
 *
 * Essentially a styled li. Intended to be used exclusively as a child of Grid.
 */
export const GridItem = forwardRef<HTMLDivElement, GridItemProps>(
  ({ children, startCol, endCol, row, overflowHidden, ...props }, ref) => {
    const context = useContext(GridContext);

    const gridSpanClassNames = context.applyGridArea ? getGridSpanClassNames({ row, startCol, endCol }) : [];

    return (
      <div
        data-testid="grid-item"
        {...props}
        ref={ref}
        className={joinStrings([
          'grid-item',
          overflowHidden && 'grid-item--overflow-hidden',
          ...gridSpanClassNames,
          props.className,
        ])}
      >
        <div>{children}</div>
      </div>
    );
  }
);

GridItem.displayName = 'GridItem';
