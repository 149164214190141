import React, { FunctionComponent } from 'react';
import { Icon, IconVariant } from '../../../shared/Icon';
import { useClickableElement } from '../../../../hooks/presentation-logic/useClickableElement';
import { joinStrings } from '../../../../utils/string';
import { Spinner } from '../../../shared/Spinner';
import { Paragraph } from '../../../shared/Paragraph';
import './styles';

export type AccountTileListItemProps = {
  /**
   * Optional spinner before loading a component.
   */
  loading?: boolean;
  /**
   * Optional icon to display.
   */
  icon?: IconVariant;
  /**
   * Optional icon aligning. By default to the left of the text
   */
  iconAlign?: 'left' | 'right';
  /**
   * Optional iconPath to display icon set in AEM.
   */
  iconPath?: string | null;
  /**
   * Highlighted item text
   */
  text: string;
  /**
   * Optional click handler
   */
  onClick?: () => void;
};

/**
 * Component for use where there's a joined account tile list with multiple items
 * An example would be for the Gambling Controls close account menu.
 */
export const AccountTileListItem: FunctionComponent<AccountTileListItemProps> = ({
  loading,
  icon,
  iconAlign = 'left',
  iconPath,
  text,
  onClick,
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const clickableAttributes = onClick ? useClickableElement(onClick) : {};
  const iconLeft = iconAlign === 'left';
  const iconRight = iconAlign === 'right';

  const contentClassName = text.includes('timezone')
    ? 'account-tile-list-item__time-zone-content'
    : 'account-tile-list-item__content';

  const textClassName = text.includes('timezone') ? 'account-tile-list-item__subtext' : 'account-tile-list-item__text';

  return (
    <div
      className={joinStrings([
        !text.includes('GMT') && 'account-tile-list-item',
        onClick && 'account-tile-list-item--clickable',
      ])}
      {...clickableAttributes}
    >
      <div className={contentClassName}>
        {loading ? (
          <Spinner />
        ) : (
          <>
            {icon && iconLeft && <Icon variant={icon} className="account-tile-list-item__icon" />}
            {iconPath && <img src={iconPath} alt="" className="account-tile-list-item__icon" />}
            <Paragraph className={textClassName} size="md" highlight noMargin>
              {text}
            </Paragraph>
            {icon && iconRight && <Icon variant={icon} className="account-tile-list-item__icon" />}
          </>
        )}
      </div>
    </div>
  );
};
