import { formatDate, parseISO, subHours } from 'date-fns';
import React, { FunctionComponent } from 'react';
import { UK__LIMITS_COOLOFF_PERIOD__HOURS } from '../../../../../consts';
import { BudgetLimitsTransactionResponse } from '../../../../../types.__generated__';
import { formatCurrency } from '../../../../../utils/wallets';
import { Paragraph } from '../../../../shared/Paragraph';
import { getLimitNameByPeriod } from '../../../helper';
import './LimitsConfirmationTiles.scss';

type LimitsConfirmationTilesProps = {
  label: string;
  activeAmount: number;
  pendingUserActionItem?: BudgetLimitsTransactionResponse;
};

export const LimitsConfirmationTiles: FunctionComponent<LimitsConfirmationTilesProps> = ({
  label,
  activeAmount,
  pendingUserActionItem,
}) => {
  const { period, amount, pendingChangeExpectedOn } = pendingUserActionItem || {};
  const limitRequestedDate =
    pendingChangeExpectedOn &&
    formatDate(subHours(parseISO(pendingChangeExpectedOn), UK__LIMITS_COOLOFF_PERIOD__HOURS), 'dd.MM.yyyy');

  return (
    <div className="limit-confirmation-tiles">
      <div className="limit-confirmation-tiles__tile">
        <div className="limit-confirmation-tiles__main-content">
          <Paragraph className="limit-confirmation-tiles__content" size="sm" noMargin highlight>
            Requested {getLimitNameByPeriod(period || '', true)} {label} Limit
          </Paragraph>
          <Paragraph className="limit-confirmation-tiles__content" size="md" noMargin highlight>
            {amount ? formatCurrency(amount, 'auto', 'auto') : 'No Limit'}
          </Paragraph>
        </div>
        <div className="limit-confirmation-tiles__auxiliary-content">
          <Paragraph className="limit-confirmation-tiles__tile--item" size="xs" noMargin highlight>
            {limitRequestedDate}
          </Paragraph>
          <Paragraph className="limit-confirmation-tiles__tile--item" size="xs" noMargin highlight>
            Current: {formatCurrency(activeAmount, 'auto', 'auto')}
          </Paragraph>
        </div>
      </div>
    </div>
  );
};
