import React, { FC, useRef, useState } from 'react';
import dompurify from 'dompurify';
import { FooterProps } from './types';
import { FooterLinksList } from './FooterLinksList';
import { MediaBreakpoint, useBreakpointInfo } from '../../hooks/useDeviceInfo';
import { UrlOptions, isHidden } from '../../utils/set-url-storage';
import { joinStrings } from '../../utils/string';
import { Paragraph } from '../shared/Paragraph';
import { Clock } from '../shared/Clock';
import { Heading } from '../shared/Heading';
import { useFooterGetQuery } from '../../libs/graphql/betzoneDirectusAPI/queries/__generated__/footer-get.query.generated';
import { locale } from '../../consts';
import { Spinner } from '../shared/Spinner';
import { Endpoint } from '../../models/api.model';
import { FlattenedFooter, flattenFooterData, initialFooterDataState } from '../../utils/footer';
import './styles';

export const Footer: FC<FooterProps> = ({ isTimeDisplayed = true, isLangSwitcherDisplayed = true }) => {
  const sanitizer = dompurify.sanitize;
  const isBelowDesktopWidth = useBreakpointInfo([MediaBreakpoint.BELOW_DESKTOP]);
  const ref = useRef<HTMLDivElement>(null);
  const [{ translations, collections }, setFooterData] = useState<FlattenedFooter>(initialFooterDataState);

  const { loading } = useFooterGetQuery({
    variables: {
      language: locale,
    },
    onCompleted: (data) => {
      const transformedData = flattenFooterData(data);

      setFooterData(transformedData);
    },
    onError: (error) => {
      console.error(error);
    },
    context: { endpoint: Endpoint.betzoneDirectusAPI },
  });
  // const footerIsVisible = useElementOnScreen(ref); Left for future optimization

  return !isHidden(UrlOptions.hideFooter) ? (
    <footer className="footer" ref={ref}>
      <div className={joinStrings(['footer__general-bar', isBelowDesktopWidth && 'footer__general-bar--mobile'])}>
        {/* TODO: this block will be implemented as a separate ticket */}
        {isLangSwitcherDisplayed && <div className="footer__language">Language</div>}

        {isTimeDisplayed && (
          <div className="footer__time">
            <Clock format="HH:mm" />
            <span>zone</span>
          </div>
        )}
      </div>

      {loading ? (
        <div className="spinner-wrapper">
          <Spinner />
        </div>
      ) : (
        <div className="footer__links-container">
          {collections.length
            ? collections.map((collectionItem) => (
                <div className="footer__links-container-item" key={collectionItem.sectionTitle}>
                  {collectionItem?.sectionTitle && (
                    <Heading level={5} noMargin>
                      {collectionItem?.sectionTitle}
                    </Heading>
                  )}
                  <FooterLinksList linksList={collectionItem?.items} />
                </div>
              ))
            : null}
        </div>
      )}

      <div className="footer__regulatory-copy">
        <Paragraph
          size="sm"
          noMargin
          className="footer__regulatory-text"
          dangerouslySetInnerHTML={{
            __html: sanitizer(translations?.footer_text, {
              ALLOWED_ATTR: ['href', 'title', 'target'],
            }),
          }}
        />
      </div>
      <div className="footer__copyright">
        <Paragraph
          size="sm"
          noMargin
          className="footer__copyright-text"
          dangerouslySetInnerHTML={{ __html: sanitizer(translations?.copyright_text) }}
        />
      </div>
    </footer>
  ) : null;
};
