import React, { FunctionComponent } from 'react';
import { joinStrings } from '../../../../utils/string';
import './Switch.scss';

export type SwitchProps = {
  /**
   * Optional. Defaults to false
   * Determines which visual state to display
   */
  checked?: boolean;
};

/**
 * Simple Switch component, which animates on state change.
 * To be used as a variant of the Checkbox component
 *
 * ### Example of Checkbox component using Switch variant
 *
 * ```tsx
 * const StatefulControllerComponent = () => {
 *   const [checked, setChecked] = useState(false);
 *   return (
 *     <Fragment>
 *       <div>...Other things...</div>
 *       <Checkbox
 *         name="switch"
 *         id="some-switch"
 *         label="Toggle me"
 *         checked={checked}
 *         onChange={(e)=> setChecked(e.target.checked)}
 *         variant='switch'
 *       />
 *     </Fragment>
 *   );
 * };
 * ```
 */
export const Switch: FunctionComponent<SwitchProps> = ({ checked = false }) => {
  return (
    <div className={joinStrings(['switch', checked && 'switch--checked'])} data-testid="switch-container">
      <span className="switch__background" />
      <span className="switch__handle" />
    </div>
  );
};
