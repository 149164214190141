import React from 'react';
import { Game } from '../games.model';
import { GameCardContainerType } from '../GameCard/GameCard.types';
import { SimpleGameCard } from '../GameCard/SimpleGameCard/SimpleGameCard';

export const mapGamesToSimpleSlides = (
  games: Game[],
  onClose: () => void,
  containerType = GameCardContainerType.Wide,
  withLabel = false
): JSX.Element[] => {
  return games.map((game: Game) => (
    <SimpleGameCard
      key={game.gameId}
      game={game}
      containerType={containerType}
      onClose={onClose}
      withLabel={withLabel}
    />
  ));
};
