import React, { forwardRef } from 'react';
import ReactInputMask, { Props } from 'react-input-mask';
import { Input, InputProps } from '../Input';

/**
 * Notes on the 3rd party lib react-input-mask
 * ---------------------------------------------
 * If your input has an initial value,
 * it should be supplied in a format you would expect after the mask has been applied.
 * Known autofill issue https://github.com/sanniassin/react-input-mask#known-issues
 *
 * Some potential alternatives
 * ---------------------------------------------
 * RIFM (https://realadvisor.github.io/rifm/) looks like another potential option
 * but you have to write your own formatters
 * React Number Format (https://github.com/s-yadav/react-number-format) is another option
 * but it comes with excess functionality we may not need
 */
export type MaskedInputProps = Props & InputProps;

/**
 *
 * A controlled Masked Input component, which wraps the Input component and adds masking functionality.
 *
 * ### Example - As Controlled Masked Input
 *
 * ```tsx
 * const UseStateExample = () => {
 *   const [value, setValue] = useState('');
 *   return (
 *     <Fragment>
 *       <MaskedInput
 *         name="telephone"
 *         value={value}
 *         label="Telephone"
 *         mask='+7 (999) 999-99-99'
 *         onChange={(e) => setValue(e.target.value)}
 *       />
 *     </Fragment>
 *   );
 * };
 * ```
 *
 * ### Example - React Hook Form
 *
 * An example which uses `react-hook-form` as the controller of state.
 *
 * ```tsx
 * import { useForm, Controller } from 'react-hook-form';
 *
 * const HookFormExample = ({
 *   onSubmit
 * }: {
 *   onSubmit: (formValues) => void;
 * }) => {
 *   const { control, handleSubmit } = useForm({
 *     defaultValues: { telephone: '' }
 *   });
 *   return (
 *     <form onSubmit={handleSubmit(onSubmit)}>
 *       <Controller
 *         name='telephone'
 *         control={control}
 *         as={<MaskedInput label='Telephone' mask='+7 (999) 999-99-99'/>}
 *       />
 *       <Button type='submit'>Submit</Button>
 *    </form>
 *   );
 * };
 * ```
 * Masked Inputs will stretch to fill the width of their containers.
 *
 * TODO: Prop table here is incorrect, looks like there's an issue showing the props since it's an intersection type
 *
 */
export const MaskedInput = forwardRef<HTMLInputElement, MaskedInputProps>(
  (
    {
      mask,
      maskPlaceholder,
      beforeMaskedStateChange,
      alwaysShowMask,
      onChange,
      onMouseDown,
      onFocus,
      onBlur,
      value,
      disabled,
      readOnly,
      ...inputProps
    },
    ref
  ) => {
    const maskProps = {
      mask,
      maskPlaceholder,
      beforeMaskedStateChange,
      alwaysShowMask,
      onChange,
      onMouseDown,
      onFocus,
      onBlur,
      value,
      disabled,
      readOnly,
    };

    return (
      <ReactInputMask {...maskProps}>
        <Input ref={ref} {...inputProps} />
      </ReactInputMask>
    );
  }
);

MaskedInput.displayName = 'MaskedInput';
