import React, { FunctionComponent, useState } from 'react';
import { AddressSearch } from './AddressSearch';
import { AddressData, RegistrationStepComponentProps } from '../types';
import { joinStrings } from '../../../utils/string';
import { DefaultFormStep } from './DefaultFormStep';
import { Heading } from '../../shared/Heading';
import { Button } from '../../shared/Button';
import { Icon } from '../../shared/Icon';

export const RegistrationAddressFormStep: FunctionComponent<RegistrationStepComponentProps> = (formStepProps) => {
  const [manualAddressEdit, setManualAddressEdit] = useState(false);
  const [addressMenuOpen, setAddressMenuOpen] = useState(false);
  const [addressState, setAddressState] = useState<Partial<AddressData>>({});

  return (
    <div
      className={joinStrings([
        'address-form-step',
        !manualAddressEdit && 'address-form-step--address-fields-hidden',
        addressMenuOpen && 'address-form-step--address-menu-open',
      ])}
    >
      <DefaultFormStep
        {...formStepProps}
        validationMode="onChange"
        headingLevel={5}
        formState={{ ...formStepProps.formState, ...addressState }}
        onSelectPreviousStep={(): void => {
          formStepProps.onSelectPreviousStep && formStepProps.onSelectPreviousStep();
        }}
        onCompleteStep={(stepIndex, d): void => {
          formStepProps.onCompleteStep && formStepProps.onCompleteStep(stepIndex, d);
        }}
        formChildren={
          !manualAddressEdit && (
            <AddressSearch
              initialAddressData={formStepProps.formState}
              onMenuOpenChange={setAddressMenuOpen}
              addressConfig={formStepProps.stepConfig}
              onEnterManual={(selected: boolean): void => {
                setManualAddressEdit(true);

                if (selected) {
                  // Blur forces form validation so that feedback icons will show.
                  const event = new Event('blur', {
                    bubbles: true,
                  });

                  document
                    .querySelectorAll<HTMLDivElement>('#registration-address-details-fieldset .input__box')
                    .forEach((e) => {
                      e.dispatchEvent(event);
                    });
                }
              }}
              onSelectAddress={setAddressState}
            />
          )
        }
      >
        {manualAddressEdit && (
          <div className="registration-form-step__manual-edit-address">
            <Heading noMargin level={6}>
              Edit Address
            </Heading>
            <Button
              type="button"
              variant="text"
              size="small"
              icon={<Icon variant="SearchLocation" />}
              iconPosition="left"
              onClick={(): void => {
                setManualAddressEdit(false);
              }}
            >
              Look Up Address
            </Button>
          </div>
        )}
      </DefaultFormStep>
    </div>
  );
};
