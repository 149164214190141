export const STORAGE_PREFIX = 'Betzone.';

export type GameInfoShort = {
  displayName: string;
  realLaunchUrl: string;
};

export type StoragePersistanceService = {
  get: (playerId?: string) => string | unknown;
  set: (props: StoragePersistanceServiceEntry) => void;
  has: (playerId?: string) => boolean;
  remove: () => void;
};

export type StoragePersistanceServiceEntry = {
  /**
   * value that will be set to the key
   */
  value: unknown;
  /**
   * value that indicates specific props for authenticated users
   */
  playerId?: string;
  /**
   * time to live (ttl) in milliseconds.
   * If ttl is a positive number, then set the expiration value to now + ttl defined
   * If ttl is expired, then the storage value will be removed on the first load
   */
  ttl?: number;
};

export type StoragePersistanceServiceProps = {
  value: unknown;
  /**
   * value that indicates specific props for authenticated users
   */
  playerId?: string;
  /**
   * value in milliseconds defined time for the expiration
   */
  expiration?: number;
};
