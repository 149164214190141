import React, { FC, useCallback, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import './scss/index.scss';
import { APP_URLS } from './consts';
import { LayoutInit } from './components/LayoutInit';
import { Registration } from './components/Registration';
import { Home } from './layouts/pages/Home';
import { useAuthController } from './hooks/auth/useAuthController';
import { MyAccount } from './components/Account/MyAccount/MyAccount';
import { sessionPreviousLocation } from './utils/storage';
import { useAuth } from 'react-oidc-context';
import { Snackbar } from './components/shared/Snackbar';
import { GameDetailsPage } from './layouts/pages/GameDetailsPage';
import PromotionsList from './components/Layout/Promotions/PromotionsList/PromotionsList';
import { ScrollToTop } from './utils/scroll-to-top';
import PromotionMainBlock from './components/Layout/Promotions/PromotionMainBlock/PromotionMainBlock';
import ErrorPage from './components/Navigation/ErrorPage/ErrorPage';
import { Cashier } from './components/Account/MyAccount/Cashier';
import { ProvidersCombiner } from './providers';
import { ContentPage } from './layouts/pages/ContentPage';
import { IframePage } from './layouts/pages/IframePage/IframePage';
import { CasinoContent } from './components/CasinoContent';
import { APP_VERSION } from './version';
import { LoggedInMessages } from './components/LoggedInMessages';
import { QuestionnairesList } from './components/Account/MyAccount/Questionnaires/QuestionnaireList';

export const App: FC = () => {
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const isUserAuthenticated = auth?.isAuthenticated;
  const storedPreviousLocation = sessionPreviousLocation.get() as { previousLocation: string };
  const previousLocation = location.state?.previousLocation || storedPreviousLocation?.previousLocation;

  useAuthController();
  ScrollToTop();

  const [accountOpen, setAccountOpen] = useState<boolean>(false);

  const handleAccountClose = useCallback(
    (isNestedRoute?: boolean): void => {
      setAccountOpen(false);
      navigate(isNestedRoute ? previousLocation || APP_URLS.home : -1);
      sessionPreviousLocation.remove();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate]
  );

  const handleAccountOpen = (): void => {
    // TODO: Refresh wallet totals
    setAccountOpen(true);
  };

  useEffect(() => {
    (window as unknown as Record<string, string>).REACT_APP_VERSION = APP_VERSION || 'dev1';
  }, []);

  useEffect(() => {
    const hasAccountQuery = Boolean(location.pathname.includes(APP_URLS.myAccount.home));

    const accOpen = isUserAuthenticated && !accountOpen && hasAccountQuery;

    accOpen && handleAccountOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleAccountClose, isUserAuthenticated]);

  return (
    <ProvidersCombiner>
      <div id="gameCardZoomPortal" />
      <LayoutInit />
      <Snackbar />
      <QuestionnairesList />
      <Routes location={previousLocation || location}>
        <Route path={APP_URLS.home} element={<Home />} />
        <Route path={APP_URLS.gameDetails} element={<GameDetailsPage />} />
        <Route path={APP_URLS.promotions} element={<PromotionsList />} />
        <Route path={APP_URLS.promotion} element={<PromotionMainBlock />} />
        <Route path={APP_URLS.contentPage} element={<ContentPage />} />
        {isUserAuthenticated && <Route path={APP_URLS.f2p} element={<IframePage />} />}
        <Route path={APP_URLS.gameLobby.dynamicPage} element={<CasinoContent />} />
        {!isUserAuthenticated && <Route path={APP_URLS.registration} element={<Registration />} />}
        {previousLocation ? null : <Route path="*" element={<ErrorPage />} />}
      </Routes>
      {isUserAuthenticated && (
        <>
          <Cashier />
          <LoggedInMessages />
          <MyAccount isOpen={accountOpen} onClose={handleAccountClose} />
        </>
      )}
    </ProvidersCombiner>
  );
};
