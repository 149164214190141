import { ApolloError } from '@apollo/client';
import { Endpoint } from '../../models/api.model';
import { UseFetchBonusDetailsProps, UseFetchBonusDetailsReturnType } from './types';
import { locale } from '../../consts';
import { useBonusGetBySlugLazyQuery } from '../../libs/graphql/betzoneDirectusAPI/queries/__generated__/bonus-get-by-slug.query.generated';

export const useFetchPromoDetails = ({
  slug,
  language = locale,
  onFetchBonusBySlugComplete,
}: UseFetchBonusDetailsProps): UseFetchBonusDetailsReturnType => {
  const [
    fetchBonusDetailsBySlug,
    {
      data: fetchedBonusDetailsBySlugData,
      loading: fetchedBonusDetailsBySlugLoading,
      error: fetchedBonusDetailsBySlugError,
    },
  ] = useBonusGetBySlugLazyQuery({
    variables: {
      language,
      slug,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: onFetchBonusBySlugComplete,
    onError: (e: ApolloError) => {
      console.error(e.message);
    },
    context: { endpoint: Endpoint.betzoneDirectusAPI },
  });

  return {
    fetchBonusDetailsBySlug,
    fetchedBonusDetailsBySlugData,
    fetchedBonusDetailsBySlugLoading,
    fetchedBonusDetailsBySlugError,
  };
};
