import noop from 'lodash/noop';
import React, { FC, Suspense, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { errorsToErrorSummary } from '../../../../utils/form';
import { Button } from '../../../shared/Button';
import { Paragraph } from '../../../shared/Paragraph';
import { PopUpHandler } from '../../../shared/PopupHandler';
import { Select } from '../../../shared/Select';
import { Spinner } from '../../../shared/Spinner';
import './RealityChecksEditModal.scss';
import { getRealityCheckOptions } from '../mocks';
import { useTranslations } from '../../../../hooks/useTranslationsHelper';

interface RealityChecksEditModalProps {
  loading: boolean;
  isOpen: boolean;
  /**
   * Value of the Select element in the format 'time text' (ex.: 15 minutes, 1 hour, Every 2 hours 10 minutes)
   */
  intervalWithLabel: string;
  /**
   * Callback that's executed when the user closes the modal
   */
  onClose: () => void;
  /**
   * Callback that's executed when the user submits the form
   */
  onFormSubmit: (data: RealityChecksFormData) => void;
  /**
   * Callback that's executed on show
   */
  onShow?: () => void;
  /**
   * Callback that's executed on cancel
   */
  onCancel?: () => void;
}

export interface RealityChecksFormData {
  interval: string;
}

export const RealityChecksEditModal: FC<RealityChecksEditModalProps> = ({
  isOpen,
  loading,
  intervalWithLabel,
  onClose,
  onFormSubmit,
  onShow = noop,
  onCancel = noop,
}) => {
  const { t } = useTranslations();
  const { control, formState, reset, handleSubmit } = useForm<RealityChecksFormData>({
    defaultValues: {
      interval: intervalWithLabel,
    },
  });

  const errorSummary = errorsToErrorSummary(formState.errors, {}, 'reality-checks-form');

  async function onSubmit(event: React.FormEvent<HTMLFormElement>): Promise<void> {
    event.preventDefault();

    if (!formState.isSubmitting) {
      return handleSubmit(onFormSubmit)(event);
    }
  }

  useEffect(() => {
    if (isOpen) {
      onShow();
    }
  }, [isOpen]);

  useEffect(() => {
    reset({ interval: intervalWithLabel });
  }, [intervalWithLabel]);

  return (
    <PopUpHandler
      isOpen={isOpen}
      onClose={onClose}
      header={t('reality-check.play-reminders')}
      drawerProps={{ backdropCanClose: true }}
      headingProps={{ headingLevel: 5 }}
      modalProps={{
        className: 'reality-checks-edit-modal',
        'aria-label': 'Play Reminders',
      }}
      footer={
        <div className="reality-checks-edit-modal__action-footer">
          <Button
            className="reality-checks-edit-modal__cancel-btn"
            variant="secondary-alt"
            type="button"
            onClick={onCancel}
            disabled={loading}
          >
            {t('buttons.cancel.uppercase')}
          </Button>
          <Button variant="primary" type="submit" form="reality-checks-form" disabled={loading}>
            {t('buttons.confirm.uppercase')}
          </Button>
        </div>
      }
    >
      <form onSubmit={onSubmit} className="reality-checks-edit-modal__select-form" id="reality-checks-form">
        <Paragraph size="sm" noMargin className="reality-checks-edit-modal__select-text">
          {t('reality-check.main-text')}
        </Paragraph>
        {loading ? (
          <Spinner />
        ) : (
          <Suspense>
            <Controller
              control={control}
              name="interval"
              defaultValue={intervalWithLabel}
              rules={{
                required: {
                  value: true,
                  message: t('validation.required'),
                },
                validate: (value: string): string | undefined => {
                  const isValid = value !== intervalWithLabel;

                  return isValid ? undefined : t('reality-check.existed-interval-message');
                },
              }}
              render={({ field, ...renderProps }): JSX.Element => (
                <Select
                  {...renderProps}
                  {...field}
                  className="reality-checks-edit-modal__select"
                  label={t('reality-check.time-limit')}
                  placement="bottom"
                  theme="dark"
                  options={getRealityCheckOptions(t)}
                  validationMessages={errorSummary.interval?.messages}
                  validationState={errorSummary.interval?.messages?.length ? 'error' : undefined}
                />
              )}
            />
          </Suspense>
        )}
      </form>
    </PopUpHandler>
  );
};
