import debounce from 'lodash/debounce';
import React, { useEffect, useState } from 'react';
import { passiveOption } from '../../consts';

/**
 * Check the boundaries a DOMRect vs the current view port to see
 * if the rect is contained within.
 * @param rect
 */
export const isRectInViewPort = (rect: DOMRect, offsetTop = 0): boolean => {
  return (
    rect.top - offsetTop >= 0 && rect.left >= 0 && rect.bottom <= window.innerHeight && rect.right <= window.innerWidth
  );
};

/**
 *
 * @param refs A list of React.RefObjects used to determine what is visible in window
 * @param items A list of data whose indexes must match the refs which they correspond to.
 */
export function filterVisibleItems<T, E extends HTMLElement>(refs: React.RefObject<E>[], items: T[]): T[] {
  return refs
    .map((el, index) => {
      const rect = el.current?.getBoundingClientRect();

      return {
        rect,
        index,
      };
    })
    .filter(({ rect }) => rect && isRectInViewPort(rect))
    .map(({ index }) => items[index]);
}

export type UseOnScreenVisibilityOptions<T> = {
  onVisibilityUpdate?: (visibleItems: T[]) => void;
  /**
   * Initial items to create references for.
   */
  initialItems?: T[];
  /**
   * Number of milliseconds to debounce browser viewpoint events (scroll & resize) which are used
   * to trigger a check for visible elements. Defaults to 200
   */
  debounceMs?: number;
};

export type UseOnScreenVisibilityReturnValue<T, E extends Element> = {
  /**
   * To be called when the list of items to be rendered is updated
   */
  setVisibilityItems: (items: T[]) => void;
  /**
   * An array of references to be set on containers representing items.
   */
  visibilityItemContainerRefs: React.RefObject<E>[];
  /**
   * Callback which will be invoked when list is updated, or there is a window scroll/resize event.
   */
  checkVisibility: () => void;
};

const windowEvents = ['scroll', 'resize'];

/**
 * A hook to encapsulate logic for checking to see if elements are there associated items
 * are currently in the on screen viewport.
 *
 * Provided visibilityItemContainerRefs will be created one per item.  Note that this will match the item order.
 * The provided ref will then need to be attached to a container element which represents each item.
 */
export function useOnScreenVisibility<T, E extends HTMLElement>({
  onVisibilityUpdate,
  initialItems = [],
  debounceMs = 200,
}: UseOnScreenVisibilityOptions<T>): UseOnScreenVisibilityReturnValue<T, E> {
  const [items, setItems] = useState<T[]>(initialItems);
  const [itemContainerRefs, setItemContainerRefs] = useState<React.RefObject<E>[]>(
    initialItems.map(() => React.createRef<E>())
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const debouncedOnVisibilityUpdate: any = debounce(
    () =>
      itemContainerRefs.length &&
      onVisibilityUpdate &&
      onVisibilityUpdate(filterVisibleItems(itemContainerRefs, items)),
    debounceMs
  );

  // Report on screen visibility. Reapply when list of items update.
  useEffect(() => {
    // Trigger callback once when items are updated
    debouncedOnVisibilityUpdate();

    windowEvents.forEach((event) => window.addEventListener(event, debouncedOnVisibilityUpdate, passiveOption));

    return () =>
      windowEvents.forEach((event) =>
        window.removeEventListener(event, debouncedOnVisibilityUpdate, passiveOption as EventListenerOptions)
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemContainerRefs]);

  return {
    /**
     * To be called when the list of items to be rendered is updated
     */
    setVisibilityItems: (items: T[]): void => {
      setItemContainerRefs(items.map(() => React.createRef<E>()));
      setItems(items);
    },
    visibilityItemContainerRefs: itemContainerRefs,
    checkVisibility: debouncedOnVisibilityUpdate,
  };
}
