import React, { FunctionComponent } from 'react';
import { joinStrings } from '../../../utils/string';
import { Button, ButtonProps } from '../Button';
import './ToggleButton.scss';

export type ToggleButtonProps = {
  /**
   * Decide whether to display selected or unselected state
   */
  selected: boolean;
} & Omit<ButtonProps, 'type'>;

/**
 *
 * ### Example as used alone as a controlled component
 *
 * ```tsx
 *import React, { useState } from 'react';
 *import { ToggleButton } from 'bede-starterkit';
 *
 *export const StatefulControllerComponent = () => {
 * const [isSelected, setIsSelected] = useState(false);
 * return (
 *   <>
 *     <ToggleButton
 *       selected={isSelected}
 *       onClick={() => setIsSelected(!isSelected)}
 *     >
 *       Toggle
 *     </ToggleButton>
 *   </>
 *  );
 *};
 * ```
 *
 * ### Example as used within react-hook-form
 *
 * ```tsx
 *import React, { useEffect } from 'react';
 *import { HookFormToggleButton } from 'bede-starterkit';
 *import { useForm } from 'react-hook-form';
 *
 *export const ExmapleToogleHookForm = () => {
 * let btnConfig = {
 *   children: 'Email',
 *   selected: true,
 *   disabled: false,
 *   variant: 'secondary',
 *   type: 'button',
 *   size: 'auto',
 *   name: 'email'
 * };
 *
 * const { handleSubmit, control, setValue } = useForm({
 *    defaultValues: {[btnConfig.name]: btnConfig.selected}
 * });
 * const onSubmit = (data: object) => console.log(data);
 *
 * return (
 *   <form onSubmit={handleSubmit(onSubmit)}>
 *     <HookFormToggleButton
 *       {...btnConfig}
 *       control={control}
 *       variant={'primary'}
 *     />
 *     <button type='submit'>Submit</button>
 *   </form>
 *  );
 *};
 *
 * ```
 */

export const ToggleButton: FunctionComponent<ToggleButtonProps> = ({
  onClick,
  selected,
  children,
  disabled = false,
  variant,
  className,
  size,
  ...buttonProps
}) => {
  return (
    <Button
      {...buttonProps}
      onClick={onClick}
      disabled={disabled}
      size={size}
      aria-pressed={selected}
      className={joinStrings(['toggle-button', selected && 'toggle-button--selected', className])}
      variant={variant}
      type="button"
    >
      {children}
    </Button>
  );
};
