import React, { FC } from 'react';
import { Icon } from '../Icon';
import './LoadingIndicator.scss';

/**
 * Loading indicator component, indicates when something is happening
 * in the background. Currently only shows a circular spinner
 * (using whatever icon is defined as `Spinner`) but could
 * easily be extended to have different variations of loading indicators.
 */
export const LoadingIndicator: FC = () => {
  return (
    <div className="loading-indicator" data-testid="loading-indicator">
      <Icon className="loading-indicator__icon" variant="Spinner" />
    </div>
  );
};
