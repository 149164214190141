import React, { Fragment, FunctionComponent, ReactNode } from 'react';
import { FaTrophy } from '@react-icons/all-files/fa/FaTrophy';
import { parseISO, formatDate } from 'date-fns';

import { BonusStatusType } from '../../../../models/bonuses.model';
import { BonusIconStateType, getBonusIconState } from '../types';
import { ParsedPromo } from '../../../Layout/Promotions/types';
import { formatCurrency } from '../../../../utils/wallets';

export const MyBonusHeading: FunctionComponent<ParsedPromo> = (bonus) => {
  const { title, createdOn } = bonus;

  const renderBonusAmount = (bonus: ParsedPromo): string | undefined =>
    formatCurrency(Math.abs(Number(bonus.convertedAmount || bonus.balanceAmount)), 'auto', 'auto');

  const renderBonusStatus = (bonus: ParsedPromo, showIcon?: boolean): ReactNode => {
    const [bonusIconState] = getBonusIconState([bonus.status as BonusStatusType]);

    return (
      <Fragment>
        {String(bonus.status)}
        {showIcon && (
          <FaTrophy
            className="my-bonus-history__summary--subheading-status-icon"
            color={`rgba(255, 209, 48, ${bonusIconState === BonusIconStateType.Positive ? 1 : 0.2})`}
          />
        )}
      </Fragment>
    );
  };

  return (
    <>
      <div className="my-bonus-history__summary--heading">
        {title}
        <span className="my-bonus-history__summary--heading-amount">{renderBonusAmount(bonus)}</span>
      </div>
      <div className="my-bonus-history__summary--subheading">
        {formatDate(parseISO(String(createdOn)), 'dd/MM/yyyy | HH:mm')}

        <span className="my-bonus-history__summary--subheading-status">{renderBonusStatus(bonus)}</span>
      </div>
    </>
  );
};
