import { TransactionType } from '../../../models/transactions.model';
// import { Icon, TransactionType } from 'bede-starterkit';
import { KambiTransactionType } from '../../Kambi/types';
import React, { FC } from 'react';
import { Icon } from '../../shared/Icon';

export const IncomingIcon: FC = () => (
  <Icon className="my-account-activity-icon my-account-activity-icon--incoming" variant="Share" />
);
export const OutgoingIcon: FC = () => (
  <Icon className="my-account-activity-icon my-account-activity-icon--outgoing" variant="Reply" />
);

const incomingTransactionTypes: TransactionType[] = [
  'Deposit',
  'Win',
  'BonusActivated',
  'BonusFulfilled',
  'BonusCompleted',
];

/**
 * Render icon representing an activity of a certain type
 */
export const MyAccountActivityIcon: FC<{
  type: TransactionType;
  transactionType?: TransactionType;
  amount: number;
}> = ({ type, transactionType }) => {
  if (type === KambiTransactionType.ADJUSTMENT) {
    // Settle Kambi transaction type is applied for incoming money status despite of outgoing
    return transactionType === KambiTransactionType.SETTLE ? <IncomingIcon /> : <OutgoingIcon />;
  }

  return incomingTransactionTypes.includes(type) ? <IncomingIcon /> : <OutgoingIcon />;
};
