import { useEffect, useMemo, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { ApolloError } from '@apollo/client';
import { PromotionTabsType } from '../../components/Layout/Promotions/PromotionsList/types';
import { GigPromo, ParsedPromo } from '../../components/Layout/Promotions/types';
import { useUserContext } from '../../providers/UserProvider';
import { UserProfileBonusStatus } from '../../types.__generated__';
import {
  BonusesGetAllQuery,
  useBonusesGetAllLazyQuery,
} from '../../libs/graphql/betzoneDirectusAPI/queries/__generated__/bonuses.query.generated';
import { APP_URLS, locale, xTenantId } from '../../consts';
import {
  UserBonusesQuery,
  useUserBonusesLazyQuery,
} from '../../libs/graphql/baseAppAPI/queries/__generated__/user-promo-collection.generated';
import { useBonusesGetPublicLazyQuery } from '../../libs/graphql/betzoneDirectusAPI/queries/__generated__/bonuses-public.query.generated';
import { Endpoint } from '../../models/api.model';
import { getImageUrl } from '../../utils/navigation';

const today = new Date().toISOString();

export const useFetchMergedPromotions = (
  promoType: PromotionTabsType
): { mergedFilteredPromotions: ParsedPromo[]; mergedHistoryPromotions: ParsedPromo[]; isPromosLoading: boolean } => {
  const isActivePromotions = promoType === PromotionTabsType.ACTIVE || promoType === PromotionTabsType.BONUS_DETAILS;

  const isUserAuthenticated = useAuth()?.isAuthenticated;

  const { userProfileId } = useUserContext();

  const [directusPromotions, setDirectusPromotions] = useState<ParsedPromo[] | null>(null);
  const [resultPromotions, setResultPromotions] = useState<ParsedPromo[] | null>(null);
  const [promotionsLoading, setPromotionsLoading] = useState<boolean>(true);

  const onQueryError = (e: ApolloError): void => {
    setPromotionsLoading(false);
    console.error(e.message);
  };

  const handleDirectusPromos = (data: BonusesGetAllQuery): void =>
    setDirectusPromotions(
      data.bonus.map((promo) => ({
        promoCode: 'test-code',
        promoButtonText: 'CLAIM',
        promotionCTAType: 'DepositJourney',
        bonusId: String(promo.template_id),
        startDate: promo.start_date,
        endDate: promo.end_date,
        bonusType: String(promo.__typename),
        products:
          promo.collection?.translations?.map((translation) => translation?.display_name?.toLocaleLowerCase()) || [],
        title: String(promo.translations?.[0]?.title),
        description: String(promo.translations?.[0]?.subtitle),
        terms: String(promo.translations?.[0]?.description),
        imgPath: getImageUrl(promo.translations?.[0]?.thumbnail?.filename_disk),
        imgAlt: String(promo.translations?.[0]?.thumbnail?.title),
        pageUrl: `${APP_URLS.promotions}/${promo.translations?.[0]?.slug}`,
        successCTAGameInformation: {
          gameId: '',
        },
      }))
    );

  const handleGigPromos = (data: UserBonusesQuery): void => {
    const gigBonuses = data.userBonuses.data || [];

    const mergedPromotions: (ParsedPromo | undefined)[] =
      (gigBonuses as GigPromo[])
        .map((gigPromo: GigPromo) => {
          const directusPromo = directusPromotions?.find(
            (dirPromo: ParsedPromo) => dirPromo.bonusId === gigPromo?.bonusTemplateId
          );

          return directusPromo
            ? {
                ...directusPromo,
                id: gigPromo?.id,
                status: gigPromo?.status,
                expiresOn: gigPromo?.expiresOn,
                createdOn: gigPromo?.createdOn,
                wageringRequirementAmount: gigPromo?.wageringRequirementAmount,
                wageredAmount: gigPromo?.wageredAmount || '',
                balanceAmount: gigPromo?.balanceAmount,
                convertedAmount: gigPromo?.convertedAmount,
              }
            : undefined;
        })
        .filter((dirPromo: ParsedPromo | undefined) => dirPromo) || [];

    setResultPromotions(mergedPromotions as ParsedPromo[]);
  };

  const [fetchBonusesPublic] = useBonusesGetPublicLazyQuery({
    variables: {
      language: locale,
      date: today,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      handleDirectusPromos(data);
      setPromotionsLoading(false);
    },
    onError: onQueryError,
    context: { endpoint: Endpoint.betzoneDirectusAPI },
  });

  const [fetchBonusesPrivate] = useBonusesGetAllLazyQuery({
    variables: {
      language: locale,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: handleDirectusPromos,
    onError: onQueryError,
    context: { endpoint: Endpoint.betzoneDirectusAPI },
  });

  const [fetchBonusesGig] = useUserBonusesLazyQuery({
    variables: {
      xTenantId,
      userProfileId,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      handleGigPromos(data);
      setPromotionsLoading(false);
    },
    notifyOnNetworkStatusChange: true,
    onError: onQueryError,
    context: { endpoint: Endpoint.appAPI },
  });

  const getMergedFilteredPromotions = (isHistory?: boolean): ParsedPromo[] | null | undefined => {
    const resultStatus: string[] = isActivePromotions
      ? [UserProfileBonusStatus.InProgress, UserProfileBonusStatus.Claimed]
      : [UserProfileBonusStatus.Available];

    const nonHistoryStatuses: string[] = [
      UserProfileBonusStatus.InProgress,
      UserProfileBonusStatus.Claimed,
      UserProfileBonusStatus.Available,
    ];

    return isUserAuthenticated
      ? resultPromotions?.filter((promo) => {
          const promoStatus = String(promo.status);

          return isHistory ? !nonHistoryStatuses.includes(promoStatus) : resultStatus.includes(promoStatus);
        })
      : directusPromotions;
  };

  // It's a bit tricky for memoization as it doesn't work in a few random cases
  const mergedFilteredPromotions = getMergedFilteredPromotions();

  const mergedHistoryPromotions = useMemo(
    () => getMergedFilteredPromotions(true),
    [resultPromotions, directusPromotions, isUserAuthenticated, isActivePromotions]
  );

  useEffect(() => {
    (async (): Promise<void> => {
      isUserAuthenticated ? await fetchBonusesPrivate() : await fetchBonusesPublic();
    })();
  }, []);

  useEffect(() => {
    directusPromotions?.length &&
      (async (): Promise<void> => {
        isUserAuthenticated && (await fetchBonusesGig());
      })();
  }, [directusPromotions]);

  return {
    mergedFilteredPromotions: mergedFilteredPromotions || [],
    mergedHistoryPromotions: mergedHistoryPromotions || [],
    isPromosLoading: promotionsLoading,
  };
};
