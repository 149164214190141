import {
  mockedBetBuilderBet,
  mockedCoupon3xSingles,
  mockedCouponExceptionOfTheRules,
  mockedCouponPatent,
  mockedCouponSimple,
  mockedCouponTreble,
  mockedCouponWrong,
} from '../../components/Account/MyAccountActivity/CouponHistory/mockedCouponData';
import { KambiCouponsResponse, KambiOfferResponse, RewardStatusResponse } from './types';

export const useKambi = (): {
  getKambiCoupons: () => Promise<KambiCouponsResponse[]>;
  getKambiBetOffer: () => Promise<KambiOfferResponse | null>;
  getKambiReward: () => Promise<RewardStatusResponse | null>;
} => {
  const getKambiCoupons = async (): Promise<KambiCouponsResponse[]> => {
    return [
      mockedCouponSimple,
      mockedCoupon3xSingles,
      mockedCouponTreble,
      mockedCouponPatent,
      mockedCouponExceptionOfTheRules,
      mockedBetBuilderBet,
      mockedCouponWrong,
    ];
  };

  const getKambiBetOffer = async (): Promise<KambiOfferResponse | null> => {
    return null;
  };

  const getKambiReward = async (): Promise<RewardStatusResponse | null> => {
    return null;
  };

  return {
    getKambiCoupons,
    getKambiBetOffer,
    getKambiReward,
  };
};
