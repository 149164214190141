import React, { forwardRef, useCallback } from 'react';
import { Link, LinkProps as RouterLinkProps } from 'react-router-dom';
import { getLinkClassName } from './Link';
import { SharedLinkProps } from './Link.types';
import { joinStrings } from '../../../utils/string';
import { hashIsValidAsScrollTarget, tryScrollingToElementWithId } from '../../../utils/scroll-to-element';

/**
 * Wrapper link component for `react-router-dom` Link.
 */
export const RouterLink = forwardRef<HTMLAnchorElement, RouterLinkProps & SharedLinkProps>(
  ({ buttonStyle, children, className, size = 'auto', icon, iconPosition = 'left', ...props }, ref) => {
    /**
     * Intercepting hash urls that are expected to scroll to an element on page.
     */
    const { to: href } = props;
    let onClick;

    if (typeof href === 'string' && href.startsWith('#') && hashIsValidAsScrollTarget(href)) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      onClick = useCallback(
        (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
          if (tryScrollingToElementWithId(href.substr(1))) {
            event.preventDefault();
            props.onClick && props.onClick(event);
          }
        },
        [href, props]
      );
    }

    return (
      <Link
        {...props}
        ref={ref}
        className={joinStrings([getLinkClassName(size, buttonStyle), className])}
        onClick={onClick || props.onClick}
      >
        {icon && iconPosition === 'left' && (
          <span aria-hidden="true" className="link__icon-left" data-testid="link-icon-left">
            {icon}
          </span>
        )}
        {children}
        {icon && iconPosition === 'right' && (
          <span aria-hidden="true" className="link__icon-right" data-testid="link-icon-right">
            {icon}
          </span>
        )}
      </Link>
    );
  }
);

RouterLink.displayName = 'RouterLink';
