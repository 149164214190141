import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import React, { FunctionComponent, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { joinStrings } from '../../../utils/string';
import { PropValidationRuleConfig, withPropValidation } from '../../../utils/withPropValidation/withPropValidation';
import { useDeviceInfo } from '../../../hooks/useDeviceInfo';
import './Overlay.scss';

export type OverlayProps = {
  /**
   * Required. Whether the overlay is open or closed
   */
  isOpen: boolean;
  /**
   * Optional. Whether the overlay should have role 'alertdialog' or 'dialog'. Defaults to 'dialog'
   */
  role?: 'alertdialog' | 'dialog';
  /**
   * Optional. A label to describe the overlay to SR users. Either aria-label or aria-labelledby needs to be set.
   */
  'aria-label'?: string;
  /**
   * Optional. ID of an element which labels the overlay. Either aria-label or aria-labelledby needs to be set.
   */
  'aria-labelledby'?: string;
  /**
   * Optional. An ID referring to an element containing text which describes the overlay.
   */
  'aria-describedby'?: string;
  /**
   * Optional.  Defaults to false.  If true, the escape key or backdrop can trigger the onClose event.
   */
  canDismiss?: boolean;
  /**
   * Optional.  Defaults to false.  If true, the escape key can trigger the onClose event.
   */
  allowKeyboardInputs?: boolean;
  /**
   * Optional.  Defaults to false.
   * If true, clicking on the backdrop will close the viewport and trigger the onClose function.
   */
  backdropCanClose?: boolean;
  /**
   * Ref of element should receive focus when the overlay opens.
   * According to WAI-ARIA Authoring Practices '[When a dialog opens]
   * In all circumstances, focus moves to an element contained in the dialog.'
   * There is information here https://www.w3.org/TR/wai-aria-practices-1.1/#keyboard-interaction-7
   * about which element you should choose.
   */
  elementToFocusOnOpen?: React.RefObject<HTMLElement>;
  /**
   * Optional custom className to apply to the overlay content element.
   */
  className?: string;
  /**
   * Optional. Number indicating the milliseconds to wait before closing the overlay.
   */
  closeTimeoutMS?: number;
  /**
   * Optional, defaults to true.
   * If false, the element that had focus prior to the overlay's display will not receive focus again when it closes.
   * In this situation it's advisable to focus a different element when it closes.
   */
  shouldReturnFocusAfterClose?: boolean;
  /**
   * Optional, defaults to #root. Specify where your app content is located.
   * More information on what this is used for can be found here
   * http://reactcommunity.org/react-modal/accessibility/#app-element
   */
  appElement?: HTMLElement | HTMLElement[] | HTMLCollection | NodeList;
  /**
   * Optional class name to apply to the backdrop
   */
  backdropClassName?: string;
  /**
   * Custom overflow element. Needed for iOS if the main modal content is not actually the intended overflow target.
   *
   * If undefined then react-modal content ref will be used as default target
   */
  overflowTarget?: Nullable<HTMLElement>;
  /**
   * Function that executes when the overlay requests to close, (via clicking on backdrop or by pressing ESC)
   * Note: It is not called if isOpen is changed by other means.
   * Required for dismissible overlays - should at least set the 'isOpen' prop to false
   */
  onRequestClose?: () => void;
  /**
   * Optional. Function that will be run after the overlay has opened.
   */
  onAfterOpen?: () => void;
  /**
   * Optional. Function that will be run after the overlay has closed.
   */
  onAfterClose?: () => void;
  /*
   * Optional custom styles to apply to the modal overlay & content
   */
  style?: ReactModal.Styles;
  /*
   * Optional callback which returns the parent element
     that the modal will be attached to.
   */
  parentSelector?: () => HTMLElement;
  children?: React.ReactNode;
};

/**
 * A Overlay component used for covering the main window with a backdrop and displaying content on top.
 * It uses a 3rd party library called [React-Modal](http://reactcommunity.org/react-modal/) which adds support for
 * layering, key bindings, focus trapping, focus restoring and hiding background content from screenreaders.
 * Extra support for disabling scrolling on the body element when it's open has also been added.
 *
 * Used internally by the `Modal` component.
 *
 *
 * Note: React-testing-library with JSDom does not support testing scrolling,
 * so unit tests could not be written for the body scroll lock feature.
 * If this needs to be testing in the future it could be done with a different testing utility
 * which runs in a browser like cypress.
 */
const Component: FunctionComponent<OverlayProps> = ({
  isOpen,
  role = 'dialog',
  'aria-label': ariaLabel,
  'aria-describedby': ariaDescribedBy,
  'aria-labelledby': ariaLabelledBy,
  className,
  onRequestClose,
  children,
  onAfterOpen,
  elementToFocusOnOpen,
  canDismiss = false,
  allowKeyboardInputs = false,
  backdropCanClose = false,
  shouldReturnFocusAfterClose = true,
  backdropClassName,
  overflowTarget,
  ...reactModalProps
}) => {
  const [defaultOverflowTarget, setDefaultOverflowTarget] = useState<HTMLElement>();
  const { isIos } = useDeviceInfo();

  // Clear any body scroll locking if the overlay closes/is destroyed
  useEffect(() => {
    const allowTouchMove = (el: HTMLElement | Element): boolean => {
      while (el && el !== document.body) {
        if (el.getAttribute('body-scroll-lock-ignore') !== null) {
          return true;
        }

        el = el.parentElement as HTMLElement;
      }

      return false;
    };

    if (!isOpen) {
      clearAllBodyScrollLocks();
    } else if (overflowTarget) {
      !isIos &&
        disableBodyScroll(overflowTarget, {
          allowTouchMove,
        });
    } else if (typeof overflowTarget !== 'undefined' && defaultOverflowTarget) {
      disableBodyScroll(defaultOverflowTarget, {
        allowTouchMove,
      });
    } else {
      clearAllBodyScrollLocks();
    }

    return clearAllBodyScrollLocks;
  }, [isOpen, defaultOverflowTarget, overflowTarget]);

  const handleAfterOpen = (): void => {
    if (elementToFocusOnOpen && elementToFocusOnOpen.current) {
      elementToFocusOnOpen.current.focus();
    }

    if (onAfterOpen) {
      onAfterOpen();
    }
  };

  return (
    <ReactModal
      {...reactModalProps}
      shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
      isOpen={isOpen}
      contentLabel={ariaLabel}
      shouldCloseOnEsc={canDismiss || allowKeyboardInputs}
      shouldCloseOnOverlayClick={canDismiss || backdropCanClose}
      className={className}
      role={role}
      aria={{
        describedby: ariaDescribedBy,
        modal: true,
        labelledby: ariaLabelledBy,
      }}
      onAfterOpen={handleAfterOpen}
      onRequestClose={onRequestClose}
      contentRef={setDefaultOverflowTarget}
      overlayClassName={joinStrings(['overlay-backdrop', backdropClassName])}
      ariaHideApp={false}
    >
      {children}
    </ReactModal>
  );
};

Component.displayName = 'Overlay';

const validationRules: PropValidationRuleConfig<OverlayProps>[] = [
  {
    message: `Dismissible overlays require an onRequestClose prop to be defined,
      which at the minimum should set the isOpen prop to false.`,
    logType: 'error',
    validate: ({ canDismiss = false, onRequestClose }: OverlayProps): boolean => {
      return canDismiss && !onRequestClose;
    },
  },
  {
    message: 'For accessibility purposes, overlays require the elementToFocusOnOpen prop to be set.',
    logType: 'error',
    validate: ({ elementToFocusOnOpen }: OverlayProps): boolean => {
      return !elementToFocusOnOpen;
    },
  },
  {
    message:
      'Overlays require either the aria-label prop or aria-labelledby prop to be set for accessibility purposes.',
    logType: 'error',
    validate: (props: OverlayProps): boolean => {
      return !props['aria-label'] && !props['aria-labelledby'];
    },
  },
];

export const Overlay = withPropValidation({
  Component,
  validationRules,
});
