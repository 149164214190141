import React, { FC, Suspense, useState } from 'react';
import { WelcomeBonusTermsAndConditions } from './WelcomeBonusTermsAndConditions';
import { BonusItemPromptProps } from './types';
import './styles';
import WelcomeBonusCalculator from './WelcomeBonusCalculator';
import { Spinner } from '../../shared/Spinner';

export type BonusConfigurationProps = Pick<BonusItemPromptProps, 'configuration'>;
interface WelcomeBonusContentProps {
  termsAndConditions?: string;
  imgPath?: string;
  imgAlt?: string;
  promoCode?: string;
  setDepositAmount?: (param: string) => void;
  depositAmount?: string;
  setInputError?: (param: string) => void;
  inputError?: string;
}

export const WelcomeBonusContent: FC<WelcomeBonusContentProps & BonusConfigurationProps> = ({
  termsAndConditions,
  imgPath,
  imgAlt,
  configuration,
  promoCode,
  setDepositAmount,
  depositAmount,
  inputError,
  setInputError,
}) => {
  const [isTnCShown, setTnCShown] = useState(false);

  return isTnCShown ? (
    <WelcomeBonusTermsAndConditions
      title="Terms and conditions"
      description={termsAndConditions}
      back={(): void => setTnCShown(false)}
    />
  ) : (
    <Suspense fallback={<Spinner />}>
      <WelcomeBonusCalculator
        depositAmount={depositAmount}
        setDepositAmount={setDepositAmount}
        showTnC={(): void => setTnCShown(true)}
        imgPath={imgPath}
        imgAlt={imgAlt}
        promoCode={promoCode}
        configuration={configuration}
        inputError={inputError}
        setInputError={setInputError}
      />
    </Suspense>
  );
};
