import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FooterGetQueryVariables = Types.Exact<{
  language: Types.Scalars['String']['input'];
}>;


export type FooterGetQuery = { __typename?: 'Query', site_footer?: { __typename?: 'site_footer', translations?: Array<{ __typename?: 'site_footer_translations', footer_text?: string | null, copyright_text?: string | null } | null> | null, collections?: Array<{ __typename?: 'site_footer_footer_collection', footer_collection_id?: { __typename?: 'footer_collection', internal_name?: string | null, translations?: Array<{ __typename?: 'footer_collection_translations', title?: string | null } | null> | null, items?: Array<{ __typename?: 'footer_collection_footer_item', footer_item_id?: { __typename?: 'footer_item', thumbnail?: { __typename?: 'directus_files', title?: string | null, filename_disk?: string | null } | null, translations?: Array<{ __typename?: 'footer_item_translations', title?: string | null, url?: string | null, open_in_new_tab?: boolean | null } | null> | null } | null } | null> | null } | null } | null> | null } | null };


export const FooterGetDocument = gql`
    query FooterGet($language: String!) {
  site_footer {
    translations(
      filter: {languages_language_code: {language_code: {_eq: $language}}}
    ) {
      footer_text
      copyright_text
    }
    collections(filter: {footer_collection_id: {status: {_eq: "published"}}}) {
      footer_collection_id(filter: null) {
        internal_name
        translations(
          filter: {languages_language_code: {language_code: {_eq: $language}}}
        ) {
          title
        }
        items(filter: {footer_item_id: {status: {_eq: "published"}}}) {
          footer_item_id(filter: null) {
            thumbnail(filter: null) {
              title
              filename_disk
            }
            translations(
              filter: {languages_language_code: {language_code: {_eq: $language}}}
            ) {
              title
              url
              open_in_new_tab
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useFooterGetQuery__
 *
 * To run a query within a React component, call `useFooterGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useFooterGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFooterGetQuery({
 *   variables: {
 *      language: // value for 'language'
 *   },
 * });
 */
export function useFooterGetQuery(baseOptions: Apollo.QueryHookOptions<FooterGetQuery, FooterGetQueryVariables> & ({ variables: FooterGetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FooterGetQuery, FooterGetQueryVariables>(FooterGetDocument, options);
      }
export function useFooterGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FooterGetQuery, FooterGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FooterGetQuery, FooterGetQueryVariables>(FooterGetDocument, options);
        }
export function useFooterGetSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FooterGetQuery, FooterGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FooterGetQuery, FooterGetQueryVariables>(FooterGetDocument, options);
        }
export type FooterGetQueryHookResult = ReturnType<typeof useFooterGetQuery>;
export type FooterGetLazyQueryHookResult = ReturnType<typeof useFooterGetLazyQuery>;
export type FooterGetSuspenseQueryHookResult = ReturnType<typeof useFooterGetSuspenseQuery>;
export type FooterGetQueryResult = Apollo.QueryResult<FooterGetQuery, FooterGetQueryVariables>;