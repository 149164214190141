import { ApolloError } from '@apollo/client';
import React, { FC, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useLocation, useParams } from 'react-router-dom';
import { locale, xTenantId } from '../../../../consts';
import { useFetchPromoDetails } from '../../../../hooks/useFetchPromoDetails';
import { NavigationTemplate } from '../../../../layouts/NavigationTemplate';
import {
  UserBonusesQuery,
  useUserBonusesLazyQuery,
} from '../../../../libs/graphql/baseAppAPI/queries/__generated__/user-promo-collection.generated';
import { BonusGetBySlugQuery } from '../../../../libs/graphql/betzoneDirectusAPI/queries/__generated__/bonus-get-by-slug.query.generated';
import { Endpoint } from '../../../../models/api.model';
import { useUserContext } from '../../../../providers/UserProvider';
import { UserProfileBonusStatus } from '../../../../types.__generated__';
import { RecommendedGameCarousel } from '../../../Games';
import { ButtonBack } from '../../../shared/ButtonBack';
import { Icon } from '../../../shared/Icon';
import { Paragraph } from '../../../shared/Paragraph';
import { Spinner } from '../../../shared/Spinner';
import { PromotionCard } from '../PromotionCard';
import { PromotionMessagesPrompt } from '../PromotionMessagesPrompt';
import { GigPromo } from '../types';
import PromotionHowItWorksBlock from './components/PromotionHowItWorksBlock';
import { getImageUrl } from '../../../../utils/navigation';
import './styles';

export const PromotionMainBlock: FC = () => {
  const location = useLocation();
  const { userProfileId } = useUserContext();
  const isUserAuthenticated = useAuth()?.isAuthenticated;
  const pageUrl = location.pathname;

  const promotionSlug = String(useParams().id);

  const howItWorks = {
    stepsTitle: 'How it works',
    steps: [
      'Create an new account or Log in',
      "Deposit £50 using the 'CLAIM' button on this page",
      'Place a bet of £50 and Lance will get you x5 £10 bets',
    ],
  };

  const [promotion, setPromotion] = useState<BonusGetBySlugQuery | null>(null);
  const [gigPromotion, setGigPromotion] = useState<GigPromo | null>(null);
  const [showPromoPrompt, setShowPromoPrompt] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);

  const isActive = ([UserProfileBonusStatus.InProgress, UserProfileBonusStatus.Claimed] as string[]).includes(
    String(gigPromotion?.status)
  );
  const promo = promotion?.bonus[0];
  const { template_id, start_date, end_date, translations } = promo || {};
  const resTransl = translations?.[0];

  const title = resTransl?.title || '';
  const description = resTransl?.subtitle || '';
  const terms = resTransl?.description || '';
  const imgPath = getImageUrl(resTransl?.thumbnail?.filename_disk);
  const imgAlt = resTransl?.thumbnail?.title || '';

  // Mocks are partially applied here due to pending all items from CMS
  const parsedPromo = {
    promoCode: 'test-code',
    promoButtonText: 'CLAIM',
    promotionCTAType: 'DepositJourney',
    bonusId: template_id || '',
    startDate: start_date || '',
    endDate: end_date || '',
    title,
    description,
    terms,
    imgPath,
    imgAlt,
    pageUrl,
  };

  const mergedPromo = { ...parsedPromo, ...gigPromotion };

  const handleGigPromos = (data: UserBonusesQuery): void => {
    const gigBonuses = data.userBonuses.data;
    const gigPromo = (gigBonuses as GigPromo[])?.find(
      (gigBonus: GigPromo) => parsedPromo.bonusId === gigBonus?.bonusTemplateId
    );

    gigPromo && setGigPromotion(gigPromo);
  };

  const { fetchBonusDetailsBySlug, fetchedBonusDetailsBySlugLoading } = useFetchPromoDetails({
    // TODO: replace hardcoded language value with dynamic
    language: locale,
    slug: promotionSlug,
    onFetchBonusBySlugComplete: setPromotion,
  });

  const [fetchBonusesGig] = useUserBonusesLazyQuery({
    variables: {
      xTenantId,
      userProfileId,
    },
    onCompleted: handleGigPromos,
    onError: (error: ApolloError) => console.error(error),
    context: { endpoint: Endpoint.appAPI },
  });

  useEffect(() => {
    (async (): Promise<void> => {
      setFetching(true);

      await fetchBonusDetailsBySlug();
      isUserAuthenticated && (await fetchBonusesGig());

      setFetching(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NavigationTemplate paddingDisabled>
      <div className="promotion-main-wrapper">
        {fetchedBonusDetailsBySlugLoading || fetching ? (
          <Spinner />
        ) : promo ? (
          <>
            <div className="button-left">
              <ButtonBack
                position="left"
                icon={<Icon variant="ArrowLeft" />}
                type="button"
                size="x-large"
                variant="text"
                text="BACK"
                siteLink="pagePromotionsLobby"
                translation="promotion-detail-page-btn-back"
              />
            </div>
            <div className="promotion-main-wrapper__info-block">
              <PromotionCard
                withoutMargin
                promo={mergedPromo}
                showCTASection={isActive}
                isActivePromotions={isActive}
                showMoreButon={false}
              />
              <PromotionHowItWorksBlock
                stepsTitle={howItWorks.stepsTitle}
                steps={howItWorks.steps}
                promo={mergedPromo}
                onActivateBonus={(): void => setShowPromoPrompt(true)}
                isActive={isActive}
              />
            </div>
          </>
        ) : (
          <Paragraph size="lg" className="promotion-main-wrapper__not-found">
            The promotion is not found
          </Paragraph>
        )}
        <RecommendedGameCarousel
          visibleConfig={{
            wide: 6,
            desktop: 6,
            tablet: 4,
            mobile: 2,
          }}
          showDots={false}
          title="Suggested Eligible Games"
        />
      </div>
      <PromotionMessagesPrompt
        imgMobileAlt=""
        imgMobilePath=""
        setShow={setShowPromoPrompt}
        show={showPromoPrompt}
        paragraphText="Do you really want to claim this promotion?"
        ctaTextSecondary="CANCEL"
        onClose={(): void => setShowPromoPrompt(false)}
        activatedPromo={mergedPromo}
      />
    </NavigationTemplate>
  );
};

export default PromotionMainBlock;
