import noop from 'lodash/noop';
import React, { forwardRef, FunctionComponent, useState } from 'react';
import { InputValidationAlerts } from '../InputValidationAlert';
import { FloatingLabel, Label } from '../Label';
import { SelectProps } from './Select.shared';
import { SelectNonNative } from './SelectNonNative';
import { joinStrings } from '../../../utils/string';
import {
  PropValidationRuleConfig,
  PropValidationRuleLogType,
  PropValidationSelectEnum,
  withPropValidation,
} from '../../../utils/withPropValidation/withPropValidation';
import './Select.scss';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const Component: FunctionComponent<SelectProps> = forwardRef(
  (
    {
      validationMessages,
      validationState,
      label,
      options,
      value,
      defaultValue,
      placeholder,
      showFeedbackIcon = false,
      theme = 'light',
      placement = 'bottom',
      disabled,
      labelProps,
      reducedHeight,
      showSelectIcon,
      onFocus = noop,
      hasSearchInput = false,
      phoneInputRef,
      ...props
    },
    ref
  ) => {
    const [inputValue, setInputValue] = useState<string | undefined>('');

    const labelVariant = labelProps?.labelVariant || 'floating';
    const hideFloatingLabel = labelVariant === 'floating' && placeholder && !value;

    const hideUnselectedOption = labelVariant === 'floating' && !placeholder && !value;

    const dropdownProps = {
      validationMessages,
      validationState,
      options,
      theme,
      value,
      reducedHeight,
      showSelectIcon,
      placement,
      placeholder,
      showFeedbackIcon,
      disabled,
      hideUnselectedOption,
      defaultValue,
      onFocus,
      inputValue: hasSearchInput ? inputValue : value,
      setInputValue,
      ...props,
    };

    return (
      <div className={joinStrings(['select', theme === 'dark' && 'select--dark'])} data-testid="select">
        <div className="select__control">
          {Boolean(label) &&
            (labelVariant === 'floating' ? (
              <FloatingLabel
                theme={theme}
                className={joinStrings(['select__label', hideFloatingLabel && 'label--floating--hidden'])}
                disabled={disabled}
                htmlFor={props.id || props.name}
                floatedTop={!!value || !!defaultValue}
                {...labelProps}
              >
                {label}
              </FloatingLabel>
            ) : (
              <Label
                theme={theme}
                className="select__label"
                disabled={disabled}
                htmlFor={props.id || props.name}
                {...labelProps}
              >
                {label}
              </Label>
            ))}
          <div className="select__dropdown">
            <SelectNonNative
              {...dropdownProps}
              ref={ref}
              phoneInputRef={phoneInputRef}
              hasSearchInput={hasSearchInput}
              theme={theme}
            />
          </div>

          <div className="select__alerts">
            <InputValidationAlerts messages={validationMessages} state={validationState} />
          </div>
        </div>
      </div>
    );
  }
);

Component.displayName = 'Select';

const logType: PropValidationRuleLogType = 'error';
const presentAsDefault: PropValidationSelectEnum = PropValidationSelectEnum.Auto;

const validationRules: PropValidationRuleConfig<SelectProps>[] = [
  {
    message: `customDropDownItemRender can not be used with native select`,
    logType,
    validate: ({ options }: SelectProps): boolean => {
      return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        presentAsDefault === PropValidationSelectEnum.Native &&
        options.some((option) => option.customDropDownItemRender)
      );
    },
  },
];

/**
 * A controlled Select component.
 *
 * ### Example - As Controlled Select
 *
 * An example using useState hook to control the Select component state.
 *
 * ```tsx
 * const UseStateExample = () => {
 *   const [value, setValue] = useState('Other value');
 *   const options: SelectOption[] = [
 *     { value: 'V', label: 'Vic' },
 *     { value: 'B', label: 'Bob' },
 *     { value: 'I', label: 'Ickers', imageSrc: 'src/image.png' }
 *   ];
 *   return (
 *     <Fragment>
 *       <Select
 *         name="selectFieldName"
 *         value={value}
 *         label="Person Name"
 *         placeholder="Select a name.."
 *         onChange={(val)=> setValue(val)}
 *       />
 *     </Fragment>
 *   );
 * };
 * ```
 *
 * ### Example - React Hook Form
 *
 * An example which uses `react-hook-form` as the controller of state.
 *
 * ```tsx
 * import { useForm, Controller } from 'react-hook-form';
 *
 * const HookFormExample = ({
 *   onSubmit
 * }: {
 *   onSubmit: (formValues) => void;
 * }) => {
 *   const options: SelectOption[] = [
 *     { value: 'V', label: 'Vic' },
 *     { value: 'B', label: 'Bob' }
 *     { value: 'I', label: 'Ickers', imageSrc: 'src/image.png' }
 *   ];
 *   const { control, handleSubmit } = useForm({
 *     defaultValues: { fieldName: 'V' }
 *   });
 *   return (
 *     <form onSubmit={handleSubmit(onSubmit)}>
 *       <Controller
 *         name='fieldName'
 *         control={control}
 *         as={<Select label='Vic or Bob' options={options} />}
 *       />
 *       <Button type='submit'>Submit</Button>
 *    </form>
 *   );
 * };
 * ```
 */
export const Select = withPropValidation({ Component, validationRules });
