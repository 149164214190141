import React, { FC, useEffect, useRef } from 'react';
import { TouchDrawer, TouchDrawerProps } from '../TouchDrawer';
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalHeaderProps, ModalProps } from '../Modal';
import { Orientation, useDeviceInfo, useOrientation } from '../../../hooks/useDeviceInfo';
import { Heading } from '../Heading';

export enum DrawerType {
  Prompt = 'prompt',
  Modal = 'modal',
}

export type PopUpHandlerProps = {
  /**
   * If true, pop up will render correct component
   */
  isOpen: boolean;
  /**
   * Function that is triggered once the pop up is closed
   */
  onClose?: () => void;
  /**
   * Content that is rendered within the body section
   */
  children?: React.ReactNode;
  /**
   * Content that is rendered within the header section
   */
  header?: string;
  /**
   * Content that is rendered within the footer section
   */
  footer?: React.ReactNode;
  /**
   * Whether to display overflow scrolling overlay.  Defaults to true.
   */
  modalBodyOverflow?: boolean;
  /**
   * Whether to display touchpad. Defaults to true.
   */
  showTouchPad?: boolean;
  /**
   * Whether to display close button. Defaults to true.
   */
  showCloseBtn?: boolean;
  /**
   * Whether to make specific landcsape start prompt position.  Defaults to false.
   */
  handleLandscape?: boolean;
  /**
   * TouchDrawer's specific props
   */
  backdropClassName?: string;
  drawerProps?: Omit<
    TouchDrawerProps,
    'onClose' | 'show' | 'footer' | 'children' | 'header' | 'subTitle' | 'elementToFocusOnOpen'
  >;
  /**
   * Modal's specific props
   */
  modalProps?: Omit<ModalProps, 'isOpen' | 'children' | 'onRequestClose' | 'elementToFocusOnOpen'>;
  /**
   * Heading specific props
   */
  headingProps?: Omit<ModalHeaderProps, 'heading'>;
  /**
   * Optional callback that will be executed when the rendered pop up component changes.
   */
  onChange?: () => void;
  /**
   * element to have focus on load
   */
  elementToFocusOnOpen?: React.RefObject<HTMLElement>;
  /**
   * indicates type of drawer
   * By default: modal - for desktop and prompt - for tablet and mobile
   */
  drawerType?: DrawerType;
  /**
   * Optional content that can be rendered below header
   */
  subTitle?: string;
  /**
   * Optional Icon that can be rendered inside header
   */
  feedbackIcon?: React.ReactNode;
};
/**
 * Simple wrapper component to handle which pop up component to render.
 * Desktop = Modal
 * Mobile = TouchDrawer
 */
export const PopUpHandler: FC<PopUpHandlerProps> = ({
  isOpen,
  showTouchPad = true,
  showCloseBtn = true,
  drawerType,
  children,
  header,
  subTitle,
  footer,
  drawerProps,
  modalProps,
  headingProps = {},
  elementToFocusOnOpen,
  modalBodyOverflow = true,
  handleLandscape = false,
  backdropClassName,
  onChange,
  feedbackIcon,
  onClose,
}) => {
  const firstRender = useRef(false);

  const { isMobileDevice, isTablet } = useDeviceInfo();
  const isMobilePhonePortAndLand = isMobileDevice && !isTablet;
  const isLandscape = useOrientation() === Orientation.LANDSCAPE;
  const { headingLevel = 5, styleHeadingAs } = headingProps;

  useEffect(() => {
    if (firstRender.current) {
      onChange && onChange();
    } else {
      firstRender.current = true;
    }
  }, [isMobilePhonePortAndLand, onChange]);

  return (isMobilePhonePortAndLand && drawerType !== DrawerType.Modal) || drawerType === DrawerType.Prompt ? (
    <TouchDrawer
      aria-label={header || 'drawer-aria'}
      showTouchPad={showTouchPad}
      showCloseBtn={showCloseBtn}
      positionCloseBtn="inside"
      backdropCanClose
      allowKeyboardInputs
      show={isOpen}
      onClose={onClose}
      footer={footer}
      elementToFocusOnOpen={elementToFocusOnOpen}
      {...(handleLandscape && isLandscape && { location: 'left' })}
      {...drawerProps}
      header={
        header && (
          <Heading noMargin level={headingLevel} styleAs={styleHeadingAs || headingLevel}>
            {header}
            {feedbackIcon && feedbackIcon}
          </Heading>
        )
      }
      subTitle={
        subTitle && (
          <Heading noMargin level={6} styleAs={styleHeadingAs || 6}>
            {subTitle}
          </Heading>
        )
      }
    >
      {children}
    </TouchDrawer>
  ) : (
    <Modal
      {...modalProps}
      isOpen={isOpen}
      onRequestClose={onClose}
      elementToFocusOnOpen={elementToFocusOnOpen}
      closeTimeoutMS={300}
      backdropClassName={backdropClassName}
      showCloseBtn={showCloseBtn}
      aria-label={header || 'modal-aria'}
    >
      {header && (
        <ModalHeader
          {...headingProps}
          heading={header}
          feedbackIcon={feedbackIcon}
          subTitle={
            subTitle && (
              <Heading className="modal-subtitle__heading" noMargin level={6} styleAs={styleHeadingAs || 6}>
                {subTitle}
              </Heading>
            )
          }
        />
      )}

      {children && <ModalBody withOverflow={modalBodyOverflow}>{children}</ModalBody>}
      {footer && <ModalFooter>{footer}</ModalFooter>}
    </Modal>
  );
};
