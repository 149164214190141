import React, { FunctionComponent } from 'react';
import { joinStrings } from '../../../utils/string';
import './styles';

export type BadgeProps = {
  /**
   * Content to display within badge
   */
  children: React.ReactNode;
  /**
   * Shape of surrounding box. Defaults to rectangle.
   */
  shape?: 'rectangle' | 'circle';
  /**
   * Optional badge colour - default is defined in CSS vars
   */
  color?: 'default' | 'error';
} & React.ComponentPropsWithoutRef<'div'>;

/**
 * Badge Component
 */
export const Badge: FunctionComponent<BadgeProps> = ({
  shape = 'rectangle',
  color = 'default',
  children,
  ...props
}) => {
  return (
    <div {...props} className={joinStrings(['badge', `badge--${shape}`, `badge--color-${color}`, props.className])}>
      {children}
    </div>
  );
};
