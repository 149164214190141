import noop from 'lodash/fp/noop';
import { FC } from 'react';
import { useAuth } from 'react-oidc-context';
import { useLocation, useNavigate } from 'react-router-dom';
import defaultImage from '../../../../assets/Games/slot_8.png';
import { useClickableElement } from '../../../../hooks/presentation-logic/useClickableElement';
import { storageRoute } from '../../../../utils/storage';
import { joinStrings } from '../../../../utils/string';
import { SimpleGameCardLabel } from '../../../shared/SimpleGameCardLabel';
import { Game } from '../../games.model';
import { GameCardContainerType } from '../GameCard.types';
import { getRedirectUri } from '../../../Authentification/helper';
import './SimpleGameCard.scss';

interface GameCardProps {
  game: Game;
  withLabel?: boolean;
  containerType?: GameCardContainerType;
  className?: string;
  onClose?: () => void;
}

export const SimpleGameCard: FC<GameCardProps> = ({
  game,
  withLabel,
  containerType = 'box',
  className,
  onClose = noop,
}): JSX.Element => {
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { gameImage, friendlyUrlName, displayName } = game;
  const { alt = 'game image', url = defaultImage } =
    {
      alt: displayName,
      url: gameImage.imgUrl,
    } || {};
  const isUserAuthenticated = auth?.isAuthenticated;
  const isInAGame = location.pathname.includes('/real');

  const launchGameHandler = (): void => {
    const gameUrl = `/game-details/${friendlyUrlName}/real`;

    if (isUserAuthenticated) {
      onClose();
      navigate(gameUrl);

      if (!isInAGame) {
        storageRoute.set({
          value: location.pathname,
        });
      }
    } else {
      auth.signinRedirect({ redirect_uri: getRedirectUri(gameUrl) });
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const clickableElement = launchGameHandler ? useClickableElement(launchGameHandler) : {};

  return (
    <div
      className={joinStrings(['simple-game-card', `simple-game-card--${containerType}`, className && className])}
      {...clickableElement}
    >
      {withLabel && <SimpleGameCardLabel labelText="YOU MAY LIKE" />}
      <div className="game-card-basic__inner">
        <img className="simple-game-card__img" src={url} alt={alt} />
      </div>
    </div>
  );
};
