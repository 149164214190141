import React, { forwardRef } from 'react';
import { LinkButtonStyle, LinkProps, LinkSize } from './Link.types';
import { joinStrings } from '../../../utils/string';
import './styles';

export const getLinkClassName = (linkSize: LinkSize, buttonStyle?: LinkButtonStyle): string =>
  `${buttonStyle ? `button button--${buttonStyle.size} button--${buttonStyle.variant}` : `link link--${linkSize}`}`;

/**
 * A component for rendering navigation links in a consistent style.
 * It can also be styled as a Button, by setting the buttonStyle prop.
 *
 * ### Example
 *
 * ```tsx
 * <Link
 *   href='https://www.bedegaming.com/'
 *   buttonStyle={{ variant: 'primary', size: 'medium' }}>
 *   Bede Gaming
 * </Link>
 * ```
 */
export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ className, children, label, buttonStyle, icon, iconPosition = 'left', size = 'auto', ...props }, ref) => {
    const styleAs = buttonStyle ? 'button' : 'link';

    return (
      <a {...props} ref={ref} className={joinStrings([getLinkClassName(size, buttonStyle), className])}>
        {icon && iconPosition === 'left' && (
          <span aria-hidden="true" className={`${styleAs}__icon-left`} data-testid={`${styleAs}-icon-left`}>
            {icon}
          </span>
        )}
        {label || children}
        {icon && (iconPosition === 'right' || !iconPosition) && (
          <span aria-hidden="true" className={`${styleAs}__icon-right`} data-testid={`${styleAs}-icon-right`}>
            {icon}
          </span>
        )}
      </a>
    );
  }
);

Link.displayName = 'Link';
