import axios from 'axios';

export const notifyGiG = async (UserId: string, RuleName: string): Promise<void> => {
  const payload = {
    UserId,
    RuleName,
  };

  await axios.post(
    'https://api-logic-stg.gigcloud.com/integration/webhook/betzone/osmacknowledged1376/5d3d3782-5f5d-4ef6-b41c-c89a8f4ebf79',
    payload
  );
};
