import noop from 'lodash/noop';
import React, { FunctionComponent, useCallback } from 'react';
import { animated, useSpring } from 'react-spring';
import { APP_URLS } from '../../../../consts';
import { Button } from '../../../shared/Button';
import { Checkbox } from '../../../shared/Checkbox';
import { Heading } from '../../../shared/Heading';
import { Icon } from '../../../shared/Icon';
import { ConsentType } from '../types';
import { useCookiePreferencesContext } from '../CookiePreferences.context';
import './styles';

type CookieSectionProps = {
  title: string;
  content: string;
  checked: boolean;
  category?: string;
  disabled?: boolean;
  onClick?: () => void;
  alwaysActive?: boolean;
  setActiveCategory: (value: string) => void;
  setIsDetailPageDisplayed: (value: boolean) => void;
};

const CookieSection: FunctionComponent<CookieSectionProps> = ({
  title,
  content,
  disabled,
  checked,
  alwaysActive,
  onClick = noop,
  category = '',
  setIsDetailPageDisplayed,
  setActiveCategory,
}) => {
  const onCheckDetailsClick = useCallback(() => {
    setActiveCategory(category);
    setIsDetailPageDisplayed(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  return (
    <div className="cookie-preference-control__section">
      <div className="cookie-preference-control__split">
        <span className="cookie-preference-control__title">{title}</span>
        {alwaysActive ? (
          <span className="cookie-preference-control__title">Always Active</span>
        ) : (
          <Checkbox
            disabled={disabled}
            checked={checked}
            onClick={(): void => {
              !disabled && onClick();
            }}
            variant="switch"
          />
        )}
      </div>
      <div className="cookie-preference-control__section-content">
        {content}
        {category && (
          <>
            <Button type="button" variant="text" onClick={onCheckDetailsClick}>
              Check details
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export const CookieControl: FunctionComponent = () => {
  const {
    isTargeted,
    isEssential,
    isFunctional,
    isConfigReady,
    isPerformance,
    isSubmitting,
    isAccepting,
    isRejecting,
    onSave,
    setIsTargeted,
    setIsEssential,
    setIsAccepting,
    setIsFunctional,
    setIsPerformance,
    setActiveCategory,
    setIsDetailPageDisplayed,
  } = useCookiePreferencesContext();

  const animationProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
  });

  const onAcceptAllBtnClick = async (): Promise<void> => {
    setIsAccepting(true);
    setIsTargeted(true);
    setIsFunctional(true);
    setIsPerformance(true);
    setIsEssential(true);

    await onSave({
      enableAll: true,
      isTargeted: true,
      isEssential: true,
      isFunctional: true,
      isPerformance: true,
    });

    setIsAccepting(false);
  };

  return (
    <animated.div style={animationProps}>
      <div className="cookie-preference-control__description">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Interdum interdum rhoncus vestibulum, molestie praesent
        habitant odio tristique eros. Aliquam arcu sagittis, et orci molestie in et facilisis. Orci urna posuere.
      </div>

      <div className="cookie-preference-control__links">
        <a className="cookie-preference-control__link" href={APP_URLS.cookiePolicy}>
          <Icon variant="Cookie" />
          <div className="cookie-preference-control__link-name">Cookies Policy</div>
        </a>
        <a className="cookie-preference-control__link" href={APP_URLS.cookiePolicy}>
          <Icon variant="Shield" />
          <div className="cookie-preference-control__link-name">Privacy Policy</div>
        </a>
      </div>

      <div className="cookie-preference-control__explanation">
        <Heading level={5}>What are cookies?</Heading>
        <span>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Interdum interdum rhoncus vestibulum, molestie
          praesent habitant odio tristique eros.
        </span>
      </div>

      <Button
        size="large"
        className="cookie-preference-control__head-button"
        type="button"
        variant="primary"
        disabled={(isFunctional && isPerformance && isTargeted && isEssential) || isSubmitting || isRejecting}
        onClick={onAcceptAllBtnClick}
        isSubmitting={isAccepting}
      >
        ACCEPT ALL COOKIES
      </Button>

      {isConfigReady && (
        <div className="cookie-preference-control__categories">
          <Heading className="cookie-preference-control__categories-header" level={5}>
            Manage your Permission Preferences
          </Heading>
          <CookieSection
            title="Strictly Necessary Cookies"
            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Interdum interdum rhoncus vestibulum, molestie praesent habitant odio tristique eros. Aliquam arcu sagittis, et orci molestie in et facilisis. Orci urna posuere."
            checked={isEssential}
            setIsDetailPageDisplayed={setIsDetailPageDisplayed}
            category={ConsentType.STRICTLY_NECESSARY}
            onClick={(): void => {
              setIsEssential(!isEssential);
            }}
            setActiveCategory={setActiveCategory}
          />

          <CookieSection
            title="Functionality Cookies"
            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Interdum interdum rhoncus vestibulum, molestie praesent habitant odio tristique eros. Aliquam arcu sagittis, et orci molestie in et facilisis. Orci urna posuere."
            checked={isFunctional}
            category={ConsentType.FUNCTIONAL}
            setIsDetailPageDisplayed={setIsDetailPageDisplayed}
            onClick={(): void => {
              setIsFunctional(!isFunctional);
            }}
            setActiveCategory={setActiveCategory}
          />

          <CookieSection
            title="Performance Cookies"
            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Interdum interdum rhoncus vestibulum, molestie praesent habitant odio tristique eros. Aliquam arcu sagittis, et orci molestie in et facilisis. Orci urna posuere."
            checked={isPerformance}
            category={ConsentType.PERFORMANCE}
            setIsDetailPageDisplayed={setIsDetailPageDisplayed}
            onClick={(): void => {
              setIsPerformance(!isPerformance);
            }}
            setActiveCategory={setActiveCategory}
          />

          <CookieSection
            title="Third Party Marketing / Targeting Cookies"
            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Interdum interdum rhoncus vestibulum, molestie praesent habitant odio tristique eros. Aliquam arcu sagittis, et orci molestie in et facilisis. Orci urna posuere."
            checked={isTargeted}
            setIsDetailPageDisplayed={setIsDetailPageDisplayed}
            category={ConsentType.TARGETING}
            onClick={(): void => {
              setIsTargeted(!isTargeted);
            }}
            setActiveCategory={setActiveCategory}
          />
        </div>
      )}
    </animated.div>
  );
};
