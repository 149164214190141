import { isEqual, min, max, differenceInMinutes, differenceInSeconds, differenceInHours } from 'date-fns';
import range from 'lodash/range';
import { validDate } from './form/validation';

/**
 * Helper which will return the valid date string if only one exists otherwise invokes a provided function to find a result
 * @param dateStrA Date string, expected to match dateFormat arg.
 * @param dateStrB Date string, expected to match dateFormat arg.
 * @param dateFormat date-fns date format
 * @param comp Function to compare dates if they're valid and return relevant date string
 */
function findDate(
  dateStrA: string | undefined,
  dateStrB: string | undefined,
  dateFormat: string,
  comp: (dateA: Date, dateB: Date) => string | undefined
): string | undefined {
  const dateA = validDate(dateStrA, dateFormat);
  const dateB = validDate(dateStrB, dateFormat);

  if (dateA && dateB) return comp(dateA, dateB);

  if (dateA) return dateStrA;

  if (dateB) return dateStrB;

  return undefined;
}

/**
 * Find the earliest of two potentially undefined date strings;
 * @param dateA Date string, expected to match dateFormat arg.
 * @param dateB Date string, expected to match dateFormat arg.
 * @param dateFormat  date-fns date format
 */
export const findEarliestDateString = (
  dateA: string | undefined,
  dateB: string | undefined,
  dateFormat: string
): string | undefined => findDate(dateA, dateB, dateFormat, (a, b) => (isEqual(min([a, b]), a) ? dateA : dateB));

/**
 * Find the latest of two potentially undefined date strings;
 * @param dateA Date string, expected to match dateFormat arg.
 * @param dateB Date string, expected to match dateFormat arg.
 * @param dateFormat  date-fns date format
 */
export const findLatestDateString = (
  dateA: string | undefined,
  dateB: string | undefined,
  dateFormat: string
): string | undefined => findDate(dateA, dateB, dateFormat, (a, b) => (isEqual(max([a, b]), a) ? dateA : dateB));

const formatDuration = (duration: number): string => {
  return duration < 10 ? `0${duration}` : duration.toString();
};

export const formattedTimeDifference = (dateA: Date, dateB: Date, seconds = false, delimiter = ' : '): string => {
  const totalMinutes = differenceInMinutes(dateA, dateB);
  const totalHours = differenceInHours(dateA, dateB);
  const adjustedMinutes = totalMinutes - totalHours * 60;
  const hoursAndMinutes = [formatDuration(totalHours), formatDuration(adjustedMinutes)].join(delimiter);

  if (seconds) {
    const totalSeconds = differenceInSeconds(dateA, dateB);
    const adjustedSeconds = totalSeconds - totalMinutes * 60;

    return [hoursAndMinutes, formatDuration(adjustedSeconds)].join(delimiter);
  }

  return hoursAndMinutes;
};

export const getMonthList = (locales?: string | string[]): string[] => {
  const year = new Date().getFullYear();
  const monthList = range(0, 12);
  const formatter = new Intl.DateTimeFormat(locales, {
    month: 'short',
  });

  const getMonthName = (monthIndex: number): string => formatter.format(new Date(year, monthIndex));

  return monthList.map(getMonthName);
};

export const getTimeInMs = (val = ''): number => {
  if (val) {
    return new Date(val).getTime();
  }

  return new Date().getTime();
};

export const calculateNewDate = (addend1 = '', addend2: number): number => {
  if (addend1) {
    return getTimeInMs(addend1) + addend2;
  }

  return getTimeInMs() + addend2;
};

export const convertMinutesToMs = (value: number): number => value * 60000;
