import { differenceInYears, isValid, parse } from 'date-fns';
import { PhoneNumber } from '../../utils/phone-number';
import { countryCodeOptions } from '../shared/TelephoneInput/countryCodes';
import { useTranslations } from '../../hooks/useTranslationsHelper';

type ValidationMessage = {
  message: string;
  value?: number;
};

type LimitsRules = {
  min: ValidationMessage;
  max: ValidationMessage;
  maxLength: ValidationMessage;
};

type DepositLimits = {
  dailyDeposit: {
    rules: LimitsRules;
  };
  weeklyDeposit: {
    rules: LimitsRules;
  };
  monthlyDeposit: {
    rules: LimitsRules;
  };
};

type PasswordRequirements = {
  mustInclude: {
    MustIncludeCharacterLength?: {
      translation: string;
      validate: (password: string) => boolean;
    };
    MustIncludeLetter?: {
      translation: string;
      validate: (password: string) => boolean;
    };
    MustIncludeNumber?: {
      translation: string;
      validate: (password: string) => boolean;
    };
    MustIncludeSpecialCharacter?: {
      translation: string;
      validate: (password: string) => boolean;
    };
  };
  mustNotInclude: Record<string, string>;
};

type PasswordRules = {
  minLength: ValidationMessage;
  maxLength: ValidationMessage;
  pattern: {
    value: RegExp;
    message: string;
  };
  required: string;
};

type EmailRules = {
  minLength: ValidationMessage;
  maxLength: ValidationMessage;
  pattern: {
    value: RegExp;
    message: string;
  };
  required: string;
};

type UsernameRules = {
  minLength: ValidationMessage;
  maxLength: ValidationMessage;
  pattern: {
    value: RegExp;
    message: string;
  };
};

type DateOfBirthRules = {
  required: string;
  validate: {
    format: (dateVal: string) => string | undefined;
  };
};

type TelephoneValidateFunction = (telephone: string) => string | undefined;

type Config = {
  minimumAge: number;
  maximumAge?: number;
  postalCodePattern: RegExp;
  phoneNumberPattern: RegExp;
  usernamePattern: RegExp;
  emailPattern: RegExp;
  maxUsernameLength: number;
  minUsernameLength: number;
  addressLinePattern: RegExp;
  addressLinePatternFirstTwoInput: RegExp;
  placeOfBirthPattern: RegExp;
  lastnamePattern: RegExp;
  promoCodePattern: RegExp;
  name: {
    maxNameLength: number;
    minNameLength: number;
    namePattern: RegExp;
  };
  telephone: {
    validate: {
      pattern: TelephoneValidateFunction;
    };
  };
  limits: DepositLimits;
  password: {
    requirements: PasswordRequirements;
    rules: PasswordRules;
    customValidatePassword: (password: string) => Promise<{ isValid: boolean }>;
  };
  email: {
    rules: EmailRules;
    customValidate: (email: string, initialValue: unknown) => Promise<{ isValid: boolean; translationKey?: string }>;
  };
  username: {
    rules: UsernameRules;
  };
  dateOfBirth: {
    rules: DateOfBirthRules;
  };
};

export const minDepositLimit = 0.1;
const checkEmail = async (email: string): Promise<boolean> => Promise.resolve(false);
const maxDepositValidationLimit = 99999999.99;

const minimumAge = 18;
const maximumAge = 120;

export const useUserValidationConstraints = (): Config => {
  const { t } = useTranslations();

  return {
    minimumAge,
    maximumAge,
    postalCodePattern: /^[a-zA-Z0-9]+$/,
    phoneNumberPattern: /(^\+)?\s?(\({1}\d+\){1})?\s?(-?\.?\/?)?\2?[\d]+/g,
    usernamePattern: /^[\w\d.-]+$/,
    emailPattern:
      // eslint-disable-next-line no-control-regex
      /^[a-zA-Z0-9][a-zA-Z0-9.+_{|}~-]*@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/i,
    maxUsernameLength: 25,
    minUsernameLength: 1,
    addressLinePattern: /^[\w 0-9]+$/,
    addressLinePatternFirstTwoInput:
      // eslint-disable-next-line max-len
      /^(([a-zA-z0-9])(?!.*[!@#$%^&*()+={}[\\]|;:'"<.>\/?])(?!.*[-]{2})(?!.*[_]{2})(?!.*[\s]{2})(?!.*[,]{2})).*[a-zA-z0-9]+$/,
    placeOfBirthPattern: /^[\w]+$/,
    lastnamePattern: /^(?![\s\-']+$)[a-zA-Z\s\-']+$/,
    promoCodePattern: /^[a-zA-Z0-9]+$/,
    name: {
      maxNameLength: 100,
      minNameLength: 2,
      namePattern: /^[\w]+$/,
    },
    telephone: {
      validate: {
        pattern: (telephone: string): string | undefined => {
          const phone = new PhoneNumber(telephone, countryCodeOptions);

          if (!phone.performValidation() && phone.errorMessageText) {
            return phone.errorMessageText;
          }
        },
      },
    },
    limits: {
      dailyDeposit: {
        rules: {
          min: {
            message: t('validation.limit.higher-min'),
            value: minDepositLimit, // cant be lower than min deposit amount
          },
          max: {
            message: t('validation.limit.higher-default'),
            value: maxDepositValidationLimit,
          },
          maxLength: {
            message: t('validation.limit.higher-default'),
            value: 11,
          },
        },
      },
      weeklyDeposit: {
        rules: {
          min: {
            message: t('validation.limit.higher-min'),
            value: minDepositLimit, // cant be lower than min deposit amount
          },
          max: {
            message: t('validation.limit.higher-default'),
            value: maxDepositValidationLimit,
          },
          maxLength: {
            message: t('validation.limit.higher-default'),
            value: 11,
          },
        },
      },
      monthlyDeposit: {
        rules: {
          min: {
            message: t('validation.limit.higher-min'),
            value: minDepositLimit, // cant be lower than min deposit amount
          },
          max: {
            message: t('validation.limit.higher-default'),
            value: maxDepositValidationLimit,
          },
          maxLength: {
            message: t('validation.limit.higher-default'),
            value: 11,
          },
        },
      },
    },
    password: {
      requirements: {
        mustInclude: {
          MustIncludeCharacterLength: {
            translation: t('validation.password.min-characters'),
            validate: (password: string): boolean => /^.{8,50}$/.test(password),
          },
          MustIncludeLetter: {
            translation: t('validation.password.lower-uppercase'),
            validate: (password: string): boolean => /^(?=.*[A-Z])(?=.*[a-z]).*$/.test(password),
          },
          MustIncludeNumber: {
            translation: t('validation.password.contain-number'),
            validate: (password: string): boolean => /.*[0-9].*/.test(password),
          },
          MustIncludeSpecialCharacter: {
            translation: t('validation.password.contain-special'),
            validate: (password: string): boolean => /.*[!@#$%^*-.<>|?{}:&=~;_[\]]/.test(password),
          },
        },
        mustNotInclude: {},
      },
      rules: {
        minLength: {
          value: 8,
          message: t('validation.password.must-min-characters'),
        },
        maxLength: {
          value: 25,
          message: t('validation.password.less-symbols'),
        },
        pattern: {
          value: /^(?!.*[`\\/])(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^*-.<>|?{}:&=~;_[\]])(?!.*[ '])(?=.{8,25}$).*/,
          message: t('validation.password.password-requirements'),
        },
        required: t('validation.field-required'),
      },
      customValidatePassword(password: string): Promise<{ isValid: boolean }> {
        const isValid = password.length > 8;

        return Promise.resolve({ isValid });
      },
    },
    email: {
      rules: {
        minLength: {
          value: 6,
          message: t('validation.email.min-characters'),
        },
        maxLength: {
          value: 80,
          message: t('validation.email.less-symbols'),
        },
        pattern: {
          value:
            // eslint-disable-next-line no-control-regex
            /^[a-zA-Z0-9][a-zA-Z0-9.+_{|}~-]*@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,}){1,2}$/i,
          message: t('validation.email.invalid'),
        },
        required: t('validation.field-required'),
      },
      async customValidate(email: string, initialValue: unknown): Promise<{ isValid: boolean }> {
        if (email === initialValue) {
          return Promise.resolve({ isValid: true });
        }

        return checkEmail(email)
          .then(() => {
            return {
              // Consider to apply this key on demand
              isValid: false,
              translationKey: 'duplicate-email',
            };
          })
          .catch(() => {
            return { isValid: true };
          });
      },
    },
    username: {
      rules: {
        minLength: {
          value: 8,
          message: t('validation.user-name.min-characters'),
        },
        maxLength: {
          value: 50,
          message: t('validation.user-name.less-symbols'),
        },
        pattern: {
          value: /^[a-zA-ZÀ-ÖØ-öø-ÿ0-9._-]+$/,
          message: t('validation.user-name.match-pattern'),
        },
      },
    },
    dateOfBirth: {
      rules: {
        required: t('validation.field-required'),
        validate: {
          format: (dateVal: string): string | undefined => {
            const parsedDate = parse(dateVal, 'dd/MM/yyyy', new Date());

            if (!isValid(parsedDate)) {
              return t('validation.dof.invalid');
            }

            const diffAge = differenceInYears(new Date(), parsedDate);

            if (minimumAge > diffAge) {
              return t('validation.dof.min-age', { minimumAge });
            } else if (maximumAge && diffAge > maximumAge) {
              return t('validation.dof.invalid');
            } else {
              return undefined;
            }
          },
        },
      },
    },
  };
};

// export const countryCodeOptions: SelectOption[] = ['DE', 'GB'].map((countryCode) => {
//   // eslint-disable-next-line
//   const { phone } = (countries as any)[countryCode];

//   return {
//     label: `+${phone}`,
//     value: `+${phone}`,
//   };
// });
