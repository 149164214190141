import React, { FunctionComponent } from 'react';
import './FormControlSpacing.scss';

/**
 * A component used to wrap form control components
 * (e.g. Select, Input, Checkbox) and apply a consistent margin to them.
 * Useful for creating gaps between controls within a form.
 */
export const FormControlSpacing: FunctionComponent<{
  children?: React.ReactNode;
}> = ({ children }) => <div className="form-control-spacing">{children}</div>;
