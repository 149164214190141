import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WalletUpdatedSubscriptionVariables = Types.Exact<{
  userProfileId: Types.Scalars['String']['input'];
}>;


export type WalletUpdatedSubscription = { __typename?: 'Subscription', walletUpdated?: { __typename?: 'WalletUpdated', currencyCode?: string | null, type?: string | null, walletId?: string | null, current?: { __typename?: 'WalletUpdatedSnapshot', balanceAmount?: number | null } | null } | null };


export const WalletUpdatedDocument = gql`
    subscription WalletUpdated($userProfileId: String!) {
  walletUpdated(userProfileId: $userProfileId) {
    currencyCode
    current {
      balanceAmount
    }
    type
    walletId
  }
}
    `;

/**
 * __useWalletUpdatedSubscription__
 *
 * To run a query within a React component, call `useWalletUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWalletUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWalletUpdatedSubscription({
 *   variables: {
 *      userProfileId: // value for 'userProfileId'
 *   },
 * });
 */
export function useWalletUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<WalletUpdatedSubscription, WalletUpdatedSubscriptionVariables> & ({ variables: WalletUpdatedSubscriptionVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<WalletUpdatedSubscription, WalletUpdatedSubscriptionVariables>(WalletUpdatedDocument, options);
      }
export type WalletUpdatedSubscriptionHookResult = ReturnType<typeof useWalletUpdatedSubscription>;
export type WalletUpdatedSubscriptionResult = Apollo.SubscriptionResult<WalletUpdatedSubscription>;