import { PasswordRequirementType, ValidationRequirements } from '../../components/Registration/types';
import { joinStrings } from '../string';

export type ValidationMessage = {
  message: string;
  id: string;
};

export type PasswordValidationState = Partial<Record<PasswordRequirementType, boolean>>;

/**
 * Take an optional list of validation messages and join the ids to produces a space delimited ID Reference list.
 * For usage in aria attributes such as `aria-describedby`.  Defaults to otherValidationIDs.
 * @param validationMessages Validation messages with IDs
 * @param otherValidationIDs Other id ref list which will be joined to result.
 */
export const joinValidationMessageIDs = (
  validationMessages?: ValidationMessage[],
  otherValidationIDs?: string
): string | undefined =>
  validationMessages && validationMessages.length > 0
    ? joinStrings([...validationMessages.map((m) => m.id), otherValidationIDs], ' ')
    : otherValidationIDs;
/**
 * Takes password requirements object and runs each of the validation functions,
 * and returns a key value list of whether each requirement passed validation or not.
 * @param requirements Password requirements object.
 * @param password password string.
 */
export const validatePasswordRequirements = (
  requirements: ValidationRequirements,
  password: string,
  blacklistedPasswords: string[] = []
): PasswordValidationState => {
  const combinedRequirements = {
    ...requirements?.mustInclude,
    ...requirements?.mustNotInclude,
  };
  const requirementsValidationState = {};

  Object.keys(combinedRequirements).forEach((key: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    requirementsValidationState[key] = combinedRequirements[key].validate(password, blacklistedPasswords);
  });

  return requirementsValidationState;
};

export const capitalizeFirstLetter = (str: string): string => {
  if (!str.length) {
    return str;
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
};
