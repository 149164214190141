import React, { ComponentPropsWithoutRef, Fragment, FunctionComponent } from 'react';
import { Icon } from '../Icon';
import { joinStrings } from '../../../utils/string';
import './Notification.scss';

export type NotificationType = 'error' | 'warning' | 'info' | 'success';
export type NotificationProps = {
  /**
   * Required. Notification state type
   */
  type: NotificationType;
  /**
   * Optional, defaults to false. If true, a box-shadow will be added to the notification.
   */
  hasBoxShadow?: boolean;
} & ComponentPropsWithoutRef<'div'>;

const getNotificationIcon = (type: NotificationType): JSX.Element => {
  switch (type) {
    case 'success':
      return <Icon variant="Success" />;
    case 'error':
      return <Icon variant="Error" />;
    case 'warning':
      return <Icon variant="Warning" />;
    default:
      return <Icon variant="Info" />;
  }
};

/**
 * A simple presentational notification component
 *
 * ## Example
 *
 * ```tsx
 * <Notification type="info">Your balance has been updated.</Notification>
 * ```
 */
export const Notification: FunctionComponent<NotificationProps> = ({
  type,
  hasBoxShadow = false,
  children,
  className,
  ...props
}) => (
  <Fragment>
    {children && (
      <div
        role="alert"
        {...props}
        className={joinStrings([
          'notification',
          'notification--' + type,
          hasBoxShadow && `notification--with-box-shadow`,
          className,
        ])}
      >
        <div className="notification__icon">{getNotificationIcon(type)}</div>
        <h4 className="notification__message">{children}</h4>
      </div>
    )}
  </Fragment>
);
