import React, { FC, useRef, useState } from 'react';
import noop from 'lodash/noop';
import { Game } from '../../Games/games.model';
import { PaginatedResponse } from '../../../models/api.model';
import './PromotionGameTags.scss';

interface PromotionGameTagsProps {
  games: PaginatedResponse<Game>;
  setGameName: (value: string) => void;
  setStateLaunchGameButton: (value: boolean) => void;
}

export const PromotionGameTags: FC<PromotionGameTagsProps> = ({
  games,
  setGameName = noop,
  setStateLaunchGameButton = noop,
}) => {
  const refElement = useRef<HTMLUListElement>(null);

  const [isActiveTile, setActiveTile] = useState<boolean>(false);
  const [tileIndex, setTileIndex] = useState<number | null>(null);

  const pickGame = (idx: number, gameName: string): void => {
    const HTMLCollection = (refElement.current && refElement.current.children) || [];

    const tilesList = Array.from(HTMLCollection);

    // Saving position (index) for a tile. If 2 clicks are made on one tile - turn off the tile on demand.
    setTileIndex(idx);
    setStateLaunchGameButton(false);

    // eslint-disable-next-line array-callback-return
    tilesList.map((tile: Element, index: number) => {
      // Setting active overlay for active | inactive tiles
      if (index !== idx) {
        tile.classList.add('active-overlay');
        setActiveTile(true);
      } else {
        tile.scrollIntoView(false);
        tile.classList.remove('active-overlay');
      }

      if (isActiveTile) {
        tile.classList.remove('active-overlay');
        setActiveTile(false);
        setStateLaunchGameButton(false);

        // Switch between tiles (from active to inactive)
        if (index !== idx) {
          tile.classList.add('active-overlay');
        }
      }

      // Turn off the active tile with using tileIndex and activate the entire list
      if (idx === tileIndex) {
        tile.classList.remove('active-overlay');
        setTileIndex(null);
        setStateLaunchGameButton(true);
      }
    });

    setGameName(gameName);
  };

  return (
    <>
      <div className="promotion-game-tags">
        <ul className="promotion-game-tags__list" ref={refElement}>
          {games &&
            games.items.map((game: Game, index: number) => {
              const { gameId, gameImage, friendlyUrlName, displayName } = game;

              return (
                <li
                  key={gameId}
                  className="promotion-game-tags__tile"
                  onClick={(): void => pickGame(index, friendlyUrlName)}
                  role="treeitem"
                  tabIndex={0}
                  aria-selected={true}
                  aria-expanded={false}
                >
                  <div>
                    <img className="promotion-game-tags__tile-image" src={gameImage.imgUrl} alt={displayName} />
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
    </>
  );
};
