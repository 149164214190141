import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RegisterUserMutationVariables = Types.Exact<{
  model: Types.Scalars['JSON']['input'];
  registerUserXTenantId2: Types.Scalars['String']['input'];
}>;


export type RegisterUserMutation = { __typename?: 'Mutation', registerUser?: { __typename?: 'RegisterResponse', data: boolean, registrationData?: { __typename?: 'RegistrationResponse', userProfileId?: string | null } | null, error?: Array<{ __typename?: 'FieldError', code: string, description?: string | null, fieldName: string, kind: Array<string | null>, pattern?: string | null } | { __typename?: 'GenericError', code: string }> | null } | null };


export const RegisterUserDocument = gql`
    mutation RegisterUser($model: JSON!, $registerUserXTenantId2: String!) {
  registerUser(model: $model, xTenantID: $registerUserXTenantId2) {
    registrationData {
      userProfileId
    }
    data
    error {
      ... on GenericError {
        code
      }
      ... on FieldError {
        code
        description
        fieldName
        kind
        pattern
      }
    }
  }
}
    `;
export type RegisterUserMutationFn = Apollo.MutationFunction<RegisterUserMutation, RegisterUserMutationVariables>;

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      model: // value for 'model'
 *      registerUserXTenantId2: // value for 'registerUserXTenantId2'
 *   },
 * });
 */
export function useRegisterUserMutation(baseOptions?: Apollo.MutationHookOptions<RegisterUserMutation, RegisterUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterUserMutation, RegisterUserMutationVariables>(RegisterUserDocument, options);
      }
export type RegisterUserMutationHookResult = ReturnType<typeof useRegisterUserMutation>;
export type RegisterUserMutationResult = Apollo.MutationResult<RegisterUserMutation>;
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<RegisterUserMutation, RegisterUserMutationVariables>;