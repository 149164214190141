import { RegistrationErrorStep } from '../../components/Registration/steps/ErrorStep';
import { RegistrationIntroductionStep } from '../../components/Registration/steps/IntroductionStep';
import { RegistrationStepConfig } from '../../components/Registration/types';
import { useTranslations } from '../useTranslationsHelper';

export const useRegistrationOuterSteps = (): RegistrationStepConfig[] => {
  const { t } = useTranslations();

  return [
    {
      id: 'error',
      title: t('errors.general'),
      shortTitle: t('errors.general'),
      StepComponent: RegistrationErrorStep,
      fieldSets: [],
    },
    {
      id: 'success',
      title: t('register.success.title'),
      shortTitle: t('register.success.title'),
      StepComponent: RegistrationIntroductionStep,
      fieldSets: [],
    },
  ];
};
