import { GridColumnAmount } from './Grid';

type GridItemColumnConfig = {
  startCol: GridColumnAmount;
  endCol: GridColumnAmount;
};

/**
 * Helper utility to calculate grid values for a list of GridItems.
 *
 * Converts a list of colSpans into GridItemColumnConfig.
 *
 * May make sense at some point to refactor and adjust component figure this out using context or something similar.
 * @param colSpans List of column span values to be converted into startCol & endCol values.
 */
export const calculateGridItemConfigurations = (colSpans: GridColumnAmount[]): GridItemColumnConfig[] =>
  colSpans.reduce(
    (acc, colSpan) => {
      const endCol = (acc.startColumn + colSpan - 1) as GridColumnAmount;

      acc.columnSpans.push({
        startCol: acc.startColumn,
        endCol,
      });
      acc.startColumn = (endCol === 12 ? 1 : endCol + 1) as GridColumnAmount;

      return acc;
    },
    { startColumn: 1, columnSpans: [] } as {
      startColumn: GridColumnAmount;
      columnSpans: GridItemColumnConfig[];
    }
  ).columnSpans;
