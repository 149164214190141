import React, { FunctionComponent, Suspense } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { errorsToErrorSummary } from '../../../../utils/form';
import { PeriodUnit, periodToDays } from '../../../../utils/time';
import { InputValidationAlerts } from '../../../shared/InputValidationAlert';
import { Paragraph } from '../../../shared/Paragraph';
import { Select, SelectOption } from '../../../shared/Select';
import { ToggleButton } from '../../../shared/ToggleButton';
import { selfExclusionFormID } from '../consts';
import { getDurationLabelByValue } from '../helper';
import { getSelfExclusion } from '../mocks';
import './styles';
import { useTranslations } from '../../../../hooks/useTranslationsHelper';

export type SelfExclusionFormData = {
  duration: string;
  reason: string;
};

export type SelfExcludeData = SelfExclusionFormData & { durationLabel: string };

export type SelfExclusionFormProps = {
  /**
   * Callback that's executed when the user submits the form
   */
  onFormSubmit: (data: SelfExcludeData) => void;
  /**
   * Initial form values
   */
  defaultValues: SelfExclusionFormData;
};

export const SelfExclusionForm: FunctionComponent<SelfExclusionFormProps> = ({ onFormSubmit, defaultValues }) => {
  const { t } = useTranslations();
  const { control, watch, formState, handleSubmit } = useForm<SelfExclusionFormData>({
    defaultValues,
  });

  const breakDurationValue = watch('duration');

  const durationOptions: SelectOption[] = getSelfExclusion(t).selfExclusionPeriod.options.map(
    ({ label, value, unit }) => ({
      label,
      value:
        value === 'indefinite' || value === 'indefinitely' ? 'indefinitely' : periodToDays(+value, unit as PeriodUnit),
    })
  );

  async function onSubmit(e: React.FormEvent<HTMLFormElement>): Promise<void> {
    e.preventDefault();

    if (!formState.isSubmitting) {
      return handleSubmit((formData) => {
        onFormSubmit({
          ...formData,
          durationLabel: getDurationLabelByValue(formData.duration, durationOptions),
        });
      })(e);
    }
  }

  const errorSummary = errorsToErrorSummary(formState.errors, {}, 'self-exclusion-form');

  return (
    <form id={selfExclusionFormID} onSubmit={onSubmit} className="self-exclusion-form">
      <Controller
        control={control}
        name="duration"
        rules={{
          required: {
            value: true,
            message: t('validation.required'),
          },
        }}
        render={({ field, ...renderProps }): JSX.Element => (
          <div body-scroll-lock-ignore="true" className="self-exclusion-form__duration-options">
            {durationOptions.map((option) => (
              <ToggleButton
                {...renderProps}
                selected={breakDurationValue === option.value}
                value={option.value}
                onClick={(): void => field.onChange(option.value)}
                key={option.value}
                className="self-exclusion-form__duration"
                variant="secondary-alt"
              >
                {option.label}
              </ToggleButton>
            ))}
          </div>
        )}
      />
      <InputValidationAlerts
        messages={errorSummary.duration?.messages}
        state={errorSummary.duration?.messages?.length ? 'error' : undefined}
      />
      <>
        <Paragraph className="self-exclusion-form__reason-label" size="sm">
          {t('self-exclusion.reasons.title')}
        </Paragraph>

        <Suspense>
          <Controller
            control={control}
            name="reason"
            rules={{
              required: {
                value: getSelfExclusion(t).selfExclusionReason.required,
                message: t('validation.required'),
              },
            }}
            render={({ field, ...props }): JSX.Element => (
              <Select
                {...props}
                {...field}
                placement="top"
                label={t('self-exclusion.options.title')}
                theme="dark"
                options={getSelfExclusion(t).selfExclusionReason.options}
                validationMessages={errorSummary.reason?.messages}
                validationState={errorSummary.reason?.messages?.length ? 'error' : undefined}
              />
            )}
          />
        </Suspense>
      </>
    </form>
  );
};
