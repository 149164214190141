import { formatDate } from 'date-fns';
import React, { FunctionComponent } from 'react';
import { useTime } from '../../../../hooks/useTime';

/**
 * Renders formatted current time.
 */
export const Clock: FunctionComponent<{ format?: string }> = ({ format = 'HH:mm	a' }) => {
  const { currentTime } = useTime();

  return <span data-testid="clock">{formatDate(currentTime, format)}</span>;
};
