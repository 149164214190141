import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserBudgetLimitsQueryVariables = Types.Exact<{
  xTenantId: Types.Scalars['ID']['input'];
  userProfileId: Types.Scalars['ID']['input'];
}>;


export type UserBudgetLimitsQuery = { __typename?: 'Query', userBudgetLimits?: Array<{ __typename?: 'BudgetLimitsTransactionResponse', activationStatus?: Types.ActivationStatus | null, amount?: number | null, changeCooldown?: string | null, createdBy?: string | null, createdOn?: string | null, creator?: string | null, currencyCode?: string | null, externalId?: string | null, id?: string | null, isCancellable?: boolean | null, isPendingCancellation?: boolean | null, lastUpdatedOn?: string | null, mediatorType?: string | null, pendingChangeExpectedOn?: string | null, period?: string | null, type?: string | null, userProfileId?: string | null } | null> | null };


export const UserBudgetLimitsDocument = gql`
    query UserBudgetLimits($xTenantId: ID!, $userProfileId: ID!) {
  userBudgetLimits(xTenantID: $xTenantId, userProfileId: $userProfileId) {
    activationStatus
    amount
    changeCooldown
    createdBy
    createdOn
    creator
    currencyCode
    externalId
    id
    isCancellable
    isPendingCancellation
    lastUpdatedOn
    mediatorType
    pendingChangeExpectedOn
    period
    type
    userProfileId
  }
}
    `;

/**
 * __useUserBudgetLimitsQuery__
 *
 * To run a query within a React component, call `useUserBudgetLimitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserBudgetLimitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserBudgetLimitsQuery({
 *   variables: {
 *      xTenantId: // value for 'xTenantId'
 *      userProfileId: // value for 'userProfileId'
 *   },
 * });
 */
export function useUserBudgetLimitsQuery(baseOptions: Apollo.QueryHookOptions<UserBudgetLimitsQuery, UserBudgetLimitsQueryVariables> & ({ variables: UserBudgetLimitsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserBudgetLimitsQuery, UserBudgetLimitsQueryVariables>(UserBudgetLimitsDocument, options);
      }
export function useUserBudgetLimitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserBudgetLimitsQuery, UserBudgetLimitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserBudgetLimitsQuery, UserBudgetLimitsQueryVariables>(UserBudgetLimitsDocument, options);
        }
export function useUserBudgetLimitsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserBudgetLimitsQuery, UserBudgetLimitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserBudgetLimitsQuery, UserBudgetLimitsQueryVariables>(UserBudgetLimitsDocument, options);
        }
export type UserBudgetLimitsQueryHookResult = ReturnType<typeof useUserBudgetLimitsQuery>;
export type UserBudgetLimitsLazyQueryHookResult = ReturnType<typeof useUserBudgetLimitsLazyQuery>;
export type UserBudgetLimitsSuspenseQueryHookResult = ReturnType<typeof useUserBudgetLimitsSuspenseQuery>;
export type UserBudgetLimitsQueryResult = Apollo.QueryResult<UserBudgetLimitsQuery, UserBudgetLimitsQueryVariables>;