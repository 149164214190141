import React, { FunctionComponent, useEffect, useState } from 'react';
import { BonusConfigurationProps, WelcomeBonusContent } from './WelcomeBonusContent';
import noop from 'lodash/noop';
import { Button } from '../../shared/Button';
import { joinStrings } from '../../../utils/string';
import { PopUpHandler } from '../../shared/PopupHandler';
import { CancelBonusConfirmationPrompt } from './CancelBonusConfirmationPrompt';
import { welcomeOfferItem } from './mocks/welcomeBonusMock';
import { WelcomeOfferItem } from './types';

type WelcomeBonusJourneyProps = {
  imgAlt?: string;
  imgPath?: string;
  isShown?: boolean;
  promoCode?: string;
  onClose?: () => void;
  isDepositJourney?: boolean;
  termsAndConditions?: string;
} & BonusConfigurationProps;

export const WelcomeBonusJourney: FunctionComponent<WelcomeBonusJourneyProps> = ({
  isShown,
  onClose = noop,
  imgAlt,
  promoCode,
  imgPath,
  configuration,
  termsAndConditions,
}) => {
  const dynamicWelcomeBonus: WelcomeOfferItem = welcomeOfferItem;

  const currentPromoCode = promoCode || dynamicWelcomeBonus.promoCode;

  const isVariableWelcomeBonus: boolean | undefined = Boolean(dynamicWelcomeBonus?.depositBonus?.bonusPercentage);

  const isOpen = true;
  const isOpenConfirmationModalStorage = true;
  const [isWelcomeBonusMainMode, setIsWelcomeBonusMainMode] = useState(false);

  useEffect(() => {
    setIsWelcomeBonusMainMode(Boolean(isOpenConfirmationModalStorage));
    // TODO: Review whether this property is needed
    console.log('Review isVariableWelcomeBonus: ', isVariableWelcomeBonus);
  }, [isOpenConfirmationModalStorage, isVariableWelcomeBonus]);

  const showBonusPrompt = isShown || isOpen;

  const onModalClose = (): void => {
    // TODO: Open Welcome Bonus Calculator and run Deposit UI Cashier process
    onClose();
  };

  const className = 'welcome-bonus';

  const drawerProps = {
    className: joinStrings([className]),
  };

  const [depositAmount, setDepositAmount] = useState<string>('');
  const [inputError, setInputError] = useState('');

  const isAcceptBtnDisabled = +depositAmount <= 0 || !!inputError || depositAmount === '.';

  const onOpenConfirmationModal = (): void => {
    // TODO: Open Welcome Bonus Calculator and set confirmation prompt
    onClose();
  };

  const onDeclineClick = (): void => {
    // Run DL check and close the prompt
    onModalClose();
  };

  const onAcceptClick = (): void => {
    // TODO: Set pre-deposit data and handle Deposit activation
    onModalClose();
  };

  const modalProps = {
    'aria-label': 'welcome-bonus-modal-aria-label',
    style: { overlay: { zIndex: 12 } },
    className: joinStrings([className]),
  };

  return (
    <>
      {showBonusPrompt && (
        <PopUpHandler
          isOpen={showBonusPrompt}
          onClose={onModalClose}
          modalProps={modalProps}
          drawerProps={drawerProps}
          header={dynamicWelcomeBonus?.name}
          headingProps={{ headingLevel: 2, styleHeadingAs: 5 }}
          footer={
            <div className="welcome-bonus__buttons">
              <Button variant="secondary" size="large" type="button" onClick={onOpenConfirmationModal}>
                DECLINE BONUS
              </Button>
              <Button
                variant="primary"
                size="large"
                type="submit"
                disabled={isAcceptBtnDisabled}
                onClick={onAcceptClick}
              >
                APPLY BONUS
              </Button>
            </div>
          }
        >
          <WelcomeBonusContent
            imgAlt={imgAlt}
            imgPath={imgPath}
            inputError={inputError}
            configuration={configuration}
            depositAmount={depositAmount}
            setInputError={setInputError}
            setDepositAmount={setDepositAmount}
            promoCode={currentPromoCode}
            termsAndConditions={termsAndConditions || dynamicWelcomeBonus.terms || ''}
          />
        </PopUpHandler>
      )}
      {isOpenConfirmationModalStorage && (
        <CancelBonusConfirmationPrompt
          isOpen={isWelcomeBonusMainMode}
          setIsOpen={setIsWelcomeBonusMainMode}
          onDeclineClick={onDeclineClick}
          promoCode={currentPromoCode}
        />
      )}
    </>
  );
};
