import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { FunctionComponent } from 'react';
import { apiLinks } from './links';
import { useAuth } from 'react-oidc-context';
import { Spinner } from '../components/shared/Spinner';

if (process.env.REACT_APP_BASE_API_URL === undefined) {
  throw new Error('API URL required');
}

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: apiLinks,
  connectToDevTools: true,
  credentials: 'include',
});

interface Props {
  children: JSX.Element;
}

export const ManagedApolloProvider: FunctionComponent<Props> = ({ children }) => {
  const auth = useAuth();

  if (auth.isLoading && !auth.isAuthenticated) {
    return (
      <div className="spinner-wrapper">
        <Spinner />
      </div>
    );
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
