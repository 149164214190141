import { configureStore } from '@reduxjs/toolkit';
import appReducer from './app/reducers';
import gameReducer from './game/reducers';
import promotionsReducer from './promotions/reducers';
import cashierReducer from './cashier/reducers';

export const store = configureStore({
  reducer: {
    app: appReducer,
    game: gameReducer,
    promotions: promotionsReducer,
    cashier: cashierReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
