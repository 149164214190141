import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BonusesGetCategoriesQueryVariables = Types.Exact<{
  language: Types.Scalars['String']['input'];
}>;


export type BonusesGetCategoriesQuery = { __typename?: 'Query', bonus_collections: Array<{ __typename?: 'bonus_collections', translations?: Array<{ __typename?: 'bonus_collections_translations', display_name?: string | null } | null> | null }> };


export const BonusesGetCategoriesDocument = gql`
    query BonusesGetCategories($language: String!) {
  bonus_collections(
    filter: {status: {_eq: "published"}, translations: {languages_language_code: {language_code: {_eq: $language}}}}
  ) {
    translations(
      filter: {languages_language_code: {language_code: {_eq: $language}}}
    ) {
      display_name
    }
  }
}
    `;

/**
 * __useBonusesGetCategoriesQuery__
 *
 * To run a query within a React component, call `useBonusesGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBonusesGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBonusesGetCategoriesQuery({
 *   variables: {
 *      language: // value for 'language'
 *   },
 * });
 */
export function useBonusesGetCategoriesQuery(baseOptions: Apollo.QueryHookOptions<BonusesGetCategoriesQuery, BonusesGetCategoriesQueryVariables> & ({ variables: BonusesGetCategoriesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BonusesGetCategoriesQuery, BonusesGetCategoriesQueryVariables>(BonusesGetCategoriesDocument, options);
      }
export function useBonusesGetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BonusesGetCategoriesQuery, BonusesGetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BonusesGetCategoriesQuery, BonusesGetCategoriesQueryVariables>(BonusesGetCategoriesDocument, options);
        }
export function useBonusesGetCategoriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BonusesGetCategoriesQuery, BonusesGetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BonusesGetCategoriesQuery, BonusesGetCategoriesQueryVariables>(BonusesGetCategoriesDocument, options);
        }
export type BonusesGetCategoriesQueryHookResult = ReturnType<typeof useBonusesGetCategoriesQuery>;
export type BonusesGetCategoriesLazyQueryHookResult = ReturnType<typeof useBonusesGetCategoriesLazyQuery>;
export type BonusesGetCategoriesSuspenseQueryHookResult = ReturnType<typeof useBonusesGetCategoriesSuspenseQuery>;
export type BonusesGetCategoriesQueryResult = Apollo.QueryResult<BonusesGetCategoriesQuery, BonusesGetCategoriesQueryVariables>;