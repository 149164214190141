import React, { FunctionComponent } from 'react';
import { joinStrings } from '../../../utils/string';
import './ActionPanel.scss';

export type ActionPanelProps = {
  /**
   * Will set the button panel fixed to bottom in mobile
   */
  stickyMobile?: boolean;
  /**
   * Display buttons inline
   */
  inline?: boolean;

  align?: 'right' | 'center' | 'space-between';
  children?: React.ReactNode;
};

/**
 * Presentational button container.  To be used for consistency for key action buttons.
 */
export const ActionPanel: FunctionComponent<ActionPanelProps> = ({
  stickyMobile = false,
  inline = false,
  align,
  children,
}) => {
  return (
    <div
      data-testid="action-panel"
      className={joinStrings([
        'action-panel',
        stickyMobile && 'action-panel--sticky-actions',
        inline ? 'action-panel--row' : 'action-panel--column',
        align && `action-panel--${align}`,
      ])}
    >
      {children}
    </div>
  );
};
