import { useEffect } from 'react';
import throttle from 'lodash/throttle';
import { passiveOption } from '../../consts';

export const useDocumentScrollThrottled = (callbackData: (currentScrollTop: number) => void): void => {
  function handleDocumentScroll(): void {
    const { scrollTop: currentScrollTop } = document.documentElement || document.body;

    callbackData(currentScrollTop);
  }

  const handleDocumentScrollThrottled = throttle(handleDocumentScroll, 250);

  useEffect(() => {
    window.addEventListener('scroll', handleDocumentScrollThrottled, passiveOption);

    return () =>
      window.removeEventListener('scroll', handleDocumentScrollThrottled, passiveOption as EventListenerOptions);
  }, [handleDocumentScrollThrottled]);
};
