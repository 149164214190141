import omit from 'lodash/omit';
import React, { FocusEvent, Suspense, useContext, useEffect, useRef } from 'react';
import { Controller, Mode, UnpackNestedValue, useFormContext as useHookFormContext } from 'react-hook-form';
import { FormField } from '.';
import { Checkbox, CheckboxProps } from '../Checkbox';
import { FormControlSwitch } from '../FormControlSwitch';
import { Input, InputProps } from '../Input';
import { MaskedInput } from '../MaskedInput';
import { PasswordInput, PasswordInputProps } from '../PasswordInput';
import { RadioGroup } from '../RadioGroup';
import { FormContext } from './context';
import { FormControlComponentProps, FormControlInputGetterArgs, FormFieldType, PasswordInputFormField } from './types';
import { useUserValidationConstraints } from '../../Registration/const';
import { validatePasswordRequirements } from '../../../utils/form';
import { Select } from '../Select';
import { TelephoneWithVerification } from '../TelephoneInput/TelephoneWithVerification/TelephoneWithVerification';
import './Form.scss';
import { CurrencyInput } from '../CurrencyInput';
import { ValidationState } from '../../../types';

/**
 * Wrap the checkbox component so that value/onChange can be mapped for hook form.
 */
const BooleanCheckbox = ({
  onChange,
  value,
  ...props
}: {
  value?: boolean;
  onChange?: (b: boolean) => void;
} & Omit<CheckboxProps, 'value' | 'onChange'>): JSX.Element => {
  const { trigger } = useHookFormContext();

  return (
    <Checkbox
      {...props}
      checked={value}
      onChange={(e): void => {
        onChange?.(e.target.checked);
        trigger(props.name);
      }}
    />
  );
};

// TODO: email field should not be hardcoded here. add app config values to pass fields down.
const PasswordWithStrength = <D extends Record<string, unknown>>({
  value,
  field,
  onBlurValue,
  validateRequirements,
  formValidationMode,
  ...props
}: PasswordInputProps & {
  field: PasswordInputFormField<D>;
  validateRequirements?: 'onBlur' | 'onChange';
  onBlurValue: string;
  formValidationMode?: Mode;
}): JSX.Element => {
  const userValidationConstraints = useUserValidationConstraints();
  const validationValue = validateRequirements === 'onBlur' ? onBlurValue : value;

  const email = useHookFormContext().getValues('email');
  const trigger = useHookFormContext().trigger;

  useEffect(() => {
    if (
      props.showRequirements &&
      validateRequirements === 'onChange' &&
      formValidationMode &&
      formValidationMode !== 'onChange' &&
      formValidationMode !== 'all'
    ) {
      trigger(field.name as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <PasswordInput
      {...props}
      showStrengthIndicator={props.showStrengthIndicator && !!value}
      id={field.id}
      value={value}
      strength={value && field.scorePassword ? field.scorePassword(value) : undefined}
      requirementsValidationState={validatePasswordRequirements(
        field.customRequirements || userValidationConstraints.password.requirements,
        validationValue || '',
        email ? [email] : []
      )}
    />
  );
};

/**
 * Dynamically get an input component based upon provided field config.
 * @param field
 * @param successStateEnabled
 * @param translate
 * @param errorSummary
 * @param controllerProps
 */
function getFormControlInput<D extends Record<string, unknown>>(
  { field, successStateEnabled, errorSummary, formValidationMode }: FormControlInputGetterArgs<D>,
  controllerProps: Partial<FormField<D>['props']>
): JSX.Element {
  const validationMessages = errorSummary && errorSummary[field.name as string]?.messages;

  const label = typeof field.label === 'string' ? field.label + (field.optional ? 'optional label' : '') : field.label;
  const validationState: ValidationState | undefined = validationMessages
    ? 'error'
    : successStateEnabled && !field.disabled
      ? 'success'
      : undefined;

  const validation = {
    validationMessages,
    validationState,
  };
  const placeholder = field.placeholder ? field.placeholder : undefined;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const hookFormContext = useHookFormContext();

  switch (field.type) {
    // TODO: Perhaps needs to be a separate component.  Left until later whilst waiting for clearer requirements.
    case FormFieldType.DateOfBirthInput:
      return (
        <MaskedInput
          label={label}
          mask={field.dateMask}
          maskPlaceholder={field.placeholder}
          disabled={field.disabled}
          {...validation}
          {...field.props}
          {...(controllerProps as typeof field.props)}
        />
      );

    case FormFieldType.CurrencyInput:
      return (
        <CurrencyInput
          {...omit(field.props, ['onChange'])}
          {...(controllerProps as Omit<typeof field.props, 'onChange'>)}
          {...validation}
          id={field.id}
          disabled={field.disabled}
          placeholder={placeholder}
          label={label}
        />
      );

    case FormFieldType.Checkbox:
      return (
        <BooleanCheckbox
          {...omit(field.props, ['onChange', 'value'])}
          {...(controllerProps as Omit<typeof field.props, 'onChange' | 'value'>)}
          label={label}
          disabled={field.disabled}
          {...validation}
        />
      );

    case FormFieldType.TelephoneInput:
      return (
        <TelephoneWithVerification
          {...field.props}
          {...(controllerProps as typeof field.props)}
          maskedInputProps={{
            ...field.props.maskedInputProps,
            ...validation,
            label,
          }}
          selectProps={{
            ...field.props.selectProps,
            label: field.props.selectProps.label,
          }}
        />
      );

    case FormFieldType.Switch:
      return (
        <BooleanCheckbox
          {...omit(field.props, ['onChange', 'value'])}
          {...(controllerProps as Omit<typeof field.props, 'onChange' | 'value'>)}
          label={label}
          disabled={field.disabled}
          {...validation}
          variant="switch"
        />
      );

    case FormFieldType.MaskedInput:
      return (
        <MaskedInput
          {...field.props}
          {...(controllerProps as typeof field.props)}
          placeholder={placeholder}
          label={label}
          disabled={field.disabled}
          mask={field.mask}
        />
      );

    case FormFieldType.TextInput:
      return (
        <Input
          {...field.props}
          {...(controllerProps as typeof field.props)}
          {...validation}
          id={field.id}
          disabled={field.disabled}
          placeholder={placeholder}
          label={label}
        />
      );

    case FormFieldType.NoWhitespaceInput:
      return (
        <Input
          {...field.props}
          {...(controllerProps as typeof field.props)}
          {...validation}
          id={field.id}
          disabled={field.disabled}
          placeholder={placeholder}
          label={label}
          onChange={(e): void => {
            // Remove whitespace from the input value, update the event with the modified value, and continue the onChange call stack

            e.target.value = e.target.value.replace(/\s+/g, '');
            (controllerProps as typeof field.props)?.onChange?.(e);
            field.props?.onChange?.(e);
          }}
        />
      );

    case FormFieldType.NumberInput:
      return (
        <Input
          {...field.props}
          {...(controllerProps as typeof field.props)}
          {...validation}
          id={field.id}
          disabled={field.disabled}
          placeholder={placeholder}
          type="number"
          label={label}
        />
      );

    case FormFieldType.Select:
      return (
        <Suspense>
          <Select
            {...field.props}
            {...(controllerProps as typeof field.props)}
            {...validation}
            id={field.id}
            disabled={field.disabled}
            placeholder={placeholder}
            options={field.options}
            defaultValue={field?.defaultValue}
            label={label}
          />
        </Suspense>
      );

    case FormFieldType.PasswordInput:
      return (
        <PasswordWithStrength
          {...field.props}
          {...(controllerProps as typeof field.props)}
          {...validation}
          field={field}
          disabled={field.disabled}
          placeholder={placeholder}
          label={label}
          onBlurValue={hookFormContext.getValues(field.name.toString()) || ''}
          validateRequirements="onBlur"
          formValidationMode={formValidationMode}
        />
      );

    case FormFieldType.RadioGroup:
      return (
        <RadioGroup
          {...field.props}
          {...(controllerProps as typeof field.props)}
          id={field.id}
          disabled={field.disabled}
          label={label}
          options={field.options}
        />
      );
  }
}

/**
 * Dynamically get an input which is wrapped with react-hook-form Controller.
 *
 * Note that storybook has a few issues with the form props being a union type so doesn't quite behave correctly.
 */
export function FormControl<D extends Record<string, unknown>>(props: FormControlComponentProps<D>): JSX.Element {
  const formContext = useContext(FormContext);
  const formControlBlurredRef = useRef<boolean>();
  const hookFormContext = useHookFormContext();
  const userValidationConstraints = useUserValidationConstraints();
  const { dirtyFields, isSubmitted, touchedFields, isValidating } = hookFormContext.formState;
  const { getInput = getFormControlInput, formControlSwitchProps } = props;
  const rules =
    typeof props.rules === 'function'
      ? props.rules(
          hookFormContext.watch as (name: string) => UnpackNestedValue<D>,
          hookFormContext.trigger,
          formContext.defaultValues[props.name as string]
        )
      : props.rules;

  const isDirty = Object.keys(dirtyFields).includes(props.name as string);
  const showValidationErrors =
    (formContext.validationMode === 'onBlur' && touchedFields[props.name as string]) ||
    (formContext.validationMode === 'onSubmit' && isSubmitted) ||
    (formContext.validationMode === 'onChange' && isDirty);

  const controlErrors = formContext.errorSummary?.[props.name as string];
  const passwordValidationState = validatePasswordRequirements(
    userValidationConstraints.password.requirements,
    useHookFormContext().getValues('password')
  );

  // Handling to trigger onBlur with validation details for 'onBlur' validationMode only atm.
  useEffect(() => {
    if (formControlBlurredRef.current && !isValidating) {
      formControlBlurredRef.current = false;
      formContext.onFormControlBlur?.(
        props.name as string,
        !controlErrors,
        controlErrors?.messages,
        passwordValidationState
      );
      props.onFormControlBlur?.(props.name as string, !controlErrors, controlErrors?.messages, passwordValidationState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formControlBlurredRef, controlErrors, isValidating]);

  const control = (
    <Controller
      name={props.name as string}
      control={hookFormContext.control}
      rules={props.optional ? omit(rules, ['required']) : rules}
      render={({ field: { onChange, onBlur, value } }): JSX.Element => {
        return getInput(
          {
            field: props,
            successStateEnabled: showValidationErrors,
            errorSummary: formContext.errorSummary,
            formValidationMode: formContext.validationMode,
          },
          {
            name: props.name as string,
            onChange,
            onFocus: (e: FocusEvent<HTMLInputElement>) => {
              formContext.onFormControlFocus?.(props.name as string);
              (props.props as InputProps)?.onFocus?.(e);
              props.onFormControlFocus?.(props.name as string);
            },
            onBlur: () => {
              onBlur();
              // Flag blur event but wait for next error state before onFormControlBlur
              formControlBlurredRef.current = true;
            },
            value,
          }
        );
      }}
    />
  );

  return formControlSwitchProps ? (
    <FormControlSwitch {...formControlSwitchProps}>{control}</FormControlSwitch>
  ) : (
    control
  );
}
