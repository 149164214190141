import React, { FunctionComponent } from 'react';
import { Icon, IconVariant } from '../../Icon';
import { joinStrings } from '../../../../utils/string';
import { PasswordRequirementType } from '../../../Registration/types';
import { Paragraph } from '../../Paragraph';
import { PasswordRequirementsProps } from './types';
import './styles';

export const PasswordRequirements: FunctionComponent<PasswordRequirementsProps> = ({
  requirements,
  validationState,
  theme,
}) => {
  const Icons: Array<IconVariant> = theme === 'dark' ? ['Check', 'Close'] : ['Success', 'Error'];

  return (
    <div className={joinStrings(['password-requirements', theme === 'dark' && 'password-requirements--dark'])}>
      {Object.keys(requirements.mustInclude).map((key: string) => {
        const requirement = requirements.mustInclude[key as PasswordRequirementType];
        const isValid = validationState[key as PasswordRequirementType];
        const hideHint = requirement?.hideFromHints || false;

        return (
          !hideHint && (
            <li key={requirement?.translation} className="password-requirements__requirements-list-item">
              {validationState && (
                <Icon
                  className={joinStrings([
                    'password-requirements__icon',
                    isValid ? 'password-requirements__icon--success' : 'password-requirements__icon--error',
                  ])}
                  variant={isValid ? Icons[0] : Icons[1]}
                />
              )}
              <Paragraph size="sm">{requirement?.translation}</Paragraph>
            </li>
          )
        );
      })}
    </div>
  );
};
