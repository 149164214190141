import React, { FC } from 'react';
import { Theme } from '../../../../types';
import { joinStrings } from '../../../../utils/string';
import { Button } from '../../../shared/Button';
import { Spinner } from '../../../shared/Spinner';
import { NavSearchIcon } from '../../../shared/SVG/Icons';
import { Input } from '../../../shared/Input';
import { useTranslations } from '../../../../hooks/useTranslationsHelper';

interface SearchBarInputProps {
  searchInputRef: React.RefObject<HTMLInputElement>;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  isLoading: boolean;
  isOpen?: boolean;
  theme?: Theme;
  onClose?: () => void;
  onFocus?: () => void;
  shouldDrawCancel?: boolean;
}

export const SearchBarInput: FC<SearchBarInputProps> = ({
  searchInputRef,
  onChange,
  value,
  theme = 'dark',
  isLoading,
  isOpen,
  onClose,
  onFocus,
  shouldDrawCancel = true,
}) => {
  const { t } = useTranslations();

  return (
    <div className={joinStrings(['search-bar', theme && `search-bar--${theme}`])}>
      <Input
        ref={searchInputRef}
        label={t('input.search-games.label')}
        onChange={onChange}
        onFocus={onFocus}
        value={value}
        className="search-bar__input"
        theme={theme}
        iconLeft={<NavSearchIcon />}
        iconRight={isLoading ? <Spinner /> : null}
      />
      {isOpen && shouldDrawCancel ? (
        <Button
          aria-label="search-bar-close-search-aria-label"
          type="button"
          variant="text"
          size="medium"
          onClick={onClose}
          className="search-bar__close-button"
        >
          Cancel
        </Button>
      ) : null}
    </div>
  );
};
