import React, { FunctionComponent } from 'react';
import { joinStrings } from '../../../utils/string';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsSearchBarOpen } from '../../../state/app';
import { setIsSearchBarOpen } from '../../../state/app/reducers';
import './styles';

export type HeaderProps = {
  /**
   * Content to be positioned to left of header layout
   * such as Menu button etc
   */
  left?: React.ReactNode;
  /**
   * Content to be positioned to right of header layout
   *
   * Such as account details, buttons etc.
   */
  right?: React.ReactNode;
  /**
   * Whether the header should stick to page top.
   */
  sticky?: boolean;
  /**
   * Optional custom className
   */
  className?: string;
};

/**
 * General header component which can be used to compose more specific headers
 * in different app sections etc.
 */
export const Header: FunctionComponent<HeaderProps> = ({ right, left, sticky, className }) => {
  const dispatch = useDispatch();
  const isSearchBarOpen = useSelector(selectIsSearchBarOpen);

  const handleCloseSearch = (): void => {
    // Execute method to close search bar
    isSearchBarOpen && dispatch(setIsSearchBarOpen(false));
  };

  return (
    <header
      className={joinStrings(['header', sticky && 'header--sticky', className])}
      onClickCapture={handleCloseSearch}
    >
      <nav className="header__nav">
        {left && <div className="header__left">{left}</div>}
        {right && <div className="header__right">{right}</div>}
      </nav>
    </header>
  );
};
