import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SuspensionLimitsCreateMutationVariables = Types.Exact<{
  userProfileId: Types.Scalars['ID']['input'];
  xTenantId: Types.Scalars['ID']['input'];
  creatorName?: Types.InputMaybe<Types.CreatorName>;
  suspensionLimitsCreate?: Types.InputMaybe<Types.SuspensionLimitsCreateInput>;
}>;


export type SuspensionLimitsCreateMutation = { __typename?: 'Mutation', suspensionLimitsCreate?: { __typename?: 'SuspensionLimitsCreateResponse', data?: { __typename?: 'SuspensionLimitsCreateSuccess', id: string } | null, error?: Array<{ __typename?: 'FieldError', code: string, description?: string | null, fieldName: string, kind: Array<string | null>, pattern?: string | null } | { __typename?: 'GenericError', code: string }> | null } | null };


export const SuspensionLimitsCreateDocument = gql`
    mutation SuspensionLimitsCreate($userProfileId: ID!, $xTenantId: ID!, $creatorName: CreatorName, $suspensionLimitsCreate: SuspensionLimitsCreateInput) {
  suspensionLimitsCreate(
    userProfileId: $userProfileId
    xTenantID: $xTenantId
    creatorName: $creatorName
    suspensionLimitsCreate: $suspensionLimitsCreate
  ) {
    data {
      id
    }
    error {
      ... on FieldError {
        code
        description
        fieldName
        kind
        pattern
      }
      ... on GenericError {
        code
      }
    }
  }
}
    `;
export type SuspensionLimitsCreateMutationFn = Apollo.MutationFunction<SuspensionLimitsCreateMutation, SuspensionLimitsCreateMutationVariables>;

/**
 * __useSuspensionLimitsCreateMutation__
 *
 * To run a mutation, you first call `useSuspensionLimitsCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSuspensionLimitsCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [suspensionLimitsCreateMutation, { data, loading, error }] = useSuspensionLimitsCreateMutation({
 *   variables: {
 *      userProfileId: // value for 'userProfileId'
 *      xTenantId: // value for 'xTenantId'
 *      creatorName: // value for 'creatorName'
 *      suspensionLimitsCreate: // value for 'suspensionLimitsCreate'
 *   },
 * });
 */
export function useSuspensionLimitsCreateMutation(baseOptions?: Apollo.MutationHookOptions<SuspensionLimitsCreateMutation, SuspensionLimitsCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SuspensionLimitsCreateMutation, SuspensionLimitsCreateMutationVariables>(SuspensionLimitsCreateDocument, options);
      }
export type SuspensionLimitsCreateMutationHookResult = ReturnType<typeof useSuspensionLimitsCreateMutation>;
export type SuspensionLimitsCreateMutationResult = Apollo.MutationResult<SuspensionLimitsCreateMutation>;
export type SuspensionLimitsCreateMutationOptions = Apollo.BaseMutationOptions<SuspensionLimitsCreateMutation, SuspensionLimitsCreateMutationVariables>;