import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';
import { SharedState } from './sharedState';
import { passiveOption } from '../../consts';

type ScrollerState = {
  scrolling: boolean;
  scrollingEventCreated: boolean;
  scrollTimeout: NodeJS.Timeout | null;
};

const scrollingState = new SharedState<ScrollerState>({
  scrolling: false,
  scrollingEventCreated: false,
  scrollTimeout: null,
});

type UseScrolling = {
  isScrolling: () => boolean;
};

export function useScrolling(): UseScrolling {
  const [, setState] = useState<Record<string, unknown>>();
  const state = scrollingState.getState();
  const { scrollTimeout } = state;

  // This will be called when the snack state changes and force a render
  function reRender(): void {
    setState({});
  }

  useEffect(() => {
    // Subscribe to a global state when a component mounts
    scrollingState.subscribe(reRender);

    return () => {
      // Unsubscribe from a global state when a component unmounts
      scrollingState.unsubscribe(reRender);
    };
  });

  const scrollHandler = (): void => {
    /* istanbul ignore if */
    if (scrollTimeout) {
      clearTimeout(scrollTimeout);
    }

    const newScrollTimeout = setTimeout(() => {
      scrollingState.setState({
        ...scrollingState.getState(),
        scrolling: false,
      });
    }, 1000);

    scrollingState.setState({
      ...scrollingState.getState(),
      scrolling: true,
      scrollTimeout: newScrollTimeout,
    });
  };

  const scrollListener = debounce(() => scrollHandler(), 1000);

  // Only create one event listener to prevent overkill
  if (!state.scrollingEventCreated) {
    window.addEventListener('scroll', scrollListener, passiveOption);
    scrollingState.setState({ ...state, scrollingEventCreated: true });
  }

  return { isScrolling: () => state.scrolling };
}
