import React, { FC } from 'react';
import { Paragraph } from '../Paragraph';
import './SimpleGameCardLabel.scss';

interface SimpleGameCardLabelProps {
  labelText: string;
}

export const SimpleGameCardLabel: FC<SimpleGameCardLabelProps> = ({ labelText }): JSX.Element => {
  return (
    <Paragraph noMargin className="game-card-label" size="xxs">
      {labelText}
    </Paragraph>
  );
};
