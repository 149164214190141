import { ButtonBack, ButtonNext, CarouselContext, Slide, Slider } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import React, {
  Children,
  Fragment,
  FunctionComponent,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { CarouselOffsetStyle, CarouselSharedProps } from '../Carousel';
import { SlideLoader } from '../SlideLoader';
import { Icon } from '../../../shared/Icon';
import { joinStrings } from '../../../../utils/string';
import './styles';

export interface CarouselSliderProps extends CarouselSharedProps {
  touchThreshold: number;
  isMobile: boolean;
  disabled: boolean;
  visibleSlides: number;
  offsetPercentage: number;
  offsetStyle: CarouselOffsetStyle;
  slides: React.ReactNode[];
  activeHandler: (state: boolean) => void;
}

const mapSlideData = (slides?: React.ReactNode[]): Array<{ zoomed: boolean; content: React.ReactNode }> =>
  slides?.map((content) => {
    return { zoomed: false, content };
  }) || [];

const EMPIRIC_SLIDE_THRESHOLD = 35;

export const SliderWrapper: FunctionComponent<CarouselSliderProps> = (props) => {
  const carouselContext = useContext(CarouselContext);
  const ref = useRef<HTMLDivElement>(null);

  const {
    touchThreshold,
    visibleSlides,
    offsetPercentage,
    offsetStyle,
    alignment,
    disabled,
    contentAlignment,
    showArrows,
    zoom,
    zoomBehavior,
    isMobile,
    slides,
    infinite,
    onClick,
    onNext,
    onBack,
    activeHandler,
  } = props;

  const [ready, setReady] = useState(Boolean(slides && slides.length));
  const [currentSlide, setCurrentSlide] = useState(carouselContext.state.currentSlide);
  const [onFirst, setOnFirst] = useState(true);
  const [onLast, setOnLast] = useState(false);
  const [animating, setAnimation] = useState(false);

  const [slideData, setSlideData] = useState<{ zoomed: boolean; content: React.ReactNode }[]>(mapSlideData(slides));

  // It's applied to catch quick coordinates change for touchmove events as useState is too slow for it
  let startTouchCoord: number;

  let animationTimer: ReturnType<typeof setTimeout>;
  let touchTimer: ReturnType<typeof setTimeout>;
  let closeTimer: ReturnType<typeof setTimeout>;
  let lockTimer: ReturnType<typeof setTimeout>;

  const offset = offsetStyle === 'leftRight' ? offsetPercentage * 2 : offsetPercentage;

  const carouselEl = ref.current
    ? (ref.current.getElementsByClassName('carousel__slider-tray--horizontal')[0] as HTMLElement)
    : null;

  useLayoutEffect(() => {
    /* istanbul ignore else */
    if (carouselEl) {
      // sets the offset (if there is any)
      carouselEl.style.setProperty(
        '--carousel-slider-tray-width',
        offset > 0
          ? `calc(${carouselEl.style.width} - ${(offset * slides.length) / visibleSlides}%)`
          : carouselEl.style.width
      );

      // set alignment of card content
      let placement = 'center';

      if (alignment === 'top') {
        placement = 'flex-start';
      } else if (alignment === 'bottom') {
        placement = 'flex-end';
      }

      carouselEl.style.setProperty('--carousel-slide-alignment', placement);
      carouselEl.style.setProperty(
        '--carousel-slider-tray-left',
        offsetStyle === 'leftRight' ? `${offsetPercentage}%` : '0'
      );
    }
  });

  useEffect(() => {
    setSlideData(mapSlideData(slides));
  }, [slides]);

  const resetAnimations = (): void => {
    setSlideData(
      slideData.map((x) => {
        x.zoomed = false;

        return x;
      })
    );
  };

  /* istanbul ignore next */
  const toggleZoom = (index: number, state?: boolean): void => {
    // once zoomed in, locks transition duration to 0 so it doesn't animate if carousel scrolls
    if (!slideData[index].zoomed && carouselEl) {
      clearTimeout(lockTimer);
      carouselEl.style.setProperty('--carousel-slide-zoom-transition', `100ms`);
      lockTimer = setTimeout(() => {
        carouselEl.style.setProperty('--carousel-slide-zoom-transition', `0s`);
      }, 100);
    }

    setSlideData(
      slideData.map((x, i) => {
        x.zoomed = index === i ? state || !x.zoomed : false;

        return x;
      })
    );
  };

  const slideLocation = (i: number): string => {
    if (currentSlide === i) {
      return 'first';
    } else if (i === visibleSlides - 1 + currentSlide) {
      return 'last';
    } else if (visibleSlides > 1 && offset > 0 && i === visibleSlides + currentSlide) {
      return 'offset';
    }

    return 'middle';
  };

  const animationHandler = (step: string): void => {
    resetAnimations();
    clearTimeout(animationTimer);
    setAnimation(true);
    animationTimer = setTimeout(() => {
      setAnimation(false);
      step === 'next' && onNext && onNext(currentSlide);
      step === 'back' && onBack && onBack(currentSlide);
    }, 500);
  };

  const onCarouselChange = (): void => {
    const { currentSlide, totalSlides } = carouselContext.state;

    if (slides.length > 1) {
      /* istanbul ignore else */
      if (carouselEl && currentSlide >= 0) {
        carouselEl.style.setProperty(
          '--carousel-slider-tray-width',
          `calc(${carouselEl.style.width} - ${(offset * slides.length - 1) / visibleSlides}%)`
        );
      }
    }

    setOnFirst(currentSlide === 0);
    setOnLast(currentSlide === totalSlides - visibleSlides);
    setCurrentSlide(currentSlide);
  };

  useEffect(() => {
    carouselContext.subscribe(onCarouselChange);
    setReady(true);

    return () => {
      carouselContext.unsubscribe(onCarouselChange);
      clearTimeout(animationTimer);
      clearTimeout(lockTimer);
      clearTimeout(closeTimer);
      clearTimeout(touchTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carouselContext, offset, visibleSlides, offsetPercentage, offsetStyle, alignment, contentAlignment]);

  /* istanbul ignore else */
  const onClickOrKeydown = (zoomed: boolean, i: number): void => {
    if (!zoomed && !isMobile) {
      zoomBehavior === 'onClick' && toggleZoom(i);
      onClick && onClick(i);
      activeHandler(true);
    }
  };

  /* istanbul ignore next */
  const onMouseLeave = (): void => {
    if (zoomBehavior === 'onHover') {
      activeHandler(false);
      resetAnimations();
    }
  };

  /* istanbul ignore next */
  const onMouseOver = (): void => {
    if (zoomBehavior === 'onHover') {
      zoomBehavior === 'onHover' && activeHandler(true);
    }
  };

  /* istanbul ignore next */
  const onTouchStart = (zoomed: boolean, i: number): void => {
    if (!zoomed) {
      clearTimeout(touchTimer);
      touchTimer = setTimeout(() => {
        toggleZoom(i);
        activeHandler(true);
      }, touchThreshold);
      onClick && onClick(i);
    }
  };

  /* istanbul ignore next */
  const onTouchEnd = (): void => {
    clearTimeout(touchTimer);
  };

  const slideCloseBtnOnClick = (i: number): void => {
    activeHandler(false);
    clearTimeout(closeTimer);
    closeTimer = setTimeout(() => {
      toggleZoom(i);
    }, 0);
  };

  const callsetterOnTouchStart = (event: React.TouchEvent): void => {
    // Don't pas any setters or hard logic expressions here
    // as it may slow down immediate coordinate change of mobile touchevent
    startTouchCoord = event.changedTouches[0].clientX;
  };

  const callHandlerOnTouchEnd = (event: React.TouchEvent): void => {
    const currentSlideIndex = carouselContext.state.currentSlide;

    if (Math.abs(event.changedTouches[0].clientX - startTouchCoord) > EMPIRIC_SLIDE_THRESHOLD) {
      event.changedTouches[0].clientX > startTouchCoord ? onBack?.(currentSlideIndex) : onNext?.(currentSlideIndex);
    }
  };

  return (
    <div className="carousel-wrapper__slide-wrapper" ref={ref}>
      {showArrows && slides.length > 1 && (
        <Fragment>
          {(infinite || !onFirst) && (
            <div className="carousel-wrapper__buttons carousel-wrapper__buttons--back">
              <ButtonBack
                data-testid="carousel-back-btn"
                {...(zoom
                  ? {
                      onClick: () => animationHandler('back'),
                      onMouseOver: () => activeHandler(true),
                    }
                  : { onClick: () => onBack && onBack(currentSlide) })}
                disabled={animating}
                className="carousel__back-btn"
              >
                <Icon role="button" variant="ArrowLeft" />
              </ButtonBack>
            </div>
          )}
          {(infinite || !onLast) && (
            <div className="carousel-wrapper__buttons carousel-wrapper__buttons--next">
              <ButtonNext
                data-testid="carousel-next-btn"
                {...(zoom
                  ? {
                      onClick: () => animationHandler('next'),
                      onMouseOver: () => activeHandler(true),
                    }
                  : {
                      onClick: () => onNext && onNext(currentSlide),
                    })}
                disabled={animating}
                className="carousel__next-btn"
              >
                <Icon role="button" variant="ArrowRight" />
              </ButtonNext>
            </div>
          )}
        </Fragment>
      )}

      <div className="carousel-wrapper__slider">
        <div className="carousel-wrapper__loader">
          <SlideLoader show={!ready} slideCount={visibleSlides} />
        </div>

        <div
          className={joinStrings([
            'carousel-wrapper__slider-slides',
            ready && 'carousel-wrapper__slider-slides--show',
            disabled && `carousel-wrapper__slider-slides--disabled`,
          ])}
          data-testid="carousel-wrapper-slider-slides"
        >
          {/* movethreshold */}
          <Slider
            moveThreshold={
              /* istanbul ignore next */
              isMobile ? 0.1 : 0.5
            }
            classNameAnimation="ease-out"
          >
            {Children.toArray(
              slideData.map((slide, i) => {
                return (
                  <Slide
                    index={i}
                    data-testid="carousel-slide"
                    {...(zoom
                      ? {
                          onTouchStart:
                            /* istanbul ignore next */
                            (): void => {
                              onTouchStart(slide.zoomed, i);
                            },

                          onTouchEnd,
                          onMouseOver,
                          onMouseLeave,
                          onClick: (): void => {
                            onClickOrKeydown(slide.zoomed, i);
                          },
                          onKeyDown: (): void => {
                            onClickOrKeydown(slide.zoomed, i);
                          },
                        }
                      : {
                          onTouchStart: callsetterOnTouchStart,
                          onTouchEnd: callHandlerOnTouchEnd,
                          onClick: (): void => {
                            onClick && onClick(i);
                          },
                          onKeyDown: () => onClick && onClick(i),
                        })}
                    classNameHidden="carousel__slide--is-hidden"
                    classNameVisible="carousel__slide--is-visible"
                    className={joinStrings(
                      [
                        `carousel__slide--alignment-${alignment}`,
                        `carousel__slide--offset-${offset}`,
                        `carousel__slide--offsetStyle-${offsetStyle}`,
                        slideLocation(i) === 'last' && `carousel__slide--last`,
                      ].concat(
                        zoom
                          ? [
                              zoomBehavior === 'onHover' && 'carousel__slide--zoom-on-hover',
                              !isMobile &&
                                zoomBehavior === 'onHover' &&
                                `carousel__slide--zoom-on-hover-${slideLocation(i)}`,
                              slide.zoomed
                                ? `carousel__slide--zoom-on-${slideLocation(i)}`
                                : `carousel__slide--zoom-out-${slideLocation(i)}`,
                            ]
                          : []
                      )
                    )}
                  >
                    <div
                      data-testid="carousel-slide-inner"
                      className={joinStrings(['carousel__slide-inner', `carousel__slide-inner--${contentAlignment}`])}
                      data-zoombehavior={zoomBehavior}
                      data-zoomed={slide.zoomed}
                      tabIndex={0}
                      role="tablist"
                    >
                      {zoom && zoomBehavior === 'onClick' && slide.zoomed && (
                        <button
                          data-testid="carousel-slide-close-btn"
                          className="carousel__slide-close-btn"
                          onClick={(): void => {
                            slideCloseBtnOnClick(i);
                          }}
                        >
                          <Icon variant="Close" />
                        </button>
                      )}
                      {slide.content}
                    </div>
                  </Slide>
                );
              })
            )}
          </Slider>
        </div>
      </div>
    </div>
  );
};
