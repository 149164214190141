import { useAuth } from 'react-oidc-context';

const useDestroyUserSession = (): { logoutUser: () => void } => {
  const auth = useAuth();

  const logoutUser = (): void => {
    window.dispatchEvent(new CustomEvent('EndUser3rdPartyLogout'));
    window.localStorage.removeItem('one-time-token');
    auth.signoutRedirect({
      redirectMethod: 'replace',
      id_token_hint: auth.user?.id_token,
    });
  };

  return { logoutUser };
};

export default useDestroyUserSession;
