export const createElement = (
  element: string,
  attributes: { name: string; value: string }[],
  toAppend: string
): void => {
  const el = document.createElement(element);

  attributes.forEach((attr) => {
    el.setAttribute(attr.name, attr.value);
  });

  document.getElementsByTagName(toAppend)[0].appendChild(el);
};
