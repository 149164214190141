import React, { FC, useState, useEffect } from 'react';
import { xTenantId } from '../../../../consts';
import { useUserContext } from '../../../../providers/UserProvider';
import {
  UserQuestionnairesQuery,
  useUserQuestionnairesQuery,
} from '../../../../libs/graphql/baseAppAPI/queries/__generated__/user-questionnaires.query.generated';
import { useSnackbar } from '../../../../hooks/snackbar';
import { useTranslations } from '../../../../hooks/useTranslationsHelper';
import { Spinner } from '../../../shared/Spinner';
import { Endpoint } from '../../../../models/api.model';
import './Questionnaires.scss';
import { Questionnaire } from './Questionnaire';
import { AccountTile } from '../AccountTile';
import { PopUpHandler } from '../../../shared/PopupHandler';
import { useAuth } from 'react-oidc-context';

const POST_LOGIN_CHECK_KEY = 'questionnaire_post_login_check';

export const QuestionnairesList: FC = () => {
  const auth = useAuth();
  const isAuthenticated = auth?.isAuthenticated;
  const { userProfileId } = useUserContext();
  const { addSnack } = useSnackbar();
  const { t } = useTranslations();
  const [questionnairesData, setQuestionnairesData] = useState<null | UserQuestionnairesQuery>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [shouldCheckQuestionnaires, setShouldCheckQuestionnaires] = useState(false);
  const [questionnaires, setQuestionnaires] = useState<UserQuestionnairesQuery['userQuestionnaires']['data']>([]);

  useEffect(() => {
    if (questionnairesData?.userQuestionnaires.data) {
      const pendingQuestionnaires = questionnairesData.userQuestionnaires.data.filter(
        (q11e) => q11e?.status === 'Requested'
      );

      setQuestionnaires(pendingQuestionnaires);
      setIsOpen(pendingQuestionnaires.length > 0);
    }
  }, [questionnairesData]);

  const handleQuestionnaireSubmitted = (id: string): void => {
    setQuestionnaires((prevQuestionnaires) => {
      const updatedQuestionnaires = prevQuestionnaires?.filter((q11e) => q11e?.id !== id) ?? [];

      if (updatedQuestionnaires.length === 0) {
        setIsOpen(false);
      }

      return updatedQuestionnaires;
    });
  };

  useEffect(() => {
    if (!isAuthenticated) {
      sessionStorage.removeItem(POST_LOGIN_CHECK_KEY);

      return;
    }

    const hasCheckedPostLogin = sessionStorage.getItem(POST_LOGIN_CHECK_KEY);

    if (!hasCheckedPostLogin) {
      setShouldCheckQuestionnaires(true);
    }
  }, [isAuthenticated]);

  const { loading, refetch } = useUserQuestionnairesQuery({
    variables: {
      xTenantId,
      userProfileId,
    },
    onCompleted: (data) => {
      setQuestionnairesData(data);
    },
    onError: (error) => {
      handleError();
    },
    context: { endpoint: Endpoint.appAPI },
    fetchPolicy: 'network-only',
    skip: !isAuthenticated || !shouldCheckQuestionnaires,
  });

  useEffect(() => {
    if (shouldCheckQuestionnaires && isAuthenticated) {
      refetch();
    }
  }, [shouldCheckQuestionnaires, refetch, isAuthenticated]);

  const handleError = (): void => {
    addSnack({
      type: 'error',
      theme: 'dark',
      message: t('errors.general'),
    });
    setShouldCheckQuestionnaires(false);
  };

  const handleClose = (): void => {
    setIsOpen(false);
    sessionStorage.setItem(POST_LOGIN_CHECK_KEY, 'true');
  };

  if (!isAuthenticated) {
    return null;
  }

  return (
    <PopUpHandler
      isOpen={isOpen}
      onClose={handleClose}
      header="Questionnaires"
      modalProps={{ 'aria-label': 'Questionnaires', className: 'questionnaires-modal' }}
      drawerProps={{ className: 'questionnaires-drawer' }}
      showCloseBtn
    >
      {loading ? (
        <div className="spinner-wrapper">
          <Spinner />
        </div>
      ) : (
        <div className="questionnaires-container">
          <AccountTile>
            <ul>
              {questionnaires?.map((q11e) => (
                <Questionnaire
                  key={q11e?.id as string}
                  questionnaireTemplatesByIdId={q11e?.questionnaireTemplateId as string}
                  id={q11e?.id as string}
                  onSubmittedSuccessfully={handleQuestionnaireSubmitted}
                />
              ))}
            </ul>
          </AccountTile>
        </div>
      )}
    </PopUpHandler>
  );
};
