import React, { Fragment, FC } from 'react';
import { Button } from '../../../../shared/Button';
import './styles';
import { DepositButton } from '../../../../shared/DepositButton';
import { useDispatch } from 'react-redux';
import { CashierType } from '../../../../../state/cashier';
import { setCashierModal } from '../../../../../state/cashier/reducers';
import { useTranslations } from '../../../../../hooks/useTranslationsHelper';

export const AccountButtons: FC = () => {
  const { t } = useTranslations();
  const dispatch = useDispatch();

  const onDepositBtnClick = (): void => {
    dispatch(setCashierModal({ isOpen: true, type: CashierType.Deposit }));
  };

  const onWithdrawalBtnClick = (): void => {
    dispatch(setCashierModal({ isOpen: true, type: CashierType.Withdrawal }));
  };

  return (
    <Fragment>
      <div className="account-buttons">
        <DepositButton className="my-account__deposit-btn" onClick={onDepositBtnClick} />
        <Button
          type="button"
          className="account-buttons__withdrawal-btn"
          variant="secondary-alt"
          onClick={onWithdrawalBtnClick}
        >
          {t('my-account.withdraw')}
        </Button>
      </div>
    </Fragment>
  );
};
