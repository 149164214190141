import { countryCode, currencyCode } from '../../../consts';
import { FlattenedProfileData } from '../../../hooks/registration';
import { countries } from '../../../utils/loqate/locale';
import { CountryObject } from '../../../utils/phone-number';
import { GenderOptions, HonoricTitleOptions } from '../types';

export const defaultRegistrationFormState: FlattenedProfileData = {
  honorificPrefix: HonoricTitleOptions.Mr,
  gender: GenderOptions.Male,
  password: '',
  email: '',
  givenName: '',
  familyName: '',
  birthDate: '',
  mobileNumber: `+${(countries as CountryObject)['GB']?.phone} `,
  mobilePrefix: `+${(countries as CountryObject)['GB']?.phone}`,
  addrStreetAddress: '',
  addrRegion: '',
  addrPostalCode: '',
  addrLocality: '',
  acceptTerms: false,
  acceptMarketingPreferences: false,
  addrCountryCode: countryCode,
  currencyCode: currencyCode,
  promoCode: '',
  toggleAllMarketing: false,
  optInEmail: false,
  optInSms: false,
  optInTelephone: false,
};
