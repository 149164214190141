import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GamesGetByTagsQueryVariables = Types.Exact<{
  gameId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  tag: Types.Scalars['String']['input'];
  category?: Types.InputMaybe<Types.Scalars['String']['input']>;
  language: Types.Scalars['String']['input'];
  count: Types.Scalars['Int']['input'];
}>;


export type GamesGetByTagsQuery = { __typename?: 'Query', game: Array<{ __typename?: 'game', game_id?: string | null, translations?: Array<{ __typename?: 'game_translations', display_name?: string | null, slug?: string | null } | null> | null, game_image?: { __typename?: 'directus_files', filename_disk?: string | null, width?: number | null, height?: number | null } | null, tags?: Array<{ __typename?: 'game_game_tags', game_tags_id?: { __typename?: 'game_tags', translations?: Array<{ __typename?: 'game_tags_translations', name?: string | null } | null> | null } | null } | null> | null }> };


export const GamesGetByTagsDocument = gql`
    query GamesGetByTags($gameId: String, $tag: String!, $category: String, $language: String!, $count: Int!) {
  game(
    limit: $count
    filter: {status: {_eq: "published"}, game_id: {_neq: $gameId}, tags: {game_tags_id: {internal_name: {_eq: $tag}}}, category: {internal_name: {_eq: $category}}}
  ) {
    game_id
    translations(
      filter: {languages_language_code: {language_code: {_eq: $language}}}
    ) {
      display_name
      slug
    }
    game_image(filter: null) {
      filename_disk
      width
      height
    }
    tags {
      game_tags_id(filter: null) {
        translations(
          filter: {languages_language_code: {language_code: {_eq: $language}}}
        ) {
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGamesGetByTagsQuery__
 *
 * To run a query within a React component, call `useGamesGetByTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGamesGetByTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGamesGetByTagsQuery({
 *   variables: {
 *      gameId: // value for 'gameId'
 *      tag: // value for 'tag'
 *      category: // value for 'category'
 *      language: // value for 'language'
 *      count: // value for 'count'
 *   },
 * });
 */
export function useGamesGetByTagsQuery(baseOptions: Apollo.QueryHookOptions<GamesGetByTagsQuery, GamesGetByTagsQueryVariables> & ({ variables: GamesGetByTagsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GamesGetByTagsQuery, GamesGetByTagsQueryVariables>(GamesGetByTagsDocument, options);
      }
export function useGamesGetByTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GamesGetByTagsQuery, GamesGetByTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GamesGetByTagsQuery, GamesGetByTagsQueryVariables>(GamesGetByTagsDocument, options);
        }
export function useGamesGetByTagsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GamesGetByTagsQuery, GamesGetByTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GamesGetByTagsQuery, GamesGetByTagsQueryVariables>(GamesGetByTagsDocument, options);
        }
export type GamesGetByTagsQueryHookResult = ReturnType<typeof useGamesGetByTagsQuery>;
export type GamesGetByTagsLazyQueryHookResult = ReturnType<typeof useGamesGetByTagsLazyQuery>;
export type GamesGetByTagsSuspenseQueryHookResult = ReturnType<typeof useGamesGetByTagsSuspenseQuery>;
export type GamesGetByTagsQueryResult = Apollo.QueryResult<GamesGetByTagsQuery, GamesGetByTagsQueryVariables>;