import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BonusGetBySlugQueryVariables = Types.Exact<{
  language: Types.Scalars['String']['input'];
  slug: Types.Scalars['String']['input'];
}>;


export type BonusGetBySlugQuery = { __typename?: 'Query', bonus: Array<{ __typename?: 'bonus', template_id?: string | null, start_date?: any | null, end_date?: any | null, translations?: Array<{ __typename?: 'bonus_translations', title?: string | null, subtitle?: string | null, description?: string | null, terms_and_conditions?: string | null, instructions?: string | null, thumbnail?: { __typename?: 'directus_files', filename_disk?: string | null, title?: string | null, width?: number | null, height?: number | null } | null } | null> | null }> };


export const BonusGetBySlugDocument = gql`
    query BonusGetBySlug($language: String!, $slug: String!) {
  bonus(
    filter: {status: {_eq: "published"}, translations: {languages_language_code: {language_code: {_eq: $language}}, slug: {_eq: $slug}}}
  ) {
    template_id
    start_date
    end_date
    translations(
      filter: {languages_language_code: {language_code: {_eq: $language}}}
    ) {
      title
      subtitle
      description
      terms_and_conditions
      instructions
      thumbnail(filter: null) {
        filename_disk
        title
        width
        height
      }
    }
  }
}
    `;

/**
 * __useBonusGetBySlugQuery__
 *
 * To run a query within a React component, call `useBonusGetBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useBonusGetBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBonusGetBySlugQuery({
 *   variables: {
 *      language: // value for 'language'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useBonusGetBySlugQuery(baseOptions: Apollo.QueryHookOptions<BonusGetBySlugQuery, BonusGetBySlugQueryVariables> & ({ variables: BonusGetBySlugQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BonusGetBySlugQuery, BonusGetBySlugQueryVariables>(BonusGetBySlugDocument, options);
      }
export function useBonusGetBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BonusGetBySlugQuery, BonusGetBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BonusGetBySlugQuery, BonusGetBySlugQueryVariables>(BonusGetBySlugDocument, options);
        }
export function useBonusGetBySlugSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BonusGetBySlugQuery, BonusGetBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BonusGetBySlugQuery, BonusGetBySlugQueryVariables>(BonusGetBySlugDocument, options);
        }
export type BonusGetBySlugQueryHookResult = ReturnType<typeof useBonusGetBySlugQuery>;
export type BonusGetBySlugLazyQueryHookResult = ReturnType<typeof useBonusGetBySlugLazyQuery>;
export type BonusGetBySlugSuspenseQueryHookResult = ReturnType<typeof useBonusGetBySlugSuspenseQuery>;
export type BonusGetBySlugQueryResult = Apollo.QueryResult<BonusGetBySlugQuery, BonusGetBySlugQueryVariables>;