import { GridBreakpointConfig, GridColumnAmount } from '../../shared/Grid';
import { Game } from '../games.model';

export interface GameItemUIProps {
  /**
   * backgroundImage sets video thumbnail
   */
  backgroundImage?: string;
  /**
   *
   * videoUrl sets video to load on component mount
   */
  videoUrl?: string;
  /**
   * Show Demo Button
   */
  showDemoButton?: boolean;
  /**
   * Show Info button
   */
  showInfoButton?: boolean;
  /**
   * The game whose details will be displayed.
   */
  game: Game;
  /**
   * Fallback image url if a suitable one can not be found in the Game object.
   */
  fallbackImageUrl: string;
  /**
   * Fallback alt text if a suitable one can not be found in the Game object.
   */
  fallbackImageAltText: string;
  /**
   * Whether to display the game's title.
   */
  showGameName: boolean;
  /**
   * Url link for full game information.
   */
  gameUrl: string;
  /**
   * Whether to disable game menu overlay. defaults to false
   */
  disableMenu?: boolean;
  /**
   * Info button icon position.  Defaults to bottom.
   */
  infoIconVert?: 'top' | 'bottom';
  /**
   * Game item panel display size. Defaults to medium.
   */
  size?: 'small' | 'medium';
  /**
   * Call back for when user selects to play the game.
   */
  onPlayGame?: () => void;
  /**
   * Call back for when user selects to play the game demo.
   */
  onPlayDemo?: () => void;
  /**
   * Call back for when user selects to view game info
   */
  onViewGameInfo?: () => void;
}

export type GameItemUIComponent<T = HTMLAnchorElement> = React.ComponentType<GameItemUIProps & React.RefAttributes<T>>;

/**
 * Shared type for games list UI components (grid, carousel)
 */
export type GamesListUIComponentProps = {
  /**
   * List of games to be displayed
   */
  games: Game[] | Game[][];
  /**
   * Number of columns or visible items per screen
   */
  columns: GridColumnAmount;
  /**
   * Whether to show game's display name
   */
  showGameName: boolean;
  /**
   * Fallback image text if not provided in Game object
   */
  fallbackImageAltText: string;
  /**
   * Fallback image if not provided in Game object images
   */
  fallbackImageUrl: string;
  /**
   * Whether to disable game menu options.
   */
  disableGameMenu?: boolean;
  /**
   * Whether data is loading
   */
  loading?: boolean;
  /**
   * Optionally configure the presentational component to render a game item.
   */
  GameItem?: GameItemUIComponent;
  /**
   * Function to get game url.
   */
  getGameUrl: (gameUrl: string, playType?: 'demo' | 'real', gameId?: string) => string;
  /**
   * Will be called on window resize/scroll & other events.
   * Calls will be debounced 200ms to prevent too high of an invocation frequency.
   *
   * Will provide a list of items which are currently on screen.
   */
  onVisibilityUpdate?: (games: Game[]) => void;
  /**
   * Called when user wishes to view game info.
   */
  onViewGameInfo?: (game: Game) => void;
  /**
   * Called when user selects try a demo version of a game
   */
  onPlayDemo?: (game: Game) => void;
  /**
   * Called when user decides to play the full game.
   */
  onPlayGame?: (game: Game) => void;
} & GridBreakpointConfig;

export type GamesListUIComponent = React.ComponentType<GamesListUIComponentProps>;

export enum GameFilterCarousel {
  Category = 'category',
  Tag = 'tag',
}
