import React, { FunctionComponent, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Orientation, useDeviceInfo, useOrientation } from '../../../../hooks/useDeviceInfo';
import { useHasScrolled } from '../../../../hooks/presentation-logic';
import { useDispatch } from 'react-redux';
import { APP_URLS } from '../../../../consts';
import { setIsNavigationExpanded } from '../../../../state/app/reducers';
import { joinStrings } from '../../../../utils/string';
import { IconButton } from '../../../shared/IconButton';
import { Icon } from '../../../shared/Icon';
import { Button } from '../../../shared/Button';
import './styles';
import { useTranslations } from '../../../../hooks/useTranslationsHelper';

export type AccountPageWrapperProps = {
  /**
   * Whether the page has a back button or not. Defaults to true
   */
  hasBackButton?: boolean;
  /**
   * Whether the page has a close button or not. Defaults to true
   */
  hasCloseButton?: boolean;
  /**
   * Callback that's executed when the user presses the close button
   */
  onClose: (isNetsedRoute?: boolean) => void;
  /**
   * Optional class name to apply to wrapper element
   */
  className?: string;
  /**
   * Whether the close and back buttons are displayed over a dark banner. Defaults to false
   * If true, they will be coloured white (unless the page is vertically scrolled)
   */
  hasDarkBanner?: boolean;
  /**
   * Ref to be added to the first focusable element, which will be the back button is the page has one, or the close button if not.
   */
  elementToFocusOnOpen?: React.RefObject<HTMLButtonElement>;
  /**
   * Callback that's executed when the user presses the back button
   */
  onBackBtnClick?: () => void;
  children?: React.ReactNode;
  stickyFooter?: React.ReactNode;
};

/**
 * Reusable component that wraps the account pages, e.g. Deposit and My Activity.
 * Gives container height and vertical scroll, and also renders a nav bar containing a close button and back button (unless hasBackButton is false)
 * that is fixed top on vertical scroll.
 */
export const AccountPageWrapper: FunctionComponent<AccountPageWrapperProps> = ({
  hasBackButton = true,
  hasCloseButton = true,
  onClose,
  className,
  children,
  hasDarkBanner,
  elementToFocusOnOpen,
  stickyFooter,
  onBackBtnClick,
}) => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const scrollRef = useRef<HTMLDivElement>(null);
  const isMobile = useDeviceInfo().isMobileDevice;
  const isLandscape = useOrientation() === Orientation.LANDSCAPE;
  const isScrolled = useHasScrolled(scrollRef);
  const isSticky = hasBackButton && isScrolled;
  const dispatch = useDispatch();

  const handleBackBtnClick = (): void => {
    onBackBtnClick ? onBackBtnClick() : navigate(APP_URLS.myAccount.home);
  };

  useEffect(() => {
    isMobile && dispatch(setIsNavigationExpanded(true));
  }, [dispatch, isMobile]);

  return (
    <div
      ref={scrollRef}
      className={joinStrings([
        'account-page-wrapper',
        hasDarkBanner && 'account-page-wrapper--with-dark-banner',
        isSticky && 'account-page-wrapper--is-scrolled',
        isMobile && isLandscape && 'spin-zone-menu__landscape-mode',
        className,
      ])}
    >
      <div className="account-page-wrapper__nav-bar">
        {hasBackButton && (
          <IconButton
            className="account-page-wrapper__back-btn"
            onClick={handleBackBtnClick}
            aria-label="my-account-back-btn"
            icon={<Icon variant="NavArrowLeft" />}
            size="large"
            color="inherit"
            ref={hasBackButton ? elementToFocusOnOpen : undefined}
          />
        )}
        {hasCloseButton && (
          <Button
            className="account-page-wrapper__close-btn"
            type="button"
            onClick={(): void => {
              onClose();
              isMobile && dispatch(setIsNavigationExpanded(false));
            }}
            variant={hasDarkBanner && !isSticky ? 'text-alt' : 'text'}
            ref={!hasBackButton ? elementToFocusOnOpen : undefined}
          >
            {t('my-account.close-btn')}
          </Button>
        )}
      </div>
      {children}
      {stickyFooter && <div className="account-page-wrapper__footer">{stickyFooter}</div>}
    </div>
  );
};
