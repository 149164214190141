import { formatDate, isFuture, parseISO } from 'date-fns';
import complement from 'lodash/fp/complement';
import pipe from 'lodash/fp/pipe';
import { BudgetLimitsTransactionResponse } from '../../types.__generated__';
import { capitalizeFirstLetter } from '../../utils/form';
import { IGroupedLimits, IsDateReached } from './types';

export const calculateDate = (value: number): string => {
  const date = new Date();
  const timeZoneOffset = date.getTimezoneOffset() * 60000;

  date.setDate(date.getDate() + value);

  return new Date(date.getTime() - timeZoneOffset).toISOString();
};

export const getFirstDayOfNextMonth = (): string => {
  const date = new Date();
  const nextMonth = date.getMonth() + 1;
  const nextDate = new Date(date.getFullYear(), nextMonth, 1);

  return formatDate(nextDate, 'dd.MM.yyyy');
};

export const getLimitNameByPeriod = (period = 'unknown', isCapitalized?: boolean): string => {
  const names: { [key: string]: string } = {
    P1D: 'daily',
    P7D: 'weekly',
    P30D: 'monthly',
    unknown: 'Unknown Limit',
  };

  return isCapitalized ? capitalizeFirstLetter(names[period]) : names[period];
};

// is date in future
export const isDateReached: IsDateReached = pipe(parseISO, complement(isFuture));

export const groupLimitsData = (transactions?: BudgetLimitsTransactionResponse[]): IGroupedLimits => {
  const groupedTransactions = transactions?.reduce((acc, transaction) => {
    const { type, period } = transaction;

    if (!acc[type as string]) {
      acc[type as string] = {};
    }

    if (!acc[type as string][period as string]) {
      acc[type as string][period as string] = [];
    }

    acc[type as string][period as string].push(transaction);

    return acc;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }, {} as any);

  return groupedTransactions;
};

export const codedToISO8601: Record<string, string> = {
  '15': 'PT15M',
  '30': 'PT30M',
  '1': 'PT1H',
};

export const decodedMinutesFromISO8601: Record<string, number> = {
  PT15M: 15,
  PT30M: 30,
  PT1H: 60,
};
