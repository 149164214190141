import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from '../../hooks/snackbar';
import { selectIsErrorDuringGeoCheck, selectIsGeoLocationAllowed } from '../../state/app';
import { useGlobalGameSession } from '../../hooks/games/useGlobalGameSession';

/**
 * Sets navigation expansion based on screen width on application load
 */
export const LayoutInit: FC = () => {
  const { addSnack } = useSnackbar();
  const geoLocationAllowed = useSelector(selectIsGeoLocationAllowed);
  const errorDuringGeoCheck = useSelector(selectIsErrorDuringGeoCheck);

  useGlobalGameSession();

  useEffect(() => {
    !geoLocationAllowed &&
      addSnack({
        type: 'error',
        message:
          'Sorry, you are not able to register from your current location. Please see our help pages for more info',
      });
  }, [geoLocationAllowed]);

  useEffect(() => {
    if (errorDuringGeoCheck) {
      addSnack({
        type: 'error',
        message: 'Something went wrong during ip check',
      });
    }
  }, [errorDuringGeoCheck]);

  return null;
};
