import { FC } from 'react';

type ConditionalWrapperProps = {
  condition: boolean;
  children: JSX.Element;
  wrapper: (argument: JSX.Element) => JSX.Element;
};

export const ConditionalWrapper: FC<ConditionalWrapperProps> = ({ condition, children, wrapper }) =>
  condition ? wrapper(children) : children;
