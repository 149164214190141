import React, { FunctionComponent, useState } from 'react';
import { AutoWithdrawalCard, AutoWithdrawalForm } from '../../../../GamblingControls';
import { Button } from '../../../../shared/Button';
import { PopUpHandler } from '../../../../shared/PopupHandler';
import './AutoWithdrawal.scss';

export type AutoWithdrawalProps = {
  /**
   * Whether the change password popup is open or closed
   */
  isOpen?: boolean;
  /**
   * Optional iconPath to display icon set in AEM.
   */
  myAccountSettingsAutoWithdrawalIconPath?: string | null;
};

export const AutoWithdrawal: FunctionComponent<AutoWithdrawalProps> = ({
  isOpen = false,
  myAccountSettingsAutoWithdrawalIconPath,
}) => {
  const [displayEditModal, setDisplayEditModal] = useState(isOpen);
  const [limitValue, setLimitValue] = useState<null | number>(null);
  const [isFocusOnInput, setIsFocusOnInput] = useState(false);

  const amountAbondon = (): void => {
    if (isFocusOnInput) {
      setIsFocusOnInput(false);
    }
  };

  // Toggle modal display
  const toggleModal = (): void => {
    setDisplayEditModal(!displayEditModal);

    if (displayEditModal) {
      amountAbondon();
    }
  };

  // Toggle modal display
  const onUpdate = (newValue: null | number | string): void => {
    const formattedValue = typeof newValue === 'string' ? parseFloat(newValue) : newValue;

    setLimitValue(formattedValue);

    if (displayEditModal) {
      setIsFocusOnInput(false);
    }

    setDisplayEditModal(false);
  };

  const onFormControlFocus = (): void => {
    setIsFocusOnInput(true);
  };

  return (
    <>
      <AutoWithdrawalCard
        onEdit={toggleModal}
        onUpdate={onUpdate}
        limitValue={limitValue}
        iconPath={myAccountSettingsAutoWithdrawalIconPath}
      />

      <PopUpHandler
        header="Auto-Withdrawal"
        headingProps={{ headingLevel: 3 }}
        modalProps={{ 'aria-label': 'Auto-Withdrawal' }}
        footer={
          <div className="u-action-footer">
            <Button variant="secondary" type="button" onClick={toggleModal}>
              CANCEL
            </Button>
            <Button type="submit" form="auto-withdrawal-form">
              UPDATE
            </Button>
          </div>
        }
        isOpen={displayEditModal}
        onClose={toggleModal}
      >
        <AutoWithdrawalForm onSubmit={onUpdate} onFormControlFocus={onFormControlFocus} />
      </PopUpHandler>
    </>
  );
};
