import flatten from 'lodash/flatten';
import { Player } from '@lottiefiles/react-lottie-player';
import React, { Fragment, useContext, useState, useMemo } from 'react';
import { RegistrationContext } from '../Registration.context';
import { MarketingData, RegistrationStepComponent, RegistrationStepConfig } from '../types';
import searchAnimation from '../../../assets/lotties/search.json';
import { useDeviceInfo } from '../../../hooks/useDeviceInfo';
import { Button } from '../../shared/Button';
import { Icon } from '../../shared/Icon';
import { RegistrationSignInLink } from '../RegistrationSignInLink/RegistrationSignInLink';
import { joinStrings } from '../../../utils/string';
import { Heading } from '../../shared/Heading';
import { InnerHTML } from '../../shared/InnerHTML';
import { FlattenedProfileData } from '../../../hooks/registration';
import { Form } from '../../shared/Form';
import { AccountDetailFieldSets } from '../../Account/AccountDetails';
import { getMarketingPreferences } from '../../../utils/marketing-preferences-helper';
import { optInString } from '../../../consts';
import './RegistrationFormStepActionsStyles';
import './DefaultFormStep.scss';
import pick from 'lodash/pick';
import { useTranslations } from '../../../hooks/useTranslationsHelper';

/**
 * A component to render registration form action buttons.
 */
const RegistrationFormStepActions = ({
  submitDisabled,
  stepConfig,
  onSelectPreviousStep,
  isSubmitting,
}: {
  submitDisabled?: boolean;
  stepConfig: RegistrationStepConfig;
  onSelectPreviousStep?: () => void;
  onSelectStep?: (arg: string) => void;
  setAbandonPromptOpen?: (arg: boolean) => void;
  isSubmitting?: boolean;
}): JSX.Element => {
  const { isMobileDevice } = useDeviceInfo();
  const { t } = useTranslations();

  const isAuthenticated = false;
  const isBackButtonEnabled = stepConfig.backEnabled;
  const isUserHaveAccountText = stepConfig.isUserHaveAccountText;

  const onSecordaryClick = (): void => {
    onSelectPreviousStep?.();
  };

  return (
    <div className="registration-form-step-actions">
      <div className="registration-form-step-actions__wrapper">
        <div className="registration-form-step-actions__submit">
          <Button
            variant={stepConfig.submissionStep ? 'success' : 'primary'}
            disabled={submitDisabled}
            type="submit"
            size="large"
            isSubmitting={isSubmitting}
          >
            {stepConfig.submitButtonText || t('buttons.next.uppercase')}
          </Button>
        </div>
        {isBackButtonEnabled && (
          <Button
            type="button"
            variant="text"
            onClick={onSecordaryClick}
            icon={<Icon variant="ArrowLeft" />}
            iconPosition="left"
            size="large"
          >
            {t('buttons.back')}
          </Button>
        )}
      </div>
      {!isMobileDevice && !isAuthenticated && isUserHaveAccountText && (
        <RegistrationSignInLink
          className={joinStrings([
            'registration-form-step-actions__sign-container',
            !isBackButtonEnabled && !isMobileDevice && 'registration-form-step-actions__sign-container--top-aligned',
          ])}
        />
      )}
    </div>
  );
};

/**
 * A registration form step component which will render a form with list of field sets displayed in a grid.
 *
 * Handles form validation so that onCompleteStep can only be called when validation constraints are satisfied.
 */
export const DefaultFormStep: RegistrationStepComponent = (registrationStepProps) => {
  const {
    stepConfig,
    stepIndex,
    formState,
    selected,
    children,
    stepperEnabled,
    buttonSize,
    headingLevel = 5,
    formChildren,
    onCompleteStep,
    onUnmount,
    onSelectPreviousStep,
    onFormValidityChange,
    onFormError,
    onSelectStep,
    setAbandonPromptOpen,
    validationMode,
    reValidateMode,
    errrorToClear,
    isSubmitting,
  } = registrationStepProps;
  const { onFormControlBlur, onSubmitSuccess, onSubmitError, onFormControlFocus } = useContext(RegistrationContext);
  const [isLoading, setIsLoading] = useState(false);
  const loadingTitle = 'Loading...';
  const id = `step-content-${stepConfig.id}`;
  const { fieldSets } = stepConfig;

  const marketingFieldSets = useMemo(() => {
    const marketingPreferencesData: MarketingData = {
      optInEmail: false,
      optInTelephone: false,
      optInSms: false,
    };

    return getMarketingPreferences(marketingPreferencesData, fieldSets);
  }, [fieldSets]);
  const fieldNames = flatten(marketingFieldSets.map((fieldSet) => fieldSet.fields)).map((field) => field.name);
  const marketingFieldNames = fieldNames.filter((fieldName) => fieldName.toLocaleLowerCase().startsWith(optInString));

  const handlePreviousStepClick = (): void => {
    onSelectPreviousStep?.();
  };

  return (
    <div
      className={joinStrings([
        'default-registration-form-step',
        selected && 'default-registration-form-step--selected',
      ])}
      id={id}
      data-testid={id}
      {...(stepperEnabled ? { role: 'tabpanel', 'aria-labelledby': `step-${stepConfig.id}` } : {})}
    >
      {/* TODO move into different step, reorganise to remove stepper step component when shown */}
      {isLoading && (
        <span className="default-registration-form__loading">
          <div
            dangerouslySetInnerHTML={{
              __html: '<style>.registration-stepper-step,.registration-form-step__enter{display:none}</style>',
            }}
          />
          <Heading level={4}>{loadingTitle}</Heading>
          <span className="default-registration-form__loading-image">
            <Player autoplay loop src={searchAnimation} />
          </span>
        </span>
      )}

      <div className={isLoading ? 'hidden' : ''}>
        <Heading level={headingLevel}>{stepConfig.title}</Heading>

        {stepConfig?.introText && (
          <span className="default-registration-form-step__intro-text">
            {typeof stepConfig.introText === 'string' ? (
              <InnerHTML text={stepConfig.introText} />
            ) : (
              stepConfig.introText
            )}
          </span>
        )}

        {children}

        <Form<Partial<FlattenedProfileData>>
          id={stepConfig.id + '-form'}
          onSubmit={async (d): Promise<void> => {
            if (stepConfig.id === 'marketing-details') {
              setIsLoading(true);
            } else {
              isLoading && setIsLoading(false);
            }

            await onCompleteStep?.(stepIndex, d);
            onSubmitSuccess?.(d);
          }}
          onUnmount={onUnmount}
          onValidationStateChange={onFormValidityChange}
          defaultValues={pick(formState, fieldNames)}
          {...{
            validationMode,
            reValidateMode,
          }}
          onFormControlBlur={(...args): void => onFormControlBlur?.(...args)}
          onFormControlFocus={(...args): void => onFormControlFocus?.(...args)}
          onError={onFormError}
          onSubmitError={(): void => {
            setIsLoading(false);
            onSubmitError?.();
          }}
          shouldUnregister={false}
          errrorToClear={errrorToClear}
          FormActions={({ submitDisabled }: { submitDisabled?: boolean }): JSX.Element => (
            <RegistrationFormStepActions
              {...{
                submitDisabled,
                onSelectPreviousStep: handlePreviousStepClick,
                stepConfig,
                buttonSize,
                onSelectStep,
                setAbandonPromptOpen,
                isSubmitting,
              }}
            />
          )}
        >
          {formChildren}
          <AccountDetailFieldSets
            fieldSets={stepConfig.fieldSets}
            formFields={registrationStepProps.formFields}
            marketingFieldNames={marketingFieldNames}
          />
          {stepConfig.preSubmitText && (
            <Fragment>
              {typeof stepConfig.preSubmitText === 'string' ? (
                <div data-testid="default-registration-pre-submit-text">
                  <InnerHTML text={stepConfig.preSubmitText} />
                </div>
              ) : (
                stepConfig.preSubmitText
              )}
            </Fragment>
          )}
        </Form>
      </div>
    </div>
  );
};
