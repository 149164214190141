import React from 'react';
import { IconType } from '@react-icons/all-files';

export const DownloadIcon: IconType = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.25 0.75C5.25 0.335787 5.58579 0 6 0C6.41421 0 6.75 0.335786 6.75 0.75V6.11832L9.18207 3.68626L10.2427 4.74692L6.00009 8.98956L1.75745 4.74692L2.81811 3.68626L5.25 6.11815V0.75Z"
      fill="#7a7a7a"
    />
    <path
      d="M0 7.5H1.5V10.5H10.5V7.5H12V10.5C12 11.3284 11.3284 12 10.5 12H1.5C0.671573 12 0 11.3284 0 10.5V7.5Z"
      fill="#7a7a7a"
    />
  </svg>
);
