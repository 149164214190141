import React, { FC } from 'react';
import './AccordionContent.scss';

/**
 * Very basic content wrapper component. No props as of yet.
 *
 * Content to be provided as component children.
 */
export const AccordionContent: FC<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <div data-testid="accordion-content" className="accordion-content">
      {children}
    </div>
  );
};
