import React, { Children, Fragment, FunctionComponent } from 'react';
import { Icon } from '../Icon/Icon';
import './styles';
import { TabularDatalistItem } from './TabularDatalistItem';
import { joinStrings } from '../../../utils/string';
import noResults from '../../../assets/Promotions/no-results.png';
import { useTranslations } from '../../../hooks/useTranslationsHelper';

export type TabularDataset = {
  /**
   * Accepts a component.  Will always be visible.
   */
  panel?: React.ReactNode;
  /**
   * Accepts a component.  Will be hidden until expanded on.
   */
  content?: React.ReactNode;
  isWithdrawPending?: boolean;
};

export type TabularDataListProps = {
  /**
   * Dataset that the the component consumes.
   */
  dataset?: TabularDataset[];
  /**
   * Allow consumer to control total results which can be different to displayed dataset.
   */
  totalResults: number;
  /**
   * If true, will render the count of the results.
   */
  showCount?: boolean;
  /**
   * If true, will render a spinner in the loading button.
   */
  isLoading?: boolean;
  /**
   * If true, clicking anywhere on the panel will toggle the show/hide of the content.
   */
  clickablePanel?: boolean;
  /**
   * If true, will render the "load more" button at the bottom of the list.
   */
  showLoadMore?: boolean;
  /**
   * If you need a custom "no results" message.  Accepts a component.
   */
  noResultsMessage?: React.ReactNode;
  /**
   * Callback function when clicking on the "load more" button.
   */
  onLoadMore?: () => void;
  /**
   * Callback function when clicking on the the expand buttons.  Returns index and state of a list item.
   */
  onExpand?: (index: number, state: boolean) => void | undefined;

  headDatalist?: string;
  forceUpdate?: boolean;
  setForceUpdate?: (setForceUpdate: boolean) => void;
  collapseItem?: boolean;
};

export const TabularDatalist: FunctionComponent<TabularDataListProps> = ({
  dataset = [],
  showCount = false,
  showLoadMore = false,
  clickablePanel = false,
  isLoading = false,
  noResultsMessage,
  totalResults,
  onLoadMore,
  onExpand,
  headDatalist,
  forceUpdate,
  setForceUpdate,
  collapseItem,
}) => {
  const { t } = useTranslations();

  return (
    <div className="tdl" data-testid="tdl-container" role="list">
      {(headDatalist || showCount) && (
        <div className="tdl__heading">
          {headDatalist}
          {showCount && (
            <p className="tdl__total-results" data-testid="tdl-total-results">
              {totalResults > 0
                ? `${totalResults} ${totalResults === 1 ? t('tabular-datalist.result') : t('tabular-datalist.results')}`
                : ''}
            </p>
          )}
        </div>
      )}

      {isLoading || totalResults > 0 ? (
        <ul>
          {Children.toArray(
            dataset.map((data, index) => {
              if (Object.keys(data).length === 0) return null;

              return (
                <TabularDatalistItem
                  {...data}
                  clickablePanel={clickablePanel}
                  onExpand={onExpand}
                  last={showLoadMore || isLoading ? false : index === dataset.length - 1}
                  index={index}
                  isWithdrawPending={data.isWithdrawPending}
                  forceUpdate={forceUpdate}
                  setForceUpdate={setForceUpdate}
                  collapseItem={collapseItem}
                />
              );
            })
          )}
        </ul>
      ) : (
        <Fragment>
          {noResultsMessage ? (
            <div data-testid="tdl-no-results">{noResultsMessage}</div>
          ) : (
            <>
              <div className="tdl__image-container">
                <img className="tdl__image" data-testid="tdl-no-results" src={noResults} alt="No results" />
              </div>

              <p className="tdl__total-results-text">
                {t('tabular-datalist.no-results')} <br />
                {t('tabular-datalist.no-results.description')}
              </p>
            </>
          )}
        </Fragment>
      )}

      {(showLoadMore || isLoading) && (
        <button
          disabled={isLoading}
          className={joinStrings(['tdl__more', 'tdl__more-btn', isLoading && 'tdl__more-btn--disabled'])}
          onClick={(): void => {
            onLoadMore && !isLoading && onLoadMore();
          }}
          data-testid="tdl-loadmore-btn"
        >
          {isLoading ? <Icon variant="Spinner" className="u-icon-spin" /> : 'Load more'}
        </button>
      )}
    </div>
  );
};
