import React, { FunctionComponent } from 'react';
import { GameFilterCategory } from './GamesFilter.types';
import { GameFilterCarousel } from '../GamesList';
import { useAuth } from 'react-oidc-context';
import { joinStrings } from '../../../utils/string';
import { ToggleButton } from '../../shared/ToggleButton';
import './GameCategoryOptions.scss';

type GameCategoryOptionsProps = {
  categories: GameFilterCategory[];
  value?: string | null;
  onChange?: (gameOptionValue: string, gameOption: GameFilterCarousel) => void;
  className?: string;
};

export const GameCategoryOptions: FunctionComponent<GameCategoryOptionsProps> = ({
  categories = [],
  value,
  onChange,
  className,
}) => {
  const auth = useAuth();
  const userAuthenticated = auth?.isAuthenticated;
  const authPropName = userAuthenticated ? 'authenticated' : 'unauthenticated';
  const allowedCategories = categories.filter((category) => category[authPropName]);

  const handleClick = (category: string, gameOptionValue: string, gameOption: GameFilterCarousel): void => {
    onChange?.(gameOptionValue, gameOption);
  };

  return (
    <div body-scroll-lock-ignore="true" className={joinStrings(['game-category-options', className])}>
      {allowedCategories.map(({ category, gameOptionValue, gameOption, label, iconPath }) => (
        <ToggleButton
          selected={value === gameOptionValue}
          value={gameOptionValue}
          onClick={(): void => handleClick(category, gameOptionValue, gameOption)}
          key={label}
          className="game-category-options__button"
          variant="secondary"
        >
          {iconPath && <img alt={label} className="game-category-options__icon" src={iconPath} />}
          {label}
        </ToggleButton>
      ))}
    </div>
  );
};
