import React, { FunctionComponent, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Link, NavLinkProps, useNavigate } from 'react-router-dom';
import { openWindow } from './helper';
import { NavigationLinkIcon } from './NavigationLinkIcon';
import { checkIsUrlExternal } from '../../../utils/links-helper/links-helper';
import { MediaBreakpoint, useBreakpointInfo, useDeviceInfo } from '../../../hooks/useDeviceInfo';
import { joinStrings } from '../../../utils/string';
import { Icon } from '../../shared/Icon';
import { setIsNavigationExpanded } from '../../../state/app/reducers';
import { CategoryOfUsers } from '../types';
import { sessionPreviousLocation } from '../../../utils/storage';
import './styles';

export enum NavigationLinkIcons {
  Bell = 'bell',
  Ball = 'ball',
  Cherry = 'cherry',
  Search = 'search',
  Profile = 'profile',
  HelpCenter = 'helpCenter',
  Gift = 'gift',
  IconPath = 'iconPath',
  Jackpot = 'jackpot',
  Casino = 'casino',
  Vip = 'vip',
}

export enum LinkTargetType {
  SameWindow = 'sameWindow',
  NewWindow = 'newWindow',
  NewTab = 'newTab',
}

export type NavigationLinkProps = {
  name: string;
  icon: string;
  activeIcon: string;
  linkText: string | null;
  useNavLink?: boolean;
  active?: boolean;
  iconPath?: string;
  label?: string;
  enableArrow?: boolean;
  /**
   * Rendered at the top right of the icon
   */
  iconBadge?: React.ReactNode;
  /**
   * If true, the expanded style will be shown for the link on tablet landscape widths and above
   */
  isExpanded: boolean;
  linkTarget?: LinkTargetType;
  showForUsers?: CategoryOfUsers;
} & Pick<NavLinkProps, 'to' | 'onClick' | 'className' | 'state'>;

const ExternalLink = ({ ...props }): JSX.Element => {
  return <a {...props}>{props.children}</a>;
};

ExternalLink.displayName = 'ExternalLink';

// TODO: update this component
export const NavigationLink: FunctionComponent<NavigationLinkProps> = ({
  name,
  icon,
  activeIcon,
  linkText,
  className,
  isExpanded,
  active = false,
  label,
  linkTarget,
  onClick,
  enableArrow,
  to,
  state,
}) => {
  const dispatch = useDispatch();
  const navLink = to.toString();
  const isExternalUrl = checkIsUrlExternal(navLink);
  const isBelowTabletLandWidth = useBreakpointInfo([MediaBreakpoint.BELOW_TABLET]);
  const { isMobileDevice, isTablet } = useDeviceInfo();
  const navigate = useNavigate();
  const elementRef = useRef(null);
  const isNewWindow = linkTarget?.includes('newTab');

  const handlePreviousUrl = (): void => {
    if (state) {
      sessionPreviousLocation.set({ value: state });
    }
  };

  const handleNavLinkClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    e.preventDefault();
    onClick && onClick(e);
    const isNavigateToLink = to && !isNewWindow && !isExternalUrl;

    // Closing side bar navigation panel before redirect
    if (isMobileDevice || isTablet) {
      dispatch(setIsNavigationExpanded(false));
      isNavigateToLink && setTimeout(() => navigate(to as string, { state }), 300);
      handlePreviousUrl();
    } else {
      isNavigateToLink && navigate(to as string, { state });
      handlePreviousUrl();
    }

    // Prevent reloading on each menu selection
    (isExternalUrl || !linkTarget?.includes('sameWindow')) && openWindow(navLink, linkTarget, e);
  };

  return (
    <Link
      ref={elementRef}
      data-testid={`navigation-${name}-link`}
      className={joinStrings([
        active && 'navigation-link--selected',
        (isExpanded || isBelowTabletLandWidth) && 'navigation-link--expanded',
        'navigation-link',
        className,
      ])}
      to={to}
      onClick={handleNavLinkClick}
      state={state}
    >
      <div
        className={isMobileDevice && !isExpanded ? 'navigation-link__wrapper-top-mobile' : 'navigation-link__wrapper'}
      >
        <div className="u-position-relative">
          <NavigationLinkIcon icon={icon} active={active} activeIcon={activeIcon || icon} label={label} />
        </div>

        <p className="navigation-link__text">{linkText}</p>
      </div>
      {enableArrow && (
        <div className="navigation-link__chevron-right">
          <Icon variant="ChevronRight" />
        </div>
      )}
      {isNewWindow && (
        <div className="navigation-link__external-link">
          <Icon variant="ExternalLink" />
        </div>
      )}
    </Link>
  );
};
