import { cookieSettings, vendors } from '../../../hooks/cookies/mocks';
import { storageConsent } from '../../../utils/storage';
import {
  ConsentEntry,
  ConsentEntryWithVendors,
  ConsentSettings,
  ConsentSubmission,
  ConsentVendorCookie,
  ConsentVendorId,
} from './types';

function extractUniqueVendorIds(array: ConsentEntry[]): number[] {
  const uniqueConsentSettingsIds: ConsentVendorId[] = [];

  array?.forEach((item: ConsentEntry) =>
    item?.vendorIds?.forEach((elem: ConsentVendorId) => {
      if (!uniqueConsentSettingsIds.includes(elem)) {
        uniqueConsentSettingsIds.push(elem);
      }
    })
  );

  return uniqueConsentSettingsIds;
}

export function getConsentSettings(): ConsentSettings {
  const configIds = vendors.map((item) => item.id);

  const consents = storageConsent.get() as ConsentSubmission;

  const storageConsentIds = extractUniqueVendorIds(consents?.consent);

  const newVendorIds: ConsentVendorId[] = [];

  configIds?.forEach((item) => {
    if (!storageConsentIds.includes(item)) {
      newVendorIds.push(item);
    }
  });

  const pendingReconsentWithEmptyElements = cookieSettings.map((item) => {
    return {
      type: item.type,
      userHasConsented: item.userHasConsented,
      alwaysActive: item.alwaysActive,
      vendorIds: item.vendorIds?.filter((vendorId) => newVendorIds.includes(vendorId)),
      // eslint-disable-next-line array-callback-return
      vendors: item.vendors?.filter((value) => {
        if (newVendorIds.includes(value.id)) {
          return {
            id: value.id,
            name: value.name,
            description: value.description,
            cookies: value.cookies.map((cookie: ConsentVendorCookie) => {
              return {
                name: cookie.name,
                isFirstParty: cookie.isFirstParty,
                isFirstPartyApex: cookie.isFirstPartyApex,
                domain: cookie.domain,
                duration: cookie.duration,
              };
            }),
          };
        }
      }),
    };
  });

  const pendingReconsent: ConsentEntryWithVendors[] = [];

  pendingReconsentWithEmptyElements.forEach((item) => {
    if (item.vendors.length > 0) {
      pendingReconsent.push(JSON.parse(JSON.stringify(item)));
    }
  });

  return {
    settings: cookieSettings,
    consentedOn: consents?.consentedOn,
    playerId: consents?.playerId,
    pendingReconsent: pendingReconsent,
  };
}
