import { parse, isValid, isBefore, isAfter } from 'date-fns';

/**
 * Will return a valid date if the date arg is valid otherwise undefined.
 * @param date A date string to be parsed, date object or undefined value to abstract away undefined checks in consumer.
 * @param dateFormat A date format string e.g. yyyy-MM-dd
 */
export function validDate(date: string | Date | undefined, dateFormat: string): Date | undefined {
  if (!date) return undefined;

  if (typeof date === 'string') {
    const parsedDate = parse(date, dateFormat, new Date());

    return isValid(parsedDate) ? parsedDate : undefined;
  }

  // Date instance
  return isValid(date) ? date : undefined;
}

/**
 * Return validation message when date is not parsed as valid.
 * @param date Date string, expected to be in provided dateFormat
 * @param dateFormat A date format string e.g. yyyy-MM-dd
 * @param message Message to be returned when date is deemed invalid
 */
export function validateDate(date: string, dateFormat: string, message: string): string | undefined {
  if (validDate(date, dateFormat)) return undefined;

  return message;
}

/**
 * Get a Validate record to validate dates in react hook form.
 * @param dateFormat  A date format string e.g. yyyy-MM-dd
 * @param message Message to be returned when date is deemed invalid
 */
export const dateValidator = (dateFormat: string, message: string): Record<string, unknown> => ({
  validDate: (date: string) => validateDate(date, dateFormat, message),
});

/**
 * Will return both dates if they are valid otherwise an empty array.
 * @param dateStrA
 * @param dateStrB
 * @param dateFormat
 */
function validDates(dateStrA: string, dateStrB: string, dateFormat: string): Date[] | [] {
  const dateA = validDate(dateStrA, dateFormat);

  if (!dateA) return [];

  const dateB = validDate(dateStrB, dateFormat);

  if (!dateB) return [];

  return [dateA, dateB];
}

/**
 * Validate that the provided date is not after the maxDate. Returns the message arg if invalid.
 * @param date A date string
 * @param maxDate Maximum date
 * @param dateFormat A date format string e.g. yyyy-MM-dd
 * @param message Message to be returned when date is deemed invalid
 */
export function validateDateLTE(
  date: string,
  maxDate: string,
  dateFormat: string,
  message: string
): string | undefined {
  const dates = validDates(date, maxDate, dateFormat);

  return dates.length === 2 && isAfter(dates[0], dates[1]) ? message : undefined;
}

/**
 * Get a Validate record to validate a date less than or equal to max date in react hook form.
 * @param dateFormat A date format string e.g. yyyy-MM-dd
 * @param maxDate A maximum date string value to compare date value against
 * @param message Message to be returned when date is deemed invalid
 */
export const dateLTEValidator = (
  dateFormat: string,
  maxDate: string | undefined,
  message: string | undefined
): Record<string, unknown> =>
  maxDate && message
    ? {
        dateLessThanEqual: (date: string) => validateDateLTE(date, maxDate, dateFormat, message),
      }
    : {};

/**
 * Validate that the provided date is not before the minDate. Returns the message arg if invalid.
 * @param date A date string
 * @param minDate Minimum date
 * @param dateFormat A date format string e.g. yyyy-MM-dd
 * @param message Message to be returned when date is deemed invalid
 */
export function validateDateGTE(
  date: string,
  minDate: string,
  dateFormat: string,
  message: string
): string | undefined {
  const dates = validDates(date, minDate, dateFormat);

  return dates.length === 2 && isBefore(dates[0], dates[1]) ? message : undefined;
}

/**
 * Get a Validate record to validate a date greater than or equal to min date in react hook form.
 * @param dateFormat  A date format string e.g. yyyy-MM-dd
 * @param minDate A minimum date string value to compare date value against
 * @param message Message to be returned when date is deemed invalid
 */
export const dateGTEValidator = (
  dateFormat: string,
  minDate: string | undefined,
  message: string | undefined
): Record<string, unknown> =>
  minDate && message
    ? {
        dateGreaterThanEqual: (date: string) => validateDateGTE(date, minDate, dateFormat, message),
      }
    : {};
