import React, { Children, FunctionComponent, useRef, useState } from 'react';
import { Icon } from '../Icon';
import { IconButton } from '../IconButton';
import { Overlay, OverlayProps } from '../Overlay';
import { ModalContext } from './ModalContext';
import { joinStrings } from '../../../utils/string';
import { withPropValidation } from '../../../utils/withPropValidation/withPropValidation';
import './Modal.scss';

export type ModalSizes = 'small' | 'medium' | 'large' | 'full-screen';

export type ModalProps = {
  /**
   * Optional. Size of the modal, defaults to medium
   */
  size?: ModalSizes;
  /**
   * Optional, defaults to true.
   * Set to false to stop the modal closing when the backdrop is clicked or ESC key is pressed,
   * and to prevent a close button being rendered.
   */
  canDismiss?: boolean;
  showCloseBtn?: boolean;
  /**
   * Required if canDismiss is false. Ref of element should receive focus when the modal opens.
   * According to WAI-ARIA Authoring Practices '[When a dialog opens]
   * In all circumstances, focus moves to an element contained in the dialog.'
   * There is information here https://www.w3.org/TR/wai-aria-practices-1.1/#keyboard-interaction-7
   * about which element you should choose.
   * This is not needed & will be ignored if canDismiss is true,
   * as the component will already know the first focusable element will be the modal close button.
   */
  elementToFocusOnOpen?: React.RefObject<HTMLElement>;
  /**
   * Optional node to render after modal content.  An example use case would be an expansion notification.
   */
  postModalElement?: React.ReactNode;
  /**
   * Optional, defaults to 'none'. If set to dense it will apply extra padding to the modal content.
   * This can be useful for form modals where the padding provided by the Modal child components  (e.g.) `ModalBody`
   * does not give sufficient white-space.
   */
  padding?: 'dense' | 'none';
  /**
   * Function that executes when the modal requests to close,
   * (via clicking on overlay, pressing ESC or by clicking the top right close button)
   * Note: It is not called if isOpen is changed by other means.
   * Required for dismissible modals - should at least set the 'isOpen' prop to false
   */
  onRequestClose?: () => void;
  /**
   * An alternative function that runs when clicking the close button specifically.
   * Example use case: when you want the X button to act as a back button without closing the modal.
   */
  onCloseBtn?: () => void;
} & OverlayProps;

/**
 * A Modal component for displaying content on top of the main application window.
 * It uses the `Overlay` component internally which makes use of a 3rd party library called
 * [React-modal](http://reactcommunity.org/react-modal/) which adds support for
 * layering, key bindings, focus trapping, focus restoring and hiding background content from screenreaders.
 * Also shows a close button for dismissable modals
 * and can display a `postModalElement` for things like an expansion notification.
 *
 * The Modal component has 3 sub-components available to wrap content in:
 * `ModalHeader`, `ModalBody` and `ModalFooter`. Using these is not required
 * but they will apply consistent spacing/styles to the content.
 *
 * ## Example Usage - Simple
 *
 * ```tsx
 * const ModalExample = () => {
 *   const [modalOpen, setModalOpen] = useState(false);
 *   return (
 *     <Fragment>
 *       <Button onClick={() => setModalOpen(true)}>Open Modal</Button>
 *       <Modal
 *         isOpen={modalOpen}
 *         onRequestClose={() => setModalOpen(false)}
 *         aria-label='Login to your account'>
 *         Login to your account
 *       </Modal>
 *     </Fragment>
 *   );
 * };
 * ```
 *
 * ## Example Usage - With sub-components
 * ```tsx
 * <Modal>
 *   <ModalHeader heading='Terms and Conditions'/>
 *   <ModalBody>
 *     These are our new terms and conditions, do you accept?
 *   </ModalBody>
 *   <ModalFooter>
 *     <Button onClick={acceptTerms}>Yes</Button>
 *     <Button onClick={cancel}>No</Button>
 *   </ModalFooter>
 * </Modal>
 * ```
 *
 */
const Component: FunctionComponent<ModalProps> = ({
  size = 'medium',
  canDismiss = true,
  showCloseBtn = true,
  className,
  children,
  postModalElement,
  elementToFocusOnOpen,
  padding = 'none',
  onRequestClose,
  onCloseBtn,
  backdropClassName,
  ...overlayProps
}) => {
  const modalCloseButtonRef = useRef<HTMLButtonElement>(null);
  const childrenLength = Children.toArray(children).filter(Boolean).length;
  const [overflowTargetElement, setOverflowTargetElement] = useState<HTMLElement | null>(null);

  const closeButton = (): void => {
    if (onCloseBtn) {
      onCloseBtn();
    } else {
      onRequestClose?.();
    }
  };

  return (
    <Overlay
      {...overlayProps}
      canDismiss={canDismiss}
      onRequestClose={onRequestClose}
      elementToFocusOnOpen={elementToFocusOnOpen || modalCloseButtonRef}
      overflowTarget={overflowTargetElement}
      className={joinStrings([
        'modal',
        `modal--${size}`,
        padding === 'dense' && 'modal--padding-dense',
        childrenLength > 2 && 'modal--with-footer',
        className,
      ])}
      backdropClassName={joinStrings(['modal-backdrop', backdropClassName])}
    >
      <div className={joinStrings(['modal__container', postModalElement && 'modal__container--with-post-element'])}>
        {canDismiss && showCloseBtn && (
          <div className="modal__close-btn">
            <IconButton
              ref={modalCloseButtonRef}
              onClick={closeButton}
              aria-label="modal-close-btn-label"
              icon={<Icon variant="Close" />}
              size="large"
            />
          </div>
        )}
        <ModalContext.Provider
          value={{
            setOverflowTargetElement,
          }}
        >
          {children}
        </ModalContext.Provider>
      </div>
      {postModalElement}
    </Overlay>
  );
};

Component.displayName = 'Modal';

export const Modal = withPropValidation({
  Component,
  validationRules: [
    {
      message:
        // eslint-disable-next-line max-len
        'For accessibility purposes, modals require the elementToFocusOnOpen prop to be set when canDismiss is set to false.',
      logType: 'error',
      validate: ({ canDismiss = true, elementToFocusOnOpen }: ModalProps): boolean => {
        return !canDismiss && !elementToFocusOnOpen;
      },
    },
  ],
});
