import React, { forwardRef } from 'react';
import { GameItemUIComponent, GameItemUIProps } from '../GamesList.types';
import { Button } from '../../../shared/Button';
import './GameItem.scss';

/**
 * A presentational component for Game items.
 *
 * ### Example Usage
 *
 * ```tsx
 * <GameItem
 *  game={game}
 *  showGameName
 *  gameUrl='https://demo.com/play-game.html'
 *  fallbackImageAltText='Fallback image description'
 *  fallbackImageUrl='https://fallback.com/fallback.jpg'
 *  {...{ onPlayDemo, onPlayGame, onViewGameInfo }}
 * />
 * ```
 */
export const GameItem: GameItemUIComponent = forwardRef<HTMLAnchorElement, GameItemUIProps>(
  (
    {
      game,
      fallbackImageAltText,
      fallbackImageUrl,
      infoIconVert = 'bottom',
      disableMenu = false,
      onViewGameInfo,
      onPlayGame,
      onPlayDemo,
      size = 'medium',
      gameUrl,
      showGameName,
    },
    ref
  ) => {
    return (
      <div data-testid="game-item" className={`game-item game-item--size-${size}`}>
        {/* TODO: update it */}
        <div className="game-item__content" style={{ backgroundImage: `url(url)` }} aria-label="alt">
          {!disableMenu && (
            <div className="game-item__overlay">
              <div className="game-item__actions">
                <Button
                  data-testid="play-game-btn"
                  disabled={false}
                  variant="primary"
                  size="auto"
                  type="button"
                  id={'product-tile-basic-buy-ticket-button-' + game.gameId}
                  aria-label={game.displayName}
                  onClick={onPlayGame}
                >
                  Play
                </Button>
                <Button
                  data-testid="play-demo-btn"
                  disabled={false}
                  variant="secondary"
                  size="auto"
                  type="button"
                  id={'product-tile-basic-learn-more-button-' + game.gameId}
                  aria-label={game.displayName}
                  onClick={onPlayDemo}
                >
                  Demo
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
);
