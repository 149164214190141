import React, { FunctionComponent } from 'react';
import { BudgetLimitsTransactionType } from '../../../types.__generated__';
import { PopUpHandler } from '../../shared/PopupHandler';
import { Paragraph } from '../../shared/Paragraph';
import { Heading } from '../../shared/Heading';
import { FormControlSpacing } from '../../shared/FormControlSpacing';
import { Button } from '../../shared/Button';
import { Icon } from '../../shared/Icon';
import { useTranslations } from '../../../hooks/useTranslationsHelper';
import { Trans } from '../../shared/Trans';

type AccountLimitsHowItWorksProps = {
  onClose: () => void;
  isOpen: boolean;
  backButton?: true;
  activeType?: BudgetLimitsTransactionType;
  onClickBack?: () => void;
};

const AccountLimitsHowItWorks: FunctionComponent<AccountLimitsHowItWorksProps> = ({
  onClose,
  isOpen,
  activeType,
  backButton,
  onClickBack,
}) => {
  const { t } = useTranslations();
  const title = activeType === BudgetLimitsTransactionType.Deposit ? t('limits.deposit') : t('limits.loss');
  const lowerCasedTitle = title.toLowerCase();

  return (
    <PopUpHandler
      isOpen={isOpen}
      onClose={onClose}
      drawerProps={{ backdropCanClose: true }}
      headingProps={{ headingLevel: 5 }}
      modalProps={{
        'aria-label': `${title} limits`,
      }}
      header={t('limits.how-it-works.title', { lowerCasedTitle })}
    >
      <Paragraph>{t('limits.allow-message.pt1', { title })}</Paragraph>
      <Paragraph>{t('limits.allow-message.pt2', { lowerCasedTitle })}</Paragraph>
      <Paragraph>{t('limits.allow-message.pt3')}</Paragraph>

      <Heading level={5} className="account-limits__sub-heading">
        {t('limits.reset-message')}
      </Heading>
      <Trans keyProp="limits.reset-explanation">
        <Paragraph>Daily Limits reset at 23:59.</Paragraph>
        <Paragraph>Weekly Limits reset on Sunday at 23:59.</Paragraph>
        <Paragraph>Montly Limits reset on the last day of the month.</Paragraph>
      </Trans>
      {backButton && (
        <>
          <FormControlSpacing />
          <Button
            iconPosition="left"
            icon={<Icon variant="ArrowLeft" />}
            type="button"
            size="large"
            variant="text"
            onClick={onClickBack}
          >
            {t('buttons.back')}
          </Button>
          <FormControlSpacing />
        </>
      )}
    </PopUpHandler>
  );
};

export default AccountLimitsHowItWorks;
