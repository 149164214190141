import React, { ComponentPropsWithoutRef, FC } from 'react';
import { TypographyTextSize } from '../Typography';
import { joinStrings } from '../../../utils/string';
import './Paragraph.scss';

export type ParagraphTextSize = TypographyTextSize;
export type ParagraphProps = {
  /**
   * Optional Paragraph text size.  Defaults to 'md'.
   */
  size?: ParagraphTextSize;
  /**
   * Optionally remove default margin.
   */
  noMargin?: boolean;
  /**
   * Optionally apply highlight styles to the paragraph.
   * Highlight styles could be customised per site, but by default will make the text bolder.
   */
  highlight?: boolean;
} & ComponentPropsWithoutRef<'p'>;

/**
 * A component used to render a paragraph element with consistent styling applied.
 *
 * ### Example
 *
 * ```tsx
 *   <Paragraph>Some text content</Heading>
 * ```
 */
export const Paragraph: FC<ParagraphProps> = ({ children, size = 'md', noMargin, className, highlight, ...props }) => {
  return (
    <p
      {...props}
      className={joinStrings([
        `paragraph paragraph--${size}`,
        noMargin && 'paragraph--no-margin',
        highlight && 'paragraph--highlight',
        className,
      ])}
    >
      {children}
    </p>
  );
};
