import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TranslationGetAllQueryVariables = Types.Exact<{
  language: Types.Scalars['String']['input'];
}>;


export type TranslationGetAllQuery = { __typename?: 'Query', translations_translations: Array<{ __typename?: 'translations_translations', translations_id?: { __typename?: 'translations', translations_key?: string | null, translations?: Array<{ __typename?: 'translations_translations', content?: string | null } | null> | null } | null }> };


export const TranslationGetAllDocument = gql`
    query TranslationGetAll($language: String!) {
  translations_translations(filter: null) {
    translations_id {
      translations_key
      translations(
        filter: {languages_language_code: {language_code: {_eq: $language}}}
      ) {
        content
      }
    }
  }
}
    `;

/**
 * __useTranslationGetAllQuery__
 *
 * To run a query within a React component, call `useTranslationGetAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useTranslationGetAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTranslationGetAllQuery({
 *   variables: {
 *      language: // value for 'language'
 *   },
 * });
 */
export function useTranslationGetAllQuery(baseOptions: Apollo.QueryHookOptions<TranslationGetAllQuery, TranslationGetAllQueryVariables> & ({ variables: TranslationGetAllQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TranslationGetAllQuery, TranslationGetAllQueryVariables>(TranslationGetAllDocument, options);
      }
export function useTranslationGetAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TranslationGetAllQuery, TranslationGetAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TranslationGetAllQuery, TranslationGetAllQueryVariables>(TranslationGetAllDocument, options);
        }
export function useTranslationGetAllSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TranslationGetAllQuery, TranslationGetAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TranslationGetAllQuery, TranslationGetAllQueryVariables>(TranslationGetAllDocument, options);
        }
export type TranslationGetAllQueryHookResult = ReturnType<typeof useTranslationGetAllQuery>;
export type TranslationGetAllLazyQueryHookResult = ReturnType<typeof useTranslationGetAllLazyQuery>;
export type TranslationGetAllSuspenseQueryHookResult = ReturnType<typeof useTranslationGetAllSuspenseQuery>;
export type TranslationGetAllQueryResult = Apollo.QueryResult<TranslationGetAllQuery, TranslationGetAllQueryVariables>;