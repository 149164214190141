import React, { FC, useRef } from 'react';

import { GameCardContainerType } from '../GameCard.types';
import { Heading } from '../../../shared/Heading';
import { joinStrings } from '../../../../utils/string';
import { SimpleGameCardLabel } from '../../../shared/SimpleGameCardLabel';
import { DynamicImage } from '../../../shared/DynamicImage';
import './GameCardBasic.scss';

interface ImageWrapperProps {
  children: React.ReactNode;
  displayName?: string;
  showDisplayName?: boolean;
}

const ImageWrapper: FC<ImageWrapperProps> = ({ children, displayName, showDisplayName }) => (
  <div className="game-card-basic__inner">
    <div className="game-card-basic__gradient" />
    {children}
    {displayName && showDisplayName && (
      <div className="game-card-basic__title" data-testid="game-card-basic-title">
        <Heading level={3} styleAs={3} style={{ color: 'inherit' }}>
          {displayName}
        </Heading>
      </div>
    )}
  </div>
);

interface GameCardBasicProps {
  /**
   * Card container type.
   */
  containerType: GameCardContainerType;
  /**
   * if true, applies an opacity effect over the image.
   */
  disabled: boolean;
  /**
   * Card image
   */
  image?: { url: string; alt: string };
  /**
   * Display name
   */
  displayName?: string;
  /**
   * Show display name
   */
  showDisplayName?: boolean;
  /**
   * Fallback image url if a suitable one can not be found in the Game object.
   */
  fallbackImageUrl?: string;
  /**
   * Fallback alt text if a suitable one can not be found in the Game object.
   */
  fallbackImageAltText?: string;
  /**
   * Call back for when user clicks on the card
   */
  onClick?: (e: React.MouseEvent | React.KeyboardEvent) => void;
  /**
   * show label on game card
   */
  gameLabel?: string;
}

export const GameCardBasic: FC<GameCardBasicProps> = ({
  containerType,
  image,
  disabled,
  displayName,
  showDisplayName,
  fallbackImageAltText,
  onClick,
  gameLabel,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  // const isVisible = useElementOnScreen(ref); Left for future optimization

  return (
    <>
      {gameLabel && <SimpleGameCardLabel labelText={gameLabel} />}
      <div
        ref={ref}
        role={onClick ? 'button' : ''}
        tabIndex={onClick ? 0 : -1}
        data-testid="game-card-basic"
        className={joinStrings([
          'game-card-basic__container',
          `game-card-basic__container--${containerType}`,
          onClick && `game-card-basic__container--button`,
        ])}
        onClick={onClick}
        onKeyDown={(e): void => {
          if (onClick && (e.key === 'Enter' || e.key === ' ')) {
            e.preventDefault();
            onClick(e);
          }
        }}
      >
        {/* Render inner game card content only if it's vivible or render just stubs if games are loading */}
        {displayName === 'skeleton-tile' ? (
          <ImageWrapper displayName={displayName} showDisplayName={showDisplayName}>
            <div
              className={joinStrings([
                'game-card-basic__image--skeleton',
                // isVisible && 'game-card-basic__image--skeleton--animated',
              ])}
            />
          </ImageWrapper>
        ) : (
          <ImageWrapper displayName={displayName} showDisplayName={showDisplayName}>
            <DynamicImage
              containerRef={ref}
              src={image?.url}
              props={{
                alt: image?.alt || fallbackImageAltText || '',
              }}
              className={joinStrings(['game-card-basic__image', disabled && 'game-card-basic__image--disabled'])}
              loadingClassName={joinStrings([
                'game-card-basic__image game-card-basic__image--skeleton game-card-basic__image--skeleton--animated',
                disabled && 'game-card-basic__image--disabled',
              ])}
            />
          </ImageWrapper>
        )}
      </div>
    </>
  );
};
