import React from 'react';
import first from 'lodash/first';
import tail from 'lodash/tail';
import { joinStrings } from './string';

export const applyWideBanner = (
  groupedBanner: (JSX.Element | JSX.Element[])[] | undefined
): {
  banner?: JSX.Element | JSX.Element[];
  slidesUnderBanner?: JSX.Element | JSX.Element[];
  slidesWithoutBanner: (JSX.Element | JSX.Element[])[];
} => {
  const banner = first(groupedBanner);
  const slidesWithoutBanner = tail(groupedBanner);
  const slidesUnderBanner = first(slidesWithoutBanner);

  return { banner, slidesUnderBanner, slidesWithoutBanner };
};

export const renderWideTileCarousel = ({
  isBanner,
  slidesWithoutBanner,
  slidesUnderBanner,
  banner,
}: {
  isBanner?: boolean;
  slidesUnderBanner: JSX.Element | JSX.Element[];
  slidesWithoutBanner: (JSX.Element | JSX.Element[])[];
  banner?: JSX.Element | JSX.Element[];
}): React.ReactNode[] =>
  slidesWithoutBanner.map((slide, index) => {
    const firstSlide = index === 0;

    return (
      (
        <div key={index} className={joinStrings(['game-cards', isBanner && 'game-cards--banner'])}>
          {isBanner && (
            <div className="game-cards--headed-slide">
              <div className="game-cards--card-banner game-card">{firstSlide && banner}</div>
              <div className="game-cards--under-banner">{firstSlide && slidesUnderBanner}</div>
            </div>
          )}
          {isBanner && !firstSlide && slide}
        </div>
      ) || null
    );
  });
