import equals from 'lodash/fp/equals';
import filter from 'lodash/fp/filter';
import map from 'lodash/fp/map';
import pick from 'lodash/fp/pick';
import pipe from 'lodash/fp/pipe';
import prop from 'lodash/fp/prop';
import sortBy from 'lodash/fp/sortBy';
import sumBy from 'lodash/fp/sumBy';
import over from 'lodash/fp/over';
import zipObj from 'lodash/fp/zipObj';
import head from 'lodash/fp/head';
import anyPass from 'lodash/fp/anyPass';
import { isFuture, parseISO } from 'date-fns';
import { PlayerWallets } from '../models/player-profile.model';
import { currencyCode, localeCurrencyCode } from '../consts';

export type WalletPredicate = (a: Partial<PlayerWallets>) => boolean;
export type WalletsMapper = (a: Partial<PlayerWallets>[]) => Partial<PlayerWallets>[];
export const sortByExpiryDate: WalletsMapper = sortBy(pipe(prop('expiryDate'), parseISO));
export const isExpiryDateInFuture: WalletPredicate = pipe(prop('expiryDate'), parseISO, isFuture);
export const isExpiryDateNull: WalletPredicate = pipe(prop('expiryDate'), equals(null));
export const isExpiryDateAllowed: WalletPredicate = anyPass([isExpiryDateInFuture, isExpiryDateNull]);

export const isWalletType = (type: string): WalletPredicate => pipe(prop('walletType'), equals(type));

export const isRewardWallet: WalletPredicate = isWalletType('Reward');

export const getRewards = pipe(
  // Filter expired
  filter(isExpiryDateAllowed),

  // Filter Reward wallet
  filter(isRewardWallet),

  // Sort by expiry date
  sortByExpiryDate,
  (any) => [any],
  filter('length'),

  // normilize
  map(
    pipe(
      over([sumBy('balance'), pipe(head, prop('expiryDate')), map(pick(['expiryDate', 'balance']))]),
      zipObj(['total', 'expiryDate', 'rewards'])
    )
  )
);

type LocaleParts = {
  currency: string; // eg £
  group: string; // eg ,
  decimal: string; // eg .
  symbolPosition: number; // -1 for the beginning, 1 for the end
};

/**
 * Get locale number formatting parts definitition.
 */
export const localeParts = (): LocaleParts => {
  return Intl.NumberFormat(localeCurrencyCode, {
    style: 'currency',
    currency: 'GBP',
  })
    .formatToParts(11111.99)
    .reduce((format, part, index, parts) => {
      if (['currency', 'group', 'decimal'].includes(part.type)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        format[part.type] = part.value;
      }

      if (part.type === 'currency') {
        format.symbolPosition = index === parts.length - 1 ? 1 : 0;
      }

      return format;
    }, {} as LocaleParts);
};

/**
 * Parse a currency string and return a shortend version.
 * i.e. shortFormatCurrency('$10,000.00') -> '$10.0k'
 *      shortFormatCurrency('€10,500.00') -> '€10.5k'
 *      shortFormatCurrency('$100,000,000.00') -> '$100m'
 *
 * @param {string} amountStr - the localized number string (eg £1,234.56)
 */
const shortFormatCurrency = (amountStr: string, fixedAt = 0): string => {
  const { decimal, group } = localeParts();
  // Regex to match number formating using the current locale characters
  const matchNumber = new RegExp(`[0-9\\${decimal}\\${group}]+`, 'g');

  // Pluck the number from amountStr, remove decimals and number group seperators (eg 10000 from $10,000.99)
  const numberString = (amountStr.match(matchNumber) || [''])[0]
    .split(decimal)[0]
    .replace(new RegExp(`[\\${group}]+`, 'g'), '');

  // Convert string to number
  const number = Number(numberString);

  // Formats for different value amounts
  const formats = [
    { value: 1000000000000, symbol: 't' }, // trillions
    { value: 1000000000, symbol: 'b' }, // billions
    { value: 1000000, symbol: 'm' }, // millions
    { value: 1000, formatFrom: 10000, symbol: 'k' }, // tens of thousands
  ];

  // less then thousands (catch all)
  let formattedString = amountStr;

  // Loop down through formatting amounts until a match is found
  for (let i = 0; i < formats.length; i++) {
    const { formatFrom, value, symbol } = formats[i];

    // Handle special case for only formatting thousands 10k and above
    if (number >= (formatFrom || value)) {
      const amount = (number / value).toFixed(fixedAt); // reduce 10000 to 10

      formattedString = amountStr.replace(matchNumber, `${amount}${symbol}`);

      break;
    }
  }

  return formattedString;
};

/**
 * Formats currency according to the locale and currencyCode set in the AppConfig.
 * @param val Value to format.  Accepts undefined in case data quality is untrusted
 * @param signDisplay Positive/negative sign display option
 * @param decimal Display option for decimals
 * @param seperator Display option for seperators
 * Note: Cannot use implemented signDisplay option as options other than 'auto' are currently not supported in Safari.
 */
export const formatCurrency = (
  val: number | undefined,
  signDisplay: 'auto' | 'exceptZero' = 'auto',
  decimal: 'show' | 'hide' | 'auto' = 'show',
  seperator: 'show' | 'hide' = 'show',
  options?: {
    shortFormat: boolean;
  }
): string | undefined => {
  if (val === undefined) {
    return (0)
      .toLocaleString(localeCurrencyCode, {
        style: 'currency',
        currency: currencyCode,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, '')
      .trim();
  }

  const hasDecimal = val % 1 !== 0;
  const formatValue = decimal === 'hide' ? Math.trunc(val) : val;

  const formatter = new Intl.NumberFormat(localeCurrencyCode, {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: decimal === 'show' || (decimal === 'auto' && hasDecimal) ? 2 : 0,
    useGrouping: seperator === 'show',
  });

  // Add space between the currency symbol and amount for both cases if symbol is before or after an amount
  const formattedValue = `${signDisplay === 'exceptZero' && formatValue > 0 ? '+' : ''}${formatter
    .format(formatValue)
    .replace(/^([\d,.]+)/, '$1 ')
    .replace(/([\d,.]+)$/, ' $1')}`;

  if (options?.shortFormat) {
    return shortFormatCurrency(formattedValue);
  }

  return formattedValue;
};

/**
 * Parse a localized currency string to a number.
 * @param {string} stringNumber - the localized number string (eg £1,234.56)
 */
export const parseLocaleNumber = (stringNumber: string): number => {
  const { currency, group, decimal } = localeParts();

  return parseFloat(
    stringNumber.replace(new RegExp(`[\\${currency}\\${group}]`, 'g'), '').replace(new RegExp(`[\\${decimal}]`), '.')
  );
};
