import { createContext, useContext } from 'react';
import { CookiesPreferenceContentPayload } from './CookiesPreferencesContent';

export type CookiePreferencesContextProps = {
  isConfigReady: boolean;
  isTargeted: boolean;
  isEssential: boolean;
  isAccepting: boolean;
  isRejecting: boolean;
  isFunctional: boolean;
  isSubmitting: boolean;
  isPerformance: boolean;
  activeCategory: string;
  isDetailPageDisplayed: boolean;
  setIsSubmitting: (value: boolean) => void;
  setIsRejecting: (value: boolean) => void;
  setIsTargeted: (value: boolean) => void;
  setIsEssential: (value: boolean) => void;
  setIsFunctional: (value: boolean) => void;
  setIsAccepting: (value: boolean) => void;
  setIsPerformance: (value: boolean) => void;
  setActiveCategory: (value: string) => void;
  setIsDetailPageDisplayed: (value: boolean) => void;
  onConfirm: () => Promise<void>;
  onReject: () => Promise<void>;
  onSave: (payload: CookiesPreferenceContentPayload) => Promise<void>;
};

export const CookiePreferencesContext = createContext<CookiePreferencesContextProps>(
  {} as CookiePreferencesContextProps
);

export const useCookiePreferencesContext = (): CookiePreferencesContextProps => useContext(CookiePreferencesContext);
