type Subscriber = () => void;

/**
 * Helper constructor to share a state between hooks
 *
 * Example
 * const [count, setCount] = useState(0);
 * const prevCount = usePreviousState(count);
 *
 * type ExampleState = {
 *   active: boolean;
 * }
 *
 * const exampleState = new SharedState<ExampleState>({
 *   active: false
 * });
 *
 * export function useExample() {
 *   const [, setState] = useState<{}>();
 *   const {active} = exampleState.getState();
 *
 *   // This will be called when the snack state changes and force a render
 *   function reRender() {
 *     setState({});
 *   }
 *
 *   useEffect(() => {
 *     // Subscribe to a global state when a component mounts
 *     exampleState.subscribe(reRender);
 *
 *     return () => {
 *       // Unsubscribe from a global state when a component unmounts
 *       exampleState.unsubscribe(reRender);
 *     };
 *   });
 *
 *   const setActive = () => {
 *     scrollingState.setState({
 *       ...exampleState.getState(), // Best to use getState to avoid cached state values being re set
 *       active: true
 *     });
 *   };
 *
 *   const isActive = () => active;
 *
 *   return { isActive, setActive };
 * }
 */

export class SharedState<T> {
  state: T;
  subscribers: Subscriber[];

  constructor(state: T) {
    this.state = state;
    this.subscribers = [];
  }

  getState(): T {
    return this.state;
  }

  setState(newState: T): void | undefined {
    /* istanbul ignore if */
    if (this.getState() === newState) {
      return;
    }

    this.state = newState;

    // Notify subscribers that the global state has changed
    this.subscribers.forEach((subscriber: Subscriber) => {
      subscriber();
    });
  }

  subscribe(itemToSubscribe: Subscriber): void | undefined {
    /* istanbul ignore if */
    if (this.subscribers.indexOf(itemToSubscribe) > -1) {
      return; // Already subsribed
    }

    this.subscribers.push(itemToSubscribe);
  }

  unsubscribe(itemToUnsubscribe: Subscriber): void {
    this.subscribers = this.subscribers.filter((subscriber: Subscriber) => subscriber !== itemToUnsubscribe);
  }
}
