import { useEffect, useCallback } from 'react';
import { useAuth } from 'react-oidc-context';
import { useIdle } from '../useIdle';
import useDestroyUserSession from './useDestroyUserSession';

export const useAuthController = (): void => {
  const auth = useAuth();
  const { logoutUser } = useDestroyUserSession();

  useEffect(() => {
    if (!auth.user) {
      return;
    }

    if (auth.user.expired && !auth.isLoading) {
      console.log('[AuthController]', 'Auth Expired, signing back in...');

      auth.signinSilent().catch((e) => {
        console.log('[AuthController]', 'Signing out due to error signing in...', e);
        logoutUser();
      });

      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  useIdle(
    30000,
    useCallback(
      (isIdle: boolean) => {
        if (!isIdle) {
          console.log('[AuthController]', 'Starting silent renew as user has returned');

          auth.startSilentRenew();

          return;
        }

        console.log('[AuthController]', 'Stopping silent renew as user is idle');
        auth.stopSilentRenew();
      },
      [auth]
    )
  );
};
