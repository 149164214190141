import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setGameLaunchUrl } from '../../../../state/game/reducers';
import { joinStrings } from '../../../../utils/string';
import { Button } from '../../../shared/Button';
import { ExitIcon, SearchIcon } from '../../../shared/SVG/Icons';
import { storageRoute } from '../../../../utils/storage';
import { APP_URLS } from '../../../../consts';
import './styles';
import { useTranslations } from '../../../../hooks/useTranslationsHelper';

interface GameMenuButtonsProps {
  searchGamesPath?: string;
  searchGamesAlt?: string;
  onClose: () => void;
  onOpenGameSearch: () => void;
}

export const GameMenuButtons: FC<GameMenuButtonsProps> = ({
  searchGamesPath,
  searchGamesAlt,
  onOpenGameSearch,
  onClose,
}) => {
  const { t } = useTranslations();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getRoute = storageRoute.get() as string;

  const onExitClick = (): void => {
    onClose();
    dispatch(setGameLaunchUrl(''));
    navigate(getRoute || APP_URLS.home);
  };

  const onClickSearchButton = (): void => {
    onOpenGameSearch();
  };

  return (
    <>
      <Button
        className={joinStrings(['game-menu-button', 'game-menu-button--divider'])}
        type="button"
        variant="text"
        size="large"
        icon={
          searchGamesPath ? (
            <img src={searchGamesPath} alt={searchGamesAlt || 'search'} />
          ) : (
            <SearchIcon color="white" />
          )
        }
        iconPosition="left"
        onClick={onClickSearchButton}
      >
        {t('game.search')}
      </Button>
      <Button
        type="button"
        variant="text"
        size="large"
        iconPosition="left"
        icon={<ExitIcon />}
        onClick={onExitClick}
        className="game-menu-button"
      >
        {t('game.exit')}
      </Button>
    </>
  );
};
