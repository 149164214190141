import React, { FunctionComponent } from 'react';
import { Icon } from '../../shared/Icon';
import { useSelector } from 'react-redux';
import { isNavigationExpanded } from '../../../state/app';
import { formatDate } from 'date-fns';
import './styles';
import useDestroyUserSession from '../../../hooks/auth/useDestroyUserSession';

// TODO: update this component
export const NavigationLogOutLink: FunctionComponent = () => {
  const navigationExpanded = useSelector(isNavigationExpanded);
  const { logoutUser } = useDestroyUserSession();

  const logoutHandler = (): void => {
    logoutUser();
  };

  return (
    <div className="navigation-logout-link">
      <div
        className={navigationExpanded ? 'navigation-logout-link__expanded' : 'navigation-logout-link__collapsed'}
        onClick={logoutHandler}
        onKeyPress={logoutHandler}
        role="button"
        tabIndex={0}
      >
        {navigationExpanded ? (
          <>
            <div className="navigation-logout-link__expanded-tile">
              <Icon variant="Logout" className="navigation-logout-link__expanded-icon" />
              Logout
            </div>
            <span className="navigation-logout-link__expanded-login-date">
              Login Date: {formatDate(new Date(), 'yyyy-MM-dd')}
            </span>
          </>
        ) : (
          <>
            <Icon variant="Logout" className="navigation-logout-link__collapsed-icon" />
            <p className="navigation-logout-link__collapsed-paragraph">Logout</p>
          </>
        )}
      </div>
    </div>
  );
};
