import React, { Fragment, FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { Button } from '../../shared/Button';
import { HamburgerLogo, Header } from '../../shared/Header';
import { MediaBreakpoint, useBreakpointInfo } from '../../../hooks/useDeviceInfo';
import { APP_URLS } from '../../../consts';
import './styles';
import { useTranslations } from '../../../hooks/useTranslationsHelper';

export type UnauthenticatedHeaderProps = {
  onOpenLogin: () => void;
  onHamburgerClick: () => void;
  isNavHidden?: boolean;
};

// TODO: update this component
export const UnauthenticatedHeader: FC<UnauthenticatedHeaderProps> = ({
  onOpenLogin,
  onHamburgerClick,
  isNavHidden,
}) => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const auth = useAuth();
  const isBelowMobileWidth = useBreakpointInfo([MediaBreakpoint.BELOW_MOBILE]);
  const isBelowTabletLandWidth = useBreakpointInfo([MediaBreakpoint.BELOW_TABLET]);

  const [loginBtnDisabled, setLoginBtnDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (auth?.isAuthenticated) {
      setLoginBtnDisabled(false);
    }
  }, [auth?.isAuthenticated, onOpenLogin]);

  const onLogin = (): void => {
    setLoginBtnDisabled(true);
    onOpenLogin();
  };

  const onRegister = (): void => {
    navigate(APP_URLS.registration);
  };

  return (
    <div className="unauthenticated-header-min-height">
      <Header
        className="unauthenticated-header"
        sticky
        left={<HamburgerLogo onHamburgerClick={onHamburgerClick} isNavHidden={isNavHidden} />}
        right={
          <Fragment>
            <Button
              type="button"
              variant="secondary-alt"
              disabled={loginBtnDisabled}
              size={isBelowMobileWidth ? 'small' : isBelowTabletLandWidth ? 'medium' : 'large'}
              className="unauthenticated-header__login-btn"
              onClick={onLogin}
            >
              {t('login.main')}
            </Button>

            <Button
              type="button"
              variant="primary"
              size={isBelowMobileWidth ? 'small' : 'large'}
              className="unauthenticated-header__register-btn"
              onClick={onRegister}
            >
              {t('register.main')}
            </Button>
          </Fragment>
        }
      />
    </div>
  );
};
