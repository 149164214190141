import React, { Fragment } from 'react';
import { RegistrationStepperStepComponent } from '../types';
import './styles';
import { useTranslations } from '../../../hooks/useTranslationsHelper';

export const RegistrationStepperStep: RegistrationStepperStepComponent = ({ stepNumber, stepCount, selected }) => {
  const { t } = useTranslations();

  return (
    <Fragment>
      {selected && stepCount > 1 && (
        <div className="registration-stepper-step">
          <span className="registration-stepper-step__step-number">{stepNumber}</span>/{stepCount}{' '}
          {t('register.steps.text')}
        </div>
      )}
    </Fragment>
  );
};
