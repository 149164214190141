import { useMemo } from 'react';
import { config } from '../../components/Authentification/config';

export const usePasswordReset = (): string => {
  const { protocol, hostname, port, pathname } = window.location;
  const redirectUri = `${protocol}//${hostname}${port ? `:${port}` : ''}${pathname}`;

  const resetUrl = useMemo(() => {
    const url = new URL(config.authUrl);

    url.pathname = `/auth/realms/${config.realm}/protocol/openid-connect/auth`;
    url.searchParams.set('client_id', config.clientId);
    url.searchParams.set('redirect_uri', redirectUri);
    url.searchParams.set('kc_action', 'UPDATE_PASSWORD');
    url.searchParams.set('response_type', 'code');

    return url.href;
  }, [redirectUri]);

  return resetUrl;
};
