import { SelectOption } from '../Select/Select.shared';
import range from 'lodash/range';
import React, { useState, useEffect, FunctionComponent, Suspense, useMemo } from 'react';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { Select } from '../Select';
import { getMonthList } from '../../../utils/dates';

type UseCustomDatePickerPropsReturnValue = {
  renderCustomHeader: FunctionComponent<ReactDatePickerCustomHeaderProps>;
  excludeDates: Date[];
};

export function useCustomDatePickerProps({ maxDate }: { maxDate: Date }): UseCustomDatePickerPropsReturnValue {
  const language = 'en';
  const months = getMonthList(language);

  const currentDate = useMemo(() => new Date(Date.now()), []);
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  const [createdYear, setCreatedYear] = useState<number>();
  const [createdMonth, setCreatedMonth] = useState<number>();

  const [availableYears, setAvailableYears] = useState<SelectOption[]>([
    { label: currentYear.toString(), value: currentYear.toString() },
  ]);
  const [excludeDates, setExcludeDates] = useState<Date[]>([]);

  useEffect(() => {
    // There is no createdDate from login response, suppose to use yesterday
    const createdDate = new Date(new Date().getDate() - 1);
    const createdMonth = createdDate.getMonth();
    const createdYear = createdDate.getFullYear();

    setCreatedYear(createdYear);
    setCreatedMonth(createdMonth);

    const yearOptions: SelectOption[] = range(createdYear, currentYear + 1)
      .map((item: number) => {
        return { value: item.toString(), label: item.toString() };
      })
      .reverse();

    setAvailableYears(yearOptions);

    const excludeDatesList = [];

    if (createdYear === currentYear && createdMonth === currentMonth) {
      // Exclude dates of the next month from calendar
      for (let i = 1; i < 7; i++) {
        excludeDatesList.push(new Date(currentYear, currentMonth + 1, i));
      }

      // Exclude dates of the previous month from calendar
      for (let i = 31; i > 22; i--) {
        excludeDatesList.push(new Date(currentYear, currentMonth - 1, i));
      }
    } else {
      // Need to generate array dates for excludeDates.
      // We put dates from tomorrow to the end of the current month.
      // The dates of the beginning of the next month are also displayed in the calendar
      // if the week starts in the current month and ends in the next.
      // So it is used 38, it equals maximum date of beginning of the next month.
      // For example, 38th of Jule equals 6th of August in this context.
      for (let i = currentDate.getDate() + 1; i < 38; i++) {
        excludeDatesList.push(new Date(currentYear, currentMonth, i));
      }
      // We put dates from one day before created date to the start month when account was created.
      // The dates of the end of the month before are also displayed in the calendar
      // if 1st day of the month does not equal Sunday.
      // So it is used -6, it equals minimum date of end of the month before created account.
      // For example, if 1th September is Saturday, that Sunday of this week will be 26th of August
      // -6 of September equals 26th of August in this context.

      for (let i = createdDate.getDate() - 1; i > -6; i--) {
        excludeDatesList.push(new Date(createdYear, createdMonth, i));
      }
    }

    setExcludeDates(excludeDatesList);
  }, [currentDate, currentMonth, currentYear]);

  const getPartsOfDate = (date: Date): { year: number; month: number; day: number; dateOfMonth: number } => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDay();
    const dateOfMonth = date.getDate();

    return { year, month, day, dateOfMonth };
  };

  const getMonthsForChosenYear = (year: number): SelectOption[] => {
    if (createdYear === currentYear) {
      const monthsForChosenYear = months.slice(createdMonth, currentMonth + 1);

      return monthsForChosenYear.map((item) => {
        return { label: item, value: item };
      });
    } else if (year === createdYear) {
      const monthsForChosenYear = months.slice(createdMonth);

      return monthsForChosenYear.map((item) => {
        return { label: item, value: item };
      });
    } else if (maxDate && year === Number(availableYears[0].value)) {
      const monthsForChosenYear = months.slice(0, currentMonth + 1);

      return monthsForChosenYear.map((item) => {
        return { label: item, value: item };
      });
    }

    return months.map((item) => {
      return { label: item, value: item };
    });
  };

  const isShowDescreaseMonthBtn = (date: Date | undefined): boolean => {
    if (date) {
      const { year, month } = getPartsOfDate(date);
      const chosenYear = year;
      const chosenMonth = month;

      if (chosenYear === createdYear) {
        const isFirstMonthForUser = months[chosenMonth] === getMonthsForChosenYear(year)[0].value;

        return !isFirstMonthForUser;
      }
    }

    return true;
  };

  const isShowIncreaseMonthBtn = (date: Date | undefined): boolean => {
    if (date) {
      const { year, month } = getPartsOfDate(date);
      const chosenYear = year;
      const chosenMonth = month;

      if (chosenYear === currentYear) {
        const monthsForCurrentYear = getMonthsForChosenYear(date.getFullYear()).map((item) => item.value);

        const isCurrentMonth = months[chosenMonth] === monthsForCurrentYear[currentMonth];

        return !isCurrentMonth;
      }
    }

    return true;
  };

  const correctMonth = (date: Date, value: string, changeMonth: (month: number) => void): void => {
    const { month } = getPartsOfDate(date);
    const indexMonthPreviousYear = month;
    const monthsCurrentYear = getMonthsForChosenYear(Number(value)).map((item) => item.value);
    const indexMonthCurrentYear = months.findIndex((item: string) => item === monthsCurrentYear[0]);

    if (indexMonthPreviousYear < indexMonthCurrentYear) {
      changeMonth(indexMonthCurrentYear);
    } else {
      if (!monthsCurrentYear.find((item: string) => item === months[indexMonthPreviousYear])) {
        changeMonth(0);
      }
    }
  };

  const renderCustomHeader: FunctionComponent<ReactDatePickerCustomHeaderProps> = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
  }) => {
    return (
      <div className="react-datepicker__date-header-wrapper">
        <div
          className={
            isShowDescreaseMonthBtn(date)
              ? 'react-datepicker__decrease-month-btn'
              : 'react-datepicker__decrease-month-btn--hidden'
          }
        >
          <Button
            icon={<Icon variant="ChevronLeft" />}
            iconPosition="left"
            size="x-large"
            onClick={decreaseMonth}
            type="button"
            variant="text"
          />
        </div>

        <div className="react-datepicker__date-selects-wrapper">
          <Suspense>
            <div className="react-datepicker__date-select">
              <Select
                options={getMonthsForChosenYear(date.getFullYear())}
                value={months[date.getMonth()]}
                onChange={(value): void => {
                  changeMonth(months.indexOf(value));
                }}
              />
            </div>

            <div className="react-datepicker__date-select">
              <Select
                options={availableYears}
                value={availableYears.find((item) => Number(item.value) === date.getFullYear())?.value}
                onChange={(value): void => {
                  changeYear(Number(value));
                  correctMonth(date, value, changeMonth);
                }}
              />
            </div>
          </Suspense>
        </div>

        <div
          className={
            isShowIncreaseMonthBtn(date)
              ? 'react-datepicker__increase-month-btn'
              : 'react-datepicker__increase-month-btn--hidden'
          }
        >
          <Button
            icon={<Icon variant="ChevronRight" />}
            iconPosition="left"
            size="x-large"
            onClick={increaseMonth}
            type="button"
            variant="text"
          />
        </div>
      </div>
    );
  };

  return {
    renderCustomHeader,
    excludeDates,
  };
}
