import { Button } from '../../../shared/Button';
import { Heading } from '../../../shared/Heading';
import { Paragraph } from '../../../shared/Paragraph';
import { Input } from '../../../shared/Input';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { ParsedPromo } from '../types';
import './PromotionPreDepositContent.scss';

type PromotionPreDepositConfirmationProps = {
  showTnC: () => void;
  onClose: () => void;
  promoCode?: string;
  depositAmount: string;
  setDepositAmount: Dispatch<SetStateAction<string>>;
  activatedPromo: ParsedPromo;
};

export const PromotionPreDepositConfirmation: FC<PromotionPreDepositConfirmationProps> = ({
  showTnC,
  onClose,
  activatedPromo,
  setDepositAmount,
  depositAmount = '',
}) => {
  const { imgPath, imgAlt } = activatedPromo;
  const [inputError, setInputError] = useState('');
  // TODO: Consider the real mininumAmount usage here
  const mininumAmount = 0;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;

    setDepositAmount(value);

    if (+value >= +mininumAmount) {
      setInputError('');
    }
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
    if (+event.target.value < +mininumAmount) {
      setInputError('The amount is needed to qualify for this bonu');
    } else {
      setInputError('');
    }
  };

  const onAcceptClick = (): void => {
    // TODO: Set Pre Deposit data and start deposit journey

    onClose();
  };

  return (
    <div className="promotion-pre-deposit">
      <div className="promotion-pre-deposit__image-container">
        <img src={imgPath} alt={imgAlt} />
      </div>
      <Heading noMargin level={5} styleAs={5} className="promotion-pre-deposit__heading">
        Great you've chosen some giveaway!
      </Heading>
      <Paragraph size="sm" noMargin className="promotion-pre-deposit__text">
        <strong>
          {/* TODO: Fill it in fith correct amount data */}
          You need to deposit this amount or more for this promotion
        </strong>
      </Paragraph>
      <Input
        showFeedbackIcon={false}
        validationMessages={[{ id: 'id', message: inputError }]}
        validationState={inputError.length ? 'error' : undefined}
        label="Enter an amount"
        theme="light"
        value={depositAmount}
        onChange={onChange}
        onBlur={onBlur}
        type="number"
      />
      <button onClick={showTnC} className="promotion-pre-deposit__TnC-button">
        See full terms and conditions
      </button>
      <Button
        variant="primary"
        size="large"
        type="submit"
        className="promotion-pre-deposit__submit-button"
        disabled={+depositAmount <= 0 || !!inputError}
        onClick={(): void => {
          onAcceptClick();
        }}
      >
        CONTINUE TO DEPOSIT
      </Button>
    </div>
  );
};
