import React, { FunctionComponent, Suspense, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { APP_URLS, currencyCode, passiveOption } from '../../../consts';
import { useSnackbar } from '../../../hooks/snackbar';
import { useDeviceInfo } from '../../../hooks/useDeviceInfo';
import { useUserContext } from '../../../providers/UserProvider';
import { selectGameLaunchUrl } from '../../../state/game';
import { setGameData, setGameLaunchUrl } from '../../../state/game/reducers';
import { getGameAspectRatio } from '../../../utils/games/games.utils';
import { Icon } from '../../shared/Icon';
import { Spinner } from '../../shared/Spinner';
import { GameSearchSpineZone } from '../GameSearch';
import { GameSessionInGame } from '../GameSession';
import { GameSessionParallel } from '../GameSession';
import { Game, GameChannel } from '../games.model';
import { retrieveGameLaunchUrl } from '../service';
import { Clock } from './Clock';
import { GameLauncherMenu } from './GameLauncherMenu';
import GamingPerformancePrompt from './GamingPerformancePrompt';
import { useGameSession } from '../../../hooks/games';
import './GameLauncher.scss';
import { useTranslations } from '../../../hooks/useTranslationsHelper';

export type GameContainerProps = {
  game: Game;
  mode: 'demo' | 'real';
};
/**
 * Component will attempt to launch provided game and display overlay with actions/session info.
 *
 * TODO: What is the unhappy path?  useGameLauncher returns request failure data - use this when unhappy path is defined.
 */
export const GameLauncher: FunctionComponent<GameContainerProps> = ({ game, mode = false }) => {
  const { t } = useTranslations();
  const headerRef = useRef<HTMLDivElement>(null);
  const auth = useAuth();
  const { addSnack } = useSnackbar();
  const { isMobileDevice } = useDeviceInfo();
  const { handleGameStartDuringBreak } = useGameSession();
  const gameChannel: GameChannel = isMobileDevice ? GameChannel.Mobile : GameChannel.Desktop;
  const dispatch = useDispatch();
  const gameContainer = useRef<HTMLDivElement>(null);
  const iframeId = 'game-iframe';
  const navigate = useNavigate();
  const { userProfileId } = useUserContext();
  const playerId = userProfileId || (auth.user?.profile?.user_profile_id as string);
  const launchUrl = useSelector(selectGameLaunchUrl);
  const { friendlyUrlName, displayName } = game;

  // TODO: add missing logic
  const mocks = {
    gameAspectRatio: getGameAspectRatio(game, gameChannel),
    isLaunchDataInitialised: true,
    isFetching: false,
    gameChannel: 'Desktop',
    isMobileDevice: false,
    gameBackgroundImage: game.gameplayBackground.imgUrl || '',
  };

  const [showPerformancePrompt, setShowPerformancePrompt] = useState(false);
  const [showParallelPrompt, setShowParallelPrompt] = useState(false);
  const [showGameSearch, setShowGameSearch] = useState<boolean>(false);
  const [gameContainerStyles, setGameContainerStyles] = useState<React.CSSProperties>({});
  const [showMenu, setShowMenu] = useState<boolean>(false);

  useEffect(() => {
    // Set current game data (used to match against session data)
    dispatch(setGameData(game));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [friendlyUrlName]);

  const setGameDimensions = (): void => {
    if (!gameContainer.current) return;

    const { clientHeight, clientWidth } = gameContainer.current;
    const isContainerPortrait = clientHeight > clientWidth;

    if (isContainerPortrait) {
      setGameContainerStyles({
        width: `${clientWidth}px`,
        height: `${clientWidth / mocks.gameAspectRatio}px`,
      });
    } else {
      setGameContainerStyles({
        width: `${clientHeight * mocks.gameAspectRatio}px`,
        height: `${clientHeight}px`,
      });
    }
  };

  const getGameLaunchUrl = useCallback(async (): Promise<void> => {
    try {
      if (auth.user?.expired) {
        await auth.signinSilent();
      }

      const launchUrl = await retrieveGameLaunchUrl({
        userProfileId: playerId,
        gameExternalId: game.gameId,
        currencyCode,
        gameProvider: String(game.provider?.mediatorId),
      });

      dispatch(setGameLaunchUrl(launchUrl.url));

      return;
    } catch (error) {
      console.error('retrieveGameLaunchUrl error: ', error);
      addSnack({
        type: 'error',
        message: t('errors.general'),
      });
      navigate(`/game-details/${friendlyUrlName}`);
    }
  }, [friendlyUrlName]);

  useLayoutEffect(() => {
    if (!gameContainer.current || isMobileDevice) return;

    window.addEventListener('resize', setGameDimensions, passiveOption);
    setGameDimensions();

    return () => {
      window.removeEventListener('resize', setGameDimensions, passiveOption as EventListenerOptions);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileDevice]);

  useEffect(() => {
    handleGameStartDuringBreak();

    document.body.style.setProperty('overflow', 'hidden');

    return () => {
      // setTimeout is used to avoid redefining overflow property in TouchDrawer
      setTimeout(() => {
        document.body.style.setProperty('overflow', null);
      }, 0);
    };
  }, []);

  useEffect(() => {
    getGameLaunchUrl();
  }, [friendlyUrlName]);

  return (
    <>
      <Suspense fallback={<Spinner />}>
        <GamingPerformancePrompt
          show={showPerformancePrompt}
          onClose={(accepted): void => {
            if (accepted) {
              console.log('createGameLaunch');
            } else {
              // Byee - casino home
              navigate(APP_URLS.home);
            }

            setShowPerformancePrompt(false);
          }}
        />
      </Suspense>

      <GameSessionParallel
        show={showParallelPrompt}
        onClose={(): void => navigate(-1)}
        onPrimary={(): void => {
          setShowParallelPrompt(false);
          console.log('createGameLaunch');
        }}
      />

      <GameSessionInGame />

      <div className={`game-launcher game-launcher--${mocks.gameChannel.toLowerCase()}-channel`}>
        <GameLauncherMenu
          haveLaunched={!!launchUrl}
          iframeId={iframeId}
          onOpenGameSearch={(): void => setShowGameSearch(true)}
          setShowMenu={setShowMenu}
          showMenu={showMenu}
        >
          <div ref={gameContainer} className="game-launcher__game-container" style={{ overflow: 'hidden' }}>
            <div ref={headerRef} className="game-launcher__header">
              <div className="game-launcher__time-container">
                <Icon variant="Clock" /> <Clock />
              </div>
              <div className="game-launcher__game-name">{displayName}</div>
            </div>
            <div
              data-testid="game-container-inner"
              style={mocks?.gameBackgroundImage ? { backgroundImage: `url(${mocks?.gameBackgroundImage})` } : {}}
              className="game-launcher__game-container-inner"
            >
              <div style={gameContainerStyles} className="game-launcher__game-container-content">
                {Boolean(launchUrl) && (
                  <iframe
                    className="game-launcher__iframe"
                    data-testid={iframeId}
                    id={iframeId}
                    src={launchUrl}
                    title={displayName}
                    allow="xr-spatial-tracking"
                    scrolling="no"
                  />
                )}
              </div>
            </div>
          </div>
        </GameLauncherMenu>
      </div>
      <GameSearchSpineZone
        isOpen={showGameSearch}
        setShowMenu={setShowMenu}
        onCloseGameSearch={(): void => setShowGameSearch(false)}
      />
    </>
  );
};
