import { RegistrationFieldMap } from './useRegistrationStepper';
import { FlattenedProfileData } from './useRegistrationStepper.model';

export const updateFormDataByFieldConfig = (
  d: Partial<FlattenedProfileData> = {},
  formFields: RegistrationFieldMap = {}
): Partial<FlattenedProfileData> => {
  let newData = { ...d };

  Object.keys(d).forEach((dField: string) => {
    const dFieldName = Object.keys(formFields).filter((f) => formFields[f].name === dField)[0];

    if (typeof formFields[dFieldName]?.updateFormData === 'function') {
      const updateFormData = formFields[dFieldName].updateFormData as (
        data: Partial<FlattenedProfileData>
      ) => Partial<FlattenedProfileData>;

      newData = updateFormData(newData);
    }
  });

  return newData;
};
