import domPurify from 'dompurify';
import React, { FunctionComponent } from 'react';
import './InnerHTML.scss';

export type InnerHTMLProps = {
  /**
   * Required. The html content to render.
   */
  text: string;
};

/**
 * Sanitizes and renders html from scenarios which starterkit components are not able to be used.
 * For example via the AEM Text control or an API response.
 * Applies app default styling for tags like <h1>, <ul> etc.
 */
export const InnerHTML: FunctionComponent<InnerHTMLProps> = ({ text }) => (
  <div className="inner-html" dangerouslySetInnerHTML={{ __html: domPurify.sanitize(text) }} />
);
