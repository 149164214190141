import React, { FunctionComponent, useContext } from 'react';
import { Icon, IconVariant } from '../Icon';
import { AccordionContext } from './Accordion.context';
import { joinStrings } from '../../../utils/string';
import './AccordionHeader.scss';

export type AccordionHeaderProps = {
  /**
   * Custom icon variant for expanded state. Defaults to ChevronUp.
   */
  expandedIcon?: IconVariant;
  /**
   * Custom icon variant for collapsed state. Defaults to ChevronDown.
   */
  collapsedIcon?: IconVariant;
  /**
   * Header button styling.  Defaults to standard.
   * `align-left` & `align-right` will make the header appear more compact like a button.
   */
  headerStyling?: 'standard' | 'align-right' | 'align-left';
  /**
   * Flagged for mode when accordion expanded or collapsed view changed only with clicking by arrow icon
   */
  expandOnlyByArrowClick?: boolean;
  /**
   * Called when header is clicked. Can be used to control Accordion state.
   */
  onClick?: (event: React.SyntheticEvent, expanded?: boolean) => void;
  children?: React.ReactNode;
};

/**
 * An AccordionHeader which will act as a button and label for the accordion content.
 * Must be used as a child of Accordion.
 */
export const AccordionHeader: FunctionComponent<AccordionHeaderProps> = ({
  children,
  expandedIcon,
  collapsedIcon,
  headerStyling = 'standard',
  expandOnlyByArrowClick,
  onClick,
}) => {
  const { onClickChangeView, expanded, headerId, contentId, transitionDuration } = useContext(AccordionContext);
  const onClickHandle = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>
  ): void => {
    onClick && onClick(event, expanded);
    !expandOnlyByArrowClick && onClickChangeView();
  };

  return (
    <div
      data-testid="accordion-header"
      className={joinStrings([
        'accordion-header',
        expanded && 'accordion-header--expanded',
        `accordion-header--${headerStyling}`,
      ])}
    >
      <div
        className="accordion-header__inner"
        id={headerId}
        tabIndex={0}
        aria-expanded={expanded}
        aria-controls={contentId}
        role="button"
        onKeyDown={(event): boolean | void => (event.key === 'Enter' || event.key === ' ') && onClickHandle(event)}
        onClick={onClickHandle}
      >
        <div className="accordion-header__label">{children}</div>
        <div
          aria-hidden="true"
          style={{ transitionDuration }}
          className={joinStrings([
            'accordion-header__icon',
            expanded && !collapsedIcon && 'accordion-header__icon--rotated',
          ])}
        >
          {expanded ? (
            <Icon variant={expandedIcon || 'ChevronDown'} />
          ) : (
            <Icon variant={collapsedIcon || 'ChevronDown'} />
          )}
        </div>
      </div>
    </div>
  );
};
