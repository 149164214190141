/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState, RefObject, DependencyList, useCallback } from 'react';

export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

export const useElementOnScreen: (elementRef: RefObject<Element>) => boolean =
  typeof IntersectionObserver === 'undefined'
    ? (): boolean => true
    : (elementRef): boolean => {
        const [isVisible, setIsVisible] = useState(false);

        const callbackFunction = (entries: IntersectionObserverEntry[]): void => {
          const [entry] = entries;

          setIsVisible(entry.isIntersecting);
        };

        useEffect(() => {
          const observer = new IntersectionObserver(callbackFunction);
          const node = elementRef?.current;

          node && observer.observe(node as Element);

          return () => observer.disconnect();
        }, [elementRef]);

        return isVisible;
      };

type AssetLoaded = {
  assetLoaded: boolean;
  onAssetLoaded: () => void;
};

export const useAssetLoadedListener = (dependencies: DependencyList = []): AssetLoaded => {
  const [assetLoaded, setAssetLoaded] = useState(false);

  useEffect(() => setAssetLoaded(false), dependencies);

  const onAssetLoaded = useCallback(() => setAssetLoaded(true), dependencies);

  return { assetLoaded, onAssetLoaded };
};
