import { useSelector } from 'react-redux';
import React, { Dispatch, FunctionComponent, SetStateAction, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Game } from '../games.model';
import { PopUpHandler } from '../../shared/PopupHandler';
import { Button } from '../../shared/Button';
import { PaginatedResponse } from '../../../models/api.model';
import { PromotionGameTags } from '../../Promotions/PromotionGameTags';
import { PromotionProgressBar } from '../../Layout/Promotions/PromotionProgressBar';
import { storageRoute } from '../../../utils/storage';
import { selectDisabledGamesData } from '../../../state/game';
import './GameSliderPrompt.scss';
import { ParsedPromo } from '../../Layout/Promotions/types';

export type GameSliderPromptProps = {
  promotion: ParsedPromo;
  gamesByTag: PaginatedResponse<Game>;
  openPopUp: boolean;
  setOpenPopUp: Dispatch<SetStateAction<boolean>>;
  onCloseWindow?: () => void;
};

export const GameSliderPrompt: FunctionComponent<GameSliderPromptProps> = ({
  promotion,
  gamesByTag,
  openPopUp,
  setOpenPopUp,
  onCloseWindow,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [gameName, setGameName] = useState<string>('');
  const { areGamesDisabled } = useSelector(selectDisabledGamesData);
  const [isDisableLaunchGameButton, setDisableLaunchGameButton] = useState<boolean>(true);

  // TODO: add getGameUrl logic
  const gameUrl = useMemo(() => {
    return `/game-details/${gameName}/real`;
  }, [gameName]);

  return (
    <>
      <PopUpHandler
        header="Select & play a game"
        drawerProps={{
          className: 'game-slider-prompt',
        }}
        modalProps={{
          className: 'game-slider-prompt',
        }}
        footer={
          <div className="game-slider__summary-block-button">
            <Button
              type="button"
              variant="secondary-alt"
              onClick={(): void => {
                setOpenPopUp(false);
              }}
            >
              CANCEL
            </Button>
            <Button
              className="game-slider__summary-block-button--primary"
              type="button"
              variant="primary"
              onClick={(): void => {
                navigate(gameUrl);
                setOpenPopUp(false);
                onCloseWindow && onCloseWindow();

                storageRoute.set({
                  value: location.pathname,
                });
              }}
              disabled={areGamesDisabled || isDisableLaunchGameButton}
            >
              LAUNCH GAME
            </Button>
          </div>
        }
        isOpen={openPopUp}
        onClose={(): void => setOpenPopUp(false)}
      >
        <PromotionProgressBar promotion={promotion} />
        <PromotionGameTags
          setGameName={setGameName}
          games={gamesByTag}
          setStateLaunchGameButton={setDisableLaunchGameButton}
        />
      </PopUpHandler>
    </>
  );
};
