import React, { FC } from 'react';
import { useDeviceInfo } from '../../../../hooks/useDeviceInfo';
import { joinStrings } from '../../../../utils/string';
import './styles';

export type GameMenuTileOrientation = 'landscape' | 'portrait';

interface GameMenuTileProp {
  children: JSX.Element;
  noPadding?: boolean;
  noMargin?: boolean;
  className?: string;
  orientation?: GameMenuTileOrientation;
}

const GameMenuTile: FC<GameMenuTileProp> = ({
  children,
  noPadding = false,
  noMargin = false,
  className,
}): JSX.Element => {
  const isMobile = useDeviceInfo().isMobileDevice;

  return (
    <div
      className={joinStrings([
        'game-menu-tile',
        isMobile && 'game-menu-tile--mobile',
        className && `${className}`,
        noPadding && 'game-menu-tile--no-padding',
        noMargin && 'game-menu-tile--no-margin',
      ])}
    >
      {children}
    </div>
  );
};

export default GameMenuTile;
