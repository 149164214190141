import React, { FunctionComponent } from 'react';
import { useGameSession } from '../../../../hooks/games';
import { Typography } from '../../../shared/Typography';
import { Icon } from '../../../shared/Icon';
import './GameSessionDuration.scss';

export const GameSessionDuration: FunctionComponent = () => {
  const { gameTimeElapsed } = useGameSession();

  return (
    <>
      {gameTimeElapsed && (
        <div className="game-session-duration">
          <Icon variant="Clock" />{' '}
          <Typography size="sm" className="game-session-duration__duration">
            {gameTimeElapsed}
          </Typography>
        </div>
      )}
    </>
  );
};
