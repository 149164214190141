import React, { FC, ReactNode } from 'react';
import { Paragraph } from '../Paragraph';
import { Theme, ValidationState } from '../../../types';
import { ValidationLinkDetails } from './types';
import { joinStrings } from '../../../utils/string';
import './InputValidationAlert.scss';

const baseClassName = 'input-validation-alert';

export type InputValidationAlertProps = {
  /**
   * String validation message or react node containing message.
   */
  message: string | ReactNode;
  /**
   * Id for alert.  Can be used with aria-describedby etc.
   */
  id: string;
  /**
   * Used to control message styling.
   */
  state: ValidationState;
  /**
   * Optional, defaults to 'light'. If set to 'dark' the alert will be styled for placement on a dark background.
   */
  theme?: Theme;
  /**
   * Optinal, props to display the link in the validation error message
   */
  linkDetails?: ValidationLinkDetails;
};

// TODO: update this component
/**
 * A basic component to display an inline error message in a form.  Should follow form Input.
 */
export const InputValidationAlert: FC<InputValidationAlertProps> = ({
  message,
  id,
  state,
  theme = 'light',
  linkDetails,
}) => {
  const className = joinStrings([
    baseClassName,
    `${baseClassName}--${state}`,
    theme === 'dark' && `${baseClassName}--dark`,
  ]);
  const { link, callback } = linkDetails || {};
  const { linkTo, linkName } = link || {};

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onClick = (): void => {
    callback?.();
  };

  return (
    <Paragraph className={className} size="xs" noMargin id={id} role="alert">
      {message}
      {linkTo && (
        <>
          {' '}
          <a href={linkName || ''}>{linkName}</a>
        </>
      )}
    </Paragraph>
  );
};
