/* eslint-disable max-len */
import React, { FunctionComponent } from 'react';

export const NavSearchIcon: FunctionComponent<{
  active?: boolean;
  color?: string;
  isJackpot?: boolean;
}> = ({ active = false, color = '#ffffff', isJackpot = false }) => {
  if (active && isJackpot) {
    return (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.39718 12.4316L6.43585 14.3933C6.3214 14.5078 6.1714 14.5648 6.0214 14.5648C5.8714 14.5648 5.7214 14.5078 5.60695 14.3933C5.37843 14.1644 5.37843 13.7933 5.60695 13.5644L7.56827 11.6027C7.79718 11.3742 8.16827 11.3742 8.39718 11.6027V11.6031C8.6257 11.832 8.62609 12.2027 8.39718 12.4316Z"
          fill="#03052d"
        />
        <path
          d="M8.39718 12.4316L6.43585 14.3933C6.3214 14.5078 6.1714 14.5648 6.0214 14.5648C5.8714 14.5648 5.7214 14.5078 5.60695 14.3933L8.39718 11.6031C8.6257 11.832 8.62609 12.2027 8.39718 12.4316Z"
          fill="#03052d"
        />
        <path
          d="M7.77921 7.28278C7.77921 7.60638 8.04155 7.86872 8.36514 7.86872C8.68874 7.86872 8.95108 7.60638 8.95108 7.28278C8.95108 5.20575 10.6408 3.51599 12.7179 3.51599C13.0415 3.51599 13.3038 3.25365 13.3038 2.93005C13.3038 2.60646 13.0415 2.34412 12.7179 2.34412C9.99467 2.34412 7.77921 4.55958 7.77921 7.28278Z"
          fill="#03052d"
        />
        <path
          d="M17.8647 2.13554C16.5464 0.816796 14.7257 0 12.7179 0C8.70187 0 5.43507 3.26679 5.43507 7.28202C5.43507 8.76132 5.87648 10.1855 6.71124 11.4004C6.96632 11.7719 7.25265 12.1168 7.56827 12.432C7.8839 12.748 8.22804 13.0336 8.59913 13.2887C9.81437 14.1234 11.2386 14.5648 12.7179 14.5648C16.7331 14.5648 19.9999 11.298 19.9999 7.28202C19.9999 5.2746 19.1831 3.45429 17.8647 2.13554ZM12.7179 13.393C11.4765 13.393 10.2819 13.023 9.26241 12.3226C8.9507 12.1086 8.66202 11.8683 8.39718 11.6031C8.13195 11.3387 7.89132 11.0492 7.67726 10.7371C6.97687 9.71796 6.60695 8.52343 6.60695 7.28202C6.60695 3.91289 9.34835 1.17187 12.7179 1.17187C14.4026 1.17187 15.9304 1.85742 17.0362 2.96406C18.1429 4.07031 18.828 5.59804 18.828 7.28202C18.828 10.6516 16.087 13.393 12.7179 13.393Z"
          fill="#03052d"
        />
        <path
          d="M19.9999 7.28204C19.9999 11.298 16.7331 14.5648 12.7179 14.5648C11.2386 14.5648 9.81436 14.1234 8.59913 13.2887C8.22803 13.0336 7.88389 12.748 7.56827 12.432L8.39717 11.6031C8.66202 11.8684 8.95069 12.1086 9.26241 12.3227C10.2819 13.023 11.4765 13.393 12.7179 13.393C16.087 13.393 18.828 10.6516 18.828 7.28204C18.828 5.59806 18.1429 4.07032 17.0362 2.96407L17.8647 2.13556C19.1831 3.45431 19.9999 5.27462 19.9999 7.28204Z"
          fill="#03052d"
        />
        <path
          d="M7.26397 15.2214L2.65734 19.8285C2.42831 20.0575 2.05714 20.0568 1.82882 19.8285L1.00031 19L0.171401 18.1711C-0.0566453 17.943 -0.0576218 17.5716 0.171401 17.3425L4.77843 12.7355C4.88819 12.6257 5.03702 12.564 5.19249 12.564C5.34796 12.564 5.49718 12.6257 5.60694 12.7355L6.43585 13.5644L7.26397 14.3929C7.49288 14.6218 7.49288 14.9929 7.26397 15.2214Z"
          fill="#03052d"
        />
        <path
          d="M7.26397 15.2215L2.65733 19.8285C2.42831 20.0575 2.05714 20.0568 1.82882 19.8285L1.00031 19L6.43585 13.5645L7.26397 14.393C7.49288 14.6219 7.49288 14.993 7.26397 15.2215Z"
          fill="none"
        />
      </svg>
    );
  } else if (active && !isJackpot) {
    return (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.39718 12.4316L6.43585 14.3933C6.3214 14.5078 6.1714 14.5648 6.0214 14.5648C5.8714 14.5648 5.7214 14.5078 5.60695 14.3933C5.37843 14.1644 5.37843 13.7933 5.60695 13.5644L7.56827 11.6027C7.79718 11.3742 8.16827 11.3742 8.39718 11.6027V11.6031C8.6257 11.832 8.62609 12.2027 8.39718 12.4316Z"
          fill="#DFE7F4"
        />
        <path
          d="M8.39718 12.4316L6.43585 14.3933C6.3214 14.5078 6.1714 14.5648 6.0214 14.5648C5.8714 14.5648 5.7214 14.5078 5.60695 14.3933L8.39718 11.6031C8.6257 11.832 8.62609 12.2027 8.39718 12.4316Z"
          fill="#C7CFE1"
        />
        <path
          d="M19.414 7.28242C19.414 10.9746 16.4101 13.9789 12.7175 13.9789C11.3577 13.9789 10.048 13.573 8.93038 12.8055C8.58898 12.5711 8.27257 12.3082 7.98234 12.018C7.69249 11.7277 7.42882 11.4109 7.19445 11.0691C6.42687 9.95194 6.02101 8.64218 6.02101 7.28242C6.02101 3.58984 9.02531 0.585938 12.7175 0.585938C14.564 0.585938 16.2382 1.33711 17.4503 2.55C18.6632 3.7625 19.414 5.43632 19.414 7.28242Z"
          fill="#7ED8F6"
        />
        <path
          d="M19.414 7.2824C19.414 10.9746 16.4101 13.9789 12.7175 13.9789C11.3577 13.9789 10.048 13.573 8.93038 12.8054C8.58897 12.5711 8.27256 12.3082 7.98233 12.0179L17.4503 2.54999C18.6632 3.76249 19.414 5.43631 19.414 7.2824Z"
          fill="#6AA9FF"
        />
        <path
          d="M7.77921 7.28278C7.77921 7.60638 8.04155 7.86872 8.36514 7.86872C8.68874 7.86872 8.95108 7.60638 8.95108 7.28278C8.95108 5.20575 10.6408 3.51599 12.7179 3.51599C13.0415 3.51599 13.3038 3.25365 13.3038 2.93005C13.3038 2.60646 13.0415 2.34412 12.7179 2.34412C9.99467 2.34412 7.77921 4.55958 7.77921 7.28278Z"
          fill="#F0F7FF"
        />
        <path
          d="M17.8647 2.13554C16.5464 0.816796 14.7257 0 12.7179 0C8.70187 0 5.43507 3.26679 5.43507 7.28202C5.43507 8.76132 5.87648 10.1855 6.71124 11.4004C6.96632 11.7719 7.25265 12.1168 7.56827 12.432C7.8839 12.748 8.22804 13.0336 8.59913 13.2887C9.81437 14.1234 11.2386 14.5648 12.7179 14.5648C16.7331 14.5648 19.9999 11.298 19.9999 7.28202C19.9999 5.2746 19.1831 3.45429 17.8647 2.13554ZM12.7179 13.393C11.4765 13.393 10.2819 13.023 9.26241 12.3226C8.9507 12.1086 8.66202 11.8683 8.39718 11.6031C8.13195 11.3387 7.89132 11.0492 7.67726 10.7371C6.97687 9.71796 6.60695 8.52343 6.60695 7.28202C6.60695 3.91289 9.34835 1.17187 12.7179 1.17187C14.4026 1.17187 15.9304 1.85742 17.0362 2.96406C18.1429 4.07031 18.828 5.59804 18.828 7.28202C18.828 10.6516 16.087 13.393 12.7179 13.393Z"
          fill="#334973"
        />
        <path
          d="M19.9999 7.28204C19.9999 11.298 16.7331 14.5648 12.7179 14.5648C11.2386 14.5648 9.81436 14.1234 8.59913 13.2887C8.22803 13.0336 7.88389 12.748 7.56827 12.432L8.39717 11.6031C8.66202 11.8684 8.95069 12.1086 9.26241 12.3227C10.2819 13.023 11.4765 13.393 12.7179 13.393C16.087 13.393 18.828 10.6516 18.828 7.28204C18.828 5.59806 18.1429 4.07032 17.0362 2.96407L17.8647 2.13556C19.1831 3.45431 19.9999 5.27462 19.9999 7.28204Z"
          fill="#1F3259"
        />
        <path
          d="M7.26397 15.2214L2.65734 19.8285C2.42831 20.0575 2.05714 20.0568 1.82882 19.8285L1.00031 19L0.171401 18.1711C-0.0566453 17.943 -0.0576218 17.5716 0.171401 17.3425L4.77843 12.7355C4.88819 12.6257 5.03702 12.564 5.19249 12.564C5.34796 12.564 5.49718 12.6257 5.60694 12.7355L6.43585 13.5644L7.26397 14.3929C7.49288 14.6218 7.49288 14.9929 7.26397 15.2214Z"
          fill="#334973"
        />
        <path
          d="M7.26397 15.2215L2.65733 19.8285C2.42831 20.0575 2.05714 20.0568 1.82882 19.8285L1.00031 19L6.43585 13.5645L7.26397 14.393C7.49288 14.6219 7.49288 14.993 7.26397 15.2215Z"
          fill="#1F3259"
        />
      </svg>
    );
  } else {
    return (
      <svg width="20" height="20" viewBox="0 0 20 20" fill={color} xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.65747 19.8284C2.42864 20.0573 2.05762 20.0572 1.82883 19.8284L0.171563 18.1711C-0.0572656 17.9423 -0.0571094 17.5711 0.171563 17.3425L4.77833 12.7357C5.00708 12.5069 5.37829 12.507 5.60696 12.7357L6.02146 13.1502L7.17076 12.0007C6.05474 10.6881 5.43509 9.03517 5.43509 7.28224C5.43509 3.2668 8.70209 0 12.7177 0C16.7332 0 20 3.2668 20 7.28224C20 11.2979 16.7332 14.5649 12.7178 14.5649C10.9691 14.5649 9.31525 13.9481 7.99939 12.8293L6.85005 13.9789L7.26419 14.393C7.49302 14.6218 7.49302 14.9928 7.26419 15.2216L2.65747 19.8284ZM12.7177 1.17188C9.34826 1.17188 6.60697 3.91298 6.60697 7.28224C6.60697 10.6622 9.34826 13.393 12.7177 13.393C16.087 13.393 18.8281 10.6517 18.8281 7.28224C18.8281 3.91298 16.087 1.17188 12.7177 1.17188ZM6.02126 14.8073L5.19263 13.9787L1.41449 17.7568L2.24317 18.5854L6.02126 14.8073ZM8.95069 7.28244C8.95069 7.60603 8.68835 7.86837 8.36475 7.86837C8.04116 7.86837 7.77882 7.60603 7.77882 7.28244C7.77882 4.55923 9.99433 2.34375 12.7175 2.34375C13.0411 2.34375 13.3035 2.6061 13.3035 2.92969C13.3035 3.25329 13.0411 3.51563 12.7175 3.51563C10.6405 3.51563 8.95069 5.2054 8.95069 7.28244Z"
        />
      </svg>
    );
  }
};
