import React, { forwardRef } from 'react';
import { Control, Controller, RegisterOptions as ValidationRules } from 'react-hook-form';
import { Checkbox, CheckboxProps } from './Checkbox';

type HookFormCheckboxType = {
  control: Control;
  name: string;
  rules?: ValidationRules;
} & CheckboxProps;

/**
 * A wrapper to allow the controlled Checkbox component to work with react-hook-form
 * using the provided Controller helper component
 */
export const HookFormCheckbox = forwardRef<HTMLInputElement, Omit<HookFormCheckboxType, 'checked'>>(
  ({ control, name, rules, ...inputProps }, ref) => (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, ...props }): JSX.Element => (
        <Checkbox
          {...inputProps}
          {...props}
          ref={ref}
          checked={field.value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => field.onChange(e.target.checked)}
        />
      )}
    />
  )
);

HookFormCheckbox.displayName = 'HookFormCheckbox';
