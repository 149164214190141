import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetRealityCheckQueryVariables = Types.Exact<{
  xTenantId: Types.Scalars['ID']['input'];
  userProfileId: Types.Scalars['ID']['input'];
  realityCheckId: Types.Scalars['ID']['input'];
}>;


export type GetRealityCheckQuery = { __typename?: 'Query', getRealityCheck: { __typename?: 'RealityCheckResponse', data?: { __typename?: 'RealityCheckSuccess', elapsedCount?: number | null, interval?: string | null, status?: Types.RealityCheckStatus | null, mediatorType?: string | null } | null } };


export const GetRealityCheckDocument = gql`
    query GetRealityCheck($xTenantId: ID!, $userProfileId: ID!, $realityCheckId: ID!) {
  getRealityCheck(
    xTenantID: $xTenantId
    userProfileId: $userProfileId
    realityCheckId: $realityCheckId
  ) {
    data {
      elapsedCount
      interval
      status
      mediatorType
    }
  }
}
    `;

/**
 * __useGetRealityCheckQuery__
 *
 * To run a query within a React component, call `useGetRealityCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRealityCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRealityCheckQuery({
 *   variables: {
 *      xTenantId: // value for 'xTenantId'
 *      userProfileId: // value for 'userProfileId'
 *      realityCheckId: // value for 'realityCheckId'
 *   },
 * });
 */
export function useGetRealityCheckQuery(baseOptions: Apollo.QueryHookOptions<GetRealityCheckQuery, GetRealityCheckQueryVariables> & ({ variables: GetRealityCheckQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRealityCheckQuery, GetRealityCheckQueryVariables>(GetRealityCheckDocument, options);
      }
export function useGetRealityCheckLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRealityCheckQuery, GetRealityCheckQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRealityCheckQuery, GetRealityCheckQueryVariables>(GetRealityCheckDocument, options);
        }
export function useGetRealityCheckSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetRealityCheckQuery, GetRealityCheckQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetRealityCheckQuery, GetRealityCheckQueryVariables>(GetRealityCheckDocument, options);
        }
export type GetRealityCheckQueryHookResult = ReturnType<typeof useGetRealityCheckQuery>;
export type GetRealityCheckLazyQueryHookResult = ReturnType<typeof useGetRealityCheckLazyQuery>;
export type GetRealityCheckSuspenseQueryHookResult = ReturnType<typeof useGetRealityCheckSuspenseQuery>;
export type GetRealityCheckQueryResult = Apollo.QueryResult<GetRealityCheckQuery, GetRealityCheckQueryVariables>;