import { ReactNode } from 'react';
import { BonusHistoryItem, BonusStatusType } from '../../../models/bonuses.model';
import tableLookup from '../../../utils/table-lookup';

export enum BonusIconStateType {
  Positive = 'Positive',
  Negative = 'Negative',
}

export type BonusIconStateTableElement = BonusStatusType | BonusIconStateType;

/**
 * Configuration table for Bonus Icon state
 *
 * bonus status | icon state
 */
export const BonusIconStateTable = [
  [BonusStatusType.PartQualified, BonusIconStateType.Positive],
  [BonusStatusType.Active, BonusIconStateType.Positive],
  [BonusStatusType.Fulfilled, BonusIconStateType.Positive],
  [BonusStatusType.Revoked, BonusIconStateType.Negative],
  [BonusStatusType.Cancelled, BonusIconStateType.Negative],
  [BonusStatusType.Declined, BonusIconStateType.Negative],
  [BonusStatusType.Inherited, BonusIconStateType.Negative],
  [BonusStatusType.Completed, BonusIconStateType.Positive],
  [BonusStatusType.Expired, BonusIconStateType.Negative],
  [BonusStatusType.NotQualified, BonusIconStateType.Negative],
  [BonusStatusType.OptedOut, BonusIconStateType.Negative],
  [BonusStatusType.Undeclined, BonusIconStateType.Negative],
  [BonusStatusType.FreeSpinsRewarded, BonusIconStateType.Positive],
  [BonusStatusType.SportsbookRewarded, BonusIconStateType.Positive],
  [BonusStatusType.Pending, BonusIconStateType.Positive],
];

export const getBonusIconState = tableLookup<BonusIconStateTableElement>(BonusIconStateTable);

export type RenderBonusParts = (bonus: BonusHistoryItem) => ReactNode;
