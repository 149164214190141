import React, { Children, FunctionComponent } from 'react';
import { joinStrings } from '../../../../utils/string';
import './SlideLoader.scss';

type SlideLoaderProps = {
  /**
   * If true, component will become visible.
   */
  show: boolean;
  /**
   * Number of blank cards to be presented
   */
  slideCount: number;
};
export const SlideLoader: FunctionComponent<SlideLoaderProps> = ({ show, slideCount }) => {
  return (
    <div className={joinStrings(['slide-loader', show && 'slide-loader--show'])}>
      {Children.toArray(
        Array.from(Array(slideCount), () => {
          return <div className="slide-loader__item" />;
        })
      )}
    </div>
  );
};
