import React, { FunctionComponent, useMemo, useState } from 'react';
import './styles';
import { useDispatch } from 'react-redux';
import { Button } from '../../shared/Button';
import { joinStrings } from '../../../utils/string';
import { ProductType } from '../../Layout/Promotions/PromotionsList/types';
import { setProductTab } from '../../../state/promotions/reducers';
import { PromoTab } from '../types';

type ProductTabsProps = {
  promotionTabs: PromoTab[];
  className?: string;
  isEnabledProductCasino: boolean;
  isEnabledProductSports: boolean;
  isSportProductTab: boolean;
  filterCategoryToProduct: (productName: ProductType) => void;
};
type ArrProductTabsProps = {
  name: string;
  active: boolean;
  enabled: boolean;
  productName: ProductType | string;
};

export const ProductTabs: FunctionComponent<ProductTabsProps> = ({
  promotionTabs,
  isEnabledProductCasino,
  isSportProductTab,
}) => {
  const dispatch = useDispatch();

  const productTabs: ArrProductTabsProps[] = useMemo(() => {
    return promotionTabs.map((promoTab) => ({
      name: promoTab.name,
      active: !isSportProductTab,
      enabled: isEnabledProductCasino,
      productName: promoTab.id,
    }));
  }, [isEnabledProductCasino, isSportProductTab, promotionTabs]);

  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  return (
    <div className="product-tabs">
      {productTabs
        .filter(({ enabled }) => enabled)
        .map((productTab, index) => {
          const isActiveTabIndex = activeTabIndex === index;

          return (
            <Button
              type="button"
              key={`${productTab.name}-${index}`}
              variant="text-alt"
              className={joinStrings([isActiveTabIndex && 'product-tabs--active'])}
              onClick={(): void => {
                setActiveTabIndex(index);
                dispatch(setProductTab(productTab.productName));
              }}
            >
              {productTab.name}
            </Button>
          );
        })}
    </div>
  );
};
