/* eslint-disable quote-props */
import { lazy } from 'react';

export const CashIcon = lazy(() =>
  import('./CashIcon' /* webpackChunkName:  "SVG" */).then((module) => ({ default: module.CashIcon }))
);
export const BonusIcon = lazy(() =>
  import('./BonusIcon' /* webpackChunkName:  "SVG" */).then((module) => ({ default: module.BonusIcon }))
);
export const NavHomeIcon = lazy(() =>
  import('./NavHomeIcon' /* webpackChunkName:  "SVG" */).then((module) => ({ default: module.NavHomeIcon }))
);
export const NavProfileIcon = lazy(() =>
  import('./NavProfileIcon' /* webpackChunkName:  "SVG" */).then((module) => ({
    default: module.NavProfileIcon,
  }))
);
export const NavBellIcon = lazy(() =>
  import('./NavBellIcon' /* webpackChunkName:  "SVG" */).then((module) => ({
    default: module.NavBellIcon,
  }))
);
export const NavCherryIcon = lazy(() =>
  import('./NavCherryIcon' /* webpackChunkName:  "SVG" */).then((module) => ({
    default: module.NavCherryIcon,
  }))
);
export const NavGiftIcon = lazy(() =>
  import('./NavGiftIcon' /* webpackChunkName:  "SVG" */).then((module) => ({
    default: module.NavGiftIcon,
  }))
);
export const InformationIcon = lazy(() =>
  import('./InformationIcon' /* webpackChunkName:  "SVG" */).then((module) => ({ default: module.InformationIcon }))
);
/**
 * Search icon is used on page load, so don't lazy load it, else we'll include
 * all icons on init.
 */
export * from './NavSearchIcon';
export * from './PromosOn';
export * from './SafeSecure';
export * from './CloseIcon';
export * from './ClockIcon';
export * from './SearchIcon';

export const MenuBtn = lazy(() =>
  import('../Icons/MenuBtn' /* webpackChunkName:  "SVG" */).then((module) => ({
    default: module.MenuBtn,
  }))
);
export const MenuReturnArrow = lazy(() =>
  import('../Icons/MenuReturnArrow' /* webpackChunkName:  "SVG" */).then((module) => ({
    default: module.MenuReturnArrow,
  }))
);
export const ExitIcon = lazy(() =>
  import('../Icons/ExitIcon' /* webpackChunkName:  "SVG" */).then((module) => ({
    default: module.ExitIcon,
  }))
);
export const NavSlotsIcon = lazy(() =>
  import('../Icons/NavSlotsIcon' /* webpackChunkName:  "SVG" */).then((module) => ({
    default: module.NavSlotsIcon,
  }))
);
export const NavBallIcon = lazy(() =>
  import('../Icons/NavBallIcon' /* webpackChunkName:  "SVG" */).then((module) => ({
    default: module.NavBallIcon,
  }))
);
export const NavLiveCasinoIcon = lazy(() =>
  import('../Icons/NavLiveCasinoIcon' /* webpackChunkName:  "SVG" */).then((module) => ({
    default: module.NavLiveCasinoIcon,
  }))
);
export const NavHelpCenterIcon = lazy(() =>
  import('../Icons/NavHelpCenterIcon' /* webpackChunkName:  "SVG" */).then((module) => ({
    default: module.NavHelpCenterIcon,
  }))
);
export const NavLocationIcon = lazy(() =>
  import('../Icons/NavLocationIcon' /* webpackChunkName:  "SVG" */).then((module) => ({
    default: module.NavLocationIcon,
  }))
);
export const EditIcon = lazy(() =>
  import('../Icons/EditIcon' /* webpackChunkName:  "SVG" */).then((module) => ({
    default: module.EditIcon,
  }))
);
export const BackIcon = lazy(() =>
  import('../Icons/BackIcon' /* webpackChunkName:  "SVG" */).then((module) => ({
    default: module.BackIcon,
  }))
);
export const PinHome = lazy(
  () =>
    import('../Icons/PinHome' /* webpackChunkName:  "SVG" */).then((module) => ({
      default: module.PinHome,
    }))
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) as any;
export const ProfileIcon = lazy(() =>
  import('../Icons/ProfileIcon' /* webpackChunkName:  "SVG" */).then((module) => ({
    default: module.ProfileIcon,
  }))
);
export const SettingsIcon = lazy(() =>
  import('../Icons/SettingsIcon' /* webpackChunkName:  "SVG" */).then((module) => ({ default: module.SettingsIcon }))
);
export const GamblingControlsIcon = lazy(() =>
  import('../Icons/GamblingControlsIcon' /* webpackChunkName:  "SVG" */).then((module) => ({
    default: module.GamblingControlsIcon,
  }))
);
export const HelpIcon = lazy(() =>
  import('../Icons/HelpIcon' /* webpackChunkName:  "SVG" */).then((module) => ({ default: module.HelpIcon }))
);
export const UnitedKingdomFlag = lazy(() =>
  import('../Icons/UnitedKingdomFlag' /* webpackChunkName:  "SVG" */).then((module) => ({
    default: module.UnitedKingdomFlag,
  }))
);
export const JackpotIcon = lazy(() =>
  import('../Icons/JackpotIcon' /* webpackChunkName:  "SVG" */).then((module) => ({
    default: module.JackpotIcon,
  }))
);
export const VipIcon = lazy(() =>
  import('../Icons/VipIcon' /* webpackChunkName:  "SVG" */).then((module) => ({
    default: module.VipIcon,
  }))
);
