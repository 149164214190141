import React, { Children, JSXElementConstructor, ReactElement, useMemo } from 'react';
import { useTranslations } from '../../../hooks/useTranslationsHelper';

interface TransProps {
  keyProp: string;
  children: React.ReactNode;
  params?: { [key: string]: string | number };
}

type ElementConstructor = ReactElement<unknown, string | JSXElementConstructor<unknown>>;

export const Trans: React.FC<TransProps> = ({ keyProp, children, params }) => {
  const { t } = useTranslations();
  const translation = t(keyProp, params);

  const withoutTagsRegExp = new RegExp(/(?<=<\d>)(.*?)(?=<\/\d>)/, 'g');
  const translationParts = translation.split(/(<\d>.*?<\/\d>)/);
  const transToNodes = translation.match(withoutTagsRegExp);

  const resultChildren: ElementConstructor[] = useMemo(() => {
    const newChildren = Children.map(
      children as ElementConstructor,
      (component: ElementConstructor) => component && typeof component === 'object' && component
    )
      .filter((component) => component)
      .map((component, index) => React.cloneElement(component, undefined, transToNodes?.[index]));

    return newChildren;
  }, []);

  if (!translation) {
    return <>{keyProp}</>;
  }

  return (
    <>
      {translationParts.map((part) => {
        const isTaged = part.match(withoutTagsRegExp);
        const nodeIndex = Number(part[1]);

        return isTaged ? resultChildren?.[nodeIndex] : part;
      })}
    </>
  );
};
