import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MessageDeleteMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type MessageDeleteMutation = { __typename?: 'Mutation', delete_messages_item?: { __typename?: 'delete_one', id: string } | null };


export const MessageDeleteDocument = gql`
    mutation MessageDelete($id: ID!) {
  delete_messages_item(id: $id) {
    id
  }
}
    `;
export type MessageDeleteMutationFn = Apollo.MutationFunction<MessageDeleteMutation, MessageDeleteMutationVariables>;

/**
 * __useMessageDeleteMutation__
 *
 * To run a mutation, you first call `useMessageDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMessageDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [messageDeleteMutation, { data, loading, error }] = useMessageDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMessageDeleteMutation(baseOptions?: Apollo.MutationHookOptions<MessageDeleteMutation, MessageDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MessageDeleteMutation, MessageDeleteMutationVariables>(MessageDeleteDocument, options);
      }
export type MessageDeleteMutationHookResult = ReturnType<typeof useMessageDeleteMutation>;
export type MessageDeleteMutationResult = Apollo.MutationResult<MessageDeleteMutation>;
export type MessageDeleteMutationOptions = Apollo.BaseMutationOptions<MessageDeleteMutation, MessageDeleteMutationVariables>;