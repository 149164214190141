/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, FunctionComponent, useRef, Suspense, useMemo } from 'react';
import { LoadingIndicator } from '../LoadingIndicator';
import { MaskedInput, MaskedInputProps } from '../MaskedInput';
import { Select, SelectOption } from '../Select';
import { SelectProps } from '../Select/Select.shared';
import { FilterableCountryCode } from './FilterableCountryCode';
import { PhoneNumber, allowedPhoneValues, anyLetterCharacterPattern, delimiter } from '../../../utils/phone-number';
import { joinStrings } from '../../../utils/string';
import { countryCodeOptions } from './countryCodes';
import './TelephoneInput.scss';

interface DataFormEvent<T> extends React.FormEvent<T> {
  data: string;
}

export type TelephoneInputProps = {
  /**
   * True if performing async validation
   */
  isValidating?: boolean;
  /**
   * Used for phone number
   */
  maskedInputProps: Omit<MaskedInputProps, 'value' | 'onChange' | 'onBlur'>;
  /**
   * Used for country code section of number therefore options should be a list of country code values.
   */
  selectProps: Omit<SelectProps, 'label' | 'value' | 'onChange' | 'onBlur'> & {
    label: string;
    /**
     * Whether to use the AutocompleteInput component instead of a standard dropdown
     */
    useFilterable?: boolean;
  };
  /**
   * Full telephone number including country code.
   */
  value?: string;
  /**
   * Called when value is changed by user.
   */
  onChange?: (telephone: string) => void;
  /**
   * Called when the select or masked input blurs.  Can be used to trigger validation.
   */
  onBlur?: (event: React.FocusEvent) => void;

  onFocus?: (event: React.FocusEvent) => void;
};

/**
 * Essentially just a wrapper around the Select & Masked input components to combine two values
 * into one form value.
 *
 * ### Example - As Controlled Input
 *
 * ```tsx
 * const UseStateExample = () => {
 *   const [value, setValue] = useState('+44 0142922222');
 *   return (
 *     <Fragment>
 *       <TelephoneInput
 *         name="telephone"
 *         value={value}
 *         label="Telephone"
 *           selectProps={{
 *            label: 'Country Code',
 *            name: 'countryCode',
 *            placeholder: 'Select value',
 *            options: [
 *              {
 *                label: '+49',
 *                value: '+49'
 *              },
 *              {
 *                label: '+44',
 *                value: '+44'
 *              }
 *            ]
 *           }}
 *           maskedInputProps={{
 *             label: 'Telephone',
 *             name: 'phoneNumber'
 *           }}
 *         onChange={setValue}
 *       />
 *     </Fragment>
 *   );
 * };
 * ```
 *
 * ### Example - React Hook Form
 *
 * An example which uses `react-hook-form` as the controller of state.
 *
 * ```tsx
 * import { useForm, Controller } from 'react-hook-form';
 *
 * const HookFormExample = ({
 *   onSubmit
 * }: {
 *   onSubmit: (formValues) => void;
 * }) => {
 *   const { control, handleSubmit } = useForm({
 *     defaultValues: { telephone: '+44 ' }
 *   });
 *   return (
 *     <form onSubmit={handleSubmit(onSubmit)}>
 *       <Controller
 *         name='telephone'
 *         control={control}
 *         as={
 *           <TelephoneInput
 *              label="Telephone"
 *              selectProps={{
 *                name: 'countryCode'
 *                label: 'Country Code',
 *                placeholder: 'Select value',
 *                options: [
 *                  {
 *                    label: '+49',
 *                    value: '+49'
 *                  },
 *                  {
 *                    label: '+44',
 *                    value: '+44'
 *                  }
 *               ]
 *             }}
 *             maskedInputProps={{
 *               label: 'Telephone',
 *               name: 'phoneNumber'
 *             }}
 *           />
 *         }
 *       />
 *       <Button type='submit'>Submit</Button>
 *    </form>
 *   );
 * };
 * ```
 */

export const TelephoneInput: FunctionComponent<TelephoneInputProps> = ({
  maskedInputProps,
  selectProps,
  value = '',
  isValidating,
  onFocus,
  onChange,
  onBlur,
}) => {
  const phone = new PhoneNumber(value, countryCodeOptions);
  const selectValue = useMemo(() => (phone.countryCode ? `+${phone.countryCode}` : ''), [value, phone.countryCode]);
  const inputValue = useMemo(() => phone.national, [value, phone.national]);
  const hasNumberBlurred = useRef(false);
  const ref = useRef<HTMLInputElement>(null);

  const [resultOptions, setResultOptions] = useState<SelectOption[]>([{ label: '', value: '' }]);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const targetValue = e.target.value;

    onChange?.(selectValue + delimiter + targetValue);
  };

  const onBeforeInputHandler = (e: DataFormEvent<HTMLInputElement>): void => {
    const key = e.data;
    const valid = !anyLetterCharacterPattern.test(key) || allowedPhoneValues.includes(key);

    if (!valid) {
      e.preventDefault();
    }
  };

  const selectChangeHandler = (countryCode: string): void => {
    onChange?.(countryCode + delimiter + inputValue);
  };

  const inputBlurHandler = (e: React.FocusEvent<HTMLInputElement, Element>): void => {
    hasNumberBlurred.current = true;
    onBlur?.(e);
  };

  const selectBlurHandler = (e: React.FocusEvent<HTMLSelectElement>): void => {
    // Prevent blur event if number input not touched
    if (hasNumberBlurred.current) {
      onBlur && onBlur(e);
    } else {
      e.stopPropagation();
    }
  };

  useEffect(() => {
    const sortedOptions = selectProps.options.sort((a, b) => {
      return a.name && b.name && a.name > b.name ? 1 : -1;
    });

    setResultOptions(sortedOptions);

    // This validations takes place for the registration step when
    // the user enters his phone and switch the country code value automatically.
    if (phone.international !== value) {
      onChange?.(phone.international);
    }
  }, []);

  return (
    <div
      className={joinStrings([
        'telephone-input',
        selectProps.useFilterable && 'telephone-input--filterable',
        isValidating && 'telephone-input--validating',
      ])}
      data-testid="telephone-input"
    >
      {selectProps.useFilterable ? (
        <FilterableCountryCode {...selectProps} value={selectValue} onChange={selectChangeHandler} />
      ) : (
        <div className="telephone-input__select">
          <Suspense>
            <Select
              {...selectProps}
              options={resultOptions}
              value={selectValue}
              onFocus={onFocus}
              onBlur={selectBlurHandler}
              onChange={selectChangeHandler}
              hasSearchInput
              phoneInputRef={ref}
            />
          </Suspense>
        </div>
      )}
      <div className="telephone-input__input">
        <MaskedInput
          {...maskedInputProps}
          value={inputValue}
          onFocus={onFocus}
          onBlur={inputBlurHandler}
          onChange={onChangeHandler}
          onBeforeInput={onBeforeInputHandler}
          ref={ref}
          type="tel"
        />
      </div>
      {isValidating && <LoadingIndicator />}
    </div>
  );
};
