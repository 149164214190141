import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Prompt } from '../../../shared/Prompt';
import { usePrevious } from '../../../../hooks/helperHooks';
import { useUserContext } from '../../../../providers/UserProvider';
import { setGameLaunchTime } from '../../../../state/game/reducers';
import { APP_URLS, xTenantId } from '../../../../consts';
import promptImg from '../../../../assets/Prompt/registration-abandon-prompt.png';
import { selectDisabledGamesData } from '../../../../state/game';
import { useGetRealityCheckLazyQuery } from '../../../../libs/graphql/baseAppAPI/queries/__generated__/reality-check-get.generated';
import { useRealityCheckUpdatedSubscription } from '../../../../libs/graphql/baseAppAPI/subscriptions/__generated__/reality-check-updated.generated';
import { useContinueRealityCheckMutation } from '../../../../libs/graphql/baseAppAPI/mutations/__generated__/reality-check-continue.mutation.generated';
import { useTranslations } from '../../../../hooks/useTranslationsHelper';
import { useStopRealityCheckMutation } from '../../../../libs/graphql/baseAppAPI/mutations/__generated__/reality-check-stop.mutation.generated';
import './GameSessionInGame.scss';
import { decodedMinutesFromISO8601 } from '../../../GamblingControls/helper';

type ResponseProp = string | null | undefined;

export const GameSessionInGame: FunctionComponent = () => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userProfileId } = useUserContext();
  const { areGamesDisabled } = useSelector(selectDisabledGamesData);
  const prevAreGamesDisabledState = usePrevious(areGamesDisabled);

  const [fetchRealityCheck, { data: realityCheckData }] = useGetRealityCheckLazyQuery({
    variables: {
      xTenantId,
      userProfileId,
      realityCheckId: userProfileId,
    },
    fetchPolicy: 'network-only',
  });

  const [setRealityCheckContinueMutation, { error: saveContinueError }] = useContinueRealityCheckMutation({
    variables: {
      xTenantId,
      userProfileId,
      realityCheckId: userProfileId,
    },
  });

  const [setRealityCheckStopMutation, { error: saveStopError }] = useStopRealityCheckMutation({
    variables: {
      xTenantId,
      userProfileId,
      realityCheckId: userProfileId,
    },
    refetchQueries: ['GetRealityCheck'],
  });

  const { data: realityCheckUpdatedData, error: realityCheckUpdatedError } = useRealityCheckUpdatedSubscription({
    variables: {
      userProfileId,
    },
  });

  const [interval, setInterval] = useState<number>(Number.MAX_SAFE_INTEGER);
  const [showPrompt, setShowPrompt] = useState<boolean>(false);

  const onContinuePlaying = (): void => {
    setShowPrompt(false);

    setRealityCheckContinueMutation();
  };

  const onDismiss = (): void => {
    setRealityCheckStopMutation();

    navigate(APP_URLS.home);
    dispatch(setGameLaunchTime());
  };

  const handleRealityCheckModal = (status: ResponseProp, interval: ResponseProp): void => {
    if (status === 'PendingAction') {
      const intervalInMinutes = decodedMinutesFromISO8601[String(interval)];

      setInterval(intervalInMinutes);

      setShowPrompt(true);
    }
  };

  useEffect(() => {
    if (!areGamesDisabled && prevAreGamesDisabledState) {
      dispatch(setGameLaunchTime());
    }
  }, [areGamesDisabled]);

  useEffect(() => {
    const { interval, status } = realityCheckUpdatedData?.realityCheckUpdated?.current || {};

    handleRealityCheckModal(status, interval);
  }, [realityCheckUpdatedData]);

  useEffect(() => {
    const { interval, status } = realityCheckData?.getRealityCheck.data || {};

    handleRealityCheckModal(status, interval);
  }, [realityCheckData]);

  useEffect(() => {
    const resultError = realityCheckUpdatedError || saveContinueError || saveStopError;

    resultError && console.error(resultError);
  }, [saveContinueError, saveStopError, realityCheckUpdatedError]);

  useEffect(() => {
    fetchRealityCheck();
  }, []);

  return (
    <>
      <Prompt
        cannotClose
        show={showPrompt}
        title={t('game.reality-check.warning', { gameTimePlayed: interval })}
        image={{
          src: promptImg,
          alt: 'Session over',
        }}
        PrimaryButton={{
          text: 'Continue playing',
          onClick: onContinuePlaying,
        }}
        SecondaryButton={{
          onClick: onDismiss,
          text: 'Got it - back to lobby',
        }}
        className="game-session-in-game__prompt"
      >
        <Link to={APP_URLS.myAccount.activity} className="game-session-in-game__link">
          {t('game.reality-check.open-history')}
        </Link>
      </Prompt>
    </>
  );
};
