import React, { ImgHTMLAttributes, MutableRefObject, ReactElement, useRef } from 'react';
import { useAssetLoadedListener } from '../../../hooks/helperHooks';

type DynamicImageProps = {
  src: Nullable<string>;
  containerRef?: MutableRefObject<HTMLElement | null>;
  loadingClassName?: string;
  className?: string;
  props: ImgHTMLAttributes<HTMLImageElement>;
};

const DynamicImageComponent = ({
  src,
  containerRef,
  className,
  loadingClassName = className,
  props = {},
}: DynamicImageProps): ReactElement => {
  const imgRef = useRef<HTMLImageElement>(null);
  const { assetLoaded, onAssetLoaded } = useAssetLoadedListener([src]);

  return (
    <img
      {...props}
      className={!assetLoaded ? loadingClassName : className}
      onLoad={onAssetLoaded}
      src={src || ''}
      alt={props.alt}
      ref={imgRef}
    />
  );
};

export const DynamicImage = React.memo(DynamicImageComponent);
DynamicImage.displayName = 'DynamicImage';
