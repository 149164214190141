import { FC, useEffect, useMemo } from 'react';
import { defaultRegistrationFormState } from './config';
import { RegistrationStepComponentProps, RegistrationStepConfig, RegistrationStepperStepComponent } from './types';
import { BaseRegistrationStepConfig, FlattenedProfileData, useRegistrationStepper } from '../../hooks/registration';
import { CheckboxFormField, PasswordInputFormField } from '../shared/Form';
import { useUserValidationConstraints } from './const';
import { useRegistrationSteps } from '../../hooks/registration/useRegistrationSteps';
import { useRegistrationFieldConfig } from '../../hooks/registration/useRegistrationFieldConfig';

interface Props {
  onStepChange: (step: BaseRegistrationStepConfig) => void;
}

const StepComponent = ({
  invalidateStep,
  setSelectedStep,
  completeStep,
  previousStep,
  registrationErrors,
  selectedStepIndex,
  previouslySelectedStepIndex,
  formFields,
  mergePartialData,
  ...props
}: {
  stepConfig: RegistrationStepConfig;
  stepIndex: number;
} & RegistrationStepComponentProps): JSX.Element => {
  const { StepComponent } = props.stepConfig;
  const selected = props.stepIndex === selectedStepIndex;
  const registrationSteps = useRegistrationSteps();

  return (
    <StepComponent
      {...props}
      selected={selected}
      previousStepIndex={previouslySelectedStepIndex}
      formFields={formFields}
      stepConfigs={registrationSteps as RegistrationStepConfig[]}
      stepperEnabled
      onSelectPreviousStep={previousStep}
      onCompleteStep={completeStep}
      onSelectStep={setSelectedStep}
      onUnmount={mergePartialData}
    />
  );
};

export const RegistrationContainer: FC<Props> = ({ onStepChange }) => {
  const userValidationConstraints = useUserValidationConstraints();
  const registrationSteps = useRegistrationSteps();
  const regFields = useRegistrationFieldConfig();

  const formSteps = registrationSteps.map((step, i) => {
    const formStep: RegistrationStepConfig = {
      ...step,
      buttonSize: 'medium',
      backEnabled: i > 0,
    };

    return formStep;
  });

  const formFields = useMemo(() => {
    // const { onPasswordVisibilityChange } = callbacks;
    const passwordField = regFields.password as PasswordInputFormField<Record<string, unknown>>;

    passwordField.props = {
      ...passwordField.props,
      maxLength: userValidationConstraints.password.rules.maxLength?.value,
    };

    // Disable click on T&C and Privacy Policy label to toggle checkbox
    regFields.acceptTerms = {
      ...(regFields.acceptTerms as CheckboxFormField<Partial<FlattenedProfileData>>),
      props: { disableLabelClick: true },
    };

    return regFields;
  }, []);

  const registrationStepper = useRegistrationStepper({
    registrationSteps: formSteps,
    defaultFormState: defaultRegistrationFormState,
    formFields,
  });
  const stepperSteps = registrationSteps.filter((config) => !!config.StepperStepComponent);

  useEffect(() => {
    if (onStepChange) {
      onStepChange(registrationStepper.selectedStep);
      window.scrollTo(0, 0);
    }
  }, [onStepChange, registrationStepper.selectedStep]);

  return (
    <div className="registration__container">
      <div>
        {stepperSteps.map((stepConfig, stepIndex) => {
          // Casting to stop unnecessary undefined check.
          // TS fails to infer type correctly based upon the filtering above
          const StepperStepComponent = stepConfig.StepperStepComponent as RegistrationStepperStepComponent;
          const disabled = !registrationStepper.status[stepIndex].enabled;
          const selected = stepIndex === registrationStepper.selectedStepIndex;
          const { completed } = registrationStepper.status[stepIndex];
          const stepNumber = stepIndex + 1;

          return (
            <StepperStepComponent
              {...{
                stepConfig,
                disabled,
                selected,
                completed,
                stepNumber,
                stepIndex,
                buttonSize: 'large',
                stepCount: stepperSteps.length,
                lastStep: stepIndex === stepperSteps.length - 1,
              }}
              onSelectStep={registrationStepper.setSelectedStep}
              key={stepConfig.id}
            />
          );
        })}
      </div>

      {registrationSteps.map((stepConfig, stepIndex) => {
        return (
          <StepComponent
            selected={false}
            formFields={formFields}
            stepConfigs={[]}
            {...{ stepIndex, stepConfig }}
            {...registrationStepper}
            key={stepConfig.id}
          />
        );
      })}
    </div>
  );
};
