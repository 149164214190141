import React, { Children, forwardRef, useEffect, useState } from 'react';
import { Icon } from '../Icon';
import { PasswordRequirements, PasswordRequirement } from './PasswordRequirements';
import { PasswordRequirementType } from '../../Registration/types';
import { Input, InputProps } from '../Input';
import { joinStrings } from '../../../utils/string';
import { InputIconButton } from '../InputIconButton';
import { useUserValidationConstraints } from '../../Registration/const';
import { PropValidationRuleLogType, withPropValidation } from '../../../utils/withPropValidation/withPropValidation';
import './PasswordInput.scss';

const displayRequirements = (type: 'always' | 'onFocus' | undefined, isFocused: boolean): boolean => {
  switch (type) {
    case 'onFocus':
      return isFocused;
    default:
      return true;
  }
};

export enum PasswordStrength {
  invalid = 1,
  weak = 2,
  medium = 3,
  strong = 4,
}
export type PasswordInputProps = {
  /**
   * Optional, defaults to true. Determines whether to render the icon button used to show/hide the password.
   */
  showRevealToggle?: boolean;
  /**
   * Optional, defaults to false. If true, a list of password requirements will be shown below the input box.
   * These are predefined in the AppConfig, but can be overridden using the customRequirements prop.
   */
  showRequirements?: boolean;
  /**
   * Optionally override the default requirements list defined in the AppConfig.
   * If showRequirements is set to true, it will display these lists in two columns beneath the password input box.
   *
   * Here's an example of defining requirements. You can use translated strings...
   *
   * customRequirements={{
   *    mustInclude: [t('password-requirements-must-include-character-length')]
   *    mustNotInclude: [t('password-requirements-must-not-include-username')]
   * }}
   *
   * ... or you can use i18n definitions.
   * If the definition doesn't exist, then the definition will be rendered as plaintext.
   *
   * customRequirements={{
   *    mustInclude: ['password-requirements-must-include-character-length']
   *    mustNotInclude: ['password-requirements-must-not-include-username']
   * }}
   */
  customRequirements?: {
    mustInclude: Partial<Record<PasswordRequirementType, PasswordRequirement>>;
    mustNotInclude: Partial<Record<PasswordRequirementType, PasswordRequirement>>;
  };
  /**
   * Optional, defaults to false.
   * If true and `strength` prop is defined,
   * a strength indicator will show above the password input box to the right side.
   */
  showStrengthIndicator?: boolean;
  /**
   * Optional. Defines a password strength with four possible values ranging from 'invalid' to 'strong'
   * Effects the styling of the strength indicator if showStrengthIndicator is set to true
   */
  strength?: PasswordStrength;
  /**
   * Optional. Object list of all password requirements, stating if they're true or false
   */
  requirementsValidationState?: Partial<Record<PasswordRequirementType, boolean>>;
  /**
   * Optional callback that's invoked when user toggles the password visibility.
   */
  onPasswordVisibilityChange?: (visible: boolean) => void;
} & InputProps;

/**
 * <br />
 * <br />
 * A controlled Password Input component with the ability to show/hide the password,
 * display requirements and display a strength meter.
 * This component has been made presentational with regards to validation and strength.
 * It will display defined requirements
 * but these should be enforced in the parent component (usually via form validation),
 * then communicated via the `passwordMeetsRequirements` prop.
 * Same with the strength meter - it will display a given strength,
 * but this should be calculated by the parent component and
 * communicated via the `strength` prop.
 * <br /> <br />
 * This component wraps the [Input Component](/story/input--default)
 * and will therefore accept Input props and pass them down.
 * <br /> <br />
 *
 * ### Notes
 * - When switching between showing and hiding password text, the hidden password 'discs' may look smaller
 * than the text size depending on the font-family. For example, 'Lato' maintains the size, 'Arial' does not.
 *
 *
 * ### Example - As Controlled Input
 *
 * ```tsx
 * const UseStateExample = () => {
 *   const [value, setValue] = useState('Initial Value');
 *   return (
 *     <Fragment>
 *       <PasswordInput
 *         name="password"
 *         value={value}
 *         label="Password"
 *         showStrengthIndicator
 *         strength={PasswordStrength.strong}
 *         customRequirements={{
 *          mustInclude: [{message: 'password-requirements-must-include-character-length'}]
 *          mustNotInclude: [{message: 'password-requirements-must-not-include-username'}]
 *         }}
 *         onChange={(e) => setValue(e.target.value)}
 *       />
 *     </Fragment>
 *   );
 * };
 * ```
 *
 * ### Example - React Hook Form
 *
 * An example which uses `react-hook-form` as the controller of state.
 *
 * ```tsx
 * import { useForm, Controller } from 'react-hook-form';
 * const HookFormExample = ({
 *   onSubmit
 * }: {
 *   onSubmit: (formValues) => void;
 * }) => {
 *   const { control, handleSubmit } = useForm({
 *     defaultValues: { password: 'Initial Value' }
 *   });
 *   return (
 *     <form onSubmit={handleSubmit(onSubmit)}>
 *       <Controller
 *         name='password'
 *         control={control}
 *         as={
 *          <PasswordInput
 *            label="Password"
 *            showStrengthIndicator
 *            strength={PasswordStrength.strong}
 *            customRequirements={{
 *              mustInclude: [{message: 'password-requirements-must-include-character-length'}]
 *              mustNotInclude: [{message: 'password-requirements-must-not-include-username'}]
 *            }}
 *          />
 *         }
 *       />
 *       <Button type='submit'>Submit</Button>
 *    </form>
 *   );
 * };
 * ```
 * Password Inputs will stretch to fill the width of their containers.
 *
 */

const Component = forwardRef<HTMLInputElement, PasswordInputProps>(
  (
    {
      strength,
      showRevealToggle = true,
      showStrengthIndicator = false,
      showRequirements = false,
      customRequirements,
      requirementsValidationState,
      theme = 'light',
      onPasswordVisibilityChange,
      ...inputProps
    },
    ref
  ) => {
    const userValidationConstraints = useUserValidationConstraints();
    const [passwordIsHidden, setPasswordIsHidden] = useState(true);
    const [isFocused, setIsFocused] = useState(false);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
    const useCustomRequirements =
      customRequirements &&
      (Object.values(customRequirements.mustInclude).length > 0 ||
        Object.values(customRequirements.mustNotInclude).length > 0);

    const requirements = useCustomRequirements ? customRequirements : userValidationConstraints.password.requirements;

    const passwordRevealButton = showRevealToggle && (
      <InputIconButton
        onClick={(): void => {
          onPasswordVisibilityChange?.(passwordIsHidden);
          setPasswordIsHidden(!passwordIsHidden);
        }}
        type="button"
        icon={<Icon variant={passwordIsHidden ? 'Eye' : 'EyeSlash'} />}
        onBlur={(e): void => e.stopPropagation()}
        aria-label={'password-aria-label'}
        aria-pressed={passwordIsHidden ? 'false' : 'true'}
        inputIsDisabled={!!inputProps.disabled}
      />
    );
    const requirementsVisible = showRequirements && displayRequirements('onFocus', isFocused);

    useEffect(() => {
      return (): void => clearTimeout(timeoutId as NodeJS.Timeout);
    }, [timeoutId]);

    return (
      <div className={joinStrings(['password-input', inputProps.disabled && 'password-input--disabled'])}>
        {/* Strength Indicator */}
        {showStrengthIndicator && strength ? (
          <div
            className={`
              password-input__strength-indicator password-input__strength-bars--${PasswordStrength[strength]}
            `}
          >
            <span className="password-input__strength-msg" aria-live="polite">
              {`password strength: ${PasswordStrength[strength]}`}
            </span>
            <div className="password-input__strength-bars">
              {Children.toArray(
                Array.from(Array(strength), (_s, i) => (
                  <div data-testid={`strength-bar_${i}`} className="password-input__strength-bar" />
                ))
              )}
            </div>
          </div>
        ) : null}

        <input readOnly className="password-input--hidden" type="password" autoComplete="new-password" />

        {/* Input Component */}
        <Input
          {...inputProps}
          theme={theme}
          type={passwordIsHidden ? 'password' : 'text'}
          iconRight={passwordRevealButton}
          ref={ref}
          onFocus={(e): void => {
            clearTimeout(timeoutId as NodeJS.Timeout);
            inputProps.onFocus && inputProps.onFocus(e);
            setIsFocused(true);
          }}
          onBlur={(e: React.FocusEvent<HTMLInputElement>): void => {
            inputProps.onBlur && inputProps.onBlur(e);
            setTimeoutId(setTimeout(() => setIsFocused(false), 100));
          }}
        />
        {/* Requirements Section */}
        {requirementsVisible && requirements && requirementsValidationState ? (
          <PasswordRequirements
            requirements={requirements}
            validationState={requirementsValidationState}
            theme={theme}
          />
        ) : undefined}
      </div>
    );
  }
);

Component.displayName = 'PasswordInput';

const logType: PropValidationRuleLogType = 'error';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const validationRules: any = [
  {
    message: `requirementsValidationState prop is required if showRequirements is set to true`,
    logType,
    validate: ({ showRequirements, requirementsValidationState }: PasswordInputProps): boolean => {
      return showRequirements === true && requirementsValidationState === undefined;
    },
  },
];

export const PasswordInput = withPropValidation({ Component, validationRules });
