import React from 'react';
import { ClockIcon } from '../../../shared/SVG/Icons/ClockIcon';
import systemBalanceIcon from '../../.././../assets/Cashier/system-bonus-balance.png';
import './styles';

interface PromotionAvailableBlockProps {
  expireDateTag: string | null;
}

export const PromotionAvailableBlock: React.FC<PromotionAvailableBlockProps> = ({ expireDateTag }) => {
  const isWelcomeBonus = expireDateTag?.toLowerCase().includes('welcome');

  return (
    <>
      {expireDateTag ? (
        <div className="promotion-available-block">
          {!isWelcomeBonus && <span>Available until </span>}
          <span>{expireDateTag}</span>
          {isWelcomeBonus ? (
            <img className="promotion-available-block__badge" src={systemBalanceIcon} alt="balance" />
          ) : (
            <ClockIcon outlined />
          )}
        </div>
      ) : null}
    </>
  );
};
