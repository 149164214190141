import { formatDate } from 'date-fns';
import isFunction from 'lodash/fp/isFunction';
import React from 'react';
import { FlattenedProfileData } from '../../../../../hooks/registration';
import { FormField, FormFieldType } from '../../../../shared/Form';
import { Grid, GridItem, calculateGridItemConfigurations } from '../../../../shared/Grid';
import { Icon, IconVariant } from '../../../../shared/Icon';
import { Paragraph } from '../../../../shared/Paragraph';
import { UnitedKingdomFlag } from '../../../../shared/SVG/Icons';
import { AccountFieldSetConfig } from '../../../types';
import './styles';
import { MarketingData } from '../../../../Registration/types';
import { useTranslations } from '../../../../../hooks/useTranslationsHelper';

/**
 * Get a nicely formatted version of a form field value.
 *
 * @param formState
 */
const formattedFieldValue = <T extends FlattenedProfileData>(
  formField: FormField<T>,
  formState: Partial<T>,
  t: (arg: string) => string
): string => {
  const value = formState[formField.name as keyof T] as unknown;

  if (value === undefined || value === '') return '-';

  switch (formField.type) {
    case FormFieldType.Checkbox:
      return value ? t('my-account.yes') : t('my-account.yes');

    case FormFieldType.DateOfBirthInput: {
      const hourOffset = new Date(value as string).getTimezoneOffset() / 60;
      const incomeDate = new Date(value as string);

      return formatDate(new Date(incomeDate.setHours(incomeDate.getHours() + hourOffset)), 'dd/MM/yyyy');
    }

    case FormFieldType.Select: {
      const option = formField.options.find((o) => o.value === value);

      /* istanbul ignore next */
      return option ? option.label : '-';
    }

    default:
      return (value as string) || '-';
  }
};

export type AccountDetailsSummaryProps<T extends FlattenedProfileData | FlattenedProfileData> = {
  fieldSets: AccountFieldSetConfig<T>[];
  formFields: Record<string, FormField<T>>;
  formState: Partial<T>;
};

const marketingIconMap: Record<string, IconVariant> = {
  optInSms: 'Sms',
  optInTelephone: 'Phone',
  optInEmail: 'Email',
  optInPost: 'Post',
};

const contactPreferenceIconMap: Record<string, IconVariant> = {
  SGContactPrefPhone: 'Phone',
  SGContactPrefEmail: 'Email',
  SGContactPrefWhatsapp: 'WhatsApp',
};

export const AccountDetailsSummary = <T extends FlattenedProfileData | FlattenedProfileData>({
  fieldSets,
  formFields,
  formState,
}: AccountDetailsSummaryProps<T>): React.ReactElement => {
  const { t } = useTranslations();

  return (
    <div className="account-details-summary">
      {fieldSets.map((fieldSet) => {
        if (fieldSet.hidden) {
          return <React.Fragment key={`${fieldSet.label} ${fieldSet.id}`} />;
        }

        const { fields } = fieldSet;
        const newFields = fields.map((marketingPreferenceName) => {
          const { name } = marketingPreferenceName;

          return {
            ...marketingPreferenceName,
            hidden: name === 'toggleAllMarketing' ? false : !formState?.[name as keyof MarketingData],
          };
        });
        const columns = newFields.map((field) => field.overviewColumns || 12);
        const gridItemConfigs = calculateGridItemConfigurations(columns);

        if (fieldSet.id === 'marketing-details-fieldset') {
          const marketingFieldNames = newFields.filter(({ name }) => String(name).includes('opt'));

          const isSubscribedToMarketing = marketingFieldNames.some(
            ({ name }) => formState?.[name as keyof MarketingData]
          );

          return (
            <div key={fieldSet.id} className="account-details-summary__marketing-subscriptions">
              <div className="label" style={{ display: 'block' }}>
                {t('my-account.home.settings.communication.subscriptions')}
              </div>
              {isSubscribedToMarketing ? (
                marketingFieldNames.map(({ name, hidden }) => {
                  const field = formFields[name as string];
                  const isHidden = hidden === true || (isFunction(hidden) && hidden(formState));

                  return (
                    !isHidden && (
                      <span key={name as string} className="account-details-summary__marketing-subscription">
                        <Icon variant={marketingIconMap[field.name as string]} />

                        <span>{typeof field.label === 'string' && field.label}</span>
                      </span>
                    )
                  );
                })
              ) : (
                <Paragraph size="sm">{t('my-account.home.settings.communication.not-subscribed-text')}</Paragraph>
              )}
            </div>
          );
        }

        if (fieldSet.id === 'contact-details-fieldset-preferences') {
          const activeContactPreferences = fields.filter(({ name }) => formState[name]);

          return (
            <div key={fieldSet.id} className="account-details-summary__contact-subscriptions">
              <div className="label" style={{ display: 'block' }}>
                {t('my-account.home.settings.communication.contact-pref-by')}:
              </div>
              {activeContactPreferences.length === 0 && (
                <Paragraph size="sm" noMargin>
                  {t('my-account.home.settings.communication.no-pref-text')}
                </Paragraph>
              )}
              {activeContactPreferences.length > 0 &&
                activeContactPreferences.map(({ name, hidden }) => {
                  const field = formFields[name as string];
                  const isHidden = hidden === true || (isFunction(hidden) && hidden(formState));

                  return (
                    !isHidden && (
                      <span key={name as string} className="account-details-summary__contact-subscription">
                        <Icon variant={contactPreferenceIconMap[field.name as string]} />

                        <span>{typeof field.label === 'string' && field.label}</span>
                      </span>
                    )
                  );
                })}
            </div>
          );
        }

        if (fieldSet.id === 'address-details-fieldset') {
          return (
            <div key={fieldSet.id}>
              <div className="label">{t('my-account.home.settings.address')}</div>
              <Grid columns={12}>
                {fields
                  .filter(({ name }) => formState[name])
                  .map(({ name, hidden }, fieldIndex) => {
                    const field = formFields[name as string];
                    const gridItemConfig = gridItemConfigs[fieldIndex];
                    const isHidden = hidden === true || (isFunction(hidden) && hidden(formState));

                    return (
                      !isHidden && (
                        <GridItem
                          startCol={gridItemConfig.startCol}
                          endCol={gridItemConfig.endCol}
                          key={name as string}
                          className="account-details-summary__detail-grid-item"
                          data-testid={`review-step-item-${String(field.name)}`}
                        >
                          <span className="account-details-summary__detail-grid-item-value">
                            {formattedFieldValue(field, formState, t)}
                          </span>
                        </GridItem>
                      )
                    );
                  })}
              </Grid>
            </div>
          );
        }

        return (
          <Grid key={fieldSet.id} columns={12} gap="m">
            {fields.map(({ name, hidden }, fieldIndex) => {
              const field = formFields[name as string];
              const gridItemConfig = gridItemConfigs[fieldIndex];
              const isHidden = hidden === true || (isFunction(hidden) && hidden(formState));

              return (
                !isHidden && (
                  <GridItem
                    startCol={gridItemConfig.startCol}
                    endCol={gridItemConfig.endCol}
                    key={name as string}
                    className="account-details-summary__detail-grid-item"
                    data-testid={`review-step-item-${String(field.name)}`}
                  >
                    <span className="label">{typeof field.label === 'string' && field.label}</span>
                    <span className="account-details-summary__detail-grid-item-value">
                      {field.name === 'language' && <UnitedKingdomFlag />}
                      {formattedFieldValue(field, formState, t)}
                    </span>
                  </GridItem>
                )
              );
            })}
          </Grid>
        );
      })}
    </div>
  );
};
