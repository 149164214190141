import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { PromotionsBlock } from '../PromotionsBlock';
import { ProductName, ProductType, PromotionTabsType } from './types';
import { Spinner } from '../../../shared/Spinner';
import { NavigationTemplate } from '../../../../layouts/NavigationTemplate';
import { promotionsNav } from '../../../Navigation/mocks';
import { productTabName, promotionTabName } from '../../../../state/promotions';
import { useSelector } from 'react-redux';
import { locale } from '../../../../consts';
import { Promotions } from '../../../Navigation/types';
import {
  BonusesGetCategoriesQuery,
  useBonusesGetCategoriesLazyQuery,
} from '../../../../libs/graphql/betzoneDirectusAPI/queries/__generated__/bonuses-categories.query.generated';
import { useFetchMergedPromotions } from '../../../../hooks/useFetchMergedPromotions';
import { ApolloError } from '@apollo/client';
import { Endpoint } from '../../../../models/api.model';
import { ParsedPromo } from '../types';

const PromotionsList: FunctionComponent = (): JSX.Element => {
  const title = 'Promotions';

  const currentProductTab = useSelector(productTabName);
  const currentPromotionTab = useSelector(promotionTabName);

  const [products, setProducts] = useState<Promotions>(promotionsNav);

  const isActivePromotions = currentPromotionTab === PromotionTabsType.ACTIVE;

  const { mergedFilteredPromotions, mergedHistoryPromotions, isPromosLoading } =
    useFetchMergedPromotions(currentPromotionTab);

  const handleProductTabs = (data: BonusesGetCategoriesQuery): void => {
    const directusTabs = data?.bonus_collections?.map((promo) => {
      const promoTranslationName = String(promo.translations?.[0]?.display_name);
      const promoTranslationType = promoTranslationName?.toLocaleLowerCase();

      const casinoNav = {
        id: ProductType.CASINO,
        name: ProductName.CASINO,
      };
      const sportNav = {
        id: ProductType.SPORT,
        name: ProductName.SPORT,
      };
      const namedNav = {
        id: promoTranslationType,
        name: promoTranslationType,
      };

      const casinoTab = promoTranslationType === ProductType.CASINO && casinoNav;
      const sportTab = promoTranslationType === ProductType.SPORT && sportNav;

      return casinoTab || sportTab || namedNav;
    });

    setProducts({
      tabs: [promotionsNav.tabs[0], ...directusTabs],
      categories: [...promotionsNav.categories],
    });
  };

  const getPromotionsByProducts = (promotions: ParsedPromo[]): ParsedPromo[] =>
    promotions?.filter((promo) => currentProductTab === ProductType.ALL || promo.products?.includes(currentProductTab));

  const [fetchBonusesCategories, { loading: fetchedBonusesCategoriesLoading }] = useBonusesGetCategoriesLazyQuery({
    variables: {
      language: locale,
    },
    onCompleted: handleProductTabs,
    onError: (error: ApolloError) => console.error(error),
    context: { endpoint: Endpoint.betzoneDirectusAPI },
  });

  const bonuses = useMemo(
    () => getPromotionsByProducts(mergedFilteredPromotions),
    [mergedFilteredPromotions, currentProductTab]
  );

  const historyBonuses = useMemo(
    () => getPromotionsByProducts(mergedHistoryPromotions),
    [mergedFilteredPromotions, currentProductTab]
  );

  const isLoading = useMemo(
    () => fetchedBonusesCategoriesLoading || isPromosLoading,
    [fetchedBonusesCategoriesLoading, isPromosLoading]
  );

  useEffect(() => {
    (async (): Promise<void> => {
      await fetchBonusesCategories();
    })();
  }, []);

  return (
    <NavigationTemplate promotions={products}>
      {isLoading ? (
        <Spinner />
      ) : (
        <PromotionsBlock
          title={title}
          isActivePromotions={isActivePromotions}
          className="promotions-block__usual"
          promotionsFiltered={bonuses}
          promotionsHistory={historyBonuses}
        />
      )}
    </NavigationTemplate>
  );
};

export default PromotionsList;
