import debounce from 'lodash/debounce';
import { useEffect, useRef, useState } from 'react';
import { passiveOption } from '../../consts';

type ScrollPosition = 'start' | 'end';

type UseYOverflow = {
  ref: React.MutableRefObject<HTMLDivElement | null>;
  isOverflowing: boolean;
  scrollPosition?: ScrollPosition;
};

/**
 * Detect whether there is Y overflow occurring in the element which the ref is attached to
 * and receive state updates on scroll/window resize.
 */
export function useYOverflow(): UseYOverflow {
  const ref: React.MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [scrollPosition, setScrollPosition] = useState<ScrollPosition>();

  useEffect(() => {
    /* istanbul ignore if */
    if (!ref || !ref.current) return;

    const { current } = ref;

    const listener = debounce(() => {
      const isNowOverflowing = !!current && current.clientHeight < current.scrollHeight;

      setIsOverflowing(isNowOverflowing);

      if (isNowOverflowing && current) {
        setScrollPosition(
          current.scrollTop === 0
            ? 'start'
            : current.scrollHeight - current.scrollTop - current.clientHeight < 1
              ? 'end'
              : undefined
        );
      } else {
        setScrollPosition(undefined);
      }
    }, 5);

    listener();
    window.addEventListener('resize', listener, passiveOption);
    current.addEventListener('scroll', listener, passiveOption);

    return () => {
      window.removeEventListener('resize', listener, passiveOption as EventListenerOptions);
      current.removeEventListener('scroll', listener, passiveOption as EventListenerOptions);
    };
  }, [ref]);

  return {
    ref,
    isOverflowing,
    scrollPosition,
  };
}
