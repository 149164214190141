import React, { FunctionComponent } from 'react';
import { AccountPageWrapper, AccountPageWrapperProps } from '../AccountPageWrapper';
import { AccountTile } from '../AccountTile';
import { AccountDetailsEditModal } from './AccountDetailsEditModal';
import { AccountDetailsSummary } from './AccountDetailsSummary';
import { Paragraph } from '../../../shared/Paragraph';
import { useSnackbar } from '../../../../hooks/snackbar';
import { Heading } from '../../../shared/Heading';
import { joinStrings } from '../../../../utils/string';
import { AccountDetails } from '../AccountDetails';
import { Link } from '../../../shared/Link';
import { APP_URLS } from '../../../../consts';
import { Icon } from '../../../shared/Icon';
import { AccountTileListItem } from '../AccountTileListItem';
import { openNewWindow } from '../../../Navigation/NavigationLink/helper';
import './styles';
import { Trans } from '../../../shared/Trans';
import { useTranslations } from '../../../../hooks/useTranslationsHelper';

export const MyProfileSetting: FunctionComponent<AccountPageWrapperProps> = (props) => {
  const { t } = useTranslations();
  const { addSnack } = useSnackbar();
  const showSourceOfIncome = false;
  const showLabelActionRequired = false;
  const isAFFSubmitted = false;
  const isAFFPassed = false;

  const inputFields = [
    t('my-account.home.my-profile.main-soi'),
    t('my-account.home.my-profile.job'),
    t('my-account.home.my-profile.annual-sallary'),
  ];

  const handleUpdateSuccess = (updateType: string): void => {
    const isMarketing = updateType === 'marketing-details';

    addSnack({
      type: 'success',
      message: isMarketing
        ? t('my-account.home.my-profile.update-success')
        : t('my-account.home.my-profile.phone-success'),
      theme: 'dark',
    });
  };

  const handleUpdateFailure = (updateType: string): void => {
    const isMarketing = updateType === 'marketing-details';

    addSnack({
      type: 'error',
      theme: 'dark',
      message: isMarketing
        ? t('my-account.home.my-profile.update-failed')
        : t('my-account.home.my-profile.phone-failed'),
    });
  };

  const startAFFJourney = (): void => {
    console.log('Start AFF Journey');
  };

  const onGoToHelp = (): void => {
    openNewWindow(APP_URLS.help);
  };

  return (
    <AccountPageWrapper {...props} className="my-profile" hasCloseButton={props.hasCloseButton}>
      <div className="my-profile__header">
        <Heading level={3} noMargin>
          {t('my-account.my-profile')}
        </Heading>
      </div>
      <div className="my-profile__content">
        <Heading level={6}>{t('my-account.home.my-profile.heading.personal')}</Heading>
        <AccountTile>
          <AccountDetails
            SummaryComponent={AccountDetailsSummary}
            EditModalComponent={AccountDetailsEditModal}
            editMode="none"
            id="personal-details"
            fieldSets={[
              {
                id: 'personal-details',
                label: 'Personal Details',
                labelSrOnly: true,
                fields: [
                  {
                    name: 'givenName',
                    columns: 12,
                    overviewColumns: 12,
                    disabled: true,
                  },
                  {
                    name: 'familyName',
                    columns: 12,
                    overviewColumns: 12,
                    disabled: true,
                  },
                  {
                    name: 'birthDate',
                    columns: 12,
                    overviewColumns: 12,
                    disabled: true,
                  },
                ],
              },
            ]}
          />
          <Paragraph size="sm" className="my-profile__help-text">
            <Trans keyProp="my-profile.personal-details.update-info">
              Contact our{' '}
              <Link className="my-profile__customer-care-link" onClick={onGoToHelp}>
                Customer Care
              </Link>{' '}
              team if you want to change any personl details.
            </Trans>
          </Paragraph>
        </AccountTile>
        {showSourceOfIncome && (
          <>
            <div className="my-profile__sof-section">
              <Heading level={6}>{t('my-account.home.my-profile.soi')}</Heading>
              <Paragraph
                className={joinStrings([
                  'my-profile__badge',
                  showLabelActionRequired && 'my-profile__badge--required',
                  isAFFSubmitted && 'my-profile__badge--in-review',
                  isAFFPassed && 'my-profile__badge--verified',
                ])}
                size="xxs"
              >
                {showLabelActionRequired && t('my-account.home.my-profile.action')}
                {isAFFSubmitted && t('my-account.home.my-profile.review')}
                {isAFFPassed && t('my-account.home.my-profile.verified')}
              </Paragraph>
            </div>

            <AccountTile>
              <div className="my-profile__sof-lists">
                {showSourceOfIncome &&
                  inputFields.map((item) => {
                    return (
                      <div className="my-profile__sof-list-item">
                        <AccountTileListItem text={item} icon="Plus" iconAlign="right" onClick={startAFFJourney} />
                      </div>
                    );
                  })}
                {/* TODO: Consider if we use SOF and AFF journeys */}
                <Paragraph className="my-profile__sof-description">
                  {showSourceOfIncome && 'Tell us more about you to reduce your chances of future restrictions.'}
                  {isAFFSubmitted &&
                    'Your documents are in review. If you need to update any details you can do this here.'}
                  {isAFFPassed && 'Tell us more about you to reduce your chances of future restrictions.'}
                  {!showSourceOfIncome && (
                    <Icon variant="Edit" onClick={startAFFJourney} className="my-profile__sof-description--icon" />
                  )}
                </Paragraph>
              </div>
            </AccountTile>
          </>
        )}

        <Heading level={6}>{t('my-account.home.my-profile.heading.details')}</Heading>
        <AccountTile>
          <AccountDetails
            SummaryComponent={AccountDetailsSummary}
            EditModalComponent={AccountDetailsEditModal}
            id="email-details"
            editMode="none"
            fieldSets={[
              {
                id: 'email-details-fieldset',
                label: t('my-account.home.my-profile.email-address'),
                labelSrOnly: true,
                fields: [
                  {
                    name: 'email',
                    columns: 12,
                    overviewColumns: 12,
                  },
                ],
              },
            ]}
          />
          <Paragraph size="sm" className="my-profile__help-text">
            <Trans keyProp="my-profile.personal-details.update-info">
              Contact our{' '}
              <Link className="my-profile__customer-care-link" onClick={onGoToHelp}>
                Customer Care
              </Link>{' '}
              team if you want to change any personl details.
            </Trans>
          </Paragraph>
        </AccountTile>
        <AccountTile>
          <AccountDetails
            SummaryComponent={AccountDetailsSummary}
            EditModalComponent={AccountDetailsEditModal}
            id="address-details"
            editMode="none"
            fieldSets={[
              {
                id: 'address-details-fieldset',
                label: t('my-account.home.my-profile.address'),
                labelSrOnly: true,
                fields: [
                  {
                    name: 'addrStreetAddress',
                    columns: 12,
                    overviewColumns: 12,
                  },
                ],
              },
            ]}
          />
          <Paragraph size="sm" className="my-profile__help-text">
            <Trans keyProp="my-profile.personal-details.update-info">
              Contact our{' '}
              <Link className="my-profile__customer-care-link" onClick={onGoToHelp}>
                Customer Care
              </Link>{' '}
              team if you want to change any personl details.
            </Trans>
          </Paragraph>
        </AccountTile>

        <AccountTile>
          <AccountDetails
            onError={(): void => {
              handleUpdateFailure('telephone');
            }}
            onUpdate={(): void => {
              handleUpdateSuccess('telephone');
            }}
            SummaryComponent={AccountDetailsSummary}
            EditModalComponent={AccountDetailsEditModal}
            editMode="modal"
            editTitle={t('my-account.home.my-profile.edit-mobile')}
            id="marketing-details"
            fieldSets={[
              {
                id: 'contact-details-fieldset',
                label: '',
                editDescription: '',
                labelSrOnly: true,
                fields: [
                  {
                    name: 'mobileNumber',
                    columns: 12,
                    overviewColumns: 12,
                  },
                ],
              },
            ]}
          />
        </AccountTile>
      </div>
    </AccountPageWrapper>
  );
};
