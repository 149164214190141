export const APP_URLS = {
  home: '/',
  registration: '/registration',
  help: 'http://betzone71040065407504206.freshdesk.com',
  cookiePolicy: '/cookie-policy',
  virtualGames: {
    home: '/virtual-games',
  },
  myAccount: {
    home: '/my-account',
    profile: '/my-account/profile',
    activity: '/my-account/activity',
    settings: '/my-account/settings',
    gamblingControls: '/my-account/gambling-controls',
    bonusHistory: '/bonus-history',
  },
  gameDetails: '/game-details/:name/*',
  promotions: '/promotions',
  promotion: '/promotions/:id',
  contentPage: '/pages/:slug',
  f2p: '/f2p/:slug',
  gameLobby: {
    main: '/game-lobby',
    dynamicPage: '/game-lobby/:category/:subcategory?',
  },
};
