import { useNavigate, useLocation } from 'react-router-dom';

// TODO: update this component
type UseQueryParams = {
  getQueryParam: (name: string) => string | null;
  removeQueryParams: (names: Array<string>) => void;
};

/**
 * Get & remove query params using react-router
 * More info: https://reactrouter.com/web/example/query-parameters
 */
export function useQueryParams(): UseQueryParams {
  const query = new URLSearchParams(useLocation().search);
  const navigate = useNavigate();

  function getQueryParam(name: string): string | null {
    return query.get(name);
  }

  function removeQueryParams(names: Array<string>): void {
    let requiresHistoryReplace = false;

    for (const name of names) {
      if (query.get(name) !== null) {
        query.delete(name);
        requiresHistoryReplace = true;
      }
    }

    if (requiresHistoryReplace) {
      navigate({ search: query.toString() });
    }
  }

  return {
    getQueryParam,
    removeQueryParams,
  };
}

export const useFullHost = (): string => {
  const { protocol, hostname, port } = window.location;

  return `${protocol}//${hostname}${port ? `:${port}` : ''}`;
};

export const useFullPath = (): string => {
  const { pathname, search } = useLocation();

  return `${pathname}${search ? `?${search}` : ''}`;
};

export const useFullLocation = (): string => {
  const path = useFullPath();
  const host = useFullHost();

  return `${host}${path}`;
};
