import React, { Fragment, useContext, useMemo, useRef, useState } from 'react';
import { animated, useTransition } from 'react-spring';
import { RegistrationContext } from '../Registration.context';
import { FlattenedProfileData } from '../../../hooks/registration';
import { Paragraph } from '../../shared/Paragraph';
import { RegistrationStepComponentProps } from '../types';
import { FieldErrorSummary } from '../../../utils/form';
import { Button } from '../../shared/Button';
import { Typography } from '../../shared/Typography';
import { joinStrings } from '../../../utils/string';
import { AbandonPrompt } from './AbandonPrompt';
import { PopUpHandler } from '../../shared/PopupHandler';
import { Icon } from '../../shared/Icon';
import { DefaultFormStep } from './DefaultFormStep';
import { RegistrationAddressFormStep } from './RegistrationAddressFormStep';
import './styles';
import { useTranslations } from '../../../hooks/useTranslationsHelper';
import { Trans } from '../../shared/Trans';

enum Policies {
  TermsAndConditions = 'TermsAndConditions',
  PrivacyPolicy = 'PrivacyPolicy',
  VerificationPolicy = 'VerificationPolicy',
}

/**
 * Specific registration form.
 */
export const RegistrationFormStep: React.ComponentType<
  RegistrationStepComponentProps & {
    termsAndConditions?: React.ReactNode;
    privacyPolicy?: React.ReactNode;
  }
> = (formStepProps) => {
  const {
    stepConfig,
    previousStepIndex,
    stepIndex,
    selected,
    formState,
    selectedStep,
    onSelectPreviousStep,
    onCompleteStep,
  } = formStepProps;
  const { t } = useTranslations();
  const [modalValue, setModalValue] = useState<Policies | null>(null);
  const emailErrorRef = useRef<string>();
  const isFirstStep = stepConfig.id === 'account-details';

  const { onCloseAbandonPrompt, abandonPromptOpen, onConfirmAbandon } = useContext(RegistrationContext);

  // Transition direction on enter. Will not slide in on first load
  const translateValue =
    typeof previousStepIndex === 'undefined' ? '0%' : stepIndex > previousStepIndex ? '100%' : '-100%';

  const transitions = useTransition(selected, null, {
    from: {
      transform: `translateX(${translateValue})`,
      position: 'absolute',
    },
    enter: {
      transform: 'translateX(0)',
      position: 'relative',
    },
    leave: {
      transform: 'translateX(0)',
      position: 'absolute',
    },
  });

  const handleFormValidationError = (
    errorSummary: Record<string, FieldErrorSummary>,
    formState: Partial<FlattenedProfileData>
  ): void => {
    const emailErrors = errorSummary.email;

    if (!emailErrors) {
      emailErrorRef.current = undefined;
    } else if (
      formState.email !== emailErrorRef.current &&
      emailErrors.messages.some((m) => m.id === 'account-details-form-email-duplicate')
    ) {
      emailErrorRef.current = formState.email;
    }
  };

  const isTargetStep = (stepName: string): boolean => stepConfig.id.toLowerCase().includes(stepName);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isTargetAbandonPrompt = useMemo(() => selectedStep?.id === stepConfig.id, [selectedStep]);

  const isAddressStep = isTargetStep('address');

  if (stepConfig.submissionStep) {
    // Option not currently available in dialogs so manually applied here.
    stepConfig.submitButtonText = t('register.step.final.join-button.text');
  }

  const TermsAndConditions = (): JSX.Element => (
    <Paragraph>
      <Trans keyProp="register.step.final.age-confirmation">
        By submitting, I confirm I'm 18 or over and I agree with the Casino Brand{' '}
        <Button
          type="button"
          variant="text"
          className="button-link"
          onClick={(): void => {
            setModalValue(Policies.TermsAndConditions);
          }}
        >
          Terms and Conditions
        </Button>{' '}
        and{' '}
        <Button
          type="button"
          variant="text"
          className="button-link"
          onClick={(): void => {
            setModalValue(Policies.PrivacyPolicy);
          }}
        >
          Privacy Policy{' '}
        </Button>
      </Trans>
    </Paragraph>
  );

  const AcceptMarketing = (): JSX.Element => <Paragraph>{t('register.step.final.accept-marketing')}</Paragraph>;

  if (stepConfig.fieldSets.some((fs) => fs.fields.some((f) => f.name === 'acceptTerms'))) {
    formStepProps.formFields.acceptTerms.label = (
      <Typography size="sm">
        <TermsAndConditions />
      </Typography>
    );
  }

  if (stepConfig.fieldSets.some((fs) => fs.fields.some((f) => f.name === 'acceptMarketingPreferences'))) {
    formStepProps.formFields.acceptMarketingPreferences.label = (
      <Typography size="sm">
        <AcceptMarketing />
      </Typography>
    );
  }

  return (
    <div
      className={joinStrings([
        'registration-form-step',
        selected && 'registration-form-step--running',
        stepConfig.submissionStep && 'registration-form-step--submission',
      ])}
    >
      {transitions.map(({ props, key, item }) => (
        <animated.div key={key} style={props} className="step-footer">
          {item && (
            <Fragment>
              {isAddressStep ? (
                <RegistrationAddressFormStep
                  onSelectPreviousStep={(): void => {
                    onSelectPreviousStep && onSelectPreviousStep();
                  }}
                  onCompleteStep={(): void => {
                    onCompleteStep && onCompleteStep(stepIndex, formState);
                  }}
                  {...formStepProps}
                />
              ) : (
                <DefaultFormStep
                  headingLevel={5}
                  onFormError={handleFormValidationError}
                  onSelectPreviousStep={(): void => {
                    onSelectPreviousStep && onSelectPreviousStep();
                  }}
                  onCompleteStep={(): void => {
                    onCompleteStep && onCompleteStep(stepIndex, formState);
                  }}
                  validationMode="onBlur"
                  reValidateMode="onChange"
                  {...formStepProps}
                />
              )}
              {selected && isFirstStep && (
                <span className="registration-form-step__enter">
                  {t('register.steps.press-enter-text')}
                  <Icon className="registration-form-step__enter-icon" variant="KeyboardReturn" />
                </span>
              )}
            </Fragment>
          )}
        </animated.div>
      ))}

      {modalValue === Policies.TermsAndConditions && (
        <PopUpHandler
          isOpen={modalValue === Policies.TermsAndConditions}
          onClose={(): void => {
            setModalValue(null);
          }}
          header="Accept terms heading"
          modalProps={{ 'aria-label': 'accept-terms-heading' }}
          drawerProps={{
            showCloseBtn: false,
          }}
        >
          Some text
        </PopUpHandler>
      )}

      <AbandonPrompt
        show={isTargetAbandonPrompt && abandonPromptOpen}
        onDismiss={onCloseAbandonPrompt}
        title="Leaving already?"
        message={`Your email address is used to log in to your account.
          We won't send you any marketing emails without your permission.`}
        onConfirmAbandon={onConfirmAbandon}
      />
    </div>
  );
};
