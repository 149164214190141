import React, { FC } from 'react';
import './BonusBar.scss';

interface BonusBarProps {
  value?: number;
  height?: number;
}

export const BonusBar: FC<BonusBarProps> = ({ value = 0, height = 8 }) => (
  <svg width="100%" height={height}>
    <g>
      <rect className="bonus-bar__background" width="100%" height={height} rx="5px" />
      <rect className="bonus-bar__amount" width={`${value}%`} height={height} rx="5px" />
    </g>
  </svg>
);
