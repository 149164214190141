import { formatDate, parseISO } from 'date-fns';
import React, { FunctionComponent, ReactNode } from 'react';

import './styles';
import { Pill } from '../../../shared/Pill';
import { MyAccountActivityGiftIcon } from '../../../shared/Gift';
import { useTranslations } from '../../../../hooks/useTranslationsHelper';

type MyAccountActivityPanelProps = {
  /**
   * The activity title.
   */
  title?: string;

  /**
   * The stake amount.
   */
  stake?: number;
  /**

  /**
   * The activity status.
   */
  status?: string;

  /**
   * The activity amount.
   */
  amount?: number;

  /**
   * A ReactNode representing the activity icon.
   */
  ActivityIcon?: ReactNode;

  /**
   * The transaction date.
   */
  transactionDate?: string;

  /**
   * The cash balance after the transaction.
   */
  cashBalanceAfter?: number;

  /**
   * The total balance after the transaction.
   */
  totalBalanceAfter?: number;

  /**
   * The isFreeBet bets
   */
  isFreeBet?: boolean;
};

export const MyAccountActivityPanel: FunctionComponent<MyAccountActivityPanelProps> = ({
  title,
  stake,
  status,
  amount,
  isFreeBet,
  ActivityIcon,
  transactionDate,
  cashBalanceAfter,
  totalBalanceAfter,
}) => {
  const { t } = useTranslations();
  const defaultClassName = 'my-account-activity-panel';

  return (
    <div className={defaultClassName}>
      <div className={`${defaultClassName}__activity-header`}>
        <span className={`${defaultClassName}__title`}>
          {ActivityIcon}
          {title}

          {status && (
            <Pill size="small">
              <span>{status}</span>
            </Pill>
          )}
        </span>
        <span className={`${defaultClassName}__amount`}>
          {isFreeBet && <MyAccountActivityGiftIcon />}
          &nbsp;
          {`£${Math.abs(amount || stake || 0)}`}
        </span>
      </div>

      <div className={`${defaultClassName}__activity-details`}>
        <div className={`${defaultClassName}__subheading`}>
          {typeof cashBalanceAfter !== 'undefined' && (
            <div className={`${defaultClassName}__balances`}>
              <span
                className={`${defaultClassName}__balances-cash`}
              >{`${t('my-account.cache-balance')} ${cashBalanceAfter}`}</span>

              {typeof totalBalanceAfter !== 'undefined' && (
                <span className={`${defaultClassName}__balances-bonus`}>
                  {`${t('my-account.bonus-balance')} ${totalBalanceAfter - cashBalanceAfter}`}
                </span>
              )}
            </div>
          )}
        </div>

        {transactionDate && (
          <span className={`${defaultClassName}__transaction-date`}>
            {formatDate(parseISO(transactionDate), 'dd/MM/yyyy | HH:mm')}
          </span>
        )}
      </div>
    </div>
  );
};
