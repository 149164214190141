import {
  addMonths,
  addWeeks,
  addYears,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from 'date-fns';

export type PeriodUnit = 'days' | 'weeks' | 'months' | 'years';

export const periodToDays = (value: number, unit: PeriodUnit): string => {
  switch (unit) {
    case 'days':
      return value.toString();

    case 'weeks':
      return differenceInDays(addWeeks(new Date(), value), new Date()).toString();

    case 'months':
      return differenceInDays(addMonths(new Date(), value), new Date()).toString();

    default:
      return differenceInDays(addYears(new Date(), value), new Date()).toString();
  }
};

const formatDuration = (duration: number): string => {
  return duration < 10 ? `0${duration}` : duration.toString();
};

export const formattedTimeDifference = (dateA: Date, dateB: Date, seconds = false, delimiter = ' : '): string => {
  const totalMinutes = differenceInMinutes(dateA, dateB);
  const totalHours = differenceInHours(dateA, dateB);
  const adjustedMinutes = totalMinutes - totalHours * 60;
  const hoursAndMinutes = [formatDuration(totalHours), formatDuration(adjustedMinutes)].join(delimiter);

  if (seconds) {
    const totalSeconds = differenceInSeconds(dateA, dateB);
    const adjustedSeconds = totalSeconds - totalMinutes * 60;

    return [hoursAndMinutes, formatDuration(adjustedSeconds)].join(delimiter);
  }

  return hoursAndMinutes;
};
