import React, { ComponentPropsWithoutRef, FC, ReactNode } from 'react';
import { joinStrings } from '../../../../utils/string';
import './ModalFooter.scss';

export type ModalFooterProps = {
  /**
   * Required. Modal footer content
   */
  children: ReactNode;
} & ComponentPropsWithoutRef<'div'>;

// TODO: Add support for styling right aligned action buttons?
/**
 * A component used to wrap modal footer content within a parent <Modal> component
 */
export const ModalFooter: FC<ModalFooterProps> = ({ className, children, ...props }) => (
  <footer {...props} className={joinStrings(['modal-footer', className])}>
    {children}
  </footer>
);
