import { CategoryOfUsers } from '../components/Navigation/types';
import { NavigationLinkPropsExtended } from '../components/Navigation/Navigation';
import { LinkTargetType } from '../components/Navigation/NavigationLink';
import { SiteMenuGetQuery } from '../libs/graphql/betzoneDirectusAPI/queries/__generated__/site-menu-get.query.generated';

interface IMenuItems {
  __typename?: 'site_menu_menu_item' | 'site_menu_menu_item1_' | 'site_menu_menu_item_1';
  menu_item_id?: {
    __typename?: 'menu_item';
    show_authenticated_only?: boolean | null;
    icon?: {
      __typename?: 'directus_files';
      filename_disk?: string | null;
      width?: number | null;
      height?: number | null;
      title?: string | null;
    } | null;
    hover_icon?: {
      __typename?: 'directus_files';
      filename_disk?: string | null;
      width?: number | null;
      height?: number | null;
      title?: string | null;
    } | null;
    translations?: Array<{
      __typename?: 'menu_item_translations';
      link_text?: string | null;
      link_URL?: string | null;
      open_in_new_tab?: boolean | null;
    } | null> | null;
  } | null;
}

export interface ITransformedNavigationData {
  menuItems: NavigationLinkPropsExtended[];
  lowerMenuItems: NavigationLinkPropsExtended[];
}

export const getImageUrl = (src?: string | null): string => {
  return src ? `${process.env.REACT_APP_BETZONE_DIRECTUS_ASSETS_URL}/${src}` : '';
};

const transformMenuData = (
  menuItems: Array<IMenuItems | null> | undefined | null,
  showDivider = false
): NavigationLinkPropsExtended[] => {
  if (!menuItems) {
    return [];
  }

  return menuItems?.map((item, index, array) => {
    const dividerAfter = index === array.length - 1 && showDivider;
    const { link_text, link_URL, open_in_new_tab } = item?.menu_item_id?.translations?.[0] || {};
    const linkTarget = open_in_new_tab ? LinkTargetType.NewTab : LinkTargetType.SameWindow;
    const showForUsers = item?.menu_item_id?.show_authenticated_only
      ? CategoryOfUsers.Authenticated
      : CategoryOfUsers.AllUser;
    const imageURL = getImageUrl(item?.menu_item_id?.icon?.filename_disk);
    const activeImageURL = getImageUrl(item?.menu_item_id?.hover_icon?.filename_disk);

    return {
      linkTarget,
      dividerAfter,
      showForUsers,
      active: false,
      icon: imageURL,
      to: link_URL || '',
      name: link_text || '',
      linkText: link_text || '',
      activeIcon: activeImageURL,
    };
  });
};

export const transformNavigationData = (data: SiteMenuGetQuery): ITransformedNavigationData => {
  const menuItems = data.site_menu?.menu_items;
  const lowerMenuItems = data.site_menu?.lower_menu_items;

  return { menuItems: transformMenuData(menuItems, true), lowerMenuItems: transformMenuData(lowerMenuItems) };
};
