import { TransactionType } from '../../../../models/transactions.model';
import { TabularDataDetailsItemProps } from '../../../shared/TabularDatalist/TabularDataDetailsItem';
import {
  KambiCouponDescription,
  KambiCouponFlatData,
  KambiCouponsResponse,
  KambiFetchBetStatus,
  KambiFetchCouponRowStatus,
  KambiTransactionType,
  KambiRewardType,
} from '../../../Kambi/types';

export type BetProps = TabularDataDetailsItemProps & {
  number: number;
};

export const SELECTION_TYPE_BET_BUILDER = 'BET_BUILDER';

const BET_TYPES: Record<string, string> = {
  '3-4': 'Trixie',
  '3-7': 'Patent',
  '4-11': 'Yankee',
  '4-15': 'Lucky 15',
  '5-26': 'Canadian',
  '5-31': 'Lucky 31',
  '6-57': 'Heinz',
  '6-63': 'Lucky 63',
  '7-120': 'Super Heinz',
  '8-247': 'Goliath',
};

const COUNT_OUTCOMES_TRANSLATION_KEYS = ['Single', 'Double', 'Treble'];

export class CouponHistory {
  private transactionType: TransactionType;
  public data: KambiCouponFlatData[];
  private propsData: BetProps[];
  private countBets: number;
  private countOutcomes: number;
  private statusValue: string;
  private descriptionValue: KambiCouponDescription;

  constructor(rawData: KambiCouponsResponse, transactionType: TransactionType) {
    this.transactionType = transactionType;
    this.data = this.flatData(rawData);
    this.countBets = rawData.bets?.length;
    this.countOutcomes = rawData.outcomes?.length;
    this.propsData = [];
    this.statusValue = '';
    this.descriptionValue = { text: '' };
  }

  get couponRef(): number {
    return this.data[0].couponRef;
  }

  get rewardType(): string | undefined {
    return this.data[0].rewardType;
  }

  get isFreeBet(): boolean {
    return this.rewardType === KambiRewardType.FREE_BET;
  }

  get status(): string | TransactionType {
    if (!this.statusValue) this.statusValue = this.createStatus();

    return this.statusValue;
  }

  get statusText(): string {
    return this.getStatusText(this.status);
  }

  get stake(): number {
    return this.countBets < this.countOutcomes
      ? this.formatOdds(this.data[this.data.length - 1].betsStakeSum)
      : this.formatOdds(this.data.reduce((acc, curr) => acc + curr.betsStakeSum, 0));
  }

  get payout(): number {
    return this.formatOdds(this.data[0]?.betsPotentialPayoutSum);
  }

  get payoutBoosted(): number {
    return this.countBets < this.countOutcomes
      ? this.formatOdds(this.data[this.data.length - 1].betsPotentialPayoutBoostedSum)
      : this.formatOdds(this.data.reduce((acc, curr) => acc + curr.betsPotentialPayoutBoostedSum, 0));
  }

  get description(): KambiCouponDescription {
    if (!this.descriptionValue.text) this.descriptionValue = this.createDescription();

    return this.descriptionValue;
  }

  get allProps(): BetProps[] {
    this.propsData.length || this.createProps();

    return this.propsData;
  }

  get isSingleBet(): boolean {
    return this.countOutcomes === this.countBets;
  }

  private flatData(rawData: KambiCouponsResponse): KambiCouponFlatData[] {
    const { outcomes, couponRows, bets, betOffers, events } = rawData;

    if (!outcomes || !couponRows || !bets || !betOffers || !events) {
      return [];
    }

    const data: KambiCouponFlatData[] = outcomes.map((outcome) => {
      const betOffer = betOffers.find((betOffer) => betOffer.betOfferId === outcome.betOfferId);

      const event = events.find((event) => event.eventId === outcome.eventId);

      const couponRowOutcomeId = couponRows.find((row) => row.outcomeId === outcome.outcomeId);

      const couponRow =
        couponRowOutcomeId || couponRows.find((row) => row.selectionType === SELECTION_TYPE_BET_BUILDER);

      const rowBets = bets.filter((bet) => {
        return couponRow && bet.couponRowIndexes.includes(couponRow.index);
      });

      return {
        couponRef: rawData.couponRef,
        rewardType: rawData.rewardType,
        isFreeBet: rawData.isFreeBet,
        placedDate: rawData.placedDate,
        ...outcome,
        outcomeStatus: outcome.status,
        ...couponRow,
        couponRowStatus: couponRow?.status,
        ...betOffer,
        ...event,
        bets: rowBets,
        betsStakeSum: rowBets.reduce((acc, bet) => acc + bet.stake, 0),
        betsPayoutSum: rowBets.reduce((acc, bet) => acc + bet.payout, 0),
        betsPotentialPayoutSum: rowBets.reduce((acc, bet) => acc + bet.potentialPayout, 0),
        betsPotentialPayoutBoostedSum: rowBets.reduce((acc, bet) => acc + bet.potentialPayoutBoosted, 0),
        betsBetStatuses: rowBets.map((bet) => bet.betStatus),
      } as KambiCouponFlatData;
    });

    return data;
  }

  private createProps(): BetProps[] {
    this.propsData = this.data.map((d, index) => {
      return {
        title: this.getTitle(d),
        content: [d.eventName],
        results: d.settledInfo?.result?.score ? d.settledInfo.result.score : '',
        dateTime: d.placedDate,
        subtitle: d.eventGroups.map((eg) => eg.name).join(' / '),
        status: d.couponRowStatus,
        stake: this.data.length > 1 ? this.formatOdds(d.betsStakeSum) : 0,
        payout: this.data.length > 1 ? this.formatOdds(d.betsPotentialPayoutSum) : 0,
        payoutBoosted: this.data.length > 1 ? this.formatOdds(d.betsPotentialPayoutBoostedSum) : 0,
        number: d.index + 1,
        isSingleBet: this.isSingleBet,
        marker: index + 1,
      };
    });

    return this.propsData;
  }

  private getTitle(d: KambiCouponFlatData): KambiCouponDescription {
    let odds = '';
    let oldOdds = '';
    const oddsCheck =
      d.selectionType === SELECTION_TYPE_BET_BUILDER ? 0 : d.payoutOdds || d.oddsBoosted || d.playedOdds;
    const text = `${d.boType}: ${d.label === 'X' ? 'Empate' : d.label} | ${this.getStatusText(d.couponRowStatus)}`;

    if (this.countBets !== 1 || this.countOutcomes !== 1) {
      odds = this.formatOddsFull(oddsCheck);

      if (!d.payoutOdds && d.oddsBoosted) {
        oldOdds = this.formatOddsFull(d.playedOdds);
      }
    }

    return { text, odds, oldOdds };
  }

  private createDescription(): KambiCouponDescription {
    if (!this.data[0]) return { text: '' };

    if (this.countBets === this.countOutcomes && this.countBets !== 1) {
      if (this.data[0].bets[0].couponRowIndexes.length === 2)
        return {
          text: `${this.countBets} x double`,
        };

      return {
        text: `${this.countBets} x single`,
      };
    }

    if (this.countBets === 1) {
      const odds = this.formatOddsFull(this.data[0]?.bets[0]?.betOddsBoosted || this.data[0]?.bets[0]?.playedOdds);
      const oldOdds = this.data[0]?.bets[0]?.betOddsBoosted
        ? this.formatOddsFull(this.data[0]?.bets[0]?.playedOdds)
        : '';

      const text = COUNT_OUTCOMES_TRANSLATION_KEYS[this.countOutcomes]
        ? COUNT_OUTCOMES_TRANSLATION_KEYS[this.countOutcomes]
        : `${this.countBets}-Multiply`;

      return { text, odds, oldOdds };
    }

    const key = `${this.countOutcomes}-${this.countBets}`;

    return { text: BET_TYPES[key] || key };
  }

  private getSimpleBetStatus(allBetStatuses: string[]): string | undefined {
    return [
      KambiFetchBetStatus.OPEN,
      KambiFetchBetStatus.CANCELLED,
      KambiFetchBetStatus.REJECTED,
      KambiFetchBetStatus.LOST,
    ].find((betStatus: string) => allBetStatuses.includes(betStatus));
  }

  private getSuccessStatus(allBetStatuses: string[], successType: string): string | undefined {
    if (allBetStatuses.includes(successType))
      return this.transactionType === KambiTransactionType.SETTLE ? successType : KambiFetchBetStatus.SETTLED;
  }

  /**
   * https://eyas.atlassian.net/wiki/spaces/DEV/pages/917012510/Logic+to+deduce+BetSlip+status+MVP+solution
   */
  private createStatus(): string {
    const allBetStatuses = this.data.map((d) => d.betsBetStatuses).flat();
    const simpleBetStatus = this.getSimpleBetStatus(allBetStatuses);

    if (simpleBetStatus) return simpleBetStatus;

    // Handle success statuses
    return (
      this.getSuccessStatus(allBetStatuses, KambiFetchBetStatus.CASHED_OUT) ||
      this.getSuccessStatus(allBetStatuses, KambiFetchBetStatus.WON) ||
      KambiFetchCouponRowStatus.VOID
    );
  }

  private formatOdds(num = 0): number {
    return num / 1000;
  }

  private formatOddsFull(num = 0): string {
    const odds = this.formatOdds(num);

    return odds ? `${odds}${Number.isInteger(odds) ? '.0' : ''}` : '';
  }

  private getStatusText(status = ''): string {
    // Consider to include some deverse here after new requirements
    return 'Open';
  }
}
