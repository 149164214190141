import React, { FunctionComponent, useState } from 'react';
import { useGameSession } from '../../../../hooks/games';
import { Orientation, useDeviceInfo, useOrientation } from '../../../../hooks/useDeviceInfo';
import { joinStrings } from '../../../../utils/string';
import { formatCurrency } from '../../../../utils/wallets';
import { Icon } from '../../../shared/Icon';
import { Tooltip } from '../../../shared/Tooltip';
import { Typography } from '../../../shared/Typography';
import './GameSessionNetPosition.scss';

export const GameSessionNetPosition: FunctionComponent = () => {
  const { isTablet, isMobileDevice } = useDeviceInfo();
  const isLandscape = useOrientation() === Orientation.LANDSCAPE;
  const { netPostion, isSessionReady } = useGameSession({
    refreshInterval: 10,
  });

  const isPortrait = useOrientation() === Orientation.PORTRAIT;
  const [showTooltip, setShowTooltip] = useState(false);

  return isSessionReady ? (
    <div
      role="button"
      className={joinStrings([
        'session-net-position',
        isTablet && !isPortrait && 'session-net-position--tablet',
        isMobileDevice && !isPortrait && 'session-net-position--phone',
      ])}
      onClick={(): void => {
        setShowTooltip(!showTooltip);
      }}
      aria-hidden="true"
    >
      <Tooltip
        {...{
          show: showTooltip,
          onHide: (): void => {
            setShowTooltip(false);
          },
          className: joinStrings([
            'session-net-position__tooltip',
            !showTooltip && 'session-net-position__tooltip--hidden',
          ]),
          icon: 'hide',
          buttonProps: { 'aria-label': 'This shows your total winnings or losses for this play session.' },
          position: 'bottom',
          alignment: 'end',
          theme: 'dark',
          entryAnimation: isLandscape ? 'none' : 'slideFromRight',
        }}
      >
        This shows your total winnings or losses for this play session.
      </Tooltip>
      {netPostion === 0 ? null : (
        <Icon
          className={joinStrings([
            'session-net-position__icon',
            `session-net-position__icon--${netPostion > 0 ? 'positive' : 'negative'}`,
          ])}
          variant="Triangle"
        />
      )}
      <Typography size="sm" className="session-net-position__value">
        {formatCurrency(netPostion, 'auto', 'show')}
      </Typography>
    </div>
  ) : null;
};

export default GameSessionNetPosition;
