/**
 * Table lookup
 *
 * @param {array} table Lookup table - an array of rows, where row is an array of values
 * @returns {func} Lookup function
 */
export default function tableLookup<TableElement>(table: TableElement[][]): (values: TableElement[]) => TableElement[] {
  const _table = Array.isArray(table) ? table : [];

  /**
   * Execute table lookup
   *
   * @param {Array} values Row element(s) to match
   * @returns {Array} Rest of the elements in the first row that matches provided values
   */
  return function (values: TableElement[]): TableElement[] {
    const _values = Array.isArray(values) ? values : [];

    // Reduced values
    const reducedTable = _table.filter((row) => row[0] === _values[0]).map((row) => row.slice(1));
    const reducesValues = _values.slice(1);

    return reducesValues.length ? tableLookup(reducedTable)(reducesValues) : reducedTable[0] || [];
  };
}
