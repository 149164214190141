import { useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { ConsentSubmission, ConsentType } from '../../components/Compliance/CookiesPreferences';
import { CookiesPreferenceContentPayload } from '../../components/Compliance/CookiesPreferences/CookiesPreferencesContent';
import { xTenantId } from '../../consts';
import { useUserProfileUpdateMutation } from '../../libs/graphql/baseAppAPI/mutations/__generated__/user-profile-update.mutation.generated';
import { useUserContext } from '../../providers/UserProvider';
import { storageConsent } from '../../utils/storage';
import { useSnackbar } from '../snackbar';
import { cookieSettings } from './mocks';

type UseCookiesClaimsReturnValue = {
  loading: boolean;
  /**
   * Fetch cookies and set constants to state to determine rather cookies should be created or updated
   */
  fetchCookies: () => Promise<void>;
  /**
   * Incoming data for claims from platform
   */
  preferences: null | CookiesPreferenceContentPayload;
  /**
   * Set cookie preferences
   */
  setPreferences: (payload: CookiesPreferenceContentPayload | null) => void;
  /**
   * Create or update cookie claims
   */
  writeCookies: (payload: CookiesPreferenceContentPayload) => Promise<void>;
  /**
   * Update consent storage
   */
  updateConsentStorage: (payload: CookiesPreferenceContentPayload) => void;
};

/**
 * A hook that returns flags that define user's ability to observe deposit content and deposit limit prompt
 */
export function useCookies(id: string): UseCookiesClaimsReturnValue {
  const auth = useAuth();
  const { addSnack } = useSnackbar();
  const { compliance, consents, userProfileId, fetchUserProfile } = useUserContext();
  const playerId = userProfileId || (auth.user?.profile?.user_profile_id as string);
  const [userProfileUpdateMutation, { loading }] = useUserProfileUpdateMutation({
    onCompleted: async (data) => {
      if (!data) return;

      await fetchUserProfile();
      addSuccessSnack({ success: true });
    },
    onError: () => {
      addSuccessSnack({ success: false });
    },
  });

  const [preferences, setPreferences] = useState<null | CookiesPreferenceContentPayload>(null);

  const { STRICTLY_NECESSARY, FUNCTIONAL, PERFORMANCE, TARGETING } = ConsentType;

  const fetchCookies = async (): Promise<void> => {
    const isEssential = Boolean(consents?.basicCookiesAllowed);
    const isFunctional = Boolean(consents?.functionalCookiesAllowed);
    const isTargeted = Boolean(compliance?.isMarketingAllowed);
    const isPerformance = Boolean(consents?.performanceCookiesAllowed);

    setPreferences({
      enableAll: isEssential && isFunctional && isTargeted && isPerformance,
      isEssential,
      isFunctional,
      isTargeted,
      isPerformance,
    });
  };

  const onCreateConsent = (consentModel: ConsentSubmission): void => {
    storageConsent.set({
      value: consentModel,
    });
  };

  const updateConsent = (consent: ConsentSubmission): void => {
    onCreateConsent({
      consent: consent?.consent,
      consentedOn: consent?.consentedOn,
      playerId: consent?.playerId,
    });
  };

  const acceptAll = (playerId?: string): void => {
    const [
      { vendorIds: essentialVendorsIds },
      { vendorIds: functionalVendorsIds },
      { vendorIds: peformanceVendorsIds },
      { vendorIds: targetingVendorsIds },
    ] = cookieSettings;

    const consent = [
      {
        type: STRICTLY_NECESSARY,
        userHasConsented: true,
        alwaysActive: true,
        vendorIds: essentialVendorsIds,
      },
      {
        type: FUNCTIONAL,
        userHasConsented: true,
        alwaysActive: false,
        vendorIds: functionalVendorsIds,
      },
      {
        type: PERFORMANCE,
        userHasConsented: true,
        alwaysActive: false,
        vendorIds: peformanceVendorsIds,
      },
      {
        type: TARGETING,
        userHasConsented: true,
        alwaysActive: false,
        vendorIds: targetingVendorsIds,
      },
    ];

    onCreateConsent({
      consent: consent,
      consentedOn: Date.now(),
      playerId: playerId,
    });
  };

  const declineAll = (playerId?: string): void => {
    const [
      { vendorIds: essentialVendorsIds },
      { vendorIds: functionalVendorsIds },
      { vendorIds: peformanceVendorsIds },
      { vendorIds: targetingVendorsIds },
    ] = cookieSettings;

    const consent = [
      {
        type: STRICTLY_NECESSARY,
        userHasConsented: true,
        alwaysActive: true,
        vendorIds: essentialVendorsIds,
      },
      {
        type: FUNCTIONAL,
        userHasConsented: false,
        alwaysActive: false,
        vendorIds: functionalVendorsIds,
      },
      {
        type: PERFORMANCE,
        userHasConsented: false,
        alwaysActive: false,
        vendorIds: peformanceVendorsIds,
      },
      {
        type: TARGETING,
        userHasConsented: false,
        alwaysActive: false,
        vendorIds: targetingVendorsIds,
      },
    ];

    onCreateConsent({
      consent: consent,
      consentedOn: Date.now(),
      playerId: playerId,
    });
  };

  const updateConsentStorage = (payload: CookiesPreferenceContentPayload): void => {
    const [
      { vendorIds: essentialVendorsIds },
      { vendorIds: functionalVendorsIds },
      { vendorIds: peformanceVendorsIds },
      { vendorIds: targetingVendorsIds },
    ] = cookieSettings;
    const { enableAll, isEssential, isPerformance, isFunctional, isTargeted } = payload;

    if (enableAll) {
      acceptAll(id);
    } else if (!isFunctional && !isPerformance && !isTargeted) {
      declineAll(id);
    } else {
      updateConsent({
        playerId: id,
        consentedOn: Date.now(),
        consent: [
          {
            type: STRICTLY_NECESSARY,
            userHasConsented: isEssential,
            alwaysActive: true,
            vendorIds: essentialVendorsIds,
          },
          {
            type: FUNCTIONAL,
            userHasConsented: isFunctional,
            alwaysActive: false,
            vendorIds: functionalVendorsIds,
          },
          {
            type: PERFORMANCE,
            userHasConsented: isPerformance,
            alwaysActive: false,
            vendorIds: peformanceVendorsIds,
          },
          {
            type: TARGETING,
            userHasConsented: isTargeted,
            alwaysActive: false,
            vendorIds: targetingVendorsIds,
          },
        ],
      });
    }
  };

  const writeCookies = async (payload: CookiesPreferenceContentPayload): Promise<void> => {
    await userProfileUpdateMutation({
      variables: {
        userProfileId: playerId,
        xTenantId,
        userProfileUpdateInfo: {
          basicCookiesAllowed: payload.isEssential,
          performanceCookiesAllowed: payload.isPerformance,
          functionalCookiesAllowed: payload.isFunctional,
          isMarketingAllowed: payload.isTargeted,
        },
      },
    });
    updateConsentStorage(payload);
  };

  const addSuccessSnack = ({ success }: { success: boolean }): void => {
    addSnack({
      type: success ? 'success' : 'error',
      theme: 'dark',
      message: success ? 'Your preferences have been updated.' : 'Unable to save your preferences.',
    });
  };

  return {
    loading,
    preferences,
    fetchCookies,
    setPreferences,
    writeCookies,
    updateConsentStorage,
  };
}
