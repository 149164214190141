import { MediaBreakpoint, useBreakpointInfo } from '../useDeviceInfo';

type UseGameSlidesCountConfig = {
  wide: number;
  desktop: number;
  tablet: number;
  mobile: number;
};

export function useGameSlidesCount(gameSlidesCountConfig: UseGameSlidesCountConfig): number {
  const { wide, desktop, tablet, mobile } = gameSlidesCountConfig;
  const mobileSlidesNumber = useBreakpointInfo([MediaBreakpoint.MOBILE]) && mobile;
  const tabletSlidesNumber = useBreakpointInfo([MediaBreakpoint.TABLET]) && tablet;
  const desktopSlidesNumber = useBreakpointInfo([MediaBreakpoint.DESKTOP]) && desktop;

  return mobileSlidesNumber || tabletSlidesNumber || desktopSlidesNumber || wide;
}
