import noop from 'lodash/noop';
import React, { FunctionComponent, useEffect, useRef } from 'react';
import { Button } from '../Button';
import { Spinner } from '../Spinner';
import { MediaBreakpoint, Orientation, useBreakpointInfo, useOrientation } from '../../../hooks/useDeviceInfo';
import { joinStrings } from '../../../utils/string';
import { TouchDrawer, TouchDrawerMode } from '../TouchDrawer';
import { IconButton } from '../IconButton';
import { Icon } from '../Icon';
import { Heading } from '../Heading';
import './styles';

export type ButtonConfig = {
  text?: string | JSX.Element;
  onClick?: () => void;
  buttonVariant?: 'secondary' | 'text' | 'primary' | 'secondary-alt';
  type?: 'submit' | 'button' | 'reset';
  form?: string;
  icon?: JSX.Element;
  iconPosition?: 'right' | 'left';
  disabled?: boolean;
  isSubmitting?: boolean;
  isExternalDisabled?: boolean;
};

type ImageConfig = {
  src: string;
  alt?: string;
};

export type PromptProps = {
  /**
   * if true, prompt will display on page
   */
  show: boolean;
  /**
   * Optional. Configuration of prompt image
   */
  image?: ImageConfig;
  /**
   * Optional. Shows a spinner and disables buttons if set to true.
   */
  busy?: boolean;
  /**
   * Text to display in sub-heading
   */
  title?: string;
  /**
   * Function to be used on close button click & overlay click
   */
  onClose?: () => void;
  /**
   * Custom className for Prompt
   */
  className?: string;
  /**
   * Configuration for footer actions
   */
  PrimaryButton?: ButtonConfig;
  /**
   * Optional. Configuration for footer actions
   */
  SecondaryButton?: ButtonConfig;
  /**
   * element to have focus on load
   */
  elementToFocusOnOpen?: React.RefObject<HTMLElement>;
  /**
   * Whether to render a close button in the top right. Defaults to true
   */
  hasCloseButton?: boolean;
  /**
   * Prompt cannot be closed via backdrop, close button, or touch/drag.
   */
  cannotClose?: boolean;
  /**
   * Handle submit button with built in spinner
   */
  isSubmitting?: boolean;
  /**
   * Handle loading secondary button with built in spinner
   */
  isSecondaryCTALoading?: boolean;
  onShow?: () => void;
  children?: React.ReactNode;
  /**
   * Optional callback for when close animation completed
   */
  onCloseAnimationCompleted?: () => void;

  mode?: TouchDrawerMode;
};

const PromptFooter: FunctionComponent<{
  PrimaryButton: ButtonConfig;
  SecondaryButton?: ButtonConfig;
  busy?: boolean;
}> = ({ PrimaryButton, SecondaryButton, busy }) => {
  const buttonType = PrimaryButton.type ? PrimaryButton.type : 'button';

  return (
    <>
      {SecondaryButton && (
        <Button
          variant={SecondaryButton.buttonVariant || 'secondary-alt'}
          type="button"
          size="large"
          icon={SecondaryButton.icon}
          iconPosition={SecondaryButton.iconPosition}
          onClick={SecondaryButton.onClick}
          disabled={busy || SecondaryButton.disabled}
          isSubmitting={SecondaryButton.isSubmitting}
        >
          {busy ? <Spinner /> : <>{SecondaryButton.text}</>}
        </Button>
      )}
      <Button
        variant={PrimaryButton.buttonVariant || 'primary'}
        type={buttonType}
        form={PrimaryButton.form}
        icon={PrimaryButton.icon}
        iconPosition={PrimaryButton.iconPosition}
        size="large"
        disabled={busy || PrimaryButton.disabled}
        onClick={PrimaryButton.onClick}
        isSubmitting={PrimaryButton.isSubmitting}
        isExternalDisabled={PrimaryButton.isExternalDisabled}
      >
        {busy ? <Spinner /> : <>{PrimaryButton.text}</>}
      </Button>
    </>
  );
};

export const Prompt: FunctionComponent<PromptProps> = ({
  show,
  image,
  busy,
  title,
  onClose,
  children,
  className,
  PrimaryButton,
  SecondaryButton,
  elementToFocusOnOpen,
  hasCloseButton = true,
  cannotClose = false,
  onShow = noop,
  onCloseAnimationCompleted,
  mode = 'dark',
}) => {
  const isBelowTabletLandWidth = useBreakpointInfo([MediaBreakpoint.BELOW_TABLET]);
  const isLandscape = useOrientation() === Orientation.LANDSCAPE;
  const isMobileDevice = useBreakpointInfo([MediaBreakpoint.BELOW_DESKTOP]);
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (show) {
      onShow();
    }
  }, [onShow, show]);

  return (
    <TouchDrawer
      className={joinStrings(['prompt', className, isBelowTabletLandWidth && isLandscape && 'mobile-landscape'])}
      show={show}
      showTouchPad={isMobileDevice && !cannotClose && hasCloseButton}
      showCloseBtn={!isMobileDevice && cannotClose}
      backdropCanClose={!cannotClose}
      allowKeyboardInputs
      positionCloseBtn="inside"
      elementToFocusOnOpen={elementToFocusOnOpen || closeButtonRef}
      onClose={onClose}
      onCloseAnimationCompleted={onCloseAnimationCompleted}
      mode={mode}
      footer={
        PrimaryButton && <PromptFooter busy={busy} PrimaryButton={PrimaryButton} SecondaryButton={SecondaryButton} />
      }
      header={
        (!isMobileDevice || cannotClose) && (
          <div>
            {hasCloseButton && !cannotClose && (
              <div role="button" aria-label="Close" aria-hidden="true" className="prompt__close-btn">
                <IconButton
                  onClick={onClose}
                  aria-label="Close"
                  icon={<Icon variant="Close" />}
                  title="close"
                  ref={closeButtonRef}
                  color="inherit"
                  size="large"
                />
              </div>
            )}
          </div>
        )
      }
    >
      {image && (
        <div className="prompt__image-container">
          <img className="prompt__image" src={image.src} alt={image.alt} />
        </div>
      )}
      <div className="prompt__content">
        <Heading className="prompt__heading" noMargin level={5}>
          {title}
        </Heading>
        {children}
      </div>
    </TouchDrawer>
  );
};
