import React, { Children, FC } from 'react';
import dompurify from 'dompurify';
import { PromotionHowItWorksProps } from '../types';
import { Heading } from '../../../../shared/Heading';
import { Paragraph } from '../../../../shared/Paragraph';
import { PromotionClaimButton } from '../../PromotionClaimButton';
import { PromotionCancelButton } from '../../PromotionCancelButton';

const PromotionHowItWorksBlock: FC<PromotionHowItWorksProps> = ({
  steps = [],
  promo,
  onActivateBonus,
  isActive,
}): JSX.Element => {
  const sanitizer = dompurify.sanitize;

  const { promoButtonText } = promo;

  return (
    <div className="opt-in-block">
      <Heading className="opt-in-block__title" level={5} noMargin>
        {/* Consider it as using from CMS */}
        How it works
      </Heading>
      <div className="opt-in-block__steps">
        <ul className="opt-in-block__list">
          {Children.toArray(
            steps.map((stepText: string, index: number) => (
              <li className="opt-in-block__item">
                <span className="opt-in-block__circle">{++index}</span>
                <Paragraph
                  size="md"
                  noMargin
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(stepText),
                  }}
                />
              </li>
            ))
          )}
        </ul>
      </div>
      {isActive ? (
        <PromotionCancelButton activatedPromo={promo} isPrimary />
      ) : (
        <PromotionClaimButton label={promoButtonText} onActivateBonus={onActivateBonus} />
      )}
    </div>
  );
};

export default PromotionHowItWorksBlock;
