export const getApexDomain = (host: string): string => {
  if (host.includes('localhost')) {
    return ' betzone.jadexconsulting.com';
  } else {
    const regex = /[^.]+\.[^.]{2,3}(\.[^.]{2})?$/;
    const apexDomain = host.match(regex);

    return apexDomain != null ? apexDomain[0] : '';
  }
};
