import React, { FC } from 'react';
import { PresentIcon } from '../../../shared/SVG/Icons/PresentIcon';
import { Heading } from '../../../shared/Heading';

export const PromotionsEmpty: FC<{ noPromosStr?: string }> = ({ noPromosStr }) => (
  <div className="promotions-block__empty">
    <PresentIcon />
    <Heading level={4} className="promotions-block__no-active-info">
      You currently have no {noPromosStr} promotions
    </Heading>
  </div>
);
