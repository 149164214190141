import React, { ComponentPropsWithRef, forwardRef, ReactNode } from 'react';
import { IconBaseProps } from '@react-icons/all-files';
import { Icon } from '../Icon';
import { joinStrings } from '../../../utils/string';
import './Radio.scss';

const RadioIcon = ({ checked, ...props }: { checked?: boolean } & IconBaseProps): JSX.Element =>
  checked ? (
    <Icon variant="CircleDot" data-testid="dot-circle" {...props} />
  ) : (
    <Icon variant="CircleOutline" data-testid="circle" {...props} />
  );

export type RadioProps = {
  /**
   * Required. Either a text string or ReactNode to represent the radio button.
   */
  label: string | ReactNode;

  /**
   * onChange handler - needed to trigger changed because this is a controlled component.
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   *Optional. Allows you to swap the label and radio icon
   */
  isFirstLabel?: boolean;
} & ComponentPropsWithRef<'input'>;

/**
 * This is a controlled Radio input component.
 *
 * ### Example with useState to control the component state
 *
 *
 * ```tsx
 * const StatefulUseComponent = () => {
 *   const [checked, setChecked] = useState(false);
 *   return (
 *     <Fragment>
 *       <Radio
 *         name="radio"
 *         value={2}
 *         label="Click me"
 *         checked={checked}
 *         onChange={(e)=> setChecked(e.target.checked)}
 *       />
 *
 *     </Fragment>
 *   );
 * };
 * ```
 */
export const Radio = forwardRef<HTMLInputElement, RadioProps>(
  ({ disabled, label, id, onChange, isFirstLabel = false, ...props }, ref) => {
    const containerClassName = joinStrings(['radio', disabled && 'radio--disabled']);

    return (
      <label htmlFor={id} className={containerClassName} data-testid="radio">
        <input
          {...props}
          className="radio__input"
          aria-disabled={disabled}
          aria-checked={props.checked}
          onChange={(e): void => {
            if (disabled) {
              e.preventDefault();
            } else {
              onChange && onChange(e);
            }
          }}
          ref={ref}
          id={id}
          type="radio"
        />
        {isFirstLabel && <div className="radio__label">{label}</div>}
        <div className={joinStrings(['radio__icon', props.checked && 'radio__icon--checked'])}>
          <RadioIcon focusable aria-hidden checked={props.checked} />
        </div>

        {!isFirstLabel && <div className="radio__label">{label}</div>}
      </label>
    );
  }
);

Radio.displayName = 'Radio';
