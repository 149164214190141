import { useState } from 'react';
import { ApolloError } from '@apollo/client';
import { Endpoint } from '../../models/api.model';
import { useTranslations } from '../useTranslationsHelper';
import { TransformedTranslationsObject } from '../useTranslationsHelper/types';
import { useTranslationGetAllQuery } from '../../libs/graphql/betzoneDirectusAPI/queries/__generated__/translations.query.generated';
import { locale } from '../../consts';

interface HookReturnType {
  loading: boolean;
  error: ApolloError | undefined;
  translations: TransformedTranslationsObject | null;
  loadLocalTranslations: (lang: string) => Promise<{ default: { [k: string]: string } }>;
}

export const useFetchTranslations = (language = locale): HookReturnType => {
  const { transformTranslationsObject } = useTranslations();
  const [translations, setTranslations] = useState<TransformedTranslationsObject | null>(null);
  const { loading, error } = useTranslationGetAllQuery({
    variables: {
      language,
    },
    onCompleted: async (data) => {
      const transformedTranslationsObject = transformTranslationsObject(data);

      setTranslations(transformedTranslationsObject);
    },
    onError: (e) => {
      console.error(e.message);
    },
    context: { endpoint: Endpoint.betzoneDirectusAPI },
  });

  const loadLocalTranslations = async (lang: string): Promise<{ default: { [k: string]: string } }> => {
    switch (lang) {
      case 'en':
        return import('../../translations/en.json');
      // TODO: add cases for other languages if needed
      default:
        return import('../../translations/en.json');
    }
  };

  return { loading, error, translations, loadLocalTranslations };
};
