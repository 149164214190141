/* eslint-disable react/display-name */
import React, { ReactNode } from 'react';

export const MyBonusSummary = React.forwardRef<HTMLDivElement, { children?: ReactNode }>(({ children }, ref) => {
  return (
    <div ref={ref} className="my-bonus-history__summary" data-testid="my-bonus-history-bonus-summary">
      {children}
    </div>
  );
});
