import React, { FunctionComponent, useEffect } from 'react';
import { ActivePromotionsList } from '../ActivePromotionsList';
import { ParsedPromo } from '../types';
import noop from 'lodash/noop';
import MyBonusHistory from '../../../Account/MyBonusHistory';
import { PromotionsEmpty } from '../PromotionsBlock/PromotionsEmpty';

export const PromotionBonusDetails: FunctionComponent<{
  promotions: ParsedPromo[];
  promotionsHistory: ParsedPromo[];
}> = ({ promotions, promotionsHistory }) => {
  const isActivePromos = Boolean(promotions.length);
  const isHistoryPromos = Boolean(promotionsHistory.length);
  const isNoPromos = !(isActivePromos || isHistoryPromos);

  useEffect(() => {
    // Trigger loadWallets
  }, []);

  return (
    <>
      {isActivePromos && <ActivePromotionsList promotions={promotions} isFetching={false} />}
      {isHistoryPromos && <MyBonusHistory promotions={promotionsHistory} withinPromotion onClose={noop} />}
      {isNoPromos && <PromotionsEmpty />}
    </>
  );
};
