import React, { FC } from 'react';
import { GameImage } from '../../../Games/games.model';

type GameItemProps = {
  catalogueName: string;
  images: GameImage[];
};

export const GameItem: FC<GameItemProps> = ({ catalogueName, images }) => {
  return (
    <li className="games-list__game-item game-item">
      <div className="game-item__game-image">
        <img src={images && images[2] && images[2].url} alt="game-item-logo-alt" />
      </div>
      <div className="game-item__game-container">
        <div className="game-item__game-header">
          <div className="game-item__game-name">{catalogueName}</div>
          <a aria-label="game-item-info-aria-label" href="home">
            <span />
          </a>
        </div>
        <div className="game-item__game-controls">
          <a className="game-item__play-button" href="game-item" aria-label="game-item-play-aria-label">
            game-item-play
          </a>
        </div>
      </div>
    </li>
  );
};
