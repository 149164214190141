import React, { FunctionComponent } from 'react';
import { ActionPanel } from '../ActionPanel';
import { Button } from '../Button';
import { InnerHTML } from '../InnerHTML';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '../Modal';
import { Paragraph } from '../Paragraph';

export type ConfirmationModalProps = {
  /**
   * Whether to display the confirmation dialog.  Defaults to false
   */
  isOpen?: boolean;
  /**
   * Modal heading text. Optional. Will default to general translation.
   */
  heading?: string;
  /**
   * Modal body text. Optional. Will default to general translation.
   */
  bodyText?: string;
  /**
   * Modal confirmation button label. Optional. Will default to general translation.
   */
  confirmLabel?: string;
  /**
   * Whether actions should be disabled such as during an async call
   */
  disableActions?: boolean;
  /**
   * Will be invoked if user chooses to confirm
   */
  onConfirm?: () => void;
  /**
   * Will be invoked if user chooses to cancel action
   */
  onCancel?: () => void;
  children?: React.ReactNode;
};

/**
 * A simple dialog intended to be used for user confirmation.
 */
export const ConfirmationModal: FunctionComponent<ConfirmationModalProps> = ({
  isOpen = false,
  heading,
  bodyText,
  confirmLabel,
  children,
  disableActions = false,
  onConfirm,
  onCancel,
}) => {
  const headingId = 'edit-confirmation-prompt-heading';

  return (
    <Modal size="medium" padding="dense" isOpen={isOpen} aria-labelledby={headingId} onRequestClose={onCancel}>
      <ModalHeader id={headingId} heading={heading || 'Confirmation Required'} />
      <ModalBody>
        {bodyText ? (
          <InnerHTML text={bodyText} />
        ) : (
          <Paragraph>Are you sure you wish to proceed with this action?</Paragraph>
        )}
        {children}
      </ModalBody>
      <ModalFooter>
        <ActionPanel align="space-between">
          <Button type="button" variant="text" onClick={onCancel} disabled={disableActions}>
            CANCEL
          </Button>

          <Button size="small" type="button" variant="primary" onClick={onConfirm} disabled={disableActions}>
            {confirmLabel || 'CONFIRM'}
          </Button>
        </ActionPanel>
      </ModalFooter>
    </Modal>
  );
};
