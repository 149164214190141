import React, { forwardRef } from 'react';
import { LabelProps } from './types';
import './Label.scss';
import { joinStrings } from '../../../utils/string';

/**
 * A simple semantic Label component with standard label style variables applies.
 * Should be used rather direct HTML tags where possible.
 */
export const Label = forwardRef<HTMLLabelElement, LabelProps>(
  (
    {
      children,
      className,
      textClassName,
      labelPosition = 'right',
      disabled = false,
      input,
      isSrOnly = false,
      theme = 'light',
      id,
      htmlFor,
    },
    ref
  ) => {
    return (
      <label
        ref={ref}
        id={id}
        htmlFor={htmlFor}
        className={joinStrings([
          className,
          'label',
          input && `label--position-${labelPosition}`,
          disabled && 'label--disabled',
          isSrOnly && 'u-sr-only',
          theme === 'dark' && 'label--dark',
        ])}
        data-testid="label-container"
      >
        <span className={joinStrings(['label__text', textClassName])}>{children}</span>
        {input && <span className="label__input">{input}</span>}
      </label>
    );
  }
);

Label.displayName = 'Label';
