import React, { createContext, FC } from 'react';
import { useAuth } from 'react-oidc-context';
import { OVERLAYS_ARIA_LABELS } from '../../../../consts';
import { MediaBreakpoint, useBreakpointInfo, useDeviceInfo } from '../../../../hooks/useDeviceInfo';
import { SearchBar } from '../../../Header/SearchBar';
import { PopUpHandler } from '../../../shared/PopupHandler';
import { GameCardContainerType } from '../../GameCard/GameCard.types';
import { RecommendedGameCarousel } from '../../RecommendedGames';
import './styles';
import { useTranslations } from '../../../../hooks/useTranslationsHelper';

interface GameSearchSpineZoneProps {
  isOpen: boolean;
  onCloseGameSearch: () => void;
  setShowMenu: (value: boolean) => void;
}

export const SpinZoneSearchContext = createContext(false);

export const GameSearchSpineZone: FC<GameSearchSpineZoneProps> = ({
  isOpen,
  setShowMenu,
  onCloseGameSearch,
}): JSX.Element => {
  const auth = useAuth();
  const { t } = useTranslations();
  const { isMobileDevice, isTablet } = useDeviceInfo();
  const isBelowMobileWidth = useBreakpointInfo([MediaBreakpoint.BELOW_MOBILE]);
  const isAboveDesktopWidth = !useBreakpointInfo([MediaBreakpoint.BELOW_DESKTOP]);
  const isPhone = isMobileDevice && !isTablet;
  const recommendedTitle = auth?.isAuthenticated ? t('game.recommended.label') : t('game.featured.label');

  const closePopupHandler = (): void => {
    onCloseGameSearch();
  };

  const onCloseMenu = (): void => {
    closePopupHandler();
    setShowMenu(false);
  };

  return (
    <div className="game-search-spine-zone">
      <PopUpHandler
        isOpen={isOpen}
        onClose={closePopupHandler}
        header={
          !isMobileDevice
            ? t('spin-zone.search-games.title')
            : isMobileDevice && !isBelowMobileWidth
              ? t('spin-zone.search-games.title')
              : ''
        }
        modalProps={{
          className: 'game-search__popup',
          style: { overlay: { zIndex: 12 } },
          'aria-label': OVERLAYS_ARIA_LABELS.SPIN_ZONE,
        }}
        drawerProps={{ className: 'game-search__popup', 'aria-label': OVERLAYS_ARIA_LABELS.SPIN_ZONE }}
        showCloseBtn={!isPhone}
      >
        <div className="game-search-spine-zone__inner">
          <SpinZoneSearchContext.Provider value>
            <SearchBar isUsedOverlay={false} closeGameSpinZoneSearch={closePopupHandler} onCloseMenu={onCloseMenu}>
              <RecommendedGameCarousel
                title={recommendedTitle}
                showArrows={isAboveDesktopWidth}
                showDots={false}
                onClose={onCloseMenu}
                containerType={GameCardContainerType.Wide}
                visibleConfig={{
                  wide: 3,
                  desktop: 4,
                  tablet: 3,
                  mobile: 2,
                }}
              />
            </SearchBar>
          </SpinZoneSearchContext.Provider>
        </div>
      </PopUpHandler>
    </div>
  );
};
