import { FunctionComponent } from 'react';
import { CookieControl } from '../CookieControl';
import { CookiesDetails } from '../CookieDetails';
import { CookiesPreferencesMode } from '../types';
import { getConsentSettings } from '../helper';
import { useCookiePreferencesContext } from '../CookiePreferences.context';
import './styles';

export type CookiesPreferenceContentPayload = {
  enableAll?: boolean;
  isEssential?: boolean;
  isFunctional?: boolean;
  isTargeted?: boolean;
  isPerformance?: boolean;
};

export type CookiesPreferenceContentProps = {
  /**
   * Whether is component opened
   */
  isOpened?: boolean;
  /**
   * Account page wrapper title setter
   */
  setCookieDetailedPageTitle: (title: string | undefined) => void;
} & CookiesPreferencesMode;

export const CookiesPreferenceContent: FunctionComponent<CookiesPreferenceContentProps> = ({
  isOpened = true,
  setCookieDetailedPageTitle,
}) => {
  const consentSettingsData = getConsentSettings();
  const { isDetailPageDisplayed, activeCategory } = useCookiePreferencesContext();

  return isOpened ? (
    <>
      {/* TODO: Replace mocked date with dynamic */}
      {isDetailPageDisplayed ? (
        <CookiesDetails
          data={consentSettingsData}
          activeCategory={activeCategory}
          setCookieDetailedPageTitle={setCookieDetailedPageTitle}
        />
      ) : (
        <CookieControl />
      )}
    </>
  ) : null;
};
