import { addYears, formatISO } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { useSnackbar } from '../snackbar';
import { BonusHistoryItem, BonusStatusType, GetBonusHistoryParams } from '../../models/bonuses.model';
import { getPlayerBonusHistoryActive } from '../../components/Header/HeaderBalance/HeaderBalanceDetails/mocks';

export function useFetchPromotionsActive(): {
  bonuses: BonusHistoryItem[];
  isFetching: boolean;
} {
  const { addSnack } = useSnackbar();
  const [isFetching, setFetching] = useState<boolean>(false);
  const [bonuses, setBonuses] = useState<BonusHistoryItem[]>([]);

  const { Active } = BonusStatusType;

  const dateRange = {
    startDate: formatISO(addYears(new Date(), -1)),
    endDate: formatISO(new Date()),
  };

  const filters = useRef<Pick<GetBonusHistoryParams, 'skip'>>({
    skip: 0,
  });

  async function handleFetchBonusHistory(): Promise<void> {
    setFetching(true);

    const params: GetBonusHistoryParams = {
      ...filters.current,
      take: 1000,
      activatedDateStart: dateRange.startDate,
      activatedDateEnd: dateRange.endDate,
    };

    try {
      const response = await getPlayerBonusHistoryActive(params);
      const bonusesFiltered = response.items.filter(({ status }: { status: string }) => status === Active).reverse();

      setBonuses(bonusesFiltered);
    } catch (e) {
      console.error(e);
      addSnack({
        type: 'error',
        message: "Oops, something's gone wrong",
      });
    } finally {
      setFetching(false);
    }
  }

  useEffect(() => {
    handleFetchBonusHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    bonuses,
    isFetching,
  };
}
