import { ApolloError } from '@apollo/client';
import { locale } from '../../consts';
import { Endpoint } from '../../models/api.model';
import { UseFetchGamesProps, UseFetchGamesReturnType } from './types';
import { useGameSearchLazyQuery } from '../../libs/graphql/betzoneDirectusAPI/queries/__generated__/game-search.query.generated';
import { useLobbiesGetLazyQuery } from '../../libs/graphql/betzoneDirectusAPI/queries/__generated__/lobbies-get.query.generated';
import { useGameGetBySlugLazyQuery } from '../../libs/graphql/betzoneDirectusAPI/queries/__generated__/game-get-by-slug.query.generated';
import { useGamesGetByTagsLazyQuery } from '../../libs/graphql/betzoneDirectusAPI/queries/__generated__/games-get-by-tags.query.generated';
import { useGamesGetByCategoryLazyQuery } from '../../libs/graphql/betzoneDirectusAPI/queries/__generated__/games-get-by-category.query.generated';

export const useFetchGames = ({
  tag = '',
  count = 0,
  slug = '',
  gameId = null,
  category = '',
  language = locale,
  searchTerm = '',
  onSearchGameComplete,
  onLobbiesBySlugCompleted,
  onGamesGetByTagsComplete,
  onFetchGameBySlugComplete,
  onGamesGetByCategoryComplete,
}: UseFetchGamesProps): UseFetchGamesReturnType => {
  const [
    fetchGameBySlug,
    { data: fetchedGameBySlugData, loading: fetchedGameBySlugLoading, error: fetchedGameBySlugError },
  ] = useGameGetBySlugLazyQuery({
    variables: {
      language,
      slug,
    },
    onCompleted: onFetchGameBySlugComplete,
    onError: (e: ApolloError) => {
      console.error(e.message);
    },
    context: { endpoint: Endpoint.betzoneDirectusAPI },
  });

  const [fetchLobbiesBySlug, { data: fetchedLobbiesData, loading: fetchedLobbiesLoading, error: fetchedLobbiesError }] =
    useLobbiesGetLazyQuery({
      variables: {
        slug,
        language,
      },
      onCompleted: onLobbiesBySlugCompleted,
      onError: (e: ApolloError) => {
        console.error(e.message);
      },
      context: { endpoint: Endpoint.betzoneDirectusAPI },
    });

  const [searchGame, { data: searchGameData, loading: searchedGameQueryLoading, error: searchedGameQueryError }] =
    useGameSearchLazyQuery({
      variables: {
        language,
        searchTerm: searchTerm,
      },
      onCompleted: onSearchGameComplete,
      onError: (e: ApolloError) => {
        console.error(e.message);
      },
      context: { endpoint: Endpoint.betzoneDirectusAPI },
    });

  const [
    fetchGamesByTags,
    { data: fetchedGamesByTagsData, loading: isFetchedGamesByTagsDataLoading, error: fetchedGamesByTagsError },
  ] = useGamesGetByTagsLazyQuery({
    variables: {
      tag,
      count,
      gameId,
      category,
      language,
    },
    onCompleted: onGamesGetByTagsComplete,
    onError: (e: ApolloError) => {
      console.error(e.message);
    },
    context: { endpoint: Endpoint.betzoneDirectusAPI },
  });

  const [
    fetchGamesByCategory,
    {
      data: fetchedGamesByCategoryData,
      loading: isFetchedGamesByCategoryDataLoading,
      error: fetchedGamesByCategoryError,
    },
  ] = useGamesGetByCategoryLazyQuery({
    variables: {
      count,
      category,
      language,
    },
    onCompleted: onGamesGetByCategoryComplete,
    onError: (e: ApolloError) => {
      console.error(e.message);
    },
    context: { endpoint: Endpoint.betzoneDirectusAPI },
  });

  return {
    searchGame,
    searchGameData,
    fetchGameBySlug,
    fetchGamesByTags,
    fetchLobbiesBySlug,
    fetchedLobbiesData,
    fetchedLobbiesError,
    fetchGamesByCategory,
    fetchedLobbiesLoading,
    fetchedGameBySlugData,
    fetchedGameBySlugError,
    searchedGameQueryError,
    fetchedGamesByTagsData,
    fetchedGamesByTagsError,
    searchedGameQueryLoading,
    fetchedGameBySlugLoading,
    fetchedGamesByCategoryData,
    fetchedGamesByCategoryError,
    isFetchedGamesByTagsDataLoading,
    isFetchedGamesByCategoryDataLoading,
  };
};
