import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GameGetBySlugQueryVariables = Types.Exact<{
  slug: Types.Scalars['String']['input'];
  language: Types.Scalars['String']['input'];
}>;


export type GameGetBySlugQuery = { __typename?: 'Query', game: Array<{ __typename?: 'game', game_id?: string | null, rtp?: string | null, min_rtp?: string | null, max_rtp?: string | null, min_bet?: string | null, max_bet?: string | null, paylines?: string | null, translations?: Array<{ __typename?: 'game_translations', display_name?: string | null, short_description?: string | null, long_description?: string | null, rules?: string | null, slug?: string | null } | null> | null, provider?: { __typename?: 'game_provider', mediator_id?: string | null } | null, gameplay_background?: { __typename?: 'directus_files', filename_disk?: string | null, width?: number | null, height?: number | null, title?: string | null } | null, game_image?: { __typename?: 'directus_files', filename_disk?: string | null, width?: number | null, height?: number | null, title?: string | null } | null, banner_image?: { __typename?: 'directus_files', filename_disk?: string | null, width?: number | null, height?: number | null, title?: string | null } | null, features?: Array<{ __typename?: 'game_game_features', game_features_id?: { __typename?: 'game_features', thumbnail?: { __typename?: 'directus_files', width?: number | null, height?: number | null, title?: string | null, filename_disk?: string | null } | null, translations?: Array<{ __typename?: 'game_features_translations', display_name?: string | null, description?: string | null } | null> | null } | null } | null> | null, category?: { __typename?: 'categories', internal_name?: string | null } | null, tags?: Array<{ __typename?: 'game_game_tags', game_tags_id?: { __typename?: 'game_tags', translations?: Array<{ __typename?: 'game_tags_translations', name?: string | null } | null> | null } | null } | null> | null }> };


export const GameGetBySlugDocument = gql`
    query GameGetBySlug($slug: String!, $language: String!) {
  game(
    filter: {status: {_eq: "published"}, translations: {slug: {_eq: $slug}}, provider: {status: {_eq: "published"}}}
  ) {
    game_id
    rtp
    min_rtp
    max_rtp
    min_bet
    max_bet
    paylines
    translations(
      filter: {languages_language_code: {language_code: {_eq: $language}}}
    ) {
      display_name
      short_description
      long_description
      rules
      slug
    }
    provider(filter: null) {
      mediator_id
    }
    gameplay_background(filter: null) {
      filename_disk
      width
      height
      title
    }
    game_image(filter: null) {
      filename_disk
      width
      height
      title
    }
    banner_image {
      filename_disk
      width
      height
      title
    }
    features {
      game_features_id(
        filter: {translations: {languages_language_code: {language_code: {_eq: $language}}}}
      ) {
        thumbnail(filter: null) {
          width
          height
          title
          filename_disk
        }
        translations(
          filter: {languages_language_code: {language_code: {_eq: $language}}}
        ) {
          display_name
          description
        }
      }
    }
    category {
      internal_name
    }
    tags {
      game_tags_id(filter: null) {
        translations(
          filter: {languages_language_code: {language_code: {_eq: $language}}}
        ) {
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGameGetBySlugQuery__
 *
 * To run a query within a React component, call `useGameGetBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGameGetBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGameGetBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGameGetBySlugQuery(baseOptions: Apollo.QueryHookOptions<GameGetBySlugQuery, GameGetBySlugQueryVariables> & ({ variables: GameGetBySlugQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GameGetBySlugQuery, GameGetBySlugQueryVariables>(GameGetBySlugDocument, options);
      }
export function useGameGetBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GameGetBySlugQuery, GameGetBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GameGetBySlugQuery, GameGetBySlugQueryVariables>(GameGetBySlugDocument, options);
        }
export function useGameGetBySlugSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GameGetBySlugQuery, GameGetBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GameGetBySlugQuery, GameGetBySlugQueryVariables>(GameGetBySlugDocument, options);
        }
export type GameGetBySlugQueryHookResult = ReturnType<typeof useGameGetBySlugQuery>;
export type GameGetBySlugLazyQueryHookResult = ReturnType<typeof useGameGetBySlugLazyQuery>;
export type GameGetBySlugSuspenseQueryHookResult = ReturnType<typeof useGameGetBySlugSuspenseQuery>;
export type GameGetBySlugQueryResult = Apollo.QueryResult<GameGetBySlugQuery, GameGetBySlugQueryVariables>;