import React, { FunctionComponent } from 'react';
import { Heading } from '../../../../shared/Heading';
import { Paragraph } from '../../../../shared/Paragraph';
import './MyAccountHomeTile.scss';

export type MyAccountHomeTileProps = {
  /**
   * Heading text. Will be translated.
   */
  heading: string;
  /**
   * Description text. Will be translated.
   */
  description: string;
  /**
   * Icon to display in right corner of tile.
   */
  icon?: React.ReactNode;
  /**
   * Flex direction of tile content, defaults to column
   */
  flexDirection?: 'column' | 'row';
};
/**
 * Layout component for account home tiles.
 */
export const MyAccountHomeTile: FunctionComponent<MyAccountHomeTileProps> = ({
  heading,
  description,
  flexDirection = 'column',
  icon,
}) => {
  return (
    <div className={`my-account-home-tile my-account-home-tile--${flexDirection}`}>
      <div className="my-account-home-tile__icon">{icon}</div>
      <div className="my-account-home-tile__text-content">
        <Heading className="my-account-home-tile__heading" noMargin level={5}>
          {heading}
        </Heading>
        <Paragraph className="my-account-home-tile__description" noMargin size="xs">
          {description}
        </Paragraph>
      </div>
    </div>
  );
};
