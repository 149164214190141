import noop from 'lodash/fp/noop';
import React, { FunctionComponent, useState } from 'react';
import { GameCardContainerType } from '../../../Games/GameCard/GameCard.types';
import { SimpleGameCard } from '../../../Games/GameCard/SimpleGameCard/SimpleGameCard';
import { Game } from '../../../Games/games.model';
import { Icon } from '../../../shared/Icon';
import { IconButton } from '../../../shared/IconButton';
import { GameItem } from './GameItem';
import './styles';

type GamesListProps = {
  data: Game[];
  simpleMode?: boolean;
  onClose?: () => void;
};

export const GamesList: FunctionComponent<GamesListProps> = ({ data, simpleMode, onClose = noop }) => {
  const [displayMode, setDisplayMode] = useState('list');

  return (
    <div className="search-results">
      {!simpleMode && (
        <div className="search-results__header">
          <span className="search-results__number">
            {data.length} result
            {data.length === 1 ? '' : 's'}
          </span>
          <div className="search-results__header-buttons">
            <IconButton
              aria-label="games-list-list-view-aria-label"
              icon={<Icon variant="Menu" />}
              onClick={(): void => setDisplayMode('list')}
              className={displayMode === 'list' ? 'active' : ''}
            />
            <IconButton
              aria-label="games-list-gallery-view-aria-label"
              icon={<Icon variant="GalleryView" />}
              onClick={(): void => setDisplayMode('gallery')}
              className={displayMode === 'gallery' ? 'active' : ''}
            />
          </div>
        </div>
      )}
      <ul className={`search-results__games-list search-results__games-list_${displayMode}`}>
        {data.map((game) =>
          simpleMode ? (
            <div className="search-results__card-wrap" key={game.gameId}>
              <SimpleGameCard game={game} containerType={GameCardContainerType.Wide} onClose={onClose} />
            </div>
          ) : (
            <GameItem
              key={game.gameId}
              images={[
                {
                  alt: game.displayName,
                  url: game.gameImage.imgUrl,
                  label: game.displayName,
                },
              ]}
              catalogueName={game.displayName}
            />
          )
        )}
      </ul>
    </div>
  );
};
