import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RealityCheckUpdatedSubscriptionVariables = Types.Exact<{
  userProfileId: Types.Scalars['String']['input'];
}>;


export type RealityCheckUpdatedSubscription = { __typename?: 'Subscription', realityCheckUpdated?: { __typename?: 'RealityCheckUpdate', current?: { __typename?: 'Endeavour_DOT_RealityCheck_DOT_Messaging_DOT_Contracts_DOT_RealityCheck_DOT_Events_DOT_RealityCheckUpdatedRealityCheck', interval?: string | null, status?: string | null, elapsedCount?: number | null } | null } | null };


export const RealityCheckUpdatedDocument = gql`
    subscription RealityCheckUpdated($userProfileId: String!) {
  realityCheckUpdated(userProfileId: $userProfileId) {
    current {
      interval
      status
      elapsedCount
    }
  }
}
    `;

/**
 * __useRealityCheckUpdatedSubscription__
 *
 * To run a query within a React component, call `useRealityCheckUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRealityCheckUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRealityCheckUpdatedSubscription({
 *   variables: {
 *      userProfileId: // value for 'userProfileId'
 *   },
 * });
 */
export function useRealityCheckUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<RealityCheckUpdatedSubscription, RealityCheckUpdatedSubscriptionVariables> & ({ variables: RealityCheckUpdatedSubscriptionVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<RealityCheckUpdatedSubscription, RealityCheckUpdatedSubscriptionVariables>(RealityCheckUpdatedDocument, options);
      }
export type RealityCheckUpdatedSubscriptionHookResult = ReturnType<typeof useRealityCheckUpdatedSubscription>;
export type RealityCheckUpdatedSubscriptionResult = Apollo.SubscriptionResult<RealityCheckUpdatedSubscription>;