import React, { Fragment, FC } from 'react';
import { useAuth } from 'react-oidc-context';
import { Navigate, Route, Routes } from 'react-router-dom';

/**
 * To be used as root component for a `react-router-dom` Route.
 *
 * Will redirect user to `redirectTo` prop if user is not authenticated.
 */
export const RouterAuthGuard: FC<{
  redirectTo: string;
  children?: React.ReactNode;
}> = ({ redirectTo, children }) => {
  const auth = useAuth();
  const isAuthenticated = auth?.isAuthenticated;

  if (isAuthenticated) {
    return <Fragment>{children}</Fragment>;
  }

  return (
    <Routes>
      <Route path="*" element={<Navigate to={redirectTo} replace />} />;
    </Routes>
  );
};
