import React, { FunctionComponent } from 'react';
import { MediaBreakpoint, useBreakpointInfo } from '../../../../hooks/useDeviceInfo';
import { CTAPanel } from '../../../shared/CTAPanel';
import { APP_URLS } from '../../../../consts';
import { useTranslations } from '../../../../hooks/useTranslationsHelper';

export const GameInfoRegBonus: FunctionComponent = () => {
  const { t } = useTranslations();
  const isBelowMobileWidth = useBreakpointInfo([MediaBreakpoint.BELOW_MOBILE]);
  const colorCTAPanel = 'var(--cta-panel-background-color)';

  return (
    <CTAPanel
      ctaButtonSize={isBelowMobileWidth ? 'medium' : 'large'}
      background={colorCTAPanel}
      heading={t('betzone.welcome')}
      ctaURL={APP_URLS.registration}
      ctaLabel={t('buttons.join-now.ippercase')}
    >
      {t('betzone.bonus-description')}
    </CTAPanel>
  );
};
