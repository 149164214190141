import React, { forwardRef } from 'react';
import { LabelProps } from '../types';
import { joinStrings } from '../../../../utils/string';
import './styles';

/**
 * A simple label component which animates between two positions of its parent's container
 */
export const FloatingLabel = forwardRef<HTMLLabelElement, LabelProps>(
  (
    {
      children,
      textClassName,
      className,
      disabled = false,
      isSrOnly = false,
      floatedTop = false,
      inputIsFocused = false,
      theme = 'dark',
      inputIcons,
      id,
      htmlFor,
    },
    ref
  ) => {
    return (
      <label
        ref={ref}
        id={id}
        htmlFor={htmlFor}
        className={joinStrings([
          className,
          'label--floating',
          disabled && 'label--floating--disabled',
          isSrOnly && 'u-sr-only',
          floatedTop && 'label--floating--top',
          inputIsFocused && 'label--floating--input-focused',
          inputIcons?.left && 'label--floating--input-has-left-icon',
          inputIcons?.right
            ? inputIcons?.feedback
              ? 'label--floating--input-has-right-icons'
              : 'label--floating--input-has-right-icon'
            : '',
          theme === 'dark' && 'label--floating--dark',
        ])}
        data-testid="floating-label-container"
      >
        <span className={joinStrings(['label__text', textClassName])}>{children}</span>
      </label>
    );
  }
);

FloatingLabel.displayName = 'FloatingLabel';
