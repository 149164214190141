import React, { FunctionComponent } from 'react';
import { GameInfoCard } from '../GameInfo/GameInfoCard';
import { Tooltip } from '../../shared/Tooltip';
import './styles';

export type GameFeature = {
  thumbnail: {
    width?: number | null;
    height?: number | null;
    title: string;
    imgUrl: string;
  };
  displayName: string;
  description: string;
};

export type GameFeaturesProps = {
  gameFeatures: GameFeature[];
  heading: string;
};

/**
 * Present a list of game features.
 */
export const GameFeatures: FunctionComponent<GameFeaturesProps> = ({ gameFeatures = [], heading }) => {
  return (
    <>
      {gameFeatures.length > 0 && (
        <GameInfoCard heading={heading}>
          <dl className="game-features__list">
            {gameFeatures.map(({ thumbnail, displayName, description }) => {
              return (
                <div key={displayName} className="game-features__list-item">
                  <div className="game-features__list-item-img">
                    <img src={thumbnail.imgUrl} alt={thumbnail.title} />
                  </div>
                  <dt>{displayName}</dt>
                  <Tooltip
                    className="game-features__list-item-tooltip"
                    buttonProps={{
                      'aria-label': 'More information',
                      size: 'inherit',
                    }}
                    position="bottom"
                    alignment="end"
                    trigger="click"
                    entryAnimation="slideFromRight"
                    tooltipMargin={8}
                    theme="dark"
                  >
                    {description}
                  </Tooltip>
                </div>
              );
            })}
          </dl>
        </GameInfoCard>
      )}
    </>
  );
};
