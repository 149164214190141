import React, { FC } from 'react';
import { mapGamesToSimpleSlides } from '../../RecommendedGames/mapGamesToSimpleSlides';
import noop from 'lodash/fp/noop';
import { Game } from '../../games.model';
import { useDeviceInfo } from '../../../../hooks/useDeviceInfo';
import { Carousel } from '../../Carousel';
import { groupedSlides } from '../../../../utils/grouped-slides';
import './GameSearchInputCarousel.scss';

interface GameSearchInputCarouselProps {
  onClose: () => void;
  data: Game[];
}

export const GameSearchInputCarousel: FC<GameSearchInputCarouselProps> = ({ data, onClose = noop }): JSX.Element => {
  const { isTablet } = useDeviceInfo();
  const desktopItemsPerRow = 6;
  const tabletItemsPerRow = 4;
  const itemsPerRow = isTablet ? tabletItemsPerRow : desktopItemsPerRow;
  const rowsDisplayed = isTablet ? (data.length > itemsPerRow ? 2 : 1) : 1;
  const isMultipleRows = rowsDisplayed > 1;

  const GamesMappedtoSlides = mapGamesToSimpleSlides(data, onClose);

  return (
    <div className={isMultipleRows ? 'game-search-input-carousel--multiple-rows' : ''}>
      <Carousel
        slides={groupedSlides(GamesMappedtoSlides, rowsDisplayed)}
        visibleConfig={{
          wide: desktopItemsPerRow,
          desktop: desktopItemsPerRow,
          tablet: tabletItemsPerRow,
          mobile: 2,
        }}
        showArrows
      />
    </div>
  );
};
