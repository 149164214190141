import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from 'react-oidc-context';
import Cookies from 'js-cookie';
import { AuthenticatedHeader } from './AuthenticatedHeader';
import { UnauthenticatedHeader } from './UnauthenticatedHeader';
import { MediaBreakpoint, useBreakpointInfo } from '../../hooks/useDeviceInfo';
import { UrlOptions, isHidden } from '../../utils/set-url-storage';
import { isNavigationExpanded } from '../../state/app';
import { setIsNavigationExpanded } from '../../state/app/reducers';
import { useSignIn } from '../../hooks/auth';

type HeaderProps = {
  isNavHidden?: boolean;
};

export const Header: FC<HeaderProps> = ({ isNavHidden }) => {
  const dispatch = useDispatch();
  const auth = useAuth();
  const signIn = useSignIn();
  const isBelowTabletLandWidth = useBreakpointInfo([MediaBreakpoint.BELOW_TABLET]);
  const navigationExpanded = useSelector(isNavigationExpanded);

  const handleOpenLogin = (): void => {
    signIn();
  };

  const handleHamburgerClick = (): void => {
    dispatch(setIsNavigationExpanded(!navigationExpanded));

    const isMainMenuHidden = Cookies.get('isMainMenuHidden');

    if (!isBelowTabletLandWidth) {
      Cookies.set('isMainMenuHidden', isMainMenuHidden === 'true' ? 'false' : 'true');
    }
  };

  return !isHidden(UrlOptions.hideHeader) ? (
    <div className="header-container">
      {auth?.isAuthenticated ? (
        <AuthenticatedHeader onHamburgerClick={handleHamburgerClick} isNavHidden={isNavHidden} />
      ) : (
        <UnauthenticatedHeader
          onHamburgerClick={handleHamburgerClick}
          onOpenLogin={handleOpenLogin}
          isNavHidden={isNavHidden}
        />
      )}
    </div>
  ) : null;
};
