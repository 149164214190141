export type PaginatedResponse<T> = {
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
  totalPages: number;
  items: T[];
};

export type PaginationParams = {
  take?: number;
  skip?: number;
};

export type DateRangePaginationParams = {
  startdate: string;
  enddate: string;
} & PaginationParams;

export type MSpineData<T> = {
  data: T;
  payload: T;
  status: number;
};

export type MSpineDataPromise<T> = Promise<MSpineData<T>>;

export interface MSpineErrorResponse {
  data: { errors: Array<ErrorData> };
  errors: string[];
  payload?: { reason?: string; subReason: string };
  status: number;
  headers: {
    'x-gateway-geo-reason': string;
    'x-gateway-person-id': string;
    'x-gateway-status': string;
  };
}

export interface ApiError<E extends ErrorData = ErrorData> {
  errors: Array<E>;
}

export type ResponseStatus = { status: string | number };

export interface ErrorData {
  code: string;
  error?: string;
  // eslint-disable-next-line camelcase
  error_description?: string;
  status: string | number; // TODO: Investigate removal of number here - used for Login geo errors but unsure of structure/geo could be removed
  title: string;
  detail: string;
  reason?: string;
  meta: {
    serviceId?: string;
    correlation?: string;
    correlationId?: string;
    eloraToken?: string;
  };
}

export type SortParam = 'asc' | 'desc';
export enum Endpoint {
  appAPI = 'appAPI',
  betzoneDirectusAPI = 'betzoneDirectusAPI',
}
