import React from 'react';
import { RegistrationStepConfig } from '../../components/Registration/types';
import { useTranslations } from '../useTranslationsHelper';
import { RegistrationFormStep } from '../../components/Registration/steps/RegistrationFormStep';
import { RegistrationStepperStep } from '../../components/Registration/stepper';
import { SafeSecure } from '../../components/shared/SVG/Icons';
import { useRegistrationOuterSteps } from './useRegistrationOuterSteps';

export const useRegistrationSteps = (): RegistrationStepConfig[] => {
  const { t } = useTranslations();
  const outerSteps = useRegistrationOuterSteps();

  return [
    {
      id: 'account-details',
      title: t('register.steps.account.credentials'),
      shortTitle: t('register.steps.account.credentials.short'),
      backEnabled: false,
      isUserHaveAccountText: true,
      introText: (
        <>
          <span>{t('register.steps.account.email-description')}</span>
          <div className="default-registration-form-step__icon">
            <SafeSecure />
          </div>
        </>
      ),
      StepComponent: RegistrationFormStep,
      StepperStepComponent: RegistrationStepperStep,
      fieldSets: [
        {
          id: 'registration-account-details-fieldset',
          labelSrOnly: true,
          fields: [{ name: 'email' }, { name: 'password' }],
        },
      ],
    },
    {
      id: 'personal-details',
      title: t('register.steps.personal.title'),
      shortTitle: 'Personal details',
      StepComponent: RegistrationFormStep,
      StepperStepComponent: RegistrationStepperStep,
      backEnabled: true,
      fieldSets: [
        {
          id: 'registration-personal-details-fieldset',
          fields: [{ name: 'honorificPrefix' }, { name: 'givenName' }, { name: 'familyName' }, { name: 'birthDate' }],
        },
      ],
    },
    {
      id: 'address-details',
      title: t('register.steps.address.title'),
      shortTitle: 'Address details',
      StepComponent: RegistrationFormStep,
      StepperStepComponent: RegistrationStepperStep,
      backEnabled: true,
      submissionStep: true,
      fieldSets: [
        {
          id: 'registration-address-details-fieldset',
          fields: [{ name: 'addrStreetAddress' }, { name: 'addrLocality' }, { name: 'addrPostalCode' }],
        },
        {
          id: 'registration-details',
          fields: [{ name: 'mobileNumber' }, { name: 'promoCode' }],
        },
        {
          id: 'registration-accept-marketing-fieldset',
          label: '',
          fields: [
            {
              name: 'acceptMarketingPreferences',
            },
          ],
        },
        {
          id: 'registration-terms-details-fieldset',
          label: '',
          fields: [{ name: 'acceptTerms' }],
        },
      ],
    },
    ...outerSteps,
  ];
};
