import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GameState } from './types';
import { ComplianceGameSession, Game } from '../../components/Games/games.model';
import { IDisabledGamesData } from '../../models/game-session.model';

const initialState: GameState = {
  launchUrl: '',
  gameLaunchTime: '',
  gameSessions: [],
  disabledGamesData: {
    enableGamesDate: null,
    areGamesDisabled: false,
  },
};

export const gameSlice = createSlice({
  name: 'game',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setGameLaunchUrl: (state: GameState, action: PayloadAction<string>) => {
      return {
        ...state,
        launchUrl: action.payload,
      };
    },
    setGameLaunchTime: (state: GameState) => {
      return {
        ...state,
        gameLaunchTime: new Date().toISOString(),
      };
    },
    /**
     * Generate action to set game sessions in store
     */
    setGameSessions: (state: GameState, action: PayloadAction<ComplianceGameSession[]>) => {
      return {
        ...state,
        gameSessions: action.payload,
      };
    },
    /**
     * Generate action to set current game data
     */
    setGameData: (state: GameState, action: PayloadAction<Game>) => {
      return {
        ...state,
        gameData: action.payload,
      };
    },
    /**
     * Generate action to set games disabled
     */
    setDisabledGamesData: (state: GameState, action: PayloadAction<IDisabledGamesData>) => {
      return {
        ...state,
        disabledGamesData: action.payload,
      };
    },
  },
});

export const { setGameLaunchUrl, setGameLaunchTime, setGameSessions, setGameData, setDisabledGamesData } =
  gameSlice.actions;

export default gameSlice.reducer;
