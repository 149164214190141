import React, { FunctionComponent } from 'react';
import { AccountButtons } from './AccountButtons';
import { AccountNotifications } from './AccountNotifications';
import { AccountWallet } from './AccountWallet';
import { MyAccountHomeTile } from './MyAccountHomeTile';
import { Heading } from '../../../shared/Heading';
import { Paragraph } from '../../../shared/Paragraph';
import { useNavigate } from 'react-router-dom';
import { AccountPageWrapper } from '../AccountPageWrapper';
import { AccountTile } from '../AccountTile';
import { APP_URLS, PROFILE_TILE_NAME } from '../../../../consts';
import { GamblingControlsIcon, HelpIcon, ProfileIcon, SettingsIcon } from '../../../shared/SVG/Icons';
import MyActivity from '../../../../assets/Account/my-activity.png';
import './styles';
import { useTranslations } from '../../../../hooks/useTranslationsHelper';

export type MyAccountHomeProps = {
  /**
   * Callback that's executed when the account drawer is requested to close
   */
  onClose: () => void;
  /**
   * Users first name to be displayed in banner. Should be provided but left optional as redux value may be undefined
   */
  firstName?: string;
  /**
   * Ref that will be added to the close button as it's the first focusable element - so should be focused on drawer open for accessibility.
   */
  elementToFocusOnOpen?: React.RefObject<HTMLButtonElement>;
  /**
   * Function to navigate to homepage
   */
  onGoToHome: (withRefresh: boolean) => void;
};
export declare type AccountOption = {
  label: string;
  imageSrc?: string;
  iconComponent?: JSX.Element;
  ctaUrl?: string;
  description?: string;
};

export const MyAccountHome: FunctionComponent<MyAccountHomeProps> = ({ onClose, firstName, elementToFocusOnOpen }) => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const showSourceOfIncome = false;

  const iconWrapper = (Icon: React.FunctionComponent): JSX.Element => {
    return (
      <div className="my-account-home__account-icons">
        <Icon />
      </div>
    );
  };

  const pageAccountOptions: AccountOption[] = [
    {
      label: t('my-account.my-activity'),
      ctaUrl: APP_URLS.myAccount.activity,
      imageSrc: MyActivity,
    },
    {
      label: t('my-account.my-profile'),
      ctaUrl: APP_URLS.myAccount.profile,
      iconComponent: iconWrapper(ProfileIcon),
    },
    {
      label: t('my-account.settings'),
      ctaUrl: APP_URLS.myAccount.settings,
      iconComponent: iconWrapper(SettingsIcon),
    },
    {
      label: t('my-account.safer-gambling-tools'),
      ctaUrl: APP_URLS.myAccount.gamblingControls,
      iconComponent: iconWrapper(GamblingControlsIcon),
    },
    {
      label: t('my-account.help'),
      ctaUrl: APP_URLS.help,
      iconComponent: iconWrapper(HelpIcon),
    },
  ];

  const handleClick = (ctaUrl: string): void => {
    const isAccountUrl = ctaUrl.includes(APP_URLS.myAccount.home);

    isAccountUrl ? navigate(ctaUrl) : window.open(ctaUrl, '_self');
  };

  const firstBlockOptions = pageAccountOptions.shift();

  return (
    <AccountPageWrapper
      hasBackButton={false}
      onClose={onClose}
      className="my-account-home"
      hasDarkBanner
      elementToFocusOnOpen={elementToFocusOnOpen}
    >
      <div className="my-account-home__banner">
        <Heading level={3} noMargin className="my-account-home__heading">
          {t('my-account.hello')} {firstName}
        </Heading>
        <AccountNotifications />
        <AccountButtons />
      </div>
      <div className="my-account-home__content">
        <AccountWallet />
        <div className="my-account-home__sections">
          {firstBlockOptions && (
            <AccountTile
              className="my-account-home__activity-section"
              onClick={(): void => {
                firstBlockOptions.ctaUrl && handleClick(firstBlockOptions.ctaUrl);
              }}
              key={firstBlockOptions.ctaUrl}
            >
              <MyAccountHomeTile
                heading={firstBlockOptions.label}
                description={firstBlockOptions.description || ''}
                flexDirection="row"
                icon={<img src={firstBlockOptions.imageSrc} alt={firstBlockOptions.label} />}
              />
            </AccountTile>
          )}
          {pageAccountOptions.map((option) => (
            <AccountTile
              onClick={(): void => {
                option.ctaUrl && handleClick(option.ctaUrl);
              }}
              key={option.ctaUrl}
            >
              {/* Consider to apply SoF for translations*/}
              {showSourceOfIncome && option.label === PROFILE_TILE_NAME && (
                <Paragraph className="my-account-home__sections-label" size="xxs">
                  ACTION REQUIRED
                </Paragraph>
              )}
              <MyAccountHomeTile
                heading={option.label}
                description={option.description || ''}
                icon={option.iconComponent || <img src={option.imageSrc} alt={option.label} />}
              />
            </AccountTile>
          ))}
        </div>
      </div>
    </AccountPageWrapper>
  );
};
