import React, { useState } from 'react';
import { APP_URLS } from '../../../consts';
import { Endpoint } from '../../../models/api.model';
import { useSnackbar } from '../../../hooks/snackbar';
import { useNavigate, useParams } from 'react-router-dom';
import { Heading } from '../../../components/shared/Heading';
import { NavigationTemplate } from '../../NavigationTemplate';
import { Spinner } from '../../../components/shared/Spinner';
import { useIframePageGetBySlugQuery } from '../../../libs/graphql/betzoneDirectusAPI/queries/__generated__/iframe-page-get-by-slug.query.generated';
import './IframePage.scss';
import { useUserContext } from '../../../providers/UserProvider';
import { useTranslations } from '../../../hooks/useTranslationsHelper';

interface IframePageData {
  title?: string | null;
  src?: string | null;
}

export const IframePage: React.FC = () => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { slug = '' } = useParams();
  const { addSnack } = useSnackbar();
  const [iframeData, setIframeData] = useState<IframePageData | null>(null);
  const { userProfileId } = useUserContext();

  const handleError = (): void => {
    addSnack({
      type: 'error',
      theme: 'dark',
      message: t('errors.general'),
    });
    navigate(APP_URLS.home);
  };

  const { loading } = useIframePageGetBySlugQuery({
    variables: {
      slug,
    },
    onCompleted: (data) => {
      if (!data.iframe_pages.length) {
        return handleError();
      }

      setIframeData(data.iframe_pages[0]);
    },
    onError: (error) => {
      console.error(error);

      return handleError();
    },
    context: { endpoint: Endpoint.betzoneDirectusAPI },
  });

  const getIframeSrc = (): string => {
    if (!iframeData || !iframeData.src) return '';

    const url = new URL(iframeData.src);

    if (userProfileId) {
      url.searchParams.append('token', userProfileId);
    }

    return url.toString();
  };

  return (
    <NavigationTemplate>
      {loading ? (
        <div className="spinner-wrapper">
          <Spinner />
        </div>
      ) : iframeData ? (
        <div className="iframe-page">
          <Heading level={1}>{iframeData.title || 'Untitled'}</Heading>
          <iframe src={getIframeSrc()} title={iframeData.title || 'Game'} />
        </div>
      ) : (
        <div>No iframe data found</div>
      )}
    </NavigationTemplate>
  );
};
