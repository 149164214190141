import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { joinStrings } from '../../../../utils/string';
import { MediaBreakpoint, useBreakpointInfo } from '../../../../hooks/useDeviceInfo';
import { Badge } from '../../Badge';
import { IconButton } from '../../IconButton';
import { RouterLink } from '../../Link';
import { APP_URLS } from '../../../../consts';
import { Logo } from '../Logo';
import { isNavigationExpanded } from '../../../../state/app';
import './styles';

export type HamburgerLogoProps = {
  onHamburgerClick: () => void;
  isNavHidden?: boolean;
};

type HamburgerPieceProps = { className: string };

const HamburgerPiece: FC<HamburgerPieceProps> = ({ className }): JSX.Element => (
  <span className={joinStrings(['piece', className])} />
);

export const HamburgerLogo: FC<HamburgerLogoProps> = ({ onHamburgerClick, isNavHidden = false }) => {
  const isBelowDesktopWidth = useBreakpointInfo([MediaBreakpoint.BELOW_DESKTOP]);
  const isDesktopAndLesser = useBreakpointInfo([MediaBreakpoint.DESKTOP, MediaBreakpoint.BELOW_DESKTOP]);
  const isLessThanTablet = useBreakpointInfo([MediaBreakpoint.BELOW_TABLET]);

  const inboxUnreadCount = false;
  const navigationExpanded = useSelector(isNavigationExpanded);
  const [isOpen, setIsOpen] = React.useState<boolean>(navigationExpanded);

  const isDesktop = !isBelowDesktopWidth;
  const isMenuHiddenAndCollapsed = !navigationExpanded;

  useEffect(() => {
    setIsOpen(navigationExpanded);
  }, [navigationExpanded]);

  // TODO: add necessary logic
  const shouldDisplayBadge = inboxUnreadCount && (isLessThanTablet || isMenuHiddenAndCollapsed || !isOpen);
  const shouldDisplayDesktopLogo = isNavHidden && !isDesktopAndLesser ? isOpen : true;

  return (
    <div className="hamburger-logo">
      <div className="hamburger-logo__inner">
        {shouldDisplayBadge ? (
          <Badge
            className={joinStrings(['hamburger-logo__pip', isOpen && 'hamburger-logo__pip--hidden'])}
            shape="circle"
            color="error"
          >
            {99}
          </Badge>
        ) : null}
        <IconButton
          className={joinStrings([
            'hamburger-logo__hamburger',
            isDesktopAndLesser && isOpen && 'hamburger-logo__hamburger--open-tablet',
            isDesktop && shouldDisplayDesktopLogo && 'hamburger-logo__hamburger--open-desktop',
          ])}
          size="x-large"
          icon={
            <>
              <HamburgerPiece className="top-piece" />
              <HamburgerPiece className="middle-piece" />
              <HamburgerPiece className="bottom-piece" />
            </>
          }
          aria-label="header-menu"
          onClick={onHamburgerClick}
          color="inherit"
        />
      </div>

      <div className="hamburger-logo__logo">
        <RouterLink to={APP_URLS.home} aria-label="header-logo">
          <Logo />
        </RouterLink>
      </div>
    </div>
  );
};
