import React, { FC, useEffect } from 'react';

import { BonusBar } from '../../../shared/SVG/BonusBar/BonusBar';
import { Paragraph } from '../../../shared/Paragraph';

const formatter = new Intl.NumberFormat('nu', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  useGrouping: false,
});

interface WelcomeBonusVariableProps {
  depositAmount: number;
  bonusPercentage?: number;
  maximumBonusAmount?: number;
  minimumDeposit?: number;
}

export const WelcomeBonusVariable: FC<WelcomeBonusVariableProps> = ({
  depositAmount,
  bonusPercentage = 1,
  maximumBonusAmount = 1,
  minimumDeposit = 1,
}) => {
  const bonusCalculation =
    depositAmount < minimumDeposit ? 0 : parseFloat(formatter.format((depositAmount / 100) * +bonusPercentage));
  const bonusPercentAmount = (bonusCalculation / maximumBonusAmount) * 100;
  const MINIMUM_BONUS_AMOUNT = 0;

  const bonusAmount = bonusCalculation > maximumBonusAmount ? maximumBonusAmount : bonusCalculation;

  const totalAmount = +bonusAmount + depositAmount;

  const formatValue = (value: number): string => {
    return String(isNaN(value) ? 0 : value);
  };

  useEffect(() => {
    // TODO: Set welcome bonus amount
  }, [depositAmount]);

  return (
    <>
      <div className="welcome-bonus__step">
        <span className="welcome-bonus__circle">2</span>
        <Paragraph size="md" noMargin>
          Plus you will get
          <b>{`${bonusAmount} in bonus funds`}</b>
        </Paragraph>
      </div>
      <BonusBar value={bonusPercentAmount} />
      <div className="welcome-bonus__min-max-amount">
        <div>
          {MINIMUM_BONUS_AMOUNT}
          <span>Min bonus</span>
        </div>
        <div>
          {maximumBonusAmount}
          <span>Max bonus</span>
        </div>
      </div>
      <div className="welcome-bonus__step">
        <span className="welcome-bonus__circle">3</span>
        <Paragraph size="md" noMargin>
          {`Total amount to play with: `}
          <b>{formatValue(totalAmount)}</b>
        </Paragraph>
      </div>
    </>
  );
};
