import React, { FunctionComponent } from 'react';
import { KambiCouponDescription } from '../../../Kambi/types';
import './CouponDescriptionWithOdds.scss';

type CouponDescriptionWithOddsProps = {
  description?: KambiCouponDescription;
};

export const CouponDescriptionWithOdds: FunctionComponent<CouponDescriptionWithOddsProps> = ({ description }) => {
  if (!description) return null;

  return typeof description === 'string' ? (
    description
  ) : (
    <span>
      {description.text}
      {!!description.odds && ` @`}
      {!!description.oldOdds && <span className="coupon-description-with-odds__value-old">{description.oldOdds}</span>}
      <span className="coupon-description-with-odds__value">{description.odds}</span>
    </span>
  );
};
