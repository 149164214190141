import noop from 'lodash/noop';
import React, { FC, Fragment, MouseEvent, Suspense, useContext } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from '../../../../hooks/snackbar';
import { Button } from '../../../shared/Button';
import { Link, LinkProps, RouterLink } from '../../../shared/Link';
import { InformationIcon } from '../../../shared/SVG/Icons';
import { GameTags } from '../../GameInfo/GameTags';
import { Game } from '../../games.model';
import { getGameUrl } from '../../helper';
import { GameCardContext } from '../GameCard.context';
import { storageRoute } from '../../../../utils/storage';
import { getRedirectUri } from '../../../Authentification/helper';
import './styles';

export interface GameCardDetailProps {
  /**
   * Game object data.
   */
  game: Game;
  /**
   * Disables card buttons from being interactable.
   */
  disabled: boolean;
  /**
   * Is the game unavailable or not?
   */
  isUnavailable: boolean;
  /**
   * Show card actins.  Defaults to true
   */
  showActions?: boolean;
  /**
   * Call back for when user clicks the information button
   */
  onInfoClick?: () => void;
  /**
   * Call back for when user selects to play the game.
   */
  onPlayGame?: () => void;
  /**
   * Call back for when user selects to play the game demo.
   */
  onPlayDemo?: () => void;
  /**
   * Use a router link for navigation, defaults to true
   */
  routerLink?: boolean;
  /**
   * Callback that you can use for various solutions.
   */
  onClose?: () => void;

  handlePlayLoading: (arg: boolean) => void;

  isPlayLoading?: boolean;
}

export const GameCardActions: FC<GameCardDetailProps> = ({
  game,
  isUnavailable,
  disabled,
  onPlayGame,
  onPlayDemo,
  routerLink = true,
  onClose,
  handlePlayLoading,
  isPlayLoading,
}) => {
  const location = useLocation();
  const auth = useAuth();
  const navigate = useNavigate();
  const { addSnack } = useSnackbar();
  const { friendlyUrlName } = game;
  const isUserAuthenticated = auth?.isAuthenticated;

  // TODO: temporary solution to remove Demo btn
  const demoLaunchUrl = false;
  const { dismissCard } = useContext(GameCardContext);

  const handleUnauthenticated = async (e: React.MouseEvent): Promise<void> => {
    try {
      handlePlayLoading(true);
      const gameUrl = `/game-details/${friendlyUrlName}/real`;

      if (isUserAuthenticated) {
        if (auth.user?.expired) {
          await auth.signinSilent();
        }

        navigate(gameUrl);

        storageRoute.set({
          value: location.pathname,
        });

        return;
      }

      e.preventDefault();
      dismissCard();
      auth.signinRedirect({ redirect_uri: getRedirectUri(gameUrl) });

      onClose && onClose();
    } catch (error) {
      console.error('retrieveGameLaunchUrl error: ', error);
      addSnack({
        type: 'error',
        message: "Oops, something's gone wrong",
      });
    } finally {
      handlePlayLoading(false);
    }
  };

  const demoPlayLinkProps: Partial<LinkProps> = {
    'aria-label': 'demo',
    buttonStyle: {
      variant: 'secondary',
      size: 'medium',
    },
    onClick(e: React.MouseEvent) {
      isPlayLoading && e.preventDefault();

      handleUnauthenticated(e);

      onPlayDemo?.();
    },
  };

  const primaryBtnClick = (e: MouseEvent): void => {
    onPlayGame?.();

    handleUnauthenticated(e);

    onClose && onClose();

    handlePlayLoading(false);
  };

  return (
    <div className="game-card-details__actions">
      {isUnavailable || disabled ? (
        <Button type="button" variant="secondary" size="medium" disabled>
          NOT AVAILABLE
        </Button>
      ) : (
        <Fragment>
          {demoLaunchUrl &&
            (routerLink ? (
              <RouterLink className="game-card-details__link" to={`${demoLaunchUrl}`} {...demoPlayLinkProps}>
                DEMO
              </RouterLink>
            ) : (
              <Link className="game-card-details__link" href={`${demoLaunchUrl}`} {...demoPlayLinkProps}>
                DEMO
              </Link>
            ))}
          <Button
            type="button"
            variant="primary"
            className="game-play-now__btn"
            onClick={(e): void => {
              handlePlayLoading(true);
              setTimeout(() => {
                primaryBtnClick(e);
              }, 0);
            }}
            isSubmitting={isPlayLoading}
          >
            PLAY
          </Button>
        </Fragment>
      )}
    </div>
  );
};

// TODO: update this component
export const GameCardDetails: FC<GameCardDetailProps> = (props) => {
  const { game, isPlayLoading, routerLink = true, showActions = true, onInfoClick = noop, onClose } = props;
  const { friendlyUrlName } = game;

  const navigate = useNavigate();

  const handleLinkClick = (): void => {
    if (onInfoClick) {
      onInfoClick();
    }
  };

  const openGameCardDetails = (event: MouseEvent<HTMLAnchorElement>, path: string): void => {
    event.preventDefault();

    if (isPlayLoading) {
      return;
    }

    onInfoClick();

    onClose?.();

    navigate(getGameUrl(path));
  };

  return (
    <div className="game-card-details">
      <div className="game-card-details__info">
        <div className="game-card-details__info-tags">
          <GameTags game={game} />
        </div>

        <div className="game-card-details__info-icon">
          <Suspense>
            {routerLink ? (
              <RouterLink
                aria-label="game-card-info"
                onKeyDown={onInfoClick}
                onClick={(event): void => {
                  openGameCardDetails(event, friendlyUrlName);
                }}
                to={getGameUrl(friendlyUrlName)}
                icon={<InformationIcon />}
              />
            ) : (
              <Link
                aria-label="game-card-info"
                onKeyDown={onInfoClick}
                onClick={handleLinkClick}
                href={getGameUrl(friendlyUrlName)}
                icon={<InformationIcon />}
                iconPosition="right"
              />
            )}
          </Suspense>
        </div>
      </div>

      {showActions && <GameCardActions {...props} />}
    </div>
  );
};
