import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CashierModal, CashierState, CashierType } from './types';

const initialState: CashierState = {
  cashierModal: {
    isOpen: false,
    type: CashierType.Deposit,
  },
};

export const cashierSlice = createSlice({
  name: 'cashier',
  initialState,
  reducers: {
    setCashierModal: (state: CashierState, action: PayloadAction<CashierModal>) => {
      return {
        ...state,
        cashierModal: action.payload,
      };
    },
  },
});

export const { setCashierModal } = cashierSlice.actions;

export default cashierSlice.reducer;
