import { RefObject, useEffect, useState } from 'react';
import { passiveOption } from '../../consts';

export function useHasScrolled(scrollRef: RefObject<HTMLDivElement>): boolean {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const onScroll = (): void => {
      if (scrollRef && scrollRef.current) {
        if (!isScrolled && scrollRef.current.scrollTop > 0) {
          setIsScrolled(true);
        } else if (isScrolled && scrollRef.current.scrollTop === 0) {
          setIsScrolled(false);
        }
      }
    };

    scrollRef.current?.addEventListener('scroll', onScroll, passiveOption);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => scrollRef.current?.removeEventListener('scroll', onScroll, passiveOption as EventListenerOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScrolled]);

  return isScrolled;
}
