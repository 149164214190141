export type CookiesPreferencesMode = { mode?: 'prompt' | 'account' };

export type CookiesPreferenceProps = {
  /**
   * Callback that's executed when an error has occured.
   */
  onError?: () => void;
  /**
   * Callback that's executed onClose.
   */
  onClose?: () => void;
} & CookiesPreferencesMode;

export enum ConsentType {
  STRICTLY_NECESSARY = 'strictly_necessary',
  FUNCTIONAL = 'functional',
  PERFORMANCE = 'performance',
  TARGETING = 'targeting',
}

export type ConsentSubmission = {
  consent: ConsentEntry[];
  consentedOn: number;
  playerId?: string;
};

export type MinifiedConsentSubmission = [number, MinifiedConsentEntry[]];

export type MinifiedConsentEntry = [ConsentType, boolean, ConsentVendorId[]];

export enum ConsentEvent {
  CONSENT_UPDATED = 'consent_updated',
}

export type ConsentVendorId = number;

export type ConsentSettings = {
  settings: ConsentEntryWithVendors[];
  pendingReconsent: ConsentEntryWithVendors[];
  consentedOn?: number;
  playerId?: string;
};

export type ConsentEntry = {
  type: ConsentType;
  userHasConsented?: boolean;
  alwaysActive: boolean;
  vendorIds: ConsentVendorId[];
};

export type ConsentEntryWithVendors = {
  vendors: ConsentVendor[];
} & ConsentEntry;

export type ConsentVendor = {
  id: ConsentVendorId;
  name: string;
  description: string;
  cookies: ConsentVendorCookie[];
};

export type ConsentVendorCookie = {
  name: string;
  isFirstParty: boolean;
  isFirstPartyApex: boolean;
  domain?: string;
  duration: number;
};
