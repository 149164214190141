import { TransformedTranslationsObject, UseTranslationsHelperReturnData } from './types';
import { TranslationGetAllQuery } from '../../libs/graphql/betzoneDirectusAPI/queries/__generated__/translations.query.generated';
import { useTranslationsContext } from '../../providers/TranslationsProvider';

export const useTranslations = (): UseTranslationsHelperReturnData => {
  const { localTranslations, translations } = useTranslationsContext();

  const transformTranslationsObject = (obj: TranslationGetAllQuery): TransformedTranslationsObject => {
    const translationsArray = obj.translations_translations;

    const result: TransformedTranslationsObject = {};

    translationsArray.forEach((item) => {
      if (item.translations_id && item.translations_id.translations) {
        const key = item.translations_id.translations_key;
        const content = item.translations_id.translations[0]?.content;

        if (key && content) {
          result[key] = content;
        }
      }
    });

    return result;
  };

  const t = (key: string, params?: { [key: string]: string | number }): string => {
    let translation = translations?.[key] || localTranslations[key];

    if (!translation) {
      return key;
    }

    if (params) {
      Object.keys(params).forEach((param) => {
        const regex = new RegExp(`{{${param}}}`, 'g');

        translation = translation.replace(regex, String(params[param]));
      });
    }

    return translation;
  };

  return { t, transformTranslationsObject };
};
