import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { store } from '../../state/store';
import { UserProvider } from '../UserProvider';
import { ManagedApolloProvider } from '../../apollo';
import { TranslationsProvider } from '../TranslationsProvider';

interface Props {
  children: React.ReactNode;
}

export const ProvidersCombiner: FC<Props> = ({ children }) => {
  return (
    <ManagedApolloProvider>
      <UserProvider>
        <Provider store={store}>
          <TranslationsProvider>{children}</TranslationsProvider>
        </Provider>
      </UserProvider>
    </ManagedApolloProvider>
  );
};
