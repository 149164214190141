import { ButtonBack, ButtonNext, CarouselContext, CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import React, { Fragment, FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { GameItem } from '../GameItem';
import { GamesListUIComponentProps } from '../GamesList.types';
import { useOnScreenVisibility } from '../../../../hooks/document';
import { Game } from '../../games.model';
import { joinStrings } from '../../../../utils/string';
import { Icon } from '../../../shared/Icon';
import './DefaultCarousel.scss';

export type GamesCarouselProps = {
  /**
   * Optional pagination mode.  Defaults to no pagination buttons.
   *
   * `overlay` will place the buttons over the top of the slider.
   * `normal` will place the buttons around the slider
   */
  pagination?: 'overlay' | 'normal';
} & GamesListUIComponentProps;

/**
 * Wrapper component to use carousel context to check on screen visibility on
 * slide change.
 */
const SliderWrapper: FunctionComponent<GamesCarouselProps & { size: 'medium' | 'small' }> = ({
  onVisibilityUpdate,
  onPlayDemo,
  onPlayGame,
  onViewGameInfo,
  disableGameMenu,
  fallbackImageAltText,
  fallbackImageUrl,
  showGameName,
  getGameUrl,
  games,
  size,
}) => {
  const { checkVisibility, setVisibilityItems, visibilityItemContainerRefs } = useOnScreenVisibility<
    Game,
    HTMLDivElement
  >({
    onVisibilityUpdate,
  });
  const carouselContext = useContext(CarouselContext);
  const [currentSlide, setCurrentSlide] = useState(carouselContext.state.currentSlide);
  const onCarouselChange = useCallback(
    (): void => setCurrentSlide(carouselContext.state.currentSlide),
    [carouselContext.state.currentSlide]
  );

  useEffect(() => {
    carouselContext.subscribe(onCarouselChange);

    return () => carouselContext.unsubscribe(onCarouselChange);
  }, [carouselContext, onCarouselChange]);

  useEffect(() => {
    setVisibilityItems(games as Game[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [games]);

  useEffect(() => {
    // Allow slide change css transition to finish.
    const timeout = setTimeout(checkVisibility, 500);

    return () => clearTimeout(timeout);
  }, [checkVisibility, currentSlide]);

  return (
    <Slider>
      {(games as Array<Game>).map((game, i) => {
        return (
          <Slide key={game.gameId.toString()} index={i}>
            <div ref={visibilityItemContainerRefs[i]}>
              <GameItem
                size={size}
                showGameName={showGameName}
                gameUrl={getGameUrl(game.friendlyUrlName)}
                game={game}
                disableMenu={disableGameMenu}
                fallbackImageAltText={fallbackImageAltText}
                fallbackImageUrl={fallbackImageUrl}
                onViewGameInfo={onViewGameInfo && ((): void => onViewGameInfo(game))}
                onPlayDemo={onPlayDemo && ((): void => onPlayDemo(game))}
                onPlayGame={onPlayGame && ((): void => onPlayGame(game))}
              />
            </div>
          </Slide>
        );
      })}
    </Slider>
  );
};

/**
 * Games Carousel UI presentation component.
 * Connection of this component to games data source is performed in components such as GamesList.
 *
 * ## Example Usage
 *
 * ```tsx
 *<GamesCarousel
 *  games={games}
 *  columns={4}
 *  showGameName
 *  getGameUrl={gameGameUrl}
 *  fallbackAltText='Fallback image'
 *  fallbackImageUrl='https://demo.com/casino-image-fallback.jpg'
 *  {...{ onPlayGame, onPlayDemo, onViewGameInfo, onVisibilityUpdate }}
 * />
 * ```
 */
export const DefaultCarousel: FunctionComponent<GamesCarouselProps> = ({ games, ...props }) => {
  const size = props.columns < 5 ? 'medium' : 'small';

  return (
    <Fragment>
      {games.length > 0 && (
        <CarouselProvider
          data-testid="games-carousel"
          naturalSlideWidth={100}
          naturalSlideHeight={75}
          isIntrinsicHeight
          totalSlides={games.length}
          visibleSlides={props.columns}
          className={joinStrings([
            'games-carousel',
            props.pagination && `games-carousel--pagination-${props.pagination}`,
            `games-carousel--size-${size}`,
          ])}
        >
          <ButtonBack className="games-carousel__pagination-button">
            <Icon variant="ChevronLeft" />
          </ButtonBack>
          <SliderWrapper games={games} size={size} {...props} />
          <ButtonNext className="games-carousel__pagination-button">
            <Icon variant="ChevronRight" />
          </ButtonNext>
        </CarouselProvider>
      )}
    </Fragment>
  );
};
