/* eslint-disable camelcase */
export enum GameTheme {
  Classic = 'classic',
  MoviesAndTV = 'movies_and_tv',
  Riches = 'riches',
  Nature = 'nature',
  Asian = 'asian',
  EgyptAndGreece = 'egypt_and_greece',
  Culture = 'culture',
  Adventure = 'adventure',
  Fantasy = 'fantasy',
  Cute = 'cute',
}

export enum GameFeature {
  New = 'new',
  ThreeReelSlots = 'three_reel_slots',
  FreeSpins = 'free_spins',
  PickEmBonus = 'pick_em_bonus',
  TumblingReels = 'tumbling_reels',
  StackedSymbols = 'stacked_symbols',
  Lines1_9 = 'lines_one_nine',
  Lines10_49 = 'lines_ten_fortynine',
  Lines50Plus = 'lines_fifty_plus',
  Ways243Plus = '243_ways_plus',
}

export const defaultThemes = Object.values(GameTheme);
export const defaultFeatures = Object.values(GameFeature);
