import last from 'lodash/last';

/**
 * Take a list of items type T and join the none empty string values.
 * @param strings
 * @param delimiter
 */
export function joinStrings<T>(strings: T[], delimiter = ' '): string {
  return strings.filter((s) => s && typeof s === 'string').join(delimiter);
}

/*
 * Turn space seperated string to title case. 'second last name' => 'Second Last Name'
 * @param str
 */
export function titleCase(str: string): string {
  const splitStr = str.toLowerCase().split(' ');

  // using for loop to assist in join operation
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }

  // return the joined string
  return splitStr.join(' ');
}

/**
 * Turn camelCase to title case. 'secondLastName' => 'Second Last Name'
 * @param camelCaseString
 */
export const prettifyCamelCase = (camelCaseString: string): string => {
  const stringWithSpaces = camelCaseString.replace(/([A-Z])/g, (s) => ` ${s}`);

  return stringWithSpaces.charAt(0).toUpperCase() + stringWithSpaces.substring(1);
};

/**
 * Turn camelcase or spaced string into kebabcase or character seperated string. 'secondLastName' => 'second-last-name'
 * @param kebabCase
 */
export const kebabCase = (camelCaseString: string, seperator = '-'): string => {
  const match = camelCaseString.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g);

  return match ? match.join(seperator).toLowerCase() : camelCaseString;
};

export const filterSpecialHTMLCharacters = (textField: string): string =>
  textField.replace(/&nbsp;|&lt;|p&gt;|\/p&gt;/g, ' ');

/**
 * Replace symbols from a string
 * @param {string} [str=''] - string with HTML tag
 * @returns {string}        - string with replaced symbol or empty
 */
export const replaceSpecialSymbols = (str = '', ampersand = false): string =>
  ampersand ? str.replace(/(<([^>]+)>)/gi, '').replace(/&amp;/g, '&') : str.replace(/(<([^>]+)>)/gi, '');

export const getFriendlyUrlName = (gameUrl: string): string | undefined => {
  const gameUrlSplited = gameUrl.split('/');

  return last(gameUrlSplited);
};
