import React, { ComponentPropsWithRef, forwardRef, Fragment } from 'react';
import { joinStrings } from '../../../utils/string';
import './Menu.scss';

/**
 * NOTE: Helper components for Autocomplete.
 * Deliberately generic for potential re-use. E.g. Switch the Select from native to custom combobox
 *
 * If/when these components are reused they should be moved into their own folders since they will be shared.
 */

type MenuItemProps = {
  disabled?: boolean;
  isSelected?: boolean;
  isActive?: boolean;
  index?: number;
} & ComponentPropsWithRef<'li'>;
export const MenuItem = forwardRef<HTMLLIElement, MenuItemProps>(
  ({ disabled, children, isSelected, isActive, ...props }, ref) => {
    const baseClassName = 'menu-item';

    return (
      <li
        {...props}
        {...(disabled ? { 'aria-disabled': 'true', 'aria-selected': 'false' } : {})}
        ref={ref}
        className={joinStrings([
          baseClassName,
          isActive && `${baseClassName}--active`,
          isSelected && `${baseClassName}--selected`,
          disabled && `${baseClassName}--disabled`,
        ])}
      >
        {children}
      </li>
    );
  }
);
MenuItem.displayName = 'MenuItem';

type MenuProps = {
  isOpen: boolean;
  loadingItemsPlaceholder?: string | React.ReactNode;
  noItemsMessage?: string | React.ReactNode;
} & ComponentPropsWithRef<'ul'>;
export const Menu = forwardRef<HTMLUListElement, MenuProps>(
  ({ isOpen, noItemsMessage, loadingItemsPlaceholder, ...props }, ref) => {
    const baseClassName = 'menu';

    return (
      <ul
        {...props}
        ref={ref}
        aria-busy={loadingItemsPlaceholder ? 'true' : 'false'}
        className={joinStrings([baseClassName, isOpen && `${baseClassName}--open`])}
      >
        {isOpen && (
          <Fragment>
            {loadingItemsPlaceholder && (
              <MenuItem disabled>
                <span role="alert">{loadingItemsPlaceholder}</span>
              </MenuItem>
            )}
            {!loadingItemsPlaceholder && noItemsMessage && (
              <MenuItem disabled>
                <span role="alert">{noItemsMessage}</span>
              </MenuItem>
            )}
            {!loadingItemsPlaceholder && !noItemsMessage && props.children}
          </Fragment>
        )}
      </ul>
    );
  }
);
Menu.displayName = 'Menu';
