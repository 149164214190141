import React, { FunctionComponent } from 'react';
import { Balance } from '../../../../../shared/Balance';
import { Paragraph } from '../../../../../shared/Paragraph';
import './styles';
import { useTranslations } from '../../../../../../hooks/useTranslationsHelper';

type Props = {
  balance: number;
  isLoading: boolean;
  balanceType: 'cash' | 'bonus';
};

export const AccountWalletTextContent: FunctionComponent<Props> = ({ balance, balanceType, isLoading }) => {
  const { t } = useTranslations();

  return (
    <div className="account-wallet-text-content">
      <Paragraph size="xs" highlight className="account-wallet-text-content__balance-type">
        {balanceType === 'cash' ? t('my-account.cache-balance') : t('my-account.bonus-balance')}
      </Paragraph>
      <Balance isHeading balance={balance} isLoading={isLoading} className="account-wallet-text-content__balance" />
    </div>
  );
};
