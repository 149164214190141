import React, { FunctionComponent } from 'react';
import { TouchDrawer, TouchDrawerProps } from '../../shared/TouchDrawer';
import { joinStrings } from '../../../utils/string';
import './styles';

export const BalanceDrawer: FunctionComponent<TouchDrawerProps> = ({ children, ...props }) => {
  const showNotificationBar = false;

  return (
    <TouchDrawer
      {...props}
      backdropClassName={joinStrings([
        'balance-drawer__backdrop',
        showNotificationBar && 'balance-drawer__notice-setting',
      ])}
      location="top"
      animationDelay={0}
      showCloseBtn={false}
      backdropCanClose
      canDismiss
      transitionValue="transform 0.8s ease-in-out"
    >
      {children}
    </TouchDrawer>
  );
};
