import { createContext } from 'react';
import { Mode } from 'react-hook-form';
import { FieldErrorSummary, PasswordValidationState, ValidationMessage } from '../../../utils/form';

export type FormContextConfig = {
  errorSummary?: Record<string, FieldErrorSummary>;
  validationMode?: Mode;
  defaultValues: Record<string, unknown>;
  onFormControlBlur?: (
    fieldName: string,
    valid: boolean,
    validationMessages?: ValidationMessage[],
    passwordValidationState?: PasswordValidationState
  ) => void;
  onFormControlFocus?: (fieldName: string) => void;
};

export const FormContext = createContext<FormContextConfig>({} as FormContextConfig);
