import React, { ComponentPropsWithoutRef, FC } from 'react';
import { Heading, HeadingLevel } from '../Heading';
import './Card.scss';
import { joinStrings } from '../../../utils/string';

export type CardProps = {
  /**
   * Whether to display a drop shadow below the card. Defaults to true.
   */
  dropShadow?: boolean;
} & ComponentPropsWithoutRef<'div'>;

/**
 * A very basic Card component for presentational purposes.  Has zero props.
 *
 * Created for GameOverview component but moved to share for other potential use cases.
 */
export const Card: FC<CardProps> = ({ children, className, dropShadow = true, ...props }) => (
  <div {...props} className={joinStrings(['card', dropShadow && `card--with-drop-shadow`, className])}>
    {children}
  </div>
);

type CardHeaderProps = {
  /**
   * Can be used to display a basic Heading.  If not provided then the user
   * should provide custom heading markup via component children.
   */
  heading?: string;
  /**
   * Used to determine heading size if heading prop is provided.  Defaults to 3.
   */
  headingLevel?: HeadingLevel;
  children?: React.ReactNode;
};

/**
 * Should be used to display a heading within Card component.
 */
export const CardHeader: FC<CardHeaderProps> = ({ children, heading, headingLevel = 3 }) => (
  <header className="card__header">
    {heading && (
      <Heading noMargin level={headingLevel}>
        {heading}
      </Heading>
    )}
    {children}
  </header>
);

/**
 * Should be used to wrap content within the Card component
 */
export const CardContent: FC<{ children?: React.ReactNode }> = ({ children }) => (
  <section className="card__content">{children}</section>
);
