export interface PromotionsListProperties {
  title: string;
  promotionSectionType: string;
  cardsQuantity: number;
}

export type ItemsType = 'bonusItem' | 'bonusActiveItem';

export interface PromotionsListProps {
  title: string;
  promotionSectionType: string;
  cardsQuantity: number;
}

export enum ProductType {
  ALL = 'all',
  SPORT = 'sports',
  CASINO = 'casino',
}

export enum ProductName {
  ALL = 'All Promotions',
  SPORT = 'Sports',
  CASINO = 'Casino',
}

export enum PromotionTabsType {
  PROMOTIONS = 'promotions',
  ACTIVE = 'active',
  BONUS_DETAILS = 'bonus-details',
}

export enum PromotionTabsName {
  PROMOTIONS = 'Promotions',
  ACTIVE = 'Active',
  BONUS_DETAILS = 'Bonus Detail',
}
