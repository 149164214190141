import React, { ComponentPropsWithRef, forwardRef, ReactNode } from 'react';
import { joinStrings } from '../../../utils/string';
import './InputIconButton.scss';

export type InputIconButtonProps = {
  /**
   * Required. Icon to render in the button. Should utilise the Icon component.
   */
  icon: ReactNode;
  /**
   * Required. Used to describe the icon button's function to screen reader users.
   */
  'aria-label': string;
  /**
   * Required. Used to indicate whether the Input component that contains this icon button
   * is currently disabled. If it is, this button will also be set to disabled.
   */
  inputIsDisabled: boolean;
  /**
   * Optionally disable the button.
   *
   * This is provided by ComponentProps but it's added to note that it will
   * use the aria-disabled attribute rather than using HTMLButtonElement.disable
   * for better accessibility.
   * Another note is that this is separate from the inputIsDisabled prop. This is to provide
   * functionality for scenarios where this specific button is disabled but the parent Input component is not.
   */
  disabled?: boolean;
  /**
   * declare HTML type the button tag should use.
   */
  type: 'button' | 'submit' | 'reset';
} & ComponentPropsWithRef<'button'>;

/**
 * A component used to add an icon button into an input box.
 * Designed to work only with the [Input Component](/story/input--custom-icon-button)
 * via the `iconRight`/`iconLeft` props.
 *
 * ### Example
 *
 * ```tsx
 *   <Input
 *     name="search"
 *     value={value}
 *     label="Search"
 *     placeholder="Search..."
 *     onChange={(e) => setValue(e.target.value)}
 *     iconRight={
 *        <InputIconButtontype='button' onClick={onSearch} aria-label='Search button' icon={<Icon variant='Search' />
 *     } />
 *   />
 * ```
 */
export const InputIconButton = forwardRef<HTMLButtonElement, InputIconButtonProps>(
  ({ icon, inputIsDisabled, disabled = false, onClick, ...props }, ref) => {
    return (
      <button
        {...props}
        className={joinStrings(['input-icon-button', disabled && 'input-icon-button--disabled'])}
        {...(!disabled && !inputIsDisabled && onClick ? { onClick } : {})}
        aria-disabled={disabled || inputIsDisabled}
        ref={ref}
      >
        {icon}
      </button>
    );
  }
);

InputIconButton.displayName = 'InputIconButton';
