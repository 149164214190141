import React, { Children, FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDeviceInfo } from '../../../hooks/useDeviceInfo';
import { goToLink } from '../../../utils/links-helper/links-helper';
import { userBalance } from '../../Header/HeaderBalance/mocks';
import { Button } from '../../shared/Button';
import { Checkbox } from '../../shared/Checkbox';
import { Icon } from '../../shared/Icon';
import { Paragraph } from '../../shared/Paragraph';
import { PopUpHandler } from '../../shared/PopupHandler';
import { StepsType } from '../AccountClosure/consts';
import { TakeABreakRule, TakeABreakRuleProps } from '../TakeABreak/TakeABreak';
import { WITHDRAWAL_LIMIT_AMOUNT, WithdrawalCheck } from '../WithdrawalCheck';
import { WithdrawalCheckButtons } from '../WithdrawalCheck/WithdrawalCheckButtons';
import { SelfExcludeData, SelfExclusionForm } from './SelfExclusionForm';
import { selfExclusionFormID } from './consts';
import './SelfExclusion.scss';
import { useTranslations } from '../../../hooks/useTranslationsHelper';

export type SelfExclusionProps = {
  /**
   * Whether the popup is open or closed
   */
  isOpen: boolean;
  /**
   * Callback that's executed when the popup requests to close
   */
  onClose: () => void;
  /**
   * Callback that's executed when the user confirms self-exclusion
   */
  onSelfExclude: (duration: string, reason: string, durationLabel: string) => void;
  /**
   * Secondary button CTA url
   */
  ctaUrl: string;
};

export const SelfExclusion: FunctionComponent<SelfExclusionProps> = ({ ctaUrl, isOpen, onClose, onSelfExclude }) => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { isMobileDevice } = useDeviceInfo();
  const [savedFormData, setSavedFormData] = useState<SelfExcludeData>({
    duration: '',
    reason: '',
    durationLabel: '',
  });
  const [check, setCheck] = useState(false);
  const [step, setStep] = useState<StepsType>('form');

  function handleFormSubmit(formData: SelfExcludeData): void {
    setSavedFormData(formData);
    userBalance.withdrawal >= WITHDRAWAL_LIMIT_AMOUNT ? setStep('withdrawal-check') : setStep('confirmation');
  }

  const onWithdrawalCheckSecondaryBtnClick = (): void => {
    goToLink(ctaUrl, navigate, '_blank');
  };

  const rules: TakeABreakRuleProps[] = [
    { icon: 'LockAlt', text: t('self-exclusion.points.01') },
    { icon: 'BellSlash', text: t('self-exclusion.points.02') },
    { icon: 'Timeout', text: t('self-exclusion.points.03') },
    {
      icon: 'CustomerSupport',
      text: t('self-exclusion.points.04'),
    },
  ];

  useEffect(() => {
    if (!isOpen) {
      setSavedFormData({ duration: '', reason: '', durationLabel: '' });
    }
  }, [isOpen, step]);

  useEffect(() => {
    if (step === 'withdrawal-check' && userBalance.withdrawal < WITHDRAWAL_LIMIT_AMOUNT) {
      setStep('confirmation');
    }
  }, [step]);

  const setSelfContent: Array<ReactNode> = Children.toArray([
    <Paragraph size="sm">{t('self-exclusion.rules.intro')}</Paragraph>,
    <Paragraph size="sm">{t('self-exclusion.rules.01', { durationLabel: savedFormData.durationLabel })}</Paragraph>,
    <Paragraph size="sm">{t('self-exclusion.rules.02')}</Paragraph>,
    <Paragraph size="sm">{t('self-exclusion.rules.03')}</Paragraph>,
    <Paragraph size="sm">{t('self-exclusion.rules.04')}</Paragraph>,
  ]);

  return isOpen ? (
    <PopUpHandler
      isOpen={isOpen}
      onClose={(): void => {
        onClose();
        setTimeout(() => setStep('form'), 325);
      }}
      header={
        step === 'form'
          ? t('self-exclusion.assertion')
          : step === 'withdrawal-check'
            ? t('self-exclusion.pre-confirmation')
            : t('self-exclusion.confirmation')
      }
      modalBodyOverflow={false}
      drawerProps={{ backdropCanClose: true }}
      headingProps={{ headingLevel: 5 }}
      modalProps={{
        className: 'self-exclusion-modal',
        'aria-label': 'Self Exclusion',
      }}
      footer={
        <div className="u-action-footer">
          {step === 'withdrawal-check' ? (
            <WithdrawalCheckButtons
              className={
                isMobileDevice
                  ? 'self-exclusion__withdrawal-check-mobile--buttons'
                  : 'self-exclusion__withdrawal-check--buttons'
              }
              onSecondaryBtnClick={onWithdrawalCheckSecondaryBtnClick}
              onPrimaryBtnClick={(): void => setStep('confirmation')}
              secondaryBtnLabel={t('self-exclusion.button.secondary')}
              primaryBtnLabel={t('self-exclusion.button.primary')}
            />
          ) : (
            <Button
              iconPosition="left"
              icon={step === 'form' ? null : <Icon variant="ArrowLeft" />}
              variant="secondary-alt"
              type="button"
              onClick={(): void => {
                if (step === 'form') {
                  onClose();
                } else {
                  setStep('form');
                }
              }}
            >
              {step === 'form' ? t('buttons.cancel.uppercase') : t('buttons.back.uppercase')}
            </Button>
          )}
          {step === 'form' && (
            <Button
              variant="primary"
              type="submit"
              iconPosition="right"
              icon={<Icon variant="ArrowRight" />}
              form={selfExclusionFormID}
            >
              {t('buttons.next.uppercase')}
            </Button>
          )}
          {step === 'confirmation' && (
            <Button
              disabled={!check}
              type="button"
              variant="primary"
              onClick={(): void => {
                if (savedFormData.duration !== '') {
                  onSelfExclude(savedFormData.duration, savedFormData.reason, savedFormData.durationLabel);
                }
              }}
            >
              {t('self-exclusion.button.close-account')}
            </Button>
          )}
        </div>
      }
    >
      {step === 'form' && (
        <>
          <Paragraph size="sm">{t('self-exclusion.last-caution')}</Paragraph>
          <ul className="self-exclusion__rules">
            {rules.map((rule) => (
              <TakeABreakRule {...rule} key={rule.icon} />
            ))}
          </ul>
          <Paragraph className="self-exclusion__paragraph-1">{t('self-exclusion.select-length.text')}</Paragraph>
          <Paragraph className="self-exclusion__paragraph-2" size="sm">
            {t('self-exclusion.immediately-start.text')}
          </Paragraph>
          <SelfExclusionForm onFormSubmit={handleFormSubmit} defaultValues={savedFormData} />
        </>
      )}
      {step === 'withdrawal-check' && (
        <WithdrawalCheck linkText="withdrawal cashier" heading={t('self-exclusion.withdraw-message')}>
          <Paragraph>{t('self-exclusion.help-message')}</Paragraph>
        </WithdrawalCheck>
      )}
      {step === 'confirmation' && (
        <>
          <Paragraph size="sm" noMargin className="self-exclusion__confirmation-paragraph-2">
            {setSelfContent}
          </Paragraph>
          <Checkbox
            checked={check}
            onClick={(): void => {
              setCheck(!check);
            }}
            variant="checkbox"
            label={t('self-exclusion.agreement.text')}
          />
        </>
      )}
    </PopUpHandler>
  ) : null;
};
