import React, { Children, FunctionComponent } from 'react';
import { ParsedPromo } from '../types';
import { Balance } from '../../../shared/Balance';
import { Heading } from '../../../shared/Heading';
import { BonusIcon } from '../../../shared/SVG/Icons';
import { useUserBalance } from '../../../../hooks/useUserBalance';
import { PromotionBonusBalanceItem } from '../PromotionBonusDetails/PromotionBonusBalanceItem';
import './styles';

type ActivePromotionsListProps = {
  promotions: ParsedPromo[];
  isFetching: boolean;
  onCloseWindow?: () => void;
};

export const ActivePromotionsList: FunctionComponent<ActivePromotionsListProps> = ({
  promotions,
  isFetching,
  onCloseWindow,
}) => {
  const { isBalanceLoading, userBalance } = useUserBalance();

  return (
    <div className="active-promotions-list">
      <Heading level={5} className="active-promotions-list__heading">
        Your Bonuses
      </Heading>
      <div className="active-promotions-list__card">
        <div className="active-promotions-list__card-header">
          <span className="active-promotions-list__card-header--icon">
            <BonusIcon />
          </span>
          <span className="active-promotions-list__card-header--text">
            Total Bonus Balance
            {Boolean(promotions.length) && (
              <span className="active-promotions-list__card-header--subtext">{promotions.length} bonus wallets</span>
            )}
          </span>
          <Balance
            isLoading={isBalanceLoading}
            balance={userBalance.bonusBalance}
            className="active-promotions-list__card-header--amount"
          />
        </div>

        {Boolean(promotions.length) && (
          <div className="active-promotions-list__card-body">
            {Children.toArray(
              promotions.map((promotion, index) => {
                return (
                  <PromotionBonusBalanceItem
                    key={promotion.id}
                    promotion={promotion}
                    index={index}
                    isFetching={isFetching}
                    onCloseWindow={onCloseWindow}
                  />
                );
              })
            )}
          </div>
        )}
      </div>
    </div>
  );
};
