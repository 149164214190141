import { FunctionComponent, useCallback } from 'react';
import { joinStrings } from '../../../../utils/string';
import { Button } from '../../../shared/Button';
import { Icon } from '../../../shared/Icon';
import { CookiesPreferencesMode } from '../types';
import { useCookiePreferencesContext } from '../CookiePreferences.context';
import './styles';

export type CookiesPreferenceFooterProps = {
  /**
   * Whether is component opened
   */
  isOpened?: boolean;
} & CookiesPreferencesMode;

export const CookiesPreferencesFooter: FunctionComponent<CookiesPreferenceFooterProps> = ({
  mode = 'prompt',
  isOpened = true,
}) => {
  const {
    isAccepting,
    isTargeted,
    isEssential,
    isPerformance,
    isFunctional,
    isRejecting,
    isSubmitting,
    isDetailPageDisplayed,
    setIsDetailPageDisplayed,
    onConfirm,
    onReject,
  } = useCookiePreferencesContext();

  const onBackBtnClick = useCallback((): void => {
    setIsDetailPageDisplayed(false);
  }, [setIsDetailPageDisplayed]);

  return isOpened ? (
    <div
      className={joinStrings([
        'cookie-preference-footer__footer-btn',
        isDetailPageDisplayed && mode !== 'prompt' && 'cookie-preference-footer__footer-btn--hidden',
        isDetailPageDisplayed && 'cookie-preference-footer__footer-btn--space-between',
      ])}
    >
      {isDetailPageDisplayed && mode === 'prompt' && (
        <Button
          size="large"
          type="button"
          variant="text"
          iconPosition="left"
          icon={<Icon variant="ArrowLeft" />}
          onClick={onBackBtnClick}
        >
          Back
        </Button>
      )}
      {!isDetailPageDisplayed && (
        <>
          <Button
            size="large"
            type="button"
            variant="secondary-alt"
            onClick={onReject}
            isSubmitting={isRejecting}
            disabled={isSubmitting || isAccepting}
          >
            REJECT ALL
          </Button>
          <Button
            size="large"
            type="button"
            variant="primary"
            disabled={!(isFunctional || isPerformance || isTargeted || isEssential) || isRejecting || isAccepting}
            onClick={onConfirm}
            isSubmitting={isSubmitting}
          >
            CONFIRM SELECTION
          </Button>
        </>
      )}
    </div>
  ) : null;
};
