import React, { FC } from 'react';
import { Paragraph } from '../../../shared/Paragraph';

interface WelcomeBonusFixedProps {
  bonusAmount?: number;
  minimumDeposit?: string;
}

export const WelcomeBonusFixed: FC<WelcomeBonusFixedProps> = ({ bonusAmount, minimumDeposit = 10 }) => {
  return (
    <>
      <div className="welcome-bonus__step">
        <span className="welcome-bonus__circle">2</span>
        <Paragraph size="md" noMargin>
          {`Stake ${minimumDeposit} or more`}
        </Paragraph>
      </div>
      <div className="welcome-bonus__step">
        <span className="welcome-bonus__circle">3</span>
        <Paragraph size="md" noMargin>
          {`Receive ${bonusAmount} bonus to play on`}
        </Paragraph>
      </div>
    </>
  );
};
