import React, { Fragment, FunctionComponent, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { Link, useResolvedPath } from 'react-router-dom';
import { xTenantId } from '../../../../../consts';
import { ContactPreferenceData, FlattenedProfileData, UserProfileResponse } from '../../../../../hooks/registration';
import { useUserProfileUpdateMutation } from '../../../../../libs/graphql/baseAppAPI/mutations/__generated__/user-profile-update.mutation.generated';
import { useUserContext } from '../../../../../providers/UserProvider/UserProvider';
import { UserProfilesUpdateInput } from '../../../../../types.__generated__';
import { PhoneNumber } from '../../../../../utils/phone-number';
import { MarketingData, MarketingItemValue } from '../../../../Registration/types';
import { Button } from '../../../../shared/Button';
import { ConfirmationModal } from '../../../../shared/ConfirmationModal';
import { FormField } from '../../../../shared/Form';
import { GridColumnAmount } from '../../../../shared/Grid';
import { Heading } from '../../../../shared/Heading';
import { Icon } from '../../../../shared/Icon';
import { InnerHTML } from '../../../../shared/InnerHTML';
import { Modal, ModalBody, ModalHeader } from '../../../../shared/Modal';
import { Notification } from '../../../../shared/Notification';
import { countryCodeOptions } from '../../../../shared/TelephoneInput/countryCodes';
import { AccountDetailsForm, AccountDetailsSummary, AccountDetailsSummaryComponent } from '../../../AccountDetails';
import { AccountDetailsFormComponent, AccountFieldMap } from '../../../types';
import { GetAccountFieldConfig } from '../../config/config';
import './AccountDetails.scss';

const flattenProfileDetails = (
  personalDetails: UserProfileResponse,
  marketing?: MarketingData,
  contactPreference?: ContactPreferenceData
): Partial<FlattenedProfileData> => {
  return {
    ...personalDetails,
    ...marketing,
    ...contactPreference,
    mobileNumber: `${personalDetails.mobileNumber?.prefix} ${personalDetails.mobileNumber?.number}`,
    addrStreetAddress: `${personalDetails.address?.streetAddress}, ${personalDetails.address?.addressLocality}, ${personalDetails.address?.postalCode}, ${personalDetails.address?.addressCountry}`,
    toggleAllMarketing: false,
    toggleAllContact: false,
  } as FlattenedProfileData;
};

export type ProfileField = {
  name: keyof FlattenedProfileData;
  optional?: boolean;
  columns: GridColumnAmount;
  overviewColumns: GridColumnAmount;
  disabled?: boolean;
  hidden?: boolean | ((data: Partial<FlattenedProfileData>) => boolean);
};

export type ProfileFieldSet = {
  label: string;
  id: string;
  name?: string;
  labelSrOnly?: boolean;
  editDescription?: React.ReactNode;
  hidden?: boolean;
  fields: ProfileField[];
};

type ProfileEditMode = 'inline' | 'modal' | 'none';
export type ProfileFieldSets = ProfileFieldSet[];

export type AccountDetailsEditModalProps = {
  /**
   * Field set configuration.  Used to determine which account details to display both in
   * summary & edit view.
   */
  fieldSets: ProfileFieldSets;
  /**
   * Form field configurations which can be used to dynamically generate the form
   */
  formFields: Record<string, FormField<FlattenedProfileData>>;
  /**
   * Default form state
   */
  defaultState: Partial<FlattenedProfileData>;
  /**
   * Whether the editing dialog should be displayed
   */
  isOpen: boolean;
  /**
   * Can be used to optionally substitute edit form component.
   */
  EditFormComponent: AccountDetailsFormComponent;
  /**
   * Base id for modal form elements
   */
  id: string;
  /**
   * Form heading text
   */
  heading: string;
  /**
   * Optional text to be displayed above the form
   */
  editIntroText?: string;
  /**
   * According this value, the submit button should show a loading spinner.
   */
  isSubmitting?: boolean;
  /**
   * Should be invoked when the dialog is to be closed without saving
   */
  onClose?: () => void;
  /**
   * Should be invoked when the form data is to be saved and dialog closed
   */
  onSave?: (data: Partial<FlattenedProfileData>) => Promise<void>;
};

export const AccountDetailsEditModal: FunctionComponent<AccountDetailsEditModalProps> = ({
  EditFormComponent,
  isOpen,
  id,
  heading,
  editIntroText,
  formFields,
  fieldSets,
  defaultState,
  onClose,
  onSave,
}) => {
  const editDialogHeaderId = `${id}-edit-dialog`;

  return (
    <Modal size="large" canDismiss isOpen={isOpen} aria-labelledby={editDialogHeaderId} onRequestClose={onClose}>
      <ModalHeader id={editDialogHeaderId} heading={heading} />
      <ModalBody withOverflow={false}>
        <EditFormComponent
          id={`${id}-form`}
          fieldSets={fieldSets}
          formFields={formFields}
          defaultState={defaultState}
          introText={editIntroText}
          onSubmit={onSave}
          onCancel={onClose}
        />
      </ModalBody>
    </Modal>
  );
};

type AccountDetailsPanelProps = {
  /**
   * Optional title
   */
  title?: string;
  /**
   * Optional intro text
   */
  introText?: string;
  /**
   * Optionally substitute the component to render the account info
   */
  SummaryComponent: AccountDetailsSummaryComponent<FlattenedProfileData>;
  /**
   * Used to determine whether to disable the edit button.
   */
  editMode: ProfileEditMode;
  /**
   * Whether form is being displayed.  Used in combination with editMode to show or hide edit button, details etc.
   */
  formDisplayed: boolean;
  /**
   * Whether profile details are being loaded/refreshed. Used to hide details while loading for the moment
   */
  fetchingProfile?: boolean;
  /**
   * FormField configurations.  Used to determine how to render account values.
   */
  fields: AccountFieldMap;
  /**
   * Configuration to determine which fields to render.
   */
  fieldSets: ProfileFieldSets;
  /**
   * Data to be displayed.
   */
  flattenedProfileData: Partial<FlattenedProfileData>;
  /**
   * Invoked when used clicks edit button if editing is enabled.
   */
  editData?: () => void;

  isLink?: boolean;

  linkTo?: string;
};

const AccountDetailsPanelButton: FunctionComponent<Pick<AccountDetailsPanelProps, 'editData'>> = ({ editData }) => {
  return (
    <Button type="button" variant="text" onClick={editData} aria-label="Edit">
      <span className="account-details__button-text">Edit</span>
      <Icon variant="MdEdit" />
    </Button>
  );
};

export const AccountDetailsPanel: FunctionComponent<AccountDetailsPanelProps> = ({
  formDisplayed,
  fetchingProfile,
  fields,
  SummaryComponent,
  title,
  introText,
  fieldSets,
  editMode,
  flattenedProfileData,
  editData,
  isLink = false,
  linkTo = '',
}) => {
  const url = useResolvedPath('').pathname;

  return (
    <Fragment>
      {editMode !== 'none' && (
        <div className="account-details__actions">
          {isLink ? (
            <Link to={`${url}/${linkTo}`}>
              <AccountDetailsPanelButton editData={editData} />
            </Link>
          ) : (
            <AccountDetailsPanelButton editData={editData} />
          )}
        </div>
      )}
      {(!formDisplayed || editMode !== 'inline') && !fetchingProfile && (
        <div>
          {title && <Heading level={4}>{title}</Heading>}
          {introText && <InnerHTML text={introText} />}
          <SummaryComponent fieldSets={fieldSets} formFields={fields} formState={flattenedProfileData} />
        </div>
      )}
    </Fragment>
  );
};

export type AccountDetailsProps = {
  /**
   * Id for container and also used for form id etc.
   */
  id: string;
  /**
   * Field set configuration.  Used to determine which account details to display both in
   * summary & edit view.
   */
  fieldSets: ProfileFieldSets;
  /**
   * Title for account details
   */
  title?: string;
  /**
   * Title when editing the selected account details
   */
  editTitle?: string;
  /**
   * Optional intro text which will be rendered before account detail summary
   */
  introText?: string;
  /**
   * Whether to allow editing in dialog, inline or not at all.
   */
  editMode: ProfileEditMode;
  /**
   * Optional intro text to be rendered before form fields when editing.
   */
  editIntroText?: string;
  /**
   * Optional text to be placed before submission button when editing.
   */
  preSubmitText?: string;
  /**
   * Optional submit button text.  Will default to locale key if not provided.
   */
  submitButtonText?: string;
  /**
   * Whether to display a confirmation prompt before showing edit form.
   */
  confirmationPrompt?: boolean;
  /**
   * Confirmation prompt title. Optional. Will default to confirmation prompt component default locale.
   */
  confirmationPromptTitle?: string;
  /**
   * Confirmation prompt body text. Optional. Will default to confirmation prompt component default locale.
   */
  confirmationPromptText?: string;
  /**
   * Confirmation prompt confirmation button label text. Optional. Will default to confirmation prompt component default locale.
   */
  confirmationPromptConfirm?: string;
  /**
   * Whether to display the default error notification.  Defaults to false.
   */
  showDefaultError?: boolean;
  /**
   * Can be used to optionally substitute edit form component.
   */
  EditFormComponent?: AccountDetailsFormComponent;
  /**
   * Can be used to optionally substitute an editor dialog component.
   */
  EditModalComponent?: React.ComponentType<AccountDetailsEditModalProps>;
  /**
   * Can be used to optionally substitute account detail summary component.
   */
  SummaryComponent?: AccountDetailsSummaryComponent<FlattenedProfileData>;
  /**
   * Will be called when the account details update has been persisted
   */
  onUpdate?: () => void;
  /**
   * Will be called when the account details update has failed to persist
   */
  onError?: (e: unknown) => void;
  /**
   * Should be invoked when the edit icon is pressed
   */
  onEdit?: () => void;

  forceDisplayEditModal?: boolean;

  externalHandler?: () => void;

  isLink?: boolean;

  linkTo?: string;
  isMarketingSettings?: boolean;
};

/**
 * Render an overview of selected account details.
 */
export const AccountDetails: FunctionComponent<AccountDetailsProps> = ({
  fieldSets,
  editMode,
  title,
  editTitle,
  introText,
  editIntroText,
  id,
  EditFormComponent = AccountDetailsForm,
  SummaryComponent = AccountDetailsSummary,
  EditModalComponent = AccountDetailsEditModal,
  confirmationPrompt,
  confirmationPromptConfirm,
  confirmationPromptText,
  confirmationPromptTitle,
  showDefaultError = false,
  onUpdate,
  onError,
  onEdit,
  forceDisplayEditModal = false,
  externalHandler,
  isMarketingSettings,
  isLink,
  linkTo = '',
}) => {
  const auth = useAuth();
  const { user, consents, userProfileId, fetchUserProfile } = useUserContext();
  const playerId = userProfileId || (auth.user?.profile?.user_profile_id as string);
  const fields = GetAccountFieldConfig();

  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const [displayForm, setDisplayForm] = useState(false);

  const [userProfileUpdateMutation, { loading }] = useUserProfileUpdateMutation({
    onCompleted: async (data) => {
      if (!data) return;

      await fetchUserProfile();
      setDisplayForm(false);
      onUpdate?.();
    },
    onError: (error) => {
      setDisplayForm(false);
      handleError(error);
    },
  });

  const contactPreferenceConfig: ContactPreferenceData = {
    SGContactPrefPhone: false,
    SGContactPrefEmail: false,
    SGContactPrefLiveChat: false,
    SGContactPrefWhatsapp: false,
  };

  const marketingConfig: MarketingData = {
    optInEmail: consents?.emailAllowed === MarketingItemValue.Accepted,
    optInTelephone: consents?.callAllowed === MarketingItemValue.Accepted,
    optInSms: consents?.smsAllowed === MarketingItemValue.Accepted,
  };

  if (!user) return null;

  const flattenedProfileData = flattenProfileDetails(user, marketingConfig, contactPreferenceConfig);

  const editData = (): void => {
    onEdit && onEdit();

    if (confirmationPrompt) {
      setDisplayConfirmation(true);
    } else {
      setDisplayForm(true);
    }
  };

  const editTitleText = editTitle || 'Edit Details';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleError = (error: any): void => {
    console.error('Save user details error: ', error?.message);
    onError?.(error?.message);
  };

  const updateDetails = async (updateInfo: UserProfilesUpdateInput): Promise<void> => {
    await userProfileUpdateMutation({
      variables: {
        userProfileId: playerId,
        xTenantId,
        userProfileUpdateInfo: updateInfo,
      },
    });
  };

  /**
   * Pick out updated props and attempt update request
   * @param details Updated profile details
   */
  const saveDetails = async (details: Partial<FlattenedProfileData>): Promise<void> => {
    try {
      if (isMarketingSettings) {
        await updateDetails({
          isSMSAllowed: details?.optInSms,
          isEmailAllowed: details?.optInEmail,
          isCallAllowed: details?.optInTelephone,
        });

        return;
      }

      const phone = new PhoneNumber(details.mobileNumber || '', countryCodeOptions);
      const number = phone.getPurePhoneNumber();
      const prefix = phone.getCountryCodeValue();

      updateDetails({
        user: {
          mobileNumber: {
            prefix,
            number,
          },
        },
      });
    } catch (error) {
      handleError(error);
    }

    setDisplayForm(false);
  };

  return (
    <div id={id} className="account-details">
      {showDefaultError && (
        <Notification type="error">
          Update failed. If the issue persists - please contact the customer support team.
        </Notification>
      )}

      {forceDisplayEditModal && (
        <EditModalComponent
          {...{ id, fieldSets, EditFormComponent, editIntroText }}
          formFields={fields}
          defaultState={flattenedProfileData}
          heading={editTitleText}
          isOpen={forceDisplayEditModal}
          onClose={(): void => {
            setDisplayForm(false);
            externalHandler && externalHandler();
          }}
          onSave={saveDetails}
          isSubmitting={loading}
        />
      )}
      {!forceDisplayEditModal && (
        <AccountDetailsPanel
          {...{
            SummaryComponent,
            fieldSets,
            fields,
            title,
            introText,
            editData,
            editMode,
            formDisplayed: displayForm,
            fetchingProfile: false,
            flattenedProfileData,
            isLink,
            linkTo,
          }}
        />
      )}
      {displayForm && editMode === 'inline' && (
        <EditFormComponent
          id={`${id}-form`}
          title={editTitleText}
          introText={editIntroText}
          fieldSets={fieldSets}
          formFields={fields}
          defaultState={flattenedProfileData}
          onSubmit={saveDetails}
          onCancel={(): void => setDisplayForm(false)}
        />
      )}
      <EditModalComponent
        {...{ id, fieldSets, EditFormComponent, editIntroText }}
        formFields={fields}
        defaultState={flattenedProfileData}
        heading={editTitleText}
        isOpen={displayForm && editMode === 'modal'}
        onClose={(): void => {
          setDisplayForm(false);
          externalHandler && externalHandler();
        }}
        onSave={saveDetails}
        isSubmitting={loading}
      />
      {confirmationPrompt && (
        <ConfirmationModal
          heading={confirmationPromptTitle}
          bodyText={confirmationPromptText}
          confirmLabel={confirmationPromptConfirm}
          isOpen={displayConfirmation}
          onCancel={(): void => setDisplayConfirmation(false)}
          onConfirm={(): void => {
            setDisplayConfirmation(false);
            setDisplayForm(true);
          }}
        />
      )}
    </div>
  );
};
