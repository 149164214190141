import React, { FunctionComponent } from 'react';
import { Pill } from '../../shared/Pill';
import { Typography } from '../../shared/Typography';
import { GameInfoCard } from '../GameInfo/GameInfoCard';
import './styles';

export type GameTagItem = {
  key?: string;
  text: string;
  value: string;
};

export type GameTagsCardProps = {
  heading: string;
  tags: GameTagItem[];
};

export const GameTagsCard: FunctionComponent<GameTagsCardProps> = ({ heading, tags }) => (
  <GameInfoCard className="game-tags-card" heading={heading}>
    <dl className="game-tags-card__tags">
      {tags.map(({ key, text, value }) => {
        return (
          <Pill className="game-tags-card__pill" key={key || text} size="large" variant="default">
            <Typography tag="dt" className="game-tags-card__label" size="sm">
              {text}
            </Typography>
            <Typography tag="dd" className="game-tags-card__value" size="sm">
              {value}
            </Typography>
          </Pill>
        );
      })}
    </dl>
  </GameInfoCard>
);
