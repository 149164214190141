import { RootState } from '../store';
import { IDisabledGamesData } from '../../models/game-session.model';
import { ComplianceGameSession } from '../../components/Games/games.model';

export const selectGameLaunchUrl = (state: RootState): string => state.game?.launchUrl;
export const selectGameLaunchTime = (state: RootState): string | undefined => state.game?.gameLaunchTime;
export const selectDisabledGamesData = (state: RootState): IDisabledGamesData => state.game?.disabledGamesData;
/**
 * Returns the session data for the current game (using the game state gameData)
 */
export const currentGameSession =
  () =>
  (state: RootState): ComplianceGameSession | undefined => {
    const gameData = state.game?.gameData;

    // TODO: update this logic on game implementation
    return state.game.gameSessions?.find?.(
      (session) => !session.sessionEndedAt && session.categories.includes(String(gameData?.gameId))
    );
  };
