import React, { ComponentPropsWithRef, FunctionComponent } from 'react';
import { joinStrings } from '../../../../utils/string';
import { useClickableElement } from '../../../../hooks/presentation-logic/useClickableElement';
import './AccountTile.scss';

export type AccountTileProps = {
  /**
   * Optional custom class name for the tile.
   */
  className?: string;
  /**
   * Whether the tile should have a box shadow, defaults to false.
   */
  withBoxShadow?: boolean;
  /**
   * Optional click callback. Will make the tile an accessible div button, and
   * handle running the callback on enter & space bar press to mimic regular buttons.
   */
  onClick?: () => void;
  /**
   * Optionally remove the padding. Defaults to false.
   */
  noPadding?: boolean;
  children?: React.ReactNode;
} & Pick<ComponentPropsWithRef<'div'>, 'style'>;

/**
 * Reusable component for the white cards used throughout My Account.
 * Can be made clickable if the onClick prop is provided.
 */
export const AccountTile: FunctionComponent<AccountTileProps> = ({
  children,
  className,
  withBoxShadow = false,
  onClick,
  noPadding = false,
  ...divProps
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const clickableAttributes = onClick ? useClickableElement(onClick) : {};

  return (
    <div
      {...divProps}
      className={joinStrings([
        'account-tile',
        withBoxShadow && 'account-tile--with-box-shadow',
        onClick && 'account-tile--clickable',
        noPadding && 'account-tile--no-padding',
        className,
      ])}
      {...clickableAttributes}
    >
      {children}
    </div>
  );
};
