/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { TelephoneInput, TelephoneInputProps } from '../TelephoneInput';
import { ValidationMessage } from '../../../../utils/form';
import { delimiter } from '../../../../utils/phone-number';
import { verifyPhoneNumber } from '../../../../utils/numverify/numverify';
import { useFetch } from '../../../../hooks/fetch';
import { ValidationState } from '../../../../types';

export type TelephoneWithVerificationProps = TelephoneInputProps & {
  useVerification?: boolean;
};

/**
 * Wrapper around the Telephone component to provide async Numverify validation
 */
export const TelephoneWithVerification: FunctionComponent<TelephoneWithVerificationProps> = ({
  useVerification,
  ...props
}) => {
  if (!useVerification) return <TelephoneInput {...props} />;

  const { maskedInputProps, value = '' } = props;
  const [selectValue, inputValue = ''] = value.split(delimiter);
  const [phoneNumber, setPhoneNumber] = useState(inputValue);
  const [validationState, setValidationState] = useState<ValidationState>();
  const [validationMessages, setValidationMessages] = useState<ValidationMessage[]>();
  const hookFormContext = useFormContext();
  const {
    fetchData: runNumverify,
    isFetching: isValidating,
    data: numverifyResponse,
    isDataInitialised: hasNumverifyResponse,
  } = useFetch({
    dataRequest: () => verifyPhoneNumber(`${selectValue}${phoneNumber}`),
  });

  const showDefaultValidation = (): void => {
    setValidationState(maskedInputProps.validationState);
    setValidationMessages(maskedInputProps.validationMessages);
  };

  const performNumverifyCheck = (): void => {
    setValidationState(undefined);
    setValidationMessages([]);
    runNumverify();
  };

  // If the validation message is of type `async` then show it as it's from our async call
  useEffect(() => {
    if (maskedInputProps.validationMessages?.length) {
      const isAsyncError = maskedInputProps.validationMessages.filter(({ id }) => id.includes('async'));

      if (isAsyncError.length) showDefaultValidation();
    }
  }, [maskedInputProps.validationMessages]);

  // If the country code changes and input is long enough, run lookup
  useEffect(() => {
    if (phoneNumber.length > 3) performNumverifyCheck();
  }, [selectValue]);

  useEffect(() => {
    // Only run phone lookup if all other validation is met and there are more than 3 digits
    if (!maskedInputProps.validationMessages && phoneNumber.length > 3) {
      performNumverifyCheck();
    } else {
      showDefaultValidation();
    }
  }, [phoneNumber]);

  // Handle the response
  useEffect(() => {
    if (hasNumverifyResponse) {
      if (numverifyResponse?.valid) {
        setValidationState('success');
        hookFormContext.clearErrors('tel-async');
        hookFormContext.clearErrors('telephone');
      } else {
        const errorObject = {
          type: 'async',
          message: 'Invalid phone number.',
        };

        hookFormContext.setError('tel-async', errorObject);
        hookFormContext.setError('telephone', errorObject);
      }
    }
  }, [numverifyResponse]);

  // Prevent form submission via the enter key if the Numverify check hasn't ran on the updated value
  // `value` updates on change so if it's changing the user is updating the input
  // and hasn't performed the Numverify check yet
  // so we set an error on the hidden input.
  // The error will be cleared (or updated) once the user has blurred out of the input and the check has ran
  useEffect(() => {
    if (inputValue) {
      hookFormContext.setError('tel-async', {});
    } else {
      hookFormContext.clearErrors('tel-async');
    }
  }, [value]);

  return (
    // Only update the phone number on blur
    // Country code is handled in a useEffect as the dropdowns onBlur fires at odd times with using Downshift
    // Hidden input required for manual/async validation as any errors set with setErrors are cleared on submit
    // (https://github.com/react-hook-form/react-hook-form/issues/3079)
    <div onBlur={(): void => setPhoneNumber(inputValue)}>
      <TelephoneInput
        {...props}
        maskedInputProps={{
          ...maskedInputProps,
          validationState,
          validationMessages,
        }}
        isValidating={isValidating}
      />
      <input name="tel-async" hidden />
    </div>
  );
};
