import { ReactNode } from 'react';
import { Mode, RegisterOptions as ValidationRules, UnpackNestedValue } from 'react-hook-form';
import { CheckboxProps } from '../Checkbox';
import { InputProps } from '../Input';
import { RadioGroupProps, RadioOption } from '../RadioGroup';
import { FieldErrorSummary, PasswordValidationState, ValidationMessage } from '../../../utils/form';
import { FormControlSwitchProps } from '../FormControlSwitch';
import { FlattenedProfileData } from '../../../hooks/registration';
import { MaskedInputProps } from '../MaskedInput';
import { PasswordInputProps, PasswordStrength } from '../PasswordInput';
import { ValidationRequirements } from '../../Registration/types';
import { SelectOption, SelectProps } from '../Select';
import { TelephoneInputProps } from '../TelephoneInput';
import { TelephoneWithVerificationProps } from '../TelephoneInput/TelephoneWithVerification/TelephoneWithVerification';
import { ValidationState } from '../../../types';

export type InputValidationProps = {
  /**
   * Optional ValidationMessage array containing any validation messages to be displayed underneath the input box.
   * The messages will be coloured to match the validationState (e.g. red for error) if one is supplied
   */
  validationMessages?: ValidationMessage[];
  /**
   * The input control and any validation feedback will be styled
   * to match the validationState (e.g. red for error) if one is supplied.
   */
  validationState?: ValidationState;
};

export enum FormFieldType {
  TextInput = 'Input',
  NoWhitespaceInput = 'NoWhitespaceInput',
  NumberInput = 'NumberInput',
  MaskedInput = 'MaskedInput',
  CurrencyInput = 'CurrencyInput',
  Checkbox = 'Checkbox',
  DateOfBirthInput = 'DateOfBirthInput',
  PasswordInput = 'PasswordInput',
  RadioGroup = 'RadioGroup',
  Switch = 'Switch',
  Select = 'Select',
  TelephoneInput = 'TelephoneInput',
}

type FormFieldBase<D extends Record<string, unknown>> = {
  type: FormFieldType;
  id?: string;
  name: keyof D;
  label: ReactNode | string;
  optional?: boolean;
  placeholder?: string;
  disabled?: boolean;
  formControlSwitchProps?: Omit<FormControlSwitchProps, 'children'>;
  hidden?: boolean | ((data: Partial<FlattenedProfileData>) => boolean);
  onFormControlBlur?: (
    fieldName: string,
    valid: boolean,
    validationMessages?: ValidationMessage[],
    passwordValidationState?: PasswordValidationState
  ) => void;
  onFormControlFocus?: (fieldName: string) => void;
  updateFormData?: (data: Partial<FlattenedProfileData>) => Partial<FlattenedProfileData>;
  rules?:
    | ValidationRules
    | ((
        watch: (names: string) => UnpackNestedValue<D>,
        triggerValidation: (fieldName: string) => void,
        defaultValue: unknown
      ) => ValidationRules);
};

export type InputFormField<D extends Record<string, unknown>> = {
  type: FormFieldType.TextInput;
  props?: Partial<InputProps>;
} & FormFieldBase<D>;

export type NoWhitespaceInputFormField<D extends Record<string, unknown>> = {
  type: FormFieldType.NoWhitespaceInput;
  props?: Partial<InputProps>;
} & FormFieldBase<D>;

export type NumberInputFormField<D extends Record<string, unknown>> = {
  type: FormFieldType.NumberInput;
  props?: Partial<InputProps>;
} & FormFieldBase<D>;

export type CurrencyInputFormField<D extends Record<string, unknown>> = {
  type: FormFieldType.CurrencyInput;
  props?: Partial<InputProps>;
} & FormFieldBase<D>;

export type MaskedInputFormField<D extends Record<string, unknown>> = {
  type: FormFieldType.MaskedInput;
  props?: Partial<MaskedInputProps>;
  mask: string;
} & FormFieldBase<D>;

export type PasswordInputFormField<D extends Record<string, unknown>> = {
  type: FormFieldType.PasswordInput;
  props?: Partial<PasswordInputProps>;
  scorePassword?: (password: string) => PasswordStrength;
  customRequirements?: ValidationRequirements;
} & FormFieldBase<D>;

export type CheckboxFormField<D extends Record<string, unknown>> = {
  type: FormFieldType.Checkbox;
  props?: Partial<CheckboxProps>;
} & FormFieldBase<D>;

export type SwitchFormField<D extends Record<string, unknown>> = {
  type: FormFieldType.Switch;
  props?: Partial<CheckboxProps>;
} & FormFieldBase<D>;

export type SelectFormField<D extends Record<string, unknown>> = {
  type: FormFieldType.Select;
  options: SelectOption[];
  props?: Partial<SelectProps>;
  defaultValue?: string;
} & FormFieldBase<D>;

export type DateOfBirthInputFormField<D extends Record<string, unknown>> = {
  type: FormFieldType.DateOfBirthInput;
  dateFormat: string;
  dateMask: string;
  props?: Partial<MaskedInputProps>;
} & FormFieldBase<D>;

export type TelephoneInputFormField<D extends Record<string, unknown>> = {
  type: FormFieldType.TelephoneInput;
  props: TelephoneInputProps | TelephoneWithVerificationProps;
} & FormFieldBase<D>;

export type RadioGroupFormField<D extends Record<string, unknown>> = {
  type: FormFieldType.RadioGroup;
  options: RadioOption[];
  props?: Partial<RadioGroupProps>;
} & FormFieldBase<D>;

export type FormField<D extends Record<string, unknown>> =
  | InputFormField<D>
  | NoWhitespaceInputFormField<D>
  | CheckboxFormField<D>
  | DateOfBirthInputFormField<D>
  | MaskedInputFormField<D>
  | PasswordInputFormField<D>
  | NumberInputFormField<D>
  | RadioGroupFormField<D>
  | SwitchFormField<D>
  | SelectFormField<D>
  | TelephoneInputFormField<D>
  | CurrencyInputFormField<D>;

export type FormControlInputGetterArgs<D extends Record<string, unknown>> = {
  field: FormField<D>;
  successStateEnabled: boolean;
  errorSummary?: Record<string, FieldErrorSummary>;
  formValidationMode?: Mode;
};
export type FormControlInputGetter<D extends Record<string, unknown>> = (
  args: FormControlInputGetterArgs<D>
) => JSX.Element;

/**
 * Props for FormControl component and custom implementations.
 */
export type FormControlComponentProps<D extends Record<string, unknown>> = FormField<D> & {
  getInput?: FormControlInputGetter<D>;
};

export type FormControlComponent<D extends Record<string, unknown>> = React.ComponentType<FormControlComponentProps<D>>;
