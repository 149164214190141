/* eslint-disable max-len */
import React, { FC } from 'react';
import { Header } from '../../components/Header';
import { NavComponent } from '../../components/Navigation';
import { Footer } from '../../components/Footer';
import { NavigationLinkPropsExtended } from '../../components/Navigation/Navigation';
import { ProductCategoryLink, Promotions } from '../../components/Navigation/types';
import './NavigationTemplate.scss';

interface Props {
  children: JSX.Element | JSX.Element[];
  navLinks?: NavigationLinkPropsExtended[];
  categories?: ProductCategoryLink[];
  promotions?: Promotions;
  isNavHidden?: boolean;
  paddingDisabled?: boolean;
  /**
   * Optional. Used to replace background color in the content
   * ex. Sportsbook component on the home page
   */
  isDarkBackground?: boolean;
  isSportsBook?: boolean;
}

export const NavigationTemplate: FC<Props> = ({
  children,
  navLinks,
  categories,
  promotions,
  isNavHidden,
  isDarkBackground,
  paddingDisabled,
  isSportsBook,
}): JSX.Element => {
  return (
    <>
      <div className="content-column content-column--sticky">
        <Header isNavHidden={isNavHidden} />
      </div>
      <div className="content-column">
        <NavComponent
          navLinks={navLinks}
          categories={categories}
          promotions={promotions}
          isNavHidden={isNavHidden}
          isDarkBackground={isDarkBackground}
          paddingDisabled={paddingDisabled}
          isSportsBook={isSportsBook}
        >
          {children}
        </NavComponent>
      </div>
      <div className="content-column">
        <Footer isTimeDisplayed={false} isLangSwitcherDisplayed={false} />
      </div>
    </>
  );
};
