import React from 'react';
import { Button } from '../../shared/Button';
import { useSignIn } from '../../../hooks/auth';
import './styles';
import { useTranslations } from '../../../hooks/useTranslationsHelper';

type RegistrationSignInLinkProps = {
  className: string;
};

type RegistrationSignInLinkType = React.ComponentType<React.PropsWithChildren<RegistrationSignInLinkProps>>;

export const RegistrationSignInLink: RegistrationSignInLinkType = ({ className }) => {
  const { t } = useTranslations();
  const signIn = useSignIn();

  const onSignInClick = (): void => {
    signIn();
  };

  return (
    <div className={className}>
      <span>{t('register.steps.account.already-have.text')}</span>
      <Button className="registration-sign-in__link" type="button" variant="link" onClick={onSignInClick}>
        <span>{t('register.steps.account.login-text')}</span>
      </Button>
    </div>
  );
};
