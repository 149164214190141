import React, { FC, useRef, useState, useEffect, CSSProperties, useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';
import { GameCardContent, GameCardProps } from '../GameCard';

type GameCardOverlayProps = {
  zoom: boolean;
  isZooming: boolean;
  gameLabel?: string;
  isDisabled: boolean;
  props: GameCardProps;
  realLaunchUrl: string;
  cancelZoom: () => void;
  setZoom: (value: boolean) => void;
  innerRef: React.RefObject<HTMLAnchorElement>;
  contentRef: React.RefObject<HTMLDivElement>;
  isPlayLoading: boolean;
  handlePlayLoading: (arg: boolean) => void;
};

/**
 * Calculate scaled card dimensions & left positions
 *
 * Will limit scaled card so that it will stay larger than 240px so that display is not broken.
 */
const getScaledCardDimensions = (
  width: number,
  height: number,
  left: number,
  transformOrigin: string
): { width: number; height: number; left: number } => {
  const minScaleWidth = 240;
  const belowMinScaleWidth = width < minScaleWidth;

  if (belowMinScaleWidth) {
    const offsetLeft = (minScaleWidth - width) / 2;
    const leftAdjustment = transformOrigin.includes('right')
      ? offsetLeft * 2
      : transformOrigin.includes('left')
        ? 0
        : offsetLeft;

    return {
      width: minScaleWidth,
      height,
      left: left - leftAdjustment,
    };
  }

  return {
    left,
    width,
    height,
  };
};

export const GameCardOverlay: FC<GameCardOverlayProps> = ({
  setZoom,
  cancelZoom,
  zoom,
  isDisabled,
  props,
  realLaunchUrl,
  gameLabel,
  contentRef,
  innerRef,
  isPlayLoading,
  handlePlayLoading,
}) => {
  const overlayRef = useRef<HTMLDivElement>(null);
  const [zoomCSSProps, setZoomCSSProps] = useState<React.CSSProperties>({});
  let zoomTimeout: NodeJS.Timeout;

  const handleZoom = (delay = 300): void => {
    if (!zoom) {
      zoomTimeout = setTimeout(() => {
        setZoom(true);
      }, delay);
    }
  };

  useEffect(() => {
    handleZoom();

    return () => {
      clearTimeout(zoomTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getZoomTransformOrigin = (current: HTMLAnchorElement, containerElementWidth: number): string => {
    const leftSpace = current.clientWidth * 0.1 <= current.offsetLeft;
    const rightSpace = current.clientWidth * 0.1 <= containerElementWidth - (current.clientWidth + current.offsetLeft);

    return leftSpace && rightSpace ? 'center center' : rightSpace ? 'center left' : 'center right';
  };

  const getZoomCSSProps = (zoomIn = false): CSSProperties => {
    if (!contentRef?.current || !innerRef?.current || !props.containerElementWidth) {
      return {};
    }

    const { current } = contentRef;
    const { containerElementWidth } = props;
    const { documentElement } = document; // Receiving whole HTML tree (From top to bottom)
    // cordinates of current element includes (x, y, width, height, top, right, left, bot)
    const boundingRect = current.getBoundingClientRect();
    const { width, top, left } = current.getBoundingClientRect();
    const leftPosition = left + documentElement.scrollLeft;
    const topPosition = top + documentElement.scrollTop;
    const height = boundingRect.height;
    const space = containerElementWidth - innerRef.current.clientWidth;

    const zoom = overlayRef.current && space / containerElementWidth > 0.2 && zoomIn;

    const transformOrigin =
      props.customTransformOrigin || getZoomTransformOrigin(innerRef.current, containerElementWidth);

    const dimensions = getScaledCardDimensions(width, height, leftPosition, transformOrigin);
    const scale = zoom ? 1.1 : 1;

    return {
      ...dimensions,
      top: overlayRef.current
        ? topPosition - ((overlayRef.current.getBoundingClientRect().height - dimensions.height) * scale) / 2
        : topPosition,
      zIndex: overlayRef.current ? 7 : -1,
      opacity: zoom ? 1 : 0,
      transform: zoom ? `scale(1.1)` : undefined,
      transition: overlayRef.current ? 'transform 0.3s, opacity 0.3s' : undefined,
      transformOrigin,
    };
  };

  useLayoutEffect(() => {
    setZoomCSSProps(getZoomCSSProps());

    if (overlayRef.current) {
      const timeout = setTimeout(() => setZoomCSSProps(getZoomCSSProps(true)), 0);

      return () => {
        clearTimeout(timeout);
        clearTimeout(zoomTimeout);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoom, overlayRef.current, contentRef.current, props.containerElementWidth]);

  return zoom
    ? ReactDOM.createPortal(
        <div
          style={{
            position: 'absolute',
            transformOrigin: 'center center',
            ...zoomCSSProps,
          }}
          className="zoomed-game-card"
          role="dialog"
          aria-label="popup-label"
          onMouseLeave={cancelZoom}
        >
          <GameCardContent
            {...props}
            gameLabel={gameLabel}
            ref={overlayRef}
            displayDetails
            enablePlayOnClick
            focusOnLoad
            realLaunchUrl={realLaunchUrl}
            disabled={isDisabled}
            isPlayLoading={isPlayLoading}
            handlePlayLoading={handlePlayLoading}
          />
        </div>,
        document.getElementById('gameCardZoomPortal') as HTMLElement
      )
    : null;
};
