import React, { FC } from 'react';
import { AccountTile } from '../../Account/MyAccount/AccountTile';
import { AccountTileListItem } from '../../Account/MyAccount/AccountTileListItem';
import { Button } from '../../shared/Button';
import { Heading } from '../../shared/Heading';
import { Icon } from '../../shared/Icon';
import { Paragraph } from '../../shared/Paragraph';
import { Spinner } from '../../shared/Spinner';
import { getLimitNameByPeriod } from '../helper';
import { AccountLimitsItem } from './AccountLimitsItem/AccountLimitsItem';
import { ActiveLimitType, LimitPeriodsEnum, ModalAction } from './types';
import { IGroupedLimits } from '../types';
import { useTranslations } from '../../../hooks/useTranslationsHelper';
import { BudgetLimitsTransactionType } from '../../../libs/graphql/baseAppAPI/__generated_types__/types.generated';

interface AccountLimitsSectionProps {
  data?: IGroupedLimits['DEPOSIT'] | IGroupedLimits['LOSS'];
  type: BudgetLimitsTransactionType;
  isDataInitialised?: boolean;
  refetchLimits: () => void;
  onItemClick: (period?: LimitPeriodsEnum, type?: ActiveLimitType, action?: ModalAction) => void;
  openLimitsHistoryModal?: (limitType: ActiveLimitType) => void;
  openHowItWorksModal?: (limitType: ActiveLimitType) => void;
}

export const AccountLimitsSection: FC<AccountLimitsSectionProps> = ({
  data = {},
  type,
  isDataInitialised,
  onItemClick,
  refetchLimits,
  openHowItWorksModal,
  openLimitsHistoryModal,
}) => {
  const { t } = useTranslations();
  const limitTitle = type === BudgetLimitsTransactionType.Deposit ? t('limits.deposit') : t('limits.loss');

  return (
    <div className="account-limits">
      <div className="account-limits__heading-container">
        <Heading level={6}>
          {t('limits.limits-title', {
            limitTitle,
          })}
        </Heading>
        {openLimitsHistoryModal && (
          <Button
            type="button"
            variant="text"
            icon={<Icon variant="File" />}
            iconPosition="left"
            onClick={(): void => openLimitsHistoryModal(type as ActiveLimitType)}
          >
            {t('limits.history')}
          </Button>
        )}
      </div>
      <Paragraph size="xs" noMargin highlight>
        {t('limits.set-limits-message', { limitTitle: limitTitle.toLowerCase() })}
        <Button type="button" variant="text" onClick={(): void => openHowItWorksModal?.(type as ActiveLimitType)}>
          {t('my-account.limits.see-how-works', { limitTitle })}
        </Button>
      </Paragraph>
      {isDataInitialised ? (
        Object.values(data)?.length ? (
          Object.keys(data)?.map((key) => (
            <AccountLimitsItem
              key={key}
              label={t('limits.period-message', {
                period: getLimitNameByPeriod(key, true),
                limitTitle,
              })}
              data={data[key]}
              onClick={onItemClick}
              refetchLimits={refetchLimits}
              type={type}
            />
          ))
        ) : (
          <AccountTile noPadding>
            <AccountTileListItem
              text={t('limit.set-limit-message', { limitTitle })}
              icon="Plus"
              iconAlign="right"
              onClick={(): void => onItemClick(LimitPeriodsEnum.daily, type as ActiveLimitType, ModalAction.Edit)}
            />
          </AccountTile>
        )
      ) : (
        <div className="account-limits__spinner-wrapper">
          <Spinner theme="light" />
        </div>
      )}
    </div>
  );
};
