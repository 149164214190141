import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GameSearchQueryVariables = Types.Exact<{
  searchTerm: Types.Scalars['String']['input'];
  language: Types.Scalars['String']['input'];
}>;


export type GameSearchQuery = { __typename?: 'Query', game: Array<{ __typename?: 'game', game_id?: string | null, provider?: { __typename?: 'game_provider', mediator_id?: string | null } | null, tags?: Array<{ __typename?: 'game_game_tags', game_tags_id?: { __typename?: 'game_tags', translations?: Array<{ __typename?: 'game_tags_translations', name?: string | null } | null> | null } | null } | null> | null, game_image?: { __typename?: 'directus_files', width?: number | null, height?: number | null, filename_disk?: string | null } | null, translations?: Array<{ __typename?: 'game_translations', display_name?: string | null, slug?: string | null } | null> | null }> };


export const GameSearchDocument = gql`
    query GameSearch($searchTerm: String!, $language: String!) {
  game(
    filter: {status: {_eq: "published"}, provider: {status: {_eq: "published"}}, translations: {display_name: {_icontains: $searchTerm}, languages_language_code: {language_code: {_eq: $language}}}}
  ) {
    game_id
    provider(filter: null) {
      mediator_id
    }
    tags {
      game_tags_id(filter: null) {
        translations(
          filter: {languages_language_code: {language_code: {_eq: $language}}}
        ) {
          name
        }
      }
    }
    game_image(filter: null) {
      width
      height
      filename_disk
    }
    translations(
      filter: {languages_language_code: {language_code: {_eq: $language}}}
    ) {
      display_name
      slug
    }
  }
}
    `;

/**
 * __useGameSearchQuery__
 *
 * To run a query within a React component, call `useGameSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGameSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGameSearchQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGameSearchQuery(baseOptions: Apollo.QueryHookOptions<GameSearchQuery, GameSearchQueryVariables> & ({ variables: GameSearchQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GameSearchQuery, GameSearchQueryVariables>(GameSearchDocument, options);
      }
export function useGameSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GameSearchQuery, GameSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GameSearchQuery, GameSearchQueryVariables>(GameSearchDocument, options);
        }
export function useGameSearchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GameSearchQuery, GameSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GameSearchQuery, GameSearchQueryVariables>(GameSearchDocument, options);
        }
export type GameSearchQueryHookResult = ReturnType<typeof useGameSearchQuery>;
export type GameSearchLazyQueryHookResult = ReturnType<typeof useGameSearchLazyQuery>;
export type GameSearchSuspenseQueryHookResult = ReturnType<typeof useGameSearchSuspenseQuery>;
export type GameSearchQueryResult = Apollo.QueryResult<GameSearchQuery, GameSearchQueryVariables>;