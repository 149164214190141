import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IsGeoLocationAllowedQueryVariables = Types.Exact<{
  xTenantId: Types.Scalars['String']['input'];
}>;


export type IsGeoLocationAllowedQuery = { __typename?: 'Query', isGeoLocationAllowed?: { __typename?: 'GeoLocationResponse', isAllowed: boolean } | null };


export const IsGeoLocationAllowedDocument = gql`
    query IsGeoLocationAllowed($xTenantId: String!) {
  isGeoLocationAllowed(xTenantID: $xTenantId) {
    isAllowed
  }
}
    `;

/**
 * __useIsGeoLocationAllowedQuery__
 *
 * To run a query within a React component, call `useIsGeoLocationAllowedQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsGeoLocationAllowedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsGeoLocationAllowedQuery({
 *   variables: {
 *      xTenantId: // value for 'xTenantId'
 *   },
 * });
 */
export function useIsGeoLocationAllowedQuery(baseOptions: Apollo.QueryHookOptions<IsGeoLocationAllowedQuery, IsGeoLocationAllowedQueryVariables> & ({ variables: IsGeoLocationAllowedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsGeoLocationAllowedQuery, IsGeoLocationAllowedQueryVariables>(IsGeoLocationAllowedDocument, options);
      }
export function useIsGeoLocationAllowedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsGeoLocationAllowedQuery, IsGeoLocationAllowedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsGeoLocationAllowedQuery, IsGeoLocationAllowedQueryVariables>(IsGeoLocationAllowedDocument, options);
        }
export function useIsGeoLocationAllowedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IsGeoLocationAllowedQuery, IsGeoLocationAllowedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IsGeoLocationAllowedQuery, IsGeoLocationAllowedQueryVariables>(IsGeoLocationAllowedDocument, options);
        }
export type IsGeoLocationAllowedQueryHookResult = ReturnType<typeof useIsGeoLocationAllowedQuery>;
export type IsGeoLocationAllowedLazyQueryHookResult = ReturnType<typeof useIsGeoLocationAllowedLazyQuery>;
export type IsGeoLocationAllowedSuspenseQueryHookResult = ReturnType<typeof useIsGeoLocationAllowedSuspenseQuery>;
export type IsGeoLocationAllowedQueryResult = Apollo.QueryResult<IsGeoLocationAllowedQuery, IsGeoLocationAllowedQueryVariables>;