import React, { FunctionComponent } from 'react';
import Div100vh from 'react-div-100vh';
import { useNavigate } from 'react-router-dom';
import placeholderImage from '../../../assets/Error/maintencence.png';
import { APP_URLS } from '../../../consts';
import { Button } from '../../shared/Button';
import { Logo } from '../../shared/Header/Logo/Logo';
import { Heading } from '../../shared/Heading';
import { RouterLink } from '../../shared/Link';
import { Paragraph } from '../../shared/Paragraph';
import './styles';

const ErrorPage: FunctionComponent = () => {
  const navigate = useNavigate();

  const onClick = (): void => {
    navigate(APP_URLS.home);
  };

  return (
    <Div100vh>
      <div className="error-page">
        <div className="error-page__header-logo">
          <RouterLink to={APP_URLS.home} aria-label="header-logo">
            <Logo />
          </RouterLink>
        </div>
        <div className="error-page__content">
          <div className="error-page__image-section">
            <img src={placeholderImage} alt="Error" className="error-page__image" />
          </div>
          <div className="error-page__text-section">
            <Heading level={1} className="error-page__title">
              Oops! Something went wrong
            </Heading>
            <Paragraph size="md" className="error-page__message">
              We can't seem to find that page. Please check you entered the correct address.
            </Paragraph>
            <Paragraph size="md" className="error-page__message">
              You can also return to the lobby to check out the great games there!
            </Paragraph>
            <Button variant="primary" size="medium" onClick={onClick} className="error-page__cta-btn" type="button">
              GO TO LOBBY
            </Button>
          </div>
        </div>
      </div>
    </Div100vh>
  );
};

export default ErrorPage;
