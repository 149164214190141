import React, { FunctionComponent, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { xTenantId } from '../../../../consts';
import { useSnackbar } from '../../../../hooks/snackbar';
import { useTransactionLimitsUpdateMutation } from '../../../../libs/graphql/baseAppAPI/mutations/__generated__/transaction-limits-update.mutation.generated';
import { useUserContext } from '../../../../providers/UserProvider';
import { BudgetLimitsTransactionResponse, CreatorName } from '../../../../types.__generated__';
import { Paragraph } from '../../../shared/Paragraph';
import { Prompt } from '../../../shared/Prompt';
import { LimitsConfirmationTiles } from './LimitsConfirmationTiles';
import './LimitsConfirmationPrompt.scss';
import { BudgetLimitsTransactionType } from '../../../../libs/graphql/baseAppAPI/__generated_types__/types.generated';

interface LimitsConfirmationPromptProps {
  activeAmount: number;
  type: BudgetLimitsTransactionType;
  activeItem?: BudgetLimitsTransactionResponse;
  pendingUserActionItem?: BudgetLimitsTransactionResponse;
  refetchLimits: () => void;
  onCloseCallback?: (arg: boolean) => void;
}

export const LimitsConfirmationPrompt: FunctionComponent<LimitsConfirmationPromptProps> = ({
  type,
  activeItem,
  pendingUserActionItem,
  activeAmount,
  refetchLimits,
  onCloseCallback,
}) => {
  const auth = useAuth();
  const { addSnack } = useSnackbar();
  const { userProfileId } = useUserContext();
  const isUserAuthenticated = auth.isAuthenticated;
  const label = type === BudgetLimitsTransactionType.Deposit ? 'Deposit' : 'Loss';
  const profileId = userProfileId || (auth.user?.profile?.user_profile_id as string);

  const [isPromptVisible, setIsPromptVisible] = useState<boolean>(true);

  const [transactionLimitsUpdateMutation, { loading }] = useTransactionLimitsUpdateMutation({
    onCompleted: async () => {
      await refetchLimits();
      addSnack({
        type: 'success',
        message: 'Your limit has been applied.',
      });
      onClose();
    },
    onError: () => {
      addSnack({
        type: 'error',
        message: 'Sorry, something went wrong. Please try again later.',
      });
    },
  });

  const applyPendingLimitQuery = async (isConfirmed: boolean): Promise<void> => {
    await transactionLimitsUpdateMutation({
      variables: {
        userProfileId: profileId,
        xTenantId,
        limitId: activeItem?.id || '',
        budgetLimitsReview: {
          confirmed: isConfirmed,
        },
        creatorName: CreatorName.User,
      },
    });
  };

  const onConfirmClick = (): void => {
    applyPendingLimitQuery(true);
  };

  const onCancelClick = (): void => {
    applyPendingLimitQuery(false);
  };

  const onClose = (): void => {
    setIsPromptVisible(false);
    onCloseCallback && onCloseCallback(false);
  };

  return (
    <>
      {isUserAuthenticated && (
        <Prompt
          title={`${label} limit update is ready`}
          show={isPromptVisible}
          className="limit-confirmation"
          onClose={onClose}
          PrimaryButton={{
            onClick: onConfirmClick,
            text: 'CONFIRM',
            isSubmitting: loading,
          }}
          SecondaryButton={{
            onClick: onCancelClick,
            text: 'CANCEL UPDATE',
            disabled: loading,
          }}
        >
          <Paragraph noMargin size="sm">
            You have requested to update your {label} limit to the below. Do you still want to update?
          </Paragraph>
          <LimitsConfirmationTiles
            label={label}
            activeAmount={activeAmount}
            pendingUserActionItem={pendingUserActionItem}
          />
        </Prompt>
      )}
    </>
  );
};
