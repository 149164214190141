/* eslint quote-props: 0 */
import roulette from '../../../../assets/Promotions/promo-roulette.png';
import vegas from '../../../../assets/Promotions/promo-vegas.jpg';
import cashino from '../../../../assets/Promotions/promo-cashino.png';
import casino from '../../../../assets/Promotions/promo-casino.jpg';
import cosmo from '../../../../assets/Promotions/promo-cosmo.jpg';
import game from '../../../../assets/Promotions/promo-game.jpg';
import kinds from '../../../../assets/Promotions/promo-kinds.jpg';
import sparkle from '../../../../assets/Promotions/promo-sparkle.jpg';
import { ProductType, PromotionTabsType } from '../PromotionsList/types';

export const bonusesMock = {
  itemsPerPage: 20,
  currentPage: 1,
  totalItems: 18,
  totalPages: 1,
  items: [
    {
      displayName: 'DEP_10_GET_20',
      description: 'DEP_10_GET_20',
      promotionTitle: 'Bet £50 Get A £50 Free Bet!',
      shortDescription: 'Conheça a oferta. Faça qualquer aposta de £50 e receba mais £50 em Aposta Grátis!',
      detailedDescription: 'DEP_10_GET_20',
      expireDateLabel: 'Welcome bonus',
      shortDescriptionTC:
        '18+. Disponível nos primeiros 3 dias após o registro. Apenas contas novas. Aposta de qualificação: uma aposta de no mínimo £50 em odds mínimas de 1,5, finalizada em até 3 dias após o registro. Recompensa: 1 Aposta Grátis de £50 em odds mínimas de 1,5. A Aposta Grátis será creditada assim que a aposta de qualificação for finalizada, dentro de 3 dias após o registro. A Aposta Grátis tem um prazo de validade de 7 dias. T&amp;Cs se aplicam.  Ver os Terminos Completos',
      termsAndConditions: null,
      canBeClaimed: true,
      priority: 1,
      publishDate: '2021-04-06T00:00:00.000Z',
      unpublishDate: '2021-05-31T23:59:59.000Z',
      reasonId: 4,
      reason: 'Promotions',
      imgPath: cosmo,
      internalName: 'DEP_10_GET_20',
      id: 103,
      startDate: '2021-04-06T00:00:00.000Z',
      endDate: '2021-05-31T23:59:59.000Z',
      promoCode: 'DEP_10_GET_20',
      bonusAmount: 20,
      configuration: {
        MaximumBonusAmount: 0,
        BonusPercentage: 0,
        MinimumDeposit: '10',
        IsPercentage: true,
      },
      bonusType: 'FirstDeposit',
      targetWalletType: 'Bonus',
      targetWalletVertical: 'Casino',
      previousBonusType: 'Inherit',
      isVisibleToLoggedOut: false,
      hidden: false,
      inheritanceChain: null,
      activatedCount: 0,
      maximumActivationLimit: null,
      totalAmountGiven: 0,
      totalAmountLimit: null,
      images: {
        default: {
          url: null,
          alt: null,
        },
      },
      default: false,
      promoButtonText: 'BET NOW',
      buttonText: 'Opt In',
      products: [ProductType.CASINO],
    },
    {
      displayName: 'Instant 30',
      description: 'All Games Bonus 30, 3 day expiry',
      promotionTitle: 'Wheel of Universe',
      shortDescription: 'Conheça a oferta. Faça qualquer aposta de £50 e receba mais £50 em Aposta Grátis!',
      detailedDescription: null,
      imgPath: vegas,
      expireDateLabel: '24/12/2025',
      termsAndConditions:
        '1. Instant 30 has limit usage of 10 per day per player between Tuesday 9th March 2021 00:00 and Wednesday 9th March 2022 23:59.\r\n2. This bonus is a £30 instant bonus.\r\n3. The bonus will be paid as Casino bonus. This can be spent on casino. \r\n4. Bonus must be wagered 10 time(s) within 72 hours before it can be withdrawn. If you have a previous bonus, all funds and contributions will be inherited. \r\n5. The site has the right to cancel the bonus at any time.\r\n6. All dates and times are displayed in Coordinated Universal Time - UTC time zone.\r\n',
      shortDescriptionTC:
        '18+. Disponível nos primeiros 3 dias após o registro. Apenas contas novas. Aposta de qualificação: uma aposta de no mínimo £50 em odds mínimas de 1,5, finalizada em até 3 dias após o registro. Recompensa: 1 Aposta Grátis de £50 em odds mínimas de 1,5. A Aposta Grátis será creditada assim que a aposta de qualificação for finalizada, dentro de 3 dias após o registro. A Aposta Grátis tem um prazo de validade de 7 dias. T&amp;Cs se aplicam.  Ver os Terminos Completos',
      canBeClaimed: false,
      priority: 2,
      publishDate: '2021-03-09T00:00:00.000Z',
      unpublishDate: '2022-03-09T23:59:59.000Z',
      reasonId: 4,
      reason: 'Promotions',
      internalName: 'INSTANT_30',
      id: 13,
      startDate: '2021-03-09T00:00:00.000Z',
      endDate: '2022-03-09T23:59:59.000Z',
      promoCode: 'INSTANT_30',
      bonusAmount: 30,
      configuration: {
        MaximumBonusAmount: 0,
        BonusPercentage: 0,
      },
      bonusType: 'Instant',
      targetWalletType: 'Bonus',
      targetWalletVertical: 'Casino',
      previousBonusType: 'Inherit',
      isVisibleToLoggedOut: false,
      hidden: false,
      inheritanceChain: null,
      activatedCount: 11,
      maximumActivationLimit: null,
      totalAmountGiven: 330,
      totalAmountLimit: null,
      images: {
        default: {
          url: null,
          alt: null,
        },
      },
      default: false,
      buttonText: 'Opt In',
      promoButtonText: 'OPT IN',
      targetAccumulation: 100,
      currentAccumulation: 75,
      products: [ProductType.CASINO],
      status: PromotionTabsType.ACTIVE,
    },
    {
      displayName: 'Cashback on Total Stake 20',
      description: 'All Games Bonus, 1 day expiry',
      promotionTitle: 'Another The Best Deposit',
      shortDescription: 'Conheça a oferta. Faça qualquer aposta de £50 e receba mais £50 em Aposta Grátis!',
      detailedDescription: null,
      imgPath: cashino,
      termsAndConditions:
        '1. Cashback on Total Stake 20 has limit usage of 10 per day per player between Tuesday 9th March 2021 00:00 and Wednesday 9th March 2022 23:59.\r\n2. This bonus is a cashback on stake bonus. Get £20 cashback when you stake £10 on all games. If stake target is achieved in the first 24 hours after claim, the bonus will be paid into your account upon qualification.\r\n3. The bonus will be paid as Casino bonus. This can be spent on casino. \r\n4. Bonus must be wagered 10 time(s) within 24 hours before it can be withdrawn. If you have a previous bonus, all funds and contributions will be inherited. \r\n5. The site has the right to cancel the bonus at any time.\r\n6. All dates and times are displayed in Coordinated Universal Time - UTC time zone.\r\n',
      shortDescriptionTC:
        '18+. Disponível nos primeiros 3 dias após o registro. Apenas contas novas. Aposta de qualificação: uma aposta de no mínimo £50 em odds mínimas de 1,5, finalizada em até 3 dias após o registro. Recompensa: 1 Aposta Grátis de £50 em odds mínimas de 1,5. A Aposta Grátis será creditada assim que a aposta de qualificação for finalizada, dentro de 3 dias após o registro. A Aposta Grátis tem um prazo de validade de 7 dias. T&amp;Cs se aplicam.  Ver os Terminos Completos',
      canBeClaimed: false,
      priority: 3,
      publishDate: '2021-03-09T00:00:00.000Z',
      unpublishDate: '2022-03-09T23:59:59.000Z',
      reasonId: 4,
      reason: 'Promotions',
      internalName: 'CBOTS_20',
      id: 14,
      startDate: '2021-03-09T00:00:00.000Z',
      endDate: '2022-03-09T23:59:59.000Z',
      promoCode: 'CBOTS_20',
      bonusAmount: 20,
      configuration: {
        MaximumBonusAmount: 0,
        BonusPercentage: 0,
        GamesToBonusRelation: 'Included',
        Games: [],
        QualifyingGameTags: [],
        MinimumLossesToReward: null,
        MinimumStakeToReward: '10',
        CashStakesOnly: true,
        IsPercentage: false,
        HoursToQualify: '24',
        PayoutAfterPeriod: false,
      },
      bonusType: 'CashbackOnTotalStake',
      targetWalletType: 'Bonus',
      targetWalletVertical: 'Casino',
      previousBonusType: 'Inherit',
      isVisibleToLoggedOut: false,
      hidden: false,
      inheritanceChain: null,
      activatedCount: 2,
      maximumActivationLimit: null,
      totalAmountGiven: 0,
      totalAmountLimit: null,
      images: {
        default: {
          url: null,
          alt: null,
        },
      },
      default: false,
      buttonText: 'Opt In',
      promoButtonText: 'OPT IN',
      products: [ProductType.CASINO],
    },
    {
      displayName: 'Test promo',
      description: 'All Games Bonus 10, 5 day expiry',
      promotionTitle: 'Another The Best Deposit',
      shortDescription: 'Conheça a oferta. Faça qualquer aposta de £50 e receba mais £50 em Aposta Grátis!',
      imgPath: casino,
      expireDateLabel: '24/12/2027',
      detailedDescription: null,
      termsAndConditions:
        '1. Instant 30 has limit usage of 10 per day per player between Tuesday 9th March 2021 00:00 and Wednesday 9th March 2022 23:59.\r\n2. This bonus is a £30 instant bonus.\r\n3. The bonus will be paid as Casino bonus. This can be spent on casino. \r\n4. Bonus must be wagered 10 time(s) within 72 hours before it can be withdrawn. If you have a previous bonus, all funds and contributions will be inherited. \r\n5. The site has the right to cancel the bonus at any time.\r\n6. All dates and times are displayed in Coordinated Universal Time - UTC time zone.\r\n',
      shortDescriptionTC:
        '18+. Disponível nos primeiros 3 dias após o registro. Apenas contas novas. Aposta de qualificação: uma aposta de no mínimo £50 em odds mínimas de 1,5, finalizada em até 3 dias após o registro. Recompensa: 1 Aposta Grátis de £50 em odds mínimas de 1,5. A Aposta Grátis será creditada assim que a aposta de qualificação for finalizada, dentro de 3 dias após o registro. A Aposta Grátis tem um prazo de validade de 7 dias. T&amp;Cs se aplicam.  Ver os Terminos Completos',
      canBeClaimed: false,
      priority: 4,
      publishDate: '2021-03-09T00:00:00.000Z',
      unpublishDate: '2022-03-09T23:59:59.000Z',
      reasonId: 4,
      reason: 'Promotions',
      internalName: 'INSTANT_30',
      id: 23,
      startDate: '2021-03-09T00:00:00.000Z',
      endDate: '2022-03-09T23:59:59.000Z',
      promoCode: 'taras',
      bonusAmount: 30,
      configuration: {
        MaximumBonusAmount: 0,
        BonusPercentage: 0,
      },
      bonusType: 'OptIn',
      targetWalletType: 'Bonus',
      targetWalletVertical: 'Casino',
      previousBonusType: 'Inherit',
      isVisibleToLoggedOut: false,
      hidden: false,
      inheritanceChain: null,
      activatedCount: 11,
      maximumActivationLimit: null,
      totalAmountGiven: 330,
      totalAmountLimit: null,
      images: {
        default: {
          url: null,
          alt: null,
        },
      },
      default: false,
      buttonText: 'Opt In',
      promoButtonText: 'OPT IN',
      products: [ProductType.CASINO],
    },
    {
      displayName: 'DEP_10_GET_20',
      description: 'DEP_10_GET_20',
      shortDescription: 'Lorem Ipsum',
      imgPath: roulette,
      promotionTitle: 'The Big Catch',
      detailedDescription: 'DEP_10_GET_20',
      termsAndConditions: null,
      shortDescriptionTC:
        '18+. Disponível nos primeiros 3 dias após o registro. Apenas contas novas. Aposta de qualificação: uma aposta de no mínimo £50 em odds mínimas de 1,5, finalizada em até 3 dias após o registro. Recompensa: 1 Aposta Grátis de £50 em odds mínimas de 1,5. A Aposta Grátis será creditada assim que a aposta de qualificação for finalizada, dentro de 3 dias após o registro. A Aposta Grátis tem um prazo de validade de 7 dias. T&amp;Cs se aplicam.  Ver os Terminos Completos',
      canBeClaimed: true,
      priority: 5,
      publishDate: '2021-04-06T00:00:00.000Z',
      unpublishDate: '2021-05-31T23:59:59.000Z',
      reasonId: 4,
      reason: 'Promotions',
      internalName: 'DEP_10_GET_20',
      id: 104,
      startDate: '2021-04-06T00:00:00.000Z',
      endDate: '2021-05-31T23:59:59.000Z',
      promoCode: 'DEP_10_GET_20',
      bonusAmount: 20,
      configuration: {
        MaximumBonusAmount: 0,
        BonusPercentage: 0,
        MinimumDeposit: '10',
        IsPercentage: true,
      },
      bonusType: 'Deposit',
      targetWalletType: 'Bonus',
      targetWalletVertical: 'Casino',
      previousBonusType: 'Inherit',
      isVisibleToLoggedOut: false,
      hidden: false,
      inheritanceChain: null,
      activatedCount: 0,
      maximumActivationLimit: null,
      totalAmountGiven: 0,
      totalAmountLimit: null,
      images: {
        // eslint-disable-next-line quote-props
        default: {
          url: null,
          alt: null,
        },
      },
      // eslint-disable-next-line quote-props
      default: false,
      buttonText: 'Opt In',
      promoButtonText: 'OPT IN',
      targetAccumulation: 300,
      currentAccumulation: 10,
      products: [ProductType.CASINO],
      status: PromotionTabsType.ACTIVE,
    },
    {
      displayName: 'Instant 30',
      description: 'All Games Bonus 30, 3 day expiry',
      shortDescription: 'Lorem Ipsum',
      imgPath: game,
      promotionTitle: 'Incredible opportunity!',
      detailedDescription: null,
      expireDateLabel: '24/12/2029',
      termsAndConditions:
        '1. Instant 30 has limit usage of 10 per day per player between Tuesday 9th March 2021 00:00 and Wednesday 9th March 2022 23:59.\r\n2. This bonus is a £30 instant bonus.\r\n3. The bonus will be paid as Casino bonus. This can be spent on casino. \r\n4. Bonus must be wagered 10 time(s) within 72 hours before it can be withdrawn. If you have a previous bonus, all funds and contributions will be inherited. \r\n5. The site has the right to cancel the bonus at any time.\r\n6. All dates and times are displayed in Coordinated Universal Time - UTC time zone.\r\n',
      shortDescriptionTC:
        '18+. Disponível nos primeiros 3 dias após o registro. Apenas contas novas. Aposta de qualificação: uma aposta de no mínimo £50 em odds mínimas de 1,5, finalizada em até 3 dias após o registro. Recompensa: 1 Aposta Grátis de £50 em odds mínimas de 1,5. A Aposta Grátis será creditada assim que a aposta de qualificação for finalizada, dentro de 3 dias após o registro. A Aposta Grátis tem um prazo de validade de 7 dias. T&amp;Cs se aplicam.  Ver os Terminos Completos',
      canBeClaimed: false,
      priority: 3,
      publishDate: '2021-03-09T00:00:00.000Z',
      unpublishDate: '2022-03-09T23:59:59.000Z',
      reasonId: 4,
      reason: 'Promotions',
      internalName: 'INSTANT_30',
      id: 130,
      startDate: '2021-03-09T00:00:00.000Z',
      endDate: '2022-03-09T23:59:59.000Z',
      promoCode: 'INSTANT_30',
      bonusAmount: 30,
      configuration: {
        MaximumBonusAmount: 0,
        BonusPercentage: 0,
      },
      bonusType: 'Instant',
      targetWalletType: 'Bonus',
      targetWalletVertical: 'Casino',
      previousBonusType: 'Inherit',
      isVisibleToLoggedOut: false,
      hidden: false,
      inheritanceChain: null,
      activatedCount: 11,
      maximumActivationLimit: null,
      totalAmountGiven: 330,
      totalAmountLimit: null,
      images: {
        // eslint-disable-next-line quote-props
        default: {
          url: null,
          alt: null,
        },
      },
      // eslint-disable-next-line quote-props
      default: false,
      buttonText: 'Opt In',
      promoButtonText: 'OPT IN',
      products: [ProductType.SPORT],
    },
    {
      displayName: 'Cashback on Total Stake 20',
      description: 'All Games Bonus, 1 day expiry',
      shortDescription: 'Lorem Ipsum',
      imgPath: sparkle,
      promotionTitle: 'Crazy bingo',
      detailedDescription: null,
      expireDateLabel: '24/12/2030',
      termsAndConditions:
        '1. Cashback on Total Stake 20 has limit usage of 10 per day per player between Tuesday 9th March 2021 00:00 and Wednesday 9th March 2022 23:59.\r\n2. This bonus is a cashback on stake bonus. Get £20 cashback when you stake £10 on all games. If stake target is achieved in the first 24 hours after claim, the bonus will be paid into your account upon qualification.\r\n3. The bonus will be paid as Casino bonus. This can be spent on casino. \r\n4. Bonus must be wagered 10 time(s) within 24 hours before it can be withdrawn. If you have a previous bonus, all funds and contributions will be inherited. \r\n5. The site has the right to cancel the bonus at any time.\r\n6. All dates and times are displayed in Coordinated Universal Time - UTC time zone.\r\n',
      shortDescriptionTC:
        '18+. Disponível nos primeiros 3 dias após o registro. Apenas contas novas. Aposta de qualificação: uma aposta de no mínimo £50 em odds mínimas de 1,5, finalizada em até 3 dias após o registro. Recompensa: 1 Aposta Grátis de £50 em odds mínimas de 1,5. A Aposta Grátis será creditada assim que a aposta de qualificação for finalizada, dentro de 3 dias após o registro. A Aposta Grátis tem um prazo de validade de 7 dias. T&amp;Cs se aplicam.  Ver os Terminos Completos',
      canBeClaimed: false,
      priority: 2,
      publishDate: '2021-03-09T00:00:00.000Z',
      unpublishDate: '2022-03-09T23:59:59.000Z',
      reasonId: 4,
      reason: 'Promotions',
      internalName: 'CBOTS_20',
      id: 140,
      startDate: '2021-03-09T00:00:00.000Z',
      endDate: '2022-03-09T23:59:59.000Z',
      promoCode: 'CBOTS_20',
      bonusAmount: 20,
      configuration: {
        MaximumBonusAmount: 0,
        BonusPercentage: 0,
        GamesToBonusRelation: 'Included',
        Games: [],
        QualifyingGameTags: [],
        MinimumLossesToReward: null,
        MinimumStakeToReward: '10',
        CashStakesOnly: true,
        IsPercentage: false,
        HoursToQualify: '24',
        PayoutAfterPeriod: false,
      },
      bonusType: 'CashbackOnTotalStake',
      targetWalletType: 'Bonus',
      targetWalletVertical: 'Casino',
      previousBonusType: 'Inherit',
      isVisibleToLoggedOut: false,
      hidden: false,
      inheritanceChain: null,
      activatedCount: 2,
      maximumActivationLimit: null,
      totalAmountGiven: 0,
      totalAmountLimit: null,
      images: {
        // eslint-disable-next-line quote-props
        default: {
          url: null,
          alt: null,
        },
      },
      // eslint-disable-next-line quote-props
      default: false,
      buttonText: 'Opt In',
      promoButtonText: 'OPT IN',
      products: [ProductType.SPORT],
    },
    {
      displayName: 'Test promo',
      description: 'All Games Bonus 10, 5 day expiry',
      promotionTitle: 'A perfect chance',
      shortDescription: 'Lorem Ipsum',
      imgPath: kinds,
      detailedDescription: null,
      expireDateLabel: '24/12/2031',
      termsAndConditions:
        '1. Instant 30 has limit usage of 10 per day per player between Tuesday 9th March 2021 00:00 and Wednesday 9th March 2022 23:59.\r\n2. This bonus is a £30 instant bonus.\r\n3. The bonus will be paid as Casino bonus. This can be spent on casino. \r\n4. Bonus must be wagered 10 time(s) within 72 hours before it can be withdrawn. If you have a previous bonus, all funds and contributions will be inherited. \r\n5. The site has the right to cancel the bonus at any time.\r\n6. All dates and times are displayed in Coordinated Universal Time - UTC time zone.\r\n',
      shortDescriptionTC:
        '18+. Disponível nos primeiros 3 dias após o registro. Apenas contas novas. Aposta de qualificação: uma aposta de no mínimo £50 em odds mínimas de 1,5, finalizada em até 3 dias após o registro. Recompensa: 1 Aposta Grátis de £50 em odds mínimas de 1,5. A Aposta Grátis será creditada assim que a aposta de qualificação for finalizada, dentro de 3 dias após o registro. A Aposta Grátis tem um prazo de validade de 7 dias. T&amp;Cs se aplicam.  Ver os Terminos Completos',
      canBeClaimed: false,
      priority: 3,
      publishDate: '2021-03-09T00:00:00.000Z',
      unpublishDate: '2022-03-09T23:59:59.000Z',
      reasonId: 4,
      reason: 'Promotions',
      internalName: 'INSTANT_30',
      id: 323,
      startDate: '2021-03-09T00:00:00.000Z',
      endDate: '2022-03-09T23:59:59.000Z',
      promoCode: 'taras',
      bonusAmount: 30,
      configuration: {
        MaximumBonusAmount: 0,
        BonusPercentage: 0,
      },
      bonusType: 'OptIn',
      targetWalletType: 'Bonus',
      targetWalletVertical: 'Casino',
      previousBonusType: 'Inherit',
      isVisibleToLoggedOut: false,
      hidden: false,
      inheritanceChain: null,
      activatedCount: 11,
      maximumActivationLimit: null,
      totalAmountGiven: 330,
      totalAmountLimit: null,
      images: {
        // eslint-disable-next-line quote-props
        default: {
          url: null,
          alt: null,
        },
      },
      // eslint-disable-next-line quote-props
      default: false,
      buttonText: 'Opt In',
      promoButtonText: 'OPT IN',
      products: [ProductType.SPORT, ProductType.CASINO],
    },
  ],
};

export const bonusHistoryMock = {
  id: 101,
  bonusId: 213123,
  displayName: 'Test Promo',
  successCTAType: 'successCTAType',
  successCTAURL: 'successCTAURL',
  successCTAGameInformation: {
    gameId: 'GAME_ID',
  },
  isSuccessWithoutRedirect: 'isSuccessWithoutRedirect',
  bonusAmount: 1,
  selfWageringRequirementsTarget: 0,
  wageringRequirementsTarget: 0,
  currentContributions: 0,
  activedDate: '10-10-2021',
  expiryDate: null,
  revokeDate: null,
  targetWalletType: 'cash',
  targetWalletVertical: 'casino',
  status: 'active',
  promoCode: 'test-promo',
  currentAccumulation: null,
  targetAccumulation: null,
  bonusType: 'instant',
  payoutAfterPeriod: false,
  enhancedPayoutQualifyingStakesSum: 0,
  endDateToQualify: null,
  termsAndConditions: null,
  reason: null,
  reasonDetails: null,
  configuration: null,
  buyInAmount: null,
  description: 'test promo',
  detailedDescription: null,
  maximumStakeRestriction: null,
  images: {
    default: {
      url: 'https://cdn.britannica.com/83/89183-050-D00C8FDC/Scup-porgy.jpg',
      alt: 'test',
    },
  },
  customFields: [
    {
      key: 'key',
      valueType: 'test type',
      value: 'test value',
    },
  ],
};

export const bonusesHistoryMock = [
  bonusHistoryMock,
  {
    id: 102,
    bonusId: 313123,
    displayName: 'New Promo',
    successCTAType: 'successCTAType',
    successCTAURL: 'successCTAURL',
    successCTAGameInformation: {
      gameId: 'GAME_ID',
    },
    isSuccessWithoutRedirect: 'isSuccessWithoutRedirect',
    bonusAmount: 1,
    selfWageringRequirementsTarget: 0,
    wageringRequirementsTarget: 0,
    currentContributions: 0,
    activedDate: '10-10-2023',
    expiryDate: null,
    revokeDate: null,
    targetWalletType: 'cash',
    targetWalletVertical: 'casino',
    status: 'active',
    promoCode: 'test-promo',
    currentAccumulation: null,
    targetAccumulation: null,
    bonusType: 'instant',
    payoutAfterPeriod: false,
    enhancedPayoutQualifyingStakesSum: 0,
    endDateToQualify: null,
    termsAndConditions: null,
    reason: null,
    reasonDetails: null,
    configuration: null,
    buyInAmount: null,
    description: 'test promo',
    detailedDescription: null,
    maximumStakeRestriction: null,
    images: {
      default: {
        url: 'https://cdn.britannica.com/83/89183-050-D00C8FDC/Scup-porgy.jpg',
        alt: 'test',
      },
    },
    customFields: undefined,
  },
];

export const mergedBonusMock = {
  displayName: 'Test Name',
  description: 'Test Description',
  title: 'Get available promotion',
  promotionTitle: 'Promo title',
  detailedDescription: 'Test Detailed',
  termsAndConditions: null,
  canBeClaimed: true,
  priority: 4,
  publishDate: '12-11-2024',
  unpublishDate: '12-11-2027',
  reasonId: 0,
  reason: 'reason',
  internalName: 'Alex',
  id: 1,
  startDate: '12-11-2025',
  endDate: '12-11-2026',
  promoCode: 'promo-code',
  bonusAmount: 100,
  configuration: {
    MaximumBonusAmount: 200,
    BonusPercentage: 20,
    MinimumDeposit: '$12',
    IsPercentage: false,
  },
  bonusType: 'test type',
  targetWalletType: 'test type',
  targetWalletVertical: 'vert',
  previousBonusType: 'test type',
  isVisibleToLoggedOut: false,
  hidden: false,
  inheritanceChain: null,
  activatedCount: 0,
  maximumActivationLimit: null,
  totalAmountGiven: 30,
  totalAmountLimit: null,
  images: {
    default: {
      url: null,
      alt: null,
    },
  },
  default: false,
  expireDateLabel: 'label',
  failedCTAText: 'failed',
  failedParagraph: 'failed',
  failedTitle: 'failed',
  imgAlt: 'image',
  isFailedWithoutRedirect: 'not redirected',
  isSuccessWithoutRedirect: 'not redirected',
  longDescription: 'Long description',
  pageUrl: 'page-url',
  promoButtonText: 'Promo Buttom',
  promotionCTAType: 'title',
  isHinnedTitle: false,
  shortDescription: 'Description',
  stepsTitle: 'Steps',
  successCTAText: 'Success CTA',
  successParagraph: 'Success Paragraph',
  successTitle: 'Success Title',
};

export const mergedBonusesMock = [
  mergedBonusMock,
  {
    displayName: 'Test Name 2',
    description: 'Test Description 2',
    promotionTitle: 'Promo title',
    detailedDescription: 'Test Detailed',
    termsAndConditions: null,
    canBeClaimed: true,
    priority: 5,
    publishDate: '15-11-2024',
    unpublishDate: '15-11-2027',
    reasonId: 0,
    reason: 'reason',
    internalName: 'Alex',
    id: 2,
    startDate: '15-11-2025',
    endDate: '15-11-2026',
    promoCode: 'promo-code',
    bonusAmount: 150,
    configuration: {
      MaximumBonusAmount: 200,
      BonusPercentage: 20,
      MinimumDeposit: '$15',
      IsPercentage: false,
    },
    bonusType: 'test type',
    targetWalletType: 'test type',
    targetWalletVertical: 'vert',
    previousBonusType: 'test type',
    isVisibleToLoggedOut: false,
    hidden: false,
    inheritanceChain: null,
    activatedCount: 0,
    maximumActivationLimit: null,
    totalAmountGiven: 30,
    totalAmountLimit: null,
    images: {
      default: {
        url: null,
        alt: null,
      },
    },
    default: false,
    expireDateLabel: 'label',
    failedCTAText: 'failed',
    failedParagraph: 'failed',
    failedTitle: 'failed',
    imgAlt: 'image',
    isFailedWithoutRedirect: 'not redirected',
    isSuccessWithoutRedirect: 'not redirected',
    longDescription: 'Long description',
    pageUrl: 'page-url',
    promoButtonText: 'Promo Buttom',
    promotionCTAType: 'itle',
    isHinnedTitle: false,
    shortDescription: 'Description',
    stepsTitle: 'Steps',
    successCTAText: 'Success CTA',
    successParagraph: 'Success Paragraph',
    successTitle: 'Success Title',
  },
];

const mockGigBonus = {
  expiresOn: '2024.11.31',
  name: 'Splash Bonus',
  status: 'Available',
  lockedAmount: '123:00',
  grantedAmount: '123:00',
  wageredAmount: '123:00',
  balanceAmount: '123:00',
};

export const mockGigBonuses = [
  { ...mockGigBonus, bonusTemplateId: 'bonus_1234' },
  { ...mockGigBonus, bonusTemplateId: '11112' },
  { ...mockGigBonus, bonusTemplateId: '11113' },
  { ...mockGigBonus, bonusTemplateId: '11114' },
  { ...mockGigBonus, bonusTemplateId: '11115' },
];
