import { BetAction, TransactionHistoryActivity } from '../../../models/transactions.model';
import { KambiCouponsResponse } from '../../Kambi/types';
import { CouponHistory } from './CouponHistory';
import { joinStrings } from '../../../utils/string';

const FORBIDDEN_CATEGORY = 'Sports';
const FORBIDDEN_TYPES = ['TransferIn', 'TransferOut'];

/**
 * Remove transactions with category 'Sports' as they duplicate the 'Betting' category.
 */
export const removeDuplicateTransactions = (data: TransactionHistoryActivity[]): TransactionHistoryActivity[] =>
  data.filter(
    (item) => !(FORBIDDEN_TYPES.includes(item?.type ?? '') && item?.details?.category === FORBIDDEN_CATEGORY)
  );

export const generateCSV = (
  data: TransactionHistoryActivity[],
  currency = 'GBP',
  t: (arg: string, val?: { currencyValue: string }) => string
): string => {
  const dataTime = 'Date/Time';
  const category = 'Category';
  const type = 'Transaction Type';
  const description = 'Description';
  const value = `Value ${currency}`;

  const headerCSV = `${dataTime},${category},${type},${description},${value}`;
  let csv = '';
  const values = data
    .map((item) => {
      const {
        amount = ' ',
        description = ' ',
        details: { category },
        transactionDate,
        displayType = ' ',
        coupon,
      } = item;

      const _formattedTransactionDate = transactionDate.replace('T', ' ').replace('Z', ' ').split('.')[0];
      const _formattedTransactionDescription = description.replace(/,/g, '');
      const _formattedTransactionAmount = String(amount);

      const translateInformation = (value: string): string | undefined => {
        let translatedValue;

        switch (value) {
          case 'Betting':
            translatedValue = 'Betting';
            break;
          case 'Gaming':
            translatedValue = 'Gaming';
            break;
          case 'Banking':
            translatedValue = 'Banking';
            break;
          case 'Bonus':
            translatedValue = 'Bonus';
            break;
          case 'Deposit':
            translatedValue = 'Deposit';
            break;
          case 'Withdrawal':
            translatedValue = 'Withdrawal';
            break;
          case 'Payment':
            translatedValue = 'Payment';
            break;
          case 'Debit':
            translatedValue = 'Debit';
            break;
          case 'Settle':
            translatedValue = 'Settle';
            break;
          case 'Stake':
            translatedValue = 'Stake';
            break;
          case 'Win':
            translatedValue = 'Win';
            break;
        }

        return translatedValue;
      };

      return joinStrings(
        [
          _formattedTransactionDate,
          translateInformation(category),
          translateInformation(displayType),
          coupon?.description ?? _formattedTransactionDescription,
          _formattedTransactionAmount,
        ],
        ','
      );
    })
    .join('\n');

  csv += headerCSV + '\n' + values;

  return csv;
};

export const getUniqueCouponIds = (transactions: TransactionHistoryActivity[]): string[] =>
  transactions.reduce((acc: string[], transaction) => {
    const id = transaction?.betting?.betActions?.[0].remoteBetId;

    if (id && !acc.includes(id)) {
      acc.push(id);
    }

    return acc;
  }, []);

export const mergeTransactionsWithCoupons = (
  transactions: TransactionHistoryActivity[],
  coupons: KambiCouponsResponse[]
): TransactionHistoryActivity[] =>
  transactions.map((transaction) => {
    const coupon = coupons.find(
      (coupon) => coupon.couponRef.toString() === transaction.betting?.betActions?.[0].remoteBetId
    );

    if (coupon) {
      transaction.coupon = new CouponHistory(coupon, transaction.type);
    }

    return transaction;
  });

const currentCombinationPayout = (value: string | undefined): number => {
  return Number(String(value).split('-')[2]) * Number(String(value).split('-')[4]);
};

export const getPotentialPayout = (additionalData: BetAction['additionalData']): number | undefined => {
  if (additionalData) {
    const combinations = Object.values(additionalData).map((value) => {
      return currentCombinationPayout(value);
    });
    const potentialPayout = combinations.reduce(function (previousValue, currentValue) {
      return previousValue + currentValue;
    });

    return potentialPayout;
  }
};
