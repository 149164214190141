import React, { Fragment, FC, useEffect, useRef, useState } from 'react';
import { Icon } from '../Icon';
import { IconButton } from '../IconButton';
import { Overlay, OverlayProps } from '../Overlay';
import { joinStrings } from '../../../utils/string';
import './styles';

export type TouchDrawerLocation = 'left' | 'bottom' | 'top' | undefined;

export type TouchDrawerMode = 'light' | 'dark';

export type CloseBtnVariant = 'outside' | 'inside';

export type TouchDrawerProps = {
  /**
   * Content that is to be rendered in the TouchDrawer viewport header section.
   */
  header?: React.ReactNode;
  /**
   * Content that is to be rendered in the TouchDrawer viewport body section.
   */
  children?: React.ReactNode;
  /**
   * Content that is to be rendered in the TouchDrawer viewport footer section.
   */
  footer?: React.ReactNode;
  /**
   * Location of where the touch drawer appears.
   */
  location?: TouchDrawerLocation;
  /**
   * Optional.  Defaults to false.  If true, the escape key can trigger the onClose event.
   */
  allowKeyboardInputs?: boolean;
  /**
   * Optional.  Defaults to false.
   * If true, clicking on the backdrop will close the viewport and trigger the onClose function.
   */
  backdropCanClose?: boolean;
  /**
   * Optional.  Defaults to false. If true, will display a close button on the top right corner.
   */
  showCloseBtn?: boolean;
  /**
   * Optional. Default "outside", will appear top right corner of window.
   * If "inside" close button will appear in top right corner of touch drawer ,
   */
  positionCloseBtn?: CloseBtnVariant;
  /**
   * Delay (in millisecond) from when the backdrop fades in and viewport slides into view.  Defaults to 300ms.
   */
  animationDelay?: number;
  /**
   * Threshold from what is considered "closed" on touch/mouse release.
   * For example, if set at 200, if the viewport is 200px from the bottom when touch/mouse is released
   * it will be considered "closed".
   * If no value is set, the threshold will be set at the viewport height / 2.
   */
  closeThreshold?: number | undefined;
  /**
   * If true, will display a close button on the top right corner.
   */
  show?: boolean;
  /**
   * Optional. If true, touch bar will render at the top of the component
   * Defaults to true
   */
  showTouchPad?: boolean;
  /**
   * Optional. Mode which TouchDrawer will use.
   */
  mode?: TouchDrawerMode;
  /**
   * element to have focus on load
   */
  elementToFocusOnOpen?: React.RefObject<HTMLElement>;
  /**
   * Function that is triggered when any of the following actions are applied:
   * 1.) the close button is clicked, the backdrop is clicked, or when the viewport threshold is crossed.
   */
  onClose?: () => void;
  /**
   * An alternative function that runs when clicking the close button specifically.
   * Example use case: when you want the X button to act as a back button without closing the modal.
   */
  onCloseBtn?: () => void;
  /**
   * Optional callback when drawer has finished closing animation
   */
  onCloseAnimationCompleted?: () => void;
  /**
   * Optional value for CSS property. Default is set to 'transform 0.2s ease-in-out'
   */
  transitionValue?: string;
  /**
   * Optional content that can be rendered below header
   */
  subTitle?: React.ReactNode;
} & Omit<OverlayProps, 'isOpen' | 'elementToFocusOnOpen'>;

/**
 A simple TouchDrawer component

  ### Simple Example
  ```tsx
<div style={{height: '400px', width: '100%'}}>
  <TouchDrawer>
    <h1 style={{ textAlign: 'center', fontSize: '24px', padding: '50px 0' }}>
      Hello World!
    </h1>
  </TouchDrawer>
</div>
  ```
 */

export const TouchDrawer: FC<TouchDrawerProps> = ({
  header,
  subTitle,
  children,
  footer,
  location = 'bottom',
  show = false,
  backdropCanClose = false,
  showCloseBtn = false,
  positionCloseBtn = 'outside',
  allowKeyboardInputs = false,
  animationDelay = 300,
  closeThreshold = 0,
  elementToFocusOnOpen,
  showTouchPad = true,
  className,
  mode = 'dark',
  transitionValue = 'transform 0.2s ease-in-out',
  backdropClassName,
  onClose,
  onCloseBtn,
  onCloseAnimationCompleted,
  ...overlayProps
}) => {
  const modalCloseButtonRef = useRef<HTMLButtonElement>(null);
  const [backdropFadeIn, setBackdropFadeIn] = useState<boolean | null>(false);
  const contentRef = useRef<HTMLDivElement>(null);

  // ready: is true when the drawer is being shown and its content is rendered
  const [ready, setReady] = useState<boolean | null>(false);

  // tracking: is true if the drawer/mouse/touch position is being tracked for dragging
  const [tracking, setTracking] = useState<boolean | null>(false);

  // active: is true if the drawer can be interacted with
  const [active, setActive] = useState<boolean | null>(true);

  // showing: is true if the drawer is open or opening
  const [showing, setShowing] = useState<boolean>(false);

  const [height, setHeight] = useState<number>(0);
  const [startY, setStartY] = useState<number | null>(null);
  const [yPos, setYPos] = useState<number | null>(null);

  const [width, setWidth] = useState<number>(0);
  const [startX, setStartX] = useState<number | null>(null);
  const [xPos, setXPos] = useState<number | null>(null);

  const [closeOnRelease, setCloseOnRelease] = useState<boolean>(false);
  const [threshold, setThreshold] = useState<number>(0);

  const ref = React.useRef<HTMLDivElement>(null);

  let showTimer: ReturnType<typeof setTimeout>;
  let closeTimer: ReturnType<typeof setTimeout>;
  let animationTimer: ReturnType<typeof setTimeout>;

  const isYCoordinates = location === 'bottom' || location === 'top';
  const isXCoordinates = location === 'left';

  const calcYPos = (): number => {
    /* istanbul ignore next */
    return !!yPos && !!startY ? (startY - yPos < 0 ? startY - yPos : yPos - startY) : 0;
  };

  const calcXPos = (): number => {
    /* istanbul ignore next */
    return !!xPos && !!startX ? (xPos - startX < 0 ? xPos - startX : 0) : 0;
  };

  const openDrawer = (): void => {
    setShowing(true);
    setReady(true);
    setActive(true);
  };

  const closeDrawer = (): void => {
    /* istanbul ignore else */
    if (active) {
      setActive(false);
      closeTimer = setTimeout(() => {
        setReady(false);
        setShowing(false);
        setBackdropFadeIn(false);
        setActive(true);
        onClose && show && onClose();
        clearTimeout(closeTimer);
        onCloseAnimationCompleted?.();
      }, animationDelay);
    }
  };

  const onKeyDown = (e: React.KeyboardEvent): void => {
    if (!allowKeyboardInputs) {
      e.preventDefault();

      return;
    }

    switch (e.key.toLowerCase()) {
      case 'escape':
        e.preventDefault();
        closeDrawer();
        break;
    }
  };

  const onRelease = (): void => {
    switch (true) {
      case isYCoordinates:
        /* istanbul ignore if */
        if (calcYPos() + height < threshold) {
          closeDrawer();
        }

        break;
      case isXCoordinates:
        /* istanbul ignore if */
        if (width + calcXPos() < threshold) {
          closeDrawer();
        }

        break;
    }

    setTracking(false);
    setYPos(null);
    setStartY(null);
    setXPos(null);
    setStartX(null);
  };

  const onMouseDown = (e: React.MouseEvent): void => {
    /* istanbul ignore else */
    if (active) {
      e.preventDefault();
      setTracking(true);
      switch (true) {
        case isYCoordinates:
          setStartY(e.clientY);
          break;
        case isXCoordinates:
          setStartX(e.clientX);
          break;
      }
    }
  };

  const onMouseMove = (e: React.MouseEvent): void => {
    if (tracking) {
      switch (true) {
        case isYCoordinates:
          setYPos(e.clientY);
          setCloseOnRelease(calcYPos() + height < threshold);
          break;
        case isXCoordinates:
          setXPos(e.clientX);
          setCloseOnRelease(calcXPos() + width < threshold);
          break;
      }
    }
  };

  const onMouseUp = (e: React.MouseEvent): void => {
    e.preventDefault();
    e.stopPropagation();
    onRelease();
  };

  const onTouchStart = (e: React.TouchEvent): void => {
    /* istanbul ignore else */
    if (active) {
      setTracking(true);
      switch (true) {
        case isYCoordinates:
          setStartY(e.touches[0].clientY);
          break;
        case isXCoordinates:
          setStartX(e.touches[0].clientX);
          break;
      }
    }
  };

  const onTouchMove = (e: React.TouchEvent): void => {
    /* istanbul ignore else */
    if (tracking) {
      e.preventDefault();
      switch (true) {
        case isYCoordinates:
          setYPos(e.touches[0].clientY);
          setCloseOnRelease(calcYPos() + height < threshold);
          break;
        case isXCoordinates:
          setXPos(e.touches[0].clientX);
          setCloseOnRelease(calcXPos() + width < threshold);
          break;
      }
    }
  };

  const onTouchEnd = (): void => {
    onRelease();
  };

  const viewportMode = (className: string): string => {
    return `${className} ${className}--${mode}`;
  };

  const viewportPosition = (): { transform: string; transition: string } => {
    switch (location) {
      case 'top':
        return !ready
          ? {
              transform: `translateY(-100%)`,
              transition: '0s',
            }
          : active
            ? {
                transform: `translateY(${calcYPos()}px)`,
                transition: tracking ? '0s' : transitionValue,
              }
            : {
                transform: `translateY(-100%)`,
                transition: transitionValue,
              };
      case 'bottom':
        return !ready
          ? {
              transform: `translateY(100%)`,
              transition: '0s',
            }
          : active
            ? {
                transform: `translateY(${Math.abs(calcYPos())}px)`,
                transition: tracking ? '0s' : transitionValue,
              }
            : {
                transform: `translateY(100%)`,
                transition: transitionValue,
              };
      case 'left':
        return !ready
          ? { transform: 'translateX(-100%)', transition: '0s' }
          : active
            ? {
                transform: `translateX(${calcXPos()}px)`,
                transition: tracking ? '0s' : transitionValue,
              }
            : {
                transform: `translateX(-100%)`,
                transition: transitionValue,
              };
    }
  };

  const closeButton = (): void => {
    if (onCloseBtn) {
      onCloseBtn();
    } else {
      onClose?.();
    }
  };

  const closeButtonTemplate = (positionCloseBtn: string): JSX.Element => {
    return (
      <div className="touch-drawer__close-btn">
        <IconButton
          ref={modalCloseButtonRef}
          onClick={closeButton}
          aria-label="Close"
          icon={<Icon variant="Close" />}
          data-testid="touch-drawer-close-btn"
          className={'touch-drawer__close-btn-' + positionCloseBtn}
          color="inherit"
          size={'large'}
        />
      </div>
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    switch (true) {
      case isYCoordinates:
        /* istanbul ignore if */
        if (ref.current && ref.current.clientHeight > height) {
          setHeight(ref.current.clientHeight);
          const threshold =
            closeThreshold === 0
              ? ref.current.clientHeight / 2
              : closeThreshold > ref.current.clientHeight
                ? ref.current.clientHeight - 20
                : closeThreshold;

          setThreshold(threshold);
        }

        break;
      case isXCoordinates:
        /* istanbul ignore if */
        if (ref.current && ref.current.clientWidth > width) {
          setWidth(ref.current.clientWidth);
          const threshold =
            closeThreshold === 0
              ? ref.current.clientWidth / 2
              : closeThreshold > ref.current.clientWidth
                ? ref.current.clientWidth - 20
                : closeThreshold;

          setThreshold(threshold);
        }

        break;
    }
  });

  useEffect(() => {
    setBackdropFadeIn(show);

    if (show) {
      setShowing(show);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      showTimer = setTimeout(openDrawer, animationDelay);
    } else {
      closeDrawer();
    }

    return () => {
      clearTimeout(showTimer);
      clearTimeout(closeTimer);
    };
  }, [show]);

  useEffect(() => {
    if (elementToFocusOnOpen?.current) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      animationTimer = setTimeout(() => {
        return elementToFocusOnOpen?.current?.focus();
      }, animationDelay);
    }

    return () => {
      clearTimeout(animationTimer);
    };
  }, [ready]);

  const isDisplayCloseBtn = showCloseBtn && active;

  return (
    <Overlay
      {...overlayProps}
      isOpen={showing}
      className={joinStrings(['touch-drawer', showing && 'touch-drawer--showing', className])}
      backdropCanClose={backdropCanClose}
      allowKeyboardInputs={allowKeyboardInputs}
      onRequestClose={onClose}
      elementToFocusOnOpen={elementToFocusOnOpen || modalCloseButtonRef}
      overflowTarget={contentRef.current}
      backdropClassName={joinStrings([
        'touch-drawer__backdrop',
        backdropCanClose && 'touch-drawer__backdrop--closeable',
        backdropFadeIn ? 'touch-drawer__backdrop--fade-in' : 'touch-drawer__backdrop--fade-out',
        backdropClassName,
      ])}
      aria-label="touch-drawer"
    >
      <div
        className={joinStrings([`touch-drawer__container`, `touch-drawer__container--${location}`])}
        data-testid="touch-drawer"
        aria-label="touch-drawer"
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        onTouchMove={(e): void => e.stopPropagation()}
        onClick={(e): void => e.stopPropagation()}
        onKeyDown={(e): void => {
          onKeyDown(e);
          e.stopPropagation();
        }}
        role="button"
        tabIndex={0}
      >
        {showing && (
          <Fragment>
            {isDisplayCloseBtn && positionCloseBtn === 'outside' && closeButtonTemplate(positionCloseBtn)}

            <div
              data-testid="touch-drawer-viewport"
              className={`${viewportMode('touch-drawer__viewport')} touch-drawer__viewport--${location}`}
              style={{
                ...viewportPosition(),
                pointerEvents: 'auto',
              }}
              role="dialog"
              aria-label="touch-drawer-viewport"
              aria-modal={showing}
              aria-hidden={!showing}
              ref={ref}
            >
              {showTouchPad && (
                <div
                  className={`touch-drawer__touchpad touch-drawer__touchpad--${location}`}
                  data-testid="touch-drawer-touchpad"
                  aria-label="touch-drawer-backdrop-touchpad"
                >
                  <div
                    className="touch-drawer__bars-wrapper"
                    aria-hidden="true"
                    onMouseDown={onMouseDown}
                    onTouchMove={onTouchMove}
                    onTouchStart={onTouchStart}
                    onTouchEnd={onTouchEnd}
                  >
                    <div className={`${viewportMode('touch-drawer__bars')} touch-drawer__bars--${location}`} />
                  </div>
                </div>
              )}

              {isDisplayCloseBtn && positionCloseBtn === 'inside' && closeButtonTemplate(positionCloseBtn)}

              {header && (
                <div className={`${viewportMode('touch-drawer__header')}`} data-testid="touch-drawer-header">
                  {header}
                </div>
              )}

              {subTitle && (
                <div className="touch-drawer__subtitle" data-testid="touch-drawer-subtitle">
                  {subTitle}
                </div>
              )}

              {ready && (
                <div
                  ref={contentRef}
                  className={`${viewportMode('touch-drawer__content')} touch-drawer__content--${location}`}
                  data-testid="touch-drawer-content"
                >
                  {children}
                </div>
              )}

              {footer && (
                <div className={`${viewportMode('touch-drawer__footer')}`} data-testid="touch-drawer-footer">
                  {footer}
                </div>
              )}
            </div>

            {ready && (
              <div
                className={`${joinStrings([
                  'touch-drawer__closing',
                  `touch-drawer__closing--${location}`,
                  closeOnRelease
                    ? /* istanbul ignore next  */
                      `touch-drawer__closing--${location}-show`
                    : `touch-drawer__closing--${location}-hide`,
                ])}`}
                data-testid="touch-drawer-closing"
              />
            )}
          </Fragment>
        )}
      </div>
    </Overlay>
  );
};
