import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SiteMenuGetQueryVariables = Types.Exact<{
  language: Types.Scalars['String']['input'];
}>;


export type SiteMenuGetQuery = { __typename?: 'Query', site_menu?: { __typename?: 'site_menu', menu_items?: Array<{ __typename?: 'site_menu_menu_item', menu_item_id?: { __typename?: 'menu_item', show_authenticated_only?: boolean | null, icon?: { __typename?: 'directus_files', filename_disk?: string | null, width?: number | null, height?: number | null, title?: string | null } | null, hover_icon?: { __typename?: 'directus_files', filename_disk?: string | null, width?: number | null, height?: number | null, title?: string | null } | null, translations?: Array<{ __typename?: 'menu_item_translations', link_text?: string | null, link_URL?: string | null, open_in_new_tab?: boolean | null } | null> | null } | null } | null> | null, lower_menu_items?: Array<{ __typename?: 'site_menu_menu_item_1', menu_item_id?: { __typename?: 'menu_item', show_authenticated_only?: boolean | null, icon?: { __typename?: 'directus_files', filename_disk?: string | null, width?: number | null, height?: number | null, title?: string | null } | null, hover_icon?: { __typename?: 'directus_files', filename_disk?: string | null, width?: number | null, height?: number | null, title?: string | null } | null, translations?: Array<{ __typename?: 'menu_item_translations', link_text?: string | null, link_URL?: string | null, open_in_new_tab?: boolean | null } | null> | null } | null } | null> | null } | null };


export const SiteMenuGetDocument = gql`
    query SiteMenuGet($language: String!) {
  site_menu {
    menu_items {
      menu_item_id(filter: {status: {_eq: "published"}}) {
        show_authenticated_only
        icon {
          filename_disk
          width
          height
          title
        }
        hover_icon {
          filename_disk
          width
          height
          title
        }
        translations(
          filter: {languages_language_code: {language_code: {_eq: $language}}}
        ) {
          link_text
          link_URL
          open_in_new_tab
        }
      }
    }
    lower_menu_items {
      menu_item_id(filter: {status: {_eq: "published"}}) {
        show_authenticated_only
        icon {
          filename_disk
          width
          height
          title
        }
        hover_icon {
          filename_disk
          width
          height
          title
        }
        translations(
          filter: {languages_language_code: {language_code: {_eq: $language}}}
        ) {
          link_text
          link_URL
          open_in_new_tab
        }
      }
    }
  }
}
    `;

/**
 * __useSiteMenuGetQuery__
 *
 * To run a query within a React component, call `useSiteMenuGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteMenuGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteMenuGetQuery({
 *   variables: {
 *      language: // value for 'language'
 *   },
 * });
 */
export function useSiteMenuGetQuery(baseOptions: Apollo.QueryHookOptions<SiteMenuGetQuery, SiteMenuGetQueryVariables> & ({ variables: SiteMenuGetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SiteMenuGetQuery, SiteMenuGetQueryVariables>(SiteMenuGetDocument, options);
      }
export function useSiteMenuGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SiteMenuGetQuery, SiteMenuGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SiteMenuGetQuery, SiteMenuGetQueryVariables>(SiteMenuGetDocument, options);
        }
export function useSiteMenuGetSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SiteMenuGetQuery, SiteMenuGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SiteMenuGetQuery, SiteMenuGetQueryVariables>(SiteMenuGetDocument, options);
        }
export type SiteMenuGetQueryHookResult = ReturnType<typeof useSiteMenuGetQuery>;
export type SiteMenuGetLazyQueryHookResult = ReturnType<typeof useSiteMenuGetLazyQuery>;
export type SiteMenuGetSuspenseQueryHookResult = ReturnType<typeof useSiteMenuGetSuspenseQuery>;
export type SiteMenuGetQueryResult = Apollo.QueryResult<SiteMenuGetQuery, SiteMenuGetQueryVariables>;