import uniq from 'lodash/uniq';
import React, { Fragment, FunctionComponent, ReactNode, RefObject } from 'react';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { Paragraph } from '../Paragraph';
import './FormErrorAlert.scss';

export type FormError = {
  /**
   * List of validation messages
   */
  messages: { message: string; id: string }[];
  /**
   * Field label
   */
  label?: string;

  /**
   * Optional but preferred way to obtain HTMLElement to focus on.
   */
  ref?: RefObject<HTMLInputElement | HTMLSelectElement>;
  /**
   * Required.  Will be used as back up to find HTMLElement to focus.
   */
  fieldName: string;
};

export type FormErrorAlertProps = {
  /**
   * Alert intro paragraph text
   */
  intro: string | ReactNode;

  /**
   * List of errors. Optional. Component will not render if no errors exist.
   */
  errors?: FormError[];
  /**
   * Optional callback. Invoked on error button click.
   */
  onClick?: (formError: FormError) => void;
};

/**
 * A component to display a summary of validation errors as an alert.
 * It will list any errors provided and focus upon inputs when user clicks a validation error description.
 *
 * TODO: Ideally should this use an AlertMessage component and render content within?
 *
 */
export const FormErrorAlert: FunctionComponent<FormErrorAlertProps> = ({ intro, errors, onClick }) => {
  const getElement = ({ ref, fieldName }: FormError): HTMLInputElement | HTMLSelectElement | HTMLElement => {
    // Prefer using react reference
    if (ref && ref.current) {
      return ref.current;
    }

    // Back up to find element by name
    const els = document.getElementsByName(fieldName);

    return els[0];
  };
  const onButtonClick = (formError: FormError): void => {
    const element = getElement(formError);

    /* istanbul ignore else */
    if (element) {
      element.scrollIntoView();
      element.focus();
    } else {
      console.error(`From control element not found with name: ${formError.fieldName}`);
    }

    onClick && onClick(formError);
  };

  return (
    <Fragment>
      {errors && errors.length > 0 && (
        <div role="alert" className="form-error-alert">
          <header className="form-error-alert__header" data-testid="alert-header">
            <Icon variant="Error" className="form-error-alert__icon" />
            <Paragraph>{intro}</Paragraph>
          </header>
          <ul className="form-error-alert__content">
            {errors.map((error) => (
              <Fragment key={error.fieldName}>
                {uniq(error.messages).map(({ message, id }) => (
                  <li key={id} className="form-error-alert__list-item">
                    <Button
                      id={id}
                      variant="text"
                      icon={<Icon variant="ChevronRight" />}
                      iconPosition="right"
                      onClick={(): void => onButtonClick(error)}
                      type="button"
                    >
                      {error.label && `${error.label}: `}
                      {message}
                    </Button>
                  </li>
                ))}
              </Fragment>
            ))}
          </ul>
        </div>
      )}
    </Fragment>
  );
};
