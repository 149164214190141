import { RefObject, useEffect } from 'react';
import cookie from 'js-cookie';
import throttle from 'lodash/throttle';
import { passiveOption } from '../../consts';

const { debug } = console;

/**
 * Persist horizontal scroll position for a scrollable element
 *
 * @param scrollRef React ref to the target scrollable element
 * @param storageName Unique name
 */
export function usePersistentHorizontalScroll<T extends HTMLElement>(
  scrollRef: RefObject<T>,
  storageName: string
): void {
  const scrollListener = throttle(
    (event: Event): void => {
      try {
        /**
         * Save horizontal scroll position as a cookie with a given name
         */
        cookie.set(storageName, String((event.target as T).scrollLeft));
      } catch (e) {
        debug(e);
      }
    },
    350,
    { leading: false, trailing: true }
  );

  useEffect(() => {
    try {
      if (scrollRef && scrollRef.current) {
        /**
         * Handle scroll event
         */
        scrollRef.current.addEventListener('scroll', scrollListener, passiveOption);

        /**
         * Restore horizontal scroll position
         */
        scrollRef.current.scrollLeft = parseInt(cookie.get(storageName) || '0');
      }
    } catch (e) {
      debug(e);
    }

    return () => {
      try {
        if (scrollRef && scrollRef.current) {
          /**
           * Cleanup scroll listener
           */
          // eslint-disable-next-line react-hooks/exhaustive-deps
          scrollRef.current.removeEventListener('scroll', scrollListener, passiveOption as EventListenerOptions);
        }
      } catch (e) {
        debug(e);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
