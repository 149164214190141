/* eslint-disable max-len */
import React, { ComponentPropsWithRef, forwardRef } from 'react';
import { LoadingIndicator } from '../LoadingIndicator';
import { joinStrings } from '../../../utils/string';
import './styles';

/**
 * As well as accepting these custom props, the button component will also accept all attributes native to the HTML Button element and apply them.
 */
// TODO: Solution for AT identifiers needed
export type ButtonVariant =
  | 'primary'
  | 'primary-alt'
  | 'secondary'
  | 'secondary-alt'
  | 'success'
  | 'text'
  | 'text-alt'
  | 'warning'
  | 'important'
  | 'transparent'
  | 'link'
  | 'lancebet-bet'
  | 'lancebet-boosted-odds';
export type ButtonSize = 'auto' | 'small' | 'medium' | 'large' | 'x-large' | 'unset';
export type ButtonProps = {
  /**
   * Which style type the button is. Optional and defaults to primary.
   * The 'alt' variants apply an alternate style suitable for use on dark backgrounds.
   */
  variant?: ButtonVariant;
  /**
   * Size of the button. Optional and defaults to medium. Note: All sizes display the same width for text buttons by default
   */
  size?: ButtonSize;
  /**
   * Optionally disable the button.
   *
   * This is provided by ComponentProps but it's added to note that it will
   * use the aria-disabled attribute rather than using HTMLInputElement.disable
   * for better accessibility.
   */
  disabled?: boolean;
  /**
   * Optional icon which is supported by text buttons.
   * Should utilise the Icon component e.g. <Icon variant='Close' />
   */
  icon?: React.ReactNode;
  /**
   * Optional position indicating where to display the icon in relation to the button text. Defaults to right
   */
  iconPosition?: 'right' | 'left';
  /**
   * Optional custom className.
   */
  className?: string;
  /**
   * declare HTML type the button tag should use.
   */
  type: 'button' | 'submit' | 'reset';
  /**
   * Optional whether the button is submitting and should display a spinner in place of the label
   */
  isSubmitting?: boolean;
  /**
   * Set wether disabled attribute is using
   */
  isExternalDisabled?: boolean;
  /**
   * Stretch the button to its maximum width of the parent container.
   */
  fullWidth?: boolean;
} & ComponentPropsWithRef<'button'>;

/**
 * Primary UI component for user interaction.
 * By default, a buttons accessible name is computed from any text content inside it, however it can also be provided with aria-labelledby or aria-label.
 * If a description of the button's function is present, use aria-describedby and set it to the ID of the element containing the description.
 */
const _Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant = 'primary',
      size = 'medium',
      disabled,
      onClick,
      icon,
      iconPosition,
      children,
      className,
      type,
      isSubmitting,
      isExternalDisabled,
      fullWidth,
      ...props
    },
    ref
  ) => {
    return (
      <button
        {...props}
        className={joinStrings([
          'button',
          `button--${variant}`,
          `button--${size}`,
          disabled && !isSubmitting && 'button--disabled',
          isSubmitting && 'button--submitting',
          fullWidth && 'button--full-width',
          className,
        ])}
        type={type}
        aria-disabled={disabled}
        {...(!disabled && onClick ? { onClick } : {})}
        {...(isExternalDisabled && { disabled })}
        ref={ref}
      >
        {isSubmitting && <LoadingIndicator />}
        <span className="button__container">
          {icon && iconPosition === 'left' && (
            <span aria-hidden="true" className="button__icon-left" data-testid="button-icon-left">
              {icon}
            </span>
          )}
          {children}
          {icon && (iconPosition === 'right' || !iconPosition) && (
            <span aria-hidden="true" className="button__icon-right" data-testid="button-icon-right">
              {icon}
            </span>
          )}
        </span>
      </button>
    );
  }
);

export const Button = React.memo(_Button);

_Button.displayName = 'Button';
