import { ApolloLink } from '@apollo/client';
import { getToken } from '../../utils/auth';

export const authAppEndpointMiddlewareLink = new ApolloLink((operation, forward) => {
  const token = getToken();

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'X-Tenant-Id': 'betzone_uk',
      authorization: token ? `Bearer ${token}` : null,
    },
  }));

  return forward(operation);
});

export const authBetzoneEndpointMiddlewareLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${process.env.REACT_APP_BETZONE_API_TOKEN}`,
    },
  }));

  return forward(operation);
});
