import React, { FunctionComponent, useState } from 'react';
import { Checkbox } from '../Checkbox';
import './FormControlSwitch.scss';

export type FormControlSwitchProps = {
  /**
   * Input you wish to toggle visibility of.
   */
  children: React.ReactNode | string;
  /**
   * Whether to show child input initially.  Defaults to false.
   */
  initialSwitchValue?: boolean;
  /**
   * Optional alternate content which will be shown when switch is off.
   */
  alternateContent?: React.ReactNode;
  /**
   * Aria Label for switch component
   */
  switchAriaLabel: string;
  /**
   * Can be used to trigger effects related to input visibility,
   * for example to trigger analytics events.
   */
  onSwitchChange?: (visible: boolean) => void;
};

/**
 * Toggle whether an input component is rendered via the switch component.
 *
 * ### Example
 *
 * ```tsx
 * <FormControlSwitch switchAriaLabel='Toggle Username Input'>
 *   <Input name='username' label='Username' />
 * </FormControlSwitch>
 * ```
 *
 */
export const FormControlSwitch: FunctionComponent<FormControlSwitchProps> = ({
  children,
  initialSwitchValue = false,
  alternateContent,
  switchAriaLabel,
  onSwitchChange,
}) => {
  const [showChildren, setShowChildren] = useState(initialSwitchValue);

  return (
    <div className="form-control-switch" data-testid="formControlSwitch">
      <div className="form-control-switch__children">{showChildren ? children : alternateContent}</div>
      <div className="form-control-switch__switch">
        <Checkbox
          aria-label={switchAriaLabel}
          variant="switch"
          checked={showChildren}
          onChange={(e): void => {
            const { checked } = e.target;

            setShowChildren(checked);
            onSwitchChange && onSwitchChange(checked);
          }}
        />
      </div>
    </div>
  );
};
