import React, { FunctionComponent } from 'react';

export const ClockIcon: FunctionComponent<{ outlined?: boolean }> = ({ outlined }) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="white" xmlns="http://www.w3.org/2000/svg">
    {outlined ? <circle cx="6" cy="6" r="5.5" stroke="black" /> : <circle cx="6" cy="6" r="6" fill="#FFFFFF" />}
    <path
      d="M8.01533 7.54298L6.23763 6.20971V3.49381C6.23763 3.22073 6.01689 3 5.74381 3C5.47073 3 5.25 3.22073 5.25 3.49381V6.45664C5.25 6.61219 5.32309 6.75886 5.44753 6.8517L7.42273 8.33311C7.51161 8.39977 7.61532 8.43187 7.71852 8.43187C7.86913 8.43187 8.01727 8.36421 8.11407 8.23385C8.27805 8.01605 8.2336 7.70644 8.01533 7.54298Z"
      fill="black"
    />
  </svg>
);
