import { AddressData } from '../../components/Registration/types';
import { LoqateFindResult } from './loqate.types';

export const extractAddressFromLabel = (searchResult: LoqateFindResult): AddressData => {
  if (!searchResult || searchResult.Type !== 'Address') {
    throw new Error('Unsupported loqate result type');
  }

  const [countryCode] = searchResult.Id.split('|');

  let line1, postCode;
  let town = '';

  switch (countryCode) {
    case 'DE':
      line1 = searchResult.Text;
      [postCode, town] = searchResult.Description.split(' ', 2);
      break;

    case 'GB': {
      line1 = searchResult.Text.replace(/((^|,)\s*[0-9-]+),/g, '$1');
      const searchResultAsWords = searchResult.Description.split(/\s+/);

      // Since the postal code is always the last two words, we split the words and take the last two as the postal code and the rest as the city
      postCode = searchResultAsWords.slice(-2).join('');
      town = searchResultAsWords.slice(0, -2).join(' ');
      break;
    }

    default:
      line1 = searchResult.Text.replace(/((^|,)\s*[0-9-]+),/g, '$1');
      [postCode, town = ''] = searchResult.Description.split(/,\s+/g).reverse();
  }

  return {
    addrStreetAddress: line1,
    addrLocality: town,
    addrPostalCode: postCode,
    addrCountryCode: countryCode,
    addrRegion: '',
  };
};
