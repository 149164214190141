import debounce from 'lodash/debounce';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useDispatch, useSelector } from 'react-redux';
import { locale } from '../../../consts';
import { MediaBreakpoint, useBreakpointInfo, useDeviceInfo, useWindowDimensions } from '../../../hooks/useDeviceInfo';
import { useFetchGames } from '../../../hooks/useFetchGames';
import { selectIsSearchBarOpen } from '../../../state/app';
import { setIsSearchBarOpen } from '../../../state/app/reducers';
import { transformArrayGameData } from '../../../utils/games/games.utils';
import { joinStrings } from '../../../utils/string';
import { RecommendedGameCarousel } from '../../Games';
import { GameCardContainerType } from '../../Games/GameCard/GameCard.types';
import { SimpleGameCard } from '../../Games/GameCard/SimpleGameCard/SimpleGameCard';
import { Game } from '../../Games/games.model';
import { GameSearchInputCarousel } from '../../Games/GameSearch';
import { Overlay } from '../../shared/Overlay';
import { GamesList } from './GamesList';
import { SearchBarInput } from './SearchBarInput';
import './styles';
import { useTranslations } from '../../../hooks/useTranslationsHelper';

interface SearchBarProps {
  isUsedOverlay?: boolean;
  children?: JSX.Element;
  closeGameSpinZoneSearch?: () => void;
  onCloseMenu?: () => void;
}

/**
 * Search games component which is used to search games.
 */
export const SearchBar: FC<SearchBarProps> = ({
  isUsedOverlay = true,
  children,
  onCloseMenu,
  closeGameSpinZoneSearch,
}) => {
  // TODO: remove onClickCapture on a big refactoring
  // onClickCapture prevents closing searchBar on input or results click (Removed in BZD-42)
  const auth = useAuth();
  const { t } = useTranslations();
  const dispatch = useDispatch();
  const isMobile = useBreakpointInfo([MediaBreakpoint.BELOW_MOBILE]);
  const isBelowTablet = useBreakpointInfo([MediaBreakpoint.BELOW_TABLET]);
  const { isMobileDevice, isTablet } = useDeviceInfo();
  const isSearchBarOpen = useSelector(selectIsSearchBarOpen);
  const { width, height } = useWindowDimensions();
  const isLandscapeMode = width > height;
  const recommendedTitle = auth?.isAuthenticated ? t('game.recommended.label') : t('game.featured.label');

  const [query, setQuery] = useState<string>('');
  const [data, setData] = useState<Game[] | null>();
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const [searchBoxLeftPosition, setSearchBoxLeftPosition] = useState<number>(0);
  const { searchGame, searchedGameQueryLoading } = useFetchGames({
    onSearchGameComplete: (data) => {
      const transformedData = transformArrayGameData(data.game);

      setData(transformedData);
    },
  });

  const searchInputRef = useRef<HTMLInputElement>(null);
  const searchContainerRef = useRef<HTMLDivElement>(null);

  async function handleDebounce(inputValue: string): Promise<void> {
    setData(null);

    if (inputValue.trim().length >= 3) {
      await searchGame({
        variables: {
          searchTerm: inputValue,
          language: locale,
        },
      });
    }
  }

  const debouncedRequest = useCallback(debounce(handleDebounce, 500), []);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setQuery(event.target.value);
    debouncedRequest(event.target.value);
  };

  const onClear = (): void => {
    setQuery('');
    setData(null);
  };

  const onClose = (): void => {
    !isMobile && searchInputRef?.current?.blur();
    dispatch(setIsSearchBarOpen(false));
    onClear();
  };

  const onFocus = (): void => {
    // Fix Firefox focus issue for Search field
    !isMobile && searchInputRef?.current?.focus();
    dispatch(setIsSearchBarOpen(true));
  };

  const searchBarProps = {
    searchInputRef,
    onChange,
    value: query,
    isLoading: searchedGameQueryLoading,
    isOpen: isSearchBarOpen,
    onClose,
    onFocus,
  };

  useEffect(() => {
    // On initial render, isOverlayVisible is false preventing Overlay from
    // rendering with searchContainerRef.current of null
    setOverlayVisible(true);
  }, []);

  useEffect(() => {
    if (!isBelowTablet && searchInputRef && searchInputRef.current) {
      setSearchBoxLeftPosition(searchInputRef?.current?.getBoundingClientRect().left);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBelowTablet, searchInputRef?.current?.getBoundingClientRect().left]);

  const customStyles = {
    content: {
      left: `${searchBoxLeftPosition}px`,
      width: `calc(100% - ${searchBoxLeftPosition + 60}px)`,
    },
  };

  return isUsedOverlay ? (
    <div ref={searchContainerRef} className="search-bar-container">
      <SearchBarInput theme="light" {...searchBarProps} />
      {isOverlayVisible && (
        <Overlay
          canDismiss
          isOpen={isSearchBarOpen}
          aria-label="search-bar-search-overlay-aria-label"
          shouldReturnFocusAfterClose={false}
          className={joinStrings([
            'search-results__overlay',
            isLandscapeMode && isMobileDevice && 'search-results__overlay--mobile-landscape',
          ])}
          backdropClassName={joinStrings([
            'search-results__backdrop',
            isLandscapeMode && isMobileDevice && 'search-results__backdrop--mobile-landscape',
          ])}
          onRequestClose={onClose}
          elementToFocusOnOpen={searchInputRef}
          role="dialog"
          style={!isBelowTablet ? customStyles : {}}
        >
          {data && data.length ? (
            isMobileDevice && !isTablet ? (
              <GamesList data={data} simpleMode onClose={onClose} />
            ) : (
              <GameSearchInputCarousel data={data} onClose={onClose} />
            )
          ) : (
            <RecommendedGameCarousel
              title={recommendedTitle}
              containerType={GameCardContainerType.Wide}
              visibleConfig={{
                desktop: 6,
                wide: 6,
                mobile: 2,
                tablet: 4,
              }}
              onClose={onClose}
            />
          )}
        </Overlay>
      )}
    </div>
  ) : (
    <div className="game-search">
      <SearchBarInput {...searchBarProps} shouldDrawCancel={false} onClose={closeGameSpinZoneSearch} />
      <div className="game-search__content">
        {query.length > 2 ? (
          <>
            {data && data.length ? (
              <div className="game-search__result">
                {data.map((game: Game) => (
                  <div className="game-search__card" key={game.gameId}>
                    <SimpleGameCard
                      game={game}
                      containerType={GameCardContainerType.Wide}
                      onClose={(): void => {
                        closeGameSpinZoneSearch?.();
                        onCloseMenu?.();
                      }}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div>{children}</div>
            )}
          </>
        ) : (
          <div>{children}</div>
        )}
      </div>
    </div>
  );
};
