import React, { FC } from 'react';

export const PresentIcon: FC = () => {
  return (
    <svg width="current" height="current" viewBox="0 0 72 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M61.6941 40.5625L53.8665 37.7967C53.6249 37.7031 53.335 37.6562 53.0453 37.6562H2.89072C1.53764 37.6562 0.474792 38.6873 0.474792 40V72.9688C0.474792 76.8592 3.71197 80 7.72256 80H56.041C60.0516 80 63.2888 76.8592 63.2888 72.9688V42.7655C63.2888 41.7813 62.6605 40.8906 61.6941 40.5625Z"
        fill="#FC1A40"
      />
      <path
        d="M63.2888 42.7655V72.9688C63.2888 76.8592 60.0516 80 56.041 80H31.8818V37.6562H53.0453C53.3352 37.6562 53.6251 37.7031 53.8665 37.7967L61.6941 40.5625C62.6605 40.8906 63.2888 41.7813 63.2888 42.7655Z"
        fill="#C60034"
      />
      <path
        d="M62.4194 15.2794C61.5982 12.045 59.1338 9.51388 55.7998 8.48248C52.5142 7.49794 48.9867 8.20123 46.426 10.4511L40.8209 15.2325L39.7098 8.06076C39.1783 4.77951 37.0039 2.01372 33.7668 0.701378C33.1386 0.466846 32.5105 0.279346 31.8823 0.185596C29.2248 -0.330029 26.4707 0.232471 24.2479 1.82638C21.3004 3.88888 19.8027 7.35763 20.2857 10.8262C20.8174 14.295 23.2333 17.2481 26.6638 18.42L31.8823 20.2481L38.3087 22.545C38.357 22.545 38.357 22.545 38.357 22.545L49.5186 26.5292C50.6297 26.9044 51.7894 27.092 52.9007 27.092C55.2686 27.092 57.5878 26.248 59.4239 24.6544C62.1295 22.3106 63.2892 18.7011 62.4194 15.2794Z"
        fill="#FE9923"
      />
      <path
        d="M59.4233 24.6513C57.5872 26.2449 55.2679 27.0889 52.9001 27.0889C51.789 27.0889 50.6293 26.9013 49.518 26.5261L38.3565 22.5419C38.3565 22.5419 38.3564 22.5419 38.3081 22.5419L31.8818 20.245V0.182373C32.5099 0.276279 33.1381 0.463623 33.7662 0.698155C37.0034 2.0105 39.1779 4.77628 39.7092 8.05753L40.8204 15.2293L46.4255 10.4478C48.9862 8.198 52.5136 7.49472 55.7993 8.47925C59.1332 9.51066 61.5975 12.0418 62.4189 15.2761C63.2888 18.698 62.1291 22.3075 59.4233 24.6513Z"
        fill="#FE8821"
      />
      <path
        d="M71.552 36.579L68.2664 45.3915C67.8797 46.329 66.9616 46.8915 65.9953 46.8915C65.7053 46.8915 65.4154 46.8446 65.174 46.751L40.1932 37.9385L37.9224 30.6729L31.8826 31.9853L26.5677 33.1571L1.58689 24.3446C0.330612 23.876 -0.297367 22.5165 0.137338 21.3446L3.47131 12.5324C4.09945 10.7512 5.45253 9.34462 7.192 8.5479C8.93146 7.75118 10.9124 7.65728 12.7485 8.31353L31.8827 15.0635L33.1875 15.5321C33.1875 15.5321 38.4057 24.7198 38.5508 24.7198C38.6476 24.7198 40.7252 23.5948 42.7546 22.5165C44.7834 21.4384 46.813 20.3135 46.813 20.3135L67.2032 27.5321C69.0393 28.1884 70.4889 29.501 71.3101 31.1885C72.1319 32.8759 72.2283 34.7979 71.552 36.579Z"
        fill="#FF3E75"
      />
      <path
        d="M71.5512 36.5772L68.2656 45.3897C67.8789 46.3272 66.9608 46.8897 65.9944 46.8897C65.7045 46.8897 65.4146 46.8429 65.1732 46.7493L40.1924 37.9368L37.9216 30.6711L31.8818 31.9835V15.0618L33.1865 15.5304C33.1865 15.5304 38.4048 24.718 38.5499 24.718C38.6467 24.718 40.7242 23.593 42.7536 22.5147C44.7826 21.4366 46.8122 20.3118 46.8122 20.3118L67.2024 27.5304C69.0385 28.1866 70.4881 29.4993 71.3093 31.1868C72.1311 32.8741 72.2275 34.7962 71.5512 36.5772Z"
        fill="#FC1A40"
      />
      <path d="M24.634 37.6555V79.9993H39.1296V37.6555H24.634Z" fill="#FCBF29" />
      <path
        d="M33.1864 15.5305L31.8816 18.9993L26.5668 33.1557L31.8816 35.0308L37.9215 37.1402L40.1923 37.9369L46.8122 20.3119L33.1864 15.5305Z"
        fill="#FCBF29"
      />
      <path d="M39.1318 37.6562H31.884V80H39.1318V37.6562Z" fill="#FE9923" />
      <path
        d="M46.8122 20.3119L40.1924 37.9369L37.9216 37.1401L31.8818 35.0307V18.9993L33.1864 15.5305L46.8122 20.3119Z"
        fill="#FE9923"
      />
    </svg>
  );
};
