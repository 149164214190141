import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StopRealityCheckMutationVariables = Types.Exact<{
  xTenantId: Types.Scalars['ID']['input'];
  userProfileId: Types.Scalars['ID']['input'];
  realityCheckId: Types.Scalars['ID']['input'];
}>;


export type StopRealityCheckMutation = { __typename?: 'Mutation', stopRealityCheck: { __typename?: 'RealityCheckStopResponse', data?: { __typename?: 'RealityCheckStopSuccess', id: string } | null, error?: Array<{ __typename?: 'FieldError', code: string } | { __typename?: 'GenericError' }> | null } };


export const StopRealityCheckDocument = gql`
    mutation StopRealityCheck($xTenantId: ID!, $userProfileId: ID!, $realityCheckId: ID!) {
  stopRealityCheck(
    xTenantID: $xTenantId
    userProfileId: $userProfileId
    realityCheckId: $realityCheckId
  ) {
    data {
      id
    }
    error {
      ... on FieldError {
        code
      }
    }
  }
}
    `;
export type StopRealityCheckMutationFn = Apollo.MutationFunction<StopRealityCheckMutation, StopRealityCheckMutationVariables>;

/**
 * __useStopRealityCheckMutation__
 *
 * To run a mutation, you first call `useStopRealityCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopRealityCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopRealityCheckMutation, { data, loading, error }] = useStopRealityCheckMutation({
 *   variables: {
 *      xTenantId: // value for 'xTenantId'
 *      userProfileId: // value for 'userProfileId'
 *      realityCheckId: // value for 'realityCheckId'
 *   },
 * });
 */
export function useStopRealityCheckMutation(baseOptions?: Apollo.MutationHookOptions<StopRealityCheckMutation, StopRealityCheckMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StopRealityCheckMutation, StopRealityCheckMutationVariables>(StopRealityCheckDocument, options);
      }
export type StopRealityCheckMutationHookResult = ReturnType<typeof useStopRealityCheckMutation>;
export type StopRealityCheckMutationResult = Apollo.MutationResult<StopRealityCheckMutation>;
export type StopRealityCheckMutationOptions = Apollo.BaseMutationOptions<StopRealityCheckMutation, StopRealityCheckMutationVariables>;