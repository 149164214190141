/* eslint-disable @typescript-eslint/ban-ts-comment */
import { AccountFieldSetConfig } from '../components/Account/types';
import { MarketingData, RegistrationFieldConfig, RegistrationFieldSetConfig } from '../components/Registration/types';
import { FlattenedProfileData } from '../hooks/registration';

type FieldType = RegistrationFieldConfig & { name: string };
type FieldSetType = RegistrationFieldSetConfig | AccountFieldSetConfig<FlattenedProfileData>;

export const getMarketingPreferences = (
  marketingObject: MarketingData,
  fieldSets: FieldSetType[]
): (FieldSetType & {
  fields: FieldType[];
})[] =>
  fieldSets.map((fieldSet) => {
    const { fields, ...otherData } = fieldSet;
    const newFields = (fields as FieldType[]).map((marketingPreferenceName) => {
      // @ts-ignore
      const marketingValue = marketingObject[marketingPreferenceName.name];

      return {
        ...marketingPreferenceName,
        hidden: marketingValue === undefined ? false : !marketingValue,
      };
    });

    return {
      ...otherData,
      fields: newFields,
    };
  });
