import React, { FunctionComponent, useEffect, useState } from 'react';
import { Icon } from '../../Icon/Icon';
import { IconButton } from '../../IconButton/IconButton';
import './TabularDatalistItem.scss';
import { Button } from '../../Button';
import { useSnackbar } from '../../../../hooks/snackbar';
import { joinStrings } from '../../../../utils/string';

type TabularDataListItemProps = {
  /**
   * Index, or order, that the listitem is in.
   */
  index: number;
  /**
   * Will be true if it's the final item in the parent dataset.
   */
  last: boolean;
  /**
   * See parent component for explanation.
   */
  clickablePanel?: boolean;
  /**
   * See parent component for explanation.
   */
  panel?: React.ReactNode;
  /**
   * See parent component for explanation.
   */
  content?: React.ReactNode;
  /**
   * See parent component for explanation.
   */
  onExpand?: (index: number, state: boolean) => void | undefined;
  isWithdrawPending?: boolean;
  remoteReference?: string;
  forceUpdate?: boolean;
  setForceUpdate?: (forceUpdate: boolean) => void;
  collapseItem?: boolean;
};

export const TabularDatalistItem: FunctionComponent<TabularDataListItemProps> = ({
  index,
  clickablePanel,
  panel,
  content,
  last,
  onExpand,
  isWithdrawPending,
  remoteReference,
  forceUpdate,
  setForceUpdate,
  collapseItem,
}) => {
  const [show, setShow] = useState<boolean>(false);

  const onClickEvent = (): void => {
    onExpand && onExpand(index, !show);
    setShow(!show);
  };

  const onKeydownEvent = (e: React.KeyboardEvent): void => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      onClickEvent();
    }
  };
  const { addSnack } = useSnackbar();

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (isWithdrawPending) {
      setShow(true);
    }
  }, [isWithdrawPending, isSubmitting]);

  useEffect(() => {
    setShow(false);
  }, [collapseItem]);

  const makeCancelWithdrawal = async (): Promise<void> => {
    try {
      setIsSubmitting(true);
      // Cancel Withdrawal here
      addSnack({
        type: 'success',
        theme: 'dark',
        message: 'Your withdrawal has been cancelled',
      });
    } catch (error) {
      addSnack({
        type: 'error',
        theme: 'dark',
        message: 'An error appeared during your request',
      });
    } finally {
      setForceUpdate && setForceUpdate(!forceUpdate);
      setIsSubmitting(false);
    }
  };

  const switchableCondition = content && isWithdrawPending;

  return (
    <li
      role="presentation"
      data-testid="tdl-item"
      className={joinStrings(['tdl-item', clickablePanel && 'tdl-item--clickable', last && 'tdl-item--last'])}
      onKeyDown={(e): void => {
        clickablePanel && onKeydownEvent(e);
      }}
      onClick={(): void => {
        clickablePanel && onClickEvent();
      }}
    >
      <div className={joinStrings(['tdl-item__block', show && 'tdl-item__block--show'])}>
        <div className="tdl-item__panel" data-testid="tdl-item-panel">
          {panel}
        </div>
        {content && !isWithdrawPending && (
          <div
            role="button"
            data-testid="tdl-item-expand-button"
            className="tdl-item__button"
            onKeyDown={onKeydownEvent}
            onClick={onClickEvent}
            tabIndex={0}
          >
            <IconButton aria-label="button" icon={<Icon variant={show ? 'ChevronUp' : 'ChevronDown'} />} />
          </div>
        )}
      </div>
      {content && (
        <div
          data-testid="tdl-item-content"
          className={joinStrings(['tdl-item__content', show && 'tdl-item__content--show'])}
        >
          {/* TODO:  Maybe look at abstracting this expansion panel out of both this and the accordian component */}
          {content}
        </div>
      )}
      {switchableCondition && (
        <div className="tdl-item__cancel-withdraw">
          <Button
            type="submit"
            variant="primary"
            isSubmitting={isSubmitting}
            onClick={(): void => {
              remoteReference && makeCancelWithdrawal();
            }}
          >
            CANCEL WITHDRAW
          </Button>
        </div>
      )}
    </li>
  );
};
