import React, { FunctionComponent } from 'react';
import { Game } from '../../games.model';
import { Chip } from '../../../shared/Chip';
import './styles';

type GameCategoriesProps = {
  game: Game;
};

export const GameTags: FunctionComponent<GameCategoriesProps> = ({ game }) => {
  return (
    <div className="game-tags">
      {game.tags?.map((tag) => {
        return <Chip key={tag?.name}>{tag?.name}</Chip>;
      })}
    </div>
  );
};
