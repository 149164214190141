import React, { FC, useEffect } from 'react';

import { ContentDataProps, MergedBonusItem } from '../../../../models/bonuses.model';

import { WelcomeBonusFixed } from './WelcomeBonusFixed';
import { WelcomeBonusVariable } from './WelcomeBonusVariable';
import { BonusConfigurationProps } from '../WelcomeBonusContent';
import { Paragraph } from '../../../shared/Paragraph';
import { Input } from '../../../shared/Input';
import { MAX_DEPOSIT_ALLOWED } from '../../../../consts';
import { bonusesMock, mergedBonusMock } from '../../../Layout/Promotions/mocks';
import { depositLimitsMock, welcomeOfferItem } from '../mocks/welcomeBonusMock';
import { DepositLimitsState } from '../types';

interface WelcomeBonusCalculatorProps {
  depositAmount?: string;
  setDepositAmount?: (param: string) => void;
  showTnC: () => void;
  imgPath?: string;
  imgAlt?: string;
  promoCode?: string;
  inputError?: string;
  setInputError?: (param: string) => void;
}

type ExtraImgData = {
  imgPath?: string;
  imgAlt?: string;
};

export const WelcomeBonusCalculator: FC<WelcomeBonusCalculatorProps & BonusConfigurationProps> = ({
  depositAmount = '',
  imgPath,
  imgAlt,
  setDepositAmount,
  showTnC,
  configuration,
  inputError,
  setInputError,
}) => {
  const bonuses = bonusesMock.items;
  const welcomeBonus: MergedBonusItem = mergedBonusMock;
  const dynamicWelcomeBonus: MergedBonusItem = mergedBonusMock;
  const { depositBonus } = welcomeOfferItem;
  const playerLimits: DepositLimitsState['player'] = depositLimitsMock.deposit.limits.player;

  // Searching for welcome bonus within bonuses list by promo code
  const wbFromBonusesList: ContentDataProps =
    bonuses.find((bonus) => welcomeBonus.promoCode === bonus?.promoCode) || {};

  const extraImgData: ExtraImgData = {
    imgPath: wbFromBonusesList?.imgPath,
    imgAlt: wbFromBonusesList?.imgAlt,
  };

  const imageSrc: string | undefined =
    imgPath || welcomeBonus?.imgPath || welcomeBonus?.images?.default?.url || extraImgData.imgPath;

  const imageAlt: string | undefined =
    imgAlt || welcomeBonus?.imgAlt || welcomeBonus?.images?.default?.alt || extraImgData.imgAlt;

  const remainingDailyAmount: number = playerLimits.dailyDeposit?.remainingAmount || MAX_DEPOSIT_ALLOWED;
  const remainingWeeklyAmount: number = playerLimits.weeklyDeposit?.remainingAmount || MAX_DEPOSIT_ALLOWED;
  const remainingMonthlyAmount: number = playerLimits.monthlyDeposit?.remainingAmount || MAX_DEPOSIT_ALLOWED;

  const minimumDeposit = configuration?.MinimumDeposit || depositBonus?.minimumDeposit || '';
  const maximumBonusAmount = configuration?.MaximumBonusAmount || depositBonus?.maximumBonusAmount || 1;
  const bonusPercentage = configuration?.BonusPercentage || depositBonus?.bonusPercentage || 1;
  const isVariableWelcomeBonus = Boolean(configuration?.IsPercentage || depositBonus?.bonusPercentage);
  const inputLabel = `Deposit ${minimumDeposit} or more`;

  const numbersOnly = (value: string): string => value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');

  const validateInput = (value: number): string | undefined => {
    // Less than minimum required deposit
    if (value < +minimumDeposit) {
      return welcomeBonus.failedParagraph || 'You have not reached the sufficient amount for bonus';
    }

    // More than platform maximum
    if (value > MAX_DEPOSIT_ALLOWED) {
      return `The maximum deposit is ${MAX_DEPOSIT_ALLOWED} please enter a lower amount't`;
    }

    // Daily limit
    if (+minimumDeposit > remainingDailyAmount) {
      return `You cannot claim this bonus as your remaining daily deposit limit is ${remainingDailyAmount}`;
    }

    if (value > remainingDailyAmount) {
      return `You cannot deposit more than ${remainingDailyAmount} due to your daily deposit limit, please enter a lower amount`;
    }

    // Weekly limit
    if (+minimumDeposit > remainingWeeklyAmount) {
      return `You cannot claim this bonus as your remaining weekly deposit limit is ${remainingWeeklyAmount}`;
    }

    if (value > remainingWeeklyAmount) {
      return `You cannot deposit more than ${remainingWeeklyAmount} due to your weekly deposit limit, please enter a lower amount`;
    }

    // Monthly limit
    if (+minimumDeposit > remainingMonthlyAmount) {
      return `You cannot claim this bonus as your remaining monthly deposit limit is ${remainingMonthlyAmount}`;
    }

    if (value > remainingMonthlyAmount) {
      return `You cannot deposit more than ${remainingMonthlyAmount} due to your monthly deposit limit, please enter a lower amount`;
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    const convertedValue = numbersOnly(value);

    setDepositAmount && setDepositAmount(convertedValue);

    if (!value || !validateInput(+value)) {
      setInputError && setInputError('');
    }
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
    const { value } = event.target;

    setInputError && setInputError(validateInput(+value) || '');
  };

  useEffect(() => {
    // TODO: Reset Welcome Bonus into store
  }, []);

  return (
    <>
      <div className="welcome-bonus__image-container">
        <img src={imageSrc} alt={imageAlt} />
      </div>
      {dynamicWelcomeBonus?.title}
      <div className="welcome-bonus__step">
        <span className="welcome-bonus__circle">1</span>
        <Paragraph size="md" noMargin>
          Enter the amount you wish to deposit
        </Paragraph>
      </div>
      <Input
        showFeedbackIcon={false}
        validationMessages={[{ id: 'id', message: inputError || '' }]}
        validationState={inputError && inputError.length ? 'error' : undefined}
        label={inputLabel}
        theme="light"
        value={depositAmount}
        onChange={onChange}
        onBlur={onBlur}
        inputMode="decimal"
      />
      {isVariableWelcomeBonus ? (
        <WelcomeBonusVariable
          depositAmount={0 || +depositAmount}
          minimumDeposit={+minimumDeposit}
          maximumBonusAmount={maximumBonusAmount}
          bonusPercentage={bonusPercentage}
        />
      ) : (
        <WelcomeBonusFixed minimumDeposit={String(minimumDeposit)} bonusAmount={welcomeBonus?.bonusAmount} />
      )}
      <button onClick={showTnC} className="welcome-bonus__TnC-button">
        See full terms and conditions
      </button>
    </>
  );
};
