import React, { ComponentPropsWithoutRef, FC, ReactNode, useContext } from 'react';
import { ModalContext } from '../ModalContext';
import { useYOverflow } from '../../../../hooks/document';
import { joinStrings } from '../../../../utils/string';
import './ModalBody.scss';

export type ModalBodyProps = {
  /**
   * Required. Modal body content
   */
  children: ReactNode;
  /**
   * Whether overflow classes should be enabled.  Defaults to true
   */
  withOverflow?: boolean;
} & ComponentPropsWithoutRef<'div'>;

/**
 * A component used to wrap main body content within a parent <Modal> component.
 *
 *
 * If overflow scrolling is enabled via css,
 * then ModalBody will observe this and add the class `modal-body--overflowing`.
 * It will also add `modal-body--scroll-start` if not scrolled and `modal-body--scroll-end` if scrolled to end position.
 *
 * Note that overflow-y auto/max height must be applied to the `modal-body__content` container for the above to work.
 */
export const ModalBody: FC<ModalBodyProps> = ({ className, children, withOverflow = true, ...props }) => {
  const { ref, isOverflowing, scrollPosition } = useYOverflow();
  const modalContext = useContext(ModalContext);

  return (
    <section
      {...props}
      className={joinStrings([
        'modal-body',
        withOverflow && isOverflowing && 'modal-body--overflowing',
        withOverflow && scrollPosition && `modal-body--scroll-${scrollPosition}`,
        className,
      ])}
    >
      <div
        ref={(e): void => {
          ref.current = e;
          withOverflow && modalContext.setOverflowTargetElement && e && modalContext.setOverflowTargetElement(e);
        }}
        className="modal-body__content"
      >
        {children}
      </div>
    </section>
  );
};
