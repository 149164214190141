import React, { FunctionComponent, useState } from 'react';
import { useSnackbar } from '../../../../hooks/snackbar';
import { Form, FormControl, FormFieldType } from '../../../shared/Form';
import { Heading } from '../../../shared/Heading';
import { Paragraph } from '../../../shared/Paragraph';

export type AutoWithdrawalFormProps = {
  /**
   * Callback for on form submit success
   */
  onSubmit: (newValue: string | number | null) => void;

  /**
   * Callback for focus on form
   */
  onFormControlFocus?: () => void;

  /**
   * Disable interactions in the view
   */
  disabled?: boolean;
};

export type AutoWithdrawalFormData = {
  newValue?: string | null;
};

export const AutoWithdrawalForm: FunctionComponent<AutoWithdrawalFormProps> = ({
  onSubmit,
  onFormControlFocus,
  disabled,
}) => {
  const { addSnack } = useSnackbar();

  const [isProcessing, setProcessing] = useState<boolean>(false);
  const isDisabled = disabled || isProcessing;

  // Loop through translations to create paragraphs
  const paragraphs = (translations: string[]): JSX.Element[] => {
    const items = [];

    for (let i = 1; translations[i]; i++) {
      items.push(<Paragraph key={i}>{`${translations[i]}`}</Paragraph>);
    }

    return items;
  };

  // Update the auto withdrawal limit
  async function setAutoWithdrawalLimit(formData: AutoWithdrawalFormData): Promise<void> {
    const limitValue = formData.newValue as string;
    const limitValueNumber = parseFloat(limitValue);

    setProcessing(true);

    try {
      console.log('setPlayerLimits');

      addSnack({
        type: 'success',
        message: `Auto-Withdrawal amount set to ${limitValueNumber}.`,
      });

      setProcessing(false);
      onSubmit && onSubmit(limitValue);
    } catch {
      addSnack({
        type: 'error',
        message: `An error occured setting Auto-Withdrawal to ${limitValueNumber}.`,
      });

      setProcessing(false);
    }
  }

  return (
    <Form
      id="auto-withdrawal-form"
      className="auto-withdrawal-form"
      onSubmit={(data: AutoWithdrawalFormData): void => {
        /* istanbul ignore else */
        if (!isDisabled) {
          setAutoWithdrawalLimit(data);
        }
      }}
      defaultValues={{ newValue: '' }}
      FormActions={(): null => null}
    >
      {paragraphs([
        'If you wish to set up automatic withdrawals, select an amount below and any winning spins that exceed this threshold will be deducted from your current balance and sent to our processing team for automatic withdrawal.',
      ])}
      <FormControl
        onFormControlFocus={onFormControlFocus}
        type={FormFieldType.Select}
        name="newValue"
        label="Auto-Withdrawal amount"
        rules={{
          required: {
            value: true,
            message: 'You need to select an option',
          },
        }}
        options={[250, 500, 750, 1000].map((option) => {
          return {
            value: `${option}`,
            label: String(option),
          };
        })}
        disabled={isDisabled}
        props={{
          labelProps: { labelVariant: 'floating' },
        }}
      />

      <Heading level={4}>How it works</Heading>
      {paragraphs([
        'For example, if you have set an automatic withdrawal amount of £500 and you have a winning spin of £490, no funds will be withdrawn, whereas if you have a winning spin of £510 the full £510 will be automatically withdrawn (as this win exceeds the threshold of £500).',
        'This automatic withdrawal amount can be changed at any time and will be applied immediately. ',
        'Automatic withdrawals will be paid to your last valid payment method. The funds may take a little longer than usual to reach your account as they need to go through an internal review process, but they should be with you in a few days.',
      ])}
    </Form>
  );
};
