import React, { FC, useEffect } from 'react';
import noop from 'lodash/noop';
import { PopUpHandler } from '../../../shared/PopupHandler';
import { Paragraph } from '../../../shared/Paragraph';

export type HowItWorksModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onShow?: () => void;
};

export const HowItWorksModal: FC<HowItWorksModalProps> = ({ isOpen, onClose, onShow = noop }) => {
  useEffect(() => {
    if (isOpen) {
      onShow();
    }
  }, [isOpen]);

  return (
    <PopUpHandler
      isOpen={isOpen}
      onClose={onClose}
      header="How it works"
      drawerProps={{ backdropCanClose: true }}
      headingProps={{ headingLevel: 5 }}
      modalProps={{
        'aria-label': 'How it works',
      }}
    >
      <Paragraph size="sm">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. At quam adipiscing diam, integer est enim tincidunt.
        Fermentum, aliquam tellus aenean interdum tempus. Tincidunt massa nascetur amet sit egestas sagittis, aenean.
        Vitae vitae tempor vulputate netus commodo elementum, fringilla purus auctor.
      </Paragraph>
      <Paragraph size="sm">
        In et, vulputate id fermentum et. Scelerisque rhoncus bibendum fusce nisl. Pretium proin cras nunc quam urna
        suspendisse. Sapien pulvinar maecenas placerat mattis aenean elit, venenatis mi in. Egestas tortor aenean
        faucibus ligula id vitae nec senectus etiam. Ultrices malesuada nam nec vestibulum velit ac. Sagittis dictum
        accumsan habitasse bibendum suscipit velit tincidunt scelerisque aliquet. Semper consequat et semper scelerisque
        tempor dictumst imperdiet nisl.
      </Paragraph>
    </PopUpHandler>
  );
};
