import React, { ChangeEvent, FocusEvent, forwardRef, KeyboardEvent, useRef, useState } from 'react';
import { Input, InputProps } from '../Input';
import { formatCurrency, localeParts, parseLocaleNumber } from '../../../utils/wallets';

export type CurrencyInputProps = {
  /**
   * Invoked with a number string (eg '1234.56') when either the input changes or null when it is empty.
   */
  onChange?: (value: null | string) => void;
} & Omit<InputProps, 'onChange'>;

export const CurrencyInput = forwardRef<HTMLInputElement, CurrencyInputProps>(
  ({ value = '', onChange, onBlur, onKeyDown, ...inputProps }, ref) => {
    const { decimal, currency, symbolPosition } = localeParts();

    const nonNumberChars = new RegExp(`[^0-9\\${decimal}]`, 'g');
    const onlyNumberChars = new RegExp(`[0-9\\${decimal}]`, 'g');

    // Use passed in fwd ref or generate a new one
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const myRef = !ref || typeof ref === 'function' ? useRef<HTMLInputElement>(null) : /* istanbul ignore next */ ref;

    const formatValue = (value: string): string => {
      if (value) {
        return formatCurrency(parseFloat(value), 'auto', 'auto') as string;
      }

      return '';
    };

    const [deleteDirection, setDeleteDirection] = useState<number>(0);
    const [baseValue, setBaseValue] = useState<string>(value);
    const [formattedValue, setFormattedValue] = useState<string>(formatValue(baseValue));
    const [caretIndex, setCaretIndex] = useState<number | null>(null);

    // Get start and end of the number string inside the formatted string
    // EG "£1,234.56" "1234" returns start:1, end:6
    const getNumberBoundry = (
      str: string,
      numStr: string
    ): {
      start: number;
      end: number;
    } => {
      let start = 0;
      let end = str.length;

      if (str && Number.isInteger(parseFloat(numStr))) {
        const firstChar = numStr.split('')[0];
        const lastChar = numStr.split('')[numStr.length - 1];

        start = str.indexOf(firstChar);
        end = str.lastIndexOf(lastChar) + 1;
      }

      return {
        start,
        end,
      };
    };

    // Remove part of the string at index
    const deleteFromString = (str: string, startIndex: number): string => {
      return str.substr(0, startIndex) + str.substr(startIndex + 1, str.length);
    };

    // Returns true if a non numeric/decimal character was deleted
    const isNonNumDel = (value: string, caret: number): boolean => {
      const isSingle = value.length - formattedValue.length === -1;
      const isDeletion = deleteDirection !== 0;
      const isNonNumeric = formattedValue.substring(caret, caret + 1).match(nonNumberChars);

      return !!(isSingle && isDeletion && isNonNumeric);
    };

    // Format input value for display and correct input
    const formatInputValue = (value: string, caret: number): string => {
      let newString = value;

      // If a non number or decimal was deleted, delete intended number instead
      if (isNonNumDel(value, caret)) {
        // Split at caret
        let splitStart;
        let splitEnd;

        if (deleteDirection < 0) {
          splitStart = 0;
          splitEnd = caret;
        } else {
          splitStart = caret;
          splitEnd = newString.length;
        }

        const splited = newString.substr(splitStart, splitEnd);

        // Get the index of intended deleted number
        let indexToDelete =
          deleteDirection < 0
            ? splited.split('').reverse().join('').search(onlyNumberChars)
            : splited.search(onlyNumberChars);

        // Get correct index for backspace
        if (deleteDirection < 0) {
          indexToDelete = splited.length - 1 - indexToDelete;
        }

        newString = deleteFromString(newString, splitStart + indexToDelete);
      }

      // Strip everything before the currency symbol if its at the start
      if (symbolPosition === 0 && newString.indexOf(currency) >= 0) {
        newString = newString.substring(newString.indexOf(currency));
      }

      // Strip everything but numbers and decimal
      newString = newString.replace(nonNumberChars, '');

      // Split at first decimal index
      const splitDecimal = newString.split(decimal, 2);

      // Format before decimal
      let numParsed = parseFloat(splitDecimal[0]);

      // Prevent formatting to empty if a decimal exists
      if (isNaN(numParsed) && splitDecimal[1]) {
        numParsed = 0;
      }

      newString = isNaN(numParsed) ? '' : (formatCurrency(numParsed, 'auto', 'auto') as string);

      // Format the decimal to two places
      if (typeof splitDecimal[1] !== 'undefined') {
        const { end } = getNumberBoundry(newString, `${numParsed}`);

        newString = newString.slice(0, end) + decimal + splitDecimal[1].slice(0, 2) + newString.slice(end);
      }

      return newString;
    };

    return (
      <Input
        {...inputProps}
        ref={typeof ref === 'function' ? (node): void => ref(node) : myRef}
        autoComplete="off"
        onChange={(e: ChangeEvent<HTMLInputElement>): void => {
          e.stopPropagation();

          const {
            target: { value, selectionStart },
          } = e;
          /* istanbul ignore next */
          const caret = selectionStart === null ? 0 : selectionStart;

          // Format input value
          const newValue = formatInputValue(value, caret);
          const lengthDifference = newValue.length - value.length;

          // Parse value to number
          const dataValue = parseLocaleNumber(newValue);

          let newCaret = caret;

          // Keep carret at end of valid characters
          if (value.length === caret) {
            // Use last input index for carat
            const newNumbers = newValue.replace(nonNumberChars, '');

            newCaret = getNumberBoundry(newValue, newNumbers).end;
            // Reset caret if value hasn't changed after formatting
          } else if (formattedValue === newValue && caret) {
            newCaret = caret + lengthDifference;

            // Position caret after change for backspace and input
            // Not needed for forward deletion
          } else if (deleteDirection === -1 || deleteDirection === 0) {
            newCaret = newCaret + lengthDifference;
          }

          setCaretIndex(newCaret + 1);

          // Reset on empty/invalid input
          if (newValue === null || newValue === undefined || !newValue.length || isNaN(dataValue)) {
            setBaseValue('');
            setFormattedValue('');

            onChange && onChange(null);

            // Set new values
          } else {
            setBaseValue(`${dataValue}`);
            setFormattedValue(newValue);

            onChange && onChange(`${dataValue}`);
          }
        }}
        onBlur={(e: FocusEvent<HTMLInputElement>): void => {
          setFormattedValue(formatValue(baseValue));

          onBlur && onBlur(e);
        }}
        onRender={(): void => {
          // Fix caret position after formatting and render
          if (caretIndex && myRef.current) {
            myRef.current.setSelectionRange(caretIndex, caretIndex - 1);
            setCaretIndex(null);
          }
        }}
        onKeyDown={(e: KeyboardEvent<HTMLInputElement>): void => {
          let direction = 0;

          if (e.key === 'Backspace' || e.keyCode === 8) {
            direction = -1;
          } else if (e.key === 'Delete' || e.keyCode === 46) {
            direction = 1;
          }

          setDeleteDirection(direction);

          onKeyDown && onKeyDown(e);
        }}
        value={formattedValue}
      />
    );
  }
);

CurrencyInput.displayName = 'CurrencyInput';
