const supportedHashFormat = /^#[A-Za-z]+[\w\-:.]*$/;
const supportedElementIdFormat = /^[A-Za-z]+[\w\-:.]*$/;

export const hashIsValidAsScrollTarget = (hash: string): boolean => supportedHashFormat.test(hash);

export const tryScrollingToElementWithId = (id: string, smoothScroll = true): boolean => {
  if (!supportedElementIdFormat.test(id)) {
    console.warn(`Element with id '${id}' has invalid format`);

    return false;
  }

  // eslint-disable-next-line no-restricted-globals
  const target = self.document?.getElementById(id);

  if (!target) {
    return false;
  }

  const header = document.querySelector('.header-container');
  const headerOffset = header ? header.getBoundingClientRect().height : 0;
  const scrollingOffset = target.getBoundingClientRect().top - headerOffset;

  if (scrollingOffset !== 0) {
    window.scrollBy({
      top: scrollingOffset,
      left: 0,
      behavior: smoothScroll ? 'smooth' : undefined,
    });
  }

  return true;
};

export function getOuterHeight<E extends HTMLElement = HTMLDivElement>(el?: E | null): number {
  if (el) {
    const { marginTop, marginBottom } = window.getComputedStyle(el);

    return (
      el.offsetHeight +
      (parseInt(marginTop) /* istanbul ignore next */ || 0) +
      (parseInt(marginBottom) /* istanbul ignore next */ || 0)
    );
  }

  return 0;
}

export function getOuterWidth<E extends HTMLElement = HTMLDivElement>(el?: E | null): number {
  if (el) {
    const { marginLeft, marginRight } = window.getComputedStyle(el);

    return (
      el.offsetWidth +
      (parseInt(marginLeft) /* istanbul ignore next */ || 0) +
      (parseInt(marginRight) /* istanbul ignore next */ || 0)
    );
  }

  return 0;
}
