import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { useAuth } from 'react-oidc-context';
import { ApolloError } from '@apollo/client';
import { xTenantId } from '../../consts';
import { useUserProfileLazyQuery } from '../../libs/graphql/baseAppAPI/queries/__generated__/user-profile.query.generated';
import { UserCompliance, UserConsents, UserProfileResponse } from '../../hooks/registration';

interface Props {
  children: React.ReactNode;
}

interface ContextValue {
  fetching: boolean;
  error: ApolloError | undefined;
  user: UserProfileResponse | undefined | null;
  consents: UserConsents | undefined | null;
  compliance: UserCompliance | undefined | null;
  userProfileId: string;
  fetchUserProfile: () => Promise<void>;
}

const initialValue: ContextValue = {
  fetching: false,
  user: undefined,
  error: undefined,
  compliance: undefined,
  consents: undefined,
  userProfileId: '',
  fetchUserProfile: async () => undefined,
};

const UserContext = React.createContext(initialValue);

export const useUserContext = (): ContextValue => useContext(UserContext);

export const UserProvider: FC<Props> = ({ children }) => {
  const auth = useAuth();
  const accessToken = auth?.user?.access_token || '';

  const [getUserProfile, { data, loading, error }] = useUserProfileLazyQuery({ fetchPolicy: 'network-only' });

  const fetchUserProfile = useCallback(async (): Promise<void> => {
    await getUserProfile({
      variables: {
        userProfileId: (auth.user?.profile?.user_profile_id as string) || '',
        xTenantId,
      },
    });
  }, [auth.user?.profile?.user_profile_id, getUserProfile]);

  useEffect(() => {
    if (auth?.isAuthenticated && accessToken && !data?.userProfile.user) {
      fetchUserProfile();
    }
  }, [auth?.isAuthenticated, accessToken, fetchUserProfile, data?.userProfile.user]);

  const value: ContextValue = useMemo(() => {
    return {
      fetching: loading,
      user: data?.userProfile.user,
      consents: data?.userProfile.consents,
      compliance: data?.userProfile.compliance,
      userProfileId: (auth.user?.profile?.user_profile_id as string) || '',
      error,
      fetchUserProfile,
    };
  }, [data, loading, auth.user?.profile?.user_profile_id, error, fetchUserProfile]);

  return <UserContext.Provider value={auth.isAuthenticated ? value : initialValue}>{children}</UserContext.Provider>;
};
