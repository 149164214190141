import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ContinueRealityCheckMutationVariables = Types.Exact<{
  xTenantId: Types.Scalars['ID']['input'];
  userProfileId: Types.Scalars['ID']['input'];
  realityCheckId: Types.Scalars['ID']['input'];
}>;


export type ContinueRealityCheckMutation = { __typename?: 'Mutation', continueRealityCheck: { __typename?: 'RealityCheckContinueResponse', data?: { __typename?: 'RealityCheckContinueSuccess', id: string } | null, error?: Array<{ __typename?: 'FieldError' } | { __typename?: 'GenericError', code: string }> | null } };


export const ContinueRealityCheckDocument = gql`
    mutation ContinueRealityCheck($xTenantId: ID!, $userProfileId: ID!, $realityCheckId: ID!) {
  continueRealityCheck(
    xTenantID: $xTenantId
    userProfileId: $userProfileId
    realityCheckId: $realityCheckId
  ) {
    data {
      id
    }
    error {
      ... on GenericError {
        code
      }
    }
  }
}
    `;
export type ContinueRealityCheckMutationFn = Apollo.MutationFunction<ContinueRealityCheckMutation, ContinueRealityCheckMutationVariables>;

/**
 * __useContinueRealityCheckMutation__
 *
 * To run a mutation, you first call `useContinueRealityCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContinueRealityCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [continueRealityCheckMutation, { data, loading, error }] = useContinueRealityCheckMutation({
 *   variables: {
 *      xTenantId: // value for 'xTenantId'
 *      userProfileId: // value for 'userProfileId'
 *      realityCheckId: // value for 'realityCheckId'
 *   },
 * });
 */
export function useContinueRealityCheckMutation(baseOptions?: Apollo.MutationHookOptions<ContinueRealityCheckMutation, ContinueRealityCheckMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ContinueRealityCheckMutation, ContinueRealityCheckMutationVariables>(ContinueRealityCheckDocument, options);
      }
export type ContinueRealityCheckMutationHookResult = ReturnType<typeof useContinueRealityCheckMutation>;
export type ContinueRealityCheckMutationResult = Apollo.MutationResult<ContinueRealityCheckMutation>;
export type ContinueRealityCheckMutationOptions = Apollo.BaseMutationOptions<ContinueRealityCheckMutation, ContinueRealityCheckMutationVariables>;