import { IPlayerProps, Player } from '@lottiefiles/react-lottie-player';
import React from 'react';
import animationData from '../../../../assets/lotties/completed.json';

type Identity<T> = { [P in keyof T]: T[P] };
type Remove<T, K extends keyof T> = Identity<Pick<T, Exclude<keyof T, K>>>;

/**
 * Taking the same type as the player component, but removing src as an option as we define it here...
 */
type AsyncIPlayerProps = Remove<IPlayerProps, 'src'>;

const CompletedAnimation = (props: AsyncIPlayerProps): JSX.Element => <Player {...props} src={animationData} />;

export default CompletedAnimation;
