import React, { FC, Suspense, useRef } from 'react';
import Div100vh from 'react-div-100vh';
import { useAuth } from 'react-oidc-context';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { animated, useTransition } from 'react-spring';
import { APP_URLS } from '../../../consts';
import { GamblingControls } from '../../GamblingControls/GamblingControls';
import { SideDrawer } from '../../shared/SideDrawer';
import { Spinner } from '../../shared/Spinner';
import { AccountPageWrapperProps } from './AccountPageWrapper';
import { MyAccountHome } from './MyAccountHome';
import MyAccountSettings from './MyAccountSettings';
import { MyProfileSetting } from './MyProfile';
import MyAccountActivity from '../MyAccountActivity';
import './MyAccount.scss';

type MyAccountRoutesProps = {
  /**
   * Function to navigate to homepage
   */
  onGoToHome: (withRefresh: boolean) => void;
} & Pick<AccountPageWrapperProps, 'elementToFocusOnOpen' | 'onClose'>;

export const MyAccountRoutes: FC<MyAccountRoutesProps> = ({ onClose, elementToFocusOnOpen, onGoToHome }) => {
  const auth = useAuth();
  const location = useLocation();

  const routeTransitions = useTransition(location, (location) => location.pathname, {
    from: (location) => {
      return {
        transform: `translate3d(${location.pathname === APP_URLS.myAccount.home ? '-100%' : '100%'},0,0)`,
      };
    },
    enter: { transform: 'translate3d(0%,0,0)' },
    leave: (location) => {
      return {
        transform: `translate3d(${location.pathname === APP_URLS.myAccount.home ? '-100%' : '100%'},0,0)`,
      };
    },
    initial: { transform: 'translate3d(0%,0,0)' },
    config: { duration: 200 },
  });

  const handleClose = (): void => {
    onClose(true);
  };

  return (
    <>
      {routeTransitions.map(({ item: transitionLocation, props, key }) => (
        <animated.div key={key} style={props} className="my-account__route">
          <Div100vh>
            <Routes location={transitionLocation}>
              <Route path={APP_URLS.myAccount.home}>
                <Route
                  index
                  element={
                    <Suspense fallback={<Spinner />}>
                      <MyAccountHome
                        elementToFocusOnOpen={elementToFocusOnOpen}
                        firstName={auth.user?.profile.given_name}
                        onClose={handleClose}
                        onGoToHome={onGoToHome}
                      />
                    </Suspense>
                  }
                />
                <Route
                  path={APP_URLS.myAccount.activity}
                  element={<MyAccountActivity elementToFocusOnOpen={elementToFocusOnOpen} onClose={handleClose} />}
                />
                <Route
                  path={APP_URLS.myAccount.settings}
                  element={
                    <MyAccountSettings
                      onClose={handleClose}
                      onGoToHome={onGoToHome}
                      elementToFocusOnOpen={elementToFocusOnOpen}
                    />
                  }
                />
                <Route
                  path={APP_URLS.myAccount.profile}
                  element={
                    <MyProfileSetting
                      {...{
                        elementToFocusOnOpen,
                        onClose: handleClose,
                      }}
                    />
                  }
                />
                <Route
                  path={APP_URLS.myAccount.gamblingControls}
                  element={
                    <GamblingControls
                      elementToFocusOnOpen={elementToFocusOnOpen}
                      onClose={handleClose}
                      onGoToHome={onGoToHome}
                    />
                  }
                />
              </Route>
            </Routes>
          </Div100vh>
        </animated.div>
      ))}
    </>
  );
};

export type MyAccountProps = {
  /**
   * Whether the account drawer is open or closed
   */
  isOpen: boolean;
  /**
   * Callback that's executed when the backdrop/close button is clicked
   */
  onClose: () => void;
};

/**
 * My Account SideDrawer that will open if the myAccount query param is set to true & user is authenticated.
 * Internally, it uses HashRouter to hold the individual routes like 'profile' and 'my activity'
 * To create a link to these routes, you would use `<RouterLink to='/profile'>Profile</RouterLink>` inside the HashRouter.
 */
export const MyAccount: FC<MyAccountProps> = ({ isOpen, onClose }) => {
  const elementToFocusOnOpen = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();

  function handleGoToHome(withRefresh: boolean): void {
    navigate(APP_URLS.home);

    if (withRefresh) {
      window.location.reload();
    }
  }

  return (
    <>
      <SideDrawer
        aria-label="my-account-drawer"
        isOpen={isOpen}
        onRequestClose={onClose}
        elementToFocusOnOpen={elementToFocusOnOpen}
        className="my-account"
      >
        <MyAccountRoutes onClose={onClose} elementToFocusOnOpen={elementToFocusOnOpen} onGoToHome={handleGoToHome} />
      </SideDrawer>
    </>
  );
};
