import noop from 'lodash/fp/noop';
import React, { FunctionComponent } from 'react';
import { Heading, HeadingProps } from '../Heading';
import { Link } from '../Link';
import { Paragraph } from '../Paragraph';
import { CTAPanelProps } from './CTAPanel.types';
import './CTAPanel.scss';

const defaultHeadingProps: HeadingProps = { level: 5 };

/**
 * Pretty simple CTA panel component.
 */
export const CTAPanel: FunctionComponent<CTAPanelProps> = ({
  background = 'var(--grey-300)',
  borderRadius = 's',
  ctaLabel,
  ctaURL,
  headingProps,
  heading,
  children: body,
  width = 'fixed',
  paragraphColor,
  headingColor,
  ctaButtonSize = 'large',
  orientation = 'auto',
  onClick = noop,
}) => (
  <div
    className={`cta-panel cta-panel--border-radius-${borderRadius} cta-panel--width-${width} cta-panel--orientation-${orientation}`}
    style={{ background }}
  >
    <div className="cta-panel__text">
      <Heading style={{ color: headingColor }} {...{ ...defaultHeadingProps, ...headingProps }}>
        {heading}
      </Heading>
      {body && <Paragraph style={{ color: paragraphColor }}>{body}</Paragraph>}
    </div>
    <div className="cta-panel__join-btn">
      <Link
        buttonStyle={{
          variant: 'primary',
          size: ctaButtonSize,
        }}
        className="button button--full-width"
        href={ctaURL}
        onClick={onClick}
      >
        {ctaLabel}
      </Link>
    </div>
  </div>
);
