import React, { FunctionComponent, useEffect, useState } from 'react';
import { Prompt } from '../../../shared/Prompt';
import { useSnackbar } from '../../../../hooks/snackbar';
import performanceImg from '../../../../assets/Prompt/registration-abandon-prompt.png';
import { Paragraph } from '../../../shared/Paragraph';
import { AccountTile } from '../../../Account/MyAccount/AccountTile';
import { Heading } from '../../../shared/Heading';
import { OVERLAYS_ARIA_LABELS } from '../../../../consts';
import './GamingPerformancePrompt.scss';

type SummaryData = {
  winnings?: string;
  losses?: string;
  deposits?: string;
  stakes?: string;
};

const summaryDataKeys: (keyof SummaryData)[] = ['deposits', 'stakes', 'winnings', 'losses'];

type GamingPerformancePromptProps = {
  /**
   * Whether to show performance acknowledgement prompt
   */
  show: boolean;
  /**
   * Called when prompt is closed.  Acknowledged is true if user clicked OK and request successful.
   */
  onClose(acknowledged?: boolean): void;
};

export const GamingPerformancePrompt: FunctionComponent<GamingPerformancePromptProps> = ({ show, onClose }) => {
  const [summaryData, setSummaryData] = useState<SummaryData>();
  const { addSnack } = useSnackbar();

  const requestSummary = async (): Promise<void> => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const summary: any = await new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            totalCashStakes: 10,
            totalBonusStakes: 15,
            totalPayout: 20,
            totalEnhancedPayout: 5,
            totalDeposit: 40,
          });
        }, 300);
      });

      const totalStakes = summary?.totalCashStakes + summary?.totalBonusStakes;
      const winnings = summary?.totalPayout + summary?.totalEnhancedPayout;

      setSummaryData({
        winnings: winnings,
        deposits: summary?.totalDeposit,
        stakes: totalStakes,
        losses: String(totalStakes - winnings),
      });
    } catch (e) {
      // TODO: Not specified but should this show an error notification either in snack form or inside prompt?
    }
  };

  useEffect(() => {
    if (show) {
      requestSummary();
    }
  }, [show]);

  const handleAcknowledgement = async (): Promise<void> => {
    try {
      console.log('Acknowledgement request');
      onClose(true);
    } catch {
      addSnack({
        message: 'Oops, there was an issue acknowledging.  Please try again',
        type: 'error',
      });
    }
  };

  return (
    <Prompt
      cannotClose
      onClose={onClose}
      aria-label={OVERLAYS_ARIA_LABELS.PERFORMANCE_PROMPT}
      className="gaming-performance-prompt"
      title="Your 30 Days Spending Insights"
      show={show && !!summaryData}
      image={{
        src: performanceImg,
        alt: 'performance-img',
      }}
      PrimaryButton={{
        onClick: handleAcknowledgement,
        text: 'OK',
      }}
    >
      <Paragraph size="sm">
        Here is a summary of your spending in the last 30 days. You can view full details in your Transaction History
      </Paragraph>

      <div className="gaming-performance-prompt__metrics">
        {summaryDataKeys.map((key) => {
          return (
            <AccountTile withBoxShadow key={key} className="gaming-performance-prompt__metric">
              <Heading noMargin level={5}>
                {summaryData?.[key]}
              </Heading>

              <Paragraph noMargin>{key}</Paragraph>
            </AccountTile>
          );
        })}
      </div>

      <Paragraph size="xs">By clicking 'OK' you are confirming you understand the above information</Paragraph>
    </Prompt>
  );
};
