import flatten from 'lodash/flatten';
import pick from 'lodash/pick';
import React, { FunctionComponent, useState } from 'react';
import { FlattenedProfileData } from '../../../../hooks/registration';
import { MarketingData } from '../../../Registration/types';
import { Button } from '../../../shared/Button';
import { Form, FormField, FormFieldType, SelectFormField, SwitchFormField } from '../../../shared/Form';
import { Icon, IconVariant } from '../../../shared/Icon';
import { PopUpHandler } from '../../../shared/PopupHandler';
import { AccountDetailFieldSets } from '../../AccountDetails';
import { AccountDetailsEditModalProps } from '../../types';
import './AccountDetailsEditModal.scss';

const iconMap: Record<string, IconVariant> = {
  optInSms: 'Sms',
  optInTelephone: 'Phone',
  optInEmail: 'Email',
  optInPost: 'Post',
  SGContactPrefPhone: 'Phone',
  SGContactPrefEmail: 'Email',
  SGContactPrefWhatsapp: 'WhatsApp',
};

export const AccountDetailsEditModal: FunctionComponent<AccountDetailsEditModalProps> = ({
  isOpen,
  id,
  heading,
  formFields,
  fieldSets,
  defaultState,
  isSubmitting,
  onClose,
  onSave,
}) => {
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const editDialogHeaderId = `${id}-edit-dialog`;
  const fieldNames = flatten(fieldSets.map((fieldSet) => fieldSet.fields)).map((field) => field.name);
  const marketingFieldNames = fieldNames.filter((fieldName) => fieldName.includes('opt'));

  marketingFieldNames.concat(['toggleAllMarketing']).forEach((fieldKey: keyof FlattenedProfileData) => {
    const iconVariant = iconMap[fieldKey];

    (formFields as Record<string, FormField<Record<string, unknown>>>)[fieldKey] = {
      ...(formFields[fieldKey] as SwitchFormField<Partial<FlattenedProfileData>>),
      type: FormFieldType.Switch,
      props: {
        iconLabel: iconVariant && <Icon variant={iconVariant} />,
      },
    } as SwitchFormField<Partial<FlattenedProfileData>>;
  });

  const contactFieldNames = fieldNames.filter((fieldName) => fieldName.includes('SGContactPref'));

  contactFieldNames.concat(['toggleAllContact']).forEach((fieldKey: keyof FlattenedProfileData) => {
    const iconVariant = iconMap[fieldKey];

    (formFields as Record<string, FormField<Record<string, unknown>>>)[fieldKey] = {
      ...(formFields[fieldKey] as SwitchFormField<Partial<FlattenedProfileData>>),
      type: FormFieldType.Switch,
      props: {
        iconLabel: iconVariant && <Icon variant={iconVariant} />,
      },
    } as SwitchFormField<Partial<FlattenedProfileData>>;
  });

  defaultState.toggleAllMarketing = marketingFieldNames.every((name) => defaultState?.[name as keyof MarketingData]);

  defaultState.toggleAllContact = contactFieldNames.every((fieldName) => defaultState[fieldName]);

  formFields.language = {
    ...(formFields.language as SelectFormField<FlattenedProfileData>),
    props: { inline: true, labelProps: { isSrOnly: true } },
    type: FormFieldType.RadioGroup,
  };
  const formId = id + '-form';
  const headingText = heading;

  const onSubmit = (details: Partial<FlattenedProfileData>): void => {
    if (submitDisabled) return;

    onSave && onSave(details);
  };

  return (
    <PopUpHandler
      footer={
        <div className="account-details-form__actions">
          <Button type="button" size="large" variant="secondary-alt" onClick={onClose} disabled={isSubmitting}>
            CANCEL
          </Button>

          <Button type="submit" size="large" form={formId} disabled={submitDisabled} isSubmitting={isSubmitting}>
            SUBMIT
          </Button>
        </div>
      }
      modalProps={{ 'aria-label': headingText, className: `${id}-edit-modal` }}
      drawerProps={{ className: 'account-details-edit-modal' }}
      header={headingText}
      headingProps={{ id: editDialogHeaderId, styleHeadingAs: 5 }}
      isOpen={isOpen}
      onClose={onClose}
      showCloseBtn
    >
      <Form<Partial<FlattenedProfileData>>
        id={formId}
        className="account-details-form__form"
        onSubmit={onSubmit}
        defaultValues={pick(defaultState, fieldNames)}
        FormActions={(): null => null}
      >
        <div className="account-details-edit-modal__field-sets">
          <AccountDetailFieldSets
            fieldSets={fieldSets}
            marketingFieldNames={marketingFieldNames}
            contactFieldNames={contactFieldNames}
            formFields={formFields}
            setSubmitDisabled={setSubmitDisabled}
          />
        </div>
      </Form>
    </PopUpHandler>
  );
};
