import { FC, useEffect, useRef, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useLocation } from 'react-router-dom';
import { APP_URLS } from '../../consts';
import { cleanup, initHead, initHTML } from './utils';
import './SportsbookContainer.scss';
import { retrieveLoginToken } from './service';
import { useUserContext } from '../../providers/UserProvider';
import useDestroyUserSession from '../../hooks/auth/useDestroyUserSession';

export const SportsbookContainer: FC = () => {
  const auth = useAuth();
  const location = useLocation();
  const initialized = useRef(false);
  const { userProfileId } = useUserContext();
  const { logoutUser } = useDestroyUserSession();

  const [isOneTimeTokenGet, setIsOneTimeTokenGet] = useState(false);
  const [intSessionContextId, setIntSessionContextId] = useState('');

  const tokenEvent = (event: Event): void => {
    console.log('oneTimeTokenGet: ', event);
    setIsOneTimeTokenGet(true);
  };

  const logoutEvent = (event: Event): void => {
    console.log('gcv2UserNotLoggedIn: ', event);
    logoutUser();
  };

  const initialize = async (): Promise<void> => {
    if (!initialized.current) {
      initialized.current = true;

      initHead();

      initHTML();

      if (auth.isAuthenticated) {
        const { sessionContextId } = await retrieveLoginToken(userProfileId);

        setIntSessionContextId(sessionContextId);
      }
    }
  };

  useEffect(() => {
    initialize();

    window.addEventListener('oneTimeTokenGet', tokenEvent);
    window.addEventListener('gcv2UserNotLoggedIn', logoutEvent);

    return () => {
      cleanup();

      window.removeEventListener('oneTimeTokenGet', tokenEvent);
      window.removeEventListener('gcv2UserNotLoggedIn', logoutEvent);
    };
  }, []);

  useEffect(() => {
    if (initialized.current && auth?.isAuthenticated && intSessionContextId && isOneTimeTokenGet) {
      const oneTimeTokenSetEvent = new CustomEvent('oneTimeTokenSet', {
        detail: { oneTimeToken: intSessionContextId },
      });

      console.log('oneTimeTokenSetEvent', oneTimeTokenSetEvent);
      window.dispatchEvent(oneTimeTokenSetEvent);
    }
  }, [initialized.current, intSessionContextId, auth?.isAuthenticated, isOneTimeTokenGet]);

  useEffect(() => {
    // Moved from init.ts to this useEffect to avoid unmounting issue when customer navigates from / to /
    const ev = new CustomEvent('mggcv2-mount');

    if (!window.location.pathname.includes(APP_URLS.myAccount.home)) {
      window.dispatchEvent(ev);
    }
  }, [location]);

  return <div id="mg-sportsbook" className="mggcv2-root"></div>;
};
