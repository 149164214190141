import { useNavigate } from 'react-router-dom';
import { Button } from '../../../shared/Button';
import { Heading } from '../../../shared/Heading';
import { Paragraph } from '../../../shared/Paragraph';
import { RegistrationStepComponent } from '../../types';
import CompletedAnimation from './CompletedAnimation';
import { APP_URLS } from '../../../../consts';
import './RegistrationIntroductionStep.scss';
import { capitalizeFirstLetter } from '../../../../utils/form';
import { useTranslations } from '../../../../hooks/useTranslationsHelper';

// TODO: update this component
/**
 * A registration step component for introduction screen!
 */
export const RegistrationIntroductionStep: RegistrationStepComponent = ({ stepConfig, selected, ...props }) => {
  const { t } = useTranslations();
  const navigate = useNavigate();

  if (!selected) return null;

  const onLaterClick = (): void => {
    console.log('onLaterClick');
  };

  const onContinueClick = (): void => {
    navigate(APP_URLS.home);
    console.log('onContinueClick');
  };

  return (
    <div id={stepConfig.id} className="registration-introduction-step">
      <Heading level={6}>
        {t('register.success.welcome', {
          name: capitalizeFirstLetter(props.formState.givenName),
        })}
      </Heading>
      <Paragraph>{t('register.success.greatings.label')}</Paragraph>
      <CompletedAnimation autoplay keepLastFrame className="completed-animation-container" />
      <div className="registration-introduction-step__buttons">
        <Button type="button" variant="secondary" size="large" onClick={onLaterClick}>
          {t('register.success.deposit.limits')}
        </Button>
        <Button onClick={onContinueClick} type="button" variant="primary" size="large">
          {t('register.success.deposit.continue')}
        </Button>
      </div>
    </div>
  );
};
