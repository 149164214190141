import React, { FC, useContext, useEffect, useState } from 'react';
import { ApolloError } from '@apollo/client';
import { locale } from '../../consts';
import { Spinner } from '../../components/shared/Spinner';
import { useFetchTranslations } from '../../hooks/useFetchTranslations';
import { TransformedTranslationsObject } from '../../hooks/useTranslationsHelper/types';

interface Props {
  children: React.ReactNode;
}

interface ContextValue {
  loading: boolean;
  error: ApolloError | undefined;
  localTranslations: { [k: string]: string };
  translations: TransformedTranslationsObject | null | undefined;
}

const initialValue: ContextValue = {
  loading: false,
  translations: null,
  error: undefined,
  localTranslations: {},
};

const TranslationsContext = React.createContext(initialValue);

export const useTranslationsContext = (): ContextValue => useContext(TranslationsContext);

export const TranslationsProvider: FC<Props> = ({ children }) => {
  // TODO: make this value dynamic
  const lang = locale.split('_')[0];
  const [localTranslations, setLocalTranslations] = useState<{ [k: string]: string }>({});

  const { loading, translations, error, loadLocalTranslations } = useFetchTranslations(locale);

  useEffect(() => {
    (async (): Promise<void> => {
      const data = await loadLocalTranslations(lang);

      setLocalTranslations(data.default);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <div className="spinner-wrapper">
        <Spinner />
      </div>
    );
  }

  return (
    <TranslationsContext.Provider value={{ translations, error, loading, localTranslations }}>
      {children}
    </TranslationsContext.Provider>
  );
};
