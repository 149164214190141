import React, { FunctionComponent } from 'react';
import { joinStrings } from '../../../utils/string';
import './styles';

export type PillProps = {
  size?: 'large' | 'small';
  children: React.ReactNode;
  variant?: 'default';
  className?: string;
};

export const Pill: FunctionComponent<PillProps> = ({ size = 'large', children, variant = 'default', className }) => {
  return <div className={joinStrings(['pill', `pill--${size}`, `pill--${variant}`, className])}>{children}</div>;
};
