import uniqueId from 'lodash/uniqueId';
import React, { Children, FunctionComponent, useEffect, useState } from 'react';
import noop from 'lodash/noop';
import { AccordionContext, AccordionContextConfig } from './Accordion.context';
import { useExpansion } from '../../../hooks/presentation-logic';
import { joinStrings } from '../../../utils/string';
import './Accordion.scss';

export type AccordionProps = {
  /**
   * Changes the theme to either dark or light.  "light" by default.
   */
  theme?: 'dark' | 'light';
  /**
   * Accordion expansion/collapse transition duration in Milliseconds.  Defaults to 300.
   */
  transitionMs?: number;
  /**
   * Accordion styling.  Defaults to card.
   */
  accordionStyling?: 'card' | 'rule' | 'none';
  /**
   * To be used if component is needed as controlled. By default the expansion state is managed internally.
   */
  expanded?: boolean;
  /**
   * Used to set default expansion state when not in 'controlled' mode.
   */
  defaultExpanded?: boolean;
  /**
   * Optional. Will be used to generate header and content ids. Will fallback to auto generated value.
   */
  id?: string;
  children?: React.ReactNode;
};

/**
 * An accessible Accordion component.  More configuration possibilities are likely upcoming.
 *
 * Note that Accordions will operate independent of each other unless used as a controlled component.
 * There is a story demonstration this usage scenario.
 *
 * ## Basic Usage Example
 *
 * ```tsx
 * <Accordion>
 *   <AccordionHeader>Accordion Header</AccordionHeader>
 *   <AccordionContent>
 *       Content
 *   </AccordionContent>
 * </Accordion>
 * <Accordion>
 *   <AccordionHeader>Accordion Header 2</AccordionHeader>
 *   <AccordionContent>
 *       Content
 *   </AccordionContent>
 * </Accordion>
 * ```
 */
export const Accordion: FunctionComponent<AccordionProps> = ({
  theme = 'light',
  expanded,
  transitionMs = 300,
  accordionStyling = 'card',
  defaultExpanded = false,
  id,
  children,
}) => {
  const [accordionId] = useState(id || uniqueId('accordion'));
  const controlledComponent = expanded !== undefined;

  const {
    expanded: _expanded,
    transitionDuration,
    setExpanded,
    expansionElementRef,
    expansionContainerStyles,
  } = useExpansion({
    transitionMs,
    defaultExpanded: controlledComponent ? expanded : defaultExpanded,
  });

  const headerId = `${accordionId}-header`;
  const contentId = `${accordionId}-content`;
  const commonContext = {
    headerId,
    contentId,
    transitionDuration,
    expanded: _expanded,
    onClickChangeView: noop,
  };

  useEffect(() => {
    if (typeof expanded !== 'undefined') {
      setExpanded(expanded);
    }
  }, [expanded, setExpanded]);

  const contextValue: AccordionContextConfig = controlledComponent
    ? commonContext
    : {
        ...commonContext,
        onClickChangeView: () => setExpanded(!_expanded),
      };

  const [header, ...bodyComponents] = Children.toArray(children);

  return (
    <div
      id={accordionId}
      data-testid="accordion"
      className={joinStrings([
        'accordion',
        `accordion--${theme}-theme`,
        `accordion--style-${accordionStyling}`,
        _expanded && 'accordion--expanded',
      ])}
    >
      <AccordionContext.Provider value={contextValue}>
        <div>{header}</div>
      </AccordionContext.Provider>
      <div
        data-testid="accordion-collapse-container"
        className="accordion__collapse-container"
        style={expansionContainerStyles}
      >
        <div
          data-testid="accordion-content-region"
          ref={expansionElementRef}
          id={contentId}
          aria-labelledby={headerId}
          role="region"
          className="accordion__wrapper"
        >
          {bodyComponents}
        </div>
      </div>
    </div>
  );
};
