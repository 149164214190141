import flatten from 'lodash/flatten';
import pick from 'lodash/pick';
import React, { Fragment, FunctionComponent, useMemo } from 'react';
import { AccountDetailFieldSets } from './AccountDetailFieldSets';
import { AccountFieldSetConfig } from '../../types';
import { FlattenedProfileData } from '../../../../hooks/registration';
import { Form, FormField } from '../../../shared/Form';
import { getMarketingPreferences } from '../../../../utils/marketing-preferences-helper';
import { MarketingData } from '../../../Registration/types';
import { Heading } from '../../../shared/Heading';
import { InnerHTML } from '../../../shared/InnerHTML';
import { Paragraph } from '../../../shared/Paragraph';
import { Button } from '../../../shared/Button';
import './AccountDetailsForm.scss';

export type AccountDetailsFormProps = {
  /**
   * Unique ID. Required to generate component and form id.
   */
  id: string;
  /**
   * Form title.  Optional.
   */
  title?: string;
  /**
   * Optional intro text to give some context to the form.
   */
  introText?: string | React.ReactNode;
  /**
   * Field set config to define how the form will be layed out.
   */
  fieldSets: AccountFieldSetConfig<FlattenedProfileData>[];
  /**
   * Initial form state.  To be derived from existing account data.
   */
  defaultState: Partial<FlattenedProfileData>;
  /**
   * List of form field config for form controls.
   */
  formFields: Record<string, FormField<FlattenedProfileData>>;
  /**
   * Optional text which will be displayed before the form action buttons.
   */
  preSubmitText?: string | React.ReactNode;
  /**
   * Custom label for submit button. Optional
   */
  submitButtonLabel?: string;
  /**
   * Will be called when user submits and data is valid.
   */
  onSubmit?: (data: Partial<FlattenedProfileData>) => Promise<void>;
  /**
   * Will be called if the user decides to not proceed with the update.
   */
  onCancel?: () => void;
};

export type AccountDetailsFormComponent = FunctionComponent<AccountDetailsFormProps>;

/**
 * Generic dynamic form for editing Account Profile Details.
 */
export const AccountDetailsForm: AccountDetailsFormComponent = ({
  id,
  introText,
  fieldSets,
  defaultState,
  formFields,
  preSubmitText,
  submitButtonLabel,
  title,
  onCancel,
  onSubmit,
}) => {
  const getMarketingFieldSets = useMemo(
    () => getMarketingPreferences(defaultState as MarketingData, fieldSets),
    [defaultState?.optInEmail, defaultState?.optInSms, defaultState?.optInTelephone, fieldSets]
  );

  const fieldNames = flatten(getMarketingFieldSets.map((fieldSet) => fieldSet.fields)).map((field) => field.name);
  const marketingFieldNames = fieldNames.filter((fieldName) => fieldName.includes('optIn'));
  const contactFieldNames = fieldNames.filter((fieldName) => fieldName.includes('SGContactPref'));

  return (
    <div id={id} data-testid={id} className="account-details-form">
      {title && <Heading level={4}>{title}</Heading>}

      {introText && (
        <div className="account-details-form__intro-text">
          {typeof introText === 'string' ? <InnerHTML text={introText} /> : introText}
        </div>
      )}

      <Paragraph>All details are required unless marked optional.</Paragraph>

      <Form<Partial<FlattenedProfileData>>
        id={id + '-form'}
        className="account-details-form__form"
        onSubmit={onSubmit}
        defaultValues={pick(defaultState, fieldNames)}
        FormActions={({ submitDisabled }): JSX.Element => (
          <div className="account-details-form__actions">
            <Button type="button" variant="secondary-alt" onClick={onCancel}>
              CANCEL
            </Button>

            <Button type="submit" disabled={submitDisabled}>
              {submitButtonLabel || 'SUBMIT'}
            </Button>
          </div>
        )}
      >
        <AccountDetailFieldSets
          fieldSets={getMarketingFieldSets}
          marketingFieldNames={marketingFieldNames}
          contactFieldNames={contactFieldNames}
          formFields={formFields}
        />
        {preSubmitText && (
          <Fragment>
            {typeof preSubmitText === 'string' ? (
              <div data-testid="account-details-form-pre-submit-text">
                <InnerHTML text={preSubmitText} />
              </div>
            ) : (
              preSubmitText
            )}
          </Fragment>
        )}
      </Form>
    </div>
  );
};
