import { useUserValidationConstraints } from '../../components/Registration/const';
import { FormField, FormFieldType } from '../../components/shared/Form';
import { Icon } from '../../components/shared/Icon';
import { countryCodeOptions } from '../../components/shared/TelephoneInput/countryCodes';
import { useTranslations } from '../useTranslationsHelper';
import { FlattenedProfileData } from './useRegistrationStepper.model';

export type RegistrationFieldMap = Record<string, FormField<Partial<FlattenedProfileData>>>;
export const useRegistrationFieldConfig = (): RegistrationFieldMap => {
  const { t } = useTranslations();
  const userValidationConstraints = useUserValidationConstraints();

  return {
    password: {
      type: FormFieldType.PasswordInput,
      name: 'password',
      label: t('input.password.label'),
      rules: userValidationConstraints.password.rules as never,
      props: {
        autoComplete: 'new-password',
        showRequirements: true,
      },
    },
    email: {
      type: FormFieldType.TextInput,
      name: 'email',
      label: t('input.mail.label'),
      rules: userValidationConstraints.email.rules as never,
      props: {
        autoComplete: 'email',
        type: 'email',
        autoCapitalize: 'off',
        inputMode: 'email',
      },
    },
    givenName: {
      type: FormFieldType.TextInput,
      name: 'givenName',
      label: t('register.steps.personal.first-name-input.label'),
      rules: {
        maxLength: {
          value: 40,
          message: t('validation.first-name.less-symbols'),
        },
        minLength: {
          value: 2,
          message: t('validation.first-name.more-symbols'),
        },
        required: t('validation.field-required'),
        pattern: {
          value: userValidationConstraints.lastnamePattern,
          message: t('validation.first-name.allowed'),
        },
      },
      props: { autoComplete: 'given-name', autoCorrect: 'off' },
    },
    familyName: {
      type: FormFieldType.TextInput,
      name: 'familyName',
      label: t('register.steps.personal.last-name-input.label'),
      rules: {
        maxLength: {
          value: 40,
          message: t('validation.last-name.less-symbols'),
        },
        minLength: {
          value: 2,
          message: t('validation.last-name.more-symbols'),
        },
        required: t('validation.field-required'),
        pattern: {
          value: userValidationConstraints.lastnamePattern,
          message: t('validation.last-name.allowed'),
        },
      },
      props: { autoComplete: 'family-name', autoCorrect: 'off' },
    },
    birthDate: {
      type: FormFieldType.DateOfBirthInput,
      name: 'birthDate',
      label: t('register.steps.personal.dof-input.label'),
      placeholder: 'DD/MM/YYYY',
      dateMask: '99/99/9999',
      dateFormat: 'dd/MM/YYYY',
      rules: userValidationConstraints.dateOfBirth.rules,
      props: { autoComplete: 'bday', type: 'text', inputMode: 'numeric' },
    },
    honorificPrefix: {
      type: FormFieldType.Select,
      name: 'honorificPrefix',
      label: t('register.steps.personal.honor-input.label'),
      rules: {},
      defaultValue: 'Mr',
      options: [
        { value: 'Mr', label: t('register.steps.personal.mr') },
        { value: 'Mrs', label: t('register.steps.personal.mrs') },
        { value: 'Ms', label: t('register.steps.personal.ms') },
        { value: 'Other', label: t('register.steps.personal.other') },
      ],
    },
    addrStreetAddress: {
      type: FormFieldType.TextInput,
      name: 'addrStreetAddress',
      label: 'Enter street address',
      rules: {
        required: 'This field is required',
        pattern: {
          value: userValidationConstraints.addressLinePatternFirstTwoInput,
          message: 'Please enter the valid address',
        },
      },
    },
    addrLocality: {
      type: FormFieldType.TextInput,
      name: 'addrLocality',
      label: 'Enter your city',
      rules: {
        required: false,
        pattern: {
          value: userValidationConstraints.addressLinePattern,
          message: 'Please enter the valid city',
        },
      },
    },
    addrRegion: {
      type: FormFieldType.TextInput,
      name: 'addrRegion',
      label: 'Enter your region',
      rules: {
        required: false,
        pattern: {
          value: userValidationConstraints.addressLinePattern,
          message: 'Please enter the valid region',
        },
      },
    },
    addrPostalCode: {
      type: FormFieldType.NoWhitespaceInput,
      name: 'addrPostalCode',
      label: 'Enter your postcode',
      rules: {
        required: 'This field is required',
        pattern: {
          value: userValidationConstraints.postalCodePattern,
          message: 'Your postcode is invalid',
        },
      },
    },
    promoCode: {
      type: FormFieldType.TextInput,
      name: 'promoCode',
      label: t('register.step.address.promo-code.label'),
      rules: {
        pattern: {
          value: userValidationConstraints.promoCodePattern,
          message: t('validation.promo-code.invalid'),
        },
      },
    },
    mobileNumber: {
      type: FormFieldType.TelephoneInput,
      label: t('register.step.address.phone-number.label'),
      name: 'mobileNumber',
      rules: userValidationConstraints.telephone,
      props: {
        maskedInputProps: {
          name: 'countryCode',
          label: t('input.country-code'),
          mask: '',
          autoComplete: 'tel-national',
          type: 'tel',
        },
        selectProps: {
          label: t('input.country-code'),
          name: 'mobilePrefix',
          options: countryCodeOptions,
        },
      },
    },
    acceptTerms: {
      type: FormFieldType.Checkbox,
      name: 'acceptTerms',
      label: 'InnerHTML',
      rules: {
        required: 'This field is required',
        validate: {
          accepted: (v) => v === true,
        },
      },
    },
    acceptMarketingPreferences: {
      type: FormFieldType.Checkbox,
      name: 'acceptMarketingPreferences',
      label: 'InnerHTML',
    },
    toggleAllMarketing: {
      type: FormFieldType.Switch,
      name: 'toggleAllMarketing',
      label: 'Select All',
    },
    optInEmail: {
      type: FormFieldType.Switch,
      name: 'optInEmail',
      label: 'Email',
      props: {
        iconLabel: <Icon variant="Email" />,
      },
    },
    optInSms: {
      type: FormFieldType.Switch,
      name: 'optInSms',
      label: 'Sms',
      props: {
        iconLabel: <Icon variant="Sms" />,
      },
    },
    optInTelephone: {
      type: FormFieldType.Switch,
      name: 'optInTelephone',
      label: 'Phone',
      props: {
        iconLabel: <Icon variant="Phone" />,
      },
    },
  };
};
