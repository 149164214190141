import React, { forwardRef } from 'react';

/**
 * A boolean indicating if we are in development or test mode
 * Mostly used to avoid printing console messages in production
 */
export const isInDevelopmentOrTestMode = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test';

export type PropValidationRuleLogType = 'log' | 'warn' | 'error' | 'info';
export enum PropValidationSelectEnum {
  Auto = 'auto',
  Native = 'native',
  Default = 'default',
}

export type PropValidationRuleConfig<P> = {
  validate: (props: P) => boolean;
  message: string;
  logType?: PropValidationRuleLogType;
};

type ValidationType<T> = {
  Component: React.ComponentType<T>;
  validationRules: PropValidationRuleConfig<T>[];
};

/**
 * Utility HOC that validates prop rules and returns a console message.
 *
 * Returns a new component decorated with property validation & issue logging.
 */
export const withPropValidation = <P, E extends HTMLElement>({
  Component,
  validationRules,
}: ValidationType<P>): React.ForwardRefExoticComponent<
  React.PropsWithoutRef<React.PropsWithChildren<P>> & React.RefAttributes<E>
> => {
  const _Component = forwardRef<E, React.PropsWithChildren<P>>((props, ref) => {
    validationRules.forEach(({ validate, message, logType = 'info' }: PropValidationRuleConfig<P>) => {
      if (isInDevelopmentOrTestMode && validate(props)) {
        console[logType](message);
      }
    });

    return <Component {...props} ref={ref} />;
  });

  _Component.displayName = Component.displayName;

  return _Component;
};
