import React, { FC } from 'react';
import defaultIcon from '../../../shared/SVG/Registration/card.svg';
import '../styles';

export type NavigationLinkIconProps = {
  icon: string;
  active?: boolean;
  activeIcon?: string;
  label?: string;
};

export const NavigationLinkIcon: FC<NavigationLinkIconProps> = ({ label, active = false, icon, activeIcon }) => {
  const iconImg = icon || defaultIcon;
  const activeIconImg = activeIcon || defaultIcon;

  return (
    <div className="navigation-link__icon">
      <img alt={label} className="game-category-options__icon" src={active ? activeIconImg : iconImg} />
    </div>
  );
};
