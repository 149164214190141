/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FunctionComponent, useMemo, useState } from 'react';
import { useUserContext } from '../../../../../providers/UserProvider';
import { storageOddsFromat } from '../../../../../utils/storage';
import { RadioGroup } from '../../../../shared/RadioGroup';
import { KambiOddsFormatTypes } from './types';
import { useAuth } from 'react-oidc-context';
import './KambiOddsFormat.scss';
import { useTranslations } from '../../../../../hooks/useTranslationsHelper';

export const KambiOddsFormat: FunctionComponent = () => {
  const { t } = useTranslations();
  const auth = useAuth();
  const { userProfileId } = useUserContext();
  const playerId = userProfileId || (auth.user?.profile?.user_profile_id as string);
  const defaultFormat = (storageOddsFromat.get(playerId) as KambiOddsFormatTypes) || KambiOddsFormatTypes.DECIMAL;
  const [radioGroupValue, setRadioGroupValue] = useState<string>(defaultFormat);

  const formats = useMemo(
    () => ({
      decimal: t('my-account.home.settings.odds-format.decimal'),
      fractional: t('my-account.home.settings.odds-format.fractional'),
      american: t('my-account.home.settings.odds-format.american'),
    }),
    []
  );

  const setOddsFormat = (value: string | number): void => {
    // TODO: remove storage logic
    storageOddsFromat.set({ value, playerId });
    setRadioGroupValue(String(value));
  };

  const options = useMemo(
    () =>
      Object.keys(formats).map((k) => ({
        // @ts-ignore
        value: formats[k],
        // @ts-ignore
        label: formats[k],
      })),
    [formats]
  );

  return (
    <div className="kambi-odds-format">
      <RadioGroup
        disabled={!radioGroupValue}
        label={t('my-account.home.settings.odds-format.title')}
        onChange={setOddsFormat}
        options={options}
        value={radioGroupValue}
        labelProps={{
          textClassName: `kambi-odds-format__label`,
        }}
      />
    </div>
  );
};
