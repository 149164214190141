import { ComponentPropsWithRef, ReactNode, RefObject } from 'react';
import { InputValidationProps } from '../Form/types';
import { LabelProps } from '../Label';
import { Theme } from '../../../types';

export type SelectVariants = 'auto' | 'native' | 'custom' | undefined;
export type SelectPlacement = 'top' | 'bottom';

export type SelectOption = {
  name?: string;
  label: string;
  id?: string;
  value: string;
  unit?: string;
  imageSrc?: string;
  disabled?: boolean;
  ctaUrl?: string;
  description?: string;
  search?: string;
  /**
   * Optional.
   * Render custom dropdown item within Non-Native select component
   */
  customDropDownItemRender?: (label: string) => React.ReactNode;
};

export type SelectProps = {
  /**
   * Current input value.  Left optional to prevent TypeScript complaint when used with hook form Controller.
   */
  value?: string;
  /**
   * Default input value.
   */
  defaultValue?: string;
  /**
   * Label text.  Optional but should be provided for accessibility purposes.
   */
  label?: string | ReactNode;
  /**
   * List of options to be presented to user.
   */
  options: SelectOption[];
  /**
   * Optional. Disable the Select component.
   * It will be aria-disabled rather than disabled so that it can be focused upon in screen readers.
   */
  disabled?: boolean;
  /**
   * Whether to display a feedback icon.  Defaults to true
   */
  showFeedbackIcon?: boolean;
  /**
   * Placeholder text for the select box.
   */
  placeholder?: string;
  /**
   * Location of where the dropbox will appear.
   */
  placement?: SelectPlacement;
  /**
   * Determines whether a native or custom dropdown is presented.
   * If "auto" is selected, it will render the native in mobile and non-native in desktop.
   * if "native" is selected, it will ALWAYS render the dropdown the Native dropdown.
   * If "custom" is selected, it will ALWAYS render the dropdown the NonNative dropdown.
   */
  presentAs?: SelectVariants;
  /**
   * Optional, defaults to 'light'. If set to 'dark' the input will be styled to be placed on a dark background.
   */
  theme?: Theme;
  /**
   * Callback invoked on change.
   * Note that the arg provided will be the selected value of the Select component rather than an event.
   */
  onChange?: (v: string) => void;
  /**
   * Optional props for input label.
   */
  labelProps?: LabelProps;
  /**
   * Optional, prevents an input from showing the first option when unselected.
   */
  hideUnselectedOption?: boolean;
  /**
   * Reduce the input field and the dropdown list items height.
   */
  reducedHeight?: boolean;
  /**
   * Display a select icon in the dropdown list items for the item that is currently selected.
   */
  showSelectIcon?: boolean;
  /**
   * Optional, provides onClick event.
   */
  onClick?: (e: React.MouseEvent | React.KeyboardEvent) => void;

  /**
   * Optional, provides onFocus event.
   */
  onFocus?: (e: React.FocusEvent) => void;
  /**
   * Optional, determines whether select has input for search.
   */
  hasSearchInput?: boolean;
  /**
   * Optional, provides input value for search.
   */
  inputValue?: string;
  /**
   * Callback invoked on intput change.
   */
  setInputValue?: (v: string | undefined) => void;
  /**
   * Text which is displayed, when filter options list is empty.
   */
  emptyListText?: string;
  /**
   * Ref object for "Phone Number" input wich is next to it.
   */
  phoneInputRef?: RefObject<HTMLInputElement>;
  children?: React.ReactNode;
} & InputValidationProps &
  Omit<ComponentPropsWithRef<'select'>, 'onChange' | 'defaultValue'>;

export const findSelectImage = (options: SelectOption[], val: SelectOption['value']): SelectOption['imageSrc'] => {
  return options.find((x) => x.value === val)?.imageSrc;
};
