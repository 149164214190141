import React, { FC } from 'react';

export const MyAccountActivityGiftIcon: FC = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    transform="translate(0, 1)"
  >
    <g clipPath="url(#clip0_2_4354)">
      <path
        d="M13.1388 8.1125L11.5733 7.55934C11.525 7.54063 11.467 7.53125 11.4091 7.53125H1.37814C1.10753 7.53125 0.894958 7.73747 0.894958 8V14.5938C0.894958 15.3718 1.54239 16 2.34451 16H12.0082C12.8103 16 13.4578 15.3718 13.4578 14.5938V8.55309C13.4578 8.35625 13.3321 8.17813 13.1388 8.1125Z"
        fill="#FC1A40"
      />
      <path
        d="M13.4577 8.55309V14.5938C13.4577 15.3718 12.8103 16 12.0082 16H7.17633V7.53125H11.409C11.467 7.53125 11.525 7.54063 11.5733 7.55934L13.1388 8.1125C13.3321 8.17813 13.4577 8.35625 13.4577 8.55309Z"
        fill="#C60034"
      />
      <path
        d="M13.2839 3.05587C13.1196 2.409 12.6267 1.90278 11.9599 1.6965C11.3028 1.49959 10.5973 1.64025 10.0852 2.09021L8.96417 3.0465L8.74194 1.61215C8.63563 0.955901 8.20077 0.402744 7.55333 0.140276C7.4277 0.0933693 7.30208 0.0558693 7.17645 0.0371193C6.64495 -0.0660058 6.09412 0.0464943 5.64955 0.365276C5.06007 0.777776 4.76053 1.47153 4.85713 2.16525C4.96346 2.859 5.44665 3.44962 6.13274 3.684L7.17645 4.04962L8.46172 4.509C8.47138 4.509 8.47138 4.509 8.47138 4.509L10.7037 5.30584C10.9259 5.38087 11.1579 5.4184 11.3801 5.4184C11.8537 5.4184 12.3175 5.24959 12.6848 4.93087C13.2259 4.46212 13.4578 3.74021 13.2839 3.05587Z"
        fill="#FE9923"
      />
      <path
        d="M12.6846 4.93026C12.3174 5.24898 11.8536 5.41779 11.38 5.41779C11.1578 5.41779 10.9258 5.38026 10.7036 5.30523L8.47127 4.50838C8.47127 4.50838 8.47127 4.50838 8.4616 4.50838L7.17633 4.04901V0.0364742C7.30196 0.0552555 7.42759 0.0927242 7.55321 0.139631C8.20065 0.402099 8.63555 0.955256 8.74182 1.61151L8.96405 3.04585L10.0851 2.08957C10.5972 1.6396 11.3027 1.49894 11.9598 1.69585C12.6266 1.90213 13.1195 2.40835 13.2838 3.05523C13.4577 3.7396 13.2258 4.46151 12.6846 4.93026Z"
        fill="#FE8821"
      />
      <path
        d="M15.1104 7.31578L14.4533 9.07828C14.3759 9.26578 14.1923 9.37828 13.999 9.37828C13.9411 9.37828 13.8831 9.36891 13.8348 9.35019L8.83862 7.58769L8.38446 6.13456L7.1765 6.39703L6.11353 6.63141L1.11737 4.86891C0.86611 4.77519 0.740514 4.50328 0.827455 4.26891L1.49425 2.50647C1.61988 2.15022 1.89049 1.86891 2.23839 1.70956C2.58628 1.55022 2.98246 1.53144 3.34968 1.66269L7.17653 3.01269L7.43749 3.10641C7.43749 3.10641 8.48113 4.94394 8.51016 4.94394C8.52952 4.94394 8.94502 4.71894 9.3509 4.50328C9.75668 4.28766 10.1626 4.06269 10.1626 4.06269L14.2406 5.50641C14.6079 5.63766 14.8978 5.90019 15.062 6.23769C15.2264 6.57516 15.2457 6.95957 15.1104 7.31578Z"
        fill="#FF3E75"
      />
      <path
        d="M15.1102 7.31545L14.4531 9.07795C14.3757 9.26545 14.1921 9.37795 13.9989 9.37795C13.9409 9.37795 13.8829 9.36858 13.8346 9.34986L8.83845 7.58736L8.38429 6.13423L7.17633 6.3967V3.01235L7.43728 3.10607C7.43728 3.10607 8.48093 4.9436 8.50995 4.9436C8.52931 4.9436 8.94482 4.7186 9.35069 4.50295C9.7565 4.28732 10.1624 4.06235 10.1624 4.06235L14.2405 5.50607C14.6077 5.63732 14.8976 5.89986 15.0618 6.23736C15.2262 6.57482 15.2455 6.95923 15.1102 7.31545Z"
        fill="#FC1A40"
      />
      <path d="M5.72678 7.53112V15.9999H8.62588V7.53112H5.72678Z" fill="#FCBF29" />
      <path
        d="M7.43727 3.1061L7.17632 3.79985L6.11334 6.63114L7.17632 7.00617L8.38428 7.42804L8.83844 7.58739L10.1624 4.06239L7.43727 3.1061Z"
        fill="#FCBF29"
      />
      <path d="M8.62634 7.53125H7.17679V16H8.62634V7.53125Z" fill="#FE9923" />
      <path
        d="M10.1624 4.06239L8.83845 7.58739L8.38429 7.42801L7.17633 7.00614V3.79985L7.43725 3.1061L10.1624 4.06239Z"
        fill="#FE9923"
      />
    </g>
    <defs>
      <clipPath id="clip0_2_4354">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
