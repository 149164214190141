import {
  KambiCouponsResponse,
  KambiFetchBetStatus,
  KambiFetchCouponRowStatus,
  KambiFetchOutcomeStatus,
} from '../../../Kambi/types';

const mockedCouponSimple: KambiCouponsResponse = {
  couponRef: 4353435227,
  couponExternalRef: 'f3b50ce1-3ad5-43a9-9b95-ab94049b538c',
  placedDate: '2023-05-11T12:31:06.315Z',
  currency: 'BRL',
  channel: 'WEB',
  bets: [
    {
      betRef: 7153433360,
      couponRowIndexes: [0],
      betOdds: 1290,
      playedOdds: 1290,
      betStatus: KambiFetchBetStatus.CASHED_OUT,
      stake: 3000,
      payout: 3870,
      potentialPayout: 3870,
      potentialPayoutBoosted: 3000,
    },
  ],
  outcomes: [
    {
      outcomeId: 2958235678,
      eventId: 1008531100,
      betOfferId: 2282603248,
      label: '1X',
      settledInfo: {
        result: {
          score: '4-3',
          correct: ['12', '1X'],
          scratched: [],
        },
      },
      status: KambiFetchOutcomeStatus.NOT_SET,
      outcomeTags: [],
    },
  ],
  couponRows: [
    {
      index: 0,
      outcomeId: 2958235678,
      status: KambiFetchCouponRowStatus.WON,
      playedOdds: 1290,
      payoutOdds: 1290,
      bestOddsGuaranteed: false,

      selectionType: 'SIMPLE',
    },
  ],
  events: [
    {
      eventId: 1008531100,
      sport: 'FOOTBALL',
      homeName: 'Liverpool',
      awayName: 'Chelsea',
      eventName: 'Liverpool - Chelsea',
      eventGroups: [
        {
          id: 1000093190,
          name: 'Futebol',
        },
        {
          id: 1000461733,
          name: 'Inglaterra',
        },
        {
          id: 1000094985,
          name: 'Premier League',
        },
      ],
      eventStartDate: '2023-05-12T02:00:00Z',
    },
  ],
  betOffers: [
    {
      betOfferId: 2282603248,
      boType: 'Double Chance',
      boTypeId: 12,
      criterion: 'Dupla Chance',
    },
  ],
};

const mockedCoupon3xSingles: KambiCouponsResponse = {
  couponRef: 4353434523,
  couponExternalRef: 'db6676ad-1af1-4640-8a48-dabe772906c8',
  placedDate: '2023-05-04T09:52:22.629Z',
  currency: 'BRL',
  channel: 'WEB',
  bets: [
    {
      betRef: 7153432606,
      couponRowIndexes: [0],
      betOdds: 2000,
      playedOdds: 2000,
      betStatus: KambiFetchBetStatus.OPEN,
      stake: 5000,
      payout: 0,
      potentialPayout: 10000,
      potentialPayoutBoosted: 3000,
    },
    {
      betRef: 7153432607,
      couponRowIndexes: [1],
      betOdds: 2000,
      playedOdds: 2000,
      betStatus: KambiFetchBetStatus.VOID,
      stake: 5000,
      payout: 0,
      potentialPayout: 10000,
      potentialPayoutBoosted: 3000,
    },
    {
      betRef: 7153432608,
      couponRowIndexes: [2],
      betOdds: 2000,
      playedOdds: 2000,
      betStatus: KambiFetchBetStatus.OPEN,
      stake: 5000,
      payout: 0,
      potentialPayout: 10000,
      potentialPayoutBoosted: 3000,
    },
  ],
  outcomes: [
    {
      outcomeId: 2958188933,
      eventId: 1008530893,
      betOfferId: 2282592026,
      label: 'X',
      status: KambiFetchOutcomeStatus.OPEN,
      outcomeTags: [],
    },
    {
      outcomeId: 2958183073,
      eventId: 1008530826,
      betOfferId: 2282590146,
      label: 'X',
      status: KambiFetchOutcomeStatus.OPEN,
      outcomeTags: [],
    },
    {
      outcomeId: 2958183595,
      eventId: 1008530827,
      betOfferId: 2282590289,
      label: 'X',
      status: KambiFetchOutcomeStatus.OPEN,
      outcomeTags: [],
    },
  ],
  couponRows: [
    {
      index: 0,
      outcomeId: 2958183073,
      status: KambiFetchCouponRowStatus.OPEN,
      playedOdds: 2000,
      bestOddsGuaranteed: false,

      selectionType: 'SIMPLE',
    },
    {
      index: 1,
      outcomeId: 2958188933,
      status: KambiFetchCouponRowStatus.LOST,
      playedOdds: 2000,
      bestOddsGuaranteed: false,

      selectionType: 'SIMPLE',
    },
    {
      index: 2,
      outcomeId: 2958183595,
      status: KambiFetchCouponRowStatus.PLACED,
      playedOdds: 2000,
      bestOddsGuaranteed: false,

      selectionType: 'SIMPLE',
    },
  ],
  events: [
    {
      eventId: 1008530827,
      sport: 'FOOTBALL',
      homeName: 'Monaco',
      awayName: 'Lille',
      eventName: 'Monaco - Lille',
      eventGroups: [
        {
          id: 1000093190,
          name: 'Futebol',
        },
        {
          id: 1000461727,
          name: 'França',
        },
        {
          id: 1000094991,
          name: 'Liga 1',
        },
      ],
      eventStartDate: '2023-05-05T00:30:00Z',
    },
    {
      eventId: 1008530826,
      sport: 'FOOTBALL',
      homeName: 'Crystal Palace',
      awayName: 'Tottenham',
      eventName: 'Crystal Palace - Tottenham',
      eventGroups: [
        {
          id: 1000093190,
          name: 'Futebol',
        },
        {
          id: 1000461733,
          name: 'Inglaterra',
        },
        {
          id: 1000094985,
          name: 'Premier League',
        },
      ],
      eventStartDate: '2023-05-05T02:00:00Z',
    },
    {
      eventId: 1008530893,
      sport: 'FOOTBALL',
      homeName: 'Liverpool',
      awayName: 'Stoke City',
      eventName: 'Liverpool - Stoke City',
      eventGroups: [
        {
          id: 1000093190,
          name: 'Futebol',
        },
        {
          id: 1000461733,
          name: 'Inglaterra',
        },
        {
          id: 1000094985,
          name: 'Premier League',
        },
      ],
      eventStartDate: '2023-05-06T02:00:00Z',
    },
  ],
  betOffers: [
    {
      betOfferId: 2282592026,
      boType: 'Match',
      boTypeId: 2,
      criterion: 'Tempo Regulamentar',
    },
    {
      betOfferId: 2282590289,
      boType: 'Match',
      boTypeId: 2,
      criterion: 'Tempo Regulamentar',
    },
    {
      betOfferId: 2282590146,
      boType: 'Match',
      boTypeId: 2,
      criterion: 'Tempo Regulamentar',
    },
  ],
};

const mockedCouponTreble: KambiCouponsResponse = {
  couponRef: 4353434522,
  couponExternalRef: 'ec7fe7d3-537d-4986-8669-4ec9c495dd51',
  placedDate: '2023-05-04T09:52:08.497Z',
  currency: 'BRL',
  channel: 'WEB',
  bets: [
    {
      betRef: 7153432605,
      couponRowIndexes: [0, 1, 2],
      betOdds: 8000,
      playedOdds: 8000,
      betStatus: KambiFetchBetStatus.OPEN,
      stake: 5000,
      payout: 0,
      potentialPayout: 40000,
      potentialPayoutBoosted: 3000,
    },
  ],
  outcomes: [
    {
      outcomeId: 2958188933,
      eventId: 1008530893,
      betOfferId: 2282592026,
      label: 'X',
      status: KambiFetchOutcomeStatus.OPEN,
      outcomeTags: [],
    },
    {
      outcomeId: 2958183073,
      eventId: 1008530826,
      betOfferId: 2282590146,
      label: 'X',
      status: KambiFetchOutcomeStatus.OPEN,
      outcomeTags: [],
    },
    {
      outcomeId: 2958183595,
      eventId: 1008530827,
      betOfferId: 2282590289,
      label: 'X',
      status: KambiFetchOutcomeStatus.OPEN,
      outcomeTags: [],
    },
  ],
  couponRows: [
    {
      index: 0,
      outcomeId: 2958183073,
      status: KambiFetchCouponRowStatus.OPEN,
      playedOdds: 2000,
      bestOddsGuaranteed: false,

      selectionType: 'SIMPLE',
    },
    {
      index: 1,
      outcomeId: 2958188933,
      status: KambiFetchCouponRowStatus.OPEN,
      playedOdds: 2000,
      bestOddsGuaranteed: false,

      selectionType: 'SIMPLE',
    },
    {
      index: 2,
      outcomeId: 2958183595,
      status: KambiFetchCouponRowStatus.OPEN,
      playedOdds: 2000,
      bestOddsGuaranteed: false,

      selectionType: 'SIMPLE',
    },
  ],
  events: [
    {
      eventId: 1008530827,
      sport: 'FOOTBALL',
      homeName: 'Monaco',
      awayName: 'Lille',
      eventName: 'Monaco - Lille',
      eventGroups: [
        {
          id: 1000093190,
          name: 'Futebol',
        },
        {
          id: 1000461727,
          name: 'França',
        },
        {
          id: 1000094991,
          name: 'Liga 1',
        },
      ],
      eventStartDate: '2023-05-05T00:30:00Z',
    },
    {
      eventId: 1008530826,
      sport: 'FOOTBALL',
      homeName: 'Crystal Palace',
      awayName: 'Tottenham',
      eventName: 'Crystal Palace - Tottenham',
      eventGroups: [
        {
          id: 1000093190,
          name: 'Futebol',
        },
        {
          id: 1000461733,
          name: 'Inglaterra',
        },
        {
          id: 1000094985,
          name: 'Premier League',
        },
      ],
      eventStartDate: '2023-05-05T02:00:00Z',
    },
    {
      eventId: 1008530893,
      sport: 'FOOTBALL',
      homeName: 'Liverpool',
      awayName: 'Stoke City',
      eventName: 'Liverpool - Stoke City',
      eventGroups: [
        {
          id: 1000093190,
          name: 'Futebol',
        },
        {
          id: 1000461733,
          name: 'Inglaterra',
        },
        {
          id: 1000094985,
          name: 'Premier League',
        },
      ],
      eventStartDate: '2023-05-06T02:00:00Z',
    },
  ],
  betOffers: [
    {
      betOfferId: 2282592026,
      boType: 'Match',
      boTypeId: 2,
      criterion: 'Tempo Regulamentar',
    },
    {
      betOfferId: 2282590289,
      boType: 'Match',
      boTypeId: 2,
      criterion: 'Tempo Regulamentar',
    },
    {
      betOfferId: 2282590146,
      boType: 'Match',
      boTypeId: 2,
      criterion: 'Tempo Regulamentar',
    },
  ],
};

const mockedCouponPatent: KambiCouponsResponse = {
  couponRef: 4353434521,
  couponExternalRef: 'f91c4dc6-8fe1-4ff0-b4ec-0d76f0722bff',
  placedDate: '2023-05-04T09:51:54.011Z',
  currency: 'BRL',
  channel: 'WEB',
  bets: [
    {
      betRef: 7153432598,
      couponRowIndexes: [0],
      betOdds: 2000,
      playedOdds: 2000,
      betStatus: KambiFetchBetStatus.OPEN,
      stake: 5000,
      payout: 0,
      potentialPayout: 10000,
      potentialPayoutBoosted: 3000,
    },
    {
      betRef: 7153432599,
      couponRowIndexes: [1],
      betOdds: 2000,
      playedOdds: 2000,
      betStatus: KambiFetchBetStatus.OPEN,
      stake: 5000,
      payout: 0,
      potentialPayout: 10000,
      potentialPayoutBoosted: 3000,
    },
    {
      betRef: 7153432600,
      couponRowIndexes: [0, 1],
      betOdds: 4000,
      playedOdds: 4000,
      betStatus: KambiFetchBetStatus.OPEN,
      stake: 5000,
      payout: 0,
      potentialPayout: 20000,
      potentialPayoutBoosted: 3000,
    },
    {
      betRef: 7153432601,
      couponRowIndexes: [2],
      betOdds: 2000,
      playedOdds: 2000,
      betStatus: KambiFetchBetStatus.OPEN,
      stake: 5000,
      payout: 0,
      potentialPayout: 10000,
      potentialPayoutBoosted: 3000,
    },
    {
      betRef: 7153432602,
      couponRowIndexes: [0, 2],
      betOdds: 4000,
      playedOdds: 4000,
      betStatus: KambiFetchBetStatus.OPEN,
      stake: 5000,
      payout: 0,
      potentialPayout: 20000,
      potentialPayoutBoosted: 3000,
    },
    {
      betRef: 7153432603,
      couponRowIndexes: [1, 2],
      betOdds: 4000,
      playedOdds: 4000,
      betStatus: KambiFetchBetStatus.OPEN,
      stake: 5000,
      payout: 0,
      potentialPayout: 20000,
      potentialPayoutBoosted: 3000,
    },
    {
      betRef: 7153432604,
      couponRowIndexes: [0, 1, 2],
      betOdds: 8000,
      playedOdds: 8000,
      betStatus: KambiFetchBetStatus.OPEN,
      stake: 5000,
      payout: 0,
      potentialPayout: 40000,
      potentialPayoutBoosted: 3000,
    },
  ],
  outcomes: [
    {
      outcomeId: 2958188933,
      eventId: 1008530893,
      betOfferId: 2282592026,
      label: 'X',
      status: KambiFetchOutcomeStatus.OPEN,
      outcomeTags: [],
    },
    {
      outcomeId: 2958183073,
      eventId: 1008530826,
      betOfferId: 2282590146,
      label: 'X',
      status: KambiFetchOutcomeStatus.OPEN,
      outcomeTags: [],
    },
    {
      outcomeId: 2958183595,
      eventId: 1008530827,
      betOfferId: 2282590289,
      label: 'X',
      status: KambiFetchOutcomeStatus.OPEN,
      outcomeTags: [],
    },
  ],
  couponRows: [
    {
      index: 0,
      outcomeId: 2958183073,
      status: KambiFetchCouponRowStatus.OPEN,
      playedOdds: 2000,
      bestOddsGuaranteed: false,

      selectionType: 'SIMPLE',
    },
    {
      index: 1,
      outcomeId: 2958188933,
      status: KambiFetchCouponRowStatus.OPEN,
      playedOdds: 2000,
      bestOddsGuaranteed: false,

      selectionType: 'SIMPLE',
    },
    {
      index: 2,
      outcomeId: 2958183595,
      status: KambiFetchCouponRowStatus.OPEN,
      playedOdds: 2000,
      bestOddsGuaranteed: false,

      selectionType: 'SIMPLE',
    },
  ],
  events: [
    {
      eventId: 1008530827,
      sport: 'FOOTBALL',
      homeName: 'Monaco',
      awayName: 'Lille',
      eventName: 'Monaco - Lille',
      eventGroups: [
        {
          id: 1000093190,
          name: 'Futebol',
        },
        {
          id: 1000461727,
          name: 'França',
        },
        {
          id: 1000094991,
          name: 'Liga 1',
        },
      ],
      eventStartDate: '2023-05-05T00:30:00Z',
    },
    {
      eventId: 1008530826,
      sport: 'FOOTBALL',
      homeName: 'Crystal Palace',
      awayName: 'Tottenham',
      eventName: 'Crystal Palace - Tottenham',
      eventGroups: [
        {
          id: 1000093190,
          name: 'Futebol',
        },
        {
          id: 1000461733,
          name: 'Inglaterra',
        },
        {
          id: 1000094985,
          name: 'Premier League',
        },
      ],
      eventStartDate: '2023-05-05T02:00:00Z',
    },
    {
      eventId: 1008530893,
      sport: 'FOOTBALL',
      homeName: 'Liverpool',
      awayName: 'Stoke City',
      eventName: 'Liverpool - Stoke City',
      eventGroups: [
        {
          id: 1000093190,
          name: 'Futebol',
        },
        {
          id: 1000461733,
          name: 'Inglaterra',
        },
        {
          id: 1000094985,
          name: 'Premier League',
        },
      ],
      eventStartDate: '2023-05-06T02:00:00Z',
    },
  ],
  betOffers: [
    {
      betOfferId: 2282592026,
      boType: 'Match',
      boTypeId: 2,
      criterion: 'Tempo Regulamentar',
    },
    {
      betOfferId: 2282590289,
      boType: 'Match',
      boTypeId: 2,
      criterion: 'Tempo Regulamentar',
    },
    {
      betOfferId: 2282590146,
      boType: 'Match',
      boTypeId: 2,
      criterion: 'Tempo Regulamentar',
    },
  ],
};

// Instead of three singles a player bets on the doubles of three selections (via the system bet option)
const mockedCouponExceptionOfTheRules: KambiCouponsResponse = {
  couponRef: 4353434524,
  couponExternalRef: '56e1d8b7-6ebd-4294-9892-d1dae92dee5a',
  placedDate: '2023-05-04T10:51:27.834Z',
  currency: 'BRL',
  channel: 'WEB',
  bets: [
    {
      betRef: 7153432609,
      couponRowIndexes: [0, 1],
      betOdds: 4000,
      playedOdds: 4000,
      betStatus: KambiFetchBetStatus.OPEN,
      stake: 5000,
      payout: 0,
      potentialPayout: 20000,
      potentialPayoutBoosted: 3000,
    },
    {
      betRef: 7153432610,
      couponRowIndexes: [0, 2],
      betOdds: 4000,
      playedOdds: 4000,
      betStatus: KambiFetchBetStatus.OPEN,
      stake: 5000,
      payout: 0,
      potentialPayout: 20000,
      potentialPayoutBoosted: 3000,
    },
    {
      betRef: 7153432611,
      couponRowIndexes: [1, 2],
      betOdds: 4000,
      playedOdds: 4000,
      betStatus: KambiFetchBetStatus.OPEN,
      stake: 5000,
      payout: 0,
      potentialPayout: 20000,
      potentialPayoutBoosted: 3000,
    },
  ],
  outcomes: [
    {
      outcomeId: 2958188933,
      eventId: 1008530893,
      betOfferId: 2282592026,
      label: 'X',
      status: KambiFetchOutcomeStatus.OPEN,
      outcomeTags: [],
    },
    {
      outcomeId: 2958183073,
      eventId: 1008530826,
      betOfferId: 2282590146,
      label: 'X',
      status: KambiFetchOutcomeStatus.OPEN,
      outcomeTags: [],
    },
    {
      outcomeId: 2958183595,
      eventId: 1008530827,
      betOfferId: 2282590289,
      label: 'X',
      status: KambiFetchOutcomeStatus.OPEN,
      outcomeTags: [],
    },
  ],
  couponRows: [
    {
      index: 0,
      outcomeId: 2958183073,
      status: KambiFetchCouponRowStatus.OPEN,
      playedOdds: 2000,
      bestOddsGuaranteed: false,

      selectionType: 'SIMPLE',
    },
    {
      index: 1,
      outcomeId: 2958188933,
      status: KambiFetchCouponRowStatus.OPEN,
      playedOdds: 2000,
      bestOddsGuaranteed: false,

      selectionType: 'SIMPLE',
    },
    {
      index: 2,
      outcomeId: 2958183595,
      status: KambiFetchCouponRowStatus.OPEN,
      playedOdds: 2000,
      bestOddsGuaranteed: false,

      selectionType: 'SIMPLE',
    },
  ],
  events: [
    {
      eventId: 1008530827,
      sport: 'FOOTBALL',
      homeName: 'Monaco',
      awayName: 'Lille',
      eventName: 'Monaco - Lille',
      eventGroups: [
        {
          id: 1000093190,
          name: 'Futebol',
        },
        {
          id: 1000461727,
          name: 'França',
        },
        {
          id: 1000094991,
          name: 'Liga 1',
        },
      ],
      eventStartDate: '2023-05-05T00:30:00Z',
    },
    {
      eventId: 1008530826,
      sport: 'FOOTBALL',
      homeName: 'Crystal Palace',
      awayName: 'Tottenham',
      eventName: 'Crystal Palace - Tottenham',
      eventGroups: [
        {
          id: 1000093190,
          name: 'Futebol',
        },
        {
          id: 1000461733,
          name: 'Inglaterra',
        },
        {
          id: 1000094985,
          name: 'Premier League',
        },
      ],
      eventStartDate: '2023-05-05T02:00:00Z',
    },
    {
      eventId: 1008530893,
      sport: 'FOOTBALL',
      homeName: 'Liverpool',
      awayName: 'Stoke City',
      eventName: 'Liverpool - Stoke City',
      eventGroups: [
        {
          id: 1000093190,
          name: 'Futebol',
        },
        {
          id: 1000461733,
          name: 'Inglaterra',
        },
        {
          id: 1000094985,
          name: 'Premier League',
        },
      ],
      eventStartDate: '2023-05-06T02:00:00Z',
    },
  ],
  betOffers: [
    {
      betOfferId: 2282592026,
      boType: 'Match',
      boTypeId: 2,
      criterion: 'Tempo Regulamentar',
    },
    {
      betOfferId: 2282590289,
      boType: 'Match',
      boTypeId: 2,
      criterion: 'Tempo Regulamentar',
    },
    {
      betOfferId: 2282590146,
      boType: 'Match',
      boTypeId: 2,
      criterion: 'Tempo Regulamentar',
    },
  ],
};

const mockedBetBuilderBet: KambiCouponsResponse = {
  ...mockedCouponSimple,
  couponRef: 4353434588,
  couponRows: [
    {
      ...mockedCouponSimple.couponRows[0],
      selectionType: 'BET_BUILDER',
    },
  ],
} as KambiCouponsResponse;

const mockedCouponWrong: KambiCouponsResponse = {
  couponRef: 4353434554,
  bets: [
    {
      betRef: 7153432598,
      couponRowIndexes: [0],
    },
  ],
} as KambiCouponsResponse;

export {
  mockedCouponSimple,
  mockedCoupon3xSingles,
  mockedCouponTreble,
  mockedCouponPatent,
  mockedCouponExceptionOfTheRules,
  mockedBetBuilderBet,
  mockedCouponWrong,
};
