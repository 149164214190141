import { countries } from '../../../utils/loqate/locale';
import { SelectOption } from '../Select';

export const countryCodeOptions: SelectOption[] = [
  'AD',
  'AL',
  'AM',
  'AT',
  'AU',
  'AZ',
  'BA',
  'BE',
  'BG',
  'BR',
  'BY',
  'CH',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FO',
  'FR',
  'GB',
  'GE',
  'GI',
  'GL',
  'GR',
  'HR',
  'HU',
  'IE',
  'IS',
  'IT',
  'LI',
  'LT',
  'LU',
  'LV',
  'MC',
  'MD',
  'ME',
  'MK',
  'MT',
  'NL',
  'NO',
  'NZ',
  'PL',
  'PT',
  'RO',
  'RS',
  'RU',
  'SE',
  'SI',
  'SK',
  'SM',
  'TR',
  'UA',
  'US',
  'XK',
].map((countryCode) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { phone, name } = (countries as any)[countryCode];
  const phoneCode = `+${phone}`;

  return {
    name,
    label: phoneCode,
    value: phoneCode,
    search: `${phoneCode}-${name}`,
    customDropDownItemRender: (label: string): JSX.Element => (
      <span className="select-non-native__span">
        <span>{name}</span>
        <span>{label}</span>
      </span>
    ),
  };
});
