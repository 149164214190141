import React, { FC, useEffect, useRef, useState } from 'react';
// import cookie from 'js-cookie';
import { MediaBreakpoint, useBreakpointInfo } from '../../../hooks/useDeviceInfo';
import { usePersistentHorizontalScroll } from '../../../hooks/presentation-logic';
import { Button } from '../../shared/Button';
import { Chip } from '../../shared/Chip';
import { joinStrings } from '../../../utils/string';
// import { ExtendedPromotionCategories } from '../Navigation';
import './styles';
import { PromotionCategoryLink } from '../types';
import { useDispatch } from 'react-redux';
import { setPromotionTabs } from '../../../state/promotions/reducers';

type PromotionsCategoryProps = {
  categories: PromotionCategoryLink[];
  setActivCategory: (identificator: string) => void;
  // Skew value to correctly align assistive navigation tabs after being centered
  skew?: number;
};

const scrollSubCategories = (): void => {
  const subCategories = document.getElementById('promotion-category__navigation');

  if (subCategories) {
    const position = { left: 0, x: 0 };

    const mouseMoveHandler = (event: MouseEvent): void => {
      const dx = event.clientX - position.x;

      subCategories.scrollLeft = position.left - dx;
    };

    const mouseUpHandler = (): void => {
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);
    };

    const mouseDownHandler = (event: MouseEvent): void => {
      position.left = subCategories.scrollLeft;
      position.x = event.clientX;

      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    };

    subCategories.addEventListener('mousedown', mouseDownHandler);
  }
};

export const PromotionCategory: FC<PromotionsCategoryProps> = ({ categories = [] }) => {
  const isMobile = useBreakpointInfo([MediaBreakpoint.BELOW_MOBILE]);
  const scrollLeft = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const [activeIndex, setActveIndex] = useState<number>(0);
  const [activeIcon, setActveIcon] = useState<string>('');

  usePersistentHorizontalScroll(scrollLeft, 'category-navigation-horizontal-position');

  useEffect(() => {
    scrollSubCategories();
  }, []);

  return categories.length ? (
    <div
      ref={scrollLeft}
      className="promotion-category__navigation"
      data-testid="promotion-category-navigation"
      id="promotion-category__navigation"
    >
      {categories.map(({ iconLink, activeIconLink, label, type }, index) => {
        const isActive = activeIndex === index;
        const targetIcon = isActive ? activeIconLink : iconLink;
        const targetHoverIcon = activeIconLink === activeIcon && activeIconLink;

        return (
          <Button
            type="button"
            className="promotion-category__navigation-item"
            size="unset"
            variant="text"
            onClick={(): void => {
              setActveIndex(index);
              dispatch(setPromotionTabs(type));
            }}
            onMouseEnter={(): void => {
              activeIconLink && setActveIcon(activeIconLink);
            }}
            onMouseLeave={(): void => {
              setActveIcon('');
            }}
            key={`${label}-${index}`}
          >
            <Chip
              variant="rounded"
              className={joinStrings([isActive && 'active', !isMobile && 'promotion-category--medium-chip'])}
            >
              {iconLink && (
                <img
                  alt="category chip"
                  className="promotion-category__navigation-item-icon"
                  src={targetHoverIcon || targetIcon}
                />
              )}
              {label}
            </Chip>
          </Button>
        );
      })}
    </div>
  ) : null;
};
