import React, { FunctionComponent, useState, Fragment } from 'react';
import { Icon } from '../../../../shared/Icon';
import { PlayerWallets } from '../../../../../models/player-profile.model';

type RewardProps = Pick<PlayerWallets, 'expiryDate' | 'balance'>;

// TODO: update this component
export type RewardItemProps = {
  total: number;
  expiryDate: string;
  rewards: RewardProps[];
  onExpand: (state: boolean) => void;
  isBonusDetails: boolean;
};

export const RewardItem: FunctionComponent<RewardItemProps> = ({
  total,
  expiryDate,
  rewards,
  onExpand,
  isBonusDetails,
}) => {
  const renderDetails = rewards.length > 1;

  const [showDetails, showDetailsSet] = useState(false);

  const onToggleDetails = (e: React.MouseEvent | React.KeyboardEvent): void => {
    e.stopPropagation();
    onExpand(!showDetails);
    showDetailsSet(!showDetails);
  };

  return (
    <div className="balance-details__item">
      <div className="balance-details__item-summary">
        <span className="balance-details__item-summary-title">header-balance-details-item-reward-heading</span>
        <span className="balance-details__item-summary-amount">total and currency</span>
        <span className="balance-details__item-summary-expire">
          {expiryDate ? 'header-balance-details-next-to-expire' : ''}
        </span>
        {renderDetails && (
          <span
            tabIndex={0}
            className="balance-details__item-summary-expand"
            onClick={onToggleDetails}
            onKeyPress={onToggleDetails}
            role="switch"
            aria-checked={showDetails}
          >
            {isBonusDetails ? (
              showDetails ? (
                <Icon variant="ChevronLeft" />
              ) : (
                <Icon variant="ChevronRight" />
              )
            ) : showDetails ? (
              'header-balance-details-hide-details'
            ) : (
              'header-balance-details-show-details'
            )}
          </span>
        )}
      </div>

      <div className={`balance-details__item-details${showDetails ? ' open' : ''}`}>
        <div className="balance-details__item-divider" />
        <div className="balance-details__item-details-row">
          {rewards.map(({ expiryDate, balance }, index) => (
            <Fragment key={`balance-details-reward-item-${index}`}>
              <span className="balance-details__item-details-text">
                {expiryDate ? 'header-balance-details-expires-in' : ''}
              </span>
              <span className="balance-details__item-details-amount">balance and currency</span>
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};
