import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserProfileBonusClaimMutationVariables = Types.Exact<{
  xTenantId: Types.Scalars['ID']['input'];
  userProfileId: Types.Scalars['ID']['input'];
  userProfileBonusId: Types.Scalars['ID']['input'];
}>;


export type UserProfileBonusClaimMutation = { __typename?: 'Mutation', userProfileBonusClaim?: any | null };


export const UserProfileBonusClaimDocument = gql`
    mutation UserProfileBonusClaim($xTenantId: ID!, $userProfileId: ID!, $userProfileBonusId: ID!) {
  userProfileBonusClaim(
    xTenantID: $xTenantId
    userProfileId: $userProfileId
    userProfileBonusId: $userProfileBonusId
  )
}
    `;
export type UserProfileBonusClaimMutationFn = Apollo.MutationFunction<UserProfileBonusClaimMutation, UserProfileBonusClaimMutationVariables>;

/**
 * __useUserProfileBonusClaimMutation__
 *
 * To run a mutation, you first call `useUserProfileBonusClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserProfileBonusClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userProfileBonusClaimMutation, { data, loading, error }] = useUserProfileBonusClaimMutation({
 *   variables: {
 *      xTenantId: // value for 'xTenantId'
 *      userProfileId: // value for 'userProfileId'
 *      userProfileBonusId: // value for 'userProfileBonusId'
 *   },
 * });
 */
export function useUserProfileBonusClaimMutation(baseOptions?: Apollo.MutationHookOptions<UserProfileBonusClaimMutation, UserProfileBonusClaimMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserProfileBonusClaimMutation, UserProfileBonusClaimMutationVariables>(UserProfileBonusClaimDocument, options);
      }
export type UserProfileBonusClaimMutationHookResult = ReturnType<typeof useUserProfileBonusClaimMutation>;
export type UserProfileBonusClaimMutationResult = Apollo.MutationResult<UserProfileBonusClaimMutation>;
export type UserProfileBonusClaimMutationOptions = Apollo.BaseMutationOptions<UserProfileBonusClaimMutation, UserProfileBonusClaimMutationVariables>;