import React, { FunctionComponent, useEffect } from 'react';

import { PromotionCard } from '../PromotionCard';
import { ParsedPromo } from '../types';
import { useDispatch, useSelector } from 'react-redux';
import { productTabName, promotionTabName } from '../../../../state/promotions';
import { ProductType, PromotionTabsType } from '../PromotionsList/types';
import { PromotionBonusDetails } from '../PromotionBonusDetails';
import { setPromotionTabs } from '../../../../state/promotions/reducers';
import './styles';
import { PromotionsEmpty } from './PromotionsEmpty';

type PromotionsBlockProps = {
  isActivePromotions?: boolean;
  isUsualPromotions?: boolean;
  title: string;
  className?: string;
  promotionsFiltered: ParsedPromo[];
  promotionsHistory: ParsedPromo[];
};

export const PromotionsBlock: FunctionComponent<PromotionsBlockProps> = ({
  className = '',
  promotionsFiltered,
  promotionsHistory,
}) => {
  const dispatch = useDispatch();
  const currentPromotionsTab = useSelector(promotionTabName);
  const currentProductTab = useSelector(productTabName);
  const isBonusDetails = currentPromotionsTab === PromotionTabsType.BONUS_DETAILS;
  const isActivePromotions = currentPromotionsTab === PromotionTabsType.ACTIVE;
  const catString = isActivePromotions ? currentPromotionsTab : currentProductTab;
  const noPromosStr = catString === ProductType.ALL ? '' : catString;

  useEffect(() => {
    return () => {
      dispatch(setPromotionTabs(PromotionTabsType.PROMOTIONS));
    };
  }, []);

  return (
    <div className={`promotions-block ${className}`}>
      {isBonusDetails ? (
        <PromotionBonusDetails promotions={promotionsFiltered} promotionsHistory={promotionsHistory} />
      ) : promotionsFiltered?.length ? (
        <div className="promotions-block__cards">
          {promotionsFiltered?.map((promo, index) => (
            <PromotionCard key={`${promo.bonusId}-${index}`} isActivePromotions={isActivePromotions} promo={promo} />
          ))}
        </div>
      ) : (
        <PromotionsEmpty noPromosStr={noPromosStr} />
      )}
    </div>
  );
};
