import React, { FunctionComponent } from 'react';
import { Heading } from '../../../shared/Heading';
import { joinStrings } from '../../../../utils/string';
import './styles';

/**
 * Card used to surround multiple sections on the Game Info page.
 */
export const GameInfoCard: FunctionComponent<{
  heading?: string;
  className?: string;
  children?: React.ReactNode;
}> = ({ heading, className, children }) => (
  <div className={joinStrings(['game-info-card', className])}>
    {heading && (
      <Heading level={5} className="game-info-card__heading">
        {heading}
      </Heading>
    )}

    {children}
  </div>
);
