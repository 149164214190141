import noop from 'lodash/noop';
import React, { FunctionComponent } from 'react';
import { Button } from '../../../shared/Button';

type WithdrawalCheckButtonsProps = {
  /**
   * Class name for styling
   */
  className: string;
  /**
   * Label for primary button
   */
  primaryBtnLabel: string;
  /**
   * Label for secondary button
   */
  secondaryBtnLabel: string;
  /**
   * Show loading spinner for the primary button when sending a request.
   */
  isSubmitting?: boolean;
  /**
   * Callback for primary button click
   */
  onPrimaryBtnClick: () => void;
  /**
   * Callback for secondary button click
   */
  onSecondaryBtnClick: () => void;
};

/**
 * Component renders buttons for withdrawal check
 */
export const WithdrawalCheckButtons: FunctionComponent<WithdrawalCheckButtonsProps> = ({
  className,
  primaryBtnLabel,
  secondaryBtnLabel,
  isSubmitting,
  onPrimaryBtnClick = noop,
  onSecondaryBtnClick = noop,
}) => {
  return (
    <div className={className}>
      <Button size="large" type="submit" variant="secondary" onClick={onSecondaryBtnClick}>
        {secondaryBtnLabel}
      </Button>
      <Button size="large" type="submit" variant="primary" onClick={onPrimaryBtnClick} isSubmitting={isSubmitting}>
        {primaryBtnLabel}
      </Button>
    </div>
  );
};
