import { TooltipAlignment, TooltipPosition } from './Tooltip';

/**
 * Check position arg against viewport boundaries.
 *
 * Returns the position arg if tooltip would be onscreen otherwise returns an alternative which would be.
 * @param position
 * @param tooltipRect
 * @param containerRect
 * @param tooltipMargin
 */
export const getBoundaryCheckedPosition = (
  position: TooltipPosition,
  tooltipRect: DOMRect,
  containerRect: DOMRect,
  tooltipMargin: number
): TooltipPosition => {
  const topIsOnScreen = tooltipMargin + tooltipRect.height <= containerRect.top;
  const bottomIsOnscreen = tooltipMargin + tooltipRect.height + containerRect.bottom < window.innerHeight;
  const leftIsOnscreen = tooltipRect.width + tooltipMargin <= containerRect.left;
  const rightIsOnscreen = tooltipRect.width + tooltipMargin + containerRect.right < window.innerWidth;

  switch (position) {
    case 'top': {
      if (topIsOnScreen) return 'top';
      else return 'bottom';
    }

    case 'bottom': {
      if (bottomIsOnscreen) return 'bottom';
      else return 'top';
    }

    case 'left': {
      if (leftIsOnscreen) return 'left';
      else if (rightIsOnscreen) return 'right';
      else if (topIsOnScreen) return 'top';
      else return 'bottom';
    }

    case 'right': {
      if (rightIsOnscreen) return 'right';
      else if (leftIsOnscreen) return 'left';
      else if (topIsOnScreen) return 'top';
      else return 'bottom';
    }
  }
};

const getVerticalPositioningMargins = (
  tooltipRect: DOMRect,
  containerRect: DOMRect,
  tooltipMargin: number,
  alignment: TooltipAlignment
): { marginY: string; marginX: string } => {
  let marginX;

  switch (alignment) {
    case 'center':
      marginX = -(tooltipRect.width / 2 - containerRect.width / 2);
      break;
    case 'start':
      marginX = 0;
      break;
    case 'end':
      marginX = -tooltipRect.width + containerRect.width;
      break;
  }

  return {
    marginY: `${tooltipRect.height + tooltipMargin}px`,
    marginX: `${containerRect.left + marginX < 0 ? 0 : marginX}px`,
  };
};

const getHorizontalPositioningMargins = (
  tooltipRect: DOMRect,
  containerRect: DOMRect,
  tooltipMargin: number,
  alignment: TooltipAlignment
): { marginY: string; marginX: string } => {
  let marginY: number;

  switch (alignment) {
    case 'center':
      marginY = -(tooltipRect.height / 2 + containerRect.height / 2);
      break;
    case 'start':
      marginY = -containerRect.height;
      break;
    case 'end':
      marginY = -tooltipRect.height;
      break;
  }

  return {
    marginX: `${tooltipRect.width + tooltipMargin}px`,
    marginY: `${containerRect.top + marginY < 0 ? -containerRect.height : marginY}px`,
  };
};

/**
 * Calculate tooltip placement styles based upon position arg.
 *
 * Note that this function is completely ignorant of viewport.
 * Therefore the usage of getBoundaryCheckedPosition should be made to provide a suitable position arg which
 * takes into account whether tooltip would be on screen.
 * @param position
 * @param tooltipRect
 * @param containerRect
 * @param tooltipMargin
 */
export const getTooltipPositionStyles = (
  position: TooltipPosition,
  tooltipRect: DOMRect,
  containerRect: DOMRect,
  tooltipMargin: number,
  alignment: TooltipAlignment
): React.CSSProperties => {
  switch (position) {
    case 'top': {
      const { marginX, marginY } = getVerticalPositioningMargins(
        tooltipRect,
        containerRect,
        // addition of container height is to move tip to top of container before positioning it
        tooltipMargin + containerRect.height,
        alignment
      );

      return {
        top: 0,
        left: 0,
        transform: `translate(${marginX}, -${marginY})`,
      };
    }

    case 'bottom': {
      const { marginX, marginY } = getVerticalPositioningMargins(tooltipRect, containerRect, tooltipMargin, alignment);

      return {
        bottom: 0,
        left: 0,
        transform: `translate(${marginX}, ${marginY})`,
      };
    }

    case 'left': {
      const { marginX, marginY } = getHorizontalPositioningMargins(
        tooltipRect,
        containerRect,
        tooltipMargin,
        alignment
      );

      return {
        left: 0,
        top: 0,
        transform: `translate(-${marginX}, ${marginY})`,
      };
    }

    case 'right': {
      const { marginX, marginY } = getHorizontalPositioningMargins(
        tooltipRect,
        containerRect,
        tooltipMargin,
        alignment
      );

      return {
        top: 0,
        right: 0,
        transform: `translate(${marginX}, ${marginY})`,
      };
    }
  }
};
