import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from './types';

const initialState: AppState = {
  isNavigationExpanded: false,
  isSearchBarOpen: false,
  isGeoLocationAllowed: true,
  isErrorDuringGeoCheck: false,
  selectedCategory: '',
};

export const appSlice = createSlice({
  name: 'app',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setIsNavigationExpanded: (state: AppState, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isNavigationExpanded: action.payload,
      };
    },
    setIsSearchBarOpen: (state: AppState, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isSearchBarOpen: action.payload,
      };
    },
    setIsGeoLocationAllowed: (state: AppState, action: PayloadAction<boolean | undefined>) => {
      return {
        ...state,
        isGeoLocationAllowed: action.payload,
      };
    },
    setIsErrorDuringGeoCheck: (state: AppState, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isErrorDuringGeoCheck: action.payload,
      };
    },
    setSelectedCategory: (state: AppState, action: PayloadAction<string>) => {
      const { payload } = action;

      return {
        ...state,
        selectedCategory: payload === state.selectedCategory ? '' : payload,
      };
    },
  },
});

export const {
  setIsNavigationExpanded,
  setIsSearchBarOpen,
  setIsGeoLocationAllowed,
  setIsErrorDuringGeoCheck,
  setSelectedCategory,
} = appSlice.actions;

export default appSlice.reducer;
