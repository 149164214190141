import Gamstop from '../../shared/SVG/Registration/GamStop.svg';
import AgeIcon from '../../shared/SVG/Registration/18.svg';
import GamCommission from '../../shared/SVG/Registration/gambling-commission.svg';

export type RegistrationFooterImage = {
  imagePath: string;
  imageLabel: string;
  imageLink?: string;
};

export const images: RegistrationFooterImage[] = [
  {
    imagePath: AgeIcon,
    imageLabel: '18+',
    imageLink: undefined,
  },
  {
    imagePath: GamCommission,
    imageLabel: 'Gambling Commission',
    imageLink: 'https://www.gamblingcommission.gov.uk/',
  },
  {
    imagePath: Gamstop,
    imageLabel: 'GamStop',
    imageLink: 'https://www.gamstop.co.uk/',
  },
];
