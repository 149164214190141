import noop from 'lodash/noop';
import React, { FC } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import { xTenantId } from '../../../../consts';
import { useSignIn } from '../../../../hooks/auth';
import { useSnackbar } from '../../../../hooks/snackbar';
import { useDeviceInfo } from '../../../../hooks/useDeviceInfo';
import { useUserProfileBonusClaimMutation } from '../../../../libs/graphql/baseAppAPI/mutations/__generated__/apply-promo.mutation.generated';
import { BonusHistoryItem } from '../../../../models/bonuses.model';
import { useUserContext } from '../../../../providers/UserProvider';
import WelcomeBonusJourney from '../../../Account/WelcomeBonusJourney';
import { Paragraph } from '../../../shared/Paragraph';
import { PopUpHandler } from '../../../shared/PopupHandler';
import { Prompt } from '../../../shared/Prompt';
import { PromotionPreDepositContent } from '../PromotionPreDepositJourney/PromotionPreDepositContent';
import { ParsedPromo } from '../types';
import './PromotionMessagesPrompt.scss';

export type PromotionMessagesPromptType = 'default' | 'cancellation' | 'fixed' | 'firstDeposit';

export type PromotionCancellationProps = {
  ctaTextSecondary?: string;
  ctaTextPrimary?: string;
  messageTitle?: string;
  declineBonusHandler?: () => void;
};

export type PromotionMessagesPromptProps = {
  type?: PromotionMessagesPromptType;
  /**
   * Whether to show the Modal/Drawer. Defaults to false
   */
  show: boolean;
  setShow?: (arg: boolean) => void;
  imgMobilePath?: string;
  imgMobileAlt?: string;
  paragraphText?: string;
  /**
   * Called when prompt should be hidden.
   */
  promoCode?: string;
  onClose: () => void;
  siteLink?: string;
  activatedPromo: ParsedPromo | BonusHistoryItem;
  onActivateBonus?: () => void;
  loading?: boolean;
} & PromotionCancellationProps;

export const PromotionMessagesPrompt: FC<PromotionMessagesPromptProps> = ({
  type = 'default',
  show,
  setShow,
  onClose,
  imgMobilePath,
  imgMobileAlt,
  paragraphText,
  ctaTextSecondary,
  ctaTextPrimary,
  messageTitle,
  promoCode = '',
  declineBonusHandler = noop,
  activatedPromo,
}): JSX.Element => {
  const parsedActivatedPromo = activatedPromo as ParsedPromo;
  const gameId =
    parsedActivatedPromo?.successCTAGameInformation?.gameId || parsedActivatedPromo?.successCTAGameInformation?.gameId;

  const navigate = useNavigate();
  const { userProfileId } = useUserContext();
  const { addSnack } = useSnackbar();
  const auth = useAuth();
  const signIn = useSignIn();

  const isUserAuthenticated = auth?.isAuthenticated;

  const [promoStandardMutation, { loading }] = useUserProfileBonusClaimMutation({
    variables: {
      xTenantId: xTenantId,
      userProfileId,
      // TODO: Change it to pure id instead of template id
      userProfileBonusId: String(parsedActivatedPromo?.id),
    },
    refetchQueries: ['UserBonuses', 'BonusesGetAll'],
    // TODO: Replace text content by translations
    onCompleted: () => {
      setShow?.(false);
    },
    // TODO: Replace text content by translations
    onError: (error) => {
      console.error(error);
      setShow?.(false);
      addSnack({
        theme: 'dark',
        type: 'error',
        message: `There is an error, please contact technical support`,
      });
    },
  });

  const onActivateBonus = async (): Promise<void> => {
    if (isUserAuthenticated) {
      try {
        await promoStandardMutation();
      } catch (error) {
        console.error(error);
      }
    } else {
      signIn();
    }
  };

  const handlePromptBtnClick = async (): Promise<void> => {
    // condition to check 'false' is related to request response from eyapi/v1/bonus
    if (parsedActivatedPromo?.promotionCTAType === 'GameLaunch' && gameId) {
      // TODO: Get game info and launch the fame
      const gameDetails = { name: 'test name' };

      if (gameDetails) {
        navigate('test-url');

        return;
      }
    }
    // redirect to ctaURL if gameDetails don't exist

    onActivateBonus?.();
  };

  const className = 'promotion-pre-deposit';
  const drawerProps = {
    className,
  };

  const isMobile = useDeviceInfo().isMobileDevice;

  const modalProps = {
    'aria-label': 'promotion-pre-deposit-modal-aria-label',
    className,
  };

  const onPreDepositPromptClose = (): void => {
    // Run exit deposit process
    onClose();
  };

  const handleDeclineBonus = (): void => {
    declineBonusHandler();

    onClose();
  };

  const onCloseUsingCTA = (): void => {
    onClose();
  };

  const onCloseUsingBackdrop = (): void => {
    onClose();
  };

  switch (type) {
    case 'default':
      return (
        <Prompt
          className="prompt--default"
          show={show}
          title={parsedActivatedPromo?.title || ''}
          onClose={onClose}
          image={{
            src: isMobile && imgMobilePath ? imgMobilePath : parsedActivatedPromo?.imgPath,
            alt: (isMobile && imgMobileAlt) || parsedActivatedPromo?.imgAlt,
          }}
          isSubmitting={loading}
          PrimaryButton={{
            onClick: handlePromptBtnClick,
            text: parsedActivatedPromo?.promoButtonText,
          }}
          SecondaryButton={
            ctaTextSecondary
              ? {
                  onClick: onCloseUsingCTA,
                  text: ctaTextSecondary,
                }
              : undefined
          }
        >
          <Paragraph noMargin size="sm">
            {paragraphText}
          </Paragraph>
        </Prompt>
      );
    case 'cancellation':
      return (
        <Prompt
          className="prompt--cancellation"
          show={show}
          title={messageTitle || parsedActivatedPromo?.title}
          onClose={onCloseUsingBackdrop}
          image={{
            src: isMobile && imgMobilePath ? imgMobilePath : parsedActivatedPromo?.imgPath,
            alt: parsedActivatedPromo?.title,
          }}
          PrimaryButton={{
            onClick: handleDeclineBonus,
            text: ctaTextPrimary || parsedActivatedPromo?.promoButtonText,
          }}
          SecondaryButton={
            ctaTextSecondary
              ? {
                  onClick: onCloseUsingCTA,
                  text: ctaTextSecondary,
                }
              : undefined
          }
        >
          <Paragraph noMargin size="sm">
            {paragraphText}
          </Paragraph>
        </Prompt>
      );
    case 'fixed':
      return (
        <PopUpHandler isOpen={show} onClose={onPreDepositPromptClose} modalProps={modalProps} drawerProps={drawerProps}>
          <PromotionPreDepositContent onClose={onClose} activatedPromo={parsedActivatedPromo} />
        </PopUpHandler>
      );
    case 'firstDeposit':
      return (
        <WelcomeBonusJourney
          isShown={show}
          onClose={onClose}
          isDepositJourney
          promoCode={promoCode}
          termsAndConditions={parsedActivatedPromo?.description}
          imgAlt={isMobile ? imgMobileAlt : parsedActivatedPromo?.imgAlt}
          imgPath={isMobile ? imgMobilePath : parsedActivatedPromo?.imgPath}
        />
      );
  }
};
