export interface IUserBalance {
  cash: string;
  bonus: string;
  total: string;
  withdrawal: number;
}
export const userBalance: IUserBalance = {
  cash: '£ 100.50',
  bonus: '£ 0.00',
  total: '£ 100.50',
  // eslint-disable-next-line prettier/prettier
  withdrawal: 10, // keep it without changing because there's no cashier logic in Self Exclusion
};
