import React, { FunctionComponent } from 'react';
import { Prompt } from '../../../shared/Prompt';
import './styles';
import { Link } from '../../../shared/Link';
import { Paragraph } from '../../../shared/Paragraph';

export type CancelBonusConfirmationPromptProps = {
  isOpen?: boolean | undefined;
  setIsOpen: (isOpen: boolean) => void;
  onDeclineClick: () => void;
  promoCode?: string;
};

export const CancelBonusConfirmationPrompt: FunctionComponent<CancelBonusConfirmationPromptProps> = ({
  isOpen,
  setIsOpen,
  onDeclineClick,
}) => {
  const img = `test-url`;

  const isOpenConfirmationModal = { value: true };

  const onClose = (): void => {
    // TODO: Close the prompt from store
    setIsOpen(false);
  };

  const onApplyBonus = (): void => {
    // TODO: Check DL and open Welcome Bonus calculator
    onClose();
  };

  const onDeclineBonus = (): void => {
    onClose();
    onDeclineClick();
  };

  const onMoreInfo = (): void => {
    // TODO: Mark in the store that prompt is swtched from confirmation
    onClose();
  };

  const onClosePrompt = (): void => {
    onClose();
  };

  const isOpenModal = isOpen || isOpenConfirmationModal.value;

  return (
    <Prompt
      className="cancel-bonus-confirmation"
      show={isOpenModal}
      title="You're about to decline the Welcome Offer Bonus!"
      onClose={onClosePrompt}
      image={{
        src: img,
        alt: 'Decline bonus confirmation image',
      }}
      PrimaryButton={{
        onClick: onDeclineBonus,
        text: 'I WANT TO DECLINE',
        buttonVariant: 'text',
      }}
      SecondaryButton={{
        onClick: onApplyBonus,
        text: 'GET WELCOME OFFER NOW',
        buttonVariant: 'primary',
      }}
    >
      <Paragraph size="sm">
        Welcome Offer Bonus only applies to the first time you make a deposit.{' '}
        <Link href={'welcome-bonus-page-url'} buttonStyle={{ variant: 'text', size: 'medium' }} onClick={onMoreInfo}>
          More info of how it works.
        </Link>
      </Paragraph>
    </Prompt>
  );
};
