import React, { FunctionComponent, ReactNode, useEffect, useRef, useState, useCallback } from 'react';
import { PopUpHandler } from '../../../shared/PopupHandler';
import { NotificationMessage } from '../../../shared/NotificationMessage';
import './Cashier.scss';
import { useDispatch, useSelector } from 'react-redux';
import { cashierModal, CashierType } from '../../../../state/cashier';
import { setCashierModal } from '../../../../state/cashier/reducers';
import { locale } from '../../../../consts';

import _PaymentIQCashier, { IPiqCashierApiMethods, IPiqCashierConfig } from 'paymentiq-cashier-bootstrapper';
import { useUserContext } from '../../../../providers/UserProvider';
import { useSnackbar } from '../../../../hooks/snackbar';
import { useTranslations } from '../../../../hooks/useTranslationsHelper';
import { fetchSessionId } from './service';

type TransactionStatus = 'success' | 'failure' | 'pending' | 'cancelled' | null;

export const Cashier: FunctionComponent = () => {
  const { t } = useTranslations();
  const cashierModalStore = useSelector(cashierModal);
  const dispatch = useDispatch();
  const { type, isOpen } = cashierModalStore;
  const cashierRef = useRef<HTMLDivElement>(null);
  const { userProfileId } = useUserContext();
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [transactionStatus, setTransactionStatus] = useState<TransactionStatus>(null);
  const { addSnack } = useSnackbar();
  const [isRetrying, setIsRetrying] = useState(false);
  const [isContainerReady, setIsContainerReady] = useState(false);
  const deposit = type === CashierType.Deposit && CashierType.Deposit;
  const withdrawal = type === CashierType.Withdrawal && CashierType.Withdrawal;

  const cashierType = deposit || withdrawal || undefined;

  useEffect(() => {
    if (isOpen && !sessionId) {
      getSessionId().then((id) => setSessionId(id));
    }
  }, [isOpen, type, sessionId, isRetrying]);

  const checkContainerReady = useCallback(() => {
    if (isOpen && cashierRef.current) {
      setIsContainerReady(true);
    } else {
      setIsContainerReady(false);
    }
  }, [isOpen]);

  useEffect(() => {
    const timeoutId = setTimeout(checkContainerReady, 500);

    return () => clearTimeout(timeoutId);
  }, [isOpen, checkContainerReady]);

  useEffect(() => {
    if (isOpen && sessionId && isContainerReady && !transactionStatus) {
      initializeCashier(sessionId);
    }
  }, [isOpen, sessionId, isContainerReady, transactionStatus]);

  // New effect to listen for gcv2DepositEvent
  useEffect(() => {
    const handleDepositEvent = (): void => {
      dispatch(setCashierModal({ isOpen: true, type: CashierType.Deposit }));
    };

    window.addEventListener('gcv2DepositEvent', handleDepositEvent);

    return () => {
      window.removeEventListener('gcv2DepositEvent', handleDepositEvent);
    };
  }, [dispatch]);

  const getSessionId = async (): Promise<string | null> => {
    try {
      return await fetchSessionId(userProfileId, withdrawal ? 'withdraw' : cashierType);
    } catch (error) {
      addSnack({
        type: 'error',
        message: t('errors.general'),
        theme: 'dark',
      });
      handleClose();

      return null;
    }
  };

  const initializeCashier = (sessionId: string): void => {
    try {
      new _PaymentIQCashier(
        '#cashier-container',
        {
          fetchConfig: true,
          defaultLoader: true,
          merchantId: process.env.REACT_APP_PAYMENTIQ_MERCHANT_ID as string,
          userId: userProfileId,
          sessionId: sessionId,
          environment: 'test',
          method: cashierType as IPiqCashierConfig['method'],
          locale: locale,
          showReceipt: false,
          containerHeight: 'auto',
        },
        (api: IPiqCashierApiMethods) => {
          api.on({
            cashierInitLoad: () => console.log('Cashier loaded and ready'),
            success: (data) => {
              console.log('Transaction completed', data);
              setTransactionStatus('success');
            },
            failure: (data) => {
              console.log('Transaction failed', data);
              setTransactionStatus('failure');
            },
            cancelled: (data) => {
              console.log('Transaction cancelled', data);
              setTransactionStatus('cancelled');
            },
            pending: (data) => {
              console.log('Transaction pending', data);
              setTransactionStatus('pending');
            },
          });
        }
      );
    } catch (error) {
      console.error('Error initializing cashier:', error);
      setTransactionStatus('failure');
    }
  };

  const handleClose = (): void => {
    dispatch(setCashierModal({ isOpen: false }));
    setSessionId(null);
    setTransactionStatus(null);
    setIsRetrying(false);
    setIsContainerReady(false);
  };

  const handleRetry = (): void => {
    setTransactionStatus(null);
    setSessionId(null);
    setIsRetrying(true);
  };

  const renderContent = (): ReactNode => {
    if (!transactionStatus) {
      return <div id="cashier-container" className="cashier__content" ref={cashierRef}></div>;
    }

    switch (transactionStatus) {
      case 'success':
        return (
          <NotificationMessage
            iconVariant="Success"
            text={t(`cashier.success-${cashierType}`)}
            buttonText={t('cashier.closeButton')}
            onClick={handleClose}
          />
        );
      case 'failure':
        return (
          <NotificationMessage
            iconVariant="Error"
            text={t(`cashier.failed-${cashierType}`)}
            buttonText={t('cashier.tryAgainButton')}
            onClick={handleRetry}
          />
        );
      case 'pending':
        return (
          <NotificationMessage
            iconVariant="Info"
            text={t(`cashier.pending-${cashierType}`)}
            buttonText={t('cashier.closeButton')}
            onClick={handleClose}
          />
        );
      case 'cancelled':
        return (
          <NotificationMessage
            iconVariant="Warning"
            text={t(`cashier.cancelled-${cashierType}`)}
            buttonText={t('cashier.tryAgainButton')}
            buttonClass="button--secondary--alt"
            onClick={handleRetry}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {isOpen && (
        <div className="cashier">
          <PopUpHandler
            header={type}
            isOpen={isOpen}
            onClose={handleClose}
            modalProps={{
              'aria-label': type,
              className: `cashier__modal`,
            }}
            drawerProps={{
              'aria-label': type,
              className: `cashier__drawer`,
            }}
          >
            {renderContent()}
          </PopUpHandler>
        </div>
      )}
    </>
  );
};
