import { ProductName, ProductType, PromotionTabsType } from '../Layout/Promotions/PromotionsList/types';
import { IconVariant } from '../shared/Icon';

/**
 * Generic navigation link
 */
export type NavigationLink = {
  /**
   * Visible label
   */
  label: string;
  /**
   * URL the link is pointing to
   */
  url: string;
  /**
   * Inactive link icon
   */
  icon: IconVariant;
  /**
   * Active link icon
   */
  iconActive: IconVariant | string;
};

export type NavigationCustomLink = {
  /**
   * Link type
   */
  type: PromotionTabsType;
  /**
   * Visible label
   */
  label: string;
  /**
   * Link icon
   */
  iconLink?: string;
  /**
   * Active iink icon
   */
  activeIconLink?: string;
};

/**
 * Auth and not auth properties
 */
type AuthUnAuthPermit = {
  /**
   * Show/hide link to authenticated users
   */
  authenticated: boolean;
  /**
   * Show/hide link to unauthenticated users
   */
  unauthenticated: boolean;
};

/**
 * Product Category navigation link
 * Extends generic navigation link
 */
export type ProductCategoryLink = AuthUnAuthPermit & NavigationLink;

export type PromotionCategoryLink = AuthUnAuthPermit & NavigationCustomLink;

export type PromoTab = {
  id: ProductType | string;
  name: ProductName | string;
};

export type Promotions = {
  tabs: PromoTab[];
  categories: PromotionCategoryLink[];
};

export enum CategoryOfUsers {
  AllUser = 'allUsers',
  Authenticated = 'authenticated',
  AuthenticatedVip = 'authenticated-vip',
  AuthenticatedInvitedVip = 'authenticated-invited-vip',
  AuthenticatedNonVip = 'authenticated-non-vip',
  Unauthenticated = 'unauthenticated',
}
