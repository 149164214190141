import React, { Fragment, FunctionComponent } from 'react';
import { parse, format } from 'date-fns';
import { FlattenedProfileData } from '../../../../hooks/registration';
import { AccountFieldSetConfig } from '../../types';
import { FormField, FormFieldType } from '../../../shared/Form';
import { Grid, GridItem, calculateGridItemConfigurations } from '../../../shared/Grid';
import { Heading } from '../../../shared/Heading';
import './AccountDetailsSummary.scss';

/**
 * Get a nicely formatted version of a form field value.
 *
 * @param formState
 */
const formattedFieldValue = <T extends FlattenedProfileData>(
  formField: FormField<T>,
  formState: Partial<T>
): string => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const value = formState[formField.name as string];

  switch (formField.type) {
    case FormFieldType.Checkbox:
      return value ? 'Yes' : 'No';

    case FormFieldType.DateOfBirthInput: {
      const parsedDate = parse(value, 'dd/MM/yyyy', new Date());

      return format(parsedDate, 'dd/MM/yyyy');
    }

    case FormFieldType.Select: {
      const option = formField.options.find((o) => o.value === value);

      /* istanbul ignore next */
      return option ? option.label : '-';
    }

    default:
      return (value as string) || '-';
  }
};

export type AccountDetailsSummaryProps<T extends FlattenedProfileData> = {
  fieldSets: AccountFieldSetConfig<T>[];
  formFields: Record<string, FormField<T>>;
  formState: Partial<T>;
};

export type AccountDetailsSummaryComponent<T extends FlattenedProfileData> = FunctionComponent<
  AccountDetailsSummaryProps<T>
>;

export const AccountDetailsSummary = <T extends FlattenedProfileData>({
  fieldSets,
  formFields,
  formState,
}: AccountDetailsSummaryProps<T>): JSX.Element => {
  return (
    <div className="account-details-summary">
      {fieldSets.map((fieldSet) => {
        if (fieldSet.hidden) {
          return <></>;
        }

        const fields = fieldSet.fields.filter(
          ({ name }) => !['password', 'retype', 'toggleAll'].some((excluded) => (name as string).includes(excluded))
        );
        const columns = fields.map((field) => field.overviewColumns || 12);
        const gridItemConfigs = calculateGridItemConfigurations(columns);

        return (
          <Fragment key={fieldSet.id}>
            {fieldSet.label && !fieldSet.labelSrOnly && <Heading level={6}>{fieldSet.label}</Heading>}
            <Grid columns={12} gap="m">
              {fields.map(({ name, hidden }, fieldIndex) => {
                const field = formFields[name as string];

                if (hidden) {
                  return <></>;
                }

                const gridItemConfig = gridItemConfigs[fieldIndex];

                return (
                  <GridItem
                    startCol={gridItemConfig.startCol}
                    endCol={gridItemConfig.endCol}
                    key={name as string}
                    className="account-details-summary__detail-grid-item"
                    data-testid={`review-step-item-${String(field.name)}`}
                  >
                    <span className="label">{field?.label || ''}</span>
                    <span>{formattedFieldValue(field, formState)}</span>
                  </GridItem>
                );
              })}
            </Grid>
          </Fragment>
        );
      })}
    </div>
  );
};
