import DOMPurify from 'dompurify';
import React, { FunctionComponent } from 'react';
import { Prompt } from '../../../shared/Prompt';
import { Paragraph } from '../../../shared/Paragraph';
import PromptImg from '../../../../assets/Prompt/registration-abandon-prompt.png';
import './styles';

export type AbandonPromptProps = {
  /**
   * Whether to show the Prompt. Defaults to false
   */
  show: boolean;
  /**
   * Called when prompt should be hidden.
   */
  onDismiss: () => void;
  /**
   * Text to display as the prompt title
   */
  title: string;
  /**
   * HTML to display as the prompt content
   */
  message: string;
  /**
   * Called if the user confirms they want to leave registration
   */
  onConfirmAbandon: () => void;
};

export const AbandonPrompt: FunctionComponent<AbandonPromptProps> = ({
  show,
  onDismiss,
  title,
  message,
  onConfirmAbandon,
}) => {
  return (
    <Prompt
      show={show}
      title={title}
      className="registration-abandon-prompt"
      onClose={onDismiss}
      image={{
        src: PromptImg,
        alt: 'prompt-img',
      }}
      PrimaryButton={{
        onClick: onConfirmAbandon,
        text: 'YES I WANT TO LEAVE',
        buttonVariant: 'secondary-alt',
      }}
      SecondaryButton={{
        onClick: onDismiss,
        text: 'CONTINUE REGISTERING',
        buttonVariant: 'primary',
      }}
      mode={'dark'}
    >
      <Paragraph
        noMargin
        size="sm"
        className="registration-abandon-prompt__message"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(message),
        }}
      />
    </Prompt>
  );
};
