/* eslint-disable camelcase */
export const NUMVERIFY_BASE_URL = 'https://apilayer.net/api/validate';
const numverifyApiKey = process.env.REACT_APP_NUMVERIFY_KEY;

export interface NumverifyResult {
  valid: boolean;
  number: string;
  local_format: string;
  international_format: string;
  country_prefix: string;
  country_code: string;
  country_name: string;
  location: string;
  carrier: string;
  line_type: string;
}

export const verifyPhoneNumber = (number: string): Promise<NumverifyResult> => {
  const url = `${NUMVERIFY_BASE_URL}?access_key=${numverifyApiKey}&number=${number}`;

  return fetch(url).then((response) => response.json().then((res) => res));
};
