import React, { forwardRef, ReactNode, useId } from 'react';
import { InputValidationAlerts } from '../InputValidationAlert';
import { Label, LabelProps } from '../Label';
import { InputValidationProps } from '../Input';
import { joinStrings } from '../../../utils/string';
import { Radio } from '../Radio/Radio';
import './RadioGroup.scss';

export type RadioOption = {
  /**
   * String value of the Radio input.
   */
  value: string;
  /**
   * Display name for Radio button
   */
  label: string | ReactNode;
  /**
   * Optional id for the radio input element.
   */
  id?: string;
};
export type RadioGroupProps = {
  /**
   * Optional but if not provided the component will not be properly aria accessible.
   */
  label?: string | React.ReactNode;
  /**
   * Radio group name. Should be set but left as optional for usage with `Controller` component from `react-form-hook`
   */
  name?: string;
  /**
   * Optional.  Used for aria-labelledby but will default to a uuid if not provided
   */
  id?: string;
  /**
   * Currently set value.  Optional but if not set then the component will be read only with the exception of
   * react-hook-form `Controller as`.
   */
  value?: string;
  /**
   * List of options used to generate the Radio buttons.
   */
  options: RadioOption[];
  /**
   * Whether to display the options inline.  Defaults to false.
   */
  inline?: boolean;
  /**
   * Optional. Allows label props to be passed onward to label component.
   */
  labelProps?: LabelProps;
  /**
   * Optional. Disable interaction with form group.
   */
  disabled?: boolean;
  /**
   * User provided aria-described by to be used for external validation messages.
   */
  'aria-describedby'?: string;
  /**
   * onChange callback which is invoked when an unselected radio button is selected.
   */
  onChange?: (value: string | number) => void;
  /**
   *Optional. Allows you to swap the label and radio icon
   */
  isFirstLabel?: boolean;
} & InputValidationProps;
/**
 * A controlled radio group component.
 *
 * ### Example - Controlled Component
 *
 * An example using useState hook to control the radio group state.
 *
 * ```tsx
 * const UseStateExample = () => {
 *   const [value, setValue] = useState('Other value');
 *   const options: RadioOption[] = [
 *     { value: 'A value', label: 'A label' },
 *     { value: 'Other value', label: 'A label' }
 *   ];
 *   return (
 *     <Fragment>
 *       <RadioGroup
 *         name="radioGroup"
 *         value={value}
 *         label="Radio Group Label"
 *         onChange={(val)=> setValue(val)}
 *       />
 *     </Fragment>
 *   );
 * };
 *
 * ```
 *
 * ### Example - React Hook Form
 *
 * An example which uses `react-hook-form` as the controller of state.
 *
 * ```tsx
 * import { useForm, Controller } from 'react-hook-form';
 *
 * const HookFormExample = ({
 *   onSubmit
 * }: {
 *   onSubmit: (formValues) => void;
 * }) => {
 *   const options: RadioOption[] = [{ value: 'a', label: 'a' }, { value: 'b', label: 'b' }];
 *   const { control, handleSubmit } = useForm({
 *     defaultValues: { fieldName: 'a' }
 *   });
 *   return (
 *     <form onSubmit={handleSubmit(onSubmit)}>
 *       <Controller
 *         name='fieldName'
 *         control={control}
 *         as={<RadioGroup label='Label' options={options} />}
 *       />
 *       <button type='submit'>Submit</button>
 *    </form>
 *   );
 * };
 * ```
 */
export const RadioGroup = forwardRef<HTMLInputElement, RadioGroupProps>(
  (
    {
      id,
      onChange,
      value,
      label,
      options,
      inline,
      validationMessages,
      labelProps,
      validationState,
      isFirstLabel,
      ...props
    },
    ref
  ) => {
    const idValue = useId();
    const radiosContainerClassName = joinStrings(['radio-group__buttons', inline && 'radio-group__buttons--inline']);
    const groupClassName = joinStrings(['radio-group', props.disabled && 'radio-group--disabled']);
    const componentId = id || props.name || idValue;
    const labelId = label ? `group-label-${componentId}` : '';
    const ariaDescribedBy = 'aria-describedby';

    return (
      <div
        id={componentId}
        className={groupClassName}
        role="radiogroup"
        aria-disabled={props.disabled}
        aria-labelledby={labelId}
        aria-describedby={ariaDescribedBy}
      >
        {label && (
          <Label
            {...labelProps}
            id={labelId}
            disabled={props.disabled}
            className={joinStrings(['radio-group__label', labelProps?.className])}
          >
            {label}
          </Label>
        )}
        <div className={radiosContainerClassName} data-testid="radio-button-container">
          {options.map((option) => (
            <Radio
              {...props}
              {...option}
              key={option.value}
              checked={option.value === value}
              ref={ref}
              onChange={(): void => onChange && onChange(option.value)}
              isFirstLabel={isFirstLabel}
            />
          ))}
        </div>
        <InputValidationAlerts messages={validationMessages} state={validationState} />
      </div>
    );
  }
);

RadioGroup.displayName = 'RadioGroup';
