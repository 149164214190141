import React, { ComponentPropsWithoutRef, FunctionComponent } from 'react';
import { MediaBreakpoint, useBreakpointInfo } from '../../../hooks/useDeviceInfo';
import { joinStrings } from '../../../utils/string';
import './Grid.scss';

export const GridContext = React.createContext<{
  applyGridArea?: boolean;
}>({});

export type GridBreakpointConfig = {
  /**
   * Number of columns for mobile width.  Optional. Defaults to value of `columns`
   */
  mobileCols?: GridColumnAmount;
  /**
   * Number of columns for table width. Optional. Defaults to value of `columns`
   */
  tabletCols?: GridColumnAmount;
  /**
   * Number of columns for desktop width. Optional. Defaults to value of `columns`
   */
  desktopCols?: GridColumnAmount;
  /**
   * Number of columns for big desktop width. Optional. Defaults to value of `columns`
   */
  bigDesktopCols?: GridColumnAmount;
};
export type GridColumnAmount = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
export type GridProps = {
  /**
   * Defaults to false.
   * When true and viewport is below tablet width only one column will be display &
   * GridItem startCol & endCol will therefore be ignore.
   *
   * Note that this will probably be replaced with something more dynamic/configurable.
   */
  responsive?: boolean;
  /**
   * Number of columns. (CSS grid)
   */
  columns: GridColumnAmount;
  /**
   * Gap between GridItems.  Undefined equals no gap.
   */
  gap?: 's' | 'm' | 'l' | 'xl';
} & ComponentPropsWithoutRef<'div'> &
  GridBreakpointConfig;

/**
 * A very simple grid list component which uses CSS grid and applies a grid layout for child GridItem's.
 *
 * ## Simple Example
 *
 * ```tsx
 * <Grid columns={2} gap="m">
 *   <GridItem>Col 1, Row 1</GridItem>
 *   <GridItem>Col 2, Row 1</GridItem>
 *   <GridItem>Col 1, Row 2</GridItem>
 *   <GridItem>Col 2, Row 2</GridItem>
 * </Grid>
 * ```
 *
 *
 * ## Custom Layout Example
 *
 * ```tsx
 * <Grid columns={2} gap="m">
 *   <GridItem row={1} startCol={1} endCol={2}>Col 1-2, Row 1</GridItem>
 *   <GridItem>Col 1, Row 2</GridItem>
 *   <GridItem>Col 2, Row 2</GridItem>
 *   <GridItem>Col 1, Row 3</GridItem>
 * </Grid>
 * ```
 */
export const Grid: FunctionComponent<GridProps> = ({
  children,
  columns,
  gap,
  className,
  responsive,
  mobileCols = columns,
  tabletCols = columns,
  desktopCols = columns,
  bigDesktopCols = columns,
  ...props
}) => {
  const isBelowTablet = useBreakpointInfo([MediaBreakpoint.BELOW_TABLET]);
  const isBelowMobile = useBreakpointInfo([MediaBreakpoint.BELOW_MOBILE]);
  const isBelowDesktop = useBreakpointInfo([MediaBreakpoint.BELOW_DESKTOP]);
  const isBelowBigDesktop = useBreakpointInfo([MediaBreakpoint.BELOW_BIG_DESKTOP]);
  const removeGrid = responsive && isBelowTablet;

  const getColumns = (): number => {
    if (isBelowMobile) {
      return mobileCols;
    } else if (isBelowDesktop) {
      return tabletCols;
    } else if (isBelowBigDesktop) {
      return desktopCols;
    } else {
      return bigDesktopCols;
    }
  };
  const classNames = joinStrings([
    `grid`,
    !removeGrid && `grid--columns-${getColumns()}`,
    gap && `grid--gap-${gap}`,
    'grid--responsive',
    className,
  ]);

  return (
    <div data-testid="grid" {...props} className={classNames}>
      <GridContext.Provider value={{ applyGridArea: !removeGrid }}>{children}</GridContext.Provider>
    </div>
  );
};
