import { createContext } from 'react';

export type AccordionContextConfig = {
  headerId: string;
  contentId: string;
  expanded?: boolean;
  expandOnlyByArrowClick?: boolean;
  transitionDuration: string;
  onClickChangeView: () => void;
};
export const AccordionContext = createContext<AccordionContextConfig>({} as AccordionContextConfig);
