import React, { FC } from 'react';
import { formatDate } from 'date-fns';
import { useTime } from '../../../hooks/useTime/useTime';

/**
 * Renders formatted current time.
 */
export const Clock: FC<{ format?: string }> = ({ format = 'HH:mm	a' }) => {
  const { currentTime } = useTime();

  return <span data-testid="clock">{formatDate(currentTime, format)}</span>;
};
