export const openNewWindow = (url: string, windowTarget?: string, windowFeatures?: string): boolean => {
  let winRef;

  try {
    winRef = window.open(url, windowTarget, windowFeatures);
  } catch (error) {
    console.warn('openNewWindow: ', error);
  }

  // if a browser blocks pop-ups, we will get null
  return Boolean(winRef);
};

export const openWindow = (
  url: string,
  linkTarget: string | undefined,
  e: React.MouseEvent | React.KeyboardEvent,
  windowFeatures?: string
): void | boolean => {
  e.preventDefault();
  switch (linkTarget) {
    // New tab
    case '_blank':
    case 'newTab':
      return openNewWindow(url, '_blank');

    // New window, new tab for mobile
    case 'newWindow':
      return openNewWindow(url, '_blank', windowFeatures || 'resizable=1,scrollbars=1,status=1');

    // Same window
    case '_self':
    case 'sameWindow':
    default:
      return openNewWindow(url, '_self');
  }
};
