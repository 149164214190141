import { FC, memo, useMemo } from 'react';
import type { AuthProviderProps } from 'react-oidc-context';
import { AuthProvider as OIAuthProvider } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import { WebStorageStateStore } from 'oidc-client-ts';
import { config } from './config';
import { APP_URLS } from '../../consts';
import { redirectHelper } from '../Account/MyAccount/MyAccountSettings/helper';
import { getRedirectUri } from './helper';
import { setXtremepushId } from '../../utils/xtremepush';
import { storageAfterLogin } from '../../utils/storage';

interface Props {
  children: JSX.Element;
}

export const AuthProvider: FC<Props> = memo(({ children }) => {
  const navigate = useNavigate();

  const redirectUri = getRedirectUri();
  const logoutRedirectUri = getRedirectUri(APP_URLS.home);

  const oidcConfig = useMemo(
    (): AuthProviderProps => ({
      authority: `${config.authUrl}/realms/${config.realm}`,
      client_id: config.clientId,
      redirect_uri: redirectUri,
      post_logout_redirect_uri: logoutRedirectUri,
      silent_redirect_uri: logoutRedirectUri,
      scope: `openid ${config.tenantId}`,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      onSigninCallback(user): void {
        redirectHelper(navigate);
        setXtremepushId(user?.profile.user_profile_id as string);

        storageAfterLogin.set({ value: true });
      },
      automaticSilentRenew: false,
      accessTokenExpiringNotificationTimeInSeconds: 30,
    }),
    [redirectUri, logoutRedirectUri, navigate]
  );

  return <OIAuthProvider {...oidcConfig}>{children}</OIAuthProvider>;
});
