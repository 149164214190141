import noop from 'lodash/noop';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { getBoundaryCheckedPosition, getTooltipPositionStyles } from './utils';
import { IconButton, IconButtonProps } from '../IconButton';
import { Icon, IconVariant } from '../Icon';
import { InformationIcon } from '../SVG/Icons';
import { Theme } from '../../../types';
import { MediaBreakpoint, useBreakpointInfo } from '../../../hooks/useDeviceInfo';
import { joinStrings } from '../../../utils/string';
import './Tooltip.scss';

export type TooltipPosition = 'top' | 'bottom' | 'right' | 'left';

export type TooltipAlignment = 'start' | 'center' | 'end';
type TooltipTrigger = 'click' | 'hover';
export type TooltipProps = {
  /**
   * Id for tooltip popup. Can be used for aria-describedby
   */
  id?: string;
  /**
   * Icon button props.  Required so that at least aria-label is provided.
   */
  buttonProps: Omit<IconButtonProps, 'icon' | 'color'>;
  /**
   * Tooltip position relative to the icon. Defaults to top.
   */
  position?: TooltipPosition;
  /**
   * Optional custom icon. Displays Info icon by default, will not render if 'hide' is used
   */
  icon?: IconVariant | 'hide';
  /**
   * Optional, defaults to 'hover'. 'hover' will switch to 'click' on screens below desktop width.
   */
  trigger?: TooltipTrigger;
  /**
   * How to align the tooltip in relation to the tooltip icon. Defaults to 'center'
   */
  alignment?: TooltipAlignment;
  /**
   * Optional, defaults to 'none'. Could be extended to add more animation options
   */
  entryAnimation?: 'slideFromRight' | 'none';
  /**
   * Optional, defaults to 10. Gap between tooltip content and tooltip icon when open.
   */
  tooltipMargin?: number;
  /**
   * Optional custom class name to apply to the tooltip container.
   */
  className?: string;
  /**
   * Optional light/dark theme. Defaults to light
   */
  theme?: Theme;
  /**
   * Optional, callback on tooltip show
   */
  onShow?: () => void;
  /**
   * Optional, callback on tooltip hide
   */
  onHide?: () => void;

  /**
   * Optional, control the tooltip via a prop
   */
  show?: boolean;
  children?: React.ReactNode;
};

/**
 * A basic tooltip component which can be positioned to the top, bottom, left or right of the info icon.
 *
 * ### Simple Example
 *
 * ```tsx
 * <Tooltip position='right' buttonProps={{ 'aria-label': 'Info icon label' }}>
 *   Some info about a <span className='custom-tooltip-text-style'>form field</span>
 * </Tooltip>
 * ```
 *
 * Note that the storybook examples are positioned centrally to prevent issues with storybook overflow styles & iframe usage.
 */
export const Tooltip: FunctionComponent<TooltipProps> = ({
  buttonProps,
  children,
  id,
  icon,
  className,
  position = 'top',
  trigger = 'hover',
  entryAnimation = 'none',
  alignment = 'center',
  tooltipMargin = 10,
  theme = 'light',
  onShow = noop,
  onHide = noop,
  show,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(show || false);
  const [positionCss, setPositionStyles] = useState({});
  const [entryAnimationStyles, setEntryAnimationStyles] = useState({});
  const [adjustedPosition, setAdjustedPosition] = useState(position);
  const [appliedTrigger, setAppliedTrigger] = useState<TooltipTrigger>(trigger);
  const isBelowDesktopWidth = useBreakpointInfo([MediaBreakpoint.BELOW_DESKTOP]);
  const width = window.innerWidth;

  useEffect(() => {
    if (!isOpen) {
      onHide && onHide();

      return;
    }

    const close = (e: MouseEvent): void => {
      /* istanbul ignore else */
      if (tooltipRef.current && containerRef.current?.contains(e.target as Node)) {
        return;
      }

      setIsOpen(false);
    };
    const closeOnEscape = (e: KeyboardEvent): void => {
      /* istanbul ignore else */
      if (e.key === 'Escape') {
        setIsOpen(false);
      }
    };

    window.addEventListener('click', close);
    window.addEventListener('keydown', closeOnEscape);

    return () => {
      window.removeEventListener('click', close);
      window.removeEventListener('keydown', closeOnEscape);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    setEntryAnimationStyles(
      /* istanbul ignore next */
      entryAnimation === 'slideFromRight' && containerRef.current && tooltipRef.current
        ? {
            transform: `translateX(${
              isOpen
                ? '0px'
                : `${
                    width -
                    containerRef.current.getBoundingClientRect().x +
                    tooltipRef.current.getBoundingClientRect().width
                  }px`
            })`,
          }
        : {}
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tooltipRef.current, containerRef.current, width, isOpen]);

  const setOpenState = (open: boolean): void => {
    setIsOpen(open);

    /* istanbul ignore else */
    if (open && containerRef.current && tooltipRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();

      const tooltipRect = tooltipRef.current.getBoundingClientRect();

      const adjustedPosition = getBoundaryCheckedPosition(position, tooltipRect, containerRect, tooltipMargin);

      setAdjustedPosition(adjustedPosition);
      setPositionStyles(
        getTooltipPositionStyles(adjustedPosition, tooltipRect, containerRect, tooltipMargin, alignment)
      );
      onShow();
    }
  };

  // Handle changes from the show prop
  useEffect(() => {
    if (show !== undefined) {
      setOpenState(show);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    setAppliedTrigger(isBelowDesktopWidth ? 'click' : trigger);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  return (
    <div
      ref={containerRef}
      className={joinStrings([
        'tooltip',
        className,
        isOpen && 'tooltip--open',
        `tooltip--${adjustedPosition}`,
        `tooltip--trigger-${appliedTrigger}`,
        `tooltip--${theme}-theme`,
      ])}
    >
      {icon !== 'hide' ? (
        <IconButton
          {...buttonProps}
          color="inherit"
          {...(appliedTrigger === 'click'
            ? { onClick: () => setOpenState(!isOpen) }
            : {
                onMouseEnter: () => setOpenState(true),
                onMouseLeave: () => setOpenState(false),
              })}
          onBlur={(): void | boolean => appliedTrigger === 'hover' && setOpenState(false)}
          onKeyUp={(e): void => {
            /* istanbul ignore else */
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault();
            }
          }}
          onKeyDown={(e): void => {
            /* istanbul ignore else */
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault();
              setOpenState(!isOpen);
            }
          }}
          icon={icon ? <Icon variant={icon} /> : <InformationIcon />}
        />
      ) : null}

      <div className="tooltip__popup" style={entryAnimationStyles}>
        <div
          data-testid="tooltip"
          id={id}
          role="tooltip"
          style={positionCss}
          aria-hidden={!isOpen}
          ref={tooltipRef}
          className="tooltip__popup-inner"
        >
          {children}
        </div>
      </div>
    </div>
  );
};
