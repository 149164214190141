import React, { Children, FC } from 'react';
import { Spinner } from '../shared/Spinner';
import { Suspensify } from '../shared/Suspensify';
import { Navigation, NavigationLinkPropsExtended } from './Navigation';
import { ProductCategoryLink, Promotions } from './types';

interface NavComponentProps {
  children: JSX.Element | JSX.Element[];
  navLinks?: NavigationLinkPropsExtended[];
  categories?: ProductCategoryLink[];
  promotions?: Promotions;
  isNavHidden?: boolean;
  isDarkBackground?: boolean;
  paddingDisabled?: boolean;
  isSportsBook?: boolean;
}

// TODO: update this component
export const NavComponent: FC<NavComponentProps> = ({
  children,
  navLinks,
  categories,
  promotions,
  isNavHidden,
  isDarkBackground,
  paddingDisabled,
  isSportsBook,
}): JSX.Element => {
  return (
    <div className="navigation-container">
      <Navigation
        navLinks={navLinks}
        categories={categories}
        promotions={promotions}
        isNavHidden={isNavHidden}
        isDarkBackground={isDarkBackground}
        paddingDisabled={paddingDisabled}
        isSportsBook={isSportsBook}
      >
        <div>
          <Suspensify fallback={<Spinner />}>{Children.toArray(children)}</Suspensify>
        </div>
      </Navigation>
    </div>
  );
};
