import chunk from 'lodash/chunk';
import zip from 'lodash/zip';
import filter from 'lodash/filter';

export function groupedSlides<T>(slides: T[], rowsNumber = 1): T[][] {
  const numberCol = Math.ceil(slides.length / rowsNumber);
  const chunkArray = slides && chunk(slides, numberCol);
  const grouped = zip(...chunkArray).map((item) => filter(item)) as T[][];

  return grouped;
}
