import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserProfileUpdateMutationVariables = Types.Exact<{
  userProfileId: Types.Scalars['ID']['input'];
  xTenantId: Types.Scalars['ID']['input'];
  userProfileUpdateInfo?: Types.InputMaybe<Types.UserProfilesUpdateInput>;
}>;


export type UserProfileUpdateMutation = { __typename?: 'Mutation', userProfileUpdate: { __typename?: 'UserProfileUpdateResponse', data?: { __typename?: 'UserProfile', user?: { __typename?: 'Person', mobileNumber?: { __typename?: 'TelephoneNumber', number?: string | null, prefix?: string | null } | null } | null } | null, error?: Array<{ __typename?: 'FieldError', code: string, description?: string | null, fieldName: string, kind: Array<string | null>, pattern?: string | null } | { __typename?: 'GenericError', code: string }> | null } };


export const UserProfileUpdateDocument = gql`
    mutation UserProfileUpdate($userProfileId: ID!, $xTenantId: ID!, $userProfileUpdateInfo: UserProfilesUpdateInput) {
  userProfileUpdate(
    userProfileId: $userProfileId
    xTenantID: $xTenantId
    userProfileUpdateInfo: $userProfileUpdateInfo
  ) {
    data {
      user {
        mobileNumber {
          number
          prefix
        }
      }
    }
    error {
      ... on GenericError {
        code
      }
      ... on FieldError {
        code
        description
        fieldName
        kind
        pattern
      }
    }
  }
}
    `;
export type UserProfileUpdateMutationFn = Apollo.MutationFunction<UserProfileUpdateMutation, UserProfileUpdateMutationVariables>;

/**
 * __useUserProfileUpdateMutation__
 *
 * To run a mutation, you first call `useUserProfileUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserProfileUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userProfileUpdateMutation, { data, loading, error }] = useUserProfileUpdateMutation({
 *   variables: {
 *      userProfileId: // value for 'userProfileId'
 *      xTenantId: // value for 'xTenantId'
 *      userProfileUpdateInfo: // value for 'userProfileUpdateInfo'
 *   },
 * });
 */
export function useUserProfileUpdateMutation(baseOptions?: Apollo.MutationHookOptions<UserProfileUpdateMutation, UserProfileUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserProfileUpdateMutation, UserProfileUpdateMutationVariables>(UserProfileUpdateDocument, options);
      }
export type UserProfileUpdateMutationHookResult = ReturnType<typeof useUserProfileUpdateMutation>;
export type UserProfileUpdateMutationResult = Apollo.MutationResult<UserProfileUpdateMutation>;
export type UserProfileUpdateMutationOptions = Apollo.BaseMutationOptions<UserProfileUpdateMutation, UserProfileUpdateMutationVariables>;