import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TransactionLimitsCreateMutationVariables = Types.Exact<{
  userProfileId: Types.Scalars['ID']['input'];
  xTenantId: Types.Scalars['ID']['input'];
  creatorName?: Types.InputMaybe<Types.CreatorName>;
  budgetLimitsCreateTransaction?: Types.InputMaybe<Types.BudgetLimitsCreateTransactionInput>;
}>;


export type TransactionLimitsCreateMutation = { __typename?: 'Mutation', transactionLimitsCreate?: { __typename?: 'TransactionLimitsCreateResponse', data?: { __typename?: 'TransactionLimitsCreateSuccess', id: string } | null, error?: Array<{ __typename?: 'FieldError', code: string, description?: string | null, fieldName: string, kind: Array<string | null>, pattern?: string | null } | { __typename?: 'GenericError', code: string }> | null } | null };


export const TransactionLimitsCreateDocument = gql`
    mutation TransactionLimitsCreate($userProfileId: ID!, $xTenantId: ID!, $creatorName: CreatorName, $budgetLimitsCreateTransaction: BudgetLimitsCreateTransactionInput) {
  transactionLimitsCreate(
    userProfileId: $userProfileId
    xTenantID: $xTenantId
    creatorName: $creatorName
    budgetLimitsCreateTransaction: $budgetLimitsCreateTransaction
  ) {
    data {
      id
    }
    error {
      ... on FieldError {
        code
        description
        fieldName
        kind
        pattern
      }
      ... on GenericError {
        code
      }
    }
  }
}
    `;
export type TransactionLimitsCreateMutationFn = Apollo.MutationFunction<TransactionLimitsCreateMutation, TransactionLimitsCreateMutationVariables>;

/**
 * __useTransactionLimitsCreateMutation__
 *
 * To run a mutation, you first call `useTransactionLimitsCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransactionLimitsCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transactionLimitsCreateMutation, { data, loading, error }] = useTransactionLimitsCreateMutation({
 *   variables: {
 *      userProfileId: // value for 'userProfileId'
 *      xTenantId: // value for 'xTenantId'
 *      creatorName: // value for 'creatorName'
 *      budgetLimitsCreateTransaction: // value for 'budgetLimitsCreateTransaction'
 *   },
 * });
 */
export function useTransactionLimitsCreateMutation(baseOptions?: Apollo.MutationHookOptions<TransactionLimitsCreateMutation, TransactionLimitsCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TransactionLimitsCreateMutation, TransactionLimitsCreateMutationVariables>(TransactionLimitsCreateDocument, options);
      }
export type TransactionLimitsCreateMutationHookResult = ReturnType<typeof useTransactionLimitsCreateMutation>;
export type TransactionLimitsCreateMutationResult = Apollo.MutationResult<TransactionLimitsCreateMutation>;
export type TransactionLimitsCreateMutationOptions = Apollo.BaseMutationOptions<TransactionLimitsCreateMutation, TransactionLimitsCreateMutationVariables>;