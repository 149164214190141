import React, { FunctionComponent } from 'react';
import './MyAccountActivityTotals.scss';
import { Paragraph } from '../../../shared/Paragraph';

type MyAccountActivityTotalsProps = {
  label: string;
  amount?: number;
  quantity?: number;
  Icon: React.ReactNode;
};

export const MyAccountActivityTotals: FunctionComponent<MyAccountActivityTotalsProps> = ({
  label,
  Icon,
  amount,
  quantity,
}) => {
  return (
    <div className="my-account-activity-totals">
      <div className="my-account-activity-totals__label">
        {Icon}
        <Paragraph noMargin highlight className="my-account-activity-totals__label-text">
          {label}
          <span className="my-account-activity-totals__label-quantity">({quantity})</span>
        </Paragraph>
      </div>
      <Paragraph noMargin highlight className="my-account-activity-totals__amount">
        {`£${Math.abs(amount || 0)}`}
      </Paragraph>
    </div>
  );
};
