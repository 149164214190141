import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OnMessageCreatedSubscriptionVariables = Types.Exact<{ [key: string]: never; }>;


export type OnMessageCreatedSubscription = { __typename?: 'Subscription', messages_mutated?: { __typename?: 'messages_mutated', data?: { __typename?: 'messages', id: string, status?: string | null, user_profile_id?: string | null, message_content?: string | null, message_header?: string | null, rule_name?: string | null } | null } | null };


export const OnMessageCreatedDocument = gql`
    subscription OnMessageCreated {
  messages_mutated(event: create) {
    data {
      id
      status
      user_profile_id
      message_content
      message_header
      rule_name
    }
  }
}
    `;

/**
 * __useOnMessageCreatedSubscription__
 *
 * To run a query within a React component, call `useOnMessageCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnMessageCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnMessageCreatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnMessageCreatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnMessageCreatedSubscription, OnMessageCreatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnMessageCreatedSubscription, OnMessageCreatedSubscriptionVariables>(OnMessageCreatedDocument, options);
      }
export type OnMessageCreatedSubscriptionHookResult = ReturnType<typeof useOnMessageCreatedSubscription>;
export type OnMessageCreatedSubscriptionResult = Apollo.SubscriptionResult<OnMessageCreatedSubscription>;