import React, { FC } from 'react';
import { Casino } from '../../../components/Games';
import { NavigationTemplate } from '../../NavigationTemplate';

export const GameDetailsPage: FC = (): JSX.Element => {
  return (
    <NavigationTemplate isNavHidden>
      <Casino gameEnabled />
    </NavigationTemplate>
  );
};
