import { useCombobox } from 'downshift';
import pick from 'lodash/pick';
import React, { Fragment, FunctionComponent, JSXElementConstructor, ReactElement } from 'react';
import { Input } from '../Input';
import { Menu, MenuItem } from './Menu';
import noop from 'lodash/noop';
import { AutocompleteInputProps } from './types';

/**
 * An auto completion input component which is primarily intended to be used as a controlled component.
 *
 * `AutocompleteInput` is partly composed of the `Input` component
 * and so there is an `inputProps` property to provide extra control of the component if required.
 * Most functionality available in the `Input` component is available in `AutocompleteInput`.
 * E.g. `validationState`, `validationMessages` etc.
 * For further info please check the `Input` component property documentation.
 *
 * Note that a generic type has been used for `AutocompleteInputProps` options,
 * this enables type assistance for the `optionToString` prop etc.
 * Take a look at the usage of generic typing when used with components in the examples below.
 *
 * The combobox logic is primarily based from [DownshiftJS Combobox](https://www.downshift-js.com/use-combobox)
 * with a bit of extra component specific logic for the use case.
 *
 * ### Example - As Controlled AutocompleteInput
 *
 * ```tsx
 * type Occupation {
 *   name: string;
 *   id: number;
 *   // etc...
 * }
 * const UseStateExample = ({ initialOptions }: { initialOptions: string[] }) => {
 *   const [value, setValue] = useState('');
 *   const [filteredOptions, setFilteredOptions] = useState(initialOptions);
 *   return (
 *      <AutocompleteInput<Occupation>
 *        id="occupation"
 *        inputProps={{ label: 'Occupation', placeholder: 'Type to filter' }}
 *        name='occupation'
 *        onChange={(val) => setValue(val)}
 *        options={filteredOptions}
 *        optionToString={(occupation) => occupation.name}
 *        value={value}
 *        onInputChange={(inputVal) => {
 *          setFilteredItems(
 *            inputVal
 *              ? initialOptions.filter((occupation) => occupation.name.startsWith(inputVal))
 *              : initialOptions
 *          );
 *        }}
 *      />
 *   );
 * };
 * ```
 *
 * ### Example - React Hook Form
 *
 * An example which uses `react-hook-form` as the controller of state.
 *
 * ```tsx
 * import { useForm, Controller } from 'react-hook-form';
 *
 * const HookFormExample = ({ onSubmit, setNextOptions, options }) => {
 *   const { handleSubmit, control } = useForm({
 *      defaultValues: { job: '' }
 *   });
 *   return (
 *     <form onSubmit={handleSubmit(onSubmit)}>
 *        <Controller
 *         name='job'
 *         control={control}
 *         as={
 *           <AutocompleteInput<string>
 *             onInputChange={setNextOptions}
 *             options={options}
 *             optionToString={(i) => i}
 *             inputProps={{ label: 'Job' }}
 *           />
 *         }
 *       />
 *       <Button variant='primary' type='submit'>
 *         Submit
 *       </Button>
 *     </form>
 *   );
 * };
 * ```
 */
export function AutocompleteInput<T>({
  disabled,
  id,
  options,
  inputProps,
  loadingOptionsPlaceholder,
  noOptionsMessage,
  name,
  isMenuOpen,
  onMenuOpenChange,
  optionToString,
  onChange,
  onInputChange,
  onSelect,
  value,
  onMenuOnFocus = false,
  onPaste = noop,
}: AutocompleteInputProps<T>): JSX.Element {
  const { highlightedIndex, isOpen, selectedItem, getInputProps, getMenuProps, getItemProps, getLabelProps, openMenu } =
    useCombobox<T>({
      isOpen: isMenuOpen,
      id,
      items: options,
      itemToString: optionToString,
      inputValue: value,
      onIsOpenChange: onMenuOpenChange,
      onSelectedItemChange: onSelect ? (c): void => onSelect(c.selectedItem as T) : undefined,
      onInputValueChange:
        onInputChange &&
        (({ inputValue, selectedItem }): void => {
          if (!selectedItem || optionToString(selectedItem) !== inputValue) {
            onInputChange(inputValue);
          }

          onChange && onChange(inputValue);
        }),
    });

  const InputMenuWrapper: FunctionComponent<{
    input: ReactElement<unknown, string | JSXElementConstructor<HTMLInputElement>> | undefined;
  }> = ({ input }) => {
    return (
      <Fragment>
        <div {...getInputProps()}>{input}</div>
        <Menu
          {...getMenuProps()}
          loadingItemsPlaceholder={loadingOptionsPlaceholder}
          noItemsMessage={noOptionsMessage}
          isOpen={isOpen}
        >
          {options.map((item, index) => {
            const itemString = optionToString(item);

            return (
              <MenuItem
                key={itemString + index}
                isActive={highlightedIndex === index}
                {...getItemProps({
                  item,
                  index,
                  isSelected: selectedItem === item,
                  ...pick(item, 'disabled'),
                })}
              >
                {itemString}
              </MenuItem>
            );
          })}
        </Menu>
      </Fragment>
    );
  };

  const downshiftInputProps = getInputProps();

  return (
    <Input
      {...inputProps}
      {...downshiftInputProps}
      onBlur={(e): void => {
        inputProps.onBlur && inputProps.onBlur(e);
        downshiftInputProps?.onBlur?.(e);
      }}
      onFocus={(e): void => {
        inputProps.onFocus && inputProps.onFocus(e);
        onMenuOnFocus && !isOpen && openMenu();
      }}
      onPaste={onPaste}
      name={name}
      disabled={disabled}
      value={value}
      labelProps={{ ...inputProps.labelProps, ...getLabelProps() }}
      inputWrapper={InputMenuWrapper}
      onKeyDown={(e): void => {
        inputProps.onKeyDown && inputProps.onKeyDown(e);
        downshiftInputProps?.onKeyDown?.(e);
      }}
      //  there is an issue in downshift library https://github.com/downshift-js/downshift/issues/1108
      onChange={(e): void => {
        onInputChange && onInputChange(e.target.value);
        downshiftInputProps.onChange && downshiftInputProps.onChange(e);
      }}
    />
  );
}
