import Cookies from 'js-cookie';

export enum UrlOptions {
  hideHeader = 'hideheader',
  hideNavigation = 'hidenavigation',
  hideFooter = 'hidefooter',
}

export const setUrlStorage = (): string | undefined => {
  const isExistParams = Object.keys(UrlOptions).some((name) =>
    window.location.href.toLowerCase().includes(name.toLowerCase())
  );

  if (!isExistParams) return;

  return Cookies.set('url', window.location.href);
};

export const isHidden = (param: UrlOptions): boolean | undefined => {
  const url = Cookies.get('url');

  return url?.toLowerCase()?.includes(`${param}=true`);
};
