import React, {
  ComponentPropsWithoutRef,
  Fragment,
  FunctionComponent,
  useEffect,
  useRef,
  useState,
  Suspense,
} from 'react';
import { FormProvider, Mode as ValidationMode, SubmitHandler, useForm } from 'react-hook-form';
import { FormContext } from './context';
import { Button } from '../Button';
import {
  FieldErrorSummary,
  PasswordValidationState,
  ValidationMessage,
  errorsToErrorSummary,
} from '../../../utils/form';
import { joinStrings } from '../../../utils/string';
import { FormErrorAlert } from '../FormErrorAlert';
import './Form.scss';

export type FormActionsType = {
  submitDisabled?: boolean;
  submitButtonLabel?: string;
  isSubmitting?: boolean;
};

export type FormActionsComponent = FunctionComponent<FormActionsType>;
/**
 * A form action panel which will be used as default for the Form component.  Contains only a submit button for now.
 */
export const DefaultFormActions: FormActionsComponent = ({
  submitDisabled,
  submitButtonLabel = 'Submit',
  isSubmitting,
}) => {
  return (
    <div className="form__actions">
      <Button disabled={submitDisabled} isSubmitting={isSubmitting} type="submit">
        {submitButtonLabel}
      </Button>
    </div>
  );
};

export type FormProps<D extends Record<string, unknown>> = {
  /**
   * Form ID
   */
  id: string;
  /**
   * Submit button label key. Only needed if custom FormActions component not provided.
   */
  submitButtonLabel?: string;
  /**
   * Whether to disabled submit for invalid form state.  Defaults to false.
   */
  disableSubmitWhenInvalid?: boolean;
  /**
   * Default form state.
   */
  defaultValues: D;
  /**
   * Must be used to provide FormControl components to render.
   *
   * Custom FormControl components can use the FormContext to register controls with react-hook-form.
   */
  children?: React.ReactNode;
  /**
   * Auto focus on first input.  Defaults to false.
   */
  autofocus?: boolean;
  /**
   * A component to render form actions.  DefaultFormActions will be used by default.
   */
  FormActions?: FormActionsComponent | null;
  /**
   * React hook form validation mode.  Defaults to onBlur.
   */
  validationMode?: ValidationMode;
  /**
   * React hook form revalidation mode.  Defaults to onBlur.
   */
  reValidateMode?: Exclude<ValidationMode, 'onTouched' | 'all'>;
  /**
   * Whether to display validation error summary above form,
   */
  displayErrorSummary?: boolean;
  /**
   * Intro text for validation summary
   */
  errorSummaryIntro?: string;
  /**
   * Unregister inputs which will remove input state from form. Optional, defaults to true.
   */
  shouldUnregister?: boolean;
  /**
   * Called when valid user submits and form state is valid.
   */
  onSubmit?: SubmitHandler<D>;
  /**
   * Will clean error with using useForm hook
   */
  errrorToClear?: string;
  /**
   * Called when form is unmounted so that data can be saved by parent components.
   */
  onUnmount?: (formState: D) => void;
  /**
   * Called when there are form validation errors. Can be used to trigger custom behavior on field validation failure.
   */
  onError?: (summary: Record<string, FieldErrorSummary>, formState: D) => void;
  /**
   * Called when the form validation state is updated. Dependent upon validationMode.
   */
  onValidationStateChange?: (formValid: boolean) => void;
  onFormControlBlur?: (
    fieldName: string,
    valid: boolean,
    validationMessages?: ValidationMessage[],
    passwordValidationState?: PasswordValidationState
  ) => void;
  onFormControlFocus?: (fieldName: string) => void;

  onSubmitError?: () => void;
} & Omit<ComponentPropsWithoutRef<'form'>, 'onSubmit' | 'onError'>;

/**
 * A form component which encapsulates integration with react hook form to enable simpler form usage when used with
 * the default FormControl component or custom form control implementation.  Particularly useful for dynamic forms.
 *
 * ## Example Usage
 *
 * ```txx
 * <Form
 *  id="name-form"
 *  defaultValues={{ firstName: '' }}
 *  onSubmit={(formData) => { console.log('do something useful') }}
 * >
 *   <FormControl<NameFormData>
 *     type={FormFieldType.TextInput}
 *     name=firstName'
 *     label='First Name'
 *     rules={{ required: 'Name is required' }}
 *   />
 * </Form>
 * ```
 */
export function Form<D extends Record<string, unknown>>({
  children,
  id,
  autofocus,
  defaultValues,
  disableSubmitWhenInvalid = true,
  submitButtonLabel,
  validationMode = 'onBlur',
  reValidateMode = 'onBlur',
  displayErrorSummary,
  shouldUnregister = true,
  noValidate = true,
  className,
  errrorToClear,
  onSubmit,
  onUnmount,
  onError,
  onValidationStateChange,
  onFormControlBlur,
  onFormControlFocus,
  onSubmitError,
  FormActions = DefaultFormActions,
  errorSummaryIntro = 'intro',
  ...formProps
}: FormProps<D>): JSX.Element {
  // Keep a copy of initial form values for use in form control validation.
  const [defaultFormValues] = useState(defaultValues);
  const useFormReturnValue = useForm({
    defaultValues: defaultFormValues as Record<string, unknown>,
    mode: validationMode,
    reValidateMode,
    shouldUnregister,
  });
  const firstUpdate = useRef(true);
  const formBlurRef = useRef(false);
  const { handleSubmit, formState, getValues, clearErrors } = useFormReturnValue;

  const errorSummary = errorsToErrorSummary(formState.errors, {}, id);
  const submitDisabled = (disableSubmitWhenInvalid && !formState.isValid) || formState.isSubmitting;

  useEffect(() => {
    errrorToClear && clearErrors(errrorToClear);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errrorToClear]);

  useEffect(() => {
    if (formState.submitCount < 1 || formState.isSubmitting || formState.isSubmitSuccessful) return;

    onSubmitError?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.submitCount, formState.isSubmitSuccessful, formState.isSubmitting]);

  useEffect(() => {
    if (autofocus) {
      const input = document.querySelector(`form#${id} [name]`) as HTMLInputElement;

      input && input.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const values = getValues() as D;
  const errorSummaryString = formState.isValidating
    ? ''
    : Object.keys(errorSummary)
        .map((key) => `${key}-${values[errorSummary[key].fieldName]}`)
        .join();

  useEffect(() => {
    if (onError && formBlurRef.current && errorSummaryString.length) {
      onError(errorSummary, getValues() as D);
      formBlurRef.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formBlurRef.current, errorSummaryString]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;

      return;
    }

    onValidationStateChange && onValidationStateChange(formState.isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationMode !== 'onSubmit' && formState.isValid]);

  useEffect(() => {
    return () => onUnmount && onUnmount(getValues() as D);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const errorList = Object.values(errorSummary);

  return (
    <Fragment>
      {displayErrorSummary && <FormErrorAlert intro={errorSummaryIntro} errors={errorList} />}
      <form
        {...formProps}
        noValidate={noValidate}
        onBlur={(e): void => {
          formBlurRef.current = true;
          formProps.onBlur && formProps.onBlur(e);
        }}
        id={id}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onSubmit={onSubmit && handleSubmit(onSubmit)}
        className={joinStrings(['form', className])}
      >
        <FormProvider {...useFormReturnValue}>
          <FormContext.Provider
            value={{
              errorSummary,
              validationMode,
              defaultValues: defaultFormValues,
              onFormControlFocus,
              onFormControlBlur,
            }}
          >
            <Suspense>
              {children}
              {FormActions ? (
                <FormActions
                  {...{
                    submitDisabled,
                    submitButtonLabel,
                    isSubmitting: formState.isSubmitting,
                  }}
                />
              ) : null}
            </Suspense>
          </FormContext.Provider>
        </FormProvider>
      </form>
    </Fragment>
  );
}
