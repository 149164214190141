import React, { FunctionComponent } from 'react';
import { Game } from '../games.model';
import { gameInfoConfig } from '../mocks';
import { GameTagItem, GameTagsCard } from './GameTagsCard';
import { useTranslations } from '../../../hooks/useTranslationsHelper';

export type GameSpecsProps = {
  game: Game;
} & React.ComponentPropsWithoutRef<'div'>;

/**
 * GameSpecs Component
 */
export const GameSpecs: FunctionComponent<GameSpecsProps> = ({ game }) => {
  const { t } = useTranslations();
  const infoFields: Array<GameTagItem> = [];
  const { minBet, maxBet, paylines, rtp } = game;
  const hasValue = [minBet, maxBet, paylines, rtp].some(Boolean);

  if (!hasValue) {
    return null;
  }

  if (!gameInfoConfig.hideMinBet && minBet) {
    infoFields.push({
      key: 'minBet',
      text: t('game.spec.min-bet'),
      value: String(minBet).endsWith('£') ? String(minBet) : `${String(minBet)}£`,
    });
  }

  if (!gameInfoConfig.hideMaxBet && maxBet) {
    infoFields.push({
      key: 'maxBet',
      text: t('game.spec.max-bet'),
      value: String(maxBet).endsWith('£') ? String(maxBet) : `${String(maxBet)}£`,
    });
  }

  if (!gameInfoConfig.hideNumberOfLines && paylines) {
    infoFields.push({
      key: 'numberOfLines',
      text: t('game.spec.paylines'),
      value: paylines?.toString() || '',
    });
  }

  if (!gameInfoConfig.hideRTP && rtp) {
    // TODO: Are min/max RTP required to be shown if different?
    const rtpValue = rtp.toString();

    infoFields.push({
      key: 'maxRtp',
      text: t('game.spec.min-rtp'),
      value: rtpValue.endsWith('%') ? rtpValue : `${rtpValue}%`,
    });
  }

  return <GameTagsCard heading="Game Specs" tags={infoFields} />;
};
