import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetRealityCheckIntervalMutationVariables = Types.Exact<{
  xTenantId: Types.Scalars['ID']['input'];
  userProfileId: Types.Scalars['ID']['input'];
  realityCheckSetIntervalBodyRequest?: Types.InputMaybe<Types.RealityCheckSetIntervalBodyRequest>;
}>;


export type SetRealityCheckIntervalMutation = { __typename?: 'Mutation', setRealityCheckInterval: { __typename?: 'RealityCheckSetIntervalResponse', data: string, error?: Array<{ __typename?: 'FieldError', code: string } | { __typename?: 'GenericError', code: string }> | null } };


export const SetRealityCheckIntervalDocument = gql`
    mutation SetRealityCheckInterval($xTenantId: ID!, $userProfileId: ID!, $realityCheckSetIntervalBodyRequest: RealityCheckSetIntervalBodyRequest) {
  setRealityCheckInterval(
    xTenantID: $xTenantId
    userProfileId: $userProfileId
    realityCheckSetIntervalBodyRequest: $realityCheckSetIntervalBodyRequest
  ) {
    data
    error {
      ... on GenericError {
        code
      }
      ... on FieldError {
        code
      }
    }
  }
}
    `;
export type SetRealityCheckIntervalMutationFn = Apollo.MutationFunction<SetRealityCheckIntervalMutation, SetRealityCheckIntervalMutationVariables>;

/**
 * __useSetRealityCheckIntervalMutation__
 *
 * To run a mutation, you first call `useSetRealityCheckIntervalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetRealityCheckIntervalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setRealityCheckIntervalMutation, { data, loading, error }] = useSetRealityCheckIntervalMutation({
 *   variables: {
 *      xTenantId: // value for 'xTenantId'
 *      userProfileId: // value for 'userProfileId'
 *      realityCheckSetIntervalBodyRequest: // value for 'realityCheckSetIntervalBodyRequest'
 *   },
 * });
 */
export function useSetRealityCheckIntervalMutation(baseOptions?: Apollo.MutationHookOptions<SetRealityCheckIntervalMutation, SetRealityCheckIntervalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetRealityCheckIntervalMutation, SetRealityCheckIntervalMutationVariables>(SetRealityCheckIntervalDocument, options);
      }
export type SetRealityCheckIntervalMutationHookResult = ReturnType<typeof useSetRealityCheckIntervalMutation>;
export type SetRealityCheckIntervalMutationResult = Apollo.MutationResult<SetRealityCheckIntervalMutation>;
export type SetRealityCheckIntervalMutationOptions = Apollo.BaseMutationOptions<SetRealityCheckIntervalMutation, SetRealityCheckIntervalMutationVariables>;