import React, { FC, memo } from 'react';
import { Paragraph, ParagraphProps } from '../Paragraph';
import { Theme, ValidationState } from '../../../types';
import { joinStrings } from '../../../utils/string';
import './InputHelperText.scss';

export type InputHelperTextProps = {
  /**
   * ID used by aria-describedby to describe input
   */
  id: string;
  /**
   * Value to display in paragraph
   */
  value: string | React.ReactNode;
  /**
   * Optional, defaults to 'light'. If set to 'dark' text will be styled to be placed on a dark background.
   */
  theme?: Theme;
  /**
   * Whether the joined input is focused or not
   */
  inputIsFocused: boolean;
  /**
   * Optional. Is joined input disabled
   */
  disabled?: boolean;
  /**
   * Optional. Validation state of joined input
   * To append a class name when joined input has validation errors
   */
  validationState?: ValidationState;
} & Omit<ParagraphProps, 'children'>;

/**
 * Component to be used as descriptive text to render underneath input
 */
export const InputHelperText: FC<InputHelperTextProps> = memo(
  ({ id, value, size = 'sm', className, theme, inputIsFocused, disabled, validationState, ...props }) => {
    return (
      <Paragraph
        {...props}
        id={id}
        size={size}
        className={joinStrings([
          'input-helper-text',
          theme === 'dark' && 'input-helper-text--dark',
          inputIsFocused && 'input-helper-text--input-focused',
          disabled && 'input-helper-text--disabled',
          validationState === 'error' && 'input-helper-text--has-errors',
          className,
        ])}
        data-testid="input-helper-text"
        noMargin
      >
        {value}
      </Paragraph>
    );
  }
);

InputHelperText.displayName = 'InputHelperText';
