export const getSelfExclusion = (
  t: (arg: string) => string
): {
  selfExclusionReason: {
    options: {
      label: string;
      value: string;
    }[];
    required: boolean;
  };
  selfExclusionPeriod: {
    options: { label: string; value: string; unit: string }[];
  };
  selfExclusionRule: {
    options: {
      label: string;
      value: string;
      unit: string;
    }[];
  };
} => {
  const reason01 = t('self-exclusion.reasons01');
  const reason02 = t('self-exclusion.reasons02');
  const reason03 = t('self-exclusion.reasons03');

  const period01 = t('self-exclusion.period01');
  const period02 = t('self-exclusion.period02');
  const period03 = t('self-exclusion.period03');
  const period04 = t('self-exclusion.period04');
  const period05 = t('self-exclusion.period05');

  const rule01 = t('self-exclusion.points.01');
  const rule02 = t('self-exclusion.rules.05');

  return {
    selfExclusionReason: {
      options: [
        {
          label: reason01,
          value: reason01,
        },
        {
          label: reason02,
          value: reason02,
        },
        {
          label: reason03,
          value: reason03,
        },
      ],
      required: true,
    },
    selfExclusionPeriod: {
      options: [
        { label: period01, value: '6', unit: 'months' },
        { label: period02, value: '12', unit: 'months' },
        { label: period03, value: '18', unit: 'months' },
        { label: period04, value: '2', unit: 'years' },
        { label: period05, value: '5', unit: 'years' },
      ],
    },
    selfExclusionRule: {
      options: [
        {
          label: rule01,
          value: rule01,
          unit: 'logo-path',
        },
        {
          label: rule02,
          value: rule02,
          unit: 'logo-path',
        },
      ],
    },
  };
};
