import { createContext } from 'react';
import { PasswordValidationState, ValidationMessage } from '../../utils/form';
import { FlattenedProfileData } from '../../hooks/registration';

export type RegistrationContextProps = {
  abandonPromptOpen: boolean;
  onCloseAbandonPrompt: () => void;
  onConfirmAbandon: () => void;
  onAddressSearchFocus?: () => void;
  onAddressSearchBlur?: () => void;
  onPostRegistrationLimitStep: () => void;
  /**
   * Should be used for observation & not to attempt to mutate stepper/form state.
   *
   * Called on FormControl focus, can be used to trigger analytics events.
   */
  onFormControlFocus?: (fieldName: string) => void;
  /**
   * Should be used for observation & not to attempt to mutate stepper/form state.
   *
   * Called on FormControl blur, can be used to trigger analytics events.
   *
   * Will be slightly async to event due to need to wait for validation update.
   */
  onFormControlBlur?: (
    fieldName: string,
    valid: boolean,
    validationMessages?: ValidationMessage[],
    passwordValidationState?: PasswordValidationState
  ) => void;
  /**
   * Should be used for observation & not to attempt to mutate stepper/form state.
   *
   * Called when user submits a form step but validation fails.
   */
  onSubmitError?: () => void;
  /**
   * Should be used for observation & not to attempt to mutate stepper/form state.
   *
   * Called when user submits a form step and there are no validation errors.
   */
  onSubmitSuccess?: (formState: Partial<FlattenedProfileData>) => void;
  /**
   * Should be used for observation & not to attempt to mutate stepper/form state.
   *
   * Called when user submits clicks the back button on a form step.
   */
  onPreviousStepClick?: () => void;
};

export const RegistrationContext = createContext<RegistrationContextProps>({} as RegistrationContextProps);
