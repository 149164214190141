import React, { FunctionComponent } from 'react';
import { userBalance } from '../../Header/HeaderBalance/mocks';
import { Heading } from '../../shared/Heading';
import { Link } from '../../shared/Link';
import { WITHDRAWAL_LIMIT_AMOUNT, WITHDRAWAL_ZERO_LIMIT } from './consts';
import './WithdrawalCheck.scss';
import { useTranslations } from '../../../hooks/useTranslationsHelper';

type WithdrawalCheckProps = {
  /**
   * Heading of the component
   */
  heading: string;
  /**
   * Link's label
   */
  linkText: string;
  /**
   * Component internal content part
   */
  children: React.ReactNode;
  /**
   * Prop which defines whether zero limit check is needed
   */
  isZeroLimitCheckNeeded?: boolean;
};

/**
 * Component to encourage customer to withdraw before account closure
 */
export const WithdrawalCheck: FunctionComponent<WithdrawalCheckProps> = ({
  heading,
  children,
  linkText,
  isZeroLimitCheckNeeded,
}) => {
  const { t } = useTranslations();
  const onClick = (): void => {
    console.log('withdrawalOpen');
  };

  return (
    <div className="withdrawal-check">
      {userBalance.withdrawal >= WITHDRAWAL_LIMIT_AMOUNT && (
        <>
          <Heading className="withdrawal-check__heading" level={6}>
            {heading}
          </Heading>
          <Link onClick={(): void => onClick()}>{linkText}</Link>
        </>
      )}
      {isZeroLimitCheckNeeded &&
        userBalance.withdrawal < WITHDRAWAL_LIMIT_AMOUNT &&
        userBalance.withdrawal !== WITHDRAWAL_ZERO_LIMIT && (
          <Heading level={6}>{t('self-exclusion.withdraw-note')}</Heading>
        )}
      {children}
    </div>
  );
};
