import { SelectOption } from '../components/shared/Select';
import { countries } from './loqate/locale';

/**
 * All the allowed special characters in a phone field.
 */
export const allowedPhoneSpecialCharacters = ['/', '-'];
const allowedCharsPattern = /\.?\(?\/?\)?-?\+?/g;

export const allowedPhoneValues = '1234567890' + allowedPhoneSpecialCharacters.join('');
export const anyLetterCharacterPattern = /[a-z]?[A-Z]?/g;
const phoneValidationPattern = /^(?<prefix>[0]{2}|\+){1}(?<countryCode>[\d]{1,5})\s(?<national>[\s\S]+)/;
const codeValidationPattern = /^(?<prefix>[0]{2}|\+){1}(?<countryCode>[\d]{1,5})/;
const particularCodes = ['GB', 'ES', 'DE', 'NZ'];

/**
 * Used to separate country code from remainder of telephone. Defaults to a space.
 * Any validation regex will need to take this separator into account.
 */
export const delimiter = ' ';

const phoneRules = [
  // UK, i.e. +44 (0)7931736299
  {
    countryCode: '44',
    minLength: 9, // rare, but possible
    maxLength: 10,
  },
  // NZ, i.e. +64 (0)21555178
  {
    countryCode: '64',
    minLength: 8,
    maxLength: 9,
  },
  // BR, i.e. +55 (0)95553776273
  {
    countryCode: '55',
    minLength: 10,
    maxLength: 11,
  },
  // DE, i.e. +49-157-555-5498
  {
    countryCode: '49',
    minLength: 10,
    maxLength: 11,
  },
];

export type CountryObject = {
  [key: string]: {
    phone: string;
  };
};

export enum PhoneErrorCodes {
  INVALID_PHONE,
  REQUIRED,
  TOO_SHORT,
  TOO_LONG,
}

export class PhoneNumber {
  /**
   * Phone number international notation E.123
   * @example +44 7931736299
   */
  private _international: string;
  /**
   * National phone number notation
   * @example 95553776273
   */
  national: string;
  /**
   * Country code of the phone number
   * @example 44
   */
  countryCode?: string;
  private _isValid: boolean;
  private _errorMessageText?: string;
  private _errorCode: PhoneErrorCodes | null;
  private countryCodeOptions: SelectOption[];
  constructor(input: string, countryCodeOptions: SelectOption[]) {
    this._international = input;
    this.national = this._national;
    this.countryCode = this._countryCode;
    this._isValid = true;
    this._errorMessageText = undefined;
    this._errorCode = null;
    this.countryCodeOptions = countryCodeOptions;
  }

  private get _countryCode(): string | undefined {
    const found = this._international.match(codeValidationPattern);

    if (found?.groups) {
      // countryCode sufix should match exactly the same name as in the phoneValidationPattern
      return found.groups.countryCode;
    } else if (found && found?.length > 1) {
      return found[2];
    }

    return undefined;
  }

  private get _national(): string {
    const found = this._international.match(phoneValidationPattern);

    if (found?.groups) {
      // national sufix should match exactly the same name as in the phoneValidationPattern
      return found.groups.national;
    } else if (found && found?.length > 2) {
      return found[3];
    } else if (this._international.split(' ').length > 1) {
      return this._international.split(' ').slice(1).join('');
    }

    return this._international || '';
  }

  /**
   * Performs a validation check and returns a new telephone international format E.123
   * @returns +55 95553776273
   */
  get international(): string {
    this.performValidation();

    if (!this.countryCode) {
      return `${this.national}`;
    }

    return `+${this.countryCode} ${this.national}`;
  }

  get isValid(): boolean {
    return this._isValid;
  }

  /**
   * Perform validation checks and return a boolean according to the output.
   */
  performValidation(): boolean {
    this.countryCodePresenceCheck();
    this.removeParticularNumberZero();
    this.sanitizeTelephone();
    this.checkPhonePattern();
    this.checkExcessiveLength();
    this.checkEmpty();

    return this._isValid;
  }

  /**
   * Define the type of error that has been thrown when the phone is invalid.
   */
  get errorCode(): PhoneErrorCodes | null {
    return this._errorCode;
  }

  /**
   * Text error message according to the error code that is returned.
   */
  get errorMessageText(): string | undefined {
    return this._errorMessageText;
  }

  /**
   * Performs an empty check
   */
  private checkEmpty(): void {
    if (!this.national) {
      this._errorCode = PhoneErrorCodes.REQUIRED;
      this._errorMessageText = 'Phone number field is required.';
      this._isValid = false;
    }
  }

  /**
   * Perform a check if the telephone has too short or too long value length.
   */
  private checkExcessiveLength(): void {
    const presentRule = phoneRules.find((rule) => rule.countryCode === this.countryCode);

    if (presentRule) {
      if (this.national.length > presentRule.maxLength) {
        this._errorCode = PhoneErrorCodes.TOO_LONG;
        this._errorMessageText = 'Phone number is too long.';
        this._isValid = false;
      }

      if (this.national.length < presentRule.minLength) {
        this._errorCode = PhoneErrorCodes.TOO_SHORT;
        this._errorMessageText = 'Phone number is too small.';
        this._isValid = false;
      }
    }

    if (this.national.length > 15) {
      this._errorCode = PhoneErrorCodes.TOO_LONG;
      this._errorMessageText = 'Phone number is too long.';
      this._isValid = false;
    }

    if (this.national.length < 4) {
      this._errorCode = PhoneErrorCodes.TOO_SHORT;
      this._errorMessageText = 'Phone number is too small.';
      this._isValid = false;
    }
  }

  /**
   * Performs a Country Code check in the national notation number.
   * If such is found it will update the actuall country code with the newly found.
   * Removes the found Country Code value from the national number.
   */
  private countryCodePresenceCheck(): void {
    // Get country codes from config and remove the + sign
    const countryCodes = this.countryCodeOptions.map((options) => options.value.replace('+', ''));
    let sliceStart = 0;
    let updatedCode = this.countryCode;

    countryCodes.forEach((code) => {
      if (this.national.startsWith(`00${code}`)) {
        updatedCode = code;
        sliceStart = `00${code}`.length;
      }

      if (this.national.startsWith(`+${code}`)) {
        updatedCode = code;
        sliceStart = `+${code}`.length;
      }
    });

    this.countryCode = updatedCode;
    const transformedNumber = this.national;

    this.national = transformedNumber.slice(sliceStart);
  }

  /**
   * Check if the national phone consists of digits only
   */
  private checkPhonePattern(): void {
    if (isNaN(Number(this.national))) {
      this._errorCode = PhoneErrorCodes.INVALID_PHONE;
      this._errorMessageText = 'Telephone verification failed';
      this._isValid = false;
    }
  }

  /**
   * Remove all all allowed phone special characters from the national telephone.
   */
  sanitizeTelephone(): void {
    let transformedValue = this.national;

    transformedValue = transformedValue.replace(allowedCharsPattern, '');

    this.national = transformedValue;
    this._international = this.countryCode + delimiter + this.national;
  }

  /**
   * Returns the value of the country code provided from it's abbreviation.
   * @param key Country ISO Code
   * @example 'UK', 'BR', 'NZ'
   * @returns +44, +55, +64
   */
  static getCodeValue(key: string): string {
    return (countries as CountryObject)[key].phone;
  }

  /**
   * Performs a validation check and returns a new telephone international format E.123
   * @returns 95553776273
   */
  public getPurePhoneNumber(): string {
    this.performValidation();

    return this.national;
  }

  /**
   * Returns the value of the country code provided from it's abbreviation.
   * @returns +44, +55, +64
   */
  public getCountryCodeValue(): string | undefined {
    return `+${this.countryCode}`;
  }

  /**
   * Removes the first 0 of a national phone if the country code exists
   * in the @param particularCodes list.
   * @example +44 07931736299
   * @returns +44 7931736299
   */
  removeParticularNumberZero(): string {
    const codes: string[] = particularCodes.map((code) => `+${PhoneNumber.getCodeValue(code)}`);
    const isContainsCode = (array: string[], value: string): boolean => array.some((code) => code === value);
    const isCodeSelected = isContainsCode(codes, `+${this.countryCode}`);

    if (isCodeSelected && this.national[0] === '0') {
      const updatedPhone = this.national.slice(1);

      this.national = updatedPhone;

      return updatedPhone;
    }

    return this.national;
  }
}
