import React, { FC } from 'react';
import { joinStrings } from '../../../../../utils/string';
import { Link, LinkButtonStyle } from '../../../../shared/Link';

import { PromotionClaimButton } from '../../PromotionClaimButton';

interface CardCTASectionProps {
  buttonText?: string;
  pageUrl?: string;
  promotionCTAType?: string;
  onActivateBonus?: () => void;
}

export const CardCTASection: FC<CardCTASectionProps> = ({
  buttonText,
  pageUrl = '',
  promotionCTAType,
  onActivateBonus,
}): JSX.Element => {
  const withCTA = promotionCTAType !== 'NoCTA';
  const fullWidthCTA: LinkButtonStyle = withCTA
    ? { variant: 'secondary ', size: 'medium' }
    : { variant: 'primary ', size: 'medium' };

  return (
    <div
      className={joinStrings([
        !withCTA && 'promotion-card__buttons-block--full-width',
        'promotion-card__buttons-block',
      ])}
    >
      <Link
        href={pageUrl}
        buttonStyle={fullWidthCTA}
        className={joinStrings([withCTA && 'promotion-card__secondary-btn'])}
      >
        MORE INFO
      </Link>
      {withCTA && <PromotionClaimButton label={buttonText} onActivateBonus={onActivateBonus} />}
    </div>
  );
};
